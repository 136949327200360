import { useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import {
  QuoteStatus,
  MatchDangerZoneForExpertFullQuery,
  MatchOldAlertsForExpertFullQuery,
  MatchDangerZoneProjectsForExpertFullQuery,
} from '../../gql/graphql';
import { MatchActionSummaryWithBrand } from '../../utils/gql';
import { unreadReasonFormatted } from '../../utils/format';
import errorUtils from '../../utils/error';

const matchDangerZoneForExpertQuery = gql`
  query MatchDangerZoneForExpertFull {
    matchDangerZoneForExpert {
      ...MatchActionSummaryWithBrand
    }
  }
  ${MatchActionSummaryWithBrand}
`;

const matchDangerZoneProjectsForExpertQuery = gql`
  query MatchDangerZoneProjectsForExpertFull {
    matchDangerZoneProjectsForExpert {
      ...MatchActionSummaryWithBrand
    }
  }
  ${MatchActionSummaryWithBrand}
`;

const matchOldAlertsForExpertQuery = gql`
  query MatchOldAlertsForExpertFull {
    matchOldAlertsForExpert {
      ...MatchActionSummaryWithBrand
    }
  }
  ${MatchActionSummaryWithBrand}
`;

interface IDangerZoneProject {
  match: MatchDangerZoneProjectsForExpertFullQuery['matchDangerZoneProjectsForExpert'][0];
  project: MatchDangerZoneProjectsForExpertFullQuery['matchDangerZoneProjectsForExpert'][0]['hasActiveProjects'][0];
}

interface ExpertDangerZoneProps {
  expertId: string;
  socketClient: ClientWithOnReconnected;
}

const initTime = new Date().getTime();

const ExpertDangerZone = ({
  expertId,
  socketClient,
}: ExpertDangerZoneProps) => {
  const {
    data: dataMatchDangerZone,
    error: errorMatchDangerZone,
    loading: loadingMatchDangerZone,
    refetch: refetchMatchDangerZone,
  } = useQuery<MatchDangerZoneForExpertFullQuery>(
    matchDangerZoneForExpertQuery,
    {
      returnPartialData: true,
    },
  );
  const {
    data: dataMatchDangerZoneProjects,
    error: errorMatchDangerZoneProjects,
    loading: loadingMatchDangerZoneProjects,
    refetch: refetchMatchDangerZoneProjects,
  } = useQuery<MatchDangerZoneProjectsForExpertFullQuery>(
    matchDangerZoneProjectsForExpertQuery,
    {
      returnPartialData: true,
    },
  );
  const {
    data: dataMatchOldAlerts,
    error: errorMatchOldAlerts,
    loading: loadingMatchOldAlerts,
    refetch: refetchMatchOldAlerts,
  } = useQuery<MatchOldAlertsForExpertFullQuery>(matchOldAlertsForExpertQuery, {
    returnPartialData: true,
  });
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('ExpertDangerZone socketClient onReconnected');
      refetchMatchDangerZone().catch(() => {});
      refetchMatchDangerZoneProjects().catch(() => {});
      refetchMatchOldAlerts().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [
    socketClient,
    refetchMatchDangerZone,
    refetchMatchDangerZoneProjects,
    refetchMatchOldAlerts,
  ]);
  // combine all data, dedup, filter, sort into 4 groups, render
  const veryUnreadClients = _.uniqBy(
    [
      ...((dataMatchOldAlerts && dataMatchOldAlerts.matchOldAlertsForExpert) ||
        []),
    ].filter(
      (m) =>
        !m.isBlocked &&
        m.expertStr === expertId &&
        m.expertUnread &&
        m.expertReadAt &&
        m.expertReadAt < initTime - 3 * 24 * 60 * 60 * 1000,
    ),
    'id',
  );
  // combine all data, dedup, filter, sort into 4 groups, render
  const dangerZoneClients = _.uniqBy(
    [
      ...((dataMatchDangerZone &&
        dataMatchDangerZone.matchDangerZoneForExpert) ||
        []),
    ].filter(
      (m) =>
        !m.isBlocked &&
        m.expertStr === expertId &&
        m.dangerZoneEventExpert &&
        m.dangerZoneEventExpert.isSupportCase,
    ),
    'id',
  );
  // combine all data, dedup, filter, sort into 4 groups, render
  const dangerZoneProjects = _.uniqBy(
    [
      ...((dataMatchDangerZoneProjects &&
        dataMatchDangerZoneProjects.matchDangerZoneProjectsForExpert) ||
        []),
    ].filter(
      (m) =>
        !m.isBlocked &&
        m.expertStr === expertId &&
        m.hasActiveProjects &&
        m.hasActiveQuotes &&
        m.dangerZoneProjectsStr,
    ),
    'id',
  );
  const dzpCompleted: IDangerZoneProject[] = [];
  const dzpRest: IDangerZoneProject[] = [];
  dangerZoneProjects.forEach((m) => {
    const project =
      m.hasActiveProjects.find(
        (p) => m.dangerZoneProjectsStr.indexOf(p.id) >= 0,
      ) ||
      m.hasActiveQuotes.find((p) => m.dangerZoneProjectsStr.indexOf(p.id) >= 0);
    if (project && project.quote) {
      if (project.quote.status === QuoteStatus.Completed) {
        dzpCompleted.push({
          match: m,
          project,
        });
      } else {
        dzpRest.push({
          match: m,
          project,
        });
      }
    }
  });
  if (
    loadingMatchDangerZone ||
    loadingMatchDangerZoneProjects ||
    loadingMatchOldAlerts
  ) {
    // ignore these
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div className="DashboardScreenContent ClientsScreenContent">
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Active Support Cases</div>
          {!dangerZoneClients.length && (
            <div className="DashboardSectionEmpty">
              {errorMatchDangerZone
                ? errorUtils.getErrorMessage(errorMatchDangerZone)
                : 'No active support cases, keep up the good work!'}
            </div>
          )}
          <div className="DashboardSectionScrollerSwitch">
            {dangerZoneClients.map((m) => {
              const dzDesc =
                (m.dangerZoneEventExpert &&
                  m.dangerZoneEventExpert.dangerZoneAdditionalDescription) ||
                '';
              const dzDescStr =
                dzDesc.length > 120 ? dzDesc.slice(0, 120) + '...' : dzDesc;
              return (
                <div
                  key={m.id}
                  className="ClientSummary ClientSummaryDangerZone"
                >
                  <Link to={'/clients/' + m.id} className="ClientSummaryBody">
                    <div className="ClientSummaryTitle ClientSummaryTitleClip">
                      {m.brand && m.brand.name ? m.brand.name : ''}
                    </div>
                    <div className="ClientSummarySubTitle">
                      {m.brand && m.brand.teamLeader && m.brand.teamLeader
                        ? (
                            (m.brand.teamLeader.firstName || '').trim() +
                            ' ' +
                            (m.brand.teamLeader.lastName || '').trim()
                          ).trim()
                        : ''}
                    </div>
                    <div className="ClientSummaryDangerDesc">{dzDescStr}</div>
                  </Link>
                  <div className="ClientSummaryFooter" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">
            Projects That Need Your Attention
          </div>
          {!dzpRest.length && (
            <div className="DashboardSectionEmpty">
              {errorMatchDangerZoneProjects
                ? errorUtils.getErrorMessage(errorMatchDangerZoneProjects)
                : "Looking good so far! We'll add projects here that could become support cases in the future to help you stay on top of things."}
            </div>
          )}
          <div className="DashboardSectionScrollerSwitch">
            {dzpRest.map((dzp) => (
              <div
                key={dzp.match.id}
                className="ClientSummary ClientSummaryProject"
              >
                <Link
                  to={'/clients/' + dzp.match.id}
                  className="ClientSummaryBody"
                >
                  <div className="ClientSummaryTitle">
                    {dzp.project.quote.title || 'Project'}
                  </div>
                  <div className="ClientSummarySubTitle">
                    {dzp.match.brand && dzp.match.brand.name
                      ? dzp.match.brand.name
                      : ''}
                  </div>
                  <div className="ClientSummaryStatusLine CardStatusLineREVISIONS_REQUESTED" />
                  <div className="ClientSummaryRightCorner">
                    {dzp.project.quote.revisionsRequested
                      ? 'Revisions Requested'
                      : 'Past Deadline'}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        {(!!veryUnreadClients.length || !!errorMatchOldAlerts) && (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">
              Unread For Multiple Days
            </div>
            {!!errorMatchOldAlerts && (
              <div className="DashboardSectionEmpty">
                {errorUtils.getErrorMessage(errorMatchOldAlerts)}
              </div>
            )}
            <div className="DashboardSectionScrollerSwitch">
              {veryUnreadClients.map((m) => (
                <div key={m.id} className="ClientSummary ClientSummaryProject">
                  <Link to={'/clients/' + m.id} className="ClientSummaryBody">
                    <div className="ClientSummaryTitle">
                      {m.brand && m.brand.name ? m.brand.name : ''}
                    </div>
                    <div className="ClientSummarySubTitle">
                      {unreadReasonFormatted(m.expertUnread)}
                    </div>
                    <div className="ClientSummaryStatusLine CardStatusLineREVISIONS_REQUESTED" />
                    <div className="ClientSummaryRightCorner">
                      Unread For Days
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
        {!!dzpCompleted.length && (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">Waiting For Approval</div>
            <div className="DashboardSectionScrollerSwitch">
              {dzpCompleted.map((dzp) => (
                <div
                  key={dzp.match.id}
                  className="ClientSummary ClientSummaryProject"
                >
                  <Link
                    to={'/clients/' + dzp.match.id}
                    className="ClientSummaryBody"
                  >
                    <div className="ClientSummaryTitle">
                      {dzp.project.quote.title || 'Project'}
                    </div>
                    <div className="ClientSummarySubTitle">
                      {dzp.match.brand && dzp.match.brand.name
                        ? dzp.match.brand.name
                        : ''}
                    </div>
                    <div className="ClientSummaryStatusLine CardStatusLineCOMPLETED" />
                    <div className="ClientSummaryRightCorner">
                      Marked Complete
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ExpertDangerZone.propTypes = {
  expertId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default ExpertDangerZone;
