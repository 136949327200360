import { useContext } from 'react';
import { useQuery, ServerError, gql } from '@apollo/client';
import { GlobalNotificationContext } from './components/context/GlobalNotification';
import PartnerLogin from './components/page/PartnerLogin';
import PartnerDashboard from './components/page/PartnerDashboard';
import oauthUtils from './utils/oauth';
import errorUtils from './utils/error';
import { AuthPartnerQuery } from './gql/graphql';

const authPartnerQuery = gql`
  query AuthPartner {
    auth {
      id
      token
      user {
        ... on Partner {
          id
          allPurchasesEstimate
          gmvTotalEstimate
          isNoCommission
          kpisUpdated
          contactEmail
          levelEstimate
          partnerName
          recipientId
          trackingId
          uniqueActiveProjectsEstimate
          uniqueLeadsEstimate
          uniquePurchasesEstimate
          uniqueReferredHumansEstimate
          uniqueSignupsEstimate
        }
      }
    }
  }
`;

const AppPartner = () => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const skipAuthCheck = !oauthUtils.getToken('partner');
  const {
    loading: loadingPartner,
    error: errorPartner,
    data: dataPartner,
  } = useQuery<AuthPartnerQuery>(authPartnerQuery, {
    onError: (err) => {
      console.log(err, 'onError');
      // onError 401/403 erase token
      if (err.networkError && err.networkError.name === 'ServerError') {
        const networkError = err.networkError as ServerError;
        if (
          networkError.statusCode === 401 ||
          networkError.statusCode === 403
        ) {
          oauthUtils.logout('partner');
          return;
        }
      }
      if (err.networkError) {
        addNotification(
          'A network error occured, please refresh and try again: ' +
            (errorUtils.getErrorMessage(err) || 'Loading Error'),
          undefined,
          300000,
        );
      } else {
        addNotification(errorUtils.getErrorMessage(err) || 'Loading Error');
      }
    },
    skip: skipAuthCheck,
  });
  if (errorPartner) {
    // ignore these
  }
  if (loadingPartner) {
    return <div className="AppLoadingFull">[storetasker]</div>;
  }
  if (
    !skipAuthCheck &&
    dataPartner &&
    dataPartner.auth &&
    dataPartner.auth.token &&
    dataPartner.auth.user &&
    dataPartner.auth.user.__typename === 'Partner' &&
    dataPartner.auth.user.id
  ) {
    oauthUtils.writeToken(
      dataPartner.auth.token,
      'partner',
      dataPartner.auth.user.id,
    );
    return <PartnerDashboard partnerDetails={dataPartner.auth.user} />;
  }
  return <PartnerLogin />;
};

export default AppPartner;
