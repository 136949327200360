import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState, useRef, useContext } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import PropTypes from 'prop-types';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { useQuery, useMutation, ApolloError, gql } from '@apollo/client';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { IFilestackFileUpload } from '../../utils/filestack';
import logError from '../../utils/airbrake';
import tokenUtils from '../../utils/token';
import errorUtils from '../../utils/error';
import EmailMessageThreadEvent from '../threadEvent/EmailMessageThreadEvent';
import TextMessageThreadEvent from '../threadEvent/TextMessageThreadEvent';
import MessageThreadEvent from '../threadEvent/MessageThreadEvent';
import PhoneCallThreadEvent from '../threadEvent/PhoneCallThreadEvent';
import MeetingThreadEvent from '../threadEvent/MeetingThreadEvent';
import EmailEditor from '../feature/EmailEditor';
import TextEditor from '../feature/TextEditor';
import AdminOrphanClientEditor from '../feature/AdminOrphanClientEditor';
import { SUPPORT_EXPERT_ID } from '../../utils/constants';
import {
  OrphanDetail,
  TextMessageEventDetail,
  EmailMessageEventDetail,
  MessageEventDetail,
  MeetingEventDetail,
  QuoteDetail,
  PhoneCallEventDetail,
  RequestEventDetail,
  MatchDetail,
} from '../../utils/gql';
import { replyToSubject } from '../../utils/format';
import {
  TemplatesQuery,
  OrphanDetailsAdminQuery,
  OrphanDetailsAdminQueryVariables,
  OrphanThreadPaginatedAdminQuery,
  OrphanThreadPaginatedAdminQueryVariables,
  OrphanThreadIntoBrandAdminMutation,
  OrphanThreadIntoBrandAdminMutationVariables,
  OrphanThreadEventUpdatedAdminSubscription,
  OrphanThreadEventUpdatedAdminSubscriptionVariables,
  OrphanAssignAdminMutation,
  OrphanAssignAdminMutationVariables,
  OrphanThreadSendTextMessageAdminMutation,
  OrphanThreadSendTextMessageAdminMutationVariables,
  MeetingInviteResponseOrphanAdminMutation,
  MeetingInviteResponseOrphanAdminMutationVariables,
  OrphanThreadSendEmailMessageAdminMutation,
  OrphanThreadSendEmailMessageAdminMutationVariables,
  OrphanArchiveUnarchiveAdminMutation,
  OrphanArchiveUnarchiveAdminMutationVariables,
} from '../../gql/graphql';

const orphanDetailsAdminQuery = gql`
  query OrphanDetailsAdmin($orphanId: ID!) {
    orphanDetails(orphanId: $orphanId) {
      ...OrphanDetail
    }
  }
  ${OrphanDetail}
`;

// @connection(key: "thread", filter: ["orphanId"])
const orphanThreadPaginatedAdminQuery = gql`
  query OrphanThreadPaginatedAdmin(
    $orphanId: ID
    $direction: String!
    $fromDate: Date
    $limit: Int!
  ) {
    threadPaginated(
      orphanId: $orphanId
      direction: $direction
      fromDate: $fromDate
      limit: $limit
    ) {
      id
      edges {
        id
        cursor
        node {
          ... on TextMessageEvent {
            ...TextMessageEventDetail
          }
          ... on EmailMessageEvent {
            ...EmailMessageEventDetail
          }
          ... on RequestEvent {
            ...RequestEventDetail
          }
          ... on MessageEvent {
            ...MessageEventDetail
          }
          ... on MeetingEvent {
            ...MeetingEventDetail
          }
          ... on Quote {
            ...QuoteDetail
          }
          ... on PhoneCallEvent {
            ...PhoneCallEventDetail
          }
        }
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const orphanThreadEventUpdatedAdminSubscription = gql`
  subscription OrphanThreadEventUpdatedAdmin($threadId: ID!) {
    threadEventUpdated(threadId: $threadId) {
      ... on TextMessageEvent {
        ...TextMessageEventDetail
      }
      ... on EmailMessageEvent {
        ...EmailMessageEventDetail
      }
      ... on RequestEvent {
        ...RequestEventDetail
      }
      ... on MessageEvent {
        ...MessageEventDetail
      }
      ... on MeetingEvent {
        ...MeetingEventDetail
      }
      ... on Quote {
        ...QuoteDetail
      }
      ... on PhoneCallEvent {
        ...PhoneCallEventDetail
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const orphanThreadIntoBrandAdminMutation = gql`
  mutation OrphanThreadIntoBrandAdmin($orphanId: ID!, $brandId: ID!) {
    orphanThreadIntoBrand(orphanId: $orphanId, brandId: $brandId) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const orphanThreadSendTextMessageAdminMutation = gql`
  mutation OrphanThreadSendTextMessageAdmin(
    $input: ThreadSendTextMessageInput!
  ) {
    threadSendTextMessage(input: $input) {
      ...TextMessageEventDetail
    }
  }
  ${TextMessageEventDetail}
`;

const orphanThreadSendEmailMessageAdminMutation = gql`
  mutation OrphanThreadSendEmailMessageAdmin(
    $input: ThreadSendEmailMessageInput!
  ) {
    threadSendEmailMessage(input: $input) {
      ...EmailMessageEventDetail
    }
  }
  ${EmailMessageEventDetail}
`;

const meetingInviteResponseOrphanAdminMutation = gql`
  mutation MeetingInviteResponseOrphanAdmin(
    $meetingId: ID!
    $response: String!
  ) {
    meetingInviteResponseByAdmin(meetingId: $meetingId, response: $response) {
      ...MeetingEventDetail
    }
  }
  ${MeetingEventDetail}
`;

const orphanAssignAdminMutation = gql`
  mutation OrphanAssignAdmin($orphanId: ID!, $adminAssignment: String!) {
    orphanAssignAdmin(orphanId: $orphanId, adminAssignment: $adminAssignment) {
      id
      adminAssigned
      isArchivedByExpert
    }
  }
`;

const orphanArchiveUnarchiveAdminMutation = gql`
  mutation OrphanArchiveUnarchiveAdmin($orphanId: ID!, $doArchive: Boolean!) {
    orphanArchiveUnarchive(orphanId: $orphanId, doArchive: $doArchive) {
      id
      isArchivedByExpert
    }
  }
`;

interface AdminInboundDetailProps {
  emailTemplates: TemplatesQuery['templates'];
  onLoadData: (childData: OrphanDetailsAdminQuery['orphanDetails']) => void;
  orphanId: string;
  socketClient: ClientWithOnReconnected;
}

interface IGroupedEvents {
  group: OrphanThreadPaginatedAdminQuery['threadPaginated']['edges'];
}

let scrollDownQuickTimeout: NodeJS.Timeout | undefined;
let isLoadingEvents = false;
const PAGE_LIMIT = 20;

const AdminInboundDetail = ({
  emailTemplates,
  onLoadData,
  orphanId,
  socketClient,
}: AdminInboundDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const threadEventsWindow = useRef<HTMLDivElement>(null);
  const [initDate, setInitDate] = useState(() => new Date());
  const [actionLoading, setActionLoading] = useState(false);
  const [threadSuccess, setThreadSuccess] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const [emailDraft, setEmailDraft] = useState(false);
  const [emailStatus, setEmailStatus] = useState('');
  const [emailFiles, setEmailFiles] = useState([] as IFilestackFileUpload[]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [emailRecipients, setEmailRecipients] = useState(
    [] as OrphanDetailsAdminQuery['orphanDetails']['humans'],
  );
  const [textDraft, setTextDraft] = useState(false);
  const [textStatus, setTextStatus] = useState('');
  const [textFiles, setTextFiles] = useState([] as IFilestackFileUpload[]);
  const [textContent, setTextContent] = useState('');
  const [textRecipients, setTextRecipients] = useState(
    [] as OrphanDetailsAdminQuery['orphanDetails']['humans'],
  );
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [tempEdges, setTempEdges] = useState(
    [] as OrphanThreadPaginatedAdminQuery['threadPaginated']['edges'],
  );
  // remoteEventIds tracks threadEventUpdated things that might cause pagination errors
  const [remoteEventIds, setRemoteEventIds] = useState([] as string[]);
  const [adminAssignment] = useState('');
  const {
    data: dataOrphan,
    error: errorOrphan,
    loading: loadingOrphan,
    refetch: refetchOrphan,
  } = useQuery<OrphanDetailsAdminQuery, OrphanDetailsAdminQueryVariables>(
    orphanDetailsAdminQuery,
    {
      onCompleted: (completedData) => {
        if (completedData && completedData.orphanDetails) {
          onLoadData(completedData.orphanDetails);
        }
      },
      returnPartialData: true,
      variables: {
        orphanId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorOrphan) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorOrphan) ||
      'Could not load orphan details';
  }
  const orphanDetails =
    dataOrphan && dataOrphan.orphanDetails && dataOrphan.orphanDetails.id
      ? dataOrphan.orphanDetails
      : undefined;
  if (
    orphanDetails &&
    orphanDetails.migratedToMatchStr &&
    !actionLoading &&
    !threadSuccess
  ) {
    fullErrorCover =
      'This orphan was merged into a match already: ' +
      orphanDetails.migratedToMatchStr;
  }
  useEffect(() => {
    setInitDate(new Date());
  }, [orphanId]);
  const {
    data: dataThread,
    error: errorThread,
    loading: loadingThread,
    fetchMore: fetchMoreThread,
    subscribeToMore: subscribeToMoreThread,
  } = useQuery<
    OrphanThreadPaginatedAdminQuery,
    OrphanThreadPaginatedAdminQueryVariables
  >(orphanThreadPaginatedAdminQuery, {
    onCompleted: () => {
      scrollDown();
    },
    variables: {
      direction: 'before',
      fromDate: initDate.getTime(),
      limit: PAGE_LIMIT,
      orphanId,
    },
  });
  if (errorThread) {
    fullErrorCover =
      'Error loading this thread: ' + errorUtils.getErrorMessage(errorThread);
  }
  const cleanThread =
    (dataThread &&
      dataThread.threadPaginated &&
      dataThread.threadPaginated.edges) ||
    [];
  useEffect(() => {
    setTempEdges([]);
    setRemoteEventIds([]);
    setCurrentAction('');
    setOpenActions(false);
  }, [orphanId]);
  useEffect(() => {
    const unsub = subscribeToMoreThread<
      OrphanThreadEventUpdatedAdminSubscription,
      OrphanThreadEventUpdatedAdminSubscriptionVariables
    >({
      document: orphanThreadEventUpdatedAdminSubscription,
      onError: (err) => {
        addNotification(
          'Error loading thread. Please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreThread', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { threadEventUpdated },
          },
        },
      ) => {
        console.log('threadEventUpdated', prev, threadEventUpdated);
        if (!threadEventUpdated || !prev.threadPaginated) {
          return prev;
        }
        if (
          threadEventUpdated.__typename === 'RequestEvent' ||
          threadEventUpdated.__typename === 'Quote'
        ) {
          return prev;
        }
        if (
          (threadEventUpdated.__typename === 'MessageEvent' ||
            threadEventUpdated.__typename === 'EmailMessageEvent' ||
            threadEventUpdated.__typename === 'TextMessageEvent' ||
            threadEventUpdated.__typename === 'MeetingEvent' ||
            threadEventUpdated.__typename === 'PhoneCallEvent') &&
          prev.threadPaginated.id !== threadEventUpdated.orphanStr
        ) {
          return prev;
        }
        setTempEdges((prevTempEdges) =>
          prevTempEdges.filter(
            (t) =>
              t.id !== threadEventUpdated.threadEvent &&
              [
                'tempEmailMessageThreadEventId',
                'tempTextMessageThreadEventId',
                'tempMeetingThreadEventId',
              ].indexOf(t.id) === -1,
          ),
        );
        setRemoteEventIds((prevRemoteEventIds) =>
          prevRemoteEventIds.concat(threadEventUpdated.id),
        );
        const updatingIndex = (prev.threadPaginated.edges || []).findIndex(
          (e) => e.id === threadEventUpdated.threadEvent,
        );
        if (updatingIndex >= 0) {
          // update existing
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: prev.threadPaginated.edges
                .slice(0, updatingIndex)
                .concat({
                  __typename:
                    prev.threadPaginated.edges[updatingIndex].__typename,
                  cursor: prev.threadPaginated.edges[updatingIndex].cursor,
                  id: prev.threadPaginated.edges[updatingIndex].id,
                  node: {
                    ...prev.threadPaginated.edges[updatingIndex].node,
                    ...threadEventUpdated,
                  } as OrphanThreadPaginatedAdminQuery['threadPaginated']['edges'][0]['node'],
                })
                .concat(prev.threadPaginated.edges.slice(updatingIndex + 1)),
              id: prev.threadPaginated.id,
            },
          };
        }
        // add new
        scrollDown();
        return {
          threadPaginated: {
            __typename: prev.threadPaginated.__typename,
            edges: (prev.threadPaginated.edges || []).concat({
              __typename: 'ThreadEventEdge',
              cursor: threadEventUpdated.createdAt,
              id: threadEventUpdated.threadEvent,
              node: threadEventUpdated,
            }),
            id: prev.threadPaginated.id,
          },
        };
      },
      variables: {
        threadId: orphanId,
      },
    });
    return () => {
      unsub();
    };
  }, [subscribeToMoreThread, orphanId, addNotification]);
  useEffect(() => {
    // check for drafts
    const hasEmailDraft = tokenUtils.readLocalStorage(
      `orphan-email-${orphanId}`,
    );
    setEmailDraft(!!hasEmailDraft);
    if (hasEmailDraft) {
      setEmailSubject(
        tokenUtils.readLocalStorage(`orphan-email-${orphanId}-subject`) || '',
      );
    } else {
      setEmailSubject('');
    }
    const hasTextDraft = tokenUtils.readLocalStorage(`orphan-text-${orphanId}`);
    setTextDraft(!!hasTextDraft);
    if (hasTextDraft) {
      setTextContent(
        tokenUtils.readLocalStorage(`orphan-text-${orphanId}-content`) || '',
      );
    } else {
      setTextContent('');
    }
  }, [orphanId]);
  const [tryArchiveUnarchive] = useMutation<
    OrphanArchiveUnarchiveAdminMutation,
    OrphanArchiveUnarchiveAdminMutationVariables
  >(orphanArchiveUnarchiveAdminMutation);
  function doArchiveUnarchive(doArchive: boolean) {
    if (!orphanDetails) return;
    tryArchiveUnarchive({
      optimisticResponse: {
        orphanArchiveUnarchive: {
          __typename: 'Orphan',
          id: orphanId,
          isArchivedByExpert: doArchive,
        },
      },
      variables: {
        doArchive,
        orphanId,
      },
    })
      .then(() => {
        onLoadData({
          ...orphanDetails,
          isArchivedByExpert: doArchive,
        });
      })
      .catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Archive/Unarchive Error',
        );
        logError(err, {
          component: 'AdminInboundDetail',
          func: 'doArchiveUnarchive',
        });
      });
  }
  const [trySendEmailMessage] = useMutation<
    OrphanThreadSendEmailMessageAdminMutation,
    OrphanThreadSendEmailMessageAdminMutationVariables
  >(orphanThreadSendEmailMessageAdminMutation);
  function onSendEmail(drafHtml: string) {
    if (sendLoading) return;
    if (!emailSubject.trim() || !emailRecipients.length || !drafHtml) {
      addNotification(
        'You need a subject, recipients, and an email body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    console.log('sendEmailMessage', emailSubject, drafHtml);
    trySendEmailMessage({
      optimisticResponse: {
        threadSendEmailMessage: {
          __typename: 'EmailMessageEvent',
          content: drafHtml,
          contentExpanded: null,
          createdAt: Date.now(),
          files: emailFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempEmailMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: null,
            orphanStr: orphanId,
            supportChannelStr: null,
          })),
          id: 'tempEmailMessageEventId',
          isTransactional: false,
          matchStr: null,
          noExpertRecipientsFound: [],
          orphanStr: orphanId,
          ownerExpert: {
            __typename: 'Expert',
            firstName: 'Storetasker',
            id: SUPPORT_EXPERT_ID,
            lastName: 'Support',
            photo: '',
          },
          ownerHuman: null,
          quote: null,
          recipientCopiedExperts: [],
          recipientExpert: null,
          recipientHumans: emailRecipients,
          sendingError: null,
          sendingStatus: 'SENT',
          subject: emailSubject,
          supportChannelStr: null,
          threadEvent: 'tempEmailMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentEmailMutation }) => {
        if (
          dataSentEmailMutation &&
          dataSentEmailMutation.threadSendEmailMessage
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempEmailMessageThreadEventId' &&
              e.id !== dataSentEmailMutation.threadSendEmailMessage.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataSentEmailMutation.threadSendEmailMessage.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor: dataSentEmailMutation.threadSendEmailMessage.createdAt,
              id: dataSentEmailMutation.threadSendEmailMessage.threadEvent,
              node: dataSentEmailMutation.threadSendEmailMessage,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          files: emailFiles,
          html: drafHtml,
          orphanId,
          recipientHumans: emailRecipients.map((r) => r.id),
          subject: emailSubject,
        },
      },
    })
      .then(() => {
        onDeleteEmailDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempEmailMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Send Email Error');
        logError(err, {
          component: 'AdminInboundDetail',
          func: 'sendEmailMessage',
        });
      });
  }
  const [trySendTextMessage] = useMutation<
    OrphanThreadSendTextMessageAdminMutation,
    OrphanThreadSendTextMessageAdminMutationVariables
  >(orphanThreadSendTextMessageAdminMutation);
  function onSendText() {
    if (sendLoading) return;
    if (!textRecipients.length || !textContent) {
      addNotification(
        'You need recipients and a message body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    trySendTextMessage({
      optimisticResponse: {
        threadSendTextMessage: {
          __typename: 'TextMessageEvent',
          content: textContent,
          createdAt: Date.now(),
          files: textFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempTextMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: null,
            orphanStr: orphanId,
            supportChannelStr: null,
          })),
          id: 'tempTextMessageEventId',
          isTransactional: false,
          matchStr: null,
          orphanStr: orphanId,
          ownerExpert: {
            __typename: 'Expert',
            firstName: 'Storetasker',
            id: SUPPORT_EXPERT_ID,
            lastName: 'Support',
            photo: '',
          },
          ownerHuman: null,
          quote: null,
          recipientExpert: null,
          recipientHumans: textRecipients,
          sendingError: null,
          supportChannelStr: null,
          threadEvent: 'tempTextMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentTextMutation }) => {
        if (
          dataSentTextMutation &&
          dataSentTextMutation.threadSendTextMessage
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempTextMessageThreadEventId' &&
              e.id !== dataSentTextMutation.threadSendTextMessage.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id === dataSentTextMutation.threadSendTextMessage.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor: dataSentTextMutation.threadSendTextMessage.createdAt,
              id: dataSentTextMutation.threadSendTextMessage.threadEvent,
              node: dataSentTextMutation.threadSendTextMessage,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          content: textContent,
          files: textFiles,
          orphanId,
          recipientHuman: textRecipients[0].id,
        },
      },
    })
      .then(() => {
        onDeleteTextDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempTextMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Send Text Error');
        logError(err, {
          component: 'AdminInboundDetail',
          func: 'sendTextMessage',
        });
      });
  }
  const [tryMeetingResponse] = useMutation<
    MeetingInviteResponseOrphanAdminMutation,
    MeetingInviteResponseOrphanAdminMutationVariables
  >(meetingInviteResponseOrphanAdminMutation);
  function tryInviteResponse(
    meeting: Extract<
      OrphanThreadPaginatedAdminQuery['threadPaginated']['edges'][0]['node'],
      { __typename?: 'MeetingEvent' | undefined }
    >['meeting'],
    response: string,
  ) {
    tryMeetingResponse({
      optimisticResponse: {
        meetingInviteResponseByAdmin: {
          __typename: 'MeetingEvent',
          action: null,
          createdAt: Date.now(),
          id: 'tempMeetingEventId',
          matchStr: null,
          meeting: {
            ...meeting,
            expertResponse: response,
          },
          orphanStr: orphanId,
          ownerExpert: {
            __typename: 'Expert',
            firstName: 'Storetasker',
            id: SUPPORT_EXPERT_ID,
            lastName: 'Support',
            photo: '',
          },
          ownerHuman: null,
          recipientExpert: null,
          recipientHumans: [
            {
              __typename: 'Human',
              firstName: '',
              id:
                (meeting.organizerHuman && meeting.organizerHuman.id) ||
                'tempMeetingEventHumanId',
              lastName: '',
              primaryEmail: '',
              primaryPhone: '',
            },
          ],
          response,
          supportChannelStr: null,
          threadEvent: 'tempMeetingThreadEventId',
        },
      },
      update: (_cache, { data: dataMeetingResponseMutation }) => {
        if (
          dataMeetingResponseMutation &&
          dataMeetingResponseMutation.meetingInviteResponseByAdmin
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempMeetingThreadEventId' &&
              e.id !==
                dataMeetingResponseMutation.meetingInviteResponseByAdmin
                  .threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataMeetingResponseMutation.meetingInviteResponseByAdmin
                  .threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor:
                dataMeetingResponseMutation.meetingInviteResponseByAdmin
                  .createdAt,
              id: dataMeetingResponseMutation.meetingInviteResponseByAdmin
                .threadEvent,
              node: dataMeetingResponseMutation.meetingInviteResponseByAdmin,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        meetingId: meeting.id,
        response,
      },
    }).catch((err: ApolloError) => {
      setTempEdges(
        tempEdges.filter((e) => e.id !== 'tempMeetingThreadEventId'),
      );
      addNotification(
        errorUtils.getErrorMessage(err) || 'Meeting Response Error',
      );
      logError(err, {
        component: 'AdminInboundDetail',
        func: 'tryMeetingResponse',
      });
    });
  }
  function onReplyClick(
    edge: OrphanThreadPaginatedAdminQuery['threadPaginated']['edges'][0],
  ) {
    console.log('onReplyClick', edge.id);
    if (edge.node.__typename === 'EmailMessageEvent') {
      const newSubject = replyToSubject(edge.node.subject);
      openEmailEditor(newSubject);
    } else if (edge.node.__typename === 'MeetingEvent') {
      const newSubject = replyToSubject(
        edge.node.meeting && edge.node.meeting.eventTitle,
      );
      openEmailEditor(newSubject);
    } else if (edge.node.__typename === 'TextMessageEvent') {
      openTextEditor();
    } else if (edge.node.__typename === 'PhoneCallEvent') {
      openTextEditor();
    } else if (edge.node.__typename === 'MessageEvent') {
      openEmailEditor();
    } else {
      scrollDown();
    }
  }
  if (loadingOrphan && loadingThread) {
    // ignore these
  }
  function groupThreadEvents(
    events: OrphanThreadPaginatedAdminQuery['threadPaginated']['edges'],
  ) {
    const groupedEvents: IGroupedEvents[] = [];
    events.forEach((ev) => {
      let isSameEventType = false;
      let isSameSenderSide = false;
      let isSameHuman = false;
      let isCurrGroupable = false;
      let isLastGroupable = false;
      const isFirstGroup = !groupedEvents.length;
      if (
        !isFirstGroup &&
        (ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'MessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent')
      ) {
        isCurrGroupable = true;
        isLastGroupable = true;
        const lastGroup = groupedEvents[groupedEvents.length - 1];
        const lastGroupEvent = lastGroup.group[0];
        // is same event type as last
        if (lastGroupEvent.node.__typename === ev.node.__typename) {
          isSameEventType = true;
          // is both sent from expert or both from Human
          isSameSenderSide =
            !!(ev.node.ownerHuman && lastGroupEvent.node.ownerHuman) ||
            !!(ev.node.ownerExpert && lastGroupEvent.node.ownerExpert);
          // is human interacting same
          isSameHuman =
            (ev.node.ownerHuman &&
              lastGroupEvent.node.ownerHuman &&
              ev.node.ownerHuman.id === lastGroupEvent.node.ownerHuman.id) ||
            (ev.node.recipientHumans[0] &&
              lastGroupEvent.node.recipientHumans[0] &&
              ev.node.recipientHumans[0].id ===
                lastGroupEvent.node.recipientHumans[0].id);
          // don't group texts with quotes attached
          if (ev.node.__typename === 'TextMessageEvent') {
            if (ev.node.quote) {
              isCurrGroupable = false;
            }
          }
          if (lastGroupEvent.node.__typename === 'TextMessageEvent') {
            if (lastGroupEvent.node.quote) {
              isLastGroupable = false;
            }
          }
        }
      }
      if (
        isFirstGroup ||
        !isCurrGroupable ||
        !isLastGroupable ||
        !isSameEventType ||
        !isSameSenderSide ||
        !isSameHuman
      ) {
        groupedEvents.push({
          group: [ev],
        });
      } else {
        groupedEvents[groupedEvents.length - 1].group.push(ev);
      }
    });
    return groupedEvents;
  }
  const threadEventEdges = _.uniqBy(cleanThread.concat(tempEdges), 'id').sort(
    (a, b) => a.cursor - b.cursor,
  );
  const overPageLimit = threadEventEdges.length >= PAGE_LIMIT;
  function scrollDown() {
    scrollDownQuickTimeout = setTimeout(() => {
      if (threadEventsWindow && threadEventsWindow.current) {
        threadEventsWindow.current.scrollTop =
          threadEventsWindow.current.scrollHeight;
      }
    }, 5);
  }
  useEffect(() => {
    function onScroll() {
      if (threadEventsWindow && threadEventsWindow.current) {
        const scrollTemp = threadEventsWindow.current.scrollTop;
        if (
          scrollTemp < 500 &&
          overPageLimit &&
          canLoadMore &&
          !isLoadingEvents
        ) {
          isLoadingEvents = true;
          const heightTemp = threadEventsWindow.current.scrollHeight;
          fetchMoreThread({
            updateQuery: (prev, { fetchMoreResult }) => {
              if (
                !fetchMoreResult ||
                !prev.threadPaginated ||
                !fetchMoreResult.threadPaginated
              )
                return prev;
              if (fetchMoreResult.threadPaginated.edges.length < PAGE_LIMIT) {
                setCanLoadMore(false);
              }
              return {
                threadPaginated: {
                  __typename: prev.threadPaginated.__typename,
                  edges: _.uniqBy(
                    fetchMoreResult.threadPaginated.edges.concat(
                      prev.threadPaginated.edges || [],
                    ),
                    'id',
                  ),
                  id: prev.threadPaginated.id,
                },
              };
            },
            variables: {
              direction: 'before',
              fromDate: threadEventEdges.filter(
                (ev) => ev.node.id && remoteEventIds.indexOf(ev.node.id) === -1,
              )[0].cursor,
              limit: PAGE_LIMIT,
              orphanId,
            },
          })
            .catch((err: ApolloError) => {
              addNotification(
                errorUtils.getErrorMessage(err) || 'Load More Error',
              );
              logError(err, {
                component: 'AdminInboundDetail',
                func: 'fetchMoreThread',
              });
            })
            .finally(() => {
              isLoadingEvents = false;
              if (threadEventsWindow && threadEventsWindow.current) {
                threadEventsWindow.current.scrollTop =
                  Math.max(
                    threadEventsWindow.current.scrollHeight - heightTemp,
                    0,
                  ) + scrollTemp;
              }
            });
        }
      }
    }
    if (threadEventsWindow && threadEventsWindow.current) {
      threadEventsWindow.current.addEventListener('scroll', onScroll);
    }
    const tempWindow = threadEventsWindow.current;
    return () => {
      if (tempWindow) {
        tempWindow.removeEventListener('scroll', onScroll);
      }
    };
  }, [
    canLoadMore,
    overPageLimit,
    addNotification,
    fetchMoreThread,
    orphanId,
    threadEventEdges,
    remoteEventIds,
  ]);
  useEffect(() => {
    scrollDown();
    const scrollDownEffectTimeout = setTimeout(() => {
      scrollDown();
    }, 500);
    return () => {
      if (scrollDownEffectTimeout) {
        clearTimeout(scrollDownEffectTimeout);
      }
      if (scrollDownQuickTimeout) {
        clearTimeout(scrollDownQuickTimeout);
      }
    };
  }, [orphanId]);
  useEffect(() => {
    const loadTime = new Date();
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminInboundDetail socketClient onReconnected');
      refetchOrphan().catch(() => {});
      fetchMoreThread({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !prev.threadPaginated ||
            !fetchMoreResult.threadPaginated
          )
            return prev;
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: _.uniqBy(
                fetchMoreResult.threadPaginated.edges.concat(
                  prev.threadPaginated.edges || [],
                ),
                'id',
              ),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          direction: 'after',
          fromDate: loadTime.getTime(),
          limit: 50,
          orphanId,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminInboundDetail',
          func: 'reconnect fetchMoreThread',
        });
      });
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchOrphan, fetchMoreThread, addNotification, orphanId]);
  const threadEventGroups = groupThreadEvents(threadEventEdges);
  function generateComponentsForGroup(
    groupedEvents: OrphanThreadPaginatedAdminQuery['threadPaginated']['edges'],
  ) {
    return groupedEvents
      .map((ev, i) => {
        if (
          ev.node.__typename === 'RequestEvent' ||
          ev.node.__typename === 'Quote'
        ) {
          return <div key={ev.id} />;
        }
        if (ev.node.__typename === 'MessageEvent') {
          return (
            <MessageThreadEvent
              key={ev.id}
              expertId={SUPPORT_EXPERT_ID}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              messageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        if (ev.node.__typename === 'EmailMessageEvent') {
          return (
            <EmailMessageThreadEvent
              key={ev.id}
              expertId={SUPPORT_EXPERT_ID}
              emailMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
              isAdmin
            />
          );
        }
        if (ev.node.__typename === 'TextMessageEvent') {
          return (
            <TextMessageThreadEvent
              key={ev.id}
              expertId={SUPPORT_EXPERT_ID}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              textMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        if (ev.node.__typename === 'PhoneCallEvent') {
          return (
            <PhoneCallThreadEvent
              key={ev.id}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              phoneCallEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        if (ev.node.__typename === 'MeetingEvent') {
          return (
            <MeetingThreadEvent
              key={ev.id}
              meetingEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
              onInviteResponse={tryInviteResponse}
            />
          );
        }
        console.log('Missing ThreadEvent type');
        return null;
      })
      .filter((c) => c);
  }
  function closeCurrentAction() {
    setCurrentAction('');
    scrollDown();
  }
  const [tryAssignAdmin] = useMutation<
    OrphanAssignAdminMutation,
    OrphanAssignAdminMutationVariables
  >(orphanAssignAdminMutation);
  function onAssignAdmin(theAssignment: string) {
    if (actionLoading || !orphanId) return;
    setActionLoading(true);
    tryAssignAdmin({
      variables: {
        adminAssignment: theAssignment,
        orphanId,
      },
    })
      .then(() => {
        setActionLoading(false);
        setCurrentAction('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Assign Admin Error',
        );
        logError(err, {
          component: 'AdminInboundDetail',
          func: 'tryAssignAdmin',
        });
      });
  }
  function openAdminAssignmentEditor() {
    // check currentAction
    if (currentAction) {
      scrollDown();
      return;
    }
    setCurrentAction('ASSIGN-ADMIN');
    setOpenActions(false);
    scrollDown();
  }
  function openOrphanClientEditor() {
    // check currentAction
    if (currentAction) {
      scrollDown();
      return;
    }
    setCurrentAction('ORPHAN-CLIENT');
    setOpenActions(false);
    scrollDown();
  }
  function openEmailEditor(subject?: string, content?: string) {
    // check currentAction
    if (currentAction || !orphanDetails) {
      scrollDown();
      return;
    }
    // check draft
    if (emailDraft) {
      subject =
        tokenUtils.readLocalStorage(`orphan-email-${orphanId}-subject`) || '';
      content =
        tokenUtils.readLocalStorage(`orphan-email-${orphanId}-content`) || '';
    }
    if (subject === undefined && content === undefined) {
      const lastEmail = _.findLast(
        threadEventEdges,
        (ev) => ev.node.__typename === 'EmailMessageEvent',
      );
      // look for the latest email
      if (lastEmail) {
        // simulate as if they clicked that email
        onReplyClick(lastEmail);
        return;
      }
    }
    setCurrentAction('EMAIL');
    setOpenActions(false);
    scrollDown();
    setEmailSubject(subject || '');
    setEmailContent(content || '');
    setEmailRecipients(orphanDetails.humans);
    setEmailFiles([]);
    setEmailStatus(emailDraft ? 'Saved' : '');
  }
  const debouncedEmailDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(`orphan-email-${orphanId}`, 'true');
    tokenUtils.writeLocalStorage(
      `orphan-email-${orphanId}-subject`,
      emailSubject,
    );
    tokenUtils.writeLocalStorage(
      `orphan-email-${orphanId}-content`,
      emailContent,
    );
    setEmailDraft(true);
    setEmailStatus('Saved');
  }, 1000);
  function onDeleteEmailDraft() {
    debouncedEmailDraft.cancel();
    tokenUtils.removeLocalStorage(`orphan-email-${orphanId}`);
    tokenUtils.removeLocalStorage(`orphan-email-${orphanId}-subject`);
    tokenUtils.removeLocalStorage(`orphan-email-${orphanId}-content`);
    setEmailDraft(false);
    setEmailStatus('');
    setEmailSubject('');
    setEmailContent('');
    setEmailRecipients([]);
    setEmailFiles([]);
    setCurrentAction('');
  }
  function onSaveEmailDraft(
    subject: string,
    draftContent: string | null,
    files: IFilestackFileUpload[],
  ) {
    let saveDraft = false;
    if (emailSubject !== subject) {
      saveDraft = true;
      setEmailSubject(subject || '');
    }
    if (emailFiles !== files) {
      setEmailFiles(files || []);
    }
    if (draftContent !== null && emailContent !== draftContent) {
      saveDraft = true;
      setEmailContent(draftContent);
    }
    if (saveDraft) {
      setEmailStatus('Saving');
      debouncedEmailDraft.callback();
    }
  }
  function openTextEditor(content?: string) {
    // check currentAction
    if (currentAction || !orphanDetails) {
      scrollDown();
      return;
    }
    // check draft
    if (textDraft) {
      content =
        tokenUtils.readLocalStorage(`orphan-text-${orphanId}-content`) || '';
    }
    setCurrentAction('TEXT');
    setOpenActions(false);
    scrollDown();
    setTextContent(content || '');
    setTextRecipients(orphanDetails.humans);
    setTextFiles([]);
    setTextStatus(textDraft ? 'Saved' : '');
  }
  const debouncedTextDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(`orphan-text-${orphanId}`, 'true');
    tokenUtils.writeLocalStorage(
      `orphan-text-${orphanId}-content`,
      textContent,
    );
    setTextDraft(true);
    setTextStatus('Saved');
  }, 1000);
  function onDeleteTextDraft() {
    debouncedTextDraft.cancel();
    tokenUtils.removeLocalStorage(`orphan-text-${orphanId}`);
    tokenUtils.removeLocalStorage(`orphan-text-${orphanId}-content`);
    setTextDraft(false);
    setTextStatus('');
    setTextContent('');
    setTextRecipients([]);
    setTextFiles([]);
    setCurrentAction('');
  }
  function onSaveTextDraft(content: string, files: IFilestackFileUpload[]) {
    let saveDraft = false;
    if (textFiles !== files) {
      setTextFiles(files || []);
    }
    if (textContent !== content) {
      saveDraft = true;
      setTextContent(content);
    }
    if (saveDraft) {
      setTextStatus('Saving');
      debouncedTextDraft.callback();
    }
  }
  const [tryThreadBrand] = useMutation<
    OrphanThreadIntoBrandAdminMutation,
    OrphanThreadIntoBrandAdminMutationVariables
  >(orphanThreadIntoBrandAdminMutation);
  function onSelectBrand(brandId: string) {
    if (actionLoading || threadSuccess || !dataThread) return;
    setActionLoading(true);
    tryThreadBrand({
      update: (cache) => {
        cache.writeFragment({
          data: {
            ...dataThread.threadPaginated,
            edges: [],
          },
          fragment: gql`
            fragment ThreadBrand on Thread {
              id
              edges {
                id
                cursor
                node {
                  ... on TextMessageEvent {
                    ...TextMessageEventDetail
                  }
                  ... on EmailMessageEvent {
                    ...EmailMessageEventDetail
                  }
                  ... on RequestEvent {
                    ...RequestEventDetail
                  }
                  ... on MessageEvent {
                    ...MessageEventDetail
                  }
                  ... on MeetingEvent {
                    ...MeetingEventDetail
                  }
                  ... on Quote {
                    ...QuoteDetail
                  }
                  ... on PhoneCallEvent {
                    ...PhoneCallEventDetail
                  }
                }
              }
            }
            ${TextMessageEventDetail}
            ${EmailMessageEventDetail}
            ${RequestEventDetail}
            ${MessageEventDetail}
            ${MeetingEventDetail}
            ${QuoteDetail}
            ${PhoneCallEventDetail}
          `,
          fragmentName: 'ThreadBrand',
          id: '',
        });
      },
      variables: {
        brandId,
        orphanId,
      },
    })
      .then(({ data: mutationData }) => {
        setThreadSuccess(true);
        setActionLoading(false);
        if (mutationData && mutationData.orphanThreadIntoBrand) {
          history.push('/brands/' + brandId);
        } else {
          setThreadSuccess(false);
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Merge Thread Error',
        );
        logError(err, {
          component: 'AdminOrphanClientEditor',
          func: 'tryThreadBrand',
        });
      });
  }
  // everyone has an email set
  const showEmailActions = !!(
    orphanDetails &&
    orphanDetails.humans &&
    !orphanDetails.humans.find((h) => !h.primaryEmail)
  );
  // only one person on thread and they have a phone set
  const showPhoneActions = !!(
    orphanDetails &&
    orphanDetails.humans &&
    orphanDetails.humans.length === 1 &&
    orphanDetails.humans[0].primaryPhone
  );
  function renderCurrentActionEditor() {
    if (currentAction === 'EMAIL') {
      return (
        <div
          className={
            'ThreadActionEmail ' +
            (sendLoading ? ' ThreadActionEmailSending ' : '')
          }
        >
          <EmailEditor
            emailTemplates={emailTemplates}
            expertId={SUPPORT_EXPERT_ID}
            files={emailFiles}
            fixedRecipients={emailRecipients}
            initContent={emailContent}
            isAdmin
            onDeleteDraft={onDeleteEmailDraft}
            onSaveDraft={onSaveEmailDraft}
            onSendEmail={onSendEmail}
            recipients={[]}
            subject={emailSubject}
            status={emailStatus}
          />
        </div>
      );
    }
    if (currentAction === 'TEXT') {
      return (
        <div
          className={
            'ThreadActionText ' +
            (sendLoading ? ' ThreadActionTextSending ' : '')
          }
        >
          <TextEditor
            content={textContent}
            expertId={SUPPORT_EXPERT_ID}
            files={textFiles}
            fixedRecipients={textRecipients}
            onDeleteDraft={onDeleteTextDraft}
            onSaveDraft={onSaveTextDraft}
            onSendText={onSendText}
            recipients={[]}
            status={textStatus}
          />
        </div>
      );
    }
    if (
      currentAction === 'ORPHAN-CLIENT' &&
      orphanDetails &&
      orphanDetails.humans
    ) {
      return (
        <div className="ThreadActionOrphanClient">
          <AdminOrphanClientEditor
            humans={orphanDetails.humans}
            onCancel={() => setCurrentAction('')}
            onSelectBrand={onSelectBrand}
            isActionLoading={actionLoading}
          />
        </div>
      );
    }
    if (currentAction === 'ASSIGN-ADMIN' && orphanDetails) {
      return (
        <div className="ThreadActionOrphanClient">
          <div className="ThreadEditor OrphanClientEditor AdminOrphanClientEditor">
            <div className="ThreadEditorTop">
              <div className="ThreadEditorTopTitle">Assign Admin</div>
              {!actionLoading && (
                <div
                  className="ThreadEditorTopAction"
                  onClick={() => setCurrentAction('')}
                >
                  Cancel
                </div>
              )}
            </div>
            <div className="ThreadEditorCardWrapper">
              <div className="ThreadEditorCard">
                <div className="ThreadEditorOrphan">
                  {actionLoading ? (
                    <div className="ThreadEditorOrphanLoading" />
                  ) : (
                    <div className="ThreadEditorOrphanSection">
                      <div className="ThreadEditorOrphanSectionTitle">
                        Assign inbound thread to an admin?
                      </div>
                      <select
                        className="ThreadDetailSidebarAdminActionSelect"
                        value={adminAssignment}
                        onChange={(e) => onAssignAdmin(e.currentTarget.value)}
                      >
                        <option value="" disabled>
                          Assign team member
                        </option>
                        <option value="Richard">Richard</option>
                        <option value="Tim">Tim</option>
                        <option value="Robin">Robin</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
  return (
    <div className="ExpertInboundDetail AdminInboundDetail">
      <div className="ThreadDetailMainBody" ref={threadEventsWindow}>
        <div
          className={
            'ThreadEvents ' + (currentAction ? ' ThreadEventsHasAction ' : '')
          }
        >
          {threadEventGroups.map((evGroup) => (
            <div className="ThreadEventGroup" key={evGroup.group[0].id}>
              {generateComponentsForGroup(evGroup.group)}
            </div>
          ))}
        </div>
        {!!currentAction && !!orphanDetails && (
          <div className="ThreadCurrentAction">
            {renderCurrentActionEditor()}
          </div>
        )}
      </div>
      <div
        className={
          'ThreadDetailActions ' +
          (openActions ? ' ThreadDetailActionsOpen ' : '') +
          (currentAction ? ' ThreadDetailActionsCurrent ' : '')
        }
      >
        <div className="ThreadDetailActionRow">
          {!!showPhoneActions && (
            <div
              className="ThreadDetailActionItem"
              onClick={() => openTextEditor()}
            >
              <span>send a </span>text
            </div>
          )}
          {!!showEmailActions && (
            <div
              className="ThreadDetailActionItem"
              onClick={() => openEmailEditor()}
            >
              <span>send an </span>email
            </div>
          )}
          <div
            className="ThreadDetailActionItem"
            onClick={() =>
              orphanDetails && orphanDetails.adminAssigned
                ? onAssignAdmin('')
                : openAdminAssignmentEditor()
            }
          >
            {orphanDetails && orphanDetails.adminAssigned
              ? 'un-assign'
              : 'assign'}
          </div>
        </div>
        <div className="ThreadDetailActionRow">
          <div
            className="ThreadDetailActionTrigger"
            onClick={() =>
              currentAction
                ? closeCurrentAction()
                : setOpenActions(!openActions)
            }
          >
            <div className="ThreadDetailActionBtn">+</div>
            <div className="ThreadDetailActionText">actions</div>
          </div>
          <div
            className="ThreadDetailActionItem"
            onClick={() => openOrphanClientEditor()}
          >
            associate<span> with brand</span>
          </div>
          {!!orphanDetails && (
            <div
              className="ThreadDetailActionItem"
              onClick={() =>
                doArchiveUnarchive(!orphanDetails.isArchivedByExpert)
              }
            >
              {orphanDetails.isArchivedByExpert ? 'unarchive' : 'archive'}
              <span> thread</span>
            </div>
          )}
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <Link to="/inbound" className="DashboardErrorCoverNav">
              back
            </Link>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminInboundDetail.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  onLoadData: PropTypes.func.isRequired,
  orphanId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default AdminInboundDetail;
