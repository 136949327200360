import React, { useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useMutation, ApolloError, gql } from '@apollo/client';
import logError from '../../utils/airbrake';
import oauth from '../../utils/oauth';
import errorUtils from '../../utils/error';
import urlUtils from '../../utils/url';
import tokenUtils from '../../utils/token';
import '../../styles/page/Login.scss';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import {
  AuthExpertEmailRequestMutation,
  AuthExpertEmailRequestMutationVariables,
  AuthExpertEmailResult,
  AuthExpertEmailVerifyMutation,
  AuthExpertEmailVerifyMutationVariables,
} from '../../gql/graphql';
import logoWhite from '../../images/storetasker-logo-white-with.png';

const initEmailParam = urlUtils.getQueryParam('email') || '';
const hasCookie = tokenUtils.read('cookie-ok') || '';

const authExpertEmailRequestMutation = gql`
  mutation AuthExpertEmailRequest($email: String!) {
    authExpertEmailRequest(email: $email) {
      result
    }
  }
`;

const authExpertEmailVerifyMutation = gql`
  mutation AuthExpertEmailVerify($email: String!, $verificationCode: String!) {
    authExpertEmailVerify(email: $email, verificationCode: $verificationCode) {
      result
      userWithAuth {
        id
        token
        user {
          ... on Expert {
            id
          }
        }
      }
    }
  }
`;

interface ExpertLoginProps {
  refetchTopQuery: () => void;
}

const ExpertLogin = ({ refetchTopQuery }: ExpertLoginProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [loginState, setLoginState] = useState('login');
  const [email, setEmail] = useState(initEmailParam);
  const [verificationCode, setVerificationCode] = useState('');
  const [termsCheck, setTermsCheck] = useState(false);
  const [cookieCheck, setCookieCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tryLogin] = useMutation<
    AuthExpertEmailRequestMutation,
    AuthExpertEmailRequestMutationVariables
  >(authExpertEmailRequestMutation, {
    variables: {
      email: email.trim(),
    },
  });
  const [tryVerify] = useMutation<
    AuthExpertEmailVerifyMutation,
    AuthExpertEmailVerifyMutationVariables
  >(authExpertEmailVerifyMutation, {
    variables: {
      email: email.trim(),
      verificationCode: verificationCode.trim(),
    },
  });

  function attemptLogin() {
    console.log('attemptLogin');
    if (loading) return;
    if (!email.trim()) {
      addNotification('Please enter your email.', undefined, 5000);
      return;
    }
    tokenUtils.write('cookie-ok', 'true');
    setLoading(true);
    tryLogin()
      .then(({ data }) => {
        setLoading(false);
        if (
          data &&
          data.authExpertEmailRequest &&
          data.authExpertEmailRequest.result
        ) {
          if (
            data.authExpertEmailRequest.result ===
            AuthExpertEmailResult.SentCode
          ) {
            setLoginState('verify');
          } else if (
            data.authExpertEmailRequest.result ===
            AuthExpertEmailResult.Inactive
          ) {
            addNotification(
              'Your account is inactive. Please contact Richard to discuss.',
            );
          } else if (
            data.authExpertEmailRequest.result ===
            AuthExpertEmailResult.EmailInvalid
          ) {
            addNotification(
              'This email looks incorrect. Please check your login info and try again.',
            );
          } else if (
            data.authExpertEmailRequest.result ===
            AuthExpertEmailResult.NeedToSignup
          ) {
            addNotification(
              "Sorry! We couldn't find an existing account with that login information. Please try again.",
            );
          } else if (
            data.authExpertEmailRequest.result === AuthExpertEmailResult.Pending
          ) {
            addNotification(
              'Your account is still pending. Please contact Richard to get started.',
            );
          } else {
            addNotification(
              'Error! Please check your login info and submit again.',
            );
          }
        } else {
          addNotification(
            'An error occured, please contact support@storetasker.com',
          );
          logError('Mutation missing result authExpertEmailRequest', {
            component: 'ExpertLogin',
            func: 'authExpertEmailRequest',
          });
        }
      })
      .catch((err: ApolloError) => {
        setLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Login Error');
        logError(err, {
          component: 'ExpertLogin',
          func: 'attemptLogin',
        });
      });
  }

  function attemptVerify() {
    console.log('attemptVerify');
    if (loading) return;
    if (verificationCode.trim().length !== 6) {
      addNotification(
        'Invalid verification code. Please check your email and try again.',
        undefined,
        5000,
      );
      return;
    }
    setLoading(true);
    tryVerify()
      .then(({ data }) => {
        if (
          data &&
          data.authExpertEmailVerify &&
          data.authExpertEmailVerify.result
        ) {
          if (
            data.authExpertEmailVerify.result ===
              AuthExpertEmailResult.GoodLogin &&
            data.authExpertEmailVerify.userWithAuth &&
            data.authExpertEmailVerify.userWithAuth.token &&
            data.authExpertEmailVerify.userWithAuth.user &&
            data.authExpertEmailVerify.userWithAuth.user.__typename ===
              'Expert' &&
            data.authExpertEmailVerify.userWithAuth.user.id
          ) {
            oauth.writeToken(
              data.authExpertEmailVerify.userWithAuth.token,
              'expert',
              data.authExpertEmailVerify.userWithAuth.user.id,
            );
            refetchTopQuery();
            // setLoading(false);
          } else {
            setLoading(false);
            if (
              data.authExpertEmailVerify.result ===
              AuthExpertEmailResult.Inactive
            ) {
              addNotification(
                'Your account is inactive. Please contact Richard to discuss.',
              );
            } else if (
              data.authExpertEmailVerify.result ===
              AuthExpertEmailResult.EmailInvalid
            ) {
              addNotification(
                'This email looks incorrect. Please check your login info and try again.',
              );
            } else if (
              data.authExpertEmailVerify.result ===
              AuthExpertEmailResult.NeedToSignup
            ) {
              addNotification(
                "Sorry! We couldn't find an existing account with that login information. Please try again.",
              );
            } else if (
              data.authExpertEmailVerify.result ===
              AuthExpertEmailResult.Pending
            ) {
              addNotification(
                'Your account is still pending. Please contact Richard to get started.',
              );
            } else if (
              data.authExpertEmailVerify.result ===
              AuthExpertEmailResult.InvalidCode
            ) {
              // FUTURE give option to resend code
              addNotification('Invalid verification code. Please try again.');
            } else {
              addNotification('Error! Please try again.');
            }
          }
        } else {
          setLoading(false);
          addNotification(
            'An error occured, please contact support@storetasker.com',
          );
          logError('Mutation missing result authExpertEmailVerify', {
            component: 'ExpertLogin',
            func: 'authExpertEmailVerify',
          });
        }
      })
      .catch((err: ApolloError) => {
        setLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Verify Error');
        logError(err, {
          component: 'ExpertLogin',
          func: 'attemptVerify',
        });
      });
  }

  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string,
  ) {
    const newValue = e.target.value || '';
    if (inputName === 'email') {
      setEmail(newValue);
    } else if (inputName === 'verificationCode') {
      setVerificationCode(newValue);
    }
  }

  function attemptCTA() {
    if (!hasCookie && (!termsCheck || !cookieCheck)) {
      addNotification('Please confirm terms & privacy policy checkboxes');
    } else if (loginState === 'login') {
      attemptLogin();
    } else if (loginState === 'verify') {
      attemptVerify();
    }
  }

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      attemptCTA();
    }
  }

  return (
    <div className="Login ExpertLogin">
      <div className="LoginLogoWrap">
        <img className="LoginLogo" src={logoWhite} alt="Storetasker Logo" />
      </div>
      <div className="LoginHeader">
        <div className="LoginHeaderIntro">Welcome back!</div>
        <div className="LoginHeaderTitle">
          {loginState === 'login'
            ? 'Login to Your Expert Account'
            : 'Check Your Email To Verify'}
        </div>
      </div>
      <div className="LoginBody">
        {loginState === 'login' ? (
          <Fragment>
            <div className="LoginField">
              <div className="LoginFieldName">Email</div>
              <input
                type="email"
                className="LoginFieldInput"
                placeholder="email address"
                spellCheck="false"
                value={email}
                onChange={(e) => {
                  handleInputChange(e, 'email');
                }}
                onKeyPress={handleKeyPress}
              />
            </div>
            {!hasCookie && (
              <div className="LoginChecks">
                <div
                  className={
                    'LoginCheck ' + (termsCheck ? ' LoginCheckChecked ' : '')
                  }
                >
                  <div
                    className="LoginCheckBox"
                    onClick={() => setTermsCheck(!termsCheck)}
                  />
                  <div className="LoginCheckText">
                    You agree to our{' '}
                    <a
                      href="https://www.storetasker.com/terms-of-service"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms &amp; Conditions
                    </a>
                  </div>
                </div>
                <div
                  className={
                    'LoginCheck ' + (cookieCheck ? ' LoginCheckChecked ' : '')
                  }
                >
                  <div
                    className="LoginCheckBox"
                    onClick={() => setCookieCheck(!cookieCheck)}
                  />
                  <div className="LoginCheckText">
                    We use cookies per our{' '}
                    <a
                      href="https://www.storetasker.com/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="LoginField">
            <div className="LoginFieldName">Verification Code</div>
            <input
              type="text"
              placeholder="Verification Code"
              spellCheck="false"
              className="LoginFieldInput"
              value={verificationCode}
              onChange={(e) => {
                handleInputChange(e, 'verificationCode');
              }}
              onKeyPress={handleKeyPress}
            />
          </div>
        )}
        <div
          className={'LoginBtn ' + (loading ? ' LoginBtnLoading ' : '')}
          onClick={attemptCTA}
        >
          {loginState === 'login' ? 'Log in' : 'Verify'}
        </div>
      </div>
    </div>
  );
};

ExpertLogin.propTypes = {
  refetchTopQuery: PropTypes.func.isRequired,
};

export default ExpertLogin;
