import React, { useState, useContext, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useQuery, useMutation, ApolloError, gql } from '@apollo/client';
import { NavLink, Link, useRouteMatch, Route } from 'react-router-dom';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import AdminInboundDetail from './AdminInboundDetail';
import { formatValidPhoneNumber } from '../../utils/format';
import { SUPPORT_EXPERT_ID } from '../../utils/constants';
import {
  TemplatesQuery,
  OrphanUnreadForAdminFullQuery,
  OrphanPaginateReadForAdminQuery,
  OrphanPaginateReadForAdminQueryVariables,
  OrphanDetailsQuery,
  OrphanMarkAsReadUnreadAdminMutation,
  OrphanMarkAsReadUnreadAdminMutationVariables,
  OrphanArchiveManyAdminMutation,
  OrphanArchiveManyAdminMutationVariables,
  OrphanSearchForAdminMutation,
  OrphanSearchForAdminMutationVariables,
} from '../../gql/graphql';
import { OrphanSummary } from '../../utils/gql';
import logo from '../../images/storetasker-logo-black.png';

const orphanUnreadForAdminQuery = gql`
  query OrphanUnreadForAdminFull {
    orphanUnreadForAdmin {
      ...OrphanSummary
    }
  }
  ${OrphanSummary}
`;

const orphanPaginateReadForAdminQuery = gql`
  query OrphanPaginateReadForAdmin(
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    orphanPaginateReadForAdmin(
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...OrphanSummary
    }
  }
  ${OrphanSummary}
`;

const orphanMarkAsReadUnreadAdminMutation = gql`
  mutation OrphanMarkAsReadUnreadAdmin($orphanId: ID!, $unreadReason: String) {
    orphanMarkAsReadUnread(orphanId: $orphanId, unreadReason: $unreadReason) {
      id
      expertUnread
    }
  }
`;

const orphanArchiveManyAdminMutation = gql`
  mutation OrphanArchiveManyAdmin($orphanIds: [ID!]!) {
    orphanArchiveMany(orphanIds: $orphanIds) {
      id
      expertUnread
      isArchivedByExpert
    }
  }
`;

const orphanSearchForAdminMutation = gql`
  mutation OrphanSearchForAdmin($searchQuery: String!) {
    orphanSearchForAdmin(searchQuery: $searchQuery) {
      ...OrphanSummary
    }
  }
  ${OrphanSummary}
`;

interface AdminInboundProps {
  emailTemplates: TemplatesQuery['templates'];
  socketClient: ClientWithOnReconnected;
}

const PAGE_LIMIT = 10;
const DESC_SUMMARY_LENGTH = 80;

const AdminInbound = ({ emailTemplates, socketClient }: AdminInboundProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [initDate] = useState(() => new Date());
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    OrphanSearchForAdminMutation['orphanSearchForAdmin'] | null
  >(null);
  const [selectedIds, setSelectedIds] = useState([] as string[]);
  console.log('selectedIds', selectedIds);
  const [childLoadData, setChildLoadData] = useState(
    [] as OrphanDetailsQuery['orphanDetails'][],
  );
  const {
    data: dataOrphanUnreads,
    error: errorOrphanUnreads,
    loading: loadingOrphanUnreads,
    refetch: refetchOrphanUnreads,
  } = useQuery<OrphanUnreadForAdminFullQuery>(orphanUnreadForAdminQuery, {
    returnPartialData: true,
  });
  const {
    data: dataOrphanPaginated,
    error: errorOrphanPaginated,
    loading: loadingOrphanPaginated,
    fetchMore: fetchMoreOrphanPaginated,
  } = useQuery<
    OrphanPaginateReadForAdminQuery,
    OrphanPaginateReadForAdminQueryVariables
  >(orphanPaginateReadForAdminQuery, {
    variables: {
      direction: 'BACKWARD',
      fromDate: initDate.getTime(),
      limit: PAGE_LIMIT,
    },
  });
  const allOrphansForAdmin:
    | OrphanUnreadForAdminFullQuery['orphanUnreadForAdmin']
    | OrphanPaginateReadForAdminQuery['orphanPaginateReadForAdmin'] = _.uniqBy(
    [
      ...((dataOrphanUnreads && dataOrphanUnreads.orphanUnreadForAdmin) || []),
      ...((dataOrphanPaginated &&
        dataOrphanPaginated.orphanPaginateReadForAdmin) ||
        []),
    ].filter((o) => !o.migratedToMatchStr && o.expertStr === SUPPORT_EXPERT_ID),
    'id',
  ).sort((a, b) => b.lastUpdated - a.lastUpdated);
  const allRead = allOrphansForAdmin.filter(
    (o) => !o.expertUnread && !o.adminAssigned,
  );
  const oldestLastUpdated = allRead[allRead.length - 1];
  function loadMore() {
    fetchMoreOrphanPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.orphanPaginateReadForAdmin)
          return prev;

        if (fetchMoreResult.orphanPaginateReadForAdmin.length < PAGE_LIMIT) {
          setShowLoadMore(false);
        }
        return {
          orphanPaginateReadForAdmin: (
            prev.orphanPaginateReadForAdmin || []
          ).concat(fetchMoreResult.orphanPaginateReadForAdmin),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate: oldestLastUpdated.lastUpdated,
        limit: PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'AdminInbound',
        func: 'loadMore',
      });
    });
  }
  useEffect(() => {
    const loadTime = new Date();
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminInbound socketClient onReconnected');
      refetchOrphanUnreads().catch(() => {});
      fetchMoreOrphanPaginated({
        updateQuery: (prev, { fetchMoreResult }) => {
          console.log('fetchMoreResult', fetchMoreResult);
          if (!fetchMoreResult) return prev;
          return {
            orphanPaginateReadForAdmin: (
              prev.orphanPaginateReadForAdmin || []
            ).concat(fetchMoreResult.orphanPaginateReadForAdmin),
          };
        },
        variables: {
          direction: 'FORWARD',
          fromDate: loadTime.getTime(),
          limit: 25,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminInbound',
          func: 'reconnect loadMore',
        });
      });
    });
    return () => {
      reconnectedListener();
    };
  }, [
    socketClient,
    refetchOrphanUnreads,
    fetchMoreOrphanPaginated,
    addNotification,
  ]);
  const [tryOrphanSearch] = useMutation<
    OrphanSearchForAdminMutation,
    OrphanSearchForAdminMutationVariables
  >(orphanSearchForAdminMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput) {
      cancelSearch();
      return;
    }
    if (searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchResults([]);
    tryOrphanSearch({
      update: (_cache, { data: dataOrphanSearch }) => {
        setSearchLoading(false);
        if (dataOrphanSearch && dataOrphanSearch.orphanSearchForAdmin) {
          console.log('tryOrphanSearch', dataOrphanSearch.orphanSearchForAdmin);
          setSearchResults(dataOrphanSearch.orphanSearchForAdmin);
        }
      },
      variables: {
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Inbound Search Error',
      );
      logError(err, {
        component: 'AdminInbound',
        func: 'tryOrphanSearch',
      });
      setSearchLoading(false);
      setSearchResults(null);
    });
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      cancelSearch();
    }
  }
  function cancelSearch() {
    setSearchInput('');
    setSearchResults(null);
    setSearchLoading(false);
  }
  const [tryMarkReadUnread] = useMutation<
    OrphanMarkAsReadUnreadAdminMutation,
    OrphanMarkAsReadUnreadAdminMutationVariables
  >(orphanMarkAsReadUnreadAdminMutation);
  const [tryArchiveMany] = useMutation<
    OrphanArchiveManyAdminMutation,
    OrphanArchiveManyAdminMutationVariables
  >(orphanArchiveManyAdminMutation);
  const withExtra:
    | OrphanUnreadForAdminFullQuery['orphanUnreadForAdmin']
    | OrphanPaginateReadForAdminQuery['orphanPaginateReadForAdmin']
    | OrphanDetailsQuery['orphanDetails'][] = _.uniqBy(
    [...allOrphansForAdmin, ...childLoadData].filter(
      (o) =>
        !o.migratedToMatchStr &&
        !o.isArchivedByExpert &&
        o.expertStr === SUPPORT_EXPERT_ID,
    ),
    'id',
  ).sort((a, b) => b.lastUpdated - a.lastUpdated);
  const withExtraUnread = withExtra.filter(
    (o) => o.expertUnread || o.adminAssigned,
  );
  const withExtraRead = withExtra.filter(
    (o) => !o.expertUnread && !o.adminAssigned,
  );
  const withExtraForAdmin = withExtraUnread.concat(withExtraRead);
  function archiveMany() {
    tryArchiveMany({
      optimisticResponse: {
        orphanArchiveMany: selectedIds.map((id) => ({
          __typename: 'Orphan',
          expertUnread: null,
          id,
          isArchivedByExpert: true,
        })),
      },
      variables: {
        orphanIds: selectedIds,
      },
    }).catch((err: ApolloError) => {
      logError(err, {
        component: 'AdminInbound',
        func: 'tryArchiveMany',
      });
    });
    setSelectedIds([]);
  }
  function onLoadChild(dataChild: OrphanDetailsQuery['orphanDetails']) {
    setChildLoadData(_.uniqBy([dataChild].concat(childLoadData), 'id'));
    if (dataChild.id && dataChild.expertUnread) {
      tryMarkReadUnread({
        optimisticResponse: {
          orphanMarkAsReadUnread: {
            __typename: 'Orphan',
            expertUnread: null,
            id: dataChild.id,
          },
        },
        variables: {
          orphanId: dataChild.id,
        },
      }).catch((err: ApolloError) => {
        // purposely ignore mark read error
        logError(err, {
          component: 'AdminInbound',
          func: 'tryMarkReadUnread',
        });
      });
    }
  }
  function renderInboundItem(
    o: OrphanUnreadForAdminFullQuery['orphanUnreadForAdmin'][0],
  ) {
    let desc = 'No description found';
    if (o.lastThreadEvent && o.lastThreadEvent.plainTextContent) {
      desc =
        o.lastThreadEvent.plainTextContent.slice(0, DESC_SUMMARY_LENGTH) +
        (o.lastThreadEvent.plainTextContent.length >= DESC_SUMMARY_LENGTH
          ? '...'
          : '');
    }
    const humanListStr =
      (o.humans || [])
        .slice(0, 3)
        .map(
          (h) =>
            (
              (h.firstName || '').trim() +
              ' ' +
              (h.lastName || '').trim()
            ).trim() ||
            h.primaryEmail ||
            formatValidPhoneNumber(h.primaryPhone) ||
            h.primaryPhone ||
            '',
        )
        .join(', ') + ((o.humans || []).length >= 3 ? '...' : '');
    return (
      <NavLink
        key={o.id}
        to={'/inbound/' + o.id}
        className={
          'InboundListItem ' +
          (o.adminAssigned ? ' InboundListItemExtended ' : '')
        }
      >
        <div className="InboundListItemTitle">{humanListStr}</div>
        {o.lastThreadEvent && o.lastThreadEvent.plainTextSubject && (
          <div className="InboundListItemSubtitle">
            {o.lastThreadEvent.plainTextSubject}
          </div>
        )}
        <div className="InboundListItemDesc">{desc}</div>
        {!!o.expertUnread && <div className="InboundListItemUnread" />}
        <div
          className="InboundListItemSelector"
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="checkbox"
            className="InboundListItemSelectorBox"
            checked={selectedIds.includes(o.id)}
            onChange={(e) => {
              e.stopPropagation();
              if (e.target.checked) {
                setSelectedIds([...selectedIds, o.id]);
              } else {
                setSelectedIds(selectedIds.filter((id) => id !== o.id));
              }
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        {!!o.adminAssigned && (
          <div
            className={
              'InboundListItemAssignedTo InboundListItemAssignedTo' +
              (o.adminAssigned || '').toUpperCase()
            }
          >
            {o.adminAssigned}
          </div>
        )}
      </NavLink>
    );
  }
  if (loadingOrphanUnreads || loadingOrphanPaginated) {
    // ignore these
  }
  const routeMatch = useRouteMatch('/inbound/:id');
  return (
    <div
      className={
        'DashboardScreen InboundScreen ' +
        (routeMatch ? ' InboundScreenWithDetail ' : '')
      }
    >
      <Link className="DashboardScreenLogoLink" to="/home">
        <img
          className="DashboardScreenLogo"
          src={logo}
          alt="Storetasker Logo"
        />
      </Link>
      <div className="DashboardScreenContent InboundScreenContent">
        <div className="InboundListColumn">
          {selectedIds.length > 0 && (
            <div className="InboundListColumnActions">
              <div className="InboundListColumnAction" onClick={archiveMany}>
                Archive Selected
              </div>
            </div>
          )}
          <div className="InboundListColumnSearch">
            <input
              type="text"
              className="InboundListColumnSearchInput"
              autoComplete="new-off"
              spellCheck="false"
              placeholder="Search Inbound"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={handleSearchInputKeyDown}
            />
            <div
              onClick={cancelSearch}
              className="InboundListColumnSearchCancel"
            />
          </div>
          {!searchLoading && searchResults === null ? (
            <div className="InboundListContainer">
              {!withExtraForAdmin.length && (
                <div className="InboundListContainerEmpty">
                  {errorOrphanUnreads || errorOrphanPaginated ? (
                    <Fragment>
                      <div className="InboundListContainerEmptyHeader">
                        Loading Error
                      </div>
                      <div className="InboundListContainerEmptySub">
                        {errorUtils.getErrorMessage(
                          errorOrphanUnreads || errorOrphanPaginated!,
                        )}
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="InboundListContainerEmptyHeader">
                        Inbound is empty
                      </div>
                      <div className="InboundListContainerEmptySub">
                        New emails and texts from contacts we don&apos;t
                        recognize will show up here.
                      </div>
                      <a
                        href="https://kb.storetasker.com/Working-in-the-Inbound-Tab-9900d21d650a41bd994e899a51314efe"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="InboundListContainerEmptyLink"
                      >
                        Learn More
                      </a>
                    </Fragment>
                  )}
                </div>
              )}
              {withExtraForAdmin.map(renderInboundItem)}
              {withExtraForAdmin.length >= PAGE_LIMIT &&
                oldestLastUpdated &&
                showLoadMore && (
                  <div className="InboundListItemLoader" onClick={loadMore}>
                    load more
                  </div>
                )}
            </div>
          ) : (
            <div className="InboundListContainer">
              {!searchLoading && searchResults && !searchResults.length && (
                <div className="InboundListContainerEmpty">
                  <div className="InboundListContainerEmptyHeader">
                    No inbound threads found.
                  </div>
                  <div className="InboundListContainerEmptySub">
                    Try another search query to find what you&apos;re looking
                    for.
                  </div>
                </div>
              )}
              {!searchLoading &&
                searchResults &&
                !!searchResults.length &&
                searchResults.map(renderInboundItem)}
            </div>
          )}
        </div>
        <div className="InboundDetailColumn">
          <Link className="InboundDetailColumnCover" to="/inbound" />
          <div className="InboundDetailColumnMain">
            <div className="InboundDetailColumnHeader">
              <Link className="InboundDetailColumnHeaderBack" to="/inbound">
                Inbound
              </Link>
            </div>
            <div className="InboundDetailColumnBody">
              <Route
                path="/inbound/:id"
                render={(routeProps) => (
                  <AdminInboundDetail
                    emailTemplates={emailTemplates}
                    onLoadData={onLoadChild}
                    orphanId={routeProps.match.params.id}
                    socketClient={socketClient}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AdminInbound.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default AdminInbound;
