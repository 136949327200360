import React from 'react';
import PropTypes from 'prop-types';

interface DangerousHTMLProps {
  className: string;
  content: string;
}

const DangerousHTML = ({ className, content }: DangerousHTMLProps) => (
  <div
    className={'DangerousHTML ' + className}
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);

DangerousHTML.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default React.memo(DangerousHTML);
