import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import HumanUserBubble from '../feature/HumanUserBubble';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import { formatValidPhoneNumber } from '../../utils/format';
import { MatchThreadPaginatedQuery } from '../../gql/graphql';

interface PhoneCallThreadEventProps {
  className?: string;
  hasUnknownHuman?: boolean;
  isUnthreadLoading?: boolean;
  isGroupFirst: boolean;
  isGroupLast: boolean;
  onReplyClick?: () => void;
  onUnthreadPhoneCall?: (phoneCallEventId: string) => void;
  phoneCallEvent: Extract<
    MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
    { __typename?: 'PhoneCallEvent' | undefined }
  >;
  threadEventId: string;
  threadEventTimestamp: number;
}
const initTime = new Date().getTime();

const PhoneCallThreadEvent = ({
  className,
  isGroupFirst,
  isGroupLast,
  onReplyClick,
  onUnthreadPhoneCall,
  phoneCallEvent,
  threadEventTimestamp,
  hasUnknownHuman,
  isUnthreadLoading,
}: PhoneCallThreadEventProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isGroupFirst]);
  const [showUnthreadOption, setShowUnthreadOption] = useState(false);
  const nowMoment = moment.tz(initTime, moment.tz.guess());
  const eventMoment = moment.tz(threadEventTimestamp, moment.tz.guess());
  // January 4th, 2020 at 5:37pm PST
  const eventDateFull =
    eventMoment.format('MMMM Do, YYYY') +
    ' at ' +
    eventMoment.format('h:mma z');
  let eventDateSummary = eventMoment.format('M/D/YY');
  if (nowMoment.isSame(eventMoment, 'day')) {
    eventDateSummary = eventMoment.format('h:mma');
  } else if (nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')) {
    eventDateSummary = 'Yesterday';
  } else if (nowMoment.isSame(eventMoment, 'week')) {
    eventDateSummary = eventMoment.format('dddd');
  } else if (nowMoment.isSame(eventMoment, 'year')) {
    eventDateSummary = eventMoment.format('MMM Do');
  }
  const isSolo =
    phoneCallEvent.ownerHuman &&
    (phoneCallEvent.ownerHuman.firstName ||
      phoneCallEvent.ownerHuman.lastName) &&
    (!phoneCallEvent.recipientHumans || !phoneCallEvent.recipientHumans.length);

  const humanRecipient = phoneCallEvent.ownerHuman
    ? phoneCallEvent.ownerHuman
    : phoneCallEvent.recipientHumans && phoneCallEvent.recipientHumans[0];
  let humanName = '';
  if (humanRecipient) {
    const cleanFirst = (humanRecipient.firstName || '').trim();
    const cleanLast = (humanRecipient.lastName || '').trim();
    if (cleanFirst || cleanLast) {
      humanName = (cleanFirst + ' ' + cleanLast).trim();
    } else {
      humanName =
        formatValidPhoneNumber(humanRecipient.primaryPhone) ||
        humanRecipient.primaryPhone ||
        '';
    }
  }
  let content = '';
  if (phoneCallEvent.ownerHuman) {
    content = `Phone call from ${humanName}`; // blank or answered resultStatus
    if (
      phoneCallEvent.resultStatus === 'missed' ||
      phoneCallEvent.resultStatus === 'voicemail'
    ) {
      content = `${
        phoneCallEvent.voicemailRecordingUrl ? 'Voicemail' : 'Missed call'
      } from ${humanName}`;
    }
  } else {
    content = `Calling ${humanName}...`;
    if (phoneCallEvent.resultStatus) {
      content = `Called ${humanName}`;
    }
  }
  return (
    <div
      className={
        'ThreadEvent PhoneCallThreadEvent ' +
        (phoneCallEvent.ownerHuman
          ? ' ThreadEventOwnerHuman '
          : ' ThreadEventOwnerExpert ') +
        (isSolo ? ' ThreadEventSolo ' : '') +
        (hasUnknownHuman ? ' ThreadEventWithUnknown ' : '') +
        (className || '')
      }
    >
      <div className="ThreadEventWrapper">
        {isGroupFirst && (
          <div className="ThreadEventTop">
            <div className="ThreadEventTopBubbles">
              {phoneCallEvent.ownerHuman && (
                <HumanUserBubble
                  firstName={
                    phoneCallEvent.ownerHuman &&
                    phoneCallEvent.ownerHuman.firstName
                  }
                  lastName={
                    phoneCallEvent.ownerHuman &&
                    phoneCallEvent.ownerHuman.lastName
                  }
                  fallbackPhone={
                    phoneCallEvent.ownerHuman &&
                    phoneCallEvent.ownerHuman.primaryPhone
                  }
                  primary
                />
              )}
              {phoneCallEvent.recipientHumans.map((r) => (
                <HumanUserBubble
                  key={r.id}
                  firstName={r.firstName}
                  lastName={r.lastName}
                  fallbackPhone={r.primaryPhone}
                />
              ))}
            </div>
            <div className="ThreadEventTopDate" data-tip={eventDateFull}>
              {eventDateSummary}
            </div>
          </div>
        )}
        <div className="ThreadEventCardWrapper">
          <div className="ThreadEventCardLeft">
            {isSolo && phoneCallEvent.ownerHuman && isGroupFirst && (
              <div className="ThreadEventCardLeftUser">
                <HumanUserBubble
                  firstName={
                    phoneCallEvent.ownerHuman &&
                    phoneCallEvent.ownerHuman.firstName
                  }
                  lastName={
                    phoneCallEvent.ownerHuman &&
                    phoneCallEvent.ownerHuman.lastName
                  }
                  primary
                />
              </div>
            )}
            {phoneCallEvent.ownerExpert && (
              <div className="ThreadEventCardLeftDate" data-tip={eventDateFull}>
                {eventDateSummary}
              </div>
            )}
          </div>
          <div className="ThreadEventCard">
            <div
              className="ThreadEventCardBody"
              onClick={() => showUnthreadOption && setShowUnthreadOption(false)}
            >
              <div className="ThreadEventCardBodyContent">{content}</div>
            </div>
            {!!hasUnknownHuman && (
              <div
                className={
                  'ThreadEventCardBodyUnthread ThreadEventCardBodyUnthread' +
                  (showUnthreadOption ? 'Active' : 'Inactive')
                }
              >
                <div
                  className="ThreadEventCardBodyUnthreadToggle"
                  onClick={() => setShowUnthreadOption(!showUnthreadOption)}
                />
                {!!showUnthreadOption && (
                  <div
                    className={
                      'ThreadEventCardBodyUnthreadOption ' +
                      (isUnthreadLoading
                        ? ' ThreadEventCardBodyUnthreadOptionLoading '
                        : '')
                    }
                    onClick={() =>
                      onUnthreadPhoneCall &&
                      onUnthreadPhoneCall(phoneCallEvent.id)
                    }
                  >
                    Unthread and move to inbound
                  </div>
                )}
              </div>
            )}
            {phoneCallEvent.voicemailRecordingUrl && (
              <div
                className="ThreadEventCardFiles"
                onClick={() =>
                  showUnthreadOption && setShowUnthreadOption(false)
                }
              >
                <div className="ThreadEventCardFileAudio">
                  <audio src={phoneCallEvent.voicemailRecordingUrl} controls />
                </div>
              </div>
            )}
          </div>
          <div className="ThreadEventCardRight">
            {!phoneCallEvent.ownerExpert && (
              <div
                className="ThreadEventCardRightDate"
                data-tip={eventDateFull}
              >
                {eventDateSummary}
              </div>
            )}
            {phoneCallEvent.ownerExpert && isGroupFirst && (
              <div className="ThreadEventCardRightUser">
                <ExpertUserBubble
                  expertId={phoneCallEvent.ownerExpert.id}
                  expertDetails={phoneCallEvent.ownerExpert}
                  primary
                />
              </div>
            )}
          </div>
        </div>
        {!hasUnknownHuman && isGroupLast && (
          <div className="ThreadEventBottom">
            {!!onReplyClick && (
              <div className="ThreadEventBottomReply" onClick={onReplyClick}>
                Reply Via Text
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

PhoneCallThreadEvent.propTypes = {
  className: PropTypes.string,
  hasUnknownHuman: PropTypes.bool,
  isGroupFirst: PropTypes.bool.isRequired,
  isGroupLast: PropTypes.bool.isRequired,
  isUnthreadLoading: PropTypes.bool,
  onReplyClick: PropTypes.func,
  onUnthreadPhoneCall: PropTypes.func,
  phoneCallEvent: PropTypes.object.isRequired,
  threadEventId: PropTypes.string.isRequired,
  threadEventTimestamp: PropTypes.number.isRequired,
};

PhoneCallThreadEvent.defaultProps = {
  className: '',
  hasUnknownHuman: false,
  isUnthreadLoading: false,
  onReplyClick: null,
  onUnthreadPhoneCall: () => {},
};

export default PhoneCallThreadEvent;
