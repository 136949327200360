import _ from 'lodash';
import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  Fragment,
} from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {
  useQuery,
  useMutation,
  useLazyQuery,
  ApolloError,
  gql,
} from '@apollo/client';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { Link, useHistory } from 'react-router-dom';
import TextareaAutosize from 'react-autosize-textarea';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import envUtils from '../../utils/env';
import oauthUtils from '../../utils/oauth';
import {
  SUPPORT_EXPERT_ID,
  DANGER_ZONE_REMOVAL_REASONS,
} from '../../utils/constants';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import {
  TextMessageEventDetail,
  EmailMessageEventDetail,
  MessageEventDetail,
  MeetingEventDetail,
  QuoteDetail,
  PhoneCallEventDetail,
  RequestEventDetail,
  MatchDetail,
  RequestSummaryHq,
  ProjectDetailHq,
  UserNoteDetail,
  DangerZoneEventDetailHq,
} from '../../utils/gql';
import {
  //   unreadReasonFormatted,
  searchResultType,
  centsDollarsRounded,
  formatNumberWithCommas,
  quoteStatusFormatted,
  amountDiscountCode,
  amountClientFee,
} from '../../utils/format';
import UserNoteEditor from '../feature/UserNoteEditor';
import ThreadSearchResults from '../feature/ThreadSearchResults';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import HumanUserBubble from '../feature/HumanUserBubble';
import RequestThreadEvent from '../threadEvent/RequestThreadEvent';
import QuoteThreadEvent from '../threadEvent/QuoteThreadEvent';
import EmailMessageThreadEvent from '../threadEvent/EmailMessageThreadEvent';
import TextMessageThreadEvent from '../threadEvent/TextMessageThreadEvent';
import MessageThreadEvent from '../threadEvent/MessageThreadEvent';
import PhoneCallThreadEvent from '../threadEvent/PhoneCallThreadEvent';
import MeetingThreadEvent from '../threadEvent/MeetingThreadEvent';
import {
  MatchDetailsAdminQuery,
  MatchDetailsAdminQueryVariables,
  RequestAllForMatchQuery,
  RequestAllForMatchQueryVariables,
  AdminThreadPaginatedMatchQuery,
  AdminThreadPaginatedMatchQueryVariables,
  AdminThreadEventUpdatedMatchSubscription,
  AdminThreadEventUpdatedMatchSubscriptionVariables,
  UserNoteMatchAdminQuery,
  UserNoteMatchAdminQueryVariables,
  UserNoteMatchEditAdminMutation,
  UserNoteMatchEditAdminMutationVariables,
  UserNoteMatchUpdatedAdminSubscription,
  UserNoteMatchUpdatedAdminSubscriptionVariables,
  ProjectPaginateMatchQuery,
  ProjectPaginateMatchQueryVariables,
  DangerZoneEventForMatchQuery,
  DangerZoneEventForMatchQueryVariables,
  ProjectCancelAdminMutation,
  ProjectCancelAdminMutationVariables,
  ProjectApproveByAdminMutation,
  ProjectApproveByAdminMutationVariables,
  MatchReleaseClaimByAdminMutation,
  MatchReleaseClaimByAdminMutationVariables,
  MatchExcludeKpisMutation,
  MatchExcludeKpisMutationVariables,
  MatchSetDangerZoneAdminMutation,
  MatchSetDangerZoneAdminMutationVariables,
  MatchSnoozeDangerZoneAdminMutation,
  MatchSnoozeDangerZoneAdminMutationVariables,
  MatchSupportCaseDangerZoneAdminMutation,
  MatchSupportCaseDangerZoneAdminMutationVariables,
  MatchAssignDangerZoneAdminMutation,
  MatchAssignDangerZoneAdminMutationVariables,
  MatchThreadSearchAdminMutation,
  MatchThreadSearchAdminMutationVariables,
  QuoteStatus,
} from '../../gql/graphql';

const matchDetailsAdminQuery = gql`
  query MatchDetailsAdmin($matchId: ID!) {
    matchDetails(matchId: $matchId) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

// @connection(key: "thread", filter: ["matchId"])
const adminThreadPaginatedMatchQuery = gql`
  query AdminThreadPaginatedMatch(
    $matchId: ID
    $direction: String!
    $fromDate: Date
    $limit: Int!
  ) {
    threadPaginated(
      matchId: $matchId
      direction: $direction
      fromDate: $fromDate
      limit: $limit
    ) {
      id
      edges {
        id
        cursor
        node {
          ... on TextMessageEvent {
            ...TextMessageEventDetail
          }
          ... on EmailMessageEvent {
            ...EmailMessageEventDetail
          }
          ... on RequestEvent {
            ...RequestEventDetail
          }
          ... on MessageEvent {
            ...MessageEventDetail
          }
          ... on MeetingEvent {
            ...MeetingEventDetail
          }
          ... on Quote {
            ...QuoteDetail
          }
          ... on PhoneCallEvent {
            ...PhoneCallEventDetail
          }
        }
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const adminThreadEventUpdatedMatchSubscription = gql`
  subscription AdminThreadEventUpdatedMatch($threadId: ID!) {
    threadEventUpdated(threadId: $threadId) {
      ... on TextMessageEvent {
        ...TextMessageEventDetail
      }
      ... on EmailMessageEvent {
        ...EmailMessageEventDetail
      }
      ... on RequestEvent {
        ...RequestEventDetail
      }
      ... on MessageEvent {
        ...MessageEventDetail
      }
      ... on MeetingEvent {
        ...MeetingEventDetail
      }
      ... on Quote {
        ...QuoteDetail
      }
      ... on PhoneCallEvent {
        ...PhoneCallEventDetail
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const userNoteAdminQuery = gql`
  query UserNoteMatchAdmin($matchId: ID, $supportChannelId: ID, $humanId: ID) {
    userNoteForTarget(
      matchId: $matchId
      supportChannelId: $supportChannelId
      humanId: $humanId
    ) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const userNoteEditAdminMutation = gql`
  mutation UserNoteMatchEditAdmin(
    $body: String!
    $userNoteId: ID
    $matchId: ID
    $supportChannelId: ID
    $humanId: ID
  ) {
    userNoteEdit(
      body: $body
      userNoteId: $userNoteId
      matchId: $matchId
      supportChannelId: $supportChannelId
      humanId: $humanId
    ) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const adminUserNoteUpdatedSubscription = gql`
  subscription UserNoteMatchUpdatedAdmin($targetId: ID!) {
    userNoteUpdated(targetId: $targetId) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const matchThreadSearchAdminMutation = gql`
  mutation MatchThreadSearchAdmin(
    $searchQuery: String!
    $matchId: ID
    $limit: Int!
  ) {
    threadSearch(searchQuery: $searchQuery, matchId: $matchId, limit: $limit) {
      id
      highlightsContent
      highlightsSubject
      node {
        ... on TextMessageEvent {
          ...TextMessageEventDetail
        }
        ... on EmailMessageEvent {
          ...EmailMessageEventDetail
        }
        ... on RequestEvent {
          ...RequestEventDetail
        }
        ... on MessageEvent {
          ...MessageEventDetail
        }
        ... on MeetingEvent {
          ...MeetingEventDetail
        }
        ... on Quote {
          ...QuoteDetail
        }
        ... on PhoneCallEvent {
          ...PhoneCallEventDetail
        }
      }
      plainTextContent
      plainTextSubject
      score
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const requestAllForMatchQuery = gql`
  query RequestAllForMatch($matchId: ID!) {
    requestAllForMatch(matchId: $matchId) {
      ...RequestSummaryHq
    }
  }
  ${RequestSummaryHq}
`;

const projectPaginateMatchQuery = gql`
  query ProjectPaginateMatch(
    $matchId: ID!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    projectPaginateMatch(
      matchId: $matchId
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...ProjectDetailHq
    }
  }
  ${ProjectDetailHq}
`;

const dangerZoneEventForMatchQuery = gql`
  query DangerZoneEventForMatch($matchId: ID!) {
    dangerZoneEventForMatch(matchId: $matchId) {
      ...DangerZoneEventDetailHq
    }
  }
  ${DangerZoneEventDetailHq}
`;

const projectCancelAdminMutation = gql`
  mutation ProjectCancelAdmin(
    $quoteId: ID!
    $matchId: ID!
    $cancelReason: String
  ) {
    projectCancel(
      quoteId: $quoteId
      matchId: $matchId
      cancelReason: $cancelReason
    ) {
      ...ProjectDetailHq
    }
  }
  ${ProjectDetailHq}
`;

const projectApproveByAdminMutation = gql`
  mutation ProjectApproveByAdmin($quoteId: ID!, $matchId: ID!) {
    projectApproveByAdmin(quoteId: $quoteId, matchId: $matchId) {
      ...ProjectDetailHq
    }
  }
  ${ProjectDetailHq}
`;

const matchReleaseClaimByAdminMutation = gql`
  mutation MatchReleaseClaimByAdmin($matchId: ID!) {
    matchReleaseClaimByAdmin(matchId: $matchId) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const matchExcludeKpisMutation = gql`
  mutation MatchExcludeKpis($matchId: ID!, $excluded: Boolean!) {
    matchExcludeKpis(matchId: $matchId, excluded: $excluded) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const matchSetDangerZoneAdminMutation = gql`
  mutation MatchSetDangerZoneAdmin(
    $matchId: ID!
    $putInDangerZone: Boolean!
    $reason: String!
  ) {
    matchSetDangerZone(
      matchId: $matchId
      putInDangerZone: $putInDangerZone
      reason: $reason
    ) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const matchSnoozeDangerZoneAdminMutation = gql`
  mutation MatchSnoozeDangerZoneAdmin($matchId: ID!, $untilDate: Date!) {
    matchSnoozeDangerZone(matchId: $matchId, untilDate: $untilDate) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const matchSupportCaseDangerZoneAdminMutation = gql`
  mutation MatchSupportCaseDangerZoneAdmin(
    $matchId: ID!
    $isSupportCase: Boolean!
    $dangerZoneAdditionalDescription: String
  ) {
    matchSupportCaseDangerZone(
      matchId: $matchId
      isSupportCase: $isSupportCase
      dangerZoneAdditionalDescription: $dangerZoneAdditionalDescription
    ) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const matchAssignDangerZoneAdminMutation = gql`
  mutation MatchAssignDangerZoneAdmin(
    $matchId: ID!
    $dangerZoneAssignment: String!
  ) {
    matchAssignDangerZone(
      matchId: $matchId
      dangerZoneAssignment: $dangerZoneAssignment
    ) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

interface AdminMatchDetailProps {
  matchId: string;
  socketClient: ClientWithOnReconnected;
}

interface IGroupedEvents {
  group: AdminThreadPaginatedMatchQuery['threadPaginated']['edges'];
}

let scrollDownQuickTimeout: NodeJS.Timeout | undefined;
let isLoadingEvents = false;
const EVENT_PAGE_LIMIT = 20;
const ACTIVITY_PAGE_LIMIT = 5;
const initTime = new Date().getTime();

const AdminMatchDetail = ({ matchId, socketClient }: AdminMatchDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const threadEventsWindow = useRef<HTMLDivElement>(null);
  const [initDate] = useState(() => new Date());
  const [projectAction, setProjectAction] = useState('');
  const [releaseLoading, setReleaseLoading] = useState(false);
  const [excludeLoading, setExcludeLoading] = useState(false);
  const [dangerLoading, setDangerLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [tempEdges, setTempEdges] = useState(
    [] as AdminThreadPaginatedMatchQuery['threadPaginated']['edges'],
  );
  // remoteEventIds tracks threadEventUpdated things that might cause pagination errors
  const [remoteEventIds, setRemoteEventIds] = useState([] as string[]);
  const [showLoadMoreProjects, setShowLoadMoreProjects] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedSearchResult, setSelectedSearchResult] = useState<
    MatchThreadSearchAdminMutation['threadSearch'][0] | null
  >(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    MatchThreadSearchAdminMutation['threadSearch'] | null
  >(null);
  const [primaryPanel, setPrimaryPanel] = useState('Match');
  const [dzeAssignment] = useState('');
  const [dzeRemovalReason] = useState('');
  const [dzeDescription, setDzeDescription] = useState('');
  const [dzeWriteCase, setDzeWriteCase] = useState(false);
  const [dzeSnoozed] = useState('');
  useEffect(() => {
    if (!searchResults) {
      ReactTooltip.hide();
    }
    ReactTooltip.rebuild();
  }, [searchLoading, searchResults]);
  const {
    data: dataMatch,
    error: errorMatch,
    loading: loadingMatch,
    refetch: refetchMatch,
  } = useQuery<MatchDetailsAdminQuery, MatchDetailsAdminQueryVariables>(
    matchDetailsAdminQuery,
    {
      returnPartialData: true,
      variables: {
        matchId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorMatch) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorMatch) || 'Could not load match details';
  }
  const matchDetails =
    dataMatch && dataMatch.matchDetails && dataMatch.matchDetails.id
      ? dataMatch.matchDetails
      : undefined;
  if (matchDetails && matchDetails.expertStr === SUPPORT_EXPERT_ID) {
    fullErrorCover = 'This is a brand match!';
  }
  const {
    data: dataThread,
    error: errorThread,
    loading: loadingThread,
    fetchMore: fetchMoreThread,
    subscribeToMore: subscribeToMoreThread,
  } = useQuery<
    AdminThreadPaginatedMatchQuery,
    AdminThreadPaginatedMatchQueryVariables
  >(adminThreadPaginatedMatchQuery, {
    onCompleted: () => {
      scrollDown();
    },
    variables: {
      direction: 'before',
      fromDate: initDate.getTime(),
      limit: EVENT_PAGE_LIMIT,
      matchId,
    },
  });
  if (errorThread) {
    fullErrorCover =
      'Error loading this thread: ' + errorUtils.getErrorMessage(errorThread);
  }
  const cleanThread =
    (dataThread &&
      dataThread.threadPaginated &&
      dataThread.threadPaginated.edges) ||
    [];
  useEffect(() => {
    setTempEdges([]);
    setRemoteEventIds([]);
  }, [matchId]);
  useEffect(() => {
    let unsub: () => void;
    if (matchId) {
      unsub = subscribeToMoreThread<
        AdminThreadEventUpdatedMatchSubscription,
        AdminThreadEventUpdatedMatchSubscriptionVariables
      >({
        document: adminThreadEventUpdatedMatchSubscription,
        onError: (err) => {
          addNotification(
            'Error loading thread. Please refresh and try again: ' +
              err.message,
          );
          console.error('onError: subscribeToMoreThread', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { threadEventUpdated },
            },
          },
        ) => {
          console.log('threadEventUpdated', prev, threadEventUpdated);
          if (!threadEventUpdated || !prev.threadPaginated) {
            return prev;
          }
          if (prev.threadPaginated.id !== threadEventUpdated.matchStr) {
            return prev;
          }
          setTempEdges((prevTempEdges) =>
            prevTempEdges.filter(
              (t) => t.id !== threadEventUpdated.threadEvent,
            ),
          );
          setRemoteEventIds((prevRemoteEventIds) =>
            prevRemoteEventIds.concat(threadEventUpdated.id),
          );
          const updatingIndex = (prev.threadPaginated.edges || []).findIndex(
            (e) => e.id === threadEventUpdated.threadEvent,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              threadPaginated: {
                __typename: prev.threadPaginated.__typename,
                edges: prev.threadPaginated.edges
                  .slice(0, updatingIndex)
                  .concat({
                    __typename:
                      prev.threadPaginated.edges[updatingIndex].__typename,
                    cursor: prev.threadPaginated.edges[updatingIndex].cursor,
                    id: prev.threadPaginated.edges[updatingIndex].id,
                    node: {
                      ...prev.threadPaginated.edges[updatingIndex].node,
                      ...threadEventUpdated,
                    } as AdminThreadPaginatedMatchQuery['threadPaginated']['edges'][0]['node'],
                  })
                  .concat(prev.threadPaginated.edges.slice(updatingIndex + 1)),
                id: prev.threadPaginated.id,
              },
            };
          }
          // add new
          scrollDown();
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: (prev.threadPaginated.edges || []).concat({
                __typename: 'ThreadEventEdge',
                cursor: threadEventUpdated.createdAt,
                id: threadEventUpdated.threadEvent || '',
                node: threadEventUpdated,
              }),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          threadId: matchId,
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [subscribeToMoreThread, matchId, addNotification]);
  const [
    getUserNotes,
    {
      called: calledUserNotes,
      data: dataUserNotes,
      error: errorUserNotes,
      loading: loadingUserNotes,
      subscribeToMore: subscribeToMoreUserNotes,
    },
  ] = useLazyQuery<UserNoteMatchAdminQuery, UserNoteMatchAdminQueryVariables>(
    userNoteAdminQuery,
    {
      variables: {
        matchId,
      },
    },
  );
  const allUserNotes = (dataUserNotes && dataUserNotes.userNoteForTarget) || [];
  useEffect(() => {
    let unsub: () => void;
    if (matchId && calledUserNotes && subscribeToMoreUserNotes) {
      unsub = subscribeToMoreUserNotes<
        UserNoteMatchUpdatedAdminSubscription,
        UserNoteMatchUpdatedAdminSubscriptionVariables
      >({
        document: adminUserNoteUpdatedSubscription,
        onError: (err) => {
          addNotification(
            'Error loading notes. Please refresh and try again: ' + err.message,
          );
          console.error('onError: subscribeToMoreUserNotes', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { userNoteUpdated },
            },
          },
        ) => {
          console.log('userNoteUpdated', prev, userNoteUpdated);
          if (!userNoteUpdated || !userNoteUpdated.matchStr) {
            return prev;
          }
          if (matchId !== userNoteUpdated.matchStr) {
            return prev;
          }
          const updatingIndex = (prev.userNoteForTarget || []).findIndex(
            (un) => un.id === userNoteUpdated.id,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              userNoteForTarget: prev.userNoteForTarget
                .slice(0, updatingIndex)
                .concat({
                  ...prev.userNoteForTarget[updatingIndex],
                  ...userNoteUpdated,
                })
                .concat(prev.userNoteForTarget.slice(updatingIndex + 1)),
            };
          }
          return {
            userNoteForTarget: (prev.userNoteForTarget || []).concat(
              userNoteUpdated,
            ),
          };
        },
        variables: {
          targetId: matchId,
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [subscribeToMoreUserNotes, matchId, calledUserNotes, addNotification]);
  const [tryEditNote] = useMutation<
    UserNoteMatchEditAdminMutation,
    UserNoteMatchEditAdminMutationVariables
  >(userNoteEditAdminMutation);
  function onSaveNote(body: string, userNoteId?: string) {
    if (noteLoading || !matchId) return;
    setNoteLoading(true);
    tryEditNote({
      variables: {
        body,
        matchId,
        userNoteId,
      },
    })
      .then(() => {
        setNoteLoading(false);
      })
      .catch((err: ApolloError) => {
        setNoteLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Edit Note Error');
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'onSaveNote',
        });
      });
  }
  const [
    getAllRequests,
    {
      called: calledRequestsAll,
      data: dataRequestsAll,
      error: errorRequestsAll,
      loading: loadingRequestsAll,
    },
  ] = useLazyQuery<RequestAllForMatchQuery, RequestAllForMatchQueryVariables>(
    requestAllForMatchQuery,
    {
      returnPartialData: true,
      variables: {
        matchId,
      },
    },
  );
  const allRequestsLoaded = (
    (dataRequestsAll && dataRequestsAll.requestAllForMatch) ||
    []
  )
    .filter(
      (r) =>
        r.matchLog &&
        r.matchLog.find((ml) => ml.matchStr === matchId) &&
        (!matchDetails ||
          !matchDetails.leadRequest ||
          matchDetails.leadRequest.id !== r.id),
    )
    .sort((a, b) => b.createdAt - a.createdAt);
  const {
    data: dataProjectPaginated,
    error: errorProjectPaginated,
    loading: loadingProjectPaginated,
    fetchMore: fetchMoreProjectPaginated,
  } = useQuery<ProjectPaginateMatchQuery, ProjectPaginateMatchQueryVariables>(
    projectPaginateMatchQuery,
    {
      variables: {
        direction: 'BACKWARD',
        fromDate: initDate.getTime(),
        limit: ACTIVITY_PAGE_LIMIT,
        matchId,
      },
    },
  );
  const allProjectsForBrand = _.uniqBy(
    [
      ...((dataProjectPaginated && dataProjectPaginated.projectPaginateMatch) ||
        []),
    ].filter((r) => r.matchStr === matchId),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  const oldestCreatedProject =
    allProjectsForBrand[allProjectsForBrand.length - 1];
  function loadMoreProjects() {
    fetchMoreProjectPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.projectPaginateMatch)
          return prev;
        if (fetchMoreResult.projectPaginateMatch.length < ACTIVITY_PAGE_LIMIT) {
          setShowLoadMoreProjects(false);
        }
        return {
          projectPaginateMatch: (prev.projectPaginateMatch || []).concat(
            fetchMoreResult.projectPaginateMatch,
          ),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate: oldestCreatedProject.createdAt,
        limit: ACTIVITY_PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'AdminMatchDetail',
        func: 'loadMoreProjects',
      });
    });
  }
  const [tryProjectCancel] = useMutation<
    ProjectCancelAdminMutation,
    ProjectCancelAdminMutationVariables
  >(projectCancelAdminMutation);
  function onProjectCancel(quoteId: string) {
    if (projectAction) return;
    setProjectAction(quoteId);
    tryProjectCancel({
      variables: {
        cancelReason: 'HQ_MANUAL_CANCEL',
        matchId,
        quoteId,
      },
    })
      .then(() => {
        setProjectAction('');
      })
      .catch((err: ApolloError) => {
        setProjectAction('');
        addNotification(
          errorUtils.getErrorMessage(err) || 'Project Cancel Error',
        );
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'tryProjectCancel',
        });
      });
  }
  const [tryProjectApprove] = useMutation<
    ProjectApproveByAdminMutation,
    ProjectApproveByAdminMutationVariables
  >(projectApproveByAdminMutation);
  function onProjectApprove(quoteId: string) {
    if (projectAction) return;
    setProjectAction(quoteId);
    tryProjectApprove({
      variables: {
        matchId,
        quoteId,
      },
    })
      .then(() => {
        setProjectAction('');
      })
      .catch((err: ApolloError) => {
        setProjectAction('');
        addNotification(
          errorUtils.getErrorMessage(err) || 'Project Approve Error',
        );
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'tryProjectApprove',
        });
      });
  }
  const [tryExcludeKpis] = useMutation<
    MatchExcludeKpisMutation,
    MatchExcludeKpisMutationVariables
  >(matchExcludeKpisMutation);
  function onExcludeKpis(excluded: boolean) {
    if (excludeLoading) return;
    setExcludeLoading(true);

    tryExcludeKpis({
      variables: {
        excluded,
        matchId,
      },
    })
      .then(() => {
        setExcludeLoading(false);
      })
      .catch((err: ApolloError) => {
        setExcludeLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'KPI Exclude Error');
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'tryExcludeKpis',
        });
      });
  }
  const [tryReleaseClaim] = useMutation<
    MatchReleaseClaimByAdminMutation,
    MatchReleaseClaimByAdminMutationVariables
  >(matchReleaseClaimByAdminMutation);
  function onReleaseClaim() {
    if (releaseLoading) return;
    setReleaseLoading(true);
    tryReleaseClaim({
      variables: {
        matchId,
      },
    })
      .then(() => {
        setReleaseLoading(false);
      })
      .catch((err: ApolloError) => {
        setReleaseLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Claim Release Error',
        );
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'tryReleaseClaim',
        });
      });
  }
  const [
    getAllDze,
    {
      called: calledDzeAll,
      data: dataDzeAll,
      error: errorDzeAll,
      loading: loadingDzeAll,
      refetch: refetchDzeAll,
    },
  ] = useLazyQuery<
    DangerZoneEventForMatchQuery,
    DangerZoneEventForMatchQueryVariables
  >(dangerZoneEventForMatchQuery, {
    returnPartialData: true,
    variables: {
      matchId,
    },
  });
  const allDzeLoaded = (
    (dataDzeAll && dataDzeAll.dangerZoneEventForMatch) ||
    []
  )
    .filter((dze) => dze.matchStr === matchId)
    .sort((a, b) => b.createdAt - a.createdAt);
  const [trySnoozeDanger] = useMutation<
    MatchSnoozeDangerZoneAdminMutation,
    MatchSnoozeDangerZoneAdminMutationVariables
  >(matchSnoozeDangerZoneAdminMutation);
  function onSnoozeDanger(snoozeDayValue?: string) {
    if (dangerLoading) return;
    let untilDate: Date;
    if (snoozeDayValue) {
      const days = parseInt(snoozeDayValue, 10);
      untilDate = moment().add(days, 'days').toDate();
    } else {
      untilDate = moment().subtract(1, 'days').toDate();
    }
    setDangerLoading(true);
    trySnoozeDanger({
      variables: {
        matchId,
        untilDate: untilDate.getTime(),
      },
    })
      .then(() => {
        setDangerLoading(false);
        if (calledDzeAll && refetchDzeAll) {
          refetchDzeAll().catch((err: ApolloError) => {
            addNotification(
              errorUtils.getErrorMessage(err) || 'Refetch Danger Error',
            );
            logError(err, {
              component: 'AdminMatchDetail',
              func: 'trySnoozeDanger + refetchDzeAll',
            });
          });
        }
      })
      .catch((err: ApolloError) => {
        setDangerLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Snooze Danger Error',
        );
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'trySnoozeDanger',
        });
      });
  }
  function startWriteCase() {
    setDzeWriteCase(true);
    setDzeDescription(
      (matchDetails &&
        matchDetails.dangerZoneEvent &&
        matchDetails.dangerZoneEvent.dangerZoneAdditionalDescription) ||
        '',
    );
  }
  const [trySupportCaseDanger] = useMutation<
    MatchSupportCaseDangerZoneAdminMutation,
    MatchSupportCaseDangerZoneAdminMutationVariables
  >(matchSupportCaseDangerZoneAdminMutation);
  function onSupportCaseDanger(isSupportCase: boolean) {
    if (dangerLoading) return;
    if (isSupportCase && !dzeDescription) {
      addNotification('You need a support case description.', undefined, 5000);
      return;
    }
    setDangerLoading(true);
    setDzeWriteCase(false);
    trySupportCaseDanger({
      variables: {
        dangerZoneAdditionalDescription: isSupportCase ? dzeDescription : null,
        isSupportCase,
        matchId,
      },
    })
      .then(() => {
        setDangerLoading(false);
        if (calledDzeAll && refetchDzeAll) {
          refetchDzeAll().catch((err: ApolloError) => {
            addNotification(
              errorUtils.getErrorMessage(err) || 'Refetch Danger Error',
            );
            logError(err, {
              component: 'AdminMatchDetail',
              func: 'trySupportCaseDanger + refetchDzeAll',
            });
          });
        }
      })
      .catch((err: ApolloError) => {
        setDangerLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Support Case Danger Error',
        );
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'trySupportCaseDanger',
        });
      });
  }
  const [tryAssignDanger] = useMutation<
    MatchAssignDangerZoneAdminMutation,
    MatchAssignDangerZoneAdminMutationVariables
  >(matchAssignDangerZoneAdminMutation);
  function onAssignDanger(dangerZoneAssignment: string) {
    if (dangerLoading) return;
    setDangerLoading(true);
    tryAssignDanger({
      variables: {
        dangerZoneAssignment,
        matchId,
      },
    })
      .then(() => {
        setDangerLoading(false);
        if (calledDzeAll && refetchDzeAll) {
          refetchDzeAll().catch((err: ApolloError) => {
            addNotification(
              errorUtils.getErrorMessage(err) || 'Refetch Danger Error',
            );
            logError(err, {
              component: 'AdminMatchDetail',
              func: 'tryAssignDanger + refetchDzeAll',
            });
          });
        }
      })
      .catch((err: ApolloError) => {
        setDangerLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Team Danger Error');
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'tryAssignDanger',
        });
      });
  }
  const [trySetDanger] = useMutation<
    MatchSetDangerZoneAdminMutation,
    MatchSetDangerZoneAdminMutationVariables
  >(matchSetDangerZoneAdminMutation);
  function onSetDanger(putInDangerZone: boolean, reason?: string) {
    if (dangerLoading) return;
    setDangerLoading(true);
    trySetDanger({
      variables: {
        matchId,
        putInDangerZone,
        reason: reason || 'HQ_MANUAL_DZ',
      },
    })
      .then(() => {
        setDangerLoading(false);
        if (calledDzeAll && refetchDzeAll) {
          refetchDzeAll().catch((err: ApolloError) => {
            addNotification(
              errorUtils.getErrorMessage(err) || 'Refetch Danger Error',
            );
            logError(err, {
              component: 'AdminMatchDetail',
              func: 'trySetDanger + refetchDzeAll',
            });
          });
        }
      })
      .catch((err: ApolloError) => {
        setDangerLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Set/Unset Danger Error',
        );
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'trySetDanger',
        });
      });
  }
  function groupThreadEvents(
    events: AdminThreadPaginatedMatchQuery['threadPaginated']['edges'],
  ) {
    const groupedEvents: IGroupedEvents[] = [];
    events.forEach((ev) => {
      let isSameEventType = false;
      let isSameSenderSide = false;
      let isSameHuman = false;
      let isCurrGroupable = false;
      let isLastGroupable = false;
      const isFirstGroup = !groupedEvents.length;
      if (
        !isFirstGroup &&
        (ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'MessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent')
      ) {
        isCurrGroupable = true;
        isLastGroupable = true;
        const lastGroup = groupedEvents[groupedEvents.length - 1];
        const lastGroupEvent = lastGroup.group[0];
        // is same event type as last
        if (lastGroupEvent.node.__typename === ev.node.__typename) {
          isSameEventType = true;
          // is both sent from expert or both from Human
          isSameSenderSide =
            !!(ev.node.ownerHuman && lastGroupEvent.node.ownerHuman) ||
            !!(ev.node.ownerExpert && lastGroupEvent.node.ownerExpert);
          // is human interacting same
          isSameHuman =
            (ev.node.ownerHuman &&
              lastGroupEvent.node.ownerHuman &&
              ev.node.ownerHuman.id === lastGroupEvent.node.ownerHuman.id) ||
            (ev.node.recipientHumans[0] &&
              lastGroupEvent.node.recipientHumans[0] &&
              ev.node.recipientHumans[0].id ===
                lastGroupEvent.node.recipientHumans[0].id);
          // don't group texts with quotes attached
          if (ev.node.__typename === 'TextMessageEvent') {
            if (ev.node.quote) {
              isCurrGroupable = false;
            }
          }
          if (lastGroupEvent.node.__typename === 'TextMessageEvent') {
            if (lastGroupEvent.node.quote) {
              isLastGroupable = false;
            }
          }
        }
      }
      if (
        isFirstGroup ||
        !isCurrGroupable ||
        !isLastGroupable ||
        !isSameEventType ||
        !isSameSenderSide ||
        !isSameHuman
      ) {
        groupedEvents.push({
          group: [ev],
        });
      } else {
        groupedEvents[groupedEvents.length - 1].group.push(ev);
      }
    });
    return groupedEvents;
  }
  const totalEdges = cleanThread.concat(tempEdges);
  const overPageLimit = totalEdges.length >= EVENT_PAGE_LIMIT;
  const threadEventEdges = _.uniqBy(totalEdges, 'id')
    .sort((a, b) => a.cursor - b.cursor)
    .filter((ev) => {
      if (ev.node.__typename === 'Quote') {
        // filter out quote accepts on subscriptions
        return (
          ev.node.paymentType === 'PROJECT' ||
          ev.node.paymentType === 'BILL' ||
          ev.node.status !== QuoteStatus.Accepted
        );
      }
      return true;
    });
  function scrollDown() {
    scrollDownQuickTimeout = setTimeout(() => {
      if (threadEventsWindow && threadEventsWindow.current) {
        threadEventsWindow.current.scrollTop =
          threadEventsWindow.current.scrollHeight;
      }
    }, 5);
  }
  useEffect(() => {
    function onScroll() {
      if (threadEventsWindow && threadEventsWindow.current) {
        const scrollTemp = threadEventsWindow.current.scrollTop;
        if (
          scrollTemp < 500 &&
          overPageLimit &&
          canLoadMore &&
          !isLoadingEvents
        ) {
          isLoadingEvents = true;
          const heightTemp = threadEventsWindow.current.scrollHeight;
          fetchMoreThread({
            updateQuery: (prev, { fetchMoreResult }) => {
              if (
                !fetchMoreResult ||
                !prev.threadPaginated ||
                !fetchMoreResult.threadPaginated
              )
                return prev;
              if (
                fetchMoreResult.threadPaginated.edges.length < EVENT_PAGE_LIMIT
              ) {
                setCanLoadMore(false);
              }
              return {
                threadPaginated: {
                  __typename: prev.threadPaginated.__typename,
                  edges: _.uniqBy(
                    fetchMoreResult.threadPaginated.edges.concat(
                      prev.threadPaginated.edges || [],
                    ),
                    'id',
                  ),
                  id: prev.threadPaginated.id,
                },
              };
            },
            variables: {
              direction: 'before',
              fromDate: threadEventEdges.filter(
                (ev) => ev.node.id && remoteEventIds.indexOf(ev.node.id) === -1,
              )[0].cursor,
              limit: EVENT_PAGE_LIMIT,
              matchId,
            },
          })
            .catch((err: ApolloError) => {
              addNotification(
                errorUtils.getErrorMessage(err) || 'Load More Error',
              );
              logError(err, {
                component: 'AdminMatchDetail',
                func: 'fetchMoreThread',
              });
            })
            .finally(() => {
              isLoadingEvents = false;
              if (threadEventsWindow && threadEventsWindow.current) {
                threadEventsWindow.current.scrollTop =
                  Math.max(
                    threadEventsWindow.current.scrollHeight - heightTemp,
                    0,
                  ) + scrollTemp;
              }
            });
        }
      }
    }
    if (threadEventsWindow && threadEventsWindow.current) {
      threadEventsWindow.current.addEventListener('scroll', onScroll);
    }
    const tempWindow = threadEventsWindow.current;
    return () => {
      if (tempWindow) {
        tempWindow.removeEventListener('scroll', onScroll);
      }
    };
  }, [
    canLoadMore,
    overPageLimit,
    addNotification,
    fetchMoreThread,
    matchId,
    threadEventEdges,
    remoteEventIds,
  ]);
  useEffect(() => {
    scrollDown();
    const scrollDownEffectTimeout = setTimeout(() => {
      scrollDown();
    }, 500);
    return () => {
      if (scrollDownEffectTimeout) {
        clearTimeout(scrollDownEffectTimeout);
      }
      if (scrollDownQuickTimeout) {
        clearTimeout(scrollDownQuickTimeout);
      }
    };
  }, [matchId]);
  useEffect(() => {
    const loadTime = new Date();
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminMatchDetail socketClient onReconnected');
      refetchMatch().catch(() => {});
      fetchMoreThread({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !prev.threadPaginated ||
            !fetchMoreResult.threadPaginated
          )
            return prev;
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: _.uniqBy(
                fetchMoreResult.threadPaginated.edges.concat(
                  prev.threadPaginated.edges || [],
                ),
                'id',
              ),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          direction: 'after',
          fromDate: loadTime.getTime(),
          limit: 50,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'reconnect fetchMoreThread',
        });
      });
      fetchMoreProjectPaginated({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            projectPaginateMatch: (prev.projectPaginateMatch || []).concat(
              fetchMoreResult.projectPaginateMatch,
            ),
          };
        },
        variables: {
          direction: 'FORWARD',
          fromDate: loadTime.getTime(),
          limit: 5,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'reconnect loadMoreProjects',
        });
      });
    });
    return () => {
      reconnectedListener();
    };
  }, [
    socketClient,
    refetchMatch,
    fetchMoreThread,
    fetchMoreProjectPaginated,
    addNotification,
  ]);
  const threadEventGroups = groupThreadEvents(threadEventEdges);
  function generateComponentsForGroup(
    groupedEvents: AdminThreadPaginatedMatchQuery['threadPaginated']['edges'],
  ) {
    return groupedEvents
      .map((ev, i) => {
        if (ev.node.__typename === 'RequestEvent') {
          return (
            <RequestThreadEvent
              key={ev.id}
              requestEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'MessageEvent') {
          return (
            <MessageThreadEvent
              key={ev.id}
              expertId={SUPPORT_EXPERT_ID}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              messageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'Quote') {
          return (
            <QuoteThreadEvent
              key={ev.id}
              quote={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'EmailMessageEvent') {
          return (
            <EmailMessageThreadEvent
              key={ev.id}
              expertId={SUPPORT_EXPERT_ID}
              emailMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              isAdmin
            />
          );
        }
        if (ev.node.__typename === 'TextMessageEvent') {
          return (
            <TextMessageThreadEvent
              key={ev.id}
              expertId={SUPPORT_EXPERT_ID}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              textMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'PhoneCallEvent') {
          return (
            <PhoneCallThreadEvent
              key={ev.id}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              phoneCallEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'MeetingEvent') {
          return (
            <MeetingThreadEvent
              key={ev.id}
              meetingEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        console.log('Missing ThreadEvent type');
        return null;
      })
      .filter((c) => c);
  }
  const [tryThreadSearch] = useMutation<
    MatchThreadSearchAdminMutation,
    MatchThreadSearchAdminMutationVariables
  >(matchThreadSearchAdminMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput) {
      toggleSearch();
      return;
    }
    if (searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchResults([]);
    tryThreadSearch({
      update: (_cache, { data: dataThreadSearch }) => {
        setSearchLoading(false);
        if (dataThreadSearch && dataThreadSearch.threadSearch) {
          console.log('tryThreadSearch', dataThreadSearch.threadSearch);
          setSearchResults(
            dataThreadSearch.threadSearch
              .slice()
              .sort((a, b) => b.score - a.score),
          );
        }
      },
      variables: {
        limit: 5,
        matchId,
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Thread Search Error');
      logError(err, {
        component: 'AdminMatchDetail',
        func: 'tryThreadSearch',
      });
      setSearchLoading(false);
      setSearchResults(null);
    });
  }
  function onSelectSearchResult(
    s: MatchThreadSearchAdminMutation['threadSearch'][0],
  ) {
    setSelectedSearchResult(s);
    setShowSidebar(false);
  }
  function blockClickPropagation(ev: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  function renderSearchResult(
    s: MatchThreadSearchAdminMutation['threadSearch'][0],
  ) {
    const nowMoment = moment.tz(initTime, moment.tz.guess());
    const eventMoment = moment.tz(s.node.createdAt, moment.tz.guess());
    const eventDateFull =
      eventMoment.format('MMMM Do, YYYY') +
      ' at ' +
      eventMoment.format('h:mma z');
    let eventDateSummary = eventMoment.format('M/D/YY');
    if (nowMoment.isSame(eventMoment, 'day')) {
      eventDateSummary = eventMoment.format('h:mma');
    } else if (
      nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')
    ) {
      eventDateSummary = 'Yesterday';
    } else if (nowMoment.isSame(eventMoment, 'week')) {
      eventDateSummary = eventMoment.format('dddd');
    } else if (nowMoment.isSame(eventMoment, 'year')) {
      eventDateSummary = eventMoment.format('MMMM Do');
    }
    const isFromExpert = !s.node.ownerHuman;
    const primaryHuman:
      | Extract<
          AdminThreadPaginatedMatchQuery['threadPaginated']['edges'][0]['node'],
          { __typename?: 'EmailMessageEvent' | undefined }
        >['ownerHuman']
      | undefined = s.node.ownerHuman || undefined;
    let humanRecipients: Exclude<
      Extract<
        AdminThreadPaginatedMatchQuery['threadPaginated']['edges'][0]['node'],
        { __typename?: 'EmailMessageEvent' | undefined }
      >['ownerHuman'],
      null | undefined
    >[] = [];
    if (
      s.node.__typename === 'MessageEvent' ||
      s.node.__typename === 'EmailMessageEvent' ||
      s.node.__typename === 'TextMessageEvent' ||
      s.node.__typename === 'MeetingEvent' ||
      s.node.__typename === 'PhoneCallEvent'
    ) {
      humanRecipients = s.node.recipientHumans || [];
    }
    // find highlights in text
    let beforeHighlight = '';
    let inHighlight = '';
    let afterHighlight = '';
    const charsBeforeAfter = 70;
    const highlightContent = s.highlightsContent[0] || '';
    if (highlightContent && s.plainTextContent) {
      const highlightIndex = s.plainTextContent.indexOf(highlightContent);
      if (highlightIndex >= 0) {
        const beforeIndex = Math.max(0, highlightIndex - charsBeforeAfter);
        const afterIndex =
          highlightIndex + highlightContent.length + charsBeforeAfter;
        const textAroundHighlight =
          (beforeIndex > 0 ? '...' : '') +
          s.plainTextContent.slice(beforeIndex, afterIndex) +
          (afterIndex < s.plainTextContent.length - 1 ? '...' : '');
        const newHighlightIndex = textAroundHighlight.indexOf(highlightContent);
        if (newHighlightIndex >= 0) {
          beforeHighlight = textAroundHighlight.slice(0, newHighlightIndex);
          inHighlight = highlightContent;
          afterHighlight = textAroundHighlight.slice(
            newHighlightIndex + highlightContent.length,
          );
        }
      }
    }
    return (
      <div
        className={
          'ThreadDetailSidebarSearchResult ' +
          (selectedSearchResult && selectedSearchResult.id === s.id
            ? ' ThreadDetailSidebarSearchResultSelected '
            : '')
        }
        key={s.id}
      >
        <div
          className="ThreadDetailSidebarSearchResultCard"
          onClick={() => onSelectSearchResult(s)}
        >
          <div className="ThreadDetailSidebarSearchResultCardRecipients">
            {primaryHuman && (
              <HumanUserBubble
                firstName={primaryHuman.firstName}
                lastName={primaryHuman.lastName}
                fallbackEmail={primaryHuman.primaryEmail}
                fallbackPhone={primaryHuman.primaryPhone}
                initialsOnly
                primary
              />
            )}
            {humanRecipients.map((r) => (
              <HumanUserBubble
                key={r.id}
                firstName={r.firstName}
                lastName={r.lastName}
                fallbackEmail={r.primaryEmail}
                fallbackPhone={r.primaryPhone}
                initialsOnly
              />
            ))}
          </div>
          {!!isFromExpert &&
            (s.node.__typename === 'MessageEvent' ||
              s.node.__typename === 'EmailMessageEvent' ||
              s.node.__typename === 'TextMessageEvent' ||
              s.node.__typename === 'MeetingEvent' ||
              s.node.__typename === 'PhoneCallEvent' ||
              s.node.__typename === 'Quote') && (
              <div className="ThreadDetailSidebarSearchResultCardFromExpert">
                <ExpertUserBubble
                  expertId={
                    s.node.ownerExpert &&
                    s.node.ownerExpert.id === SUPPORT_EXPERT_ID &&
                    (s.node.__typename === 'MessageEvent' ||
                      s.node.__typename === 'EmailMessageEvent' ||
                      s.node.__typename === 'TextMessageEvent' ||
                      s.node.__typename === 'MeetingEvent' ||
                      s.node.__typename === 'PhoneCallEvent')
                      ? (s.node.recipientExpert && s.node.recipientExpert.id) ||
                        ''
                      : (s.node.ownerExpert && s.node.ownerExpert.id) || ''
                  }
                  expertDetails={
                    s.node.ownerExpert &&
                    s.node.ownerExpert.id === SUPPORT_EXPERT_ID &&
                    (s.node.__typename === 'MessageEvent' ||
                      s.node.__typename === 'EmailMessageEvent' ||
                      s.node.__typename === 'TextMessageEvent' ||
                      s.node.__typename === 'MeetingEvent' ||
                      s.node.__typename === 'PhoneCallEvent')
                      ? s.node.recipientExpert
                      : s.node.ownerExpert
                  }
                  primary
                />
              </div>
            )}
          <div className="ThreadDetailSidebarSearchResultCardSubject">
            {s.plainTextSubject || ''}
          </div>
          {!inHighlight && (
            <div className="ThreadDetailSidebarSearchResultCardText">
              {s.plainTextContent.length > 2 * charsBeforeAfter
                ? s.plainTextContent.slice(0, 2 * charsBeforeAfter) + '...'
                : s.plainTextContent}
            </div>
          )}
          {inHighlight && (
            <div className="ThreadDetailSidebarSearchResultCardText">
              {beforeHighlight}
              <span>{inHighlight}</span>
              {afterHighlight}
            </div>
          )}
        </div>
        <div className="ThreadDetailSidebarSearchResultBottom">
          <div className="ThreadDetailSidebarSearchResultBottomLeft">
            {searchResultType(s.node.__typename!)}
          </div>
          <div
            className="ThreadDetailSidebarSearchResultBottomRight"
            data-tip={eventDateFull}
          >
            {eventDateSummary}
          </div>
        </div>
      </div>
    );
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      toggleSearch();
    }
  }
  function toggleSearch() {
    const nextShowSearch = !showSearch;
    setSearchInput('');
    setSearchResults(null);
    setSelectedSearchResult(null);
    setSearchLoading(false);
    setShowSearch(nextShowSearch);
    if (nextShowSearch) {
      setTimeout(() => {
        if (searchRef && searchRef.current) {
          searchRef.current.focus();
        }
      });
    }
  }
  function requestCardRender(
    r: Exclude<
      MatchDetailsAdminQuery['matchDetails']['leadRequest'],
      null | undefined
    >,
  ) {
    let requestStatusStr = '';
    if (r.isMultipleMatch && r.isActive) {
      requestStatusStr = 'Waiting for multiple match';
    } else if (r.currentMatchStr && !r.inReview) {
      requestStatusStr = 'Matched';
    } else if (!r.isActive) {
      requestStatusStr = 'Canceled';
    } else if (r.inReview) {
      requestStatusStr = 'Needs Review';
    } else {
      requestStatusStr = 'Waiting for single match';
    }
    return (
      <Link key={r.id} to={'/requests/' + r.id} className="AdminLinkedRecord">
        <div className="AdminLinkedRecordTitle">{requestStatusStr}</div>
        {r.createdAt && (
          <div className="AdminLinkedRecordSubtitle">
            Submitted: {moment(r.createdAt).format('MMMM Do, YYYY')}
          </div>
        )}
        {!!r.isMultipleMatch && (
          <div className="AdminLinkedRecordSubtitle AdminLinkedRecordSubtitleAlert">
            Is Multiple Match (made: {r.matchLog ? r.matchLog.length : 0})
          </div>
        )}
        <div className="AdminLinkedRecordTag">Request</div>
      </Link>
    );
  }
  const secondaryPanels = ['Match', 'Projects', 'Info', 'Notes'].filter(
    (p) => p !== primaryPanel,
  );
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
    if (panelName === 'Notes' && !calledUserNotes && matchId) {
      getUserNotes().catch((err: ApolloError) => {
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'getUserNotes',
        });
      });
    }
    if (panelName === 'Info' && !calledDzeAll && matchId) {
      getAllDze().catch((err: ApolloError) => {
        logError(err, {
          component: 'AdminMatchDetail',
          func: 'getAllDze',
        });
      });
    }
  }
  if (
    loadingMatch ||
    loadingThread ||
    loadingUserNotes ||
    loadingRequestsAll ||
    loadingProjectPaginated ||
    loadingDzeAll
  ) {
    // ignore these
  }
  return (
    <div className="DashboardModal AdminMatchDetailModal">
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '') +
          (selectedSearchResult ? ' ThreadDetailViewWithModal ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/dangerzone')
              }
              className="ThreadDetailMainHeaderBack"
            >
              {matchDetails && matchDetails.brand && matchDetails.expert
                ? (matchDetails.expert.firstName || '') +
                  ' ' +
                  (matchDetails.expert.lastName || '') +
                  ' + ' +
                  (matchDetails.brand.name || '')
                : 'Back'}
            </div>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div
            className="ThreadDetailMainBody AdminMatchDetailView"
            ref={threadEventsWindow}
          >
            <div className="ThreadEvents">
              {threadEventGroups.map((evGroup) => (
                <div className="ThreadEventGroup" key={evGroup.group[0].id}>
                  {generateComponentsForGroup(evGroup.group)}
                </div>
              ))}
            </div>
          </div>
          {!!selectedSearchResult && (
            <div className="ThreadDetailMainFocusModal" onClick={toggleSearch}>
              <div
                className="ThreadDetailMainFocusModalContainer ThreadDetailMainFocusModalContainerSearch"
                onClick={blockClickPropagation}
              >
                <ThreadSearchResults
                  expertId={SUPPORT_EXPERT_ID}
                  matchId={matchId}
                  searchResultFound={selectedSearchResult}
                />
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={toggleSearch}
                >
                  close
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div className="ThreadDetailSidebar">
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
            <div
              className={
                'ThreadDetailSidebarHeaderSearch ThreadDetailSidebarHeaderSearch' +
                (showSearch ? 'Active ' : '')
              }
            >
              <div className="ThreadDetailSidebarHeaderSearchBar">
                <input
                  type="text"
                  ref={searchRef}
                  className="ThreadDetailSidebarHeaderSearchBarInput"
                  placeholder="Search"
                  autoComplete="new-off"
                  spellCheck="false"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={handleSearchInputKeyDown}
                />
                <div
                  className="ThreadDetailSidebarHeaderSearchToggle"
                  onClick={toggleSearch}
                />
              </div>
            </div>
          </div>
          <div className="ThreadDetailSidebarBody">
            {!searchLoading && searchResults === null ? (
              <div className="ThreadDetailSidebarBodyPanels">
                {primaryPanel === 'Match' &&
                  matchDetails &&
                  matchDetails.brand &&
                  matchDetails.expert && (
                    <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelMatch">
                      {matchDetails.brand.teamLeader &&
                        matchDetails.brand.teamLeader.expertReferralStr &&
                        matchDetails.brand.teamLeader.expertReferralStr ===
                          matchDetails.expert.id && (
                          <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                            <div className="ThreadDetailSidebarBodyPanelEmpty">
                              <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                                The expert brought this client
                              </div>
                              <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                                Storetasker only takes a 3% cut on clients you
                                bring to the platform and you&apos;re welcome to
                                charge them any hourly rate you like.
                              </div>
                            </div>
                          </div>
                        )}
                      <div className="ThreadDetailSidebarAdminSection">
                        <div className="ThreadDetailSidebarAdminSectionTitle">
                          Brand
                        </div>
                        <Link
                          to={'/brands/' + matchDetails.brand.id}
                          className="AdminLinkedRecord"
                        >
                          <div className="AdminLinkedRecordTitle">
                            {matchDetails.brand.name}
                          </div>
                          <div className="AdminLinkedRecordSubtitle">
                            {matchDetails.brand.url}
                          </div>
                          <div className="AdminLinkedRecordTag">Brand</div>
                        </Link>
                      </div>
                      <div className="ThreadDetailSidebarAdminSection">
                        <div className="ThreadDetailSidebarAdminSectionTitle">
                          Expert
                        </div>
                        <Link
                          to={'/experts/' + matchDetails.expert.id}
                          className="AdminLinkedRecord"
                        >
                          <div className="AdminLinkedRecordTitle">
                            {(matchDetails.expert.firstName || '') +
                              ' ' +
                              (matchDetails.expert.lastName || '')}
                          </div>
                          <div className="AdminLinkedRecordSubtitle">
                            {matchDetails.expert.primaryPublicEmail || ''}
                          </div>
                          <div className="AdminLinkedRecordTag">Expert</div>
                        </Link>
                      </div>
                      <div className="ThreadDetailSidebarAdminSection">
                        <div className="ThreadDetailSidebarAdminSectionTitle">
                          Requests
                        </div>
                        {matchDetails.leadRequest &&
                          requestCardRender(matchDetails.leadRequest)}
                        {!calledRequestsAll && (
                          <div
                            className="AdminListItemLoader"
                            onClick={() => {
                              getAllRequests().catch((err: ApolloError) => {
                                logError(err, {
                                  component: 'AdminMatchDetail',
                                  func: 'getAllRequests',
                                });
                              });
                            }}
                          >
                            {matchDetails.leadRequest
                              ? 'load more'
                              : 'load requests'}
                          </div>
                        )}
                        {allRequestsLoaded.map((r) => requestCardRender(r))}
                        {!matchDetails.leadRequest &&
                          !!calledRequestsAll &&
                          !allRequestsLoaded.length && (
                            <div className="ThreadDetailSidebarAdminEmpty">
                              {errorRequestsAll
                                ? errorUtils.getErrorMessage(errorRequestsAll)
                                : 'No requests found.'}
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                {primaryPanel === 'Projects' && matchDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelProjects">
                    <div className="ThreadDetailSidebarAdminSection">
                      {!allProjectsForBrand.length && (
                        <div className="ThreadDetailSidebarAdminEmpty">
                          {errorProjectPaginated
                            ? errorUtils.getErrorMessage(errorProjectPaginated)
                            : 'No projects yet.'}
                        </div>
                      )}
                      {allProjectsForBrand.map((p) => {
                        let deadlineStr = '';
                        if (p.quote && p.quote.estimatedCompletionDate) {
                          deadlineStr = moment
                            .utc(p.quote.estimatedCompletionDate)
                            .tz('UTC')
                            .format('MMMM Do, YYYY');
                        } else if (p.quote && p.quote.estimatedCompletionDays) {
                          deadlineStr = `${p.quote.estimatedCompletionDays} business days from quote accept`;
                        } else {
                          deadlineStr = 'No deadline found.';
                        }
                        return (
                          <div
                            className="ThreadDetailSidebarAdminProject"
                            key={p.id}
                          >
                            <div className="ThreadDetailSidebarAdminProjectSection">
                              <div className="ThreadDetailSidebarAdminProjectTitle">
                                {(p.quote && p.quote.title) || ''}
                              </div>
                              {p.createdAt && (
                                <div className="ThreadDetailSidebarAdminProjectDescription">
                                  Created:{' '}
                                  {moment(p.createdAt).format('MMMM Do, YYYY')}
                                </div>
                              )}
                              {p.quote && p.quote.createdAt && (
                                <div className="ThreadDetailSidebarAdminProjectDescription">
                                  {quoteStatusFormatted(p.quote.status || '')}:{' '}
                                  {moment(p.quote.createdAt).format(
                                    'MMMM Do, YYYY',
                                  )}
                                </div>
                              )}
                              {p.quote && p.quote.paymentType === 'PROJECT' && (
                                <div className="ThreadDetailSidebarAdminProjectDescription">
                                  Deadline: {deadlineStr}
                                </div>
                              )}
                              {p.quote && p.quote.revisionsRequested && (
                                <div className="ThreadDetailSidebarAdminProjectDescription">
                                  REVISIONS REQUESTED: True
                                </div>
                              )}
                              {p.quote && p.quote.paymentType && (
                                <div className="ThreadDetailSidebarAdminProjectDescription">
                                  Payment Type: {p.quote.paymentType}
                                </div>
                              )}
                              {p.clientFee && (
                                <div className="ThreadDetailSidebarAdminProjectDescription ThreadDetailSidebarAdminProjectDescriptionAlert">
                                  Client fee of {amountClientFee(p.clientFee)}%
                                </div>
                              )}
                              {p.discountCode && (
                                <div className="ThreadDetailSidebarAdminProjectDescription ThreadDetailSidebarAdminProjectDescriptionAlert">
                                  Client used{' '}
                                  {amountDiscountCode(p.discountCode)}% off
                                  discount code
                                </div>
                              )}
                              {p.feedbackValue && (
                                <div className="ThreadDetailSidebarAdminProjectDescription">
                                  Customer Feedback: {p.feedbackValue}
                                </div>
                              )}
                              <div
                                className={
                                  'ThreadDetailSidebarAdminProjectStatusLine CardStatusLine' +
                                  ((p.quote && p.quote.status) || '')
                                }
                              />
                              <div className="ThreadDetailSidebarAdminProjectStatus">
                                {quoteStatusFormatted(
                                  (p.quote && p.quote.status) || '',
                                )}
                              </div>
                              <div className="ThreadDetailSidebarAdminProjectPrice">
                                $
                                {formatNumberWithCommas(
                                  centsDollarsRounded(
                                    (p.quote && p.quote.cents) || 0,
                                  ),
                                )}
                              </div>
                            </div>
                            {p.feedbackValue && p.feedbackDetail && (
                              <div className="ThreadDetailSidebarAdminProjectSection">
                                <div
                                  className={
                                    'ThreadDetailSidebarAdminProjectFeedback ' +
                                    (p.feedbackValue !== 'GOOD'
                                      ? 'ThreadDetailSidebarAdminProjectFeedbackHighlight'
                                      : '')
                                  }
                                >
                                  {p.feedbackValue} Feedback: &quot;
                                  {p.feedbackDetail}&quot;
                                </div>
                              </div>
                            )}
                            {p.quote &&
                              p.quote.status &&
                              p.quote.status !== QuoteStatus.Approved &&
                              p.quote.status !== QuoteStatus.BillPaid &&
                              p.quote.status !== QuoteStatus.Canceled && (
                                <div className="ThreadDetailSidebarAdminProjectSection">
                                  {projectAction === p.quote.id ? (
                                    <div className="ThreadDetailSidebarAdminProjectLoading" />
                                  ) : (
                                    <Fragment>
                                      {p.quote.paymentType === 'PROJECT' && (
                                        <div
                                          className="ThreadDetailSidebarAdminProjectAction"
                                          onClick={() =>
                                            onProjectApprove(p.quote.id)
                                          }
                                        >
                                          Approve Project
                                        </div>
                                      )}
                                      <div
                                        className="ThreadDetailSidebarAdminProjectAction ThreadDetailSidebarAdminProjectActionBad"
                                        onClick={() =>
                                          onProjectCancel(p.quote.id)
                                        }
                                      >
                                        Cancel Project
                                      </div>
                                    </Fragment>
                                  )}
                                </div>
                              )}
                          </div>
                        );
                      })}
                      {allProjectsForBrand.length >= ACTIVITY_PAGE_LIMIT &&
                        oldestCreatedProject &&
                        showLoadMoreProjects && (
                          <div
                            className="AdminListItemLoader"
                            onClick={loadMoreProjects}
                          >
                            load more projects
                          </div>
                        )}
                    </div>
                  </div>
                )}
                {primaryPanel === 'Info' && matchDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelInfo">
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Info
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Matched:{' '}
                        {moment(matchDetails.createdAt).format('MMMM Do, YYYY')}
                      </div>
                      {matchDetails.isBlocked && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Expert Blocked: TRUE
                        </div>
                      )}
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Expert Unread:{' '}
                        {matchDetails.expertUnread ? 'True' : 'False'}
                      </div>
                      {matchDetails.excludeFromExpertPerformanceMetrics && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Excluded from Expert KPIs: TRUE
                        </div>
                      )}
                      {!matchDetails.isBlocked && !!matchDetails.isLead && (
                        <Fragment>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Active Claim: TRUE
                          </div>
                          <div
                            className={
                              'ThreadDetailSidebarAdminAction ' +
                              (releaseLoading
                                ? ' ThreadDetailSidebarAdminActionLoading '
                                : '')
                            }
                            onClick={onReleaseClaim}
                          >
                            release expert claim
                          </div>
                        </Fragment>
                      )}
                      <div
                        className={
                          'ThreadDetailSidebarAdminAction ' +
                          (excludeLoading
                            ? ' ThreadDetailSidebarAdminActionLoading '
                            : '')
                        }
                        onClick={() =>
                          onExcludeKpis(
                            !matchDetails.excludeFromExpertPerformanceMetrics,
                          )
                        }
                      >
                        {matchDetails.excludeFromExpertPerformanceMetrics
                          ? 'Include in Expert KPIs'
                          : 'Exclude from Expert KPIs'}
                      </div>
                    </div>
                    <div className="ThreadDetailSidebarAdminSection ThreadDetailSidebarAdminSectionMinHeight">
                      {dangerLoading ? (
                        <div className="ThreadDetailSidebarAdminSectionLoading" />
                      ) : (
                        <Fragment>
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Danger Zone
                          </div>
                          {matchDetails.dangerZoneEvent &&
                          matchDetails.dangerZoneEvent.type !== 'REMOVE' ? (
                            <Fragment>
                              <div className="ThreadDetailSidebarAdminSectionDescription">
                                Danger Zone Reason:{' '}
                                {matchDetails.dangerZoneEvent
                                  .dangerZoneReason || 'True'}
                              </div>
                              <div className="ThreadDetailSidebarAdminSectionDescription">
                                Is Support Case:{' '}
                                {matchDetails.dangerZoneEvent.isSupportCase
                                  ? 'True'
                                  : 'False'}
                              </div>
                              {!!matchDetails.dangerZoneEvent
                                .dangerZoneAssignment && (
                                <div className="ThreadDetailSidebarAdminSectionDescription">
                                  Assigned to:{' '}
                                  {
                                    matchDetails.dangerZoneEvent
                                      .dangerZoneAssignment
                                  }
                                </div>
                              )}
                              {!!matchDetails.dangerZoneEvent
                                .dangerZoneSnoozedUntil &&
                                matchDetails.dangerZoneEvent
                                  .dangerZoneSnoozedUntil > initTime && (
                                  <div className="ThreadDetailSidebarAdminSectionDescription">
                                    Snoozed until:{' '}
                                    {moment(
                                      matchDetails.dangerZoneEvent
                                        .dangerZoneSnoozedUntil,
                                    ).format('MMMM Do') +
                                      ' at ' +
                                      moment(
                                        matchDetails.dangerZoneEvent
                                          .dangerZoneSnoozedUntil,
                                      ).format('h:mma z')}
                                  </div>
                                )}
                              <select
                                className="ThreadDetailSidebarAdminActionSelect"
                                value={dzeRemovalReason}
                                onChange={(e) =>
                                  onSetDanger(false, e.currentTarget.value)
                                }
                              >
                                <option value="" disabled>
                                  Remove from danger zone
                                </option>
                                {DANGER_ZONE_REMOVAL_REASONS.map((reason) => (
                                  <option
                                    key={reason.value}
                                    value={reason.value}
                                  >
                                    {reason.label}
                                  </option>
                                ))}
                              </select>
                              {!!matchDetails.dangerZoneEvent && (
                                <div className="ThreadDetailSidebarAdminSupportCase">
                                  {dzeWriteCase ? (
                                    <div className="ThreadDetailSidebarAdminSupportCaseEditor">
                                      <TextareaAutosize
                                        type="text"
                                        placeholder="Support Case Details (shown to expert)"
                                        spellCheck="true"
                                        className="ThreadDetailSidebarAdminSupportCaseEditorTextarea"
                                        value={dzeDescription}
                                        onChange={(e) => {
                                          setDzeDescription(
                                            e.currentTarget.value,
                                          );
                                        }}
                                      />
                                      <div className="ThreadDetailSidebarAdminSupportCaseEditorFooter">
                                        <div
                                          onClick={() =>
                                            onSupportCaseDanger(true)
                                          }
                                          className="ThreadDetailSidebarAdminSupportCaseEditorFooterBtn"
                                        >
                                          save
                                        </div>
                                        <div
                                          onClick={() => setDzeWriteCase(false)}
                                          className="ThreadDetailSidebarAdminSupportCaseEditorFooterBtn"
                                        >
                                          cancel
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <Fragment>
                                      {!!matchDetails.dangerZoneEvent
                                        .isSupportCase &&
                                        !!matchDetails.dangerZoneEvent
                                          .dangerZoneAdditionalDescription && (
                                          <div className="ThreadDetailSidebarAdminSupportCaseText">
                                            {
                                              matchDetails.dangerZoneEvent
                                                .dangerZoneAdditionalDescription
                                            }
                                          </div>
                                        )}
                                      {matchDetails.dangerZoneEvent
                                        .isSupportCase ? (
                                        <Fragment>
                                          <div
                                            className="ThreadDetailSidebarAdminAction"
                                            onClick={startWriteCase}
                                          >
                                            Update Support Case
                                          </div>
                                          <div
                                            className="ThreadDetailSidebarAdminAction ThreadDetailSidebarAdminActionBAD"
                                            onClick={() =>
                                              onSupportCaseDanger(false)
                                            }
                                          >
                                            No Longer A Support Case
                                          </div>
                                        </Fragment>
                                      ) : (
                                        <div
                                          className="ThreadDetailSidebarAdminAction"
                                          onClick={startWriteCase}
                                        >
                                          Mark As Support Case
                                        </div>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                              )}
                              {matchDetails.dangerZoneEvent
                                .dangerZoneAssignment && (
                                <div
                                  className="ThreadDetailSidebarAdminAction"
                                  onClick={() => onAssignDanger('')}
                                >
                                  Remove Assignment:{' '}
                                  {
                                    matchDetails.dangerZoneEvent
                                      .dangerZoneAssignment
                                  }
                                </div>
                              )}
                              <select
                                className="ThreadDetailSidebarAdminActionSelect"
                                value={dzeAssignment}
                                onChange={(e) =>
                                  onAssignDanger(e.currentTarget.value)
                                }
                              >
                                <option value="" disabled>
                                  Assign team member
                                </option>
                                <option value="Richard">Richard</option>
                                <option value="Tim">Tim</option>
                                <option value="Robin">Robin</option>
                              </select>
                              {matchDetails.dangerZoneEvent
                                .dangerZoneSnoozedUntil &&
                              matchDetails.dangerZoneEvent
                                .dangerZoneSnoozedUntil > initTime ? (
                                <div
                                  className="ThreadDetailSidebarAdminAction"
                                  onClick={() => onSnoozeDanger()}
                                >
                                  Unsnooze Danger Zone
                                </div>
                              ) : (
                                <select
                                  className="ThreadDetailSidebarAdminActionSelect"
                                  value={dzeSnoozed}
                                  onChange={(e) =>
                                    onSnoozeDanger(e.currentTarget.value)
                                  }
                                >
                                  <option value="" disabled>
                                    Snooze for X days
                                  </option>
                                  <option value="1">1 Day</option>
                                  <option value="2">2 Days</option>
                                  <option value="3">3 Days</option>
                                  <option value="4">4 Days</option>
                                  <option value="5">5 Days</option>
                                  <option value="6">6 Days</option>
                                  <option value="7">7 Days</option>
                                </select>
                              )}
                            </Fragment>
                          ) : (
                            <Fragment>
                              <div className="ThreadDetailSidebarAdminSectionDescription">
                                In Danger Zone: False
                              </div>
                              <div
                                className="ThreadDetailSidebarAdminAction ThreadDetailSidebarAdminActionBAD"
                                onClick={() => onSetDanger(true)}
                              >
                                Put In Danger Zone
                              </div>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </div>
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Danger Zone Log
                      </div>
                      {allDzeLoaded.map((dze) => (
                        <div key={dze.id} className="AdminDangerZoneEvent">
                          {dze.type && (
                            <div className="AdminDangerZoneEventDescription">
                              Action: {dze.type}
                            </div>
                          )}
                          {dze.dangerZoneReason && (
                            <div className="AdminDangerZoneEventDescription">
                              Reason: {dze.dangerZoneReason}
                            </div>
                          )}
                          {dze.expert && (
                            <div className="AdminDangerZoneEventDescription">
                              Done by: {dze.expert.firstName || ''}{' '}
                              {dze.expert.lastName || ''}
                            </div>
                          )}
                          {dze.isSupportCase && (
                            <div className="AdminDangerZoneEventDescription">
                              Is Support Case: True
                            </div>
                          )}
                          {dze.dangerZoneAssignment && (
                            <div className="AdminDangerZoneEventDescription">
                              Assigned: {dze.dangerZoneAssignment}
                            </div>
                          )}
                          {dze.dangerZoneAdditionalDescription && (
                            <div className="AdminDangerZoneEventDescription">
                              Description: {dze.dangerZoneAdditionalDescription}
                            </div>
                          )}
                          {dze.dangerZoneRemovalReason && (
                            <div className="AdminDangerZoneEventDescription">
                              Removed: {dze.dangerZoneRemovalReason}
                            </div>
                          )}
                          {dze.createdAt && (
                            <div className="AdminDangerZoneEventDescription">
                              Date:{' '}
                              {moment(dze.createdAt).format(
                                'MMMM Do, YYYY h:mma z',
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                      {!!calledDzeAll && !allDzeLoaded.length && (
                        <div className="ThreadDetailSidebarAdminEmpty">
                          {errorDzeAll
                            ? errorUtils.getErrorMessage(errorDzeAll)
                            : 'No danger zone events found.'}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {primaryPanel === 'Notes' && matchDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelTeam">
                    <div className="ThreadDetailSidebarAdminSection">
                      <UserNoteEditor
                        onSave={onSaveNote}
                        isLoading={noteLoading}
                        errorFound={errorUserNotes}
                        userNotes={allUserNotes}
                      />
                    </div>
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Export Raw Data
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        <a
                          href={`${envUtils.pick(
                            'https://api.storetasker.com',
                            'https://dev.storetasker.com',
                            'http://localhost:4000',
                          )}/export/raw?match=${
                            matchDetails.id
                          }&token=${oauthUtils.getToken('admin')}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ThreadDetailSidebarAboutWebsiteLink"
                        >
                          Export Chat History
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="ThreadDetailSidebarBodySearch">
                {!searchLoading && searchResults && !searchResults.length && (
                  <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                    <div className="ThreadDetailSidebarBodyPanelEmpty">
                      <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                        No search results found
                      </div>
                      <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                        Please try again with another search.
                      </div>
                    </div>
                  </div>
                )}
                {!searchLoading && searchResults && !!searchResults.length && (
                  <div className="ThreadDetailSidebarSearchResults">
                    {searchResults.map(renderSearchResult)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/dangerzone')
              }
              className="DashboardErrorCoverNav"
            >
              back
            </div>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminMatchDetail.propTypes = {
  matchId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default AdminMatchDetail;
