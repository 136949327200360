import _ from 'lodash';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
// import logError from '../../utils/airbrake';
// import errorUtils from '../../utils/error';
import RequestThreadEvent from '../threadEvent/RequestThreadEvent';
import EmailMessageThreadEvent from '../threadEvent/EmailMessageThreadEvent';
import TextMessageThreadEvent from '../threadEvent/TextMessageThreadEvent';
import QuoteThreadEvent from '../threadEvent/QuoteThreadEvent';
import MessageThreadEvent from '../threadEvent/MessageThreadEvent';
import PhoneCallThreadEvent from '../threadEvent/PhoneCallThreadEvent';
import MeetingThreadEvent from '../threadEvent/MeetingThreadEvent';
import {
  TextMessageEventDetail,
  EmailMessageEventDetail,
  MessageEventDetail,
  MeetingEventDetail,
  QuoteDetail,
  PhoneCallEventDetail,
  RequestEventDetail,
} from '../../utils/gql';
import {
  MatchThreadSearchMutation,
  QuoteStatus,
  SearchResultsThreadPaginatedQuery,
  SearchResultsThreadPaginatedQueryVariables,
} from '../../gql/graphql';

// @connection(key: "thread", filter: ["matchId"])
const searchResultsThreadPaginatedQuery = gql`
  query SearchResultsThreadPaginated(
    $matchId: ID
    $supportChannelId: ID
    $direction: String!
    $fromDate: Date
    $limit: Int!
  ) {
    threadPaginated(
      matchId: $matchId
      supportChannelId: $supportChannelId
      direction: $direction
      fromDate: $fromDate
      limit: $limit
    ) {
      id
      edges {
        id
        cursor
        node {
          ... on TextMessageEvent {
            ...TextMessageEventDetail
          }
          ... on EmailMessageEvent {
            ...EmailMessageEventDetail
          }
          ... on RequestEvent {
            ...RequestEventDetail
          }
          ... on MessageEvent {
            ...MessageEventDetail
          }
          ... on MeetingEvent {
            ...MeetingEventDetail
          }
          ... on Quote {
            ...QuoteDetail
          }
          ... on PhoneCallEvent {
            ...PhoneCallEventDetail
          }
        }
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

interface ThreadSearchResultsProps {
  expertId: string;
  matchId?: string;
  supportChannelId?: string;
  searchResultFound: MatchThreadSearchMutation['threadSearch'][0];
}

interface IGroupedEvents {
  group: SearchResultsThreadPaginatedQuery['threadPaginated']['edges'];
}

const PAGE_LIMIT = 10;
let scrollDownQuickTimeout: NodeJS.Timeout | undefined;

const ThreadSearchResults = ({
  expertId,
  matchId,
  supportChannelId,
  searchResultFound,
}: ThreadSearchResultsProps) => {
  const threadEventsWindow = useRef<HTMLDivElement>(null);
  const threadEventFound = useRef<HTMLDivElement>(null);
  const {
    data: dataThread,
    error: errorThread,
    loading: loadingThread,
  } = useQuery<
    SearchResultsThreadPaginatedQuery,
    SearchResultsThreadPaginatedQueryVariables
  >(searchResultsThreadPaginatedQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      scrollToSelected();
    },
    variables: {
      direction: 'both',
      fromDate: searchResultFound.node.createdAt,
      limit: PAGE_LIMIT,
      matchId,
      supportChannelId,
    },
  });
  if (errorThread) {
    console.error(errorThread);
  }
  const cleanThread =
    (dataThread &&
      dataThread.threadPaginated &&
      dataThread.threadPaginated.edges) ||
    [];
  if (loadingThread) {
    // ignore these
  }
  useEffect(() => {
    scrollToSelected();
    const scrollDownEffectTimeout = setTimeout(() => {
      scrollToSelected();
    }, 500);
    return () => {
      if (scrollDownEffectTimeout) {
        clearTimeout(scrollDownEffectTimeout);
      }
      if (scrollDownQuickTimeout) {
        clearTimeout(scrollDownQuickTimeout);
      }
    };
  }, []);
  function scrollToSelected() {
    scrollDownQuickTimeout = setTimeout(() => {
      if (
        threadEventsWindow &&
        threadEventsWindow.current &&
        threadEventFound &&
        threadEventFound.current &&
        threadEventFound.current.offsetParent
      ) {
        threadEventsWindow.current.scrollTop =
          threadEventFound.current.offsetTop +
          (threadEventFound.current.offsetParent as HTMLDivElement).offsetTop -
          50;
      }
    }, 5);
  }
  function groupThreadEvents(
    events: SearchResultsThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    const groupedEvents: IGroupedEvents[] = [];
    events.forEach((ev) => {
      let isSameEventType = false;
      let isSameSenderSide = false;
      let isSameHuman = false;
      let isCurrGroupable = false;
      let isLastGroupable = false;
      const isFirstGroup = !groupedEvents.length;
      if (
        !isFirstGroup &&
        (ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'MessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent')
      ) {
        isCurrGroupable = true;
        isLastGroupable = true;
        const lastGroup = groupedEvents[groupedEvents.length - 1];
        const lastGroupEvent = lastGroup.group[0];
        // is same event type as last
        if (lastGroupEvent.node.__typename === ev.node.__typename) {
          isSameEventType = true;
          // is both sent from expert or both from Human
          isSameSenderSide =
            !!(ev.node.ownerHuman && lastGroupEvent.node.ownerHuman) ||
            !!(ev.node.ownerExpert && lastGroupEvent.node.ownerExpert);
          // is human interacting same
          isSameHuman =
            (ev.node.ownerHuman &&
              lastGroupEvent.node.ownerHuman &&
              ev.node.ownerHuman.id === lastGroupEvent.node.ownerHuman.id) ||
            (ev.node.recipientHumans[0] &&
              lastGroupEvent.node.recipientHumans[0] &&
              ev.node.recipientHumans[0].id ===
                lastGroupEvent.node.recipientHumans[0].id);
          // don't group texts with quotes attached
          if (ev.node.__typename === 'TextMessageEvent') {
            if (ev.node.quote) {
              isCurrGroupable = false;
            }
          }
          if (lastGroupEvent.node.__typename === 'TextMessageEvent') {
            if (lastGroupEvent.node.quote) {
              isLastGroupable = false;
            }
          }
        }
      }
      if (
        isFirstGroup ||
        !isCurrGroupable ||
        !isLastGroupable ||
        !isSameEventType ||
        !isSameSenderSide ||
        !isSameHuman
      ) {
        groupedEvents.push({
          group: [ev],
        });
      } else {
        groupedEvents[groupedEvents.length - 1].group.push(ev);
      }
    });
    return groupedEvents;
  }
  const threadEventEdges = _.uniqBy(
    cleanThread.concat({
      __typename: 'ThreadEventEdge',
      cursor: searchResultFound.node.createdAt,
      id: searchResultFound.node.threadEvent,
      node: searchResultFound.node,
    }),
    'id',
  )
    .sort((a, b) => a.cursor - b.cursor)
    .filter((ev) => {
      if (ev.node.__typename === 'Quote') {
        // filter out quote accepts on subscriptions
        return (
          ev.node.paymentType === 'PROJECT' ||
          ev.node.paymentType === 'BILL' ||
          ev.node.status !== QuoteStatus.Accepted
        );
      }
      return true;
    });
  const threadEventGroups = groupThreadEvents(threadEventEdges);
  function generateComponentsForGroup(
    groupedEvents: SearchResultsThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    return groupedEvents
      .map((ev, i) => {
        if (ev.node.__typename === 'RequestEvent') {
          return (
            <div
              key={ev.id}
              ref={
                searchResultFound.node.id === ev.node.id
                  ? threadEventFound
                  : null
              }
            >
              <RequestThreadEvent
                className={
                  searchResultFound.node.id === ev.node.id
                    ? ' MatchSearchResultHighlight '
                    : ''
                }
                requestEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
              />
            </div>
          );
        }
        if (ev.node.__typename === 'MessageEvent') {
          return (
            <div
              key={ev.id}
              ref={
                searchResultFound.node.id === ev.node.id
                  ? threadEventFound
                  : null
              }
            >
              <MessageThreadEvent
                className={
                  searchResultFound.node.id === ev.node.id
                    ? ' MatchSearchResultHighlight '
                    : ''
                }
                expertId={expertId}
                isGroupFirst={!i}
                isGroupLast={i === groupedEvents.length - 1}
                messageEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
              />
            </div>
          );
        }
        if (ev.node.__typename === 'Quote') {
          return (
            <div
              key={ev.id}
              ref={
                searchResultFound.node.id === ev.node.id
                  ? threadEventFound
                  : null
              }
            >
              <QuoteThreadEvent
                className={
                  searchResultFound.node.id === ev.node.id
                    ? ' MatchSearchResultHighlight '
                    : ''
                }
                quote={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
              />
            </div>
          );
        }
        if (ev.node.__typename === 'EmailMessageEvent') {
          return (
            <div
              key={ev.id}
              ref={
                searchResultFound.node.id === ev.node.id
                  ? threadEventFound
                  : null
              }
            >
              <EmailMessageThreadEvent
                className={
                  searchResultFound.node.id === ev.node.id
                    ? ' MatchSearchResultHighlight '
                    : ''
                }
                expertId={expertId}
                emailMessageEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
              />
            </div>
          );
        }
        if (ev.node.__typename === 'TextMessageEvent') {
          return (
            <div
              key={ev.id}
              ref={
                searchResultFound.node.id === ev.node.id
                  ? threadEventFound
                  : null
              }
            >
              <TextMessageThreadEvent
                className={
                  searchResultFound.node.id === ev.node.id
                    ? ' MatchSearchResultHighlight '
                    : ''
                }
                expertId={expertId}
                isGroupFirst={!i}
                isGroupLast={i === groupedEvents.length - 1}
                textMessageEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
              />
            </div>
          );
        }
        if (ev.node.__typename === 'PhoneCallEvent') {
          return (
            <div
              key={ev.id}
              ref={
                searchResultFound.node.id === ev.node.id
                  ? threadEventFound
                  : null
              }
            >
              <PhoneCallThreadEvent
                className={
                  searchResultFound.node.id === ev.node.id
                    ? ' MatchSearchResultHighlight '
                    : ''
                }
                isGroupFirst={!i}
                isGroupLast={i === groupedEvents.length - 1}
                phoneCallEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
              />
            </div>
          );
        }
        if (ev.node.__typename === 'MeetingEvent') {
          return (
            <div
              key={ev.id}
              ref={
                searchResultFound.node.id === ev.node.id
                  ? threadEventFound
                  : null
              }
            >
              <MeetingThreadEvent
                className={
                  searchResultFound.node.id === ev.node.id
                    ? ' MatchSearchResultHighlight '
                    : ''
                }
                meetingEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
              />
            </div>
          );
        }
        console.log('Missing ThreadEvent type');
        return null;
      })
      .filter((c) => c);
  }
  return (
    <div
      className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodySearchResults ThreadSearchResults"
      ref={threadEventsWindow}
    >
      <div className="ThreadEvents">
        {threadEventGroups.map((evGroup) => (
          <div className="ThreadEventGroup" key={evGroup.group[0].id}>
            {generateComponentsForGroup(evGroup.group)}
          </div>
        ))}
      </div>
    </div>
  );
};

ThreadSearchResults.propTypes = {
  expertId: PropTypes.string.isRequired,
  matchId: PropTypes.string,
  searchResultFound: PropTypes.object.isRequired,
  supportChannelId: PropTypes.string,
};

export default ThreadSearchResults;
