import { useState, useContext, Fragment } from 'react';
import _ from 'lodash';
import { useQuery, gql, useMutation, ApolloError } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import {
  AdminActiveForAdminQuery,
  AdminPastForAdminQuery,
  AdminCreateAdminMutation,
  AdminCreateAdminMutationVariables,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { AdminDetailHq } from '../../utils/gql';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';

const adminActiveForAdminQuery = gql`
  query AdminActiveForAdmin {
    adminActiveForAdmin {
      ...AdminDetailHq
    }
  }
  ${AdminDetailHq}
`;

const adminPastForAdminQuery = gql`
  query AdminPastForAdmin {
    adminPastForAdmin {
      ...AdminDetailHq
    }
  }
  ${AdminDetailHq}
`;

const adminCreateAdminMutation = gql`
  mutation AdminCreateAdmin(
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    adminCreateAdmin(
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      ...AdminDetailHq
    }
  }
  ${AdminDetailHq}
`;

const AdminAdmins = () => {
  const history = useHistory();
  const [actionLoading, setActionLoading] = useState(false);
  const [newAdminFirst, setNewAdminFirst] = useState('');
  const [newAdminLast, setNewAdminLast] = useState('');
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const { addNotification } = useContext(GlobalNotificationContext);
  const {
    data: dataAdmins,
    error: errorAdmins,
    loading: loadingAdmins,
    refetch: refetchAdmins,
  } = useQuery<AdminActiveForAdminQuery>(adminActiveForAdminQuery, {
    returnPartialData: true,
  });
  const activeAdmins = _.uniqBy(
    [...((dataAdmins && dataAdmins.adminActiveForAdmin) || [])].filter(
      (e) => e.status === 'admin' && !e.blockAdminAccess,
    ),
    'id',
  );
  const {
    data: dataAdminsPast,
    error: errorAdminsPast,
    loading: loadingAdminsPast,
  } = useQuery<AdminPastForAdminQuery>(adminPastForAdminQuery, {
    returnPartialData: true,
  });
  const pastAdmins = _.uniqBy(
    [...((dataAdminsPast && dataAdminsPast.adminPastForAdmin) || [])].filter(
      (e) => e.status === 'admin' && !!e.blockAdminAccess,
    ),
    'id',
  );
  const [tryCreateAdmin] = useMutation<
    AdminCreateAdminMutation,
    AdminCreateAdminMutationVariables
  >(adminCreateAdminMutation);
  function saveNewAdmin() {
    if (actionLoading) return;
    if (!newAdminFirst || !newAdminLast || !newAdminEmail) {
      addNotification('Please submit all the fields!', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryCreateAdmin({
      variables: {
        email: newAdminEmail,
        firstName: newAdminFirst,
        lastName: newAdminLast,
      },
    })
      .then(({ data: mutationData }) => {
        setActionLoading(false);
        setNewAdminFirst('');
        setNewAdminLast('');
        setNewAdminEmail('');
        if (mutationData && mutationData.adminCreateAdmin) {
          history.push('/extras/hq/' + mutationData.adminCreateAdmin.id);
        }
        refetchAdmins().catch((err: ApolloError) => {
          addNotification(errorUtils.getErrorMessage(err) || 'Refetch Error');
          logError(err, {
            component: 'AdminAdmins',
            func: 'refetchAdmins',
          });
        });
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Admin Create Error',
        );
        logError(err, {
          component: 'AdminAdmins',
          func: 'tryCreateAdmin',
        });
      });
  }
  if (loadingAdmins || loadingAdminsPast) {
    // ignore these
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div className="DashboardScreenContent ClientsScreenContent">
        <Fragment>
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">Active Admins</div>
            {!activeAdmins.length && (
              <div className="DashboardSectionEmpty">
                {errorAdmins
                  ? errorUtils.getErrorMessage(errorAdmins) || 'Loading Error'
                  : 'No active admins found.'}
              </div>
            )}
            <div className="DashboardSectionScrollerSwitch">
              {activeAdmins.map((e) => (
                <div key={e.id} className="ClientSummary ClientSummaryRecent">
                  <Link to={'/extras/hq/' + e.id} className="ClientSummaryBody">
                    <div className="ClientSummaryTitle">
                      {((e.firstName || '') + ' ' + (e.lastName || '')).trim()}
                    </div>
                    <div className="ClientSummarySubTitle">
                      {e.primaryPersonalEmail}
                    </div>
                  </Link>
                  <div className="ClientSummaryFooter" />
                </div>
              ))}
            </div>
          </div>
          <div className="AdminDashboardForm">
            <div className="AdminDashboardFormTitle">Create a new admin?</div>
            <div className="AdminDashboardFormField">
              <div className="AdminDashboardFormFieldTitle">First Name</div>
              <input
                className="AdminDashboardFormFieldInput"
                type="text"
                autoComplete="new-off"
                spellCheck="false"
                placeholder="First Name"
                value={newAdminFirst}
                onChange={(e) => setNewAdminFirst(e.currentTarget.value)}
              />
            </div>
            <div className="AdminDashboardFormField">
              <div className="AdminDashboardFormFieldTitle">Last Name</div>
              <input
                className="AdminDashboardFormFieldInput"
                type="text"
                autoComplete="new-off"
                spellCheck="false"
                placeholder="Last Name"
                value={newAdminLast}
                onChange={(e) => setNewAdminLast(e.currentTarget.value)}
              />
            </div>
            <div className="AdminDashboardFormField">
              <div className="AdminDashboardFormFieldTitle">
                Google Suite Email (hq.storetasker.com)
              </div>
              <input
                className="AdminDashboardFormFieldInput"
                type="email"
                autoComplete="new-off"
                spellCheck="false"
                placeholder="sam@hq.storetasker.com"
                value={newAdminEmail}
                onChange={(e) => setNewAdminEmail(e.currentTarget.value)}
              />
            </div>
            <div
              className={
                'AdminDashboardFormAction ' +
                (actionLoading ? ' AdminDashboardFormActionLoading ' : '')
              }
              onClick={saveNewAdmin}
            >
              Create
            </div>
          </div>
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">Past Admins</div>
            {!pastAdmins.length && (
              <div className="DashboardSectionEmpty">
                {errorAdminsPast
                  ? errorUtils.getErrorMessage(errorAdminsPast) ||
                    'Loading Error'
                  : 'No past admins found.'}
              </div>
            )}
            <div className="DashboardSectionScrollerSwitch">
              {pastAdmins.map((e) => (
                <div key={e.id} className="ClientSummary ClientSummaryRecent">
                  <Link to={'/extras/hq/' + e.id} className="ClientSummaryBody">
                    <div className="ClientSummaryTitle">
                      {((e.firstName || '') + ' ' + (e.lastName || '')).trim()}
                    </div>
                    <div className="ClientSummarySubTitle">
                      {e.primaryPersonalEmail}
                    </div>
                  </Link>
                  <div className="ClientSummaryFooter" />
                </div>
              ))}
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
};

AdminAdmins.propTypes = {};

export default AdminAdmins;
