import React, {
  useState,
  useRef,
  useContext,
  Fragment,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useQuery, gql, useMutation, ApolloError } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { SUPPORT_EXPERT_ID } from '../../utils/constants';
import {
  MatchAlertsForAdminFullQuery,
  BrandHumanSearchForAdminMutation,
  BrandHumanSearchForAdminMutationVariables,
  HumanCreateByAdminMutation,
  HumanCreateByAdminMutationVariables,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { MatchActionSummary, HumanSummary } from '../../utils/gql';
import PhoneNumberInput from '../feature/PhoneNumberInput';
import { unreadReasonFormatted } from '../../utils/format';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';

const matchAlertsForAdminFullQuery = gql`
  query MatchAlertsForAdminFull {
    matchAlertsForAdmin {
      ...MatchActionSummary
    }
  }
  ${MatchActionSummary}
`;

const brandHumanSearchForAdminMutation = gql`
  mutation BrandHumanSearchForAdmin($searchQuery: String!) {
    brandHumanSearchForAdmin(searchQuery: $searchQuery) {
      brands {
        id
        name
        url
        shopifyAdminURL
      }
      humans {
        id
        firstName
        lastName
        primaryEmail
        primaryPhone
      }
    }
  }
`;

const humanCreateByAdminMutation = gql`
  mutation HumanCreateByAdmin(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
  ) {
    humanCreateByAdmin(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
    ) {
      ...HumanSummary
    }
  }
  ${HumanSummary}
`;

interface AdminBrandsHumansProps {
  socketClient: ClientWithOnReconnected;
}

const initTime = new Date().getTime();

const AdminBrandsHumans = ({ socketClient }: AdminBrandsHumansProps) => {
  const history = useHistory();
  const searchRef = useRef<HTMLInputElement>(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchBrandResults, setSearchBrandResults] = useState<
    | BrandHumanSearchForAdminMutation['brandHumanSearchForAdmin']['brands']
    | null
  >(null);
  const [searchHumanResults, setSearchHumanResults] = useState<
    | BrandHumanSearchForAdminMutation['brandHumanSearchForAdmin']['humans']
    | null
  >(null);
  const [newHumanFirst, setNewHumanFirst] = useState('');
  const [newHumanLast, setNewHumanLast] = useState('');
  const [newHumanEmail, setNewHumanEmail] = useState('');
  const [newHumanPhone, setNewHumanPhone] = useState('');
  const [newHumanPhoneValid, setNewHumanPhoneValid] = useState(true);
  const { addNotification } = useContext(GlobalNotificationContext);
  const {
    data: dataMatchAlerts,
    error: errorMatchAlerts,
    loading: loadingMatchAlerts,
    refetch: refetchMatchAlerts,
  } = useQuery<MatchAlertsForAdminFullQuery>(matchAlertsForAdminFullQuery, {
    returnPartialData: true,
  });
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminBrandsHumans socketClient onReconnected');
      refetchMatchAlerts().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchMatchAlerts]);
  // combine all data, dedup, filter, sort into 4 groups, render
  const unreadBrandMatches = _.uniqBy(
    [
      ...((dataMatchAlerts && dataMatchAlerts.matchAlertsForAdmin) || []),
    ].filter(
      (m) =>
        m.expertUnread &&
        m.expertStr === SUPPORT_EXPERT_ID &&
        (!m.expertSnoozedUntil || m.expertSnoozedUntil <= initTime),
    ),
    'id',
  ).sort((a, b) => a.lastUpdated - b.lastUpdated);
  const [tryHumanCreateAdmin] = useMutation<
    HumanCreateByAdminMutation,
    HumanCreateByAdminMutationVariables
  >(humanCreateByAdminMutation);
  function saveNewHuman() {
    if (actionLoading) return;
    if (
      !newHumanFirst ||
      !newHumanLast ||
      !newHumanEmail ||
      !newHumanPhoneValid
    ) {
      addNotification('Please submit name and email at least', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryHumanCreateAdmin({
      variables: {
        email: newHumanEmail,
        firstName: newHumanFirst,
        lastName: newHumanLast,
        phone: newHumanPhone,
      },
    })
      .then(({ data: mutationData }) => {
        setActionLoading(false);
        setNewHumanFirst('');
        setNewHumanLast('');
        setNewHumanEmail('');
        setNewHumanPhone('');
        if (mutationData && mutationData.humanCreateByAdmin) {
          history.push('/humans/' + mutationData.humanCreateByAdmin.id);
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Human Create Error',
        );
        logError(err, {
          component: 'AdminBrandsHumans',
          func: 'tryHumanCreateAdmin',
        });
      });
  }
  const [tryBrandsHumansSearch] = useMutation<
    BrandHumanSearchForAdminMutation,
    BrandHumanSearchForAdminMutationVariables
  >(brandHumanSearchForAdminMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput) {
      toggleSearch();
      return;
    }
    if (searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchBrandResults([]);
    setSearchHumanResults([]);
    tryBrandsHumansSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        setSearchLoading(false);
        if (dataMatchSearch && dataMatchSearch.brandHumanSearchForAdmin) {
          console.log(
            'tryBrandsHumansSearch',
            dataMatchSearch.brandHumanSearchForAdmin,
          );
          setSearchBrandResults(
            dataMatchSearch.brandHumanSearchForAdmin.brands,
          );
          setSearchHumanResults(
            dataMatchSearch.brandHumanSearchForAdmin.humans,
          );
        }
      },
      variables: {
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Client Search Error');
      logError(err, {
        component: 'AdminBrandsHumans',
        func: 'tryBrandsHumansSearch',
      });
      setSearchLoading(false);
      setSearchHumanResults(null);
      setSearchBrandResults(null);
    });
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      toggleSearch();
    }
  }
  function toggleSearch() {
    const nextShowSearch = !showSearch;
    setSearchInput('');
    setSearchHumanResults(null);
    setSearchBrandResults(null);
    setSearchLoading(false);
    setShowSearch(nextShowSearch);
    if (nextShowSearch) {
      setTimeout(() => {
        if (searchRef && searchRef.current) {
          searchRef.current.focus();
        }
      });
    }
  }
  function phoneNumberChange(phone: string, isValid: boolean) {
    setNewHumanPhone(phone);
    if (!phone || isValid) {
      setNewHumanPhoneValid(true);
    }
  }
  function phoneNumberBlur(isValid: boolean) {
    setNewHumanPhoneValid(!newHumanPhone || isValid);
  }
  if (loadingMatchAlerts) {
    // ignore these
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div
        className={
          'ClientsScreenSearch ClientsScreenSearch' +
          (showSearch ? 'Active ' : '')
        }
      >
        <div className="ClientsScreenSearchBar">
          <input
            type="text"
            ref={searchRef}
            className="ClientsScreenSearchBarInput"
            autoComplete="new-off"
            spellCheck="false"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleSearchInputKeyDown}
          />
          <div className="ClientsScreenSearchToggle" onClick={toggleSearch}>
            Search Clients
          </div>
        </div>
      </div>
      <div className="DashboardScreenContent ClientsScreenContent">
        {!searchLoading &&
        searchBrandResults === null &&
        searchHumanResults === null ? (
          <Fragment>
            <div className="DashboardSection">
              <div className="DashboardSectionHeader">Unread Messages</div>
              {!unreadBrandMatches.length && (
                <div className="DashboardSectionEmpty">
                  {errorMatchAlerts
                    ? errorUtils.getErrorMessage(errorMatchAlerts) ||
                      'Loading Error'
                    : 'New messages from clients to Support will show up here.'}
                </div>
              )}
              <div className="DashboardSectionScrollerSwitch">
                {unreadBrandMatches.map((m) => (
                  <div key={m.id} className="ClientSummary ClientSummaryAlert">
                    <Link
                      to={'/brands/' + (m.brand ? m.brand.id : '')}
                      className={
                        'ClientSummaryBody ' +
                        (m.adminAssigned ? ' ClientSummaryBodyExtended ' : '')
                      }
                    >
                      <div className="ClientSummaryTitle">
                        {m.brand && m.brand.name ? m.brand.name : ''}
                      </div>
                      <div className="ClientSummarySubTitle">
                        {unreadReasonFormatted(m.expertUnread)}
                      </div>
                      {m.adminAssigned && (
                        <div
                          className={
                            'ClientSummaryAssignedTo ClientSummaryAssignedTo' +
                            (m.adminAssigned || '').toUpperCase()
                          }
                        >
                          {m.adminAssigned}
                        </div>
                      )}
                    </Link>
                    <div className="ClientSummaryFooter" />
                  </div>
                ))}
              </div>
            </div>
            <div className="AdminDashboardForm">
              <div className="AdminDashboardFormTitle">
                Create a new client?
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">First Name</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="First Name"
                  value={newHumanFirst}
                  onChange={(e) => setNewHumanFirst(e.currentTarget.value)}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Last Name</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Last Name"
                  value={newHumanLast}
                  onChange={(e) => setNewHumanLast(e.currentTarget.value)}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">
                  Email Address
                </div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="email"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Email Address"
                  value={newHumanEmail}
                  onChange={(e) => setNewHumanEmail(e.currentTarget.value)}
                />
              </div>
              <div
                className={
                  'AdminDashboardFormField ' +
                  (!newHumanPhoneValid
                    ? ' AdminDashboardFormFieldInvalid '
                    : '')
                }
              >
                <div className="AdminDashboardFormFieldTitle">Phone Number</div>
                <PhoneNumberInput
                  className="AdminDashboardFormFieldInput"
                  value={newHumanPhone}
                  onChange={phoneNumberChange}
                  onBlur={phoneNumberBlur}
                />
              </div>
              <div
                className={
                  'AdminDashboardFormAction ' +
                  (actionLoading ? ' AdminDashboardFormActionLoading ' : '')
                }
                onClick={saveNewHuman}
              >
                Create
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="DashboardSection">
              <div className="DashboardSectionHeader">Brand Results</div>
              {!searchLoading &&
                searchBrandResults &&
                !searchBrandResults.length && (
                  <div className="DashboardSectionEmpty">
                    No brands found. Try another search query to find what
                    you&apos;re looking for.
                  </div>
                )}
              <div className="DashboardSectionRows">
                {!searchLoading &&
                  searchBrandResults &&
                  !!searchBrandResults.length &&
                  searchBrandResults.map((brandResult) => (
                    <div
                      key={brandResult.id}
                      className="ClientSummary ClientSummarySearch"
                    >
                      <Link
                        to={'/brands/' + brandResult.id}
                        className="ClientSummaryBody"
                      >
                        <div className="ClientSummaryTitle">
                          {brandResult.name || ''}
                        </div>
                        <div className="ClientSummarySubTitle">
                          {brandResult.url || brandResult.shopifyAdminURL || ''}
                        </div>
                      </Link>
                      <div className="ClientSummaryFooter" />
                    </div>
                  ))}
              </div>
            </div>
            <div className="DashboardSection">
              <div className="DashboardSectionHeader">Human Results</div>
              {!searchLoading &&
                searchHumanResults &&
                !searchHumanResults.length && (
                  <div className="DashboardSectionEmpty">
                    No humans found. Try another search query to find what
                    you&apos;re looking for.
                  </div>
                )}
              <div className="DashboardSectionRows">
                {!searchLoading &&
                  searchHumanResults &&
                  !!searchHumanResults.length &&
                  searchHumanResults.map((humanResult) => (
                    <div
                      key={humanResult.id}
                      className="ClientSummary ClientSummarySearch"
                    >
                      <Link
                        to={'/humans/' + humanResult.id}
                        className="ClientSummaryBody"
                      >
                        <div className="ClientSummaryTitle">
                          {(
                            (humanResult.firstName || '') +
                            ' ' +
                            (humanResult.lastName || '')
                          ).trim()}
                        </div>
                        <div className="ClientSummarySubTitle">
                          {humanResult.primaryEmail ||
                            humanResult.primaryPhone ||
                            ''}
                        </div>
                      </Link>
                      <div className="ClientSummaryFooter" />
                    </div>
                  ))}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

AdminBrandsHumans.propTypes = {
  socketClient: PropTypes.object.isRequired,
};

export default AdminBrandsHumans;
