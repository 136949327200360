/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import moment from 'moment-timezone';
import { useState, useEffect, useContext, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQuery, ApolloError, gql } from '@apollo/client';
import { ClientWithOnReconnected } from '../../utils/apollo';
import {
  AuthHumanQuery,
  HumanMatchDetailsQuery,
  HumanMatchDetailsQueryVariables,
  HumanMatchThreadPaginatedQuery,
  HumanMatchThreadPaginatedQueryVariables,
  HumanThreadEventUpdatedMatchSubscription,
  HumanThreadEventUpdatedMatchSubscriptionVariables,
  HumanFileActiveForMatchQuery,
  HumanFileActiveForMatchQueryVariables,
  QuoteStatus,
} from '../../gql/graphql';
import {
  MatchDetail,
  TextMessageEventDetail,
  EmailMessageEventDetail,
  MessageEventDetail,
  MeetingEventDetail,
  QuoteDetail,
  PhoneCallEventDetail,
  RequestEventDetail,
  FileUploadDetail,
} from '../../utils/gql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import RequestThreadEvent from '../threadEvent/RequestThreadEvent';
import EmailMessageThreadEvent from '../threadEvent/EmailMessageThreadEvent';
import TextMessageThreadEvent from '../threadEvent/TextMessageThreadEvent';
import QuoteThreadEvent from '../threadEvent/QuoteThreadEvent';
import MessageThreadEvent from '../threadEvent/MessageThreadEvent';
import PhoneCallThreadEvent from '../threadEvent/PhoneCallThreadEvent';
import MeetingThreadEvent from '../threadEvent/MeetingThreadEvent';
import errorUtils from '../../utils/error';
import logError from '../../utils/airbrake';
import '../../styles/page/HumanMatchDetail.scss';

const humanMatchDetailsQuery = gql`
  query HumanMatchDetails($matchId: ID!) {
    matchDetails(matchId: $matchId) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

// @connection(key: "thread", filter: ["matchId"])
const humanMatchThreadPaginatedQuery = gql`
  query HumanMatchThreadPaginated(
    $matchId: ID
    $direction: String!
    $fromDate: Date
    $limit: Int!
  ) {
    threadPaginated(
      matchId: $matchId
      direction: $direction
      fromDate: $fromDate
      limit: $limit
    ) {
      id
      edges {
        id
        cursor
        node {
          ... on TextMessageEvent {
            ...TextMessageEventDetail
          }
          ... on EmailMessageEvent {
            ...EmailMessageEventDetail
          }
          ... on RequestEvent {
            ...RequestEventDetail
          }
          ... on MessageEvent {
            ...MessageEventDetail
          }
          ... on MeetingEvent {
            ...MeetingEventDetail
          }
          ... on Quote {
            ...QuoteDetail
          }
          ... on PhoneCallEvent {
            ...PhoneCallEventDetail
          }
        }
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const humanThreadEventUpdatedMatchSubscription = gql`
  subscription HumanThreadEventUpdatedMatch($threadId: ID!) {
    threadEventUpdated(threadId: $threadId) {
      ... on TextMessageEvent {
        ...TextMessageEventDetail
      }
      ... on EmailMessageEvent {
        ...EmailMessageEventDetail
      }
      ... on RequestEvent {
        ...RequestEventDetail
      }
      ... on MessageEvent {
        ...MessageEventDetail
      }
      ... on MeetingEvent {
        ...MeetingEventDetail
      }
      ... on Quote {
        ...QuoteDetail
      }
      ... on PhoneCallEvent {
        ...PhoneCallEventDetail
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const humanFileActiveForMatchQuery = gql`
  query HumanFileActiveForMatch($matchId: ID) {
    fileActiveForThread(matchId: $matchId) {
      ...FileUploadDetail
    }
  }
  ${FileUploadDetail}
`;

interface HumanMatchDetailProps {
  matchId: string;
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
  socketClient: ClientWithOnReconnected;
}

interface IGroupedEvents {
  group: HumanMatchThreadPaginatedQuery['threadPaginated']['edges'];
}

let scrollDownQuickTimeout: NodeJS.Timeout | undefined;
let isLoadingEvents = false;
const EVENT_PAGE_LIMIT = 20;

const HumanMatchDetail = ({
  matchId,
  // human,
  socketClient,
}: HumanMatchDetailProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const [primaryPanel, setPrimaryPanel] = useState('Details');
  const secondaryPanels = ['Details'].filter((p) => p !== primaryPanel);
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
  }
  const threadEventsWindow = useRef<HTMLDivElement>(null);
  const [initDate] = useState(() => new Date());
  const [canLoadMore, setCanLoadMore] = useState(true);
  // remoteEventIds tracks threadEventUpdated things that might cause pagination errors
  const [remoteEventIds, setRemoteEventIds] = useState([] as string[]);

  const {
    data: dataMatch,
    error: errorMatch,
    loading: loadingMatch,
    refetch: refetchMatch,
  } = useQuery<HumanMatchDetailsQuery, HumanMatchDetailsQueryVariables>(
    humanMatchDetailsQuery,
    {
      returnPartialData: true,
      variables: {
        matchId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorMatch) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorMatch) || 'Could not load match details';
  }
  const matchDetails =
    dataMatch && dataMatch.matchDetails && dataMatch.matchDetails.id
      ? dataMatch.matchDetails
      : undefined;
  const expertDetails =
    matchDetails && matchDetails.expert && matchDetails.expert.id
      ? matchDetails.expert
      : undefined;
  const expertId = expertDetails ? expertDetails.id : '';
  const {
    data: dataThread,
    error: errorThread,
    loading: loadingThread,
    fetchMore: fetchMoreThread,
    subscribeToMore: subscribeToMoreThread,
  } = useQuery<
    HumanMatchThreadPaginatedQuery,
    HumanMatchThreadPaginatedQueryVariables
  >(humanMatchThreadPaginatedQuery, {
    onCompleted: () => {
      scrollDown();
    },
    variables: {
      direction: 'before',
      fromDate: initDate.getTime(),
      limit: EVENT_PAGE_LIMIT,
      matchId,
    },
  });
  if (errorThread) {
    fullErrorCover =
      'Error loading this thread: ' + errorUtils.getErrorMessage(errorThread);
  }
  const cleanThread =
    (dataThread &&
      dataThread.threadPaginated &&
      dataThread.threadPaginated.edges) ||
    [];
  useEffect(() => {
    setRemoteEventIds([]);
  }, [matchId]);
  useEffect(() => {
    const unsub = subscribeToMoreThread<
      HumanThreadEventUpdatedMatchSubscription,
      HumanThreadEventUpdatedMatchSubscriptionVariables
    >({
      document: humanThreadEventUpdatedMatchSubscription,
      onError: (err) => {
        addNotification(
          'Error loading thread. Please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreThread', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { threadEventUpdated },
          },
        },
      ) => {
        console.log('threadEventUpdated', prev, threadEventUpdated);
        if (!threadEventUpdated || !prev.threadPaginated) {
          return prev;
        }
        if (prev.threadPaginated.id !== threadEventUpdated.matchStr) {
          return prev;
        }
        setRemoteEventIds((prevRemoteEventIds) =>
          prevRemoteEventIds.concat(threadEventUpdated.id),
        );
        const updatingIndex = (prev.threadPaginated.edges || []).findIndex(
          (e) => e.id === threadEventUpdated.threadEvent,
        );
        if (updatingIndex >= 0) {
          // update existing
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: prev.threadPaginated.edges
                .slice(0, updatingIndex)
                .concat({
                  __typename:
                    prev.threadPaginated.edges[updatingIndex].__typename,
                  cursor: prev.threadPaginated.edges[updatingIndex].cursor,
                  id: prev.threadPaginated.edges[updatingIndex].id,
                  node: {
                    ...prev.threadPaginated.edges[updatingIndex].node,
                    ...threadEventUpdated,
                  } as HumanMatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
                })
                .concat(prev.threadPaginated.edges.slice(updatingIndex + 1)),
              id: prev.threadPaginated.id,
            },
          };
        }
        // add new
        scrollDown();
        return {
          threadPaginated: {
            __typename: prev.threadPaginated.__typename,
            edges: (prev.threadPaginated.edges || []).concat({
              __typename: 'ThreadEventEdge',
              cursor: threadEventUpdated.createdAt,
              id: threadEventUpdated.threadEvent || '',
              node: threadEventUpdated,
            }),
            id: prev.threadPaginated.id,
          },
        };
      },
      variables: {
        threadId: matchId,
      },
    });
    return () => {
      unsub();
    };
  }, [subscribeToMoreThread, matchId, addNotification]);
  // FUTURE lazy query
  const {
    data: dataFileActive,
    error: errorFileActive,
    loading: loadingFileActive,
  } = useQuery<
    HumanFileActiveForMatchQuery,
    HumanFileActiveForMatchQueryVariables
  >(humanFileActiveForMatchQuery, {
    returnPartialData: true,
    variables: {
      matchId,
    },
  });
  const activeFiles = [
    ...((dataFileActive && dataFileActive.fileActiveForThread) || []),
  ]
    .filter(
      (f) =>
        f.matchStr === matchId &&
        !!f.url &&
        !f.isHiddenByExpert &&
        !f.isInlineEmail,
    )
    .sort((a, b) => b.createdAt - a.createdAt);
  // FUTURE matchFileUploadUpdatedSubscription
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [activeFiles]);
  function groupThreadEvents(
    events: HumanMatchThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    const groupedEvents: IGroupedEvents[] = [];
    events.forEach((ev) => {
      let isSameEventType = false;
      let isSameSenderSide = false;
      let isSameHuman = false;
      let isCurrGroupable = false;
      let isLastGroupable = false;
      const isFirstGroup = !groupedEvents.length;
      if (
        !isFirstGroup &&
        (ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'MessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent')
      ) {
        isCurrGroupable = true;
        isLastGroupable = true;
        const lastGroup = groupedEvents[groupedEvents.length - 1];
        const lastGroupEvent = lastGroup.group[0];
        // is same event type as last
        if (lastGroupEvent.node.__typename === ev.node.__typename) {
          isSameEventType = true;
          // is both sent from expert or both from Human
          isSameSenderSide =
            !!(ev.node.ownerHuman && lastGroupEvent.node.ownerHuman) ||
            !!(ev.node.ownerExpert && lastGroupEvent.node.ownerExpert);
          // is human interacting same
          isSameHuman =
            (ev.node.ownerHuman &&
              lastGroupEvent.node.ownerHuman &&
              ev.node.ownerHuman.id === lastGroupEvent.node.ownerHuman.id) ||
            (ev.node.recipientHumans[0] &&
              lastGroupEvent.node.recipientHumans[0] &&
              ev.node.recipientHumans[0].id ===
                lastGroupEvent.node.recipientHumans[0].id);
          // don't group texts with quotes attached
          if (ev.node.__typename === 'TextMessageEvent') {
            if (ev.node.quote) {
              isCurrGroupable = false;
            }
          }
          if (lastGroupEvent.node.__typename === 'TextMessageEvent') {
            if (lastGroupEvent.node.quote) {
              isLastGroupable = false;
            }
          }
        }
      }
      if (
        isFirstGroup ||
        !isCurrGroupable ||
        !isLastGroupable ||
        !isSameEventType ||
        !isSameSenderSide ||
        !isSameHuman
      ) {
        groupedEvents.push({
          group: [ev],
        });
      } else {
        groupedEvents[groupedEvents.length - 1].group.push(ev);
      }
    });
    return groupedEvents;
  }
  const threadEventEdges = _.uniqBy(cleanThread, 'id')
    .sort((a, b) => a.cursor - b.cursor)
    .filter((ev) => {
      if (ev.node.__typename === 'Quote') {
        // filter out quote accepts on subscriptions
        return (
          ev.node.paymentType === 'PROJECT' ||
          ev.node.paymentType === 'BILL' ||
          ev.node.status !== QuoteStatus.Accepted
        );
      }
      return true;
    });
  const overPageLimit = threadEventEdges.length >= EVENT_PAGE_LIMIT;
  function scrollDown() {
    scrollDownQuickTimeout = setTimeout(() => {
      if (threadEventsWindow && threadEventsWindow.current) {
        threadEventsWindow.current.scrollTop =
          threadEventsWindow.current.scrollHeight;
      }
    }, 5);
  }
  useEffect(() => {
    function onScroll() {
      if (threadEventsWindow && threadEventsWindow.current) {
        const scrollTemp = threadEventsWindow.current.scrollTop;
        if (
          scrollTemp < 500 &&
          overPageLimit &&
          canLoadMore &&
          !isLoadingEvents
        ) {
          isLoadingEvents = true;
          const heightTemp = threadEventsWindow.current.scrollHeight;
          fetchMoreThread({
            updateQuery: (prev, { fetchMoreResult }) => {
              if (
                !fetchMoreResult ||
                !prev.threadPaginated ||
                !fetchMoreResult.threadPaginated
              )
                return prev;
              if (
                fetchMoreResult.threadPaginated.edges.length < EVENT_PAGE_LIMIT
              ) {
                setCanLoadMore(false);
              }
              return {
                threadPaginated: {
                  __typename: prev.threadPaginated.__typename,
                  edges: _.uniqBy(
                    fetchMoreResult.threadPaginated.edges.concat(
                      prev.threadPaginated.edges || [],
                    ),
                    'id',
                  ),
                  id: prev.threadPaginated.id,
                },
              };
            },
            variables: {
              direction: 'before',
              fromDate: threadEventEdges.filter(
                (ev) => ev.node.id && remoteEventIds.indexOf(ev.node.id) === -1,
              )[0].cursor,
              limit: EVENT_PAGE_LIMIT,
              matchId,
            },
          })
            .catch((err: ApolloError) => {
              addNotification(
                errorUtils.getErrorMessage(err) || 'Load More Error',
              );
              logError(err, {
                component: 'HumanMatchDetail',
                func: 'fetchMoreThread',
              });
            })
            .finally(() => {
              isLoadingEvents = false;
              if (threadEventsWindow && threadEventsWindow.current) {
                threadEventsWindow.current.scrollTop =
                  Math.max(
                    threadEventsWindow.current.scrollHeight - heightTemp,
                    0,
                  ) + scrollTemp;
              }
            });
        }
      }
    }
    if (threadEventsWindow && threadEventsWindow.current) {
      threadEventsWindow.current.addEventListener('scroll', onScroll);
    }
    const tempWindow = threadEventsWindow.current;
    return () => {
      if (tempWindow) {
        tempWindow.removeEventListener('scroll', onScroll);
      }
    };
  }, [
    canLoadMore,
    overPageLimit,
    addNotification,
    fetchMoreThread,
    matchId,
    threadEventEdges,
    remoteEventIds,
  ]);
  useEffect(() => {
    scrollDown();
    const scrollDownEffectTimeout = setTimeout(() => {
      scrollDown();
    }, 500);
    return () => {
      if (scrollDownEffectTimeout) {
        clearTimeout(scrollDownEffectTimeout);
      }
      if (scrollDownQuickTimeout) {
        clearTimeout(scrollDownQuickTimeout);
      }
    };
  }, [matchId]);
  useEffect(() => {
    const loadTime = new Date();
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('HumanMatchDetail socketClient onReconnected');
      refetchMatch().catch(() => {});
      fetchMoreThread({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !prev.threadPaginated ||
            !fetchMoreResult.threadPaginated
          )
            return prev;
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: _.uniqBy(
                fetchMoreResult.threadPaginated.edges.concat(
                  prev.threadPaginated.edges || [],
                ),
                'id',
              ),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          direction: 'after',
          fromDate: loadTime.getTime(),
          limit: 50,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'HumanMatchDetail',
          func: 'reconnect fetchMoreThread',
        });
      });
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, fetchMoreThread, addNotification, refetchMatch]);
  const threadEventGroups = groupThreadEvents(threadEventEdges);
  function generateComponentsForGroup(
    groupedEvents: HumanMatchThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    return groupedEvents
      .map((ev, i) => {
        if (ev.node.__typename === 'RequestEvent') {
          return (
            <RequestThreadEvent
              key={ev.id}
              requestEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'MessageEvent') {
          return (
            <MessageThreadEvent
              key={ev.id}
              expertId={expertId}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              messageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'Quote') {
          return (
            <QuoteThreadEvent
              key={ev.id}
              quote={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'EmailMessageEvent') {
          return (
            <EmailMessageThreadEvent
              key={ev.id}
              expertId={expertId}
              emailMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'TextMessageEvent') {
          return (
            <TextMessageThreadEvent
              key={ev.id}
              expertId={expertId}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              textMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'PhoneCallEvent') {
          return (
            <PhoneCallThreadEvent
              key={ev.id}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              phoneCallEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'MeetingEvent') {
          return (
            <MeetingThreadEvent
              key={ev.id}
              meetingEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        console.log('Missing ThreadEvent type');
        return null;
      })
      .filter((c) => c);
  }

  if (loadingMatch || loadingThread || loadingFileActive) {
    // ignore these
  }

  return (
    <div className="DashboardModal ClientDetailModal HumanMatchDetailModal">
      <div className="DashboardModalTopCircle" />
      <div className="DashboardModalBottomCircle" />
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <Link
              to={'/experts' + (expertDetails ? '/' + expertDetails.id : '')}
              className="ThreadDetailMainHeaderBack"
            >
              {expertDetails
                ? (expertDetails.firstName || '') +
                  ' ' +
                  (expertDetails.lastName || '')
                : 'Experts'}
            </Link>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody" ref={threadEventsWindow}>
            {!!expertDetails && (
              <div className="ThreadEvents ThreadEventsHuman">
                {threadEventGroups.map((evGroup) => (
                  <div className="ThreadEventGroup" key={evGroup.group[0].id}>
                    {generateComponentsForGroup(evGroup.group)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div
          className={
            'ThreadDetailSidebar ' +
            (!secondaryPanels.length ? ' ThreadDetailSidebarSolo ' : '')
          }
        >
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            {!!matchDetails && !!expertDetails && (
              <div className="ThreadDetailSidebarBodyPanels">
                {primaryPanel === 'Details' && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                    <div className="ThreadDetailSidebarHumanSection">
                      <div className="ThreadDetailSidebarHumanSectionTitle">
                        Meet {expertDetails.firstName || 'your expert'}
                      </div>
                      {!!expertDetails.profileBio && (
                        <div className="ThreadDetailSidebarHumanSectionSubTitle">
                          {expertDetails.profileBio.slice(0, 140) +
                            (expertDetails.profileBio.length > 140
                              ? '...'
                              : '')}
                        </div>
                      )}
                      <Link
                        className="ThreadDetailSidebarHumanSectionBtn"
                        to={'/experts/' + expertDetails.id}
                      >
                        view full profile
                      </Link>
                    </div>
                    <div className="ThreadDetailSidebarHumanSection">
                      <div className="ThreadDetailSidebarHumanSectionTitle">
                        Shared Files
                      </div>
                      {activeFiles.map((f) => {
                        const fileMoment = moment.tz(
                          f.createdAt,
                          moment.tz.guess(),
                        );
                        const fileDateFull =
                          fileMoment.format('MMMM Do, YYYY') +
                          ' at ' +
                          fileMoment.format('h:mma z');
                        return (
                          <div
                            key={f.id}
                            className="ThreadDetailSidebarFileItem"
                          >
                            <a
                              className="ThreadDetailSidebarFileItemTitle"
                              href={f.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              data-tip={fileDateFull}
                            >
                              {f.filename}
                            </a>
                          </div>
                        );
                      })}
                      {!activeFiles.length && (
                        <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                          <div className="ThreadDetailSidebarBodyPanelEmpty ThreadDetailSidebarBodyPanelEmptyHuman">
                            <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                              {errorFileActive
                                ? errorUtils.getErrorMessage(errorFileActive)
                                : 'When you send or receive files, they will be saved here.'}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <Link
              to={'/experts' + (expertDetails ? '/' + expertDetails.id : '')}
              className="DashboardErrorCoverNav"
            >
              back
            </Link>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

HumanMatchDetail.propTypes = {
  human: PropTypes.object.isRequired,
  matchId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default HumanMatchDetail;
