import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import PhoneNumberInput from '../feature/PhoneNumberInput';
import '../../styles/feature/HumanBrandSidebarEditor.scss';
import { MatchDetailsQuery } from '../../gql/graphql';

interface HumanBrandSidebarEditorProps {
  human?: MatchDetailsQuery['matchDetails']['brand']['team'][0];
  isActionLoading: boolean;
  onCancel: () => void;
  onSave: (
    human?: MatchDetailsQuery['matchDetails']['brand']['team'][0],
    phone?: string,
    email?: string,
    firstName?: string,
    lastName?: string,
    secondaryEmails?: string[],
  ) => void;
}

const HumanBrandSidebarEditor = ({
  human,
  isActionLoading,
  onCancel,
  onSave,
}: HumanBrandSidebarEditorProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [humanFirst, setHumanFirst] = useState(
    (human && human.firstName) || '',
  );
  const [humanLast, setHumanLast] = useState((human && human.lastName) || '');
  const [humanEmail, setHumanEmail] = useState(
    (human && human.primaryEmail) || '',
  );
  const [humanSecondaryEmails, setHumanSecondaryEmails] = useState(
    (human && human.secondaryEmails ? human.secondaryEmails : []).filter(
      (se) => se !== ((human && human.primaryEmail) || ''),
    ),
  );
  const [humanPhone, setHumanPhone] = useState(
    (human && human.primaryPhone) || '',
  );
  const [humanPhoneValid, setHumanPhoneValid] = useState(true);
  function phoneNumberChange(phone: string, isValid: boolean) {
    setHumanPhone(phone);
    if (!phone || isValid) {
      setHumanPhoneValid(true);
    }
  }
  function phoneNumberBlur(isValid: boolean) {
    setHumanPhoneValid(!humanPhone || isValid);
  }
  function trySave() {
    if (!humanFirst && !humanLast) {
      addNotification('Please submit a first or last name', undefined, 5000);
      return;
    }
    if (!humanPhoneValid) {
      addNotification('Please submit a valid phone number', undefined, 5000);
      return;
    }
    if (!humanEmail && !humanPhone) {
      addNotification(
        'Please submit a valid phone or email at least',
        undefined,
        5000,
      );
      return;
    }
    if (!humanEmail && humanSecondaryEmails.length) {
      addNotification(
        'Make sure you have a primary email set instead of only secondaries.',
        undefined,
        5000,
      );
      return;
    }
    onSave(
      human,
      humanPhone,
      humanEmail,
      humanFirst,
      humanLast,
      humanSecondaryEmails,
    );
  }
  return (
    <div className="HumanBrandSidebarEditor">
      <div className="HumanBrandSidebarEditorTop">
        <div className="HumanBrandSidebarEditorTopTitle">
          {human ? 'Edit' : 'Add'} Contact
        </div>
        <div className="HumanBrandSidebarEditorTopActions">
          <div
            className="HumanBrandSidebarEditorTopActionBtn HumanBrandSidebarEditorTopActionIgnore"
            onClick={onCancel}
          >
            Cancel
          </div>
          <div
            className={
              'HumanBrandSidebarEditorTopActionBtn HumanBrandSidebarEditorTopActionSave ' +
              (isActionLoading
                ? ' HumanBrandSidebarEditorTopActionBtnLoading '
                : '')
            }
            onClick={trySave}
          >
            Save
          </div>
        </div>
      </div>
      <div className="HumanBrandSidebarEditorCardWrapper">
        <div className="HumanBrandSidebarEditorCard">
          <div className="HumanBrandSidebarEditorFormInput">
            <div className="HumanBrandSidebarEditorFormInputTitle">
              First Name
            </div>
            <input
              className="HumanBrandSidebarEditorFormInputField"
              type="text"
              autoComplete="new-off"
              placeholder="First Name"
              value={humanFirst}
              onChange={(e) => setHumanFirst(e.currentTarget.value)}
            />
          </div>
          <div className="HumanBrandSidebarEditorFormInput">
            <div className="HumanBrandSidebarEditorFormInputTitle">
              Last Name
            </div>
            <input
              className="HumanBrandSidebarEditorFormInputField"
              type="text"
              autoComplete="new-off"
              placeholder="Last Name"
              value={humanLast}
              onChange={(e) => setHumanLast(e.currentTarget.value)}
            />
          </div>
          <div
            className={
              'HumanBrandSidebarEditorFormInput ' +
              (!humanPhoneValid
                ? ' HumanBrandSidebarEditorFormInputInvalid '
                : '')
            }
          >
            <div className="HumanBrandSidebarEditorFormInputTitle">
              Phone Number
            </div>
            <PhoneNumberInput
              className="HumanBrandSidebarEditorFormInputField"
              value={humanPhone}
              onChange={phoneNumberChange}
              onBlur={phoneNumberBlur}
            />
          </div>
          <div className="HumanBrandSidebarEditorFormInput">
            <div className="HumanBrandSidebarEditorFormInputTitle">
              Primary Email
            </div>
            <input
              className="HumanBrandSidebarEditorFormInputField"
              type="email"
              autoComplete="new-off"
              spellCheck="false"
              placeholder="Email Address"
              value={humanEmail}
              onChange={(e) => setHumanEmail(e.currentTarget.value)}
            />
          </div>
          {!!human && (
            <div className="HumanBrandSidebarEditorFormInput">
              <div className="HumanBrandSidebarEditorFormInputTitle">
                Secondary Emails
              </div>
              {humanSecondaryEmails.map((se, i) => (
                <input
                  key={i}
                  className="HumanBrandSidebarEditorFormInputField"
                  type="email"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Secondary Email Address"
                  value={se}
                  onChange={(e) =>
                    setHumanSecondaryEmails(
                      humanSecondaryEmails
                        .slice(0, i)
                        .concat(e.currentTarget.value)
                        .concat(humanSecondaryEmails.slice(i + 1)),
                    )
                  }
                />
              ))}
              <div
                className="HumanBrandSidebarEditorFormExtraAction"
                onClick={() =>
                  setHumanSecondaryEmails(humanSecondaryEmails.concat(''))
                }
              >
                Add another email?
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

HumanBrandSidebarEditor.propTypes = {
  human: PropTypes.object,
  isActionLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

HumanBrandSidebarEditor.defaultProps = {
  human: null,
};

export default HumanBrandSidebarEditor;
