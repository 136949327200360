import envUtils from './env';
const cookieDomain = envUtils.pick('storetasker.com', 'storetasker.com', '');

function write(name: string, value: string, days?: number) {
  writeCookie(name, value, days || 1000);
}

function erase(key: string) {
  eraseCookie(key);
}

function read(key: string) {
  return readCookie(key);
}

function readLocalStorage(key: string) {
  let val;
  try {
    val = window.localStorage.getItem(key);
  } catch (e) {
    val = null;
  }
  return val;
}

function writeLocalStorage(key: string, val: string) {
  let wrote;
  try {
    window.localStorage.setItem(key, val);
    wrote = true;
  } catch (e) {
    wrote = false;
  }
  return wrote;
}

function removeLocalStorage(key: string) {
  let removed;
  try {
    window.localStorage.removeItem(key);
    removed = true;
  } catch (e) {
    removed = false;
  }
  return removed;
}

function writeCookie(name: string, value: string, days?: number) {
  let expires = '';
  let domainStr = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }

  if (cookieDomain) domainStr = '; domain=' + cookieDomain;
  window.document.cookie =
    name + '=' + value + expires + domainStr + '; path=/';
}

function readCookie(name: string) {
  const nameEQ = name + '=';
  const ca = window.document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name: string) {
  write(name, '', -1);
}

export default {
  erase,
  read,
  readLocalStorage,
  removeLocalStorage,
  write,
  writeLocalStorage,
};
