import moment from 'moment-timezone';
import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

interface LocationTimestampProps {
  locationCity?: string | null;
  locationRegionCode?: string | null;
  locationStr?: string | null;
  locationTimezone?: string | null;
}

const LocationTimestamp = ({
  locationCity,
  locationRegionCode,
  locationStr,
  locationTimezone,
}: LocationTimestampProps) => {
  const [localTimeStr, setLocalTimeStr] = useState('');
  let locationDetailStr = (locationStr || '').trim();
  if (locationCity) {
    locationDetailStr = locationCity.trim();
    if (locationRegionCode) {
      locationDetailStr += ', ' + locationRegionCode.trim();
    }
  }
  useEffect(() => {
    const getTime = () => {
      if (locationTimezone) {
        setLocalTimeStr(
          ' - ' + moment().tz(locationTimezone).format('h:mma z'),
        );
      }
    };
    getTime();
    const interval = setInterval(getTime, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [locationTimezone]);
  const combinedStr =
    locationDetailStr + (locationDetailStr ? localTimeStr : '');
  return <Fragment>{combinedStr}</Fragment>;
};

LocationTimestamp.propTypes = {
  locationCity: PropTypes.string,
  locationRegionCode: PropTypes.string,
  locationStr: PropTypes.string,
  locationTimezone: PropTypes.string,
};

LocationTimestamp.defaultProps = {
  locationCity: '',
  locationRegionCode: '',
  locationStr: '',
  locationTimezone: '',
};

export default LocationTimestamp;
