import { useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql, ApolloError } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ContentState } from 'draft-js';
import {
  AuthHumanQuery,
  BrandForHumansQuery,
  BrandSendSupportMessageMutation,
  BrandSendSupportMessageMutationVariables,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import errorUtils from '../../utils/error';
import logError from '../../utils/airbrake';
import RichTextEditor from '../feature/RichTextEditor';
import { SUPPORT_PHONE_NUMBER } from '../../utils/constants';
import { formatValidPhoneNumber } from '../../utils/format';
import envUtils from '../../utils/env';
import { IFilestackFileUpload } from '../../utils/props';
import logo from '../../images/storetasker-logo-black.png';
import '../../styles/page/HumanSupportView.scss';
const { convertToHTML } = require('draft-convert'); // eslint-disable-line @typescript-eslint/no-var-requires
const ReactFilestack = require('filestack-react').default; // eslint-disable-line @typescript-eslint/no-var-requires

const brandSendSupportMessageMutation = gql`
  mutation BrandSendSupportMessage(
    $brandId: ID!
    $html: String!
    $files: [FileDetails!]!
  ) {
    brandSendSupportMessage(brandId: $brandId, html: $html, files: $files) {
      id
    }
  }
`;
interface HumanSupportViewProps {
  brands: BrandForHumansQuery['brandForHumans'];
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
}

const HumanSupportView = ({ brands, human }: HumanSupportViewProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [supportContent, setSupportContent] = useState<ContentState | null>(
    null,
  );
  const [supportFiles, setSupportFiles] = useState<IFilestackFileUpload[]>([]);
  const [supportSent, setSupportSent] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  function fileError(error: Error) {
    addNotification(
      (error && error.message) || 'File upload error. Please try again.',
    );
    console.log('Filestack fileError', error);
  }
  function fileUploaded(filestackRes: any) {
    console.log('Filestack fileUploaded', filestackRes);
    if (filestackRes && filestackRes.filesUploaded) {
      setSupportFiles(
        supportFiles.concat(
          filestackRes.filesUploaded.map((f: any) => ({
            container: f.container || '',
            filename: f.filename || '',
            handle: f.handle || '',
            key: f.key || '',
            mimetype: f.mimetype || '',
            size: (f.size || '').toString(),
            source: f.source || '',
            uploadId: f.uploadId || '',
            url: f.url || '',
          })) as IFilestackFileUpload[],
        ),
      );
    }
  }
  function removeFile(filestackUploadUrl: string) {
    console.log('Filestack removeFile', filestackUploadUrl);
    setSupportFiles(supportFiles.filter((f) => f.url !== filestackUploadUrl));
  }
  const [trySendMessage] = useMutation<
    BrandSendSupportMessageMutation,
    BrandSendSupportMessageMutationVariables
  >(brandSendSupportMessageMutation);
  function doSendEmail(html: string) {
    if (!brands.length) {
      return;
    }
    setActionLoading(true);
    trySendMessage({
      variables: {
        brandId: brands[0].id,
        files: supportFiles,
        html,
      },
    })
      .then(() => {
        setActionLoading(false);
        setSupportSent(true);
        addNotification("Thanks! We'll get in touch shortly.", 'GOOD', 4000);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Suppprt Error');
        logError(err, {
          component: 'SupportDetailView',
          func: 'trySendMessage',
        });
      });
  }
  function onSendMessage() {
    if (actionLoading) return;
    if (
      !supportContent ||
      !supportContent.hasText() ||
      !supportContent.getPlainText().replace(/\s/gi, '').trim()
    ) {
      addNotification(
        'Please fill out your message details before submitting.',
        undefined,
        5000,
      );
      return;
    }
    const html = convertToHTML({
      blockToHTML: (block: any) => {
        if (block.type === 'unstyled') {
          if (block.text === '' || block.text === ' ') {
            return <br />;
          }
          return <div />;
        }
        return undefined;
      },
      entityToHTML: (entity: any, originalText: string) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} rel="noopener noreferrer" target="_blank">
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(supportContent) as string;
    doSendEmail(
      html.replace(/(<div><\/div>)+$/gi, '').replace(/(<br\/>)+$/gi, ''),
    );
  }

  return (
    <div className="DashboardScreen DashboardScreenHuman HumanSupportView">
      <Link className="DashboardScreenLogoLink" to="/home">
        <img
          className="DashboardScreenLogo"
          src={logo}
          alt="Storetasker Logo"
        />
      </Link>
      <div className="DashboardScreenContent HumanSupportViewScreenContent">
        {!!brands.length && (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">
              Your Freelance Talent Partner
            </div>
            <div className="DashboardSectionAligned">
              <div className="BasicForm BasicFormSmallTop">
                {supportSent ? (
                  <div className="BasicFormField">
                    <div className="BasicFormFieldValue">
                      Thanks for letting us know. We&apos;ll get in touch
                      shortly.
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <div className="BasicFormField BasicFormFieldLessMargin">
                      <div className="BasicFormFieldTitle">
                        How can we help?
                      </div>
                      <div className="BasicFormFieldRichWrapper">
                        <div className="BasicFormFieldRichWrapperText">
                          <RichTextEditor
                            initContent={''}
                            placeholder="Tell us as much as you can about what you need help with and we'll get in touch shortly. Thank you!"
                            onUpdated={setSupportContent}
                          />
                        </div>
                        <div className="BasicFormFieldRichFooter">
                          <ReactFilestack
                            apikey="AGxdviGoVRwWPL6lKEdnXz"
                            actionOptions={{
                              maxFiles: 20,
                              storeTo: {
                                container: envUtils.pick(
                                  'files.asklorem.com',
                                  'files-dev.asklorem.com',
                                  'files-dev.asklorem.com',
                                ),
                                location: 's3',
                                path: `${human.id}/`,
                                region: 'us-east-1',
                              },
                            }}
                            onSuccess={fileUploaded}
                            onError={fileError}
                            customRender={({ onPick }: { onPick: any }) => (
                              <div
                                onMouseDown={onPick}
                                className="BasicFormFieldRichFooterAttachments"
                              >
                                attach files
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="BasicFormFiles">
                        {supportFiles.map((file) => (
                          <div
                            key={file.url}
                            className="BasicFormFile"
                            onClick={() => removeFile(file.url || '')}
                          >
                            <span className="BasicFormFileText">
                              {file.filename}
                            </span>
                            <div className="BasicFormFileRemove" />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={
                        'BasicFormAction ' +
                        (actionLoading ? ' BasicFormActionLoading ' : '')
                      }
                      onClick={onSendMessage}
                    >
                      Send Message
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">
            {brands.length ? 'Get In Touch' : 'Contact Support'}
          </div>
          <div className="DashboardSectionAligned">
            <div className="SupportInfo">
              <div className="SupportInfoSection">
                <div className="SupportInfoSectionTitle">Contact Info</div>
                <a
                  className="SupportInfoSectionLine"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:support@storetasker.com"
                >
                  support@storetasker.com
                </a>
                <a
                  className="SupportInfoSectionLine"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'sms:' + SUPPORT_PHONE_NUMBER}
                >
                  {formatValidPhoneNumber(SUPPORT_PHONE_NUMBER) ||
                    SUPPORT_PHONE_NUMBER ||
                    ''}
                </a>
              </div>
              <div className="SupportInfoSection">
                <div className="SupportInfoSectionTitle">Office Hours</div>
                <div className="SupportInfoSectionLine">Monday - Friday</div>
                <div className="SupportInfoSectionLine">9am - 6pm EST</div>
              </div>
              <div className="SupportInfoSection">
                <div className="SupportInfoSectionTitle">Help Center</div>
                <a
                  className="SupportInfoSectionLine"
                  href="https://support.storetasker.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support.storetasker.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HumanSupportView.propTypes = {
  brands: PropTypes.array.isRequired,
  human: PropTypes.object.isRequired,
};

export default HumanSupportView;
