import PropTypes from 'prop-types';
import '../../styles/threadEvent/UnknownHuman.scss';
import {
  MatchDetailsQuery,
  MatchThreadPaginatedQuery,
} from '../../gql/graphql';

interface UnknownHumanProps {
  brandTeam: MatchDetailsQuery['matchDetails']['brand']['team'];
  className?: string;
  onAddHuman: (
    human: Exclude<
      Extract<
        MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
        { __typename?: 'EmailMessageEvent' | undefined }
      >['ownerHuman'],
      null | undefined
    >,
  ) => void;
  onIgnoreHuman: (humanId: string) => void;
  onMergeHuman: (parentHumanId: string, childHumanId: string) => void;
  unknownCount: number;
  unknownHuman: Exclude<
    Extract<
      MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
      { __typename?: 'EmailMessageEvent' | undefined }
    >['ownerHuman'],
    null | undefined
  >;
  unknownType: string;
}

const UnknownHuman = ({
  brandTeam,
  className,
  onAddHuman,
  onIgnoreHuman,
  onMergeHuman,
  unknownCount,
  unknownHuman,
  unknownType,
}: UnknownHumanProps) => {
  const contactName = (
    (unknownHuman.firstName || '') +
    ' ' +
    (unknownHuman.lastName || '')
  ).trim();
  const contactMethod =
    unknownType === 'email'
      ? unknownHuman.primaryEmail || ''
      : unknownHuman.primaryPhone || '';
  let contactStr = '';
  if (contactName) {
    contactStr = `${contactName} (${contactMethod})`;
  } else {
    contactStr = contactMethod;
  }
  return (
    <div className={'UnknownHuman ' + (className || '')}>
      <div className="UnknownHumanWrapper">
        <div className="UnknownHumanTop">
          <div className="UnknownHumanTopIndent">+</div>
          <div className="UnknownHumanTopTitle">
            New {unknownType === 'email' ? 'Email Address' : 'Phone Number'}{' '}
            Recognized
            {unknownCount > 1 ? ' (1 / ' + unknownCount.toString() + ')' : ''}
          </div>
        </div>
        <div className="UnknownHumanCardWrapper">
          <div className="UnknownHumanCard">
            <div className="UnknownHumanCardTitle">
              Looks like there&apos;s a new{' '}
              {unknownType === 'email' ? 'email' : 'phone number'}:{' '}
              <span>{contactStr}</span> that isn&apos;t associated with someone
              on this team. You have 3 options:
            </div>
            <div className="UnknownHumanCardSection">
              <div className="UnknownHumanCardSectionTitle">
                1. Create a new team member with this{' '}
                {unknownType === 'email' ? 'email address' : 'phone number'}:
              </div>
              <div
                className="UnknownHumanCardOption"
                onClick={() => onAddHuman(unknownHuman)}
              >
                Create A New Team Member
              </div>
            </div>
            {!!brandTeam.length && (
              <div className="UnknownHumanCardSection">
                <div className="UnknownHumanCardSectionTitle">
                  2. Merge this{' '}
                  {unknownType === 'email' ? 'email address' : 'phone number'}{' '}
                  into an existing contact on their team:
                </div>
                <div className="UnknownHumanCardOptions">
                  {brandTeam.map((teamMember) => {
                    const teamMemberName =
                      (
                        (teamMember.firstName || '') +
                        ' ' +
                        (teamMember.lastName || '')
                      ).trim() ||
                      teamMember.primaryEmail ||
                      '' ||
                      teamMember.primaryPhone ||
                      '';
                    return (
                      <div
                        key={teamMember.id}
                        className="UnknownHumanCardOption"
                        onClick={() =>
                          onMergeHuman(teamMember.id, unknownHuman.id)
                        }
                      >
                        {teamMemberName}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="UnknownHumanCardSection">
              <div className="UnknownHumanCardSectionTitle">
                3. Skip it - this{' '}
                {unknownType === 'email' ? 'email address' : 'phone number'}{' '}
                does not need to be added to this brand&apos;s team:
              </div>
              <div
                className="UnknownHumanCardOption"
                onClick={() => onIgnoreHuman(unknownHuman.id)}
              >
                Skip / Ignore
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UnknownHuman.propTypes = {
  brandTeam: PropTypes.array.isRequired,
  className: PropTypes.string,
  onAddHuman: PropTypes.func.isRequired,
  onIgnoreHuman: PropTypes.func.isRequired,
  onMergeHuman: PropTypes.func.isRequired,
  unknownCount: PropTypes.number.isRequired,
  unknownHuman: PropTypes.object.isRequired,
  unknownType: PropTypes.string.isRequired,
};

UnknownHuman.defaultProps = {
  className: '',
};

export default UnknownHuman;
