import _ from 'lodash';
import {
  expertSkillsBrag,
  expertFocusPosition,
  expertClientsBrag,
  formatValidPhoneNumber,
} from './format';

interface IName {
  firstName?: string | null;
  lastName?: string | null;
}

function getQuoteSendEmail(
  clientNames: IName[],
  expertName?: IName,
  paymentType?: string,
  discountCode?: string,
) {
  if (paymentType === 'BILL') {
    return `Hi ${
      addressedTo(clientNames) || 'there'
    },\n\nJust created a link to pay the bill for our work together below. Take a look and let me know if you have any questions.${
      discountCode
        ? ' Your discount code will automatically get factored into this as well.'
        : ''
    }\n\nThanks,\n${(expertName && expertName.firstName) || ''}`;
  }
  return `Hi ${
    addressedTo(clientNames) || 'there'
  },\n\nJust put together a quote for this project below. Take a look and let me know what you think.${
    discountCode
      ? ' Your discount code will automatically get factored into this as well.'
      : ''
  }\n\nOnce you accept, then I can get started working on this. Sound good?\n\nThanks,\n${
    (expertName && expertName.firstName) || ''
  }`;
}
function getQuoteSendText(clientNames: IName[], paymentType?: string) {
  if (paymentType === 'BILL') {
    return `Hey ${
      addressedTo(clientNames) || 'there'
    }, just created a link to pay the bill for our work together below. Take a look and let me know if you have any questions.`;
  }
  return `Hey ${
    addressedTo(clientNames) || 'there'
  }, just put together a quote for this project below. Take a look and let me know what you think. Sound good?`;
}
function getQuoteUpdateEmail(
  clientNames: IName[],
  expertName?: IName,
  paymentType?: string,
  discountCode?: string,
) {
  if (paymentType === 'BILL') {
    return `Hey ${
      addressedTo(clientNames) || 'there'
    },\n\nJust updated the bill for our work together below. Take another look and let me know if you have any questions.${
      discountCode
        ? ' Your discount code will automatically get factored into this as well.'
        : ''
    }\n\nThanks,\n${(expertName && expertName.firstName) || ''}`;
  }
  return `Hey ${
    addressedTo(clientNames) || 'there'
  },\n\nJust updated the quote below. Take another look and we can go from there.${
    discountCode
      ? ' Your discount code will automatically get factored into this as well.'
      : ''
  }\n\nLet me know if you have any questions!\n\nThanks,\n${
    (expertName && expertName.firstName) || ''
  }`;
}
function getQuoteUpdateText(clientNames: IName[], paymentType?: string) {
  if (paymentType === 'BILL') {
    return `Hey ${
      addressedTo(clientNames) || 'there'
    }, just updated the bill for our work together below. Take another look and let me know if you have any questions.`;
  }
  return `Hey ${
    addressedTo(clientNames) || 'there'
  }, just updated the quote below. Take another look and we can go from there.`;
}
function getQuoteMarkCompleteEmail(
  clientNames: IName[],
  hasRevisionsRequested: boolean,
  expertName?: IName,
) {
  return `Hey ${addressedTo(clientNames) || 'there'},\n\n${
    hasRevisionsRequested
      ? 'Thanks again for your feedback. I made the changes you requested and have marked this project as ready for review.'
      : 'Now that you’ve had a chance to review my work, I’ve marked this project as completed and ready for your final approval.'
  }\n\n${
    hasRevisionsRequested
      ? 'Take another look and let me know if you have any more questions!'
      : 'Take a look and let me know if you have any questions!'
  }\n\nThanks,\n${(expertName && expertName.firstName) || ''}`;
}
function getQuoteMarkCompleteText(
  clientNames: IName[],
  hasRevisionsRequested: boolean,
) {
  return `Hey ${addressedTo(clientNames) || 'there'}, ${
    hasRevisionsRequested
      ? 'thanks again for your feedback. I made the changes you requested and have marked this project as ready for review. Take another look and let me know if you have any more questions!'
      : 'now that you’ve had a chance to review my work, I’ve marked this project as completed and ready for your final approval. Take a look and let me know if you have any questions!'
  }`;
}
function getQuoteAcceptedEmail(clientNames: IName[], expertName?: IName) {
  return `Hey ${
    addressedTo(clientNames) || 'there'
  },\n\nThis is the default template email for quote accept response (FUTURE).\n\nThanks,\n${
    (expertName && expertName.firstName) || ''
  }`;
}
function getQuoteAcceptedText() {
  return 'This is the default template text for quote accept response (FUTURE)';
}
function getQuoteApprovedEmail(clientNames: IName[], expertName?: IName) {
  return `Hey ${
    addressedTo(clientNames) || 'there'
  },\n\nThis is the default template email for project approved response (FUTURE).\n\nThanks,\n${
    (expertName && expertName.firstName) || ''
  }`;
}
function getQuoteApprovedText() {
  return 'This is the default template text for project approved response (FUTURE)';
}
function getQuoteCanceledEmail(clientNames: IName[], expertName?: IName) {
  return `Hey ${
    addressedTo(clientNames) || 'there'
  },\n\nThis is the default template email for project cancel (FUTURE).\n\nThanks,\n${
    (expertName && expertName.firstName) || ''
  }`;
}
function getQuoteCanceledText() {
  return 'This is the default template text for project cancel (FUTURE)';
}
function getQuotePaidCanceledEmail(clientNames: IName[], expertName?: IName) {
  return `Hey ${
    addressedTo(clientNames) || 'there'
  },\n\nThis is the default template email for a canceled project that was paid for (FUTURE).\n\nThanks,\n${
    (expertName && expertName.firstName) || ''
  }`;
}
function getQuotePaidCanceledText() {
  return 'This is the default template text for a canceled project that was paid for (FUTURE)';
}
function getQuoteRevisionsEmail(clientNames: IName[], expertName?: IName) {
  return `Hey ${
    addressedTo(clientNames) || 'there'
  },\n\nThis is the default template email for request revisions response (FUTURE).\n\nThanks,\n${
    (expertName && expertName.firstName) || ''
  }`;
}
function getQuoteRevisionsText() {
  return 'This is the default template email for request revisions response (FUTURE)';
}
function getQuoteUndoEmail(clientNames: IName[], expertName?: IName) {
  return `Hey ${
    addressedTo(clientNames) || 'there'
  },\n\nThis is the default template email for undo mark complete (FUTURE).\n\nThanks,\n${
    (expertName && expertName.firstName) || ''
  }`;
}
function getQuoteUndoText() {
  return 'This is the default template email for undo mark complete (FUTURE)';
}
function getClaimLeadUnmatchedEmailSubject() {
  return 'Default subject for claiming previously unmatched lead FUTURE';
}
function getClaimLeadUnmatchedEmail(clientNames: IName[], expertName?: IName) {
  return `Hey ${
    addressedTo(clientNames) || 'there'
  },\n\nThis is the default template email for claiming a lead that was previously unmatched. FUTURE\n\nThanks,\n${
    (expertName && expertName.firstName) || ''
  }`;
}
function getClaimLeadUnmatchedText(clientNames: IName[], expertName?: IName) {
  return `Hey ${addressedTo(clientNames) || 'there'}, I'm ${
    (expertName && expertName.firstName) || ''
  } from Storetasker. This is the default template text for claiming a lead that was previously unmatched
  `;
}
function getClaimLeadDefaultEmailSubject(
  brandName?: string,
  humanName?: IName,
  expertName?: IName,
) {
  return `Storetasker Intro: ${
    humanName && humanName.firstName && brandName
      ? humanName.firstName + ' (' + brandName + ') <> '
      : ''
  }${(expertName && expertName.firstName) || ''} (Shopify Expert)`;
}
function getClaimLeadDefaultEmail(
  clientNames: IName[],
  expertPathToSchedule?: string,
  expertName?: IName,
) {
  return `Hi ${
    addressedTo(clientNames) || 'there'
  },\n\nIt’s great to meet you! I’d love to help you with this project and have done similar work with other clients.\n\nAre you available for a quick call to go over the details? After that, I’ll follow up with a quote and we can get started.\n\n${
    expertPathToSchedule
      ? 'You can also book time on my calendar to meet and discuss your project: meet.storetasker.com/' +
        expertPathToSchedule +
        '\n\n'
      : ''
  }Thanks,\n${(expertName && expertName.firstName) || ''}
  `;
}
function getClaimLeadDefaultEmailIntro(
  humanName?: IName,
  expertName?: IName,
  tagSkillsRaw?: string[],
  tagFocus?: string[],
  profileClients?: string,
  profileEmailIntro?: string,
  primaryPublicEmail?: string,
  primaryPublicPhone?: string,
) {
  const salutation = _.sample([
    "It's a pleasure",
    "It's great",
    'Very happy',
    'Excited',
  ]);
  const adjective = _.sample([
    'an experienced',
    'a talented',
    'a skilled',
    'a fantastic',
    'a terrific',
  ]);
  const skillList = expertSkillsBrag(tagSkillsRaw);
  return `Hi ${
    humanName && humanName.firstName ? humanName.firstName : 'there'
  },\n\n${salutation} to be introducing you to ${
    expertName && expertName.firstName ? expertName.firstName : 'your expert'
  } (cc'd). They’re ${adjective} ${
    expertFocusPosition(tagFocus).toLowerCase() || 'developer'
  }${expertClientsBrag(profileClients)}${
    profileEmailIntro ? ' ' + profileEmailIntro : ''
  }\n\nWe're sure you'll enjoy chatting, and are hoping you two can connect today or tomorrow.\n${
    skillList
      ? `\n${
          expertName && expertName.firstName
            ? expertName.firstName
            : 'Your expert'
        } often works on ${skillList} projects, but has a wide range of e-commerce skills.\n`
      : ''
  }\nYou can reply here, or reach out to them directly at ${primaryPublicEmail}${
    primaryPublicPhone
      ? ' or ' + formatValidPhoneNumber(primaryPublicPhone, true)
      : ''
  }.\n\nCheers,\nStoretasker Support`;
}
function getClaimLeadDefaultText(clientNames: IName[], expertName?: IName) {
  return `Hey ${addressedTo(clientNames) || 'there'}, I'm ${
    (expertName && expertName.firstName) || ''
  } -- a Shopify Expert at Storetasker. I can definitely help you with this project. Going to request partner access to your store now, so that I can properly scope the project and give you a quote. Looking forward to working with you!`;
}

function getDefaultEmail(clientNames: IName[], expertName?: IName) {
  return `Hi ${addressedTo(clientNames) || 'there'},\n\n\n\nThanks,\n${
    (expertName && expertName.firstName) || ''
  }
  `;
}
function getDefaultText() {
  return '';
}
function addressedTo(clientNames: IName[]) {
  let addressedToStr = '';
  if (
    clientNames[0] &&
    clientNames.length <= 2 &&
    !clientNames.find((n) => !n.firstName)
  ) {
    addressedToStr = clientNames[0].firstName!;
    if (clientNames[1]) {
      addressedToStr += ' & ' + clientNames[1].firstName!;
    }
  }
  return addressedToStr;
}

export default {
  getClaimLeadDefaultEmail,
  getClaimLeadDefaultEmailIntro,
  getClaimLeadDefaultEmailSubject,
  getClaimLeadDefaultText,
  getClaimLeadUnmatchedEmail,
  getClaimLeadUnmatchedEmailSubject,
  getClaimLeadUnmatchedText,
  getDefaultEmail,
  getDefaultText,
  getQuoteAcceptedEmail,
  getQuoteAcceptedText,
  getQuoteApprovedEmail,
  getQuoteApprovedText,
  getQuoteCanceledEmail,
  getQuoteCanceledText,
  getQuoteMarkCompleteEmail,
  getQuoteMarkCompleteText,
  getQuotePaidCanceledEmail,
  getQuotePaidCanceledText,
  getQuoteRevisionsEmail,
  getQuoteRevisionsText,
  getQuoteSendEmail,
  getQuoteSendText,
  getQuoteUndoEmail,
  getQuoteUndoText,
  getQuoteUpdateEmail,
  getQuoteUpdateText,
};
