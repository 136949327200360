import { useState, useContext, Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { AdminDetailHq } from '../../utils/gql';
import {
  AdminDetailsQuery,
  AdminDetailsQueryVariables,
  AdminEditAdminMutation,
  AdminEditAdminMutationVariables,
} from '../../gql/graphql';

const adminDetailsQuery = gql`
  query AdminDetails($expertId: ID!) {
    expertDetails(expertId: $expertId) {
      ...AdminDetailHq
    }
  }
  ${AdminDetailHq}
`;

const adminEditAdminMutation = gql`
  mutation AdminEditAdmin(
    $adminId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $secondaryEmails: [String!]!
    $blockAdminAccess: Boolean
  ) {
    adminEditAdmin(
      adminId: $adminId
      firstName: $firstName
      lastName: $lastName
      email: $email
      secondaryEmails: $secondaryEmails
      blockAdminAccess: $blockAdminAccess
    ) {
      ...AdminDetailHq
    }
  }
  ${AdminDetailHq}
`;

interface AdminAdminDetailProps {
  adminId: string;
}

const AdminAdminDetail = ({ adminId }: AdminAdminDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const [actionLoading, setActionLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [editExpertFirst, setEditExpertFirst] = useState('');
  const [editExpertLast, setEditExpertLast] = useState('');
  const [editExpertEmail, setEditExpertEmail] = useState('');
  const [editExpertSecondaryEmails, setEditExpertSecondaryEmails] = useState(
    [] as string[],
  );
  const [editExpertBlockAccess, setEditExpertBlockAccess] = useState('false');
  const [primaryPanel, setPrimaryPanel] = useState('About');
  const {
    data: dataExpert,
    error: errorExpert,
    loading: loadingExpert,
  } = useQuery<AdminDetailsQuery, AdminDetailsQueryVariables>(
    adminDetailsQuery,
    {
      returnPartialData: true,
      variables: {
        expertId: adminId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorExpert) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorExpert) || 'Could not load admin details';
  }
  const expertDetails =
    dataExpert && dataExpert.expertDetails && dataExpert.expertDetails.id
      ? dataExpert.expertDetails
      : undefined;
  if (
    expertDetails &&
    expertDetails.status &&
    expertDetails.status !== 'admin'
  ) {
    fullErrorCover = 'This expert is not an admin!';
  }
  const [tryEditExpert] = useMutation<
    AdminEditAdminMutation,
    AdminEditAdminMutationVariables
  >(adminEditAdminMutation);
  function onSaveEditingInfo() {
    if (actionLoading) return;
    if (!editExpertFirst || !editExpertLast || !editExpertEmail) {
      addNotification('Please submit all the fields!', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryEditExpert({
      variables: {
        adminId,
        blockAdminAccess: editExpertBlockAccess === 'true',
        email: editExpertEmail,
        firstName: editExpertFirst,
        lastName: editExpertLast,
        secondaryEmails: editExpertSecondaryEmails.filter(
          (se) => !!se && !!se.trim(),
        ),
      },
    })
      .then(() => {
        setIsEditingInfo(false);
        setActionLoading(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Admin Edit Error');
        logError(err, {
          component: 'AdminAdminDetail',
          func: 'tryEditExpert',
        });
      });
  }
  function startEditingInfo() {
    if (!expertDetails) {
      return;
    }
    setIsEditingInfo(true);
    setEditExpertFirst(expertDetails.firstName || '');
    setEditExpertLast(expertDetails.lastName || '');
    setEditExpertEmail(expertDetails.primaryPersonalEmail || '');
    setEditExpertSecondaryEmails(
      (expertDetails.secondaryPersonalEmails || []).filter(
        (se) => se !== expertDetails.primaryPersonalEmail,
      ),
    );
    setEditExpertBlockAccess(expertDetails.blockAdminAccess ? 'true' : 'false');
  }
  const secondaryPanels = ['About'].filter((p) => p !== primaryPanel);
  if (loadingExpert) {
    // ignore these
  }
  return (
    <div className="DashboardModal AdminAdminDetailModal">
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/extras/hq')
              }
              className="ThreadDetailMainHeaderBack"
            >
              {expertDetails
                ? expertDetails.firstName + ' ' + expertDetails.lastName
                : 'Back'}
            </div>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody AdminAdminDetailView"></div>
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div className="ThreadDetailSidebar">
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => setPrimaryPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            <div className="ThreadDetailSidebarBodyPanels">
              {primaryPanel === 'About' && expertDetails && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                  {isEditingInfo ? (
                    <div className="ThreadDetailSidebarAdminSection ThreadDetailSidebarAdminSectionPaddingTop">
                      <div
                        className="ThreadDetailSidebarAdminSectionTopRightBtn"
                        onClick={() => setIsEditingInfo(false)}
                      >
                        cancel
                      </div>
                      <div className="ThreadDetailSidebarAdminForm">
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          First Name
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="First Name"
                          value={editExpertFirst}
                          onChange={(e) =>
                            setEditExpertFirst(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Last Name
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Last Name"
                          value={editExpertLast}
                          onChange={(e) =>
                            setEditExpertLast(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Email
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="email"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Email"
                          value={editExpertEmail}
                          onChange={(e) =>
                            setEditExpertEmail(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Secondary Emails
                        </div>
                        {editExpertSecondaryEmails.map((se, i) => (
                          <input
                            key={i}
                            className="ThreadDetailSidebarAdminFormInput ThreadDetailSidebarAdminFormInputNoBottom"
                            type="email"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Secondary Email Address"
                            value={se}
                            onChange={(e) =>
                              setEditExpertSecondaryEmails(
                                editExpertSecondaryEmails
                                  .slice(0, i)
                                  .concat(e.currentTarget.value)
                                  .concat(
                                    editExpertSecondaryEmails.slice(i + 1),
                                  ),
                              )
                            }
                          />
                        ))}
                        <div
                          className="ThreadDetailSidebarAdminFormExtra"
                          onClick={() =>
                            setEditExpertSecondaryEmails(
                              editExpertSecondaryEmails.concat(''),
                            )
                          }
                        >
                          Add another email?
                        </div>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Block Admin Access?
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editExpertBlockAccess}
                          onChange={(e) =>
                            setEditExpertBlockAccess(e.currentTarget.value)
                          }
                        >
                          <option value="false">False</option>
                          <option value="true">True</option>
                        </select>
                        <div
                          className={
                            'ThreadDetailSidebarAdminFormAction ' +
                            (actionLoading
                              ? ' ThreadDetailSidebarAdminFormActionLoading '
                              : '')
                          }
                          onClick={onSaveEditingInfo}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="ThreadDetailSidebarAdminSection ThreadDetailSidebarAdminSectionPaddingTop">
                      <div
                        className="ThreadDetailSidebarAdminSectionTopRightBtn"
                        onClick={startEditingInfo}
                      >
                        edit
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Admin
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionBig">
                        {expertDetails.firstName + ' ' + expertDetails.lastName}
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription ThreadDetailSidebarAdminSectionDescriptionBad">
                        Admin Access:{' '}
                        {expertDetails.blockAdminAccess ? 'BLOCKED' : 'Active'}
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Email:{' '}
                        <span className="SelectAllText">
                          {expertDetails.primaryPersonalEmail || ''}
                        </span>
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Secondary emails:{' '}
                        {(expertDetails.secondaryPersonalEmails || [])
                          .filter(
                            (se) => se !== expertDetails.primaryPersonalEmail,
                          )
                          .map((se, i) => (
                            <Fragment key={i}>
                              <span className="SelectAllText">{se}</span>,{' '}
                            </Fragment>
                          ))}
                      </div>
                      {expertDetails.createdAt && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Created:{' '}
                          {moment(expertDetails.createdAt).format(
                            'MMMM Do, YYYY',
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/extras/hq')
              }
              className="DashboardErrorCoverNav"
            >
              back
            </div>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminAdminDetail.propTypes = {
  adminId: PropTypes.string.isRequired,
};

export default AdminAdminDetail;
