/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useContext } from 'react';
import { convertToRaw, ContentState } from 'draft-js';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import { useDebouncedCallback } from 'use-debounce';
import TextareaAutosize from 'react-autosize-textarea';
import PropTypes from 'prop-types';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { TemplatesQuery } from '../../gql/graphql';
import '../../styles/feature/QuoteEditor.scss';
import {
  amountClientFee,
  getExpertCut,
  formatNumberWithCommas,
  pitchDiscountCode,
  valueClientFee,
} from '../../utils/format';
import { QUOTE_TEMPLATE } from '../../utils/constants';
import tokenUtils from '../../utils/token';
import RichTextEditor from './RichTextEditor';

interface QuoteEditorProps {
  actionType: string;
  expertBroughtClient: boolean;
  expertCreatedAt: number;
  hasClientFee: string;
  hasDiscountCode: string;
  inactiveQuoteEditingError?: boolean;
  initContent: string;
  levelEstimate: string;
  onDeleteDraft: () => void;
  onNextStep: (draftContent: string, contentLength: number) => void;
  onSaveDraft: (
    title: string,
    paymentPrice: string,
    paymentType: string,
    draftContent: string | null,
    timelineDate: moment.Moment,
    timelineDays: string,
    timelineType: string,
  ) => void;
  paymentPrice: string;
  paymentType: string;
  quoteTemplates: TemplatesQuery['templates'];
  status: string;
  timelineDate: moment.Moment;
  timelineDays: string;
  timelineType: string;
  title: string;
}

const QuoteEditor = ({
  actionType,
  expertBroughtClient,
  expertCreatedAt,
  hasClientFee,
  hasDiscountCode,
  inactiveQuoteEditingError,
  initContent,
  levelEstimate,
  onDeleteDraft,
  onNextStep,
  onSaveDraft,
  paymentPrice,
  paymentType,
  quoteTemplates,
  status,
  timelineDate,
  timelineDays,
  timelineType,
  title,
}: QuoteEditorProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [dateFocused, setDateFocused] = useState(false);
  const [priceFocused, setPriceFocused] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const [showSwitchOption, setShowSwitchOption] = useState(false);
  const [usePureText, setUsePureText] = useState(
    () => tokenUtils.readLocalStorage('use-pure-text') === 'true',
  );
  const [pureText, setPureText] = useState('');
  const [initQuoteContent, setInitQuoteContent] = useState(
    initContent || QUOTE_TEMPLATE,
  );
  const [editorLatest, setEditorLatest] = useState<ContentState | null>(null);
  const priceVal = Math.round(
    parseFloat((paymentPrice || '').replace(/\$/gi, '')) || 0,
  );
  const cutVal =
    priceVal && priceVal > 0
      ? getExpertCut(
          levelEstimate,
          priceVal,
          expertBroughtClient,
          expertCreatedAt,
        )
      : '';
  const clientFeeStr = amountClientFee(hasClientFee);
  const clientFeeVal =
    priceVal && priceVal >= 30
      ? formatNumberWithCommas(
          Math.round(priceVal * valueClientFee(hasClientFee)).toString(),
        )
      : '';
  const timelineVal = Math.round(parseFloat(timelineDays || '') || 0);
  const debounced = useDebouncedCallback(() => {
    trySaveDraft(null, null, null, editorLatest, null, null, null);
  }, 1000);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [hasClientFee, priceVal]);
  function trySaveDraft(
    draftTitle: string | null,
    draftPaymentPrice: string | null,
    draftPaymentType: string | null,
    draftContentUpdated: ContentState | null,
    draftTimelineDate: moment.Moment | null,
    draftTimelineDays: string | null,
    draftTimelineType: string | null,
  ) {
    let draftContent: string | null = null;
    if (draftContentUpdated !== null) {
      if (draftContentUpdated.hasText()) {
        try {
          draftContent = JSON.stringify(convertToRaw(draftContentUpdated));
        } catch (rawError) {
          console.log('quote convertToRaw error', rawError);
        }
      } else {
        draftContent = '';
      }
    }
    onSaveDraft(
      draftTitle === null ? title : draftTitle,
      draftPaymentPrice === null ? paymentPrice : draftPaymentPrice,
      draftPaymentType === null ? paymentType : draftPaymentType,
      draftContent,
      draftTimelineDate === null ? timelineDate : draftTimelineDate,
      draftTimelineDays === null ? timelineDays : draftTimelineDays,
      draftTimelineType === null ? timelineType : draftTimelineType,
    );
  }
  function switchEditorType() {
    debounced.cancel();
    if (usePureText) {
      setInitQuoteContent(pureText || '');
      setUsePureText(false);
      tokenUtils.removeLocalStorage('use-pure-text');
    } else {
      setPureText((editorLatest && editorLatest.getPlainText()) || '');
      setUsePureText(true);
      tokenUtils.writeLocalStorage('use-pure-text', 'true');
    }
    setShowSwitchOption(false);
  }
  function tryDelete() {
    debounced.cancel();
    onDeleteDraft();
  }
  function tryNext() {
    if (inactiveQuoteEditingError) {
      addNotification(
        'This project has been updated. Please delete this draft and start over.',
        undefined,
        5000,
      );
      return;
    }
    if (!title.trim()) {
      addNotification('You need a title for your quote.', undefined, 5000);
      return;
    }
    if (!paymentPrice || !priceVal || priceVal <= 0) {
      addNotification(
        'You need a valid price set for your quote.',
        undefined,
        5000,
      );
      return;
    }
    if (
      paymentType === 'PROJECT' &&
      (!timelineType ||
        (timelineType === 'DAYS' && (!timelineVal || timelineVal <= 0)) ||
        (timelineType === 'DATE' && (!timelineDate || !timelineDate.isAfter())))
    ) {
      addNotification(
        'You need a valid timeline set for your quote.',
        undefined,
        5000,
      );
      return;
    }
    if (usePureText) {
      if (!pureText.trim()) {
        addNotification(
          'You need a description for your quote.',
          undefined,
          5000,
        );
        return;
      }
      debounced.cancel();
      onNextStep(pureText, pureText.length);
    } else {
      const checkContent =
        (editorLatest &&
          editorLatest.hasText() &&
          editorLatest.getPlainText().replace(/\s/gi, '').trim()) ||
        '';
      if (!checkContent.length) {
        addNotification(
          'You need a description for your quote.',
          undefined,
          5000,
        );
        return;
      }
      debounced.cancel();
      let draftContent = '';
      try {
        draftContent = JSON.stringify(convertToRaw(editorLatest!));
      } catch (rawError) {
        console.log('quote next convertToRaw error', rawError);
      }
      onNextStep(draftContent, checkContent.length);
    }
  }
  function onEditorUpdated(updatedContent: ContentState) {
    setInitQuoteContent('');
    setEditorLatest(updatedContent);
    debounced.callback();
  }
  function onBlurPrice(e: React.FocusEvent<HTMLInputElement>) {
    setPriceFocused(false);
    trySaveDraft(
      null,
      '$' +
        (
          Math.max(
            0,
            Math.round(
              parseFloat((e.currentTarget.value || '').replace(/\$/gi, '')) ||
                0,
            ),
          ) || ''
        ).toString(),
      null,
      null,
      null,
      null,
      null,
    );
  }
  function paymentTypeName(pType: string) {
    if (pType === 'BILL') return 'Bill';
    if (pType === 'MONTHLY_SUBSCRIPTION') return 'Repeat';
    if (pType === 'WEEKLY_SUBSCRIPTION') return 'Repeat';
    return 'Project';
  }
  function useTemplate(description: string) {
    setShowTemplates(false);
    setInitQuoteContent(description);
  }
  const otherPaymentTypes = ['PROJECT', 'MONTHLY_SUBSCRIPTION', 'BILL'].filter(
    (o) => {
      if (o === paymentType) return false;
      if (paymentType === 'WEEKLY_SUBSCRIPTION' && o === 'MONTHLY_SUBSCRIPTION')
        return false;
      return true;
    },
  );
  return (
    <div className="ThreadEditor QuoteEditor">
      <div className="ThreadEditorTop">
        {!usePureText && <div className="ThreadEditorTopStatus">{status}</div>}
        <div className="ThreadEditorRow">
          <div className="ThreadEditorRowTitle">Title</div>
          <div className="ThreadEditorRowInput">
            <TextareaAutosize
              type="text"
              placeholder="Project Title"
              spellCheck="true"
              className="ThreadEditorRowInputTextarea"
              value={title}
              onChange={(e) => {
                trySaveDraft(
                  e.currentTarget.value.replace(/[\r\n]/gi, ' '),
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="ThreadEditorCardWrapper">
        <div className="ThreadEditorCard">
          <div
            className={
              'ThreadEditorBody ' +
              (showTemplates ? ' ThreadEditorBodyWithTemplates ' : '')
            }
          >
            {!usePureText ? (
              <RichTextEditor
                initContent={initQuoteContent}
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                onUpdated={onEditorUpdated}
              />
            ) : (
              <div className="EmailEditorWrap">
                <TextareaAutosize
                  type="text"
                  rows={10}
                  placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                  spellCheck="true"
                  className="EmailEditorTextarea"
                  value={pureText}
                  onChange={(e) => {
                    setPureText(e.currentTarget.value);
                  }}
                />
              </div>
            )}
            <div
              className={
                'ThreadEditorBodySwitch ThreadEditorBodySwitch' +
                (showSwitchOption ? 'Active' : 'Inactive')
              }
            >
              <div
                className="ThreadEditorBodySwitchToggle"
                onClick={() => setShowSwitchOption(!showSwitchOption)}
              />
              {!!showSwitchOption && (
                <div
                  className="ThreadEditorBodySwitchOption"
                  onClick={switchEditorType}
                >
                  {usePureText ? 'use full editor' : 'use simple editor'}
                </div>
              )}
            </div>
            {!!inactiveQuoteEditingError && (
              <div className="ThreadEditorBodyError">
                This project has been updated. Please delete this draft and
                start over.
              </div>
            )}
            {!!showTemplates && (
              <div className="ThreadEditorBodyTemplates">
                <div className="ThreadEditorBodyTemplatesHeader">
                  Your Templates{' '}
                  <Link to="/profile?templates=setup">manage</Link>
                </div>
                <div
                  className="ThreadEditorBodyTemplatesClose"
                  onClick={() => setShowTemplates(false)}
                />
                {quoteTemplates.length ? (
                  <div className="ThreadEditorBodyTemplatesList">
                    {quoteTemplates.map((t) => (
                      <div
                        className="ThreadEditorBodyTemplatesListItem"
                        key={t.id}
                        onClick={() => useTemplate(t.description)}
                      >
                        {t.title}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="ThreadEditorBodyTemplatesEmpty">
                    No templates found. Click &quot;manage&quot; to create your
                    first quote template.
                  </div>
                )}
              </div>
            )}
          </div>
          {!!hasDiscountCode && (
            <div className="ThreadEditorMiddle">
              {paymentType === 'MONTHLY_SUBSCRIPTION' ||
              paymentType === 'WEEKLY_SUBSCRIPTION'
                ? "Heads up, their discount code can't be used on subscriptions. You'll need to explain this to them."
                : `${pitchDiscountCode(
                    hasDiscountCode,
                  )} You should quote the full
              amount and we will handle the discount before they pay. You will
              still get paid based on the full quote.`}
            </div>
          )}
          <div className="ThreadEditorTools">
            <a
              className="ThreadEditorTool ThreadEditorToolCalculator"
              href="https://loremtech.typeform.com/to/OYtDyA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>quote </span>calculator
            </a>
            <a
              className="ThreadEditorTool ThreadEditorToolExamples"
              href="https://kb.storetasker.com/Quote-Examples-723296eb20c3477a8b74e9b539819c35"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>see </span>examples
            </a>
            {!usePureText && (
              <div
                className="ThreadEditorTool ThreadEditorToolTemplate"
                onClick={() => setShowTemplates(!showTemplates)}
              >
                <span>use a </span>template
              </div>
            )}
          </div>
          <div className="ThreadEditorFooter">
            <div className="ThreadEditorFooterUpper">
              <div className="ThreadEditorQuoteCustomizations">
                <div
                  className={
                    'ThreadEditorQuoteCustomization ' +
                    (priceFocused
                      ? ' ThreadEditorQuoteCustomizationPriceFocused '
                      : '')
                  }
                >
                  <div className="ThreadEditorQuoteCustomizationTitle">
                    <div className="ThreadEditorQuoteCustomizationTitleOption ThreadEditorQuoteCustomizationTitleOptionActive">
                      {paymentTypeName(paymentType)}
                    </div>
                    {actionType === 'CREATE' &&
                      otherPaymentTypes.map((pType) => (
                        <div
                          key={pType}
                          className="ThreadEditorQuoteCustomizationTitleOption ThreadEditorQuoteCustomizationTitleOptionOther"
                          onClick={() =>
                            trySaveDraft(
                              null,
                              null,
                              pType,
                              null,
                              null,
                              null,
                              null,
                            )
                          }
                        >
                          {paymentTypeName(pType)}
                        </div>
                      ))}
                  </div>
                  <div className="ThreadEditorQuoteCustomizationField">
                    <div className="ThreadEditorQuoteCustomizationFieldGrow">
                      <div className="ThreadEditorQuoteCustomizationFieldGrowHidden">
                        {paymentPrice}
                      </div>
                      <input
                        type="text"
                        className={
                          'ThreadEditorQuoteCustomizationFieldGrowInput ' +
                          (priceVal && priceVal > 0
                            ? ''
                            : ' ThreadEditorQuoteCustomizationFieldGrowInputInvalid ')
                        }
                        autoComplete="new-off"
                        spellCheck="false"
                        placeholder="$250"
                        value={paymentPrice}
                        onFocus={() => setPriceFocused(true)}
                        onChange={(e) =>
                          trySaveDraft(
                            null,
                            '$' +
                              (e.currentTarget.value || '').replace(/\$/gi, ''),
                            null,
                            null,
                            null,
                            null,
                            null,
                          )
                        }
                        onBlur={onBlurPrice}
                      />
                      {!!cutVal && (
                        <div className="ThreadEditorQuoteCustomizationFieldBank">
                          (${cutVal})
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {paymentType === 'PROJECT' && (
                  <div className="ThreadEditorQuoteCustomization">
                    <div className="ThreadEditorQuoteCustomizationTitle">
                      <div className="ThreadEditorQuoteCustomizationTitleOption ThreadEditorQuoteCustomizationTitleOptionActive">
                        {timelineType === 'DAYS' ? 'Timeline' : 'Date'}
                      </div>
                      <div
                        className="ThreadEditorQuoteCustomizationTitleOption ThreadEditorQuoteCustomizationTitleOptionOther"
                        onClick={() =>
                          trySaveDraft(
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            timelineType === 'DAYS' ? 'DATE' : 'DAYS',
                          )
                        }
                      >
                        {timelineType === 'DAYS' ? 'Date' : 'Timeline'}
                      </div>
                    </div>
                    <div className="ThreadEditorQuoteCustomizationField">
                      {timelineType === 'DAYS' ? (
                        <div className="ThreadEditorQuoteCustomizationDays">
                          <div className="ThreadEditorQuoteCustomizationFieldGrow">
                            <div className="ThreadEditorQuoteCustomizationFieldGrowHidden">
                              {timelineDays}
                            </div>
                            <input
                              type="text"
                              className={
                                'ThreadEditorQuoteCustomizationFieldGrowInput ' +
                                (timelineVal && timelineVal > 0
                                  ? ''
                                  : ' ThreadEditorQuoteCustomizationFieldGrowInputInvalid ')
                              }
                              autoComplete="new-off"
                              spellCheck="false"
                              placeholder="3"
                              value={timelineDays}
                              onChange={(e) =>
                                trySaveDraft(
                                  null,
                                  null,
                                  null,
                                  null,
                                  null,
                                  (e.currentTarget.value || '').replace(
                                    /[^\d]/gi,
                                    '',
                                  ),
                                  null,
                                )
                              }
                              onBlur={(e) =>
                                trySaveDraft(
                                  null,
                                  null,
                                  null,
                                  null,
                                  null,
                                  (
                                    Math.max(
                                      0,
                                      Math.round(
                                        parseFloat(
                                          e.currentTarget.value || '',
                                        ) || 0,
                                      ),
                                    ) || ''
                                  ).toString(),
                                  null,
                                )
                              }
                            />
                          </div>
                          <span> biz day{timelineDays !== '1' ? 's' : ''}</span>
                        </div>
                      ) : (
                        <div className="ThreadEditorQuoteCustomizationDate">
                          <SingleDatePicker
                            date={timelineDate}
                            onDateChange={(date) => {
                              console.log(
                                'onDateChange',
                                date,
                                date && date.format(),
                              );
                              trySaveDraft(
                                null,
                                null,
                                null,
                                null,
                                date,
                                null,
                                null,
                              );
                            }}
                            focused={dateFocused}
                            onFocusChange={({ focused }) =>
                              setDateFocused(focused || false)
                            }
                            showClearDate={false}
                            displayFormat="MMM Do"
                            small
                            noBorder
                            hideKeyboardShortcutsPanel
                            numberOfMonths={1}
                            withPortal
                            id="uniqueId"
                            placeholder="Jan 4th"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {actionType === 'CREATE' &&
                  (paymentType === 'MONTHLY_SUBSCRIPTION' ||
                    paymentType === 'WEEKLY_SUBSCRIPTION') && (
                    <div className="ThreadEditorQuoteCustomization">
                      <div className="ThreadEditorQuoteCustomizationTitle">
                        <div className="ThreadEditorQuoteCustomizationTitleOption ThreadEditorQuoteCustomizationTitleOptionActive">
                          {paymentType === 'MONTHLY_SUBSCRIPTION'
                            ? 'Monthly'
                            : 'Weekly'}
                        </div>
                        <div
                          className="ThreadEditorQuoteCustomizationTitleOption ThreadEditorQuoteCustomizationTitleOptionOther"
                          onClick={() =>
                            trySaveDraft(
                              null,
                              null,
                              paymentType === 'MONTHLY_SUBSCRIPTION'
                                ? 'WEEKLY_SUBSCRIPTION'
                                : 'MONTHLY_SUBSCRIPTION',
                              null,
                              null,
                              null,
                              null,
                            )
                          }
                        >
                          {paymentType === 'WEEKLY_SUBSCRIPTION'
                            ? 'Monthly'
                            : 'Weekly'}
                        </div>
                      </div>
                      <div className="ThreadEditorQuoteCustomizationField">
                        <div className="ThreadEditorQuoteCustomizationFieldValue">
                          {paymentType === 'MONTHLY_SUBSCRIPTION'
                            ? 'Monthly'
                            : 'Weekly'}
                        </div>
                      </div>
                    </div>
                  )}
                {(actionType === 'CREATE' || actionType === 'UPDATE') &&
                  !!hasClientFee &&
                  !!clientFeeStr &&
                  !!clientFeeVal && (
                    <div className="ThreadEditorQuoteCustomization ThreadEditorQuoteCustomizationFixed">
                      <div className="ThreadEditorQuoteCustomizationTitle">
                        <div className="ThreadEditorQuoteCustomizationTitleOption ThreadEditorQuoteCustomizationTitleOptionActive">
                          Client Fee{' '}
                          <span data-tip="For select clients, Storetasker charges a small platform fee. This will be added to their payment, not deducted from your quote." />
                        </div>
                      </div>
                      <div className="ThreadEditorQuoteCustomizationField">
                        <div className="ThreadEditorQuoteCustomizationFieldValue">
                          {clientFeeStr}%
                        </div>
                        <div className="ThreadEditorQuoteCustomizationFieldBank">
                          (${clientFeeVal})
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="ThreadEditorActions">
              <div
                className="ThreadEditorActionBtn ThreadEditorActionSend"
                onClick={tryNext}
              >
                Next
              </div>
              <div
                className="ThreadEditorActionBtn ThreadEditorActionDelete"
                onClick={tryDelete}
              >
                Delete
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuoteEditor.propTypes = {
  actionType: PropTypes.string.isRequired,
  expertBroughtClient: PropTypes.bool.isRequired,
  expertCreatedAt: PropTypes.number.isRequired,
  hasClientFee: PropTypes.string.isRequired,
  hasDiscountCode: PropTypes.string.isRequired,
  inactiveQuoteEditingError: PropTypes.bool.isRequired,
  initContent: PropTypes.string.isRequired,
  levelEstimate: PropTypes.string.isRequired,
  onDeleteDraft: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  paymentPrice: PropTypes.string.isRequired,
  paymentType: PropTypes.string.isRequired,
  quoteTemplates: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  timelineDate: PropTypes.object.isRequired,
  timelineDays: PropTypes.string.isRequired,
  timelineType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default QuoteEditor;
