import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { SUPPORT_EXPERT_ID } from '../../utils/constants';

interface IExpertDetails {
  firstName?: string | null;
  lastName?: string | null;
  photo?: string | null;
}
interface ExpertUserBubbleProps {
  className?: string;
  expertDetails?: IExpertDetails | null;
  expertId: string;
  hideTooltip?: boolean;
  primary?: boolean;
}

const ExpertUserBubble = ({
  className,
  expertDetails,
  expertId,
  hideTooltip,
  primary,
}: ExpertUserBubbleProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const initials =
    expertId === SUPPORT_EXPERT_ID
      ? 'HQ'
      : ((expertDetails && expertDetails.firstName) || '').trim().charAt(0) +
        ((expertDetails && expertDetails.lastName) || '').trim().charAt(0);
  return (
    <div
      className={
        'UserBubble ExpertUserBubble ' +
        (primary ? ' UserBubblePrimary ' : '') +
        (className || '') +
        ' UserBubble' +
        (expertDetails && expertDetails.photo ? 'Image ' : 'Initials ')
      }
      data-tip={
        hideTooltip
          ? undefined
          : ((expertDetails && expertDetails.firstName) || '').trim() +
            ' ' +
            ((expertDetails && expertDetails.lastName) || '').trim()
      }
    >
      {expertDetails && expertDetails.photo ? (
        <img
          src={expertDetails && expertDetails.photo}
          alt="Storetasker Expert"
        />
      ) : (
        initials
      )}
    </div>
  );
};

ExpertUserBubble.propTypes = {
  className: PropTypes.string,
  expertDetails: PropTypes.object,
  hideTooltip: PropTypes.bool,
  primary: PropTypes.bool,
};

ExpertUserBubble.defaultProps = {
  className: '',
  expertDetails: null,
  hideTooltip: false,
  primary: false,
};

export default ExpertUserBubble;
