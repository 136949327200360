function pick(production: string, staging: string, dev: string) {
  if (process.env.NODE_ENV === 'test') return staging;
  if (process.env.REACT_APP_STAGING) return staging;
  if (process.env.NODE_ENV === 'production') return production;
  if (dev === undefined) return staging;
  return dev;
}

function get() {
  if (process.env.NODE_ENV === 'test') return 'test';
  if (process.env.REACT_APP_STAGING) return 'staging';
  if (process.env.NODE_ENV === 'production') return 'production';
  return 'dev';
}

function pickWebAppUrlForUserType(userType = 'web') {
  switch (userType) {
    case 'web':
      return pick(
        'https://www.storetasker.com',
        'https://www-dev.storetasker.com',
        'http://localhost:3000',
      );
    case 'human':
      return pick(
        'https://app.storetasker.com',
        'https://app-dev.storetasker.com',
        'http://localhost:3001',
      );
    case 'expert':
      return pick(
        'https://expert.storetasker.com',
        'https://expert-dev.storetasker.com',
        'http://localhost:3002',
      );
    case 'partner':
      return pick(
        'https://partner.storetasker.com',
        'https://partner-dev.storetasker.com',
        'http://localhost:3004',
      );
    default:
      return '';
  }
}

function pickRestApi() {
  return pick(
    'https://api.storetasker.com',
    'https://dev.storetasker.com',
    'http://localhost:4000',
  );
}

export default {
  get,
  pick,
  pickRestApi,
  pickWebAppUrlForUserType,
};
