import { useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/feature/MultiSelect.scss';

interface MultiSelectOption {
  label: string;
  value: string;
}

interface MultiSelectProps {
  className?: string;
  currValues: string[];
  max?: number;
  onChange: (vals: string[]) => void;
  options: MultiSelectOption[];
  placeholder?: string;
  withTags?: boolean;
}

const MultiSelect = ({
  className,
  currValues,
  max,
  onChange,
  options,
  placeholder,
  withTags,
}: MultiSelectProps) => {
  const atMaxCount = currValues.length >= (max || Number.POSITIVE_INFINITY);
  const [searchInput, setSearchInput] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const filtered = options.filter(
    (o) =>
      (o.label.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0 ||
        o.value.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0) &&
      currValues.indexOf(o.value) === -1,
  );
  function selectOption(value: string) {
    onChange(currValues.concat(value));
    setSearchInput('');
    setShowOptions(false);
  }
  function removeOption(value: string) {
    onChange(currValues.filter((v) => v !== value));
  }
  return (
    <div className={'MultiSelect ' + (className || '')}>
      {!!withTags && (
        <div className="MultiSelectTags">
          {currValues.map((val) => {
            const found = options.find((o) => o.value === val);
            return found ? (
              <div
                key={found.value}
                className="MultiSelectTag"
                onClick={() => removeOption(found.value)}
              >
                {found.label}
              </div>
            ) : null;
          })}
        </div>
      )}
      <div
        className={
          'MultiSelectContainer ' +
          (atMaxCount ? ' MultiSelectContainerDisabled ' : '')
        }
      >
        <div className="MultiSelectInputBox">
          <input
            className="MultiSelectInput"
            type="text"
            autoComplete="new-off"
            spellCheck="false"
            placeholder={placeholder || 'Select'}
            value={searchInput}
            onChange={(e) => setSearchInput(e.currentTarget.value)}
            onFocus={() => setShowOptions(true)}
            onBlur={() => setShowOptions(false)}
            disabled={atMaxCount}
          />
        </div>
        {!!filtered.length && showOptions && (
          <div className="MultiSelectResults">
            <div className="MultiSelectResultsList">
              {filtered.map((f) => (
                <div
                  key={f.value}
                  className="MultiSelectResult"
                  onMouseDown={() => selectOption(f.value)}
                  onClick={() => selectOption(f.value)}
                >
                  {f.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

MultiSelect.propTypes = {
  className: PropTypes.string,
  currValues: PropTypes.array.isRequired,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  withTags: PropTypes.bool,
};

MultiSelect.defaultProps = {
  className: '',
  max: undefined,
  placeholder: '',
  withTags: false,
};

export default MultiSelect;
