import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  Fragment,
} from 'react';
import { useHistory } from 'react-router-dom';

interface INotificationParams {
  requestId: string;
}

interface INotificationContext {
  addNotification: (
    message: string,
    status?: string,
    timeout?: number,
    params?: INotificationParams,
  ) => void;
  notification: INotification | null;
  removeNotification: () => void;
}
interface INotification {
  message: string;
  status: string;
  requestId?: string;
}

export const GlobalNotificationContext = createContext<INotificationContext>({
  addNotification: (
    message: string,
    status?: string,
    timeout?: number,
    params?: INotificationParams,
  ) => {
    console.log(message, status, timeout, params);
  },
  notification: null,
  removeNotification: () => {},
});

interface IProviderProps {
  children: ReactNode | ReactNode[];
}

let currTimeout: NodeJS.Timeout | undefined;
export default function GlobalNotificationProvider({
  children,
}: IProviderProps) {
  const [notification, setNotification] = useState<INotification | null>(null);

  const removeNotification = () => {
    if (currTimeout) {
      clearTimeout(currTimeout);
    }
    setNotification(null);
  };

  const addNotification = (notif: INotification) => setNotification(notif);

  const contextValue = {
    addNotification: useCallback(
      (
        message: string,
        status?: string,
        timeout?: number,
        params?: INotificationParams,
      ) => {
        if (status === 'SUGGESTION') {
          if (
            params &&
            params.requestId &&
            window &&
            window.location &&
            window.location.pathname &&
            window.location.pathname.split('/')[1] !== 'leads'
          ) {
            addNotification({
              message: "A new project we think you'll like is in the queue!",
              requestId: params.requestId,
              status: status || 'BAD',
            });
          }
        } else {
          addNotification({
            message,
            status: status || 'BAD',
          });
        }
        if (currTimeout) {
          clearTimeout(currTimeout);
        }
        currTimeout = setTimeout(removeNotification, timeout || 15000);
      },
      [],
    ),
    notification,
    removeNotification: useCallback(() => removeNotification(), []),
  };

  return (
    <GlobalNotificationContext.Provider value={contextValue}>
      {children}
    </GlobalNotificationContext.Provider>
  );
}

export function GlobalNotification() {
  const history = useHistory();
  const { removeNotification, notification } = useContext(
    GlobalNotificationContext,
  );
  const goToQueue = () => {
    history.push(
      notification && notification.requestId
        ? '/leads/' + notification.requestId
        : '/leads',
    );
    removeNotification();
  };

  return (
    <div
      className="GlobalNotification"
      style={{ display: notification ? 'block' : 'none' }}
    >
      <div
        className={
          'GlobalNotificationBody GlobalNotificationStyle' +
          (notification ? notification.status : '')
        }
        onClick={
          notification && notification.status !== 'SUGGESTION'
            ? () => removeNotification()
            : undefined
        }
      >
        {!!notification && (
          <Fragment>
            <div className="GlobalNotificationText">
              {notification.message + ' '}
              {notification.status === 'SUGGESTION' && (
                <span onClick={goToQueue}>view</span>
              )}
            </div>
            <div
              className="GlobalNotificationClose"
              onClick={
                notification.status === 'SUGGESTION'
                  ? () => removeNotification()
                  : undefined
              }
            />
          </Fragment>
        )}
      </div>
    </div>
  );
}
