import { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { MatchDangerZoneForAdminFullQuery } from '../../gql/graphql';
import { MatchSummaryHq } from '../../utils/gql';
import errorUtils from '../../utils/error';

const matchDangerZoneForAdminFullQuery = gql`
  query MatchDangerZoneForAdminFull {
    matchDangerZoneForAdmin {
      ...MatchSummaryHq
    }
  }
  ${MatchSummaryHq}
`;

interface AdminDangerZoneProps {
  socketClient: ClientWithOnReconnected;
}

const initTime = new Date().getTime();

const AdminDangerZone = ({ socketClient }: AdminDangerZoneProps) => {
  const {
    data: dataDangerZones,
    error: errorDangerZones,
    loading: loadingDangerZones,
    refetch: refetchDangerZones,
  } = useQuery<MatchDangerZoneForAdminFullQuery>(
    matchDangerZoneForAdminFullQuery,
    {
      returnPartialData: true,
    },
  );
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminDangerZone socketClient onReconnected');
      refetchDangerZones().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchDangerZones]);
  // combine all data, dedup, filter, sort into 4 groups, render
  const dangerZoneForAdminAll = _.uniqBy(
    [
      ...((dataDangerZones && dataDangerZones.matchDangerZoneForAdmin) || []),
    ].filter(
      (m) =>
        m.dangerZoneEventStr &&
        (!m.dangerZoneSnoozedUntil || m.dangerZoneSnoozedUntil <= initTime),
    ),
    'id',
  ).sort(
    (a, b) =>
      (a.dangerZoneEvent ? a.dangerZoneEvent.createdAt : 0) -
      (b.dangerZoneEvent ? b.dangerZoneEvent.createdAt : 0),
  );
  const dangerZoneForAdminSupportCases = dangerZoneForAdminAll.filter(
    (m) => m.dangerZoneEvent && m.dangerZoneEvent.isSupportCase,
  );
  const dangerZoneForAdminOthers = dangerZoneForAdminAll.filter(
    (m) => !m.dangerZoneEvent || !m.dangerZoneEvent.isSupportCase,
  );
  const dangerZoneForAdminPast = dangerZoneForAdminOthers.filter(
    (m) =>
      m.dangerZoneEvent &&
      m.dangerZoneEvent.dangerZoneReason &&
      m.dangerZoneEvent.dangerZoneReason === 'WAY_PAST_DEADLINE',
  );
  const dangerZoneForAdminRest = dangerZoneForAdminOthers.filter(
    (m) =>
      !m.dangerZoneEvent ||
      !m.dangerZoneEvent.dangerZoneReason ||
      m.dangerZoneEvent.dangerZoneReason !== 'WAY_PAST_DEADLINE',
  );
  if (loadingDangerZones) {
    // ignore these
  }
  function dzeCard(
    m: MatchDangerZoneForAdminFullQuery['matchDangerZoneForAdmin'][0],
  ) {
    return (
      <div key={m.id} className="ClientSummary">
        <Link to={'/match/' + m.id} className="ClientSummaryBody">
          <div className="ClientSummaryTitle">
            {m.brand && m.brand.name ? m.brand.name : ''}
          </div>
          <div className="ClientSummarySubTitle">
            {m.expert
              ? (
                  (m.expert.firstName || '') +
                  ' ' +
                  (m.expert.lastName || '')
                ).trim()
              : ''}
          </div>
          <div className="ClientSummarySubTitle">
            {m.dangerZoneEvent ? m.dangerZoneEvent.dangerZoneReason : ''}
          </div>
          {m.dangerZoneEvent && m.dangerZoneEvent.dangerZoneAssignment && (
            <div
              className={
                'ClientSummaryAssignedTo ClientSummaryAssignedTo' +
                (m.dangerZoneEvent.dangerZoneAssignment || '').toUpperCase()
              }
            >
              {m.dangerZoneEvent.dangerZoneAssignment}
            </div>
          )}
          {m.dangerZoneEvent && m.dangerZoneEvent.isSupportCase && (
            <div className="ClientSummaryStatusTag ClientSummaryStatusTagSUPPORT-CASE">
              support case
            </div>
          )}
        </Link>
        <div className="ClientSummaryFooter" />
      </div>
    );
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div className="DashboardScreenContent ClientsScreenContent">
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Active Support Cases</div>
          {!dangerZoneForAdminSupportCases.length && (
            <div className="DashboardSectionEmpty">
              {errorDangerZones
                ? errorUtils.getErrorMessage(errorDangerZones) ||
                  'Loading Error'
                : 'No active Support Cases found'}
            </div>
          )}
          <div className="DashboardSectionScrollerSwitch">
            {dangerZoneForAdminSupportCases.map(dzeCard)}
          </div>
        </div>
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Danger Zone</div>
          {!dangerZoneForAdminRest.length && (
            <div className="DashboardSectionEmpty">
              {errorDangerZones
                ? errorUtils.getErrorMessage(errorDangerZones) ||
                  'Loading Error'
                : 'New danger zone things will show up here.'}
            </div>
          )}
          <div className="DashboardSectionScrollerSwitch">
            {dangerZoneForAdminRest.map(dzeCard)}
          </div>
        </div>
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Way Past Deadline</div>
          {!dangerZoneForAdminPast.length && (
            <div className="DashboardSectionEmpty">
              Danger Zone things way past deadline will show up here.
            </div>
          )}
          <div className="DashboardSectionScrollerSwitch">
            {dangerZoneForAdminPast.map(dzeCard)}
          </div>
        </div>
      </div>
    </div>
  );
};

AdminDangerZone.propTypes = {
  socketClient: PropTypes.object.isRequired,
};

export default AdminDangerZone;
