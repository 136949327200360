import _ from 'lodash';
import { useState, useContext, Fragment } from 'react';
import { ApolloError } from '@apollo/client';
import { convertToRaw, ContentState } from 'draft-js';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import RichTextEditor from '../feature/RichTextEditor';
import errorUtils from '../../utils/error';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { UserNoteBrandAdminQuery } from '../../gql/graphql';

interface UserNoteEditorProps {
  errorFound?: ApolloError;
  isExpert?: boolean;
  isLoading: boolean;
  onSave: (body: string, userNoteId?: string) => void;
  userNotes: UserNoteBrandAdminQuery['userNoteForTarget'];
}

const UserNoteEditor = ({
  errorFound,
  isExpert,
  isLoading,
  onSave,
  userNotes,
}: UserNoteEditorProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [currNoteId, setCurrNoteId] = useState('');
  const [currNoteInit, setCurrNoteInit] = useState('');
  const [editNoteDescription, setEditNoteDescription] =
    useState<ContentState | null>(null);
  const sortedNotes = _.uniqBy(userNotes, 'id').sort(
    (a, b) => b.createdAt - a.createdAt,
  );
  function selectNote(noteId: string, noteBody: string) {
    setCurrNoteId(noteId);
    setCurrNoteInit(noteBody);
    setEditNoteDescription(null);
  }
  function onCancel() {
    if (isLoading || !currNoteId) return;
    setCurrNoteId('');
    setCurrNoteInit('');
    setEditNoteDescription(null);
  }
  function trySave() {
    if (isLoading || !currNoteId) return;
    let draftContent = '';
    let plainTextContent = '';
    if (editNoteDescription && editNoteDescription.hasText()) {
      plainTextContent = editNoteDescription.getPlainText().trim();
      if (plainTextContent.replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(editNoteDescription));
        } catch (rawError) {
          console.log('noteDescription convertToRaw error', rawError);
        }
      }
    }
    if (!draftContent) {
      if (currNoteId !== 'new' || !sortedNotes.length) {
        addNotification(
          'Please write something before saving!',
          undefined,
          5000,
        );
        return;
      }
    }
    onSave(draftContent, currNoteId === 'new' ? undefined : currNoteId);
    setCurrNoteId('');
    setCurrNoteInit('');
    setEditNoteDescription(null);
  }
  return (
    <div className="HumanBrandSidebarEditor UserNoteBrandSidebarEditor BrandSidebarEditor">
      <div className="HumanBrandSidebarEditorTop">
        <div className="HumanBrandSidebarEditorTopTitle">
          {currNoteId ? 'Edit Note' : 'Notes'}
        </div>
        <div className="HumanBrandSidebarEditorTopActions">
          {currNoteId ? (
            <Fragment>
              <div
                className="HumanBrandSidebarEditorTopActionBtn HumanBrandSidebarEditorTopActionIgnore"
                onClick={onCancel}
              >
                Cancel
              </div>
              <div
                className={
                  'HumanBrandSidebarEditorTopActionBtn HumanBrandSidebarEditorTopActionSave ' +
                  (isLoading
                    ? ' HumanBrandSidebarEditorTopActionBtnLoading '
                    : '')
                }
                onClick={trySave}
              >
                Save
              </div>
            </Fragment>
          ) : (
            <div
              className="HumanBrandSidebarEditorTopActionBtn HumanBrandSidebarEditorTopActionSave"
              onClick={() => selectNote('new', '')}
            >
              Create New
            </div>
          )}
        </div>
      </div>
      <div className="HumanBrandSidebarEditorCardWrapper">
        {currNoteId ? (
          <div className="UserNoteSidebarEdit">
            <RichTextEditor
              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
              initContent={currNoteInit}
              onUpdated={setEditNoteDescription}
            />
          </div>
        ) : (
          <div className="UserNoteSidebarList">
            {!!isLoading && <div className="UserNoteSidebarListLoading" />}
            {!sortedNotes.length && !isLoading && (
              <div className="UserNoteSidebarListEmpty">
                {errorFound
                  ? errorUtils.getErrorMessage(errorFound) || 'Loading Error'
                  : 'No notes found.'}
              </div>
            )}
            {sortedNotes.map((un) => {
              const noteMoment = moment.tz(un.createdAt, moment.tz.guess());
              const dateStr = noteMoment.format('MMM Do, YYYY');
              return (
                <div key={un.id} className="UserNoteSidebarListItem">
                  <div
                    className="UserNoteSidebarListItemHeader"
                    onClick={() => selectNote(un.id, un.body)}
                  >
                    {!isExpert && un.author ? (
                      <Fragment>
                        <div className="UserNoteSidebarListItemName">
                          {un.author.firstName + ' ' + un.author.lastName}
                        </div>
                        <div className="UserNoteSidebarListItemSub">
                          {dateStr}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="UserNoteSidebarListItemName">
                          {dateStr}
                        </div>
                        <div className="UserNoteSidebarListItemEdit">edit</div>
                      </Fragment>
                    )}
                  </div>
                  <div className="UserNoteSidebarListItemBody">
                    <ReadOnlyEditor
                      className="UserNoteSidebarListItemBodyContent"
                      content={un.body}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

UserNoteEditor.propTypes = {
  errorFound: PropTypes.object,
  isExpert: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  userNotes: PropTypes.array.isRequired,
};
UserNoteEditor.defaultProps = {
  errorFound: null,
  isExpert: false,
};

export default UserNoteEditor;
