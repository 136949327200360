import { useRef, useEffect, useContext } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import PropTypes from 'prop-types';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import '../../styles/feature/MessageHqEditor.scss';

interface MessageHqEditorProps {
  content: string;
  onChangeContent: (content: string) => void;
  onDeleteContent: () => void;
  onSendContent: () => void;
}

const MessageHqEditor = ({
  content,
  onChangeContent,
  onDeleteContent,
  onSendContent,
}: MessageHqEditorProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { addNotification } = useContext(GlobalNotificationContext);
  function trySend() {
    if (!content || !content.replace(/\s/gi, '').trim()) {
      addNotification(
        'Please fill out your message details before submitting.',
        undefined,
        5000,
      );
      return;
    }
    onSendContent();
  }
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);
  return (
    <div className="ThreadEditor MessageHqEditor">
      <div className="ThreadEditorTop">
        <div className="ThreadEditorRow">
          <div className="ThreadEditorRowTitle">To</div>
          <div className="ThreadEditorRowBubbles">
            <div className="ThreadEditorRowBubble">
              <span className="ThreadEditorRowBubbleText">
                Storetasker Support
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="ThreadEditorCardWrapper">
        <div className="ThreadEditorCard">
          <div className="ThreadEditorBody">
            <div className="MessageHqEditorWrap">
              <TextareaAutosize
                type="text"
                rows={2}
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                spellCheck="true"
                ref={textareaRef}
                className="MessageHqEditorTextarea"
                value={content}
                onChange={(e) => {
                  onChangeContent(e.currentTarget.value);
                }}
              />
            </div>
          </div>
          <div className="ThreadEditorFooter">
            <div className="ThreadEditorActions">
              <div
                className="ThreadEditorActionBtn ThreadEditorActionSend"
                onClick={trySend}
              >
                Notify Support
              </div>
              <div
                className="ThreadEditorActionBtn ThreadEditorActionDelete"
                onClick={onDeleteContent}
              >
                Delete
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MessageHqEditor.propTypes = {
  content: PropTypes.string.isRequired,
  onChangeContent: PropTypes.func.isRequired,
  onDeleteContent: PropTypes.func.isRequired,
  onSendContent: PropTypes.func.isRequired,
};

export default MessageHqEditor;
