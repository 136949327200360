import axios from 'axios';
import envUtils from './env';
import oauthUtils from './oauth';

const API_HOST = envUtils.pick(
  'https://api.storetasker.com',
  'https://dev.storetasker.com',
  'http://localhost:4000',
);
function postRequest(endpoint: string, payload: any, headers: any) {
  return axios.post(API_HOST + endpoint, payload, { headers });
}

function sendNylasCode(
  nylasCode: string,
  callback: (err?: Error, accessToken?: string) => void,
) {
  postRequest(
    '/calendar/token-access',
    {
      code: nylasCode,
    },
    { Authorization: `Bearer ${oauthUtils.getToken('expert')}` },
  )
    .then((res) => res.data)
    .then((result) => {
      if (!result || !result.accessToken) {
        callback(new Error('No Access Token Found In Response'));
      } else {
        callback(undefined, result.accessToken as string);
      }
    })
    .catch((err: Error) => {
      console.log('sendNylasCode error: ', err);
      callback(err);
    });
}

export default {
  sendNylasCode,
};
