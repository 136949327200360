import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { formatValidPhoneNumber } from '../../utils/format';

interface HumanUserBubbleProps {
  className?: string;
  fallbackEmail: string | null;
  fallbackPhone: string | null;
  firstName: string | null;
  hideTooltip?: boolean;
  initialsOnly?: boolean;
  lastName: string | null;
  primary?: boolean;
}

const HumanUserBubble = ({
  className,
  fallbackEmail,
  fallbackPhone,
  firstName,
  hideTooltip,
  initialsOnly,
  lastName,
  primary,
}: HumanUserBubbleProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const cleanFirst = (firstName || '').trim();
  const cleanLast = (lastName || '').trim();
  let initials = cleanFirst.charAt(0) + cleanLast.charAt(0);
  let full = (cleanFirst + ' ' + cleanLast).trim();
  let fallback = false;
  if (!initials) {
    if (fallbackEmail) {
      fallback = true;
      initials = initialsOnly ? fallbackEmail.slice(0, 1) : fallbackEmail;
      full = fallbackEmail;
    } else if (fallbackPhone) {
      fallback = true;
      const formattedPhone =
        formatValidPhoneNumber(fallbackPhone) || fallbackPhone;
      initials = initialsOnly ? '#' : formattedPhone;
      full = formattedPhone;
    }
  }
  return (
    <div
      className={
        'UserBubble HumanUserBubble UserBubbleInitials ' +
        (primary ? ' UserBubblePrimary ' : '') +
        (fallback && !initialsOnly ? ' UserBubbleFallback ' : '') +
        (className || '')
      }
      data-tip={hideTooltip ? undefined : full}
    >
      {initials}
    </div>
  );
};

HumanUserBubble.propTypes = {
  className: PropTypes.string,
  fallbackEmail: PropTypes.string,
  fallbackPhone: PropTypes.string,
  firstName: PropTypes.string,
  hideTooltip: PropTypes.bool,
  initialsOnly: PropTypes.bool,
  lastName: PropTypes.string,
  primary: PropTypes.bool,
};

HumanUserBubble.defaultProps = {
  className: '',
  fallbackEmail: '',
  fallbackPhone: '',
  firstName: '',
  hideTooltip: false,
  initialsOnly: false,
  lastName: '',
  primary: false,
};

export default HumanUserBubble;
