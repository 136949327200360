import _ from 'lodash';
import { useState, useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { IDaySchedule } from '../../utils/props';
import '../../styles/feature/CalendarSchedule.scss';

interface IActiveSelection {
  dayIndex: number;
  endBlock: number;
  isAddTime: boolean;
  startBlock: number;
}

interface CalendarScheduleProps {
  isLoading: boolean;
  scheduleDays: IDaySchedule[][];
  updateScheduleDays: ((updated: IDaySchedule[]) => void)[];
}

const CalendarSchedule = ({
  isLoading,
  scheduleDays,
  updateScheduleDays,
}: CalendarScheduleProps) => {
  const windowWrapRef = useRef<HTMLDivElement>(null);
  const [expertCalendarActiveSelection, setExpertCalendarActiveSelection] =
    useState<IActiveSelection | null>(null);
  useEffect(() => {
    if (windowWrapRef && windowWrapRef.current) {
      windowWrapRef.current.scrollTop = 211;
    }
  }, [windowWrapRef]);
  function activeHighlightStr(dayIndex: number, blockIndex: number) {
    if (
      expertCalendarActiveSelection &&
      expertCalendarActiveSelection.dayIndex === dayIndex
    ) {
      const startBlock =
        expertCalendarActiveSelection.startBlock <=
        expertCalendarActiveSelection.endBlock
          ? expertCalendarActiveSelection.startBlock
          : expertCalendarActiveSelection.endBlock;
      const endBlock =
        expertCalendarActiveSelection.startBlock <=
        expertCalendarActiveSelection.endBlock
          ? expertCalendarActiveSelection.endBlock
          : expertCalendarActiveSelection.startBlock;
      if (startBlock <= blockIndex && endBlock >= blockIndex) {
        return expertCalendarActiveSelection.isAddTime
          ? ' CalendarScheduleSelectItemAdd '
          : ' CalendarScheduleSelectItemRemove ';
      }
    }
    return '';
  }
  function isBlockHighlight(dayIndex: number, blockIndex: number) {
    const blockStartMin = blockIndex * 30;
    return !!scheduleDays[dayIndex].find(
      (ds) => ds.start <= blockStartMin && ds.end > blockStartMin,
    );
  }
  function formatHourStr(i: number) {
    return moment().hour(i).format('ha');
  }
  function formatBlockStr(blockIndex: number) {
    return moment()
      .hour(0)
      .minute(blockIndex * 30)
      .format('h:mm a');
  }
  function startHighlight(dayIndex: number, blockIndex: number) {
    setExpertCalendarActiveSelection({
      dayIndex,
      endBlock: blockIndex,
      isAddTime: !isBlockHighlight(dayIndex, blockIndex),
      startBlock: blockIndex,
    });
  }
  function continueHighlight(dayIndex: number, blockIndex: number) {
    if (
      expertCalendarActiveSelection &&
      expertCalendarActiveSelection.dayIndex === dayIndex
    ) {
      console.log({
        dayIndex: expertCalendarActiveSelection.dayIndex,
        endBlock: blockIndex,
        isAddTime: expertCalendarActiveSelection.isAddTime,
        startBlock: expertCalendarActiveSelection.startBlock,
      });
      setExpertCalendarActiveSelection({
        dayIndex: expertCalendarActiveSelection.dayIndex,
        endBlock: blockIndex,
        isAddTime: expertCalendarActiveSelection.isAddTime,
        startBlock: expertCalendarActiveSelection.startBlock,
      });
    }
  }
  function endHighlight() {
    if (expertCalendarActiveSelection) {
      const theSchedule = scheduleDays[expertCalendarActiveSelection.dayIndex];
      let eachHour: number[] = [];
      theSchedule.forEach((ds) => {
        for (let i = ds.start; i < ds.end; i += 30) {
          eachHour.push(i);
        }
      });
      console.log(eachHour);
      const startBlock =
        expertCalendarActiveSelection.startBlock <=
        expertCalendarActiveSelection.endBlock
          ? expertCalendarActiveSelection.startBlock
          : expertCalendarActiveSelection.endBlock;
      const endBlock =
        expertCalendarActiveSelection.startBlock <=
        expertCalendarActiveSelection.endBlock
          ? expertCalendarActiveSelection.endBlock
          : expertCalendarActiveSelection.startBlock;
      if (expertCalendarActiveSelection.isAddTime) {
        for (let i = startBlock; i <= endBlock; i += 1) {
          eachHour.push(i * 30);
        }
      } else {
        eachHour = eachHour.filter(
          (h) => h < startBlock * 30 || h > endBlock * 30,
        );
      }
      eachHour = _.uniq(eachHour).sort();
      console.log(eachHour);
      const nextSchedule: IDaySchedule[] = [];
      for (let i = 0; i < eachHour.length; i += 1) {
        if (!i) {
          nextSchedule.push({ end: eachHour[i] + 30, start: eachHour[i] });
        } else if (eachHour[i] - eachHour[i - 1] === 30) {
          nextSchedule[nextSchedule.length - 1].end += 30;
        } else {
          nextSchedule.push({ end: eachHour[i] + 30, start: eachHour[i] });
        }
      }
      console.log('nextSchedule', nextSchedule);
      updateScheduleDays[expertCalendarActiveSelection.dayIndex](nextSchedule);
    }
    setExpertCalendarActiveSelection(null);
  }
  return (
    <div className="CalendarSchedule">
      {isLoading ? (
        <div className="CalendarScheduleLoading" />
      ) : (
        <div className="CalendarScheduleWrap">
          <div className="CalendarScheduleDayRow">
            <div className="CalendarScheduleDayBlank" />
            <div className="CalendarScheduleDayItem">
              <span className="CalendarScheduleDayItemBig">Sunday</span>
              <span className="CalendarScheduleDayItemSmall">Sun</span>
            </div>
            <div className="CalendarScheduleDayItem">
              <span className="CalendarScheduleDayItemBig">Monday</span>
              <span className="CalendarScheduleDayItemSmall">Mon</span>
            </div>
            <div className="CalendarScheduleDayItem">
              <span className="CalendarScheduleDayItemBig">Tuesday</span>
              <span className="CalendarScheduleDayItemSmall">Tue</span>
            </div>
            <div className="CalendarScheduleDayItem">
              <span className="CalendarScheduleDayItemBig">Wednesday</span>
              <span className="CalendarScheduleDayItemSmall">Wed</span>
            </div>
            <div className="CalendarScheduleDayItem">
              <span className="CalendarScheduleDayItemBig">Thursday</span>
              <span className="CalendarScheduleDayItemSmall">Thu</span>
            </div>
            <div className="CalendarScheduleDayItem">
              <span className="CalendarScheduleDayItemBig">Friday</span>
              <span className="CalendarScheduleDayItemSmall">Fri</span>
            </div>
            <div className="CalendarScheduleDayItem">
              <span className="CalendarScheduleDayItemBig">Saturday</span>
              <span className="CalendarScheduleDayItemSmall">Sat</span>
            </div>
          </div>
          <div
            className="CalendarScheduleTimeWrap"
            ref={windowWrapRef}
            onMouseLeave={endHighlight}
          >
            <div className="CalendarScheduleTimeColumn">
              {[...Array(24)].map((__, i) => (
                <div key={i} className="CalendarScheduleTimeItem">
                  {formatHourStr(i)}
                </div>
              ))}
            </div>
            {scheduleDays.map((___, dayIndex) => (
              <div
                key={dayIndex}
                className="CalendarScheduleSelectColumn"
                onMouseLeave={endHighlight}
              >
                {[...Array(48)].map((__, blockIndex) => (
                  <div
                    key={blockIndex}
                    className={
                      'CalendarScheduleSelectItem ' +
                      (isBlockHighlight(dayIndex, blockIndex)
                        ? ' CalendarScheduleSelectItemSelected '
                        : '') +
                      activeHighlightStr(dayIndex, blockIndex)
                    }
                    onMouseDown={() => startHighlight(dayIndex, blockIndex)}
                    onMouseEnter={() => continueHighlight(dayIndex, blockIndex)}
                    onMouseUp={endHighlight}
                  >
                    {formatBlockStr(blockIndex)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

CalendarSchedule.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  scheduleDays: PropTypes.array.isRequired,
  updateScheduleDays: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
};

export default CalendarSchedule;
