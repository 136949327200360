import React, { useState, useRef, useContext, Fragment } from 'react';
// import _ from 'lodash';
import { gql, useMutation, ApolloError } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import {
  PartnerSearchForAdminMutation,
  PartnerSearchForAdminMutationVariables,
  AdminCreatePartnerMutation,
  AdminCreatePartnerMutationVariables,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { PartnerSummary, PartnerDetail } from '../../utils/gql';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';

const partnerSearchForAdminMutation = gql`
  mutation PartnerSearchForAdmin($searchQuery: String!) {
    partnerSearchForAdmin(searchQuery: $searchQuery) {
      ...PartnerSummary
    }
  }
  ${PartnerSummary}
`;

const adminCreatePartnerMutation = gql`
  mutation AdminCreatePartner(
    $contactEmail: String!
    $contactName: String!
    $partnerName: String!
    $partnerType: String!
    $trackingId: String!
    $entityType: String!
    $companyName: String
    $isNoCommission: Boolean
    $isAlwaysDiscount: String
  ) {
    adminCreatePartner(
      contactEmail: $contactEmail
      contactName: $contactName
      partnerName: $partnerName
      partnerType: $partnerType
      trackingId: $trackingId
      entityType: $entityType
      companyName: $companyName
      isNoCommission: $isNoCommission
      isAlwaysDiscount: $isAlwaysDiscount
    ) {
      ...PartnerDetail
    }
  }
  ${PartnerDetail}
`;

const AdminPartners = () => {
  const history = useHistory();
  const searchRef = useRef<HTMLInputElement>(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    PartnerSearchForAdminMutation['partnerSearchForAdmin'] | null
  >(null);
  const [newPartnerContactName, setNewPartnerContactName] = useState('');
  const [newPartnerContactEmail, setNewPartnerContactEmail] = useState('');
  const [newPartnerName, setNewPartnerName] = useState('');
  const [newPartnerType, setNewPartnerType] = useState('app');
  const [newPartnerTrackingId, setNewPartnerTrackingId] = useState('');
  const [newPartnerEntityType, setNewPartnerEntityType] =
    useState('individual');
  const [newPartnerCompanyName, setNewPartnerCompanyName] = useState('');
  const [newPartnerNoCommission, setNewPartnerNoCommission] = useState('false');
  const [newPartnerIsDiscount, setNewPartnerIsDiscount] = useState('');
  const { addNotification } = useContext(GlobalNotificationContext);
  const [tryPartnerCreateAdmin] = useMutation<
    AdminCreatePartnerMutation,
    AdminCreatePartnerMutationVariables
  >(adminCreatePartnerMutation);
  function saveNewPartner() {
    if (actionLoading) return;
    if (
      !newPartnerContactName ||
      !newPartnerContactEmail ||
      !newPartnerName ||
      !newPartnerType ||
      !newPartnerTrackingId ||
      !newPartnerEntityType
    ) {
      addNotification('Please submit all the fields!', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryPartnerCreateAdmin({
      variables: {
        companyName: newPartnerCompanyName,
        contactEmail: newPartnerContactEmail,
        contactName: newPartnerContactName,
        entityType: newPartnerEntityType,
        isAlwaysDiscount: newPartnerIsDiscount || null,
        isNoCommission: newPartnerNoCommission === 'true',
        partnerName: newPartnerName,
        partnerType: newPartnerType,
        trackingId: newPartnerTrackingId
          .trim()
          .toLowerCase()
          .replace(/\s/gi, ''),
      },
    })
      .then(({ data: mutationData }) => {
        setActionLoading(false);
        setNewPartnerContactName('');
        setNewPartnerContactEmail('');
        setNewPartnerName('');
        setNewPartnerType('');
        setNewPartnerTrackingId('');
        setNewPartnerEntityType('individual');
        setNewPartnerIsDiscount('');
        setNewPartnerNoCommission('false');
        setNewPartnerCompanyName('');
        if (mutationData && mutationData.adminCreatePartner) {
          history.push(
            '/extras/partners/' + mutationData.adminCreatePartner.id,
          );
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Partner Create Error',
        );
        logError(err, {
          component: 'AdminPartners',
          func: 'tryPartnerCreateAdmin',
        });
      });
  }
  const [tryPartnerSearch] = useMutation<
    PartnerSearchForAdminMutation,
    PartnerSearchForAdminMutationVariables
  >(partnerSearchForAdminMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput) {
      toggleSearch();
      return;
    }
    if (searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchResults([]);
    tryPartnerSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        setSearchLoading(false);
        if (dataMatchSearch && dataMatchSearch.partnerSearchForAdmin) {
          console.log(
            'tryPartnerSearch',
            dataMatchSearch.partnerSearchForAdmin,
          );
          setSearchResults(dataMatchSearch.partnerSearchForAdmin);
        }
      },
      variables: {
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Partner Search Error',
      );
      logError(err, {
        component: 'AdminPartners',
        func: 'tryPartnerSearch',
      });
      setSearchLoading(false);
      setSearchResults(null);
    });
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      toggleSearch();
    }
  }
  function toggleSearch() {
    const nextShowSearch = !showSearch;
    setSearchInput('');
    setSearchResults(null);
    setSearchLoading(false);
    setShowSearch(nextShowSearch);
    if (nextShowSearch) {
      setTimeout(() => {
        if (searchRef && searchRef.current) {
          searchRef.current.focus();
        }
      });
    }
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div
        className={
          'ClientsScreenSearch ClientsScreenSearch' +
          (showSearch ? 'Active ' : '')
        }
      >
        <div className="ClientsScreenSearchBar">
          <input
            type="text"
            ref={searchRef}
            className="ClientsScreenSearchBarInput"
            autoComplete="new-off"
            spellCheck="false"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleSearchInputKeyDown}
          />
          <div className="ClientsScreenSearchToggle" onClick={toggleSearch}>
            Search Partners
          </div>
        </div>
      </div>
      <div className="DashboardScreenContent ClientsScreenContent">
        {!searchLoading && searchResults === null ? (
          <Fragment>
            <div className="DashboardSection">
              <div className="DashboardSectionHeader">Search Results</div>
              <div className="DashboardSectionEmpty">
                Search for a specific partner above.
              </div>
            </div>
            <div className="AdminDashboardForm">
              <div className="AdminDashboardFormTitle">
                Create a new partner?
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Partner Name</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Partner Name"
                  value={newPartnerName}
                  onChange={(e) => setNewPartnerName(e.currentTarget.value)}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">
                  Tracking Id (no spaces, dashes okay)
                </div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="tracking-id"
                  value={newPartnerTrackingId}
                  onChange={(e) =>
                    setNewPartnerTrackingId(e.currentTarget.value)
                  }
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Partner Type</div>
                <select
                  className="AdminDashboardFormFieldInput"
                  required
                  value={newPartnerType}
                  onChange={(e) => setNewPartnerType(e.currentTarget.value)}
                >
                  <option value="app">App</option>
                  <option value="app-projects">
                    App Projects (&quot;Need help with Bold?&quot;)
                  </option>
                  <option value="theme">Theme</option>
                  <option value="agency">Agency</option>
                  <option value="audience">Audience</option>
                  <option value="expert">Expert</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">
                  Contact Email
                </div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="email"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Contact Email"
                  value={newPartnerContactEmail}
                  onChange={(e) =>
                    setNewPartnerContactEmail(e.currentTarget.value)
                  }
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Contact Name</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Contact Name"
                  value={newPartnerContactName}
                  onChange={(e) =>
                    setNewPartnerContactName(e.currentTarget.value)
                  }
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Entity Type</div>
                <select
                  className="AdminDashboardFormFieldInput"
                  required
                  value={newPartnerEntityType}
                  onChange={(e) =>
                    setNewPartnerEntityType(e.currentTarget.value)
                  }
                >
                  <option value="individual">Individual</option>
                  <option value="business">Business</option>
                </select>
              </div>
              {newPartnerEntityType === 'business' && (
                <div className="AdminDashboardFormField">
                  <div className="AdminDashboardFormFieldTitle">
                    Company Name
                  </div>
                  <input
                    className="AdminDashboardFormFieldInput"
                    type="text"
                    autoComplete="new-off"
                    spellCheck="false"
                    placeholder="Company Name"
                    value={newPartnerCompanyName}
                    onChange={(e) =>
                      setNewPartnerCompanyName(e.currentTarget.value)
                    }
                  />
                </div>
              )}
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">No Payments?</div>
                <select
                  className="AdminDashboardFormFieldInput"
                  required
                  value={newPartnerNoCommission}
                  onChange={(e) =>
                    setNewPartnerNoCommission(e.currentTarget.value)
                  }
                >
                  <option value="false">False = yes payments</option>
                  <option value="true">True = no payments</option>
                </select>
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">
                  Customers Get Discounts?
                </div>
                <select
                  className="AdminDashboardFormFieldInput"
                  required
                  value={newPartnerIsDiscount}
                  onChange={(e) =>
                    setNewPartnerIsDiscount(e.currentTarget.value)
                  }
                >
                  <option value="">False = no discounts</option>
                  <option value="10_FIRST_FOREVER">
                    10% off first project
                  </option>
                </select>
              </div>
              <div
                className={
                  'AdminDashboardFormAction ' +
                  (actionLoading ? ' AdminDashboardFormActionLoading ' : '')
                }
                onClick={saveNewPartner}
              >
                Create
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">Search Results</div>
            {!searchLoading && searchResults && !searchResults.length && (
              <div className="DashboardSectionEmpty">
                No partners found. Try another search query to find who
                you&apos;re looking for.
              </div>
            )}
            <div className="DashboardSectionRows">
              {!searchLoading &&
                searchResults &&
                !!searchResults.length &&
                searchResults.map((partnerResult) => (
                  <div
                    key={partnerResult.id}
                    className="ClientSummary ClientSummarySearch"
                  >
                    <Link
                      to={'/extras/partners/' + partnerResult.id}
                      className="ClientSummaryBody"
                    >
                      <div className="ClientSummaryTitle">
                        {partnerResult.partnerName || ''}
                      </div>
                      <div className="ClientSummarySubTitle">
                        {partnerResult.trackingId || ''}
                      </div>
                    </Link>
                    <div className="ClientSummaryFooter" />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

AdminPartners.propTypes = {};

export default AdminPartners;
