import { useState, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import TextareaAutosize from 'react-autosize-textarea';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import '../../styles/feature/MeetingEditor.scss';
import { MatchDetailsQuery, ExpertDetailsQuery } from '../../gql/graphql';

interface MeetingEditorProps {
  actionMeetingId: string;
  description: string;
  expertDetails?: ExpertDetailsQuery['expertDetails'];
  goEditContacts?: () => void;
  location: string;
  onDeleteDraft: () => void;
  onTimePickerOpenClose: (isOpen: boolean) => void;
  onSaveDraft: (
    title: string,
    location: string,
    description: string,
    recipients: MatchDetailsQuery['matchDetails']['brand']['team'],
    timeStart: Date,
    timeEnd: Date,
    timezone: string,
  ) => void;
  onSendMeeting: () => void;
  recipients: MatchDetailsQuery['matchDetails']['brand']['team'];
  status: string;
  team?: MatchDetailsQuery['matchDetails']['brand']['team'];
  timeEnd: Date;
  timeStart: Date;
  timezone: string;
  title: string;
}

const initDate = new Date();

const MeetingEditor = ({
  actionMeetingId,
  description,
  expertDetails,
  goEditContacts,
  location,
  onDeleteDraft,
  onSaveDraft,
  onSendMeeting,
  recipients,
  status,
  team,
  timeEnd,
  timeStart,
  timezone,
  title,
  onTimePickerOpenClose,
}: MeetingEditorProps) => {
  const [recipientsOpen, setRecipientsOpen] = useState(false);
  const { addNotification } = useContext(GlobalNotificationContext);
  function trySaveDraft(
    draftTitle: string | null,
    draftLocation: string | null,
    draftDescription: string | null,
    draftRecipients: MatchDetailsQuery['matchDetails']['brand']['team'] | null,
    draftTimeStart: Date | null,
    draftTimeEnd: Date | null,
    draftTimezone: string | null,
  ) {
    onSaveDraft(
      draftTitle === null ? title : draftTitle,
      draftLocation === null ? location : draftLocation,
      draftDescription === null ? description : draftDescription,
      draftRecipients === null ? recipients : draftRecipients,
      draftTimeStart === null ? timeStart : draftTimeStart,
      draftTimeEnd === null ? timeEnd : draftTimeEnd,
      draftTimezone === null ? timezone : draftTimezone,
    );
  }
  function trySend() {
    if (
      !recipients.length ||
      !title ||
      !timeStart ||
      !timeEnd ||
      !timezone ||
      !title.replace(/\s/gi, '').trim()
    ) {
      addNotification(
        'You need recipients, a title, start/end times, and a timezone set.',
        undefined,
        5000,
      );
      return;
    }
    if (timeStart >= timeEnd) {
      addNotification('Meeting must start before it ends.', undefined, 5000);
      return;
    }
    onSendMeeting();
  }
  function addRecipient(
    recipientToAdd: MatchDetailsQuery['matchDetails']['brand']['team'][0],
  ) {
    trySaveDraft(
      null,
      null,
      null,
      recipients.concat(recipientToAdd),
      null,
      null,
      null,
    );
    setRecipientsOpen(false);
  }
  function changeStartTime(newStart: Date) {
    let newEnd: Date | null = null;
    if (newStart && timeStart && timeEnd && newStart !== timeStart) {
      const diff = newStart.getTime() - timeStart.getTime();
      newEnd = new Date(timeEnd.getTime() + diff);
    }
    trySaveDraft(null, null, null, null, newStart, newEnd, null);
  }
  function changeEndTime(newEnd: Date) {
    trySaveDraft(null, null, null, null, null, newEnd, null);
  }
  const timezones = useMemo(
    () =>
      moment.tz
        .names()
        .map((tz) => ({ offset: moment.tz(tz).utcOffset(), tz }))
        .sort((a, b) => a.offset - b.offset),
    [],
  );
  const availableToAdd = (team || []).filter(
    (h) => !recipients.find((r) => r.id === h.id),
  );
  return (
    <div className="ThreadEditor MeetingEditor">
      <div className="ThreadEditorTop">
        <div className="ThreadEditorTopStatus">{status}</div>
        <div className="ThreadEditorRow">
          <div className="ThreadEditorRowTitle">To</div>
          <div className="ThreadEditorRowBubbles">
            {recipients.map((h) => (
              <div
                key={h.id}
                className="ThreadEditorRowBubble"
                data-tip={h.primaryEmail || ''}
                onClick={() =>
                  trySaveDraft(
                    null,
                    null,
                    null,
                    recipients.filter((r) => r.id !== h.id),
                    null,
                    null,
                    null,
                  )
                }
              >
                <span className="ThreadEditorRowBubbleText">
                  {`${(h.firstName || '').trim()} ${(
                    h.lastName || ''
                  ).trim()}`.trim() ||
                    (h.primaryEmail || '').trim() ||
                    ''}
                </span>
                <div className="ThreadEditorRowBubbleRemove" />
              </div>
            ))}
            <div
              className={
                'ThreadEditorRowBubbleAdd ' +
                (recipientsOpen ? ' ThreadEditorRowBubbleAddCollapse ' : ' ')
              }
              onClick={() => setRecipientsOpen(!recipientsOpen)}
            >
              +
            </div>
          </div>
        </div>
      </div>
      <div className="ThreadEditorCardWrapper">
        <div className="ThreadEditorCard">
          <div className="ThreadEditorBody MeetingEditorBody">
            <div className="MeetingEditorField">
              <div className="MeetingEditorFieldTitle">Meeting Title</div>
              <input
                type="text"
                className="MeetingEditorFieldInput"
                placeholder="Meeting Title"
                spellCheck="true"
                value={title}
                onChange={(e) => {
                  trySaveDraft(
                    e.currentTarget.value,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                  );
                }}
              />
            </div>
            <div className="MeetingEditorField MeetingEditorFieldSplit MeetingEditorFieldSplitFirst">
              <div className="MeetingEditorFieldTitle">Start Time</div>
              <div className="MeetingEditorTimePicker">
                <DatePicker
                  onCalendarClose={() => onTimePickerOpenClose(false)}
                  onCalendarOpen={() => onTimePickerOpenClose(true)}
                  selected={timeStart}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  onChange={changeStartTime}
                  className="MeetingEditorFieldSchedule"
                  minDate={initDate}
                  placeholderText="Start Date"
                  timeIntervals={15}
                  withPortal
                />
              </div>
              {timezone !== moment.tz.guess() &&
                !!moment.tz.guess() &&
                !!timeStart &&
                !!timezone && (
                  <div className="MeetingEditorTimePickerExplain">
                    this is{' '}
                    {moment(timeStart)
                      .tz(timezone, true)
                      .tz(moment.tz.guess())
                      .format('h:mma z')}{' '}
                    your time
                  </div>
                )}
            </div>
            <div className="MeetingEditorField MeetingEditorFieldSplit">
              <div className="MeetingEditorFieldTitle">End Time</div>
              <div className="MeetingEditorTimePicker">
                <DatePicker
                  onCalendarClose={() => onTimePickerOpenClose(false)}
                  onCalendarOpen={() => onTimePickerOpenClose(true)}
                  selected={timeEnd}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  onChange={changeEndTime}
                  className="MeetingEditorFieldSchedule"
                  minDate={initDate}
                  placeholderText="End Date"
                  timeIntervals={15}
                  withPortal
                />
              </div>
            </div>
            <div className="MeetingEditorField">
              <div className="MeetingEditorFieldTitle">Timezone</div>
              <select
                className="MeetingEditorFieldInput MeetingEditorFieldTimezone"
                required
                value={timezone}
                onChange={(e) => {
                  trySaveDraft(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.currentTarget.value,
                  );
                }}
              >
                <option value="" disabled>
                  Timezone
                </option>
                {timezones.map((tzObj) => (
                  <option key={tzObj.tz} value={tzObj.tz}>
                    (GMT{moment.tz(tzObj.tz).format('Z')}) {tzObj.tz}
                  </option>
                ))}
              </select>
            </div>
            <div className="MeetingEditorField">
              <div className="MeetingEditorFieldTitle">
                Meeting Details / Location (optional)
              </div>
              <input
                type="text"
                className="MeetingEditorFieldInput"
                placeholder="Meeting Details / Location"
                spellCheck="true"
                value={location}
                onChange={(e) => {
                  trySaveDraft(
                    null,
                    e.currentTarget.value,
                    null,
                    null,
                    null,
                    null,
                    null,
                  );
                }}
              />
            </div>
            <div className="MeetingEditorField">
              <div className="MeetingEditorFieldTitle">
                Meeting Notes (optional)
              </div>
              <TextareaAutosize
                type="text"
                rows={3}
                placeholder="Meeting Notes"
                spellCheck="true"
                className="MeetingEditorFieldTextarea"
                value={description}
                onChange={(e) => {
                  trySaveDraft(
                    null,
                    null,
                    e.currentTarget.value,
                    null,
                    null,
                    null,
                    null,
                  );
                }}
              />
            </div>
          </div>
          {expertDetails &&
            (!expertDetails.nylasAccessToken ||
              !expertDetails.nylasCalendarId) && (
              <div className="ThreadEditorMiddle">
                Reminder to link{' '}
                <Link
                  to="/profile?calendar=setup"
                  className="ThreadEditorMiddleLink"
                >
                  your personal calendar
                </Link>{' '}
                to stay up to date with your Storetasker meetings.
              </div>
            )}
          <div className="ThreadEditorFooter">
            <div className="ThreadEditorActions">
              <div
                className="ThreadEditorActionBtn ThreadEditorActionSend"
                onClick={trySend}
              >
                {actionMeetingId ? 'Update' : 'Send'} Invite
              </div>
              {onDeleteDraft && (
                <div
                  className="ThreadEditorActionBtn ThreadEditorActionDelete"
                  onClick={() => onDeleteDraft && onDeleteDraft()}
                >
                  Delete
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!recipientsOpen && (
        <div className="ThreadEditorRecipientsPopoverWrapper">
          <div
            className="ThreadEditorRecipientsPopoverClose"
            onClick={() => setRecipientsOpen(false)}
          >
            cancel
          </div>
          <div className="ThreadEditorRecipientsPopover">
            <div className="ThreadEditorRecipientsOptions">
              {availableToAdd.map((h) => (
                <div
                  key={h.id}
                  className="ThreadEditorRecipientsOption"
                  onClick={() => addRecipient(h)}
                >
                  {`${(h.firstName || '').trim()} ${(
                    h.lastName || ''
                  ).trim()}`.trim() ||
                    (h.primaryEmail || '').trim() ||
                    ''}
                </div>
              ))}
            </div>
            <div className="ThreadEditorRecipientsInfo">
              Don&apos;t see who you&apos;re looking for? Update contact info{' '}
              <span onClick={() => goEditContacts && goEditContacts()}>
                here
              </span>
              .
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MeetingEditor.propTypes = {
  actionMeetingId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  expertDetails: PropTypes.object,
  goEditContacts: PropTypes.func,
  location: PropTypes.string.isRequired,
  onDeleteDraft: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  onSendMeeting: PropTypes.func.isRequired,
  onTimePickerOpenClose: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  team: PropTypes.array,
  timeEnd: PropTypes.instanceOf(Date),
  timeStart: PropTypes.instanceOf(Date),
  timezone: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

MeetingEditor.defaultProps = {
  expertDetails: null,
  goEditContacts: () => {},
  team: [],
};

export default MeetingEditor;
