import { useEffect, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  useMutation,
  useQuery,
  ApolloError,
  ServerError,
  gql,
} from '@apollo/client';
import { ClientWithOnReconnected } from './utils/apollo';
import { GlobalNotificationContext } from './components/context/GlobalNotification';
import AdminLogin from './components/page/AdminLogin';
import AdminDashboard from './components/page/AdminDashboard';
import oauthUtils from './utils/oauth';
import errorUtils from './utils/error';
import logError from './utils/airbrake';
import {
  AuthAdminQuery,
  HeartbeatAdminMutation,
  HeartbeatAdminMutationVariables,
} from './gql/graphql';

const authAdminQuery = gql`
  query AuthAdmin {
    auth {
      id
      token
      user {
        ... on Expert {
          id
          isSuperAdmin
          firstName
          lastName
          photo
        }
      }
    }
  }
`;

const heartbeatAdminMutation = gql`
  mutation HeartbeatAdmin($isActive: Boolean!) {
    userHeartbeat(isActive: $isActive)
  }
`;

interface AppAdminProps {
  socketClient: ClientWithOnReconnected;
}

let oneHeartbeatAtATime = false;

const AppAdmin = ({ socketClient }: AppAdminProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [refetched, setRefetched] = useState(false);
  const skipAuthCheck = !oauthUtils.getToken('admin');
  const {
    loading: loadingAdmin,
    error: errorAdmin,
    data: dataAdmin,
    refetch: refetchAdmin,
  } = useQuery<AuthAdminQuery>(authAdminQuery, {
    onError: (err) => {
      console.log(err, 'onError');
      // onError 401/403 erase token
      if (err.networkError && err.networkError.name === 'ServerError') {
        const networkError = err.networkError as ServerError;
        if (
          networkError.statusCode === 401 ||
          networkError.statusCode === 403
        ) {
          oauthUtils.logout('admin');
          return;
        }
      }
      if (err.networkError) {
        addNotification(
          'A network error occured, please refresh and try again: ' +
            (errorUtils.getErrorMessage(err) || 'Loading Error'),
          undefined,
          30000,
        );
      } else {
        addNotification(errorUtils.getErrorMessage(err) || 'Loading Error');
      }
    },
    skip: skipAuthCheck,
  });
  const refetchTop = useCallback(() => {
    setRefetched(false);
    refetchAdmin()
      .catch(() => {})
      .finally(() => {
        setRefetched(true);
      });
  }, [refetchAdmin]);
  const [sendHeartbeat] = useMutation<
    HeartbeatAdminMutation,
    HeartbeatAdminMutationVariables
  >(heartbeatAdminMutation);
  useEffect(() => {
    function onKeepAlive() {
      if (oauthUtils.getCachedUserId()) {
        if (!oneHeartbeatAtATime) {
          oneHeartbeatAtATime = true;
          sendHeartbeat({
            variables: {
              isActive: true,
            },
          })
            .catch((error: ApolloError) => {
              logError(error, {
                component: 'AppAdmin',
                func: 'heartbeatMutation',
              });
            })
            .finally(() => {
              oneHeartbeatAtATime = false;
            });
        }
      }
    }
    const keepAliveTimer = setInterval(onKeepAlive, 50000);
    return () => {
      clearInterval(keepAliveTimer);
    };
  }, [sendHeartbeat]);
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AppAdmin socketClient onReconnected');
      refetchTop();
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchTop]);
  if (refetched || errorAdmin) {
    // ignore these
  }
  if (loadingAdmin) {
    return <div className="AppLoadingFull">[storetasker]</div>;
  }
  if (
    !skipAuthCheck &&
    dataAdmin &&
    dataAdmin.auth &&
    dataAdmin.auth.token &&
    dataAdmin.auth.user &&
    dataAdmin.auth.user.__typename === 'Expert' &&
    dataAdmin.auth.user.id &&
    dataAdmin.auth.user.isSuperAdmin
  ) {
    oauthUtils.writeToken(
      dataAdmin.auth.token,
      'admin',
      dataAdmin.auth.user.id,
    );
    return (
      <AdminDashboard
        adminId={dataAdmin.auth.user.id}
        adminDetails={dataAdmin.auth.user}
        socketClient={socketClient}
      />
    );
  }
  return <AdminLogin />;
};

AppAdmin.propTypes = {
  socketClient: PropTypes.object.isRequired,
};

export default AppAdmin;
