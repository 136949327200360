import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import HumanUserBubble from '../feature/HumanUserBubble';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import {
  centsDollarsRounded,
  quoteEventStatus,
  formatNumberWithCommas,
} from '../../utils/format';
import { MatchThreadPaginatedQuery, QuoteStatus } from '../../gql/graphql';

interface QuoteThreadEventProps {
  className?: string;
  showProTip?: boolean;
  onReplyClick?: () => void;
  quote: Extract<
    MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
    { __typename?: 'Quote' | undefined }
  >;
  threadEventId: string;
  threadEventTimestamp: number;
}
const initTime = new Date().getTime();

const QuoteThreadEvent = ({
  className,
  quote,
  showProTip,
  threadEventTimestamp,
}: QuoteThreadEventProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const nowMoment = moment.tz(initTime, moment.tz.guess());
  const eventMoment = moment.tz(threadEventTimestamp, moment.tz.guess());
  // January 4th, 2020 at 5:37pm PST
  const eventDateFull =
    eventMoment.format('MMMM Do, YYYY') +
    ' at ' +
    eventMoment.format('h:mma z');
  let eventDateSummary = eventMoment.format('M/D/YY');
  if (nowMoment.isSame(eventMoment, 'day')) {
    eventDateSummary = eventMoment.format('h:mma');
  } else if (nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')) {
    eventDateSummary = 'Yesterday';
  } else if (nowMoment.isSame(eventMoment, 'week')) {
    eventDateSummary = eventMoment.format('dddd');
  } else if (nowMoment.isSame(eventMoment, 'year')) {
    eventDateSummary = eventMoment.format('MMM Do');
  }
  const isSolo =
    quote.ownerHuman &&
    (quote.ownerHuman.firstName || quote.ownerHuman.lastName);

  const [expanded, setExpanded] = useState(false);
  let estimatedCompletionStr = '';
  if (quote.estimatedCompletionDate) {
    const dueMoment = moment.utc(quote.estimatedCompletionDate).tz('UTC');
    if (nowMoment.isSame(dueMoment, 'day')) {
      estimatedCompletionStr = 'Today';
    } else if (dueMoment.valueOf() < nowMoment.valueOf()) {
      estimatedCompletionStr = dueMoment.format('MMMM Do');
    } else if (nowMoment.clone().add(1, 'day').isSame(dueMoment, 'day')) {
      estimatedCompletionStr = 'Tomorrow';
    } else if (nowMoment.isSame(dueMoment, 'week')) {
      estimatedCompletionStr = dueMoment.format('dddd');
    } else if (nowMoment.isSame(dueMoment, 'year')) {
      estimatedCompletionStr = dueMoment.format('MMMM Do');
    } else {
      estimatedCompletionStr = dueMoment.format('MMMM Do, YYYY');
    }
  } else if (quote.estimatedCompletionDays) {
    estimatedCompletionStr = `${quote.estimatedCompletionDays} business day${
      quote.estimatedCompletionDays > 1 ? 's' : ''
    } from quote accept`;
  }
  function getQuotePriceType(paymentType: string) {
    if (paymentType === 'MONTHLY_SUBSCRIPTION') return 'Monthly';
    if (paymentType === 'WEEKLY_SUBSCRIPTION') return 'Weekly';
    if (paymentType === 'BILL') return 'Bill';
    return 'Price';
  }
  return (
    <div
      className={
        'ThreadEvent QuoteThreadEvent ' +
        (quote.ownerHuman
          ? ' ThreadEventOwnerHuman '
          : ' ThreadEventOwnerExpert ') +
        (isSolo ? ' ThreadEventSolo ' : '') +
        (className || '')
      }
    >
      <div className="ThreadEventWrapper">
        <div className="ThreadEventTop">
          <div className="ThreadEventTopBubbles">
            {quote.ownerHuman && (
              <HumanUserBubble
                firstName={quote.ownerHuman && quote.ownerHuman.firstName}
                lastName={quote.ownerHuman && quote.ownerHuman.lastName}
                fallbackPhone={
                  quote.ownerHuman && quote.ownerHuman.primaryPhone
                }
                primary
              />
            )}
          </div>
          <div className="ThreadEventTopTitle">
            {quoteEventStatus(quote.status)}
          </div>
          <div className="ThreadEventTopDate" data-tip={eventDateFull}>
            {eventDateSummary}
          </div>
        </div>
        <div className="ThreadEventCardWrapper">
          <div className="ThreadEventCardLeft">
            {isSolo && quote.ownerHuman && (
              <div className="ThreadEventCardLeftUser">
                <HumanUserBubble
                  firstName={quote.ownerHuman && quote.ownerHuman.firstName}
                  lastName={quote.ownerHuman && quote.ownerHuman.lastName}
                  primary
                />
              </div>
            )}
            {quote.ownerExpert && (
              <div className="ThreadEventCardLeftDate" data-tip={eventDateFull}>
                {eventDateSummary}
              </div>
            )}
          </div>
          {quote.status === QuoteStatus.RevisionsRequested &&
            quote.revisionsRequested && (
              <div className="ThreadEventCard ThreadEventCardRequestRevisions">
                <div className="ThreadEventCardBody">
                  <ReadOnlyEditor
                    className="ThreadEventCardBodyContent"
                    content={quote.revisionsRequested}
                  />
                </div>
              </div>
            )}
          <div
            className={
              'ThreadEventCard ' + (expanded ? ' ThreadEventCardExpanded ' : '')
            }
          >
            <div className="ThreadEventCardBody">
              <div
                className="ThreadEventCardQuoteTop"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="ThreadEventCardQuoteTopLeft">
                  <div className="ThreadEventCardQuoteTopTitle">Title</div>
                  <div className="ThreadEventCardQuoteTopText">
                    {quote.title}
                  </div>
                </div>
                <div className="ThreadEventCardQuoteTopRight">
                  <div className="ThreadEventCardQuoteTopTitle">
                    {getQuotePriceType(quote.paymentType)}
                  </div>
                  <div className="ThreadEventCardQuoteTopText">
                    ${formatNumberWithCommas(centsDollarsRounded(quote.cents))}
                  </div>
                </div>
              </div>
              {!!expanded && (
                <div className="ThreadEventCardQuoteMain">
                  <div className="ThreadEventCardQuoteBody">
                    <div className="ThreadEventCardQuoteBodyTitle">
                      Description
                    </div>
                    <ReadOnlyEditor
                      className="ThreadEventCardQuoteBodyContent"
                      content={quote.description}
                    />
                  </div>
                  {quote.paymentType === 'PROJECT' && (
                    <div className="ThreadEventCardQuoteFooter">
                      <div className="ThreadEventCardQuoteFooterTitle">
                        {quote.estimatedCompletionDate
                          ? 'Deadline'
                          : 'Timeline'}
                      </div>
                      <div className="ThreadEventCardQuoteFooterText">
                        {estimatedCompletionStr}
                      </div>
                    </div>
                  )}
                  {!!quote.revisionsRequested && (
                    <div className="ThreadEventCardQuoteRevisions">
                      <div className="ThreadEventCardQuoteRevisionsTitle">
                        Revisions Requested
                      </div>
                      <ReadOnlyEditor
                        className="ThreadEventCardQuoteRevisionsContent"
                        content={quote.revisionsRequested}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="ThreadEventCardRight">
            {!quote.ownerExpert && (
              <div
                className="ThreadEventCardRightDate"
                data-tip={eventDateFull}
              >
                {eventDateSummary}
              </div>
            )}
            {quote.ownerExpert && (
              <div className="ThreadEventCardRightUser">
                <ExpertUserBubble
                  expertId={quote.ownerExpert.id}
                  expertDetails={quote.ownerExpert}
                  primary
                />
              </div>
            )}
          </div>
        </div>
        <div className="ThreadEventBottom" />
      </div>
      {!!showProTip &&
        (quote.status === QuoteStatus.BillPaid ||
          quote.status === QuoteStatus.Approved) && (
          <div className="ThreadEventWrapper">
            <div className="ThreadEventCardWrapper">
              <div className="ThreadEventCard ThreadEventCardTip">
                <div className="ThreadEventCardBody">
                  <div className="ThreadActionQuoteTipTitle">
                    Ready for a retainer?
                  </div>
                  <div className="ThreadActionQuoteTipBody">
                    Now that you’ve done two project together, consider
                    suggesting a retainer. Clients on retainer often have 4X the
                    lifetime value as project to project.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

QuoteThreadEvent.propTypes = {
  className: PropTypes.string,
  onReplyClick: PropTypes.func,
  quote: PropTypes.object.isRequired,
  showProTip: PropTypes.bool,
  threadEventId: PropTypes.string.isRequired,
  threadEventTimestamp: PropTypes.number.isRequired,
};

QuoteThreadEvent.defaultProps = {
  className: '',
  onReplyClick: null,
  showProTip: false,
};

export default QuoteThreadEvent;
