const defaultTitle = 'Storetasker: Expert Dashboard';
let shouldPlaySounds = 'NONE';

const receive = createAudioSource(
  'lorem-audio-receive',
  'https://cdn.asklorem.com/assets/sound/expertreceive.mp3',
);
const newjob = createAudioSource(
  'lorem-audio-newjob',
  'https://cdn.asklorem.com/assets/sound/newjob.mp3',
);

function createAudioSource(id: string, src: string) {
  const audio = document.createElement('audio');
  audio.id = id;
  audio.src = src;
  document.body.appendChild(audio);
  return audio;
}

function getAudioSource(sound: string) {
  switch (sound) {
    case 'receive': {
      return receive;
    }
    case 'newjob': {
      return newjob;
    }
    default: {
      console.log('Invalid sound specified', sound);
      return receive;
    }
  }
}

/**
 *  Sound controller for Customer or Expert alerts
 */
let lastPlayedAudio = { ended: true };
function playSound(sound: string) {
  const audio = getAudioSource(sound);
  if (lastPlayedAudio.ended) {
    try {
      const playPromise = audio.play();
      if (playPromise.catch) {
        playPromise.catch(() => {}); // user hasn't interacted with page yet, so NotAllowedError. swallow it.
      }
    } catch {
      // ignore
    }
    lastPlayedAudio = audio;
  }
}

/**
 *  Used to alert Customers or Experts of a new message
 */
let titleAlertInterval: NodeJS.Timeout | undefined;

const clearTitleAlert = () => {
  if (titleAlertInterval) {
    clearInterval(titleAlertInterval);
  }
  document.title = defaultTitle;
};

const showMessageInTitle = (msg: string) => {
  document.title = msg;
  if (titleAlertInterval) {
    clearInterval(titleAlertInterval);
  }
  titleAlertInterval = setInterval(() => {
    document.title = document.title === defaultTitle ? msg : defaultTitle;
  }, 2000);
  setTimeout(clearTitleAlert, 8000);
};

const jobsAlerted = new Map<string, boolean>();
function alertNewJob(id: string) {
  if (!jobsAlerted.get(id)) {
    jobsAlerted.set(id, true);
    if (shouldPlaySounds === 'QUEUE' || shouldPlaySounds === 'ALL') {
      playSound('newjob');
      showMessageInTitle('New Project Available!');
    }
  }
}

const thingsAlerted = new Map<string, boolean>();
function alertUnread(id: string, unreadMessage: string) {
  if (!thingsAlerted.get(id)) {
    thingsAlerted.set(id, true);
    if (shouldPlaySounds === 'UNREAD' || shouldPlaySounds === 'ALL') {
      playSound('receive');
      showMessageInTitle((unreadMessage || 'Unread Alert') + '!');
    }
  }
}

function removeUnread(id: string) {
  clearTitleAlert();
  thingsAlerted.set(id, false);
}
function setPlaySounds(doPlaySounds: string) {
  console.log('setPlaySounds', doPlaySounds);
  shouldPlaySounds = doPlaySounds;
}

export default {
  alertNewJob,
  alertUnread,
  playSound,
  removeUnread,
  setPlaySounds,
};
