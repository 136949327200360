import { useState, useContext, Fragment, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useQuery, useMutation, ApolloError, gql } from '@apollo/client';
import { NavLink, Route, Link, useRouteMatch } from 'react-router-dom';
import { ClientWithOnReconnected } from '../../utils/apollo';
import '../../styles/page/ExpertInbound.scss';
import ExpertInboundDetail from './ExpertInboundDetail';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import alertUtils from '../../utils/alert';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { formatValidPhoneNumber } from '../../utils/format';
import {
  TemplatesQuery,
  OrphanUnreadForExpertFullQuery,
  OrphanPaginateReadForExpertQuery,
  OrphanPaginateReadForExpertQueryVariables,
  OrphanDetailsQuery,
  OrphanMarkAsReadUnreadMutation,
  OrphanMarkAsReadUnreadMutationVariables,
  ExpertDetailsQuery,
} from '../../gql/graphql';
import { OrphanSummary } from '../../utils/gql';
import logo from '../../images/storetasker-logo-black.png';

const orphanUnreadForExpertQuery = gql`
  query OrphanUnreadForExpertFull {
    orphanUnreadForExpert {
      ...OrphanSummary
    }
  }
  ${OrphanSummary}
`;

const orphanPaginateReadForExpertQuery = gql`
  query OrphanPaginateReadForExpert(
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    orphanPaginateReadForExpert(
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...OrphanSummary
    }
  }
  ${OrphanSummary}
`;

const orphanMarkAsReadUnreadMutation = gql`
  mutation OrphanMarkAsReadUnread($orphanId: ID!, $unreadReason: String) {
    orphanMarkAsReadUnread(orphanId: $orphanId, unreadReason: $unreadReason) {
      id
      expertUnread
    }
  }
`;

interface ExpertInboundProps {
  emailTemplates: TemplatesQuery['templates'];
  expertDetails?: ExpertDetailsQuery['expertDetails'];
  expertId: string;
  socketClient: ClientWithOnReconnected;
}

const PAGE_LIMIT = 10;
const DESC_SUMMARY_LENGTH = 80;

const ExpertInbound = ({
  emailTemplates,
  expertId,
  expertDetails,
  socketClient,
}: ExpertInboundProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [initDate] = useState(() => new Date());
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [childLoadData, setChildLoadData] = useState(
    [] as OrphanDetailsQuery['orphanDetails'][],
  );
  const {
    data: dataOrphanUnreads,
    error: errorOrphanUnreads,
    loading: loadingOrphanUnreads,
    refetch: refetchOrphanUnreads,
  } = useQuery<OrphanUnreadForExpertFullQuery>(orphanUnreadForExpertQuery, {
    returnPartialData: true,
  });
  const {
    data: dataOrphanPaginated,
    error: errorOrphanPaginated,
    loading: loadingOrphanPaginated,
    fetchMore: fetchMoreOrphanPaginated,
  } = useQuery<
    OrphanPaginateReadForExpertQuery,
    OrphanPaginateReadForExpertQueryVariables
  >(orphanPaginateReadForExpertQuery, {
    variables: {
      direction: 'BACKWARD',
      fromDate: initDate.getTime(),
      limit: PAGE_LIMIT,
    },
  });
  const allOrphansForExpert:
    | OrphanUnreadForExpertFullQuery['orphanUnreadForExpert']
    | OrphanPaginateReadForExpertQuery['orphanPaginateReadForExpert'] =
    _.uniqBy(
      [
        ...((dataOrphanUnreads && dataOrphanUnreads.orphanUnreadForExpert) ||
          []),
        ...((dataOrphanPaginated &&
          dataOrphanPaginated.orphanPaginateReadForExpert) ||
          []),
      ].filter((o) => !o.migratedToMatchStr && o.expertStr === expertId),
      'id',
    ).sort((a, b) => b.lastUpdated - a.lastUpdated);
  const allRead = allOrphansForExpert.filter((o) => !o.expertUnread);
  const oldestLastUpdated = allRead[allRead.length - 1];
  function loadMore() {
    fetchMoreOrphanPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.orphanPaginateReadForExpert)
          return prev;
        if (fetchMoreResult.orphanPaginateReadForExpert.length < PAGE_LIMIT) {
          setShowLoadMore(false);
        }
        return {
          orphanPaginateReadForExpert: (
            prev.orphanPaginateReadForExpert || []
          ).concat(fetchMoreResult.orphanPaginateReadForExpert),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate: oldestLastUpdated.lastUpdated,
        limit: PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'ExpertInbound',
        func: 'loadMore',
      });
    });
  }
  useEffect(() => {
    const loadTime = new Date();
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('ExpertInbound socketClient onReconnected');
      refetchOrphanUnreads().catch(() => {});
      fetchMoreOrphanPaginated({
        updateQuery: (prev, { fetchMoreResult }) => {
          console.log('fetchMoreResult', fetchMoreResult);
          if (!fetchMoreResult || !fetchMoreResult.orphanPaginateReadForExpert)
            return prev;
          return {
            orphanPaginateReadForExpert: (
              prev.orphanPaginateReadForExpert || []
            ).concat(fetchMoreResult.orphanPaginateReadForExpert),
          };
        },
        variables: {
          direction: 'FORWARD',
          fromDate: loadTime.getTime(),
          limit: 25,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'ExpertInbound',
          func: 'reconnect loadMore',
        });
      });
    });
    return () => {
      reconnectedListener();
    };
  }, [
    socketClient,
    refetchOrphanUnreads,
    fetchMoreOrphanPaginated,
    addNotification,
  ]);
  const [tryMarkReadUnread] = useMutation<
    OrphanMarkAsReadUnreadMutation,
    OrphanMarkAsReadUnreadMutationVariables
  >(orphanMarkAsReadUnreadMutation);
  console.log('childLoadData', childLoadData);
  const withExtra:
    | OrphanUnreadForExpertFullQuery['orphanUnreadForExpert']
    | OrphanPaginateReadForExpertQuery['orphanPaginateReadForExpert']
    | OrphanDetailsQuery['orphanDetails'][] = _.uniqBy(
    [...allOrphansForExpert, ...childLoadData].filter(
      (o) =>
        !o.migratedToMatchStr &&
        !o.isArchivedByExpert &&
        o.expertStr === expertId,
    ),
    'id',
  ).sort((a, b) => b.lastUpdated - a.lastUpdated);
  const withExtraUnread = withExtra.filter((o) => o.expertUnread);
  const withExtraRead = withExtra.filter((o) => !o.expertUnread);
  const withExtraForExpert = withExtraUnread.concat(withExtraRead);
  function onLoadChild(dataChild: OrphanDetailsQuery['orphanDetails']) {
    console.log('onLoadChild', dataChild);
    setChildLoadData(_.uniqBy([dataChild].concat(childLoadData), 'id'));
    if (dataChild.id && dataChild.expertUnread) {
      alertUtils.removeUnread(dataChild.id);
      tryMarkReadUnread({
        optimisticResponse: {
          orphanMarkAsReadUnread: {
            __typename: 'Orphan',
            expertUnread: null,
            id: dataChild.id,
          },
        },
        variables: {
          orphanId: dataChild.id,
        },
      }).catch((err: ApolloError) => {
        // purposely ignore mark read error
        logError(err, {
          component: 'ExpertInbound',
          func: 'tryMarkReadUnread',
        });
      });
    }
  }
  if (loadingOrphanUnreads || loadingOrphanPaginated) {
    // ignore these
  }
  const routeMatch = useRouteMatch('/inbound/:id');
  return (
    <div
      className={
        'DashboardScreen InboundScreen ' +
        (routeMatch ? ' InboundScreenWithDetail ' : '')
      }
    >
      <Link className="DashboardScreenLogoLink" to="/home">
        <img
          className="DashboardScreenLogo"
          src={logo}
          alt="Storetasker Logo"
        />
      </Link>
      <div className="DashboardScreenContent InboundScreenContent">
        <div className="InboundListColumn">
          <div className="InboundListContainer">
            {!withExtraForExpert.length && (
              <div className="InboundListContainerEmpty">
                {errorOrphanUnreads || errorOrphanPaginated ? (
                  <Fragment>
                    <div className="InboundListContainerEmptyHeader">
                      Loading Error
                    </div>
                    <div className="InboundListContainerEmptySub">
                      {errorUtils.getErrorMessage(
                        errorOrphanUnreads || errorOrphanPaginated!,
                      )}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="InboundListContainerEmptyHeader">
                      Inbound is empty
                    </div>
                    <div className="InboundListContainerEmptySub">
                      New emails and texts that we don&apos;t recognize will
                      show up here.
                    </div>
                    <a
                      href="https://kb.storetasker.com/Working-in-the-Inbound-Tab-9900d21d650a41bd994e899a51314efe"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="InboundListContainerEmptyLink"
                    >
                      Learn More
                    </a>
                  </Fragment>
                )}
              </div>
            )}
            {withExtraForExpert.map((o) => {
              let desc = 'No description found';
              if (o.lastThreadEvent && o.lastThreadEvent.plainTextContent) {
                desc =
                  o.lastThreadEvent.plainTextContent.slice(
                    0,
                    DESC_SUMMARY_LENGTH,
                  ) +
                  (o.lastThreadEvent.plainTextContent.length >=
                  DESC_SUMMARY_LENGTH
                    ? '...'
                    : '');
              }
              const humanListStr =
                (o.humans || [])
                  .slice(0, 3)
                  .map(
                    (h) =>
                      (
                        (h.firstName || '').trim() +
                        ' ' +
                        (h.lastName || '').trim()
                      ).trim() ||
                      h.primaryEmail ||
                      formatValidPhoneNumber(h.primaryPhone) ||
                      h.primaryPhone ||
                      '',
                  )
                  .join(', ') + ((o.humans || []).length >= 3 ? '...' : '');
              return (
                <NavLink
                  key={o.id}
                  to={'/inbound/' + o.id}
                  className="InboundListItem"
                >
                  <div className="InboundListItemTitle">{humanListStr}</div>
                  {o.lastThreadEvent && o.lastThreadEvent.plainTextSubject && (
                    <div className="InboundListItemSubtitle">
                      {o.lastThreadEvent.plainTextSubject}
                    </div>
                  )}
                  <div className="InboundListItemDesc">{desc}</div>
                  {!!o.expertUnread && (
                    <div className="InboundListItemUnread" />
                  )}
                </NavLink>
              );
            })}
            {withExtraForExpert.length >= PAGE_LIMIT &&
              oldestLastUpdated &&
              showLoadMore && (
                <div className="InboundListItemLoader" onClick={loadMore}>
                  load more
                </div>
              )}
          </div>
        </div>
        <div className="InboundDetailColumn">
          <Link className="InboundDetailColumnCover" to="/inbound" />
          <div className="InboundDetailColumnMain">
            <div className="InboundDetailColumnHeader">
              <Link className="InboundDetailColumnHeaderBack" to="/inbound">
                Inbound
              </Link>
            </div>
            <div className="InboundDetailColumnBody">
              <Route
                path="/inbound/:id"
                render={(routeProps) => (
                  <ExpertInboundDetail
                    emailTemplates={emailTemplates}
                    expertDetails={expertDetails}
                    expertId={expertId}
                    onLoadData={onLoadChild}
                    orphanId={routeProps.match.params.id}
                    socketClient={socketClient}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ExpertInbound.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  expertDetails: PropTypes.object,
  expertId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

ExpertInbound.defaultProps = {
  expertDetails: null,
};

export default ExpertInbound;
