import { useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import '../../styles/threadEvent/QuoteAttachedThreadEvent.scss';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import {
  formatNumberWithCommas,
  centsDollarsRounded,
  quoteStatusExpertAction,
} from '../../utils/format';
import { MatchThreadPaginatedQuery } from '../../gql/graphql';

interface QuoteAttachedThreadEventProps {
  quote: Exclude<
    Extract<
      MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
      { __typename?: 'EmailMessageEvent' | undefined }
    >['quote'],
    null | undefined
  >;
}
const initTime = new Date().getTime();

const QuoteAttachedThreadEvent = ({ quote }: QuoteAttachedThreadEventProps) => {
  const [expanded, setExpanded] = useState(false);
  let estimatedCompletionStr = '';
  if (quote.estimatedCompletionDate) {
    const nowMoment = moment.tz(initTime, moment.tz.guess());
    const dueMoment = moment.utc(quote.estimatedCompletionDate).tz('UTC');
    if (nowMoment.isSame(dueMoment, 'day')) {
      estimatedCompletionStr = 'Today';
    } else if (dueMoment.valueOf() < nowMoment.valueOf()) {
      estimatedCompletionStr = dueMoment.format('MMMM Do');
    } else if (nowMoment.clone().add(1, 'day').isSame(dueMoment, 'day')) {
      estimatedCompletionStr = 'Tomorrow';
    } else if (nowMoment.isSame(dueMoment, 'week')) {
      estimatedCompletionStr = dueMoment.format('dddd');
    } else if (nowMoment.isSame(dueMoment, 'year')) {
      estimatedCompletionStr = dueMoment.format('MMMM Do');
    } else {
      estimatedCompletionStr = dueMoment.format('MMMM Do, YYYY');
    }
  } else if (quote.estimatedCompletionDays) {
    estimatedCompletionStr = `${quote.estimatedCompletionDays} business day${
      quote.estimatedCompletionDays > 1 ? 's' : ''
    } from quote accept`;
  }
  function getQuotePriceType(paymentType: string) {
    if (paymentType === 'MONTHLY_SUBSCRIPTION') return 'Monthly';
    if (paymentType === 'WEEKLY_SUBSCRIPTION') return 'Weekly';
    if (paymentType === 'BILL') return 'Bill';
    return 'Price';
  }
  return (
    <div className="QuoteAttachedThreadEventWrapper">
      <div
        className={
          'QuoteAttachedThreadEvent ' +
          (expanded ? ' QuoteAttachedThreadEventExpanded ' : '')
        }
      >
        <div
          className="QuoteAttachedThreadEventTop"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="QuoteAttachedThreadEventTopLeft">
            <div className="QuoteAttachedThreadEventTopTitle">
              {quoteStatusExpertAction(quote.status)}
            </div>
            <div className="QuoteAttachedThreadEventTopText">{quote.title}</div>
          </div>
          <div className="QuoteAttachedThreadEventTopRight">
            <div className="QuoteAttachedThreadEventTopTitle">
              {getQuotePriceType(quote.paymentType)}
            </div>
            <div className="QuoteAttachedThreadEventTopText">
              ${formatNumberWithCommas(centsDollarsRounded(quote.cents))}
            </div>
          </div>
        </div>
        {!!expanded && (
          <div className="QuoteAttachedThreadEventMain">
            <div className="QuoteAttachedThreadEventBody">
              <div className="QuoteAttachedThreadEventRevisionsTitle">
                Description
              </div>
              <ReadOnlyEditor
                className="QuoteAttachedThreadEventBodyContent"
                content={quote.description}
              />
            </div>
            {quote.paymentType === 'PROJECT' && (
              <div className="QuoteAttachedThreadEventFooter">
                <div className="QuoteAttachedThreadEventFooterTitle">
                  {quote.estimatedCompletionDate ? 'Deadline' : 'Timeline'}
                </div>
                <div className="QuoteAttachedThreadEventFooterText">
                  {estimatedCompletionStr}
                </div>
              </div>
            )}
            {!!quote.revisionsRequested && (
              <div className="QuoteAttachedThreadEventRevisions">
                <div className="QuoteAttachedThreadEventRevisionsTitle">
                  Revisions Requested
                </div>
                <ReadOnlyEditor
                  className="QuoteAttachedThreadEventRevisionsContent"
                  content={quote.revisionsRequested}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

QuoteAttachedThreadEvent.propTypes = {
  quote: PropTypes.object.isRequired,
};

export default QuoteAttachedThreadEvent;
