import React, { createContext, Fragment, ReactNode } from 'react';
import PropTypes from 'prop-types';

export const ADMIN = 'admin';
export const HUMAN = 'human';
export const EXPERT = 'expert';
export const PARTNER = 'partner';

export const WebAppContext = createContext(
  HUMAN /* default, should explicitly set this at app root */,
);

export const WebAppProvider = WebAppContext.Provider;
export const WebAppConsumer = WebAppContext.Consumer;

interface IChildrenOrElseOptions {
  children: ReactNode | ReactNode[];
  elseShow: ReactNode | ReactNode[];
}

const renderIfContextMatchesValues = (
  Consumer: React.Consumer<string>,
  ...values: string[]
) => {
  const Component = (options: IChildrenOrElseOptions) => (
    <Consumer>
      {(value: string) =>
        (values || []).includes(value) ? (
          <Fragment>{options.children}</Fragment>
        ) : (
          options.elseShow && <Fragment>{options.elseShow}</Fragment>
        )
      }
    </Consumer>
  );
  Component.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    elseShow: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };
  return Component;
};

export const IfWebAppAdmin = renderIfContextMatchesValues(
  WebAppContext.Consumer,
  ADMIN,
);
export const IfWebAppHuman = renderIfContextMatchesValues(
  WebAppContext.Consumer,
  HUMAN,
);
export const IfWebAppExpert = renderIfContextMatchesValues(
  WebAppContext.Consumer,
  EXPERT,
);
export const IfWebAppPartner = renderIfContextMatchesValues(
  WebAppContext.Consumer,
  PARTNER,
);

interface IChildrenOrElseOptionsForApps extends IChildrenOrElseOptions {
  apps: string[];
}

export const IfOneOfWebApps = (appsOptions: IChildrenOrElseOptionsForApps) => {
  const Component = renderIfContextMatchesValues(
    WebAppContext.Consumer,
    ...appsOptions.apps,
  );
  return (
    <Component elseShow={appsOptions.elseShow}>
      {appsOptions.children}
    </Component>
  );
};

IfOneOfWebApps.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  elseShow: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
