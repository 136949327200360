import PropTypes from 'prop-types';
import '../../styles/feature/PhoneCallEditor.scss';
import { formatValidPhoneNumber } from '../../utils/format';
import { MatchDetailsQuery } from '../../gql/graphql';

interface IFixedRecipient {
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  primaryEmail?: string | null;
  primaryPhone?: string | null;
}
interface PhoneCallEditorProps {
  fixedRecipients?: IFixedRecipient[];
  goEditContacts?: () => void;
  onCancel: () => void;
  onMakeCall: (
    human?: MatchDetailsQuery['matchDetails']['brand']['team'][0],
  ) => void;
  team?: MatchDetailsQuery['matchDetails']['brand']['team'];
}

const PhoneCallEditor = ({
  fixedRecipients,
  goEditContacts,
  onCancel,
  onMakeCall,
  team,
}: PhoneCallEditorProps) => (
  <div className="ThreadEditor PhoneCallEditor">
    <div className="ThreadEditorTop">
      <div className="ThreadEditorTopTitle">Phone Call</div>
      <div className="ThreadEditorTopAction" onClick={onCancel}>
        Cancel
      </div>
    </div>
    <div className="ThreadEditorCardWrapper">
      <div className="ThreadEditorCard">
        <div
          className={
            'ThreadEditorPhoneCall ' +
            (!(team || []).length ? 'ThreadEditorPhoneCallEmpty' : '')
          }
        >
          <div className="ThreadEditorPhoneCallOptions">
            {(fixedRecipients || []).map((f) => (
              <div
                key={f.id}
                className="ThreadEditorPhoneCallOption"
                onClick={() => onMakeCall()}
              >
                {`${(f.firstName || '').trim()} ${(
                  f.lastName || ''
                ).trim()}`.trim() ||
                  formatValidPhoneNumber(f.primaryPhone) ||
                  f.primaryPhone ||
                  ''}
              </div>
            ))}
            {(team || []).map((h) => (
              <div
                key={h.id}
                className="ThreadEditorPhoneCallOption"
                onClick={() => onMakeCall(h)}
              >
                {`${(h.firstName || '').trim()} ${(
                  h.lastName || ''
                ).trim()}`.trim() ||
                  (
                    formatValidPhoneNumber(h.primaryPhone) ||
                    h.primaryPhone ||
                    ''
                  ).trim() ||
                  ''}
              </div>
            ))}
          </div>
          {!!goEditContacts && (
            <div className="ThreadEditorPhoneCallInfo">
              Don&apos;t see who you&apos;re looking for? Update contact info{' '}
              <span onClick={goEditContacts}>here</span>.
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

PhoneCallEditor.propTypes = {
  goEditContacts: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onMakeCall: PropTypes.func.isRequired,
  team: PropTypes.array,
};

PhoneCallEditor.defaultProps = {
  goEditContacts: null,
  team: [],
};

export default PhoneCallEditor;
