import { useEffect, useState, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useMutation,
  useQuery,
  ApolloError,
  ServerError,
  gql,
} from '@apollo/client';
import { ClientWithOnReconnected } from './utils/apollo';
import { GlobalNotificationContext } from './components/context/GlobalNotification';
import HumanDashboard from './components/page/HumanDashboard';
import oauthUtils from './utils/oauth';
import errorUtils from './utils/error';
import marketingUtils from './utils/marketing';
import logError from './utils/airbrake';
import { HumanDetail } from './utils/gql';
import envUtils from './utils/env';
import {
  AuthHumanQuery,
  HeartbeatHumanMutation,
  HeartbeatHumanMutationVariables,
} from './gql/graphql';

const authHumanQuery = gql`
  query AuthHuman {
    auth {
      id
      token
      user {
        ... on Human {
          ...HumanDetail
        }
      }
    }
  }
  ${HumanDetail}
`;

const heartbeatHumanMutation = gql`
  mutation HeartbeatHuman($isActive: Boolean!) {
    userHeartbeat(isActive: $isActive)
  }
`;

interface AppHumanProps {
  socketClient: ClientWithOnReconnected;
}

let oneHeartbeatAtATime = false;

const AppHuman = ({ socketClient }: AppHumanProps) => {
  const location = useLocation();
  useEffect(() => {
    marketingUtils.recordGtagPageview(location.pathname);
  }, [location]);
  const { addNotification } = useContext(GlobalNotificationContext);
  const [refetched, setRefetched] = useState(false);
  const [leadDiscountCode, setLeadDiscountCode] = useState('');
  const skipAuthCheck = !oauthUtils.getToken('customer');
  const {
    loading: loadingHuman,
    error: errorHuman,
    data: dataHuman,
    refetch: refetchHuman,
  } = useQuery<AuthHumanQuery>(authHumanQuery, {
    onCompleted: (completedData) => {
      if (
        completedData &&
        completedData.auth &&
        completedData.auth.user &&
        completedData.auth.user.__typename === 'Human'
      ) {
        marketingUtils.setHumanLeadId(completedData.auth.user.leadStr || '');
        marketingUtils.isLoggedIn(
          completedData.auth.user.id,
          completedData.auth.user.discountCode || '',
          (dc: string) => {
            setLeadDiscountCode(dc);
          },
        );
      }
    },
    onError: (err) => {
      console.log(err, 'onError');
      // onError 401/403 erase token
      if (err.networkError && err.networkError.name === 'ServerError') {
        const networkError = err.networkError as ServerError;
        if (
          networkError.statusCode === 401 ||
          networkError.statusCode === 403
        ) {
          oauthUtils.logout('customer');
          return;
        }
      }
      if (err.networkError) {
        addNotification(
          'A network error occured, please refresh and try again: ' +
            (errorUtils.getErrorMessage(err) || 'Loading Error'),
          undefined,
          300000,
        );
      } else {
        addNotification(errorUtils.getErrorMessage(err) || 'Loading Error');
      }
    },
    skip: skipAuthCheck,
  });
  const refetchTop = useCallback(() => {
    setRefetched(false);
    refetchHuman()
      .then((refetchedData) => {
        if (
          refetchedData &&
          refetchedData.data &&
          refetchedData.data.auth &&
          refetchedData.data.auth.user &&
          refetchedData.data.auth.user.__typename === 'Human'
        ) {
          marketingUtils.setHumanLeadId(
            refetchedData.data.auth.user.leadStr || '',
          );
        }
      })
      .catch(() => {})
      .finally(() => {
        setRefetched(true);
      });
  }, [refetchHuman]);
  const [sendHeartbeat] = useMutation<
    HeartbeatHumanMutation,
    HeartbeatHumanMutationVariables
  >(heartbeatHumanMutation);
  useEffect(() => {
    marketingUtils.startLead((dc: string) => {
      setLeadDiscountCode(dc);
    });
  }, [setLeadDiscountCode]);
  useEffect(() => {
    function onKeepAlive() {
      if (oauthUtils.getCachedUserId()) {
        if (!oneHeartbeatAtATime) {
          oneHeartbeatAtATime = true;
          sendHeartbeat({
            variables: {
              isActive: true,
            },
          })
            .catch((error: ApolloError) => {
              logError(error, {
                component: 'AppHuman',
                func: 'heartbeatMutation',
              });
            })
            .finally(() => {
              oneHeartbeatAtATime = false;
            });
        }
      }
    }
    const keepAliveTimer = setInterval(onKeepAlive, 50000);
    return () => {
      clearInterval(keepAliveTimer);
    };
  }, [sendHeartbeat]);
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AppHuman socketClient onReconnected');
      refetchTop();
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchTop]);
  function chooseNextRedirect() {
    const baseRedirect = envUtils.pickWebAppUrlForUserType('web');
    const { pathname } = window.location;
    const pathSplit = pathname.split('/');
    let nextQuery = '';
    if (pathSplit[1] === 'projects' && pathSplit[2]) {
      nextQuery = '?project=' + pathSplit[2];
    } else if (pathSplit[1] === 'new') {
      nextQuery = '?new=true';
    } else {
      nextQuery = '?login=true&next=' + window.location.pathname;
    }
    return baseRedirect + nextQuery;
  }
  if (refetched || errorHuman) {
    // ignore these
  }
  if (loadingHuman) {
    return <div className="AppLoadingFull">[storetasker]</div>;
  }
  if (
    !skipAuthCheck &&
    dataHuman &&
    dataHuman.auth &&
    dataHuman.auth.token &&
    dataHuman.auth.user &&
    dataHuman.auth.user.__typename === 'Human' &&
    dataHuman.auth.user.id &&
    !dataHuman.auth.user.migratedToHumanStr
  ) {
    oauthUtils.writeToken(
      dataHuman.auth.token,
      'customer',
      dataHuman.auth.user.id,
    );
    return (
      <HumanDashboard
        human={dataHuman.auth.user}
        leadDiscountCode={leadDiscountCode || ''}
        socketClient={socketClient}
      />
    );
  }
  if (!errorHuman) {
    window.location.href = chooseNextRedirect();
  }
  return <div className="AppLoadingFull">[storetasker]</div>;
};

AppHuman.propTypes = {
  socketClient: PropTypes.object.isRequired,
};

export default AppHuman;
