import React, { Fragment, useState, useContext, useRef } from 'react';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import '../../styles/feature/OrphanClientEditor.scss';
import { MatchDetail, BrandDetail } from '../../utils/gql';
import {
  OrphanDetailsQuery,
  BrandForHumansOrphanQuery,
  BrandForHumansOrphanQueryVariables,
  MatchSearchForExpertOrphanMutation,
  MatchSearchForExpertOrphanMutationVariables,
  BrandCreatedByExpertMutation,
  BrandCreatedByExpertMutationVariables,
} from '../../gql/graphql';

const brandForHumansOrphanQuery = gql`
  query BrandForHumansOrphan($humanIds: [ID!]!) {
    brandForHumans(humanIds: $humanIds) {
      ...BrandDetail
    }
  }
  ${BrandDetail}
`;

const matchSearchForExpertOrphanMutation = gql`
  mutation MatchSearchForExpertOrphan($searchQuery: String!) {
    matchSearchForExpert(searchQuery: $searchQuery) {
      id
      brandNameMatch
      humanNameMatch {
        id
        firstName
        lastName
      }
      match {
        ...MatchDetail
      }
      shopifyAdminMatch
      urlMatch
    }
  }
  ${MatchDetail}
`;
const brandCreatedByExpertMutation = gql`
  mutation BrandCreatedByExpert(
    $name: String!
    $url: String!
    $humanIds: [ID!]!
  ) {
    brandCreatedByExpert(name: $name, url: $url, humanIds: $humanIds) {
      ...BrandDetail
    }
  }
  ${BrandDetail}
`;

interface OrphanClientEditorProps {
  humans: OrphanDetailsQuery['orphanDetails']['humans'];
  isActionLoading: boolean;
  onCancel: () => void;
  onSelectBrand: (brandId: string) => void;
}

const OrphanClientEditor = ({
  humans,
  isActionLoading,
  onCancel,
  onSelectBrand,
}: OrphanClientEditorProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [createLoading, setCreateLoading] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const [brandNameInput, setBrandNameInput] = useState('');
  const [brandUrlInput, setBrandUrlInput] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    MatchSearchForExpertOrphanMutation['matchSearchForExpert'] | null
  >(null);
  const {
    data: dataHumanBrands,
    error: errorHumanBrands,
    loading: loadingHumanBrands,
  } = useQuery<BrandForHumansOrphanQuery, BrandForHumansOrphanQueryVariables>(
    brandForHumansOrphanQuery,
    {
      returnPartialData: true,
      variables: {
        humanIds: humans.map((h) => h.id),
      },
    },
  );
  const [tryBrandCreate] = useMutation<
    BrandCreatedByExpertMutation,
    BrandCreatedByExpertMutationVariables
  >(brandCreatedByExpertMutation);
  function onCreate() {
    if (createLoading) return;
    if (!brandNameInput || !brandUrlInput) {
      addNotification('Please submit a brand name and url.', undefined, 5000);
      return;
    }
    setCreateLoading(true);
    tryBrandCreate({
      variables: {
        humanIds: humans.map((h) => h.id),
        name: brandNameInput,
        url: brandUrlInput,
      },
    })
      .then(({ data: mutationData }) => {
        setCreateLoading(false);
        if (mutationData && mutationData.brandCreatedByExpert) {
          onSelectBrand(mutationData.brandCreatedByExpert.id);
        }
      })
      .catch((err: ApolloError) => {
        setCreateLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Brand Create Error',
        );
        logError(err, {
          component: 'OrphanClientEditor',
          func: 'tryBrandCreate',
        });
      });
  }
  const [tryMatchSearch] = useMutation<
    MatchSearchForExpertOrphanMutation,
    MatchSearchForExpertOrphanMutationVariables
  >(matchSearchForExpertOrphanMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput) {
      setSearchResults(null);
      return;
    }
    if (!searchInput || searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchResults([]);
    tryMatchSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        setSearchLoading(false);
        if (dataMatchSearch && dataMatchSearch.matchSearchForExpert) {
          console.log('tryMatchSearch', dataMatchSearch.matchSearchForExpert);
          setSearchResults(
            dataMatchSearch.matchSearchForExpert
              .slice()
              .sort((a, b) => b.match.lastUpdated - a.match.lastUpdated),
          );
        }
      },
      variables: {
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Client Search Error');
      logError(err, {
        component: 'ExpertActiveClients',
        func: 'tryMatchSearch',
      });
      setSearchLoading(false);
      setSearchResults(null);
    });
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
  }
  const humanBrands = (dataHumanBrands && dataHumanBrands.brandForHumans) || [];
  if (errorHumanBrands || loadingHumanBrands) {
    // ignore these
  }
  return (
    <div className="ThreadEditor OrphanClientEditor">
      <div className="ThreadEditorTop">
        <div className="ThreadEditorTopTitle">Work with this client</div>
        {!isActionLoading && !createLoading && (
          <div className="ThreadEditorTopAction" onClick={onCancel}>
            Cancel
          </div>
        )}
      </div>
      <div className="ThreadEditorCardWrapper">
        <div className="ThreadEditorCard">
          <div className="ThreadEditorOrphan">
            {isActionLoading || createLoading ? (
              <div className="ThreadEditorOrphanLoading" />
            ) : (
              <Fragment>
                {isCreating ? (
                  <div className="ThreadEditorOrphanForm">
                    <div className="ThreadEditorOrphanFormField">
                      <div className="ThreadEditorOrphanFormFieldTitle">
                        Brand
                      </div>
                      <input
                        className="ThreadEditorOrphanFormFieldInput"
                        type="text"
                        autoComplete="new-off"
                        spellCheck="false"
                        placeholder="Brand Name"
                        value={brandNameInput}
                        onChange={(e) =>
                          setBrandNameInput(e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="ThreadEditorOrphanFormField">
                      <div className="ThreadEditorOrphanFormFieldTitle">
                        Website
                      </div>
                      <input
                        className="ThreadEditorOrphanFormFieldInput"
                        type="text"
                        autoComplete="new-off"
                        spellCheck="false"
                        placeholder="Website URL"
                        value={brandUrlInput}
                        onChange={(e) =>
                          setBrandUrlInput(e.currentTarget.value)
                        }
                      />
                    </div>
                    <div
                      className="ThreadEditorOrphanFormAction"
                      onClick={onCreate}
                    >
                      Create a new client
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <div className="ThreadEditorOrphanSection">
                      <div className="ThreadEditorOrphanSectionTitle">
                        Add to an existing client?
                      </div>
                      <div className="ThreadEditorOrphanSectionSearch">
                        <input
                          type="text"
                          ref={searchRef}
                          className="ThreadEditorOrphanSectionSearchInput"
                          placeholder="Search Clients"
                          autoComplete="new-off"
                          spellCheck="false"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                          onKeyDown={handleSearchInputKeyDown}
                        />
                      </div>
                      <div className="ThreadEditorOrphanSectionResults">
                        {!searchLoading &&
                          searchResults === null &&
                          humanBrands.map((b) => {
                            const teamMember =
                              (b.team || []).find((t) =>
                                humans.find((h) => t.id === h.id),
                              ) || b.teamLeader;
                            const subtitle = teamMember
                              ? (
                                  (teamMember.firstName || '').trim() +
                                  ' ' +
                                  (teamMember.lastName || '').trim()
                                ).trim()
                              : '';
                            return (
                              <div
                                key={b.id}
                                className="ThreadEditorOrphanSectionResultsItem"
                                onClick={() => onSelectBrand(b.id)}
                              >
                                <div className="ThreadEditorOrphanSectionResultsItemTitle">
                                  {b.name || ''}
                                </div>
                                <div className="ThreadEditorOrphanSectionResultsItemSub">
                                  {subtitle}
                                </div>
                              </div>
                            );
                          })}
                        {!searchLoading &&
                          searchResults &&
                          !!searchResults.length &&
                          searchResults.map((result) => {
                            let subtitle = '';
                            if (result.humanNameMatch) {
                              subtitle =
                                (
                                  (
                                    result.humanNameMatch.firstName || ''
                                  ).trim() +
                                  ' ' +
                                  (result.humanNameMatch.lastName || '').trim()
                                ).trim() || '';
                            } else if (result.urlMatch) {
                              subtitle =
                                (result.match.brand &&
                                  result.match.brand.url) ||
                                '';
                            } else if (result.shopifyAdminMatch) {
                              subtitle =
                                (result.match.brand &&
                                  result.match.brand.shopifyAdminURL) ||
                                '';
                            }
                            if (
                              !subtitle &&
                              result.match.brand &&
                              result.match.brand.teamLeader
                            ) {
                              subtitle =
                                (
                                  (
                                    result.match.brand.teamLeader.firstName ||
                                    ''
                                  ).trim() +
                                  ' ' +
                                  (
                                    result.match.brand.teamLeader.lastName || ''
                                  ).trim()
                                ).trim() || '';
                            }
                            return (
                              <div
                                key={result.match.id}
                                className="ThreadEditorOrphanSectionResultsItem"
                                onClick={() =>
                                  onSelectBrand(
                                    (result.match.brand &&
                                      result.match.brand.id) ||
                                      '',
                                  )
                                }
                              >
                                <div className="ThreadEditorOrphanSectionResultsItemTitle">
                                  {result.match.brand && result.match.brand.name
                                    ? result.match.brand.name
                                    : ''}
                                </div>
                                <div className="ThreadEditorOrphanSectionResultsItemSub">
                                  {subtitle}
                                </div>
                              </div>
                            );
                          })}
                        {!searchLoading &&
                          searchResults &&
                          !searchResults.length && (
                            <div className="ThreadEditorOrphanSectionResultsEmpty">
                              No search results found...
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="ThreadEditorOrphanSection">
                      <div className="ThreadEditorOrphanSectionTitle">
                        Or create a new client instead?
                      </div>
                      <div
                        className="ThreadEditorOrphanSectionAction"
                        onClick={() => setIsCreating(true)}
                      >
                        Create a new client
                      </div>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

OrphanClientEditor.propTypes = {
  humans: PropTypes.array.isRequired,
  isActionLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelectBrand: PropTypes.func.isRequired,
};

export default OrphanClientEditor;
