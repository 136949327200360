import PropTypes from 'prop-types';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import PartnerOverview from './PartnerOverview';
import { AuthPartnerQuery } from '../../gql/graphql';

interface PartnerDashboardProps {
  partnerDetails: Extract<
    Exclude<AuthPartnerQuery['auth'], null | undefined>['user'],
    { __typename?: 'Partner' | undefined }
  >;
}

const PartnerDashboard = ({ partnerDetails }: PartnerDashboardProps) => {
  const location = useLocation();
  return (
    <div className="Dashboard PartnerDashboard">
      <Switch location={location}>
        <Route path="/overview">
          <PartnerOverview partnerDetails={partnerDetails} />
        </Route>
        <Redirect to="/overview" />
      </Switch>
    </div>
  );
};

PartnerDashboard.propTypes = {
  partnerDetails: PropTypes.object.isRequired,
};

export default PartnerDashboard;
