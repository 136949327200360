import _ from 'lodash';
import { useEffect, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql, useSubscription } from '@apollo/client';
import {
  Link,
  NavLink,
  Route,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';
import apolloUtils, { ClientWithOnReconnected } from '../../utils/apollo';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import '../../styles/page/AdminDashboard.scss';
import AdminQueue from './AdminQueue';
import AdminBrandsHumans from './AdminBrandsHumans';
import AdminExperts from './AdminExperts';
import AdminPartners from './AdminPartners';
import AdminPrefabs from './AdminPrefabs';
import AdminTemplates from './AdminTemplates';
import AdminAdmins from './AdminAdmins';
import AdminPublish from './AdminPublish';
import AdminInbound from './AdminInbound';
import AdminDangerZone from './AdminDangerZone';
import AdminRequestDetail from './AdminRequestDetail';
import AdminExpertDetail from './AdminExpertDetail';
import AdminBrandDetail from './AdminBrandDetail';
import AdminHumanDetail from './AdminHumanDetail';
import AdminMatchDetail from './AdminMatchDetail';
import AdminPartnerDetail from './AdminPartnerDetail';
import AdminPrefabDetail from './AdminPrefabDetail';
import AdminAdminDetail from './AdminAdminDetail';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import {
  AuthAdminQuery,
  RequestReviewForAdminQuery,
  TemplatesAdminQuery,
  RequestUpdatedAdminSubscription,
  MatchAlertsForAdminQuery,
  MatchDangerZoneForAdminQuery,
  MatchUpdatedAdminSubscription,
  SupportChannelUnreadForAdminQuery,
  SupportChannelUpdatedAdminSubscription,
  OrphanUnreadForAdminQuery,
  OrphanUpdatedAdminSubscription,
  MeetingUpdatedAdminSubscription,
  ExpertUpdatedAdminSubscription,
  BrandUpdatedAdminSubscription,
  HumanUpdatedAdminSubscription,
  ProjectUpdatedAdminSubscription,
  QuoteUpdatedAdminSubscription,
} from '../../gql/graphql';
import { SUPPORT_EXPERT_ID } from '../../utils/constants';
import errorUtils from '../../utils/error';
import {
  RequestDetailHq,
  TemplateSummary,
  RequestPartialTop,
  MatchDetail,
  MatchPartialTopHq,
  SupportChannelPartialTop,
  SupportChannelDetail,
  OrphanPartialTop,
  OrphanDetail,
  MeetingDetail,
  ExpertDetailHq,
  BrandDetailHq,
  HumanDetailHq,
  ProjectDetailHq,
  QuoteDetail,
} from '../../utils/gql';
import logoBlack from '../../images/storetasker-logo-black.png';

const templatesAdminQuery = gql`
  query TemplatesAdmin {
    templates {
      ...TemplateSummary
    }
  }
  ${TemplateSummary}
`;

const requestReviewForAdminQuery = gql`
  query RequestReviewForAdmin {
    requestReviewForAdmin {
      ...RequestPartialTop
    }
  }
  ${RequestPartialTop}
`;

const requestUpdatedAdminSubscription = gql`
  subscription RequestUpdatedAdmin {
    requestUpdated {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const matchAlertsForAdminQuery = gql`
  query MatchAlertsForAdmin {
    matchAlertsForAdmin {
      ...MatchPartialTopHq
    }
  }
  ${MatchPartialTopHq}
`;

const matchUpdatedAdminSubscription = gql`
  subscription MatchUpdatedAdmin {
    matchUpdated {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const supportChannelUnreadForAdminQuery = gql`
  query SupportChannelUnreadForAdmin {
    supportChannelUnreadForAdmin {
      ...SupportChannelPartialTop
    }
  }
  ${SupportChannelPartialTop}
`;

const supportChannelUpdatedAdminSubscription = gql`
  subscription SupportChannelUpdatedAdmin {
    supportChannelUpdated {
      ...SupportChannelDetail
    }
  }
  ${SupportChannelDetail}
`;

const orphanUnreadForAdminQuery = gql`
  query OrphanUnreadForAdmin {
    orphanUnreadForAdmin {
      ...OrphanPartialTop
    }
  }
  ${OrphanPartialTop}
`;

const orphanUpdatedAdminSubscription = gql`
  subscription OrphanUpdatedAdmin {
    orphanUpdated {
      ...OrphanDetail
    }
  }
  ${OrphanDetail}
`;

const matchDangerZoneForAdminQuery = gql`
  query MatchDangerZoneForAdmin {
    matchDangerZoneForAdmin {
      ...MatchPartialTopHq
    }
  }
  ${MatchPartialTopHq}
`;

const meetingUpdatedAdminSubscription = gql`
  subscription MeetingUpdatedAdmin {
    meetingUpdated {
      ...MeetingDetail
    }
  }
  ${MeetingDetail}
`;

const expertUpdatedAdminSubscription = gql`
  subscription ExpertUpdatedAdmin {
    expertUpdated {
      ...ExpertDetailHq
    }
  }
  ${ExpertDetailHq}
`;

const brandUpdatedAdminSubscription = gql`
  subscription BrandUpdatedAdmin {
    brandUpdated {
      ...BrandDetailHq
    }
  }
  ${BrandDetailHq}
`;

const humanUpdatedAdminSubscription = gql`
  subscription HumanUpdatedAdmin {
    humanUpdated {
      ...HumanDetailHq
    }
  }
  ${HumanDetailHq}
`;

const projectUpdatedAdminSubscription = gql`
  subscription ProjectUpdatedAdmin {
    projectUpdated {
      ...ProjectDetailHq
    }
  }
  ${ProjectDetailHq}
`;

const quoteUpdatedAdminSubscription = gql`
  subscription QuoteUpdatedAdmin {
    quoteUpdated {
      ...QuoteDetail
    }
  }
  ${QuoteDetail}
`;

const PATH_TO_CLASS = {
  brands: 'AdminDashboardBrands',
  dangerzone: 'AdminDashboardDangerZone',
  experts: 'AdminDashboardExperts',
  extras: 'AdminDashboardExtras',
  humans: 'AdminDashboardHumans',
  inbound: 'AdminDashboardInbound',
  match: 'AdminDashboardMatch',
  requests: 'AdminDashboardQueue',
} as { [key: string]: string };

interface AdminDashboardProps {
  adminDetails: Extract<
    Exclude<AuthAdminQuery['auth'], null | undefined>['user'],
    { __typename?: 'Expert' | undefined }
  >;
  adminId: string;
  socketClient: ClientWithOnReconnected;
}

const initTime = new Date().getTime();

const AdminDashboard = ({
  adminDetails,
  socketClient,
}: AdminDashboardProps) => {
  const location = useLocation();
  const [networkErrorMessage, setNetworkErrorMessage] = useState('');
  const [networkErrorType, setNetworkErrorType] = useState('');
  const [refetchedTemplates, setRefetchedTemplates] = useState(false);
  const { addNotification } = useContext(GlobalNotificationContext);
  const {
    data: dataReviewRequests,
    error: errorReviewRequests,
    refetch: refetchReviewRequests,
    loading: loadingReviewRequests,
    subscribeToMore: subscribeToMoreReviewRequests,
  } = useQuery<RequestReviewForAdminQuery>(requestReviewForAdminQuery);
  useEffect(() => {
    subscribeToMoreReviewRequests<RequestUpdatedAdminSubscription>({
      document: requestUpdatedAdminSubscription,
      onError: (err) => {
        addNotification(
          'An error occured, please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreReviewRequests', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { requestUpdated },
          },
        },
      ) => {
        console.log('requestUpdated', prev, requestUpdated);
        if (!requestUpdated) {
          return prev;
        }
        const updatingIndex = (prev.requestReviewForAdmin || []).findIndex(
          (l) => l.id === requestUpdated.id,
        );
        if (updatingIndex >= 0) {
          // update existing
          return {
            requestReviewForAdmin: prev.requestReviewForAdmin
              .slice(0, updatingIndex)
              .concat({
                ...prev.requestReviewForAdmin[updatingIndex],
                ...requestUpdated,
              })
              .concat(prev.requestReviewForAdmin.slice(updatingIndex + 1)),
          };
        }
        // add new
        return {
          requestReviewForAdmin: (prev.requestReviewForAdmin || []).concat(
            requestUpdated,
          ),
        };
      },
    });
  }, [subscribeToMoreReviewRequests, addNotification]);
  let reviewLeadsForAdmin: RequestReviewForAdminQuery['requestReviewForAdmin'] =
    [];
  if (dataReviewRequests && dataReviewRequests.requestReviewForAdmin) {
    reviewLeadsForAdmin = dataReviewRequests.requestReviewForAdmin.filter(
      (r) => !r.cancelledAt && r.inReview && r.isActive,
    );
  }
  const {
    data: dataTemplates,
    error: errorTemplates,
    refetch: refetchTemplates,
    loading: loadingTemplates,
  } = useQuery<TemplatesAdminQuery>(templatesAdminQuery);
  const templates = _.sortBy(
    (dataTemplates &&
      dataTemplates.templates &&
      dataTemplates.templates.filter(
        (t) => t.expertStr === SUPPORT_EXPERT_ID,
      )) ||
      [],
    'title',
  );
  const emailTemplates = templates.filter((t) => !t.isQuote);
  const refetchTemplatesTop = useCallback(() => {
    setRefetchedTemplates(false);
    refetchTemplates()
      .catch(() => {})
      .finally(() => {
        setRefetchedTemplates(true);
      });
  }, [refetchTemplates]);
  const {
    data: dataSupportChannels,
    error: errorSupportChannels,
    refetch: refetchSupportChannels,
    loading: loadingSupportChannels,
    subscribeToMore: subscribeToMoreSupportChannels,
  } = useQuery<SupportChannelUnreadForAdminQuery>(
    supportChannelUnreadForAdminQuery,
  );
  useEffect(() => {
    subscribeToMoreSupportChannels<SupportChannelUpdatedAdminSubscription>({
      document: supportChannelUpdatedAdminSubscription,
      onError: (err) => {
        addNotification(
          'An error occured, please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreSupportChannels', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { supportChannelUpdated },
          },
        },
      ) => {
        console.log('supportChannelUpdated', prev, supportChannelUpdated);
        if (!supportChannelUpdated) {
          return prev;
        }
        const updatingIndex = (
          prev.supportChannelUnreadForAdmin || []
        ).findIndex((l) => l.id === supportChannelUpdated.id);
        if (updatingIndex >= 0) {
          // update existing
          return {
            supportChannelUnreadForAdmin: prev.supportChannelUnreadForAdmin
              .slice(0, updatingIndex)
              .concat({
                ...prev.supportChannelUnreadForAdmin[updatingIndex],
                ...supportChannelUpdated,
              })
              .concat(
                prev.supportChannelUnreadForAdmin.slice(updatingIndex + 1),
              ),
          };
        }
        // add new
        return {
          supportChannelUnreadForAdmin: (
            prev.supportChannelUnreadForAdmin || []
          ).concat(supportChannelUpdated),
        };
      },
    });
  }, [subscribeToMoreSupportChannels, addNotification]);
  let unreadSupportChannelsForAdmin: SupportChannelUnreadForAdminQuery['supportChannelUnreadForAdmin'] =
    [];
  if (dataSupportChannels && dataSupportChannels.supportChannelUnreadForAdmin) {
    unreadSupportChannelsForAdmin =
      dataSupportChannels.supportChannelUnreadForAdmin.filter(
        (sc) =>
          sc.adminUnread &&
          (!sc.adminSnoozedUntil || sc.adminSnoozedUntil <= initTime),
      );
  }
  const {
    data: dataOrphanUnread,
    error: errorOrphanUnread,
    refetch: refetchOrphanUnread,
    loading: loadingOrphanUnread,
    subscribeToMore: subscribeToMoreOrphanUnread,
  } = useQuery<OrphanUnreadForAdminQuery>(orphanUnreadForAdminQuery);
  useEffect(() => {
    subscribeToMoreOrphanUnread<OrphanUpdatedAdminSubscription>({
      document: orphanUpdatedAdminSubscription,
      onError: (err) => {
        addNotification(
          'An error occured, please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreOrphanUnread', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { orphanUpdated },
          },
        },
      ) => {
        console.log('orphanUpdated', prev, orphanUpdated);
        if (!orphanUpdated || orphanUpdated.expertStr !== SUPPORT_EXPERT_ID) {
          return prev;
        }
        const updatingIndex = (prev.orphanUnreadForAdmin || []).findIndex(
          (l) => l.id === orphanUpdated.id,
        );
        if (updatingIndex >= 0) {
          // update existing
          return {
            orphanUnreadForAdmin: prev.orphanUnreadForAdmin
              .slice(0, updatingIndex)
              .concat({
                ...prev.orphanUnreadForAdmin[updatingIndex],
                ...orphanUpdated,
              })
              .concat(prev.orphanUnreadForAdmin.slice(updatingIndex + 1)),
          };
        }
        // add new
        return {
          orphanUnreadForAdmin: (prev.orphanUnreadForAdmin || []).concat(
            orphanUpdated,
          ),
        };
      },
    });
  }, [subscribeToMoreOrphanUnread, addNotification]);
  let activeOrphansForAdmin: OrphanUnreadForAdminQuery['orphanUnreadForAdmin'] =
    [];
  if (dataOrphanUnread && dataOrphanUnread.orphanUnreadForAdmin) {
    activeOrphansForAdmin = dataOrphanUnread.orphanUnreadForAdmin.filter(
      (o) =>
        (o.expertUnread || o.adminAssigned) &&
        !o.migratedToMatchStr &&
        o.expertStr === SUPPORT_EXPERT_ID,
    );
  }
  const {
    data: dataMatchAlerts,
    error: errorMatchAlerts,
    refetch: refetchMatchAlerts,
    loading: loadingMatchAlerts,
    subscribeToMore: subscribeToMoreMatchAlerts,
  } = useQuery<MatchAlertsForAdminQuery>(matchAlertsForAdminQuery);
  useEffect(() => {
    subscribeToMoreMatchAlerts<MatchUpdatedAdminSubscription>({
      document: matchUpdatedAdminSubscription,
      onError: (err) => {
        addNotification(
          'An error occured, please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreMatchAlerts', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { matchUpdated },
          },
        },
      ) => {
        console.log('matchUpdated', prev, matchUpdated);
        if (!matchUpdated) {
          return prev;
        }
        const updatingIndex = (prev.matchAlertsForAdmin || []).findIndex(
          (l) => l.id === matchUpdated.id,
        );
        if (updatingIndex >= 0) {
          // update existing
          return {
            matchAlertsForAdmin: prev.matchAlertsForAdmin
              .slice(0, updatingIndex)
              .concat({
                ...prev.matchAlertsForAdmin[updatingIndex],
                ...matchUpdated,
              })
              .concat(prev.matchAlertsForAdmin.slice(updatingIndex + 1)),
          };
        }
        if (
          matchUpdated.expertStr !== SUPPORT_EXPERT_ID &&
          !matchUpdated.dangerZoneEventStr
        ) {
          return prev;
        }
        // add new
        return {
          matchAlertsForAdmin: (prev.matchAlertsForAdmin || []).concat(
            matchUpdated,
          ),
        };
      },
    });
  }, [subscribeToMoreMatchAlerts, addNotification]);
  const {
    data: dataMatchDangerZone,
    error: errorMatchDangerZone,
    refetch: refetchMatchDangerZone,
    loading: loadingMatchDangerZone,
  } = useQuery<MatchDangerZoneForAdminQuery>(matchDangerZoneForAdminQuery);
  const allTopLevelMatches = _.uniqBy(
    [
      ...((dataMatchDangerZone &&
        dataMatchDangerZone.matchDangerZoneForAdmin) ||
        []),
      ...((dataMatchAlerts && dataMatchAlerts.matchAlertsForAdmin) || []),
    ],
    'id',
  );
  const unreadBrandsForAdmin = allTopLevelMatches.filter(
    (m) =>
      m.expertUnread &&
      m.expertStr === SUPPORT_EXPERT_ID &&
      (!m.expertSnoozedUntil || m.expertSnoozedUntil <= initTime),
  );
  const dangerZoneForAdmin = allTopLevelMatches.filter(
    (m) =>
      m.expertStr !== SUPPORT_EXPERT_ID &&
      m.dangerZoneEventStr &&
      (!m.dangerZoneSnoozedUntil || m.dangerZoneSnoozedUntil <= initTime),
  );
  useEffect(() => {
    let networkTimeout: NodeJS.Timeout | undefined;
    function removeNetworkError() {
      showNetworkError('');
    }
    function showNetworkError(
      message: string,
      status?: string,
      timeout?: number,
    ) {
      if (networkTimeout) {
        clearTimeout(networkTimeout);
      }
      setNetworkErrorType(message ? status || 'BAD' : '');
      setNetworkErrorMessage(message || '');
      if (timeout) {
        networkTimeout = setTimeout(removeNetworkError, timeout);
      }
    }
    const connectingListener = socketClient.on('connecting', () => {
      console.log('AdminDashboard socketClient onConnecting');
    });
    const connectedListener = socketClient.on('connected', () => {
      console.log('AdminDashboard socketClient onConnected');
      removeNetworkError();
    });
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminDashboard socketClient onReconnected');
      showNetworkError('Reconnected!', 'GOOD', 1500);
      refetchReviewRequests().catch(() => {});
      refetchSupportChannels().catch(() => {});
      refetchOrphanUnread().catch(() => {});
      refetchMatchAlerts().catch(() => {});
      refetchMatchDangerZone().catch(() => {});
      refetchTemplates().catch(() => {});
    });
    const disconnectedListener = socketClient.on('closed', () => {
      console.log('AdminDashboard socketClient onDisconnected');
      if (!apolloUtils.getClosedOnPurpose()) {
        showNetworkError('Connection Error. Trying to reconnect.');
      }
    });
    const errorListener = socketClient.on('error', () => {
      console.log('AdminDashboard socketClient onError');
      if (!apolloUtils.getClosedOnPurpose()) {
        showNetworkError('Connection Error. Trying to reconnect.');
      }
    });
    return () => {
      connectingListener();
      connectedListener();
      reconnectedListener();
      disconnectedListener();
      errorListener();
    };
  }, [
    socketClient,
    refetchReviewRequests,
    refetchSupportChannels,
    refetchOrphanUnread,
    refetchMatchAlerts,
    refetchMatchDangerZone,
    refetchTemplates,
  ]);
  useSubscription<MeetingUpdatedAdminSubscription>(
    meetingUpdatedAdminSubscription,
  );
  useSubscription<ExpertUpdatedAdminSubscription>(
    expertUpdatedAdminSubscription,
  );
  useSubscription<BrandUpdatedAdminSubscription>(brandUpdatedAdminSubscription);
  useSubscription<HumanUpdatedAdminSubscription>(humanUpdatedAdminSubscription);
  useSubscription<ProjectUpdatedAdminSubscription>(
    projectUpdatedAdminSubscription,
  );
  useSubscription<QuoteUpdatedAdminSubscription>(quoteUpdatedAdminSubscription);
  const loadingError =
    errorReviewRequests ||
    errorMatchAlerts ||
    errorSupportChannels ||
    errorOrphanUnread ||
    errorTemplates ||
    errorMatchDangerZone
      ? errorUtils.getErrorMessage(
          errorReviewRequests ||
            errorMatchAlerts ||
            errorSupportChannels ||
            errorOrphanUnread ||
            errorTemplates ||
            errorMatchDangerZone!,
        )
      : '';
  if (
    loadingReviewRequests &&
    loadingMatchAlerts &&
    loadingSupportChannels &&
    loadingOrphanUnread &&
    loadingTemplates &&
    refetchedTemplates &&
    loadingMatchDangerZone
  ) {
    // ignore these
  }
  const pathBase = location.pathname.split('/')[1];
  const pathClass = PATH_TO_CLASS[pathBase] || '';
  return (
    <div className={'Dashboard AdminDashboard ' + pathClass + ' '}>
      <div className="DashboardFixed" />
      <div className="DashboardNav">
        <div className="DashboardNavHeader">
          <Link className="DashboardNavHeaderLeft" to="/requests">
            <img
              className="DashboardNavHeaderLogo DashboardNavHeaderLogoBlack"
              src={logoBlack}
              alt="Storetasker Logo"
            />
          </Link>
        </div>
        <div className="DashboardNavIntro">
          <Switch location={location}>
            <Route path="/requests">
              <div className="DashboardNavIntroHeader">Almighty Queue</div>
              <div className="DashboardNavIntroBody">
                You have a project due today and should start picking up some
                leads.
              </div>
            </Route>
            <Route path="/brands">
              <div className="DashboardNavIntroHeader">
                Clients &amp; Brands
              </div>
              <div className="DashboardNavIntroBody">
                You have a project due today and should start picking up some
                leads.
              </div>
            </Route>
            <Route path="/experts">
              <div className="DashboardNavIntroHeader">The Expert Network</div>
              <div className="DashboardNavIntroBody">
                You have a project due today and should start picking up some
                leads.
              </div>
            </Route>
            <Route path="/dangerzone">
              <div className="DashboardNavIntroHeader">Dangerous Zone</div>
              <div className="DashboardNavIntroBody">
                You have a project due today and should start picking up some
                leads.
              </div>
            </Route>
            <Route path="/inbound">
              <div className="DashboardNavIntroHeader">
                The Lost &amp; Found
              </div>
              <div className="DashboardNavIntroBody">
                You have a project due today and should start picking up some
                leads.
              </div>
            </Route>
            <Route path="/extras/partners">
              <div className="DashboardNavIntroHeader">
                My Favorite Type Of Ship
              </div>
              <div className="DashboardNavIntroBody">
                You have a project due today and should start picking up some
                leads.
              </div>
            </Route>
            <Route path="/extras/prefabs">
              <div className="DashboardNavIntroHeader">
                Prefabricated Projects
              </div>
              <div className="DashboardNavIntroBody">
                You have a project due today and should start picking up some
                leads.
              </div>
            </Route>
            <Route path="/extras/templates">
              <div className="DashboardNavIntroHeader">Update Templates</div>
              <div className="DashboardNavIntroBody">
                Update the saved email templates for admin users to use whenever
                they want.
              </div>
            </Route>
            <Route path="/extras/hq">
              <div className="DashboardNavIntroHeader">The Admistration</div>
              <div className="DashboardNavIntroBody">
                You have a project due today and should start picking up some
                leads.
              </div>
            </Route>
            <Route path="/extras/publish">
              <div className="DashboardNavIntroHeader">
                Update The Internets
              </div>
              <div className="DashboardNavIntroBody">
                You have a project due today and should start picking up some
                leads.
              </div>
            </Route>
            <Route path="/extras">
              <div className="DashboardNavIntroHeader">Extra Special Stuff</div>
              <div className="DashboardNavIntroBody">
                You have a project due today and should start picking up some
                leads.
              </div>
            </Route>
          </Switch>
        </div>
        <div className="DashboardNavLinks">
          <div className="DashboardNavLinkPointer" />
          <NavLink
            className="DashboardNavLink DashboardNavLinkQueue"
            to="/requests"
          >
            <span className="DashboardNavLinkText">Queue</span>
            {!!reviewLeadsForAdmin.length && (
              <span className="DashboardNavLinkSuper">
                {reviewLeadsForAdmin.length}
              </span>
            )}
          </NavLink>
          <NavLink className="DashboardNavLink" to="/brands">
            <span className="DashboardNavLinkText">Brands</span>
            {!!unreadBrandsForAdmin.length && (
              <span className="DashboardNavLinkSuper">
                {unreadBrandsForAdmin.length}
              </span>
            )}
          </NavLink>
          <NavLink className="DashboardNavLink" to="/experts">
            <span className="DashboardNavLinkText">Experts</span>
            {!!unreadSupportChannelsForAdmin.length && (
              <span className="DashboardNavLinkSuper">
                {unreadSupportChannelsForAdmin.length}
              </span>
            )}
          </NavLink>
          <NavLink className="DashboardNavLink" to="/dangerzone">
            <span className="DashboardNavLinkText">Danger Zone</span>
            {!!dangerZoneForAdmin.length && (
              <span className="DashboardNavLinkSuper">
                {dangerZoneForAdmin.length}
              </span>
            )}
          </NavLink>
          <NavLink className="DashboardNavLink" to="/inbound">
            <span className="DashboardNavLinkText">Inbound</span>
            {!!activeOrphansForAdmin.length && (
              <span className="DashboardNavLinkSuper">
                {activeOrphansForAdmin.length}
              </span>
            )}
          </NavLink>
          <NavLink className="DashboardNavLink" to="/extras/partners">
            <span className="DashboardNavLinkText">Extras</span>
          </NavLink>
          <NavLink className="DashboardNavSubLink" to="/extras/partners">
            <span className="DashboardNavSubLinkText">Partners</span>
          </NavLink>
          <NavLink className="DashboardNavSubLink" to="/extras/prefabs">
            <span className="DashboardNavSubLinkText">Prefabs</span>
          </NavLink>
          <NavLink className="DashboardNavSubLink" to="/extras/templates">
            <span className="DashboardNavSubLinkText">Templates</span>
          </NavLink>
          <NavLink className="DashboardNavSubLink" to="/extras/hq">
            <span className="DashboardNavSubLinkText">Admins</span>
          </NavLink>
          <NavLink className="DashboardNavSubLink" to="/extras/publish">
            <span className="DashboardNavSubLinkText">Publish</span>
          </NavLink>
        </div>
        <div className="DashboardNavFooter">
          <div className="DashboardNavFooterLink">
            {(adminDetails.firstName || '') +
              ' ' +
              (adminDetails.lastName || '')}
          </div>
        </div>
      </div>
      <div className="DashboardMain">
        <Switch location={location}>
          <Route path="/requests">
            <AdminQueue socketClient={socketClient} />
          </Route>
          <Route path="/brands">
            <AdminBrandsHumans socketClient={socketClient} />
          </Route>
          <Route path="/experts">
            <AdminExperts socketClient={socketClient} />
          </Route>
          <Route path="/dangerzone">
            <AdminDangerZone socketClient={socketClient} />
          </Route>
          <Route path="/inbound">
            <AdminInbound
              emailTemplates={emailTemplates}
              socketClient={socketClient}
            />
          </Route>
          <Route path="/extras/partners">
            <AdminPartners />
          </Route>
          <Route path="/extras/prefabs">
            <AdminPrefabs />
          </Route>
          <Route path="/extras/templates">
            <AdminTemplates
              emailTemplates={emailTemplates}
              refetchTemplates={refetchTemplatesTop}
            />
          </Route>
          <Route path="/extras/hq">
            <AdminAdmins />
          </Route>
          <Route path="/extras/publish">
            <AdminPublish />
          </Route>
          <Route path="/extras">
            <div className="ExtrasScreen" />
          </Route>
          <Route path="/humans">
            <div className="HumansScreen" />
          </Route>
          <Route path="/match">
            <div className="MatchScreen" />
          </Route>
          <Redirect to="/requests" />
        </Switch>
      </div>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/requests/:id"
            render={(routeProps) => (
              <AdminRequestDetail
                requestId={routeProps.match.params.id}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/experts/:id"
            render={(routeProps) => (
              <AdminExpertDetail
                expertId={routeProps.match.params.id}
                emailTemplates={emailTemplates}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/brands/:id"
            render={(routeProps) => (
              <AdminBrandDetail
                brandId={routeProps.match.params.id}
                emailTemplates={emailTemplates}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/humans/:id"
            render={(routeProps) => (
              <AdminHumanDetail
                humanId={routeProps.match.params.id}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/match/:id"
            render={(routeProps) => (
              <AdminMatchDetail
                matchId={routeProps.match.params.id}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/extras/partners/:id"
            render={(routeProps) => (
              <AdminPartnerDetail partnerId={routeProps.match.params.id} />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/extras/prefabs/:id"
            render={(routeProps) => (
              <AdminPrefabDetail prefabId={routeProps.match.params.id} />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/extras/hq/:id"
            render={(routeProps) => (
              <AdminAdminDetail adminId={routeProps.match.params.id} />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      {!!networkErrorMessage && (
        <div
          className={
            'AppDashboardNetworkError AppDashboardNetworkError' +
            (networkErrorType || 'BAD')
          }
          onClick={() => setNetworkErrorMessage('')}
        >
          {networkErrorMessage}
        </div>
      )}
      {!!loadingError && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div className="DashboardErrorCoverContent">{loadingError}</div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminDashboard.propTypes = {
  adminDetails: PropTypes.object.isRequired,
  adminId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default AdminDashboard;
