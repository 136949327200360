import _ from 'lodash';
import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import '../../styles/page/ExpertCollabView.scss';
import { useQuery, gql, useMutation, ApolloError } from '@apollo/client';
import {
  ExpertSearchDirectoryMutation,
  ExpertSearchDirectoryMutationVariables,
  ExpertInvitePotentialExpertMutation,
  ExpertInvitePotentialExpertMutationVariables,
  ExpertInSpotlightExpertQuery,
  ExpertDetailsQuery,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { ExpertDetail, ExpertDetailHuman } from '../../utils/gql';
import MultiSelect from '../feature/MultiSelect';
import {
  FOCUS_OPTIONS,
  SKILL_OPTIONS,
  TOOL_OPTIONS,
  CONTINENTS,
} from '../../utils/constants';
import {
  tagPersonFocus,
  tagLabelSkill,
  tagLabelTool,
} from '../../utils/format';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';

const expertInSpotlightExpertQuery = gql`
  query ExpertInSpotlightExpert {
    expertInSpotlight {
      ...ExpertDetailHuman
    }
  }
  ${ExpertDetailHuman}
`;

const expertSearchDirectoryMutation = gql`
  mutation ExpertSearchDirectory(
    $name: String!
    $tagFocus: [String!]!
    $tagSkills: [String!]!
    $tagTools: [String!]!
    $agencyIndependent: String!
    $continent: [String!]!
  ) {
    expertSearchDirectory(
      name: $name
      tagFocus: $tagFocus
      tagSkills: $tagSkills
      tagTools: $tagTools
      agencyIndependent: $agencyIndependent
      continent: $continent
    ) {
      ...ExpertDetailHuman
    }
  }
  ${ExpertDetailHuman}
`;

const expertInvitePotentialExpertMutation = gql`
  mutation ExpertInvitePotentialExpert(
    $email: String!
    $expertise: String!
    $name: String!
  ) {
    expertInvitePotentialExpert(
      email: $email
      expertise: $expertise
      name: $name
    ) {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;

interface ExpertCollabViewProps {
  expertDetails?: ExpertDetailsQuery['expertDetails'];
  expertId: string;
}

const ExpertCollabView = ({
  expertDetails,
  expertId,
}: ExpertCollabViewProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [searchInput, setSearchInput] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    ExpertSearchDirectoryMutation['expertSearchDirectory']
  >([]);
  const [searchTagFocus, setSearchTagFocus] = useState([] as string[]);
  const [searchTagSkills, setSearchTagSkills] = useState([] as string[]);
  const [searchTagTools, setSearchTagTools] = useState([] as string[]);
  const [searchContinents, setSearchContinents] = useState([] as string[]);
  const [searchAgencyIndependent, setSearchAgencyIndependent] = useState('');
  const [actionLoading, setActionLoading] = useState(false);
  const [newExpertEmail, setNewExpertEmail] = useState('');
  const [newExpertExpertise, setNewExpertExpertise] = useState(
    'FOCUS_WEB_DEVELOPMENT',
  );
  const [newExpertName, setNewExpertName] = useState('');
  const {
    loading: loadingExpertsSpotlight,
    error: errorExpertsSpotlight,
    data: dataExpertsSpotlight,
  } = useQuery<ExpertInSpotlightExpertQuery>(expertInSpotlightExpertQuery, {
    returnPartialData: true,
  });

  const showResults = _.uniqBy(
    (searchResults.length
      ? searchResults
      : (dataExpertsSpotlight && dataExpertsSpotlight.expertInSpotlight) || []
    ).filter((e) => e.photo && e.id !== expertId),
    'id',
  );
  const [tryReferPotential] = useMutation<
    ExpertInvitePotentialExpertMutation,
    ExpertInvitePotentialExpertMutationVariables
  >(expertInvitePotentialExpertMutation);
  function doReferPotential() {
    if (actionLoading) return;
    if (!newExpertName || !newExpertExpertise || !newExpertEmail) {
      addNotification('Please submit all the fields', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryReferPotential({
      variables: {
        email: newExpertEmail,
        expertise: newExpertExpertise,
        name: newExpertName,
      },
    })
      .then(() => {
        setActionLoading(false);
        setNewExpertEmail('');
        setNewExpertExpertise('FOCUS_WEB_DEVELOPMENT');
        setNewExpertName('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Refer Expert Error',
        );
        logError(err, {
          component: 'ExpertCollabView',
          func: 'tryReferPotential',
        });
      });
  }
  const [tryDirectorySearch] = useMutation<
    ExpertSearchDirectoryMutation,
    ExpertSearchDirectoryMutationVariables
  >(expertSearchDirectoryMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    setSearchLoading(true);
    setSearchResults([]);
    if (searchInput.length <= 2) {
      setSearchInput('');
    }
    tryDirectorySearch({
      update: (_cache, { data: dataDirectorySearch }) => {
        setSearchLoading(false);
        if (dataDirectorySearch && dataDirectorySearch.expertSearchDirectory) {
          console.log(
            'tryDirectorySearch',
            dataDirectorySearch.expertSearchDirectory,
          );
          setSearchResults(dataDirectorySearch.expertSearchDirectory);
        }
      },
      variables: {
        agencyIndependent: searchAgencyIndependent,
        continent: searchContinents,
        name: searchInput,
        tagFocus: searchTagFocus,
        tagSkills: searchTagSkills,
        tagTools: searchTagTools,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Client Search Error');
      logError(err, {
        component: 'ExpertCollabView',
        func: 'tryDirectorySearch',
      });
      setSearchLoading(false);
      setSearchResults([]);
    });
  }
  if (searchLoading || loadingExpertsSpotlight || errorExpertsSpotlight) {
    // ignore these
  }
  return (
    <div className="DashboardScreen CollabScreen">
      <div className="DashboardScreenContent CollabScreenContent">
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Invite new collaborators</div>
          <div className="DashboardSectionAligned">
            <div className="CollabScreenSplit CollabScreenSplitLeft">
              <div className="AdminDashboardForm">
                <a
                  className="AdminDashboardFormActionLink"
                  href="https://kb.storetasker.com/storetasker/Refer-Your-Friends-6ffef02f12bd4e7089affa90c9326a94"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
                <div>
                  <div className="AdminDashboardFormTitle">Invites</div>
                  <div className="AdminDashboardFormSubtitle">
                    Do you know someone who should join Storetasker? We pay up
                    to $2,000 for each new member you refer.
                  </div>
                </div>
                <div className="AdminDashboardFormField">
                  <div className="AdminDashboardFormFieldTitle">Name</div>
                  <input
                    className="AdminDashboardFormFieldInput"
                    type="text"
                    autoComplete="new-off"
                    spellCheck="false"
                    placeholder="Name"
                    value={newExpertName}
                    onChange={(e) => setNewExpertName(e.currentTarget.value)}
                  />
                </div>
                <div className="AdminDashboardFormField">
                  <div className="AdminDashboardFormFieldTitle">
                    Email Address
                  </div>
                  <input
                    className="AdminDashboardFormFieldInput"
                    type="email"
                    autoComplete="new-off"
                    spellCheck="false"
                    placeholder="Email Address"
                    value={newExpertEmail}
                    onChange={(e) => setNewExpertEmail(e.currentTarget.value)}
                  />
                </div>
                <div className="AdminDashboardFormField">
                  <div className="AdminDashboardFormFieldTitle">Expertise</div>
                  <select
                    className="AdminDashboardFormFieldInput"
                    required
                    value={newExpertExpertise}
                    onChange={(e) =>
                      setNewExpertExpertise(e.currentTarget.value)
                    }
                  >
                    {FOCUS_OPTIONS.map((o) => (
                      <option key={o.value} value={o.value}>
                        {o.person}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className={
                    'AdminDashboardFormAction ' +
                    (actionLoading ? ' AdminDashboardFormActionLoading ' : '')
                  }
                  onClick={doReferPotential}
                >
                  Invite
                </div>
              </div>
            </div>
            <div className="CollabScreenSplit CollabScreenSplitRight">
              {!!expertDetails &&
                !!expertDetails.potentialExpertReferrals &&
                !!expertDetails.potentialExpertReferrals.length && (
                  <div className="CollabInvites">
                    <div className="CollabInvitesHeader">Your invitations</div>
                    <div className="CollabInvitesList">
                      {expertDetails.potentialExpertReferrals.map((r) => (
                        <div key={r.email} className="CollabInvitesListItem">
                          <div className="CollabInvitesListName">{r.name}</div>
                          <div className="CollabInvitesListTitle">
                            {tagPersonFocus(r.expertise)}
                          </div>
                          <div className="CollabInvitesListEmail">
                            {r.email}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Find collaborators</div>
          <div className="DashboardSectionAligned">
            <div className="CollabScreenSplit CollabScreenSplitRight">
              <div className="CollabSearch">
                <div className="CollabSearchHeader">
                  <div className="CollabSearchHeaderTitle">Filters</div>
                  <div
                    onClick={onSearchStart}
                    className="CollabSearchHeaderBtn"
                  >
                    search
                  </div>
                </div>
                <div className="CollabSearchInput">
                  <div className="CollabSearchInputTitle">Name</div>
                  <div className="CollabSearchInputField">
                    <input
                      type="text"
                      className="CollabSearchInputFieldBox"
                      autoComplete="new-off"
                      spellCheck="false"
                      placeholder="Name"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                </div>
                <div className="CollabSearchInput">
                  <div className="CollabSearchInputTitle">
                    Focus
                    <span>{searchTagFocus.length} / 1</span>
                  </div>
                  <div className="CollabSearchInputField">
                    <MultiSelect
                      options={FOCUS_OPTIONS}
                      currValues={searchTagFocus}
                      onChange={setSearchTagFocus}
                      withTags
                      max={1}
                    />
                  </div>
                </div>
                <div className="CollabSearchInput">
                  <div className="CollabSearchInputTitle">
                    Skills
                    <span>{searchTagSkills.length} / 3</span>
                  </div>
                  <div className="CollabSearchInputField">
                    <MultiSelect
                      options={SKILL_OPTIONS}
                      currValues={searchTagSkills}
                      onChange={setSearchTagSkills}
                      withTags
                      max={3}
                    />
                  </div>
                </div>
                <div className="CollabSearchInput">
                  <div className="CollabSearchInputTitle">
                    Tool Expertise
                    <span>{searchTagTools.length} / 3</span>
                  </div>
                  <div className="CollabSearchInputField">
                    <MultiSelect
                      options={TOOL_OPTIONS}
                      currValues={searchTagTools}
                      onChange={setSearchTagTools}
                      withTags
                      max={3}
                    />
                  </div>
                </div>
                <div className="CollabSearchInput">
                  <div className="CollabSearchInputTitle">
                    Location
                    <span>{searchContinents.length} / 3</span>
                  </div>
                  <div className="CollabSearchInputField">
                    <MultiSelect
                      options={CONTINENTS.map((c) => ({ label: c, value: c }))}
                      currValues={searchContinents}
                      onChange={setSearchContinents}
                      withTags
                      max={3}
                    />
                  </div>
                </div>
                <div className="CollabSearchInput">
                  <div className="CollabSearchInputTitle">
                    Independent / Agency
                  </div>
                  <div className="CollabSearchInputField">
                    <select
                      className="CollabSearchInputFieldBox"
                      required
                      value={searchAgencyIndependent}
                      onChange={(e) =>
                        setSearchAgencyIndependent(e.currentTarget.value)
                      }
                    >
                      <option value="">Everyone</option>
                      <option value="AGENCY">Agency</option>
                      <option value="INDEPENDENT">Independent</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="CollabScreenSplit CollabScreenSplitLeft">
              <div className="CollabExpertList">
                {showResults.map((sr) => (
                  <div className="CollabExpertListItem" key={sr.id}>
                    <div className="CollabExpertListItemLink">
                      <div
                        className="CollabExpertListItemPic"
                        style={{
                          backgroundImage: 'url(' + sr.photo! + ')',
                        }}
                      />
                      <div className="CollabExpertListItemHeader">
                        <div className="CollabExpertListItemName">
                          {sr.firstName || 'Expert'}
                        </div>
                        <div className="CollabExpertListItemTitle">
                          {tagPersonFocus(sr.tagFocus[0] || '')}
                        </div>
                        <div className="CollabExpertListItemLocation">
                          {sr.locationStr}
                        </div>
                      </div>
                      <div className="CollabExpertListItemBody">
                        <div className="CollabExpertListItemBodyText">
                          {sr.profileBio}
                        </div>
                        {!!sr.tagSkills.length && (
                          <div className="CollabExpertListItemSkills">
                            <div className="CollabExpertListItemSkillsHeader">
                              Skills
                            </div>
                            <div className="CollabExpertListItemSkillsList">
                              {sr.tagSkills.slice(0, 4).map((sk) => (
                                <div
                                  className="CollabExpertListItemSkillsListItem"
                                  key={sk}
                                >
                                  {tagLabelSkill(sk)}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {!!sr.tagTools.length && (
                          <div className="CollabExpertListItemSkills">
                            <div className="CollabExpertListItemSkillsHeader">
                              Tools
                            </div>
                            <div className="CollabExpertListItemSkillsList">
                              {sr.tagTools.slice(0, 4).map((sk) => (
                                <div
                                  className="CollabExpertListItemSkillsListItem"
                                  key={sk}
                                >
                                  {tagLabelTool(sk)}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {!!sr.slackId && (
                          <div className="CollabExpertListItemSlack">
                            <a
                              className="CollabExpertListItemSlackBtn"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                'https://storetasker.slack.com/app_redirect?channel=' +
                                sr.slackId
                              }
                            >
                              slack
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ExpertCollabView.propTypes = {
  expertDetails: PropTypes.object,
  expertId: PropTypes.string.isRequired,
};

ExpertCollabView.defaultProps = {
  expertDetails: null,
};

export default ExpertCollabView;
