/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useContext, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { convertToRaw, ContentState } from 'draft-js';
import { useDebouncedCallback } from 'use-debounce';
import { Link } from 'react-router-dom';
import TextareaAutosize from 'react-autosize-textarea';
import copyToClipboard from 'clipboard-copy';
import PropTypes from 'prop-types';
import '../../styles/feature/EmailEditor.scss';
import RichTextEditor from './RichTextEditor';
import ReadOnlyEditor from './ReadOnlyEditor';
import envUtils from '../../utils/env';
import tokenUtils from '../../utils/token';
import { SUPPORT_EXPERT_ID } from '../../utils/constants';
import { formatNumberWithCommas } from '../../utils/format';
import { IFilestackFileUpload } from '../../utils/filestack';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { TemplatesQuery, MatchDetailsQuery } from '../../gql/graphql';
const { convertToHTML } = require('draft-convert'); // eslint-disable-line @typescript-eslint/no-var-requires
const ReactFilestack = require('filestack-react').default; // eslint-disable-line @typescript-eslint/no-var-requires

interface IFixedRecipient {
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  primaryEmail?: string | null;
  primaryPhone?: string | null;
}
interface EmailEditorProps {
  backToQuoteEditor?: () => void;
  emailTemplates: TemplatesQuery['templates'];
  expertEmailAddress?: string;
  expertId: string;
  expertPhoneNumber?: string;
  expertSchedulePath?: string;
  files: IFilestackFileUpload[];
  fixedRecipients?: IFixedRecipient[];
  goEditContacts?: () => void;
  inactiveQuoteEditingError?: boolean;
  initContent: string;
  introContent?: string;
  isAdmin?: boolean;
  isClaim?: boolean;
  isHideFiles?: boolean;
  isSending?: boolean;
  onDeleteDraft?: () => void;
  onSaveDraft: (
    subject: string,
    draftContent: string | null,
    files: IFilestackFileUpload[],
    recipients: MatchDetailsQuery['matchDetails']['brand']['team'],
  ) => void;
  onSendEmail: (draftHtml: string) => void;
  placeholderContent?: string;
  quoteActionType?: string;
  quotePaymentPrice?: string;
  quotePaymentType?: string;
  quoteTitle?: string;
  recipients: MatchDetailsQuery['matchDetails']['brand']['team'];
  status: string;
  subject: string;
  switchToTextEditor?: (content: string) => void;
  team?: MatchDetailsQuery['matchDetails']['brand']['team'];
}

const EmailEditor = ({
  backToQuoteEditor,
  emailTemplates,
  expertId,
  expertEmailAddress,
  expertPhoneNumber,
  expertSchedulePath,
  files,
  fixedRecipients,
  goEditContacts,
  inactiveQuoteEditingError,
  initContent,
  introContent,
  isAdmin,
  isClaim,
  isHideFiles,
  isSending,
  onDeleteDraft,
  onSaveDraft,
  onSendEmail,
  placeholderContent,
  quoteActionType,
  quotePaymentPrice,
  quotePaymentType,
  quoteTitle,
  recipients,
  status,
  subject,
  switchToTextEditor,
  team,
}: EmailEditorProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [fixedRecipients, recipients]);
  const [editorLatest, setEditorLatest] = useState<ContentState | null>(null);
  const [showTemplates, setShowTemplates] = useState(false);
  const [showSwitchOption, setShowSwitchOption] = useState(false);
  const [usePureText, setUsePureText] = useState(
    () => tokenUtils.readLocalStorage('use-pure-text') === 'true',
  );
  const [pureText, setPureText] = useState('');
  const [initEmailContent, setInitEmailContent] = useState(initContent || '');
  const { addNotification } = useContext(GlobalNotificationContext);
  const debounced = useDebouncedCallback(() => {
    trySaveDraft(null, null, null, editorLatest);
  }, 1000);
  const [recipientsOpen, setRecipientsOpen] = useState(false);
  function trySaveDraft(
    draftSubject: string | null,
    draftRecipients: MatchDetailsQuery['matchDetails']['brand']['team'] | null,
    draftFiles: IFilestackFileUpload[] | null,
    draftContentUpdated: ContentState | null,
  ) {
    let draftContent: string | null = null;
    if (draftContentUpdated !== null) {
      if (draftContentUpdated.hasText()) {
        try {
          draftContent = JSON.stringify(convertToRaw(draftContentUpdated));
        } catch (rawError) {
          console.log('email convertToRaw error', rawError);
        }
      } else {
        draftContent = '';
      }
    }
    onSaveDraft(
      draftSubject === null ? subject : draftSubject,
      draftContent,
      draftFiles === null ? files : draftFiles,
      draftRecipients === null ? recipients : draftRecipients,
    );
  }
  function switchToText() {
    debounced.cancel();
    if (switchToTextEditor) {
      if (usePureText) {
        switchToTextEditor(pureText);
      } else {
        switchToTextEditor(
          editorLatest &&
            editorLatest.hasText() &&
            editorLatest.getPlainText().replace(/\s/gi, '').trim()
            ? editorLatest.getPlainText()
            : '',
        );
      }
    }
  }
  function switchEditorType() {
    debounced.cancel();
    if (usePureText) {
      setInitEmailContent(pureText || '');
      setUsePureText(false);
      tokenUtils.removeLocalStorage('use-pure-text');
    } else {
      setPureText((editorLatest && editorLatest.getPlainText()) || '');
      setUsePureText(true);
      tokenUtils.writeLocalStorage('use-pure-text', 'true');
    }
    setShowSwitchOption(false);
  }
  function tryDelete() {
    debounced.cancel();
    if (onDeleteDraft) onDeleteDraft();
  }
  function trySend() {
    if (inactiveQuoteEditingError) {
      addNotification(
        'This project has been updated. Please delete this draft and start over.',
        undefined,
        5000,
      );
      return;
    }
    if (
      !subject.trim() ||
      (!(fixedRecipients || []).length && !recipients.length)
    ) {
      addNotification('You need a subject and recipients.', undefined, 5000);
      return;
    }
    if (
      !usePureText &&
      (!editorLatest ||
        !editorLatest.hasText() ||
        !editorLatest.getPlainText().replace(/\s/gi, '').trim())
    ) {
      addNotification('You need an email body.', undefined, 5000);
      return;
    }
    if (usePureText && !pureText.trim()) {
      addNotification('You need an email body.', undefined, 5000);
      return;
    }
    debounced.cancel();
    const html = usePureText
      ? pureText.replace(/[\r\n]/gi, '<br />') + '<br />'
      : (convertToHTML({
          blockToHTML: (block: any) => {
            if (block.type === 'unstyled') {
              if (block.text === '' || block.text === ' ') {
                return <br />;
              }
              return <div />;
            }
            return undefined;
          },
          entityToHTML: (entity: any, originalText: string) => {
            if (entity.type === 'LINK') {
              return (
                <a
                  href={entity.data.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {originalText}
                </a>
              );
            }
            return originalText;
          },
        })(editorLatest)
          .replace(/(<div><\/div>)+$/gi, '')
          .replace(/(<br\/>)+$/gi, '') as string);
    // console.log('html-output', html);
    onSendEmail(html);
  }
  function fileError(error: Error) {
    console.log('Filestack fileError', error);
    addNotification(
      (error && error.message) || 'File upload error. Please try again.',
      undefined,
      5000,
    );
  }
  function fileUploaded(filestackRes: any) {
    console.log('Filestack fileUploaded', filestackRes);
    if (filestackRes && filestackRes.filesUploaded) {
      let currFileSizeTotal = 0;
      files.forEach((f) => {
        if (f.size) {
          const sizeInt = parseInt(f.size, 10);
          if (sizeInt) {
            currFileSizeTotal += sizeInt;
          }
        }
      });
      const allowedFiles = filestackRes.filesUploaded.filter((f: any) => {
        if (f.size) {
          const sizeInt = parseInt(f.size as string, 10);
          if (sizeInt) {
            if (currFileSizeTotal + sizeInt < 20 * 1024 * 1024) {
              currFileSizeTotal += sizeInt;
            } else {
              return false;
            }
          }
        }
        return true;
      });
      if (allowedFiles.length < filestackRes.filesUploaded.length) {
        addNotification(
          'Files attached must be less than 20MB in total',
          undefined,
          5000,
        );
      }
      trySaveDraft(
        null,
        null,
        files.concat(
          allowedFiles.map((f: any) => ({
            container: f.container || '',
            filename: f.filename || '',
            handle: f.handle || '',
            key: f.key || '',
            mimetype: f.mimetype || '',
            size: (f.size || '').toString(),
            source: f.source || '',
            uploadId: f.uploadId || '',
            url: f.url || '',
          })) as IFilestackFileUpload[],
        ),
        null,
      );
    }
  }
  function removeFile(filestackUploadUrl: string) {
    console.log('Filestack removeFile', filestackUploadUrl);
    trySaveDraft(
      null,
      null,
      files.filter((f) => f.url !== filestackUploadUrl),
      null,
    );
  }
  function useTemplate(description: string) {
    setShowTemplates(false);
    setInitEmailContent(description);
  }
  function addRecipient(
    recipientToAdd: MatchDetailsQuery['matchDetails']['brand']['team'][0],
  ) {
    trySaveDraft(null, recipients.concat(recipientToAdd), null, null);
    setRecipientsOpen(false);
  }
  function onEditorUpdated(updatedContent: ContentState) {
    setEditorLatest(updatedContent);
    setInitEmailContent('');
    debounced.callback();
  }
  function getQuoteTitleType(paymentType: string) {
    if (paymentType === 'PROJECT') return 'Project';
    if (paymentType === 'BILL') return 'Bill';
    return 'Subscription';
  }
  function getQuotePriceType(paymentType: string) {
    if (paymentType === 'MONTHLY_SUBSCRIPTION') return 'Monthly';
    if (paymentType === 'WEEKLY_SUBSCRIPTION') return 'Weekly';
    return 'Price';
  }
  function copySchedulingLink() {
    if (expertSchedulePath) {
      copyToClipboard('meet.storetasker.com/' + expertSchedulePath).catch(
        () => {},
      );
    }
    setShowSwitchOption(false);
  }
  function copyEmailLink() {
    if (expertEmailAddress) {
      copyToClipboard(expertEmailAddress).catch(() => {});
    }
    setShowSwitchOption(false);
  }
  function copyPhoneLink() {
    if (expertPhoneNumber) {
      copyToClipboard(expertPhoneNumber).catch(() => {});
    }
    setShowSwitchOption(false);
  }
  const availableToAdd = (team || []).filter(
    (h) => !recipients.find((r) => r.id === h.id),
  );
  return (
    <div
      className={
        'ThreadEditor EmailEditor ' +
        (expertId !== SUPPORT_EXPERT_ID ? ' EmailEditorWithSignatue ' : '')
      }
    >
      <div className="ThreadEditorTop">
        {!usePureText && <div className="ThreadEditorTopStatus">{status}</div>}
        <div className="ThreadEditorRow">
          <div className="ThreadEditorRowTitle">To</div>
          <div className="ThreadEditorRowBubbles">
            {(fixedRecipients || []).map((f) => (
              <div
                key={f.id}
                className="ThreadEditorRowBubble ThreadEditorRowBubbleFixed"
                data-tip={f.primaryEmail || ''}
              >
                <span className="ThreadEditorRowBubbleText">
                  {`${(f.firstName || '').trim()} ${(
                    f.lastName || ''
                  ).trim()}`.trim() ||
                    f.primaryEmail ||
                    ''}
                </span>
              </div>
            ))}
            {recipients.map((h) => (
              <div
                key={h.id}
                className="ThreadEditorRowBubble"
                data-tip={h.primaryEmail || ''}
                onClick={() =>
                  trySaveDraft(
                    null,
                    recipients.filter((r) => r.id !== h.id),
                    null,
                    null,
                  )
                }
              >
                <span className="ThreadEditorRowBubbleText">
                  {`${(h.firstName || '').trim()} ${(
                    h.lastName || ''
                  ).trim()}`.trim() ||
                    (h.primaryEmail || '').trim() ||
                    ''}
                </span>
                <div className="ThreadEditorRowBubbleRemove" />
              </div>
            ))}
            {!(fixedRecipients || []).length && (
              <div
                className={
                  'ThreadEditorRowBubbleAdd ' +
                  (recipientsOpen ? ' ThreadEditorRowBubbleAddCollapse ' : ' ')
                }
                onClick={() => setRecipientsOpen(!recipientsOpen)}
              >
                +
              </div>
            )}
          </div>
        </div>
        <div className="ThreadEditorRow">
          <div className="ThreadEditorRowTitle">Subject</div>
          <div className="ThreadEditorRowInput">
            <TextareaAutosize
              type="text"
              placeholder="Subject..."
              spellCheck="true"
              className="ThreadEditorRowInputTextarea"
              value={subject}
              disabled={!!introContent}
              readOnly={!!introContent}
              onChange={(e) => {
                trySaveDraft(
                  e.currentTarget.value.replace(/[\r\n]/gi, ' '),
                  null,
                  null,
                  null,
                );
              }}
            />
          </div>
        </div>
      </div>
      {!!introContent && !!isClaim && (
        <div className="ThreadEditorCardWrapper ThreadEditorCardWrapperEmailIntroContent">
          <div className="ThreadEditorCard">
            <div className="ThreadEditorBody">
              <div className="RequestDetailViewEmailIntroContentExplain">
                (our intro email)
              </div>
              <ReadOnlyEditor
                className="RequestDetailViewEmailIntroContent"
                content={introContent}
              />
            </div>
          </div>
        </div>
      )}
      <div className="ThreadEditorCardWrapper">
        <div className="ThreadEditorCard">
          <div
            className={
              'ThreadEditorBody ' +
              (showTemplates ? ' ThreadEditorBodyWithTemplates ' : '')
            }
          >
            {!usePureText ? (
              <RichTextEditor
                initContent={initEmailContent}
                placeholder={
                  placeholderContent ||
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada.'
                }
                onUpdated={onEditorUpdated}
              />
            ) : (
              <div className="EmailEditorWrap">
                <TextareaAutosize
                  type="text"
                  rows={3}
                  placeholder={
                    placeholderContent ||
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada.'
                  }
                  spellCheck="true"
                  className="EmailEditorTextarea"
                  value={pureText}
                  onChange={(e) => {
                    setPureText(e.currentTarget.value);
                  }}
                />
              </div>
            )}
            {expertId !== SUPPORT_EXPERT_ID && (
              <Link
                to="/profile?signature=true"
                className="EmailEditorSignature"
              >
                your storetasker signature
              </Link>
            )}
            <div
              className={
                'ThreadEditorBodySwitch ThreadEditorBodySwitch' +
                (showSwitchOption ? 'Active' : 'Inactive')
              }
            >
              <div
                className="ThreadEditorBodySwitchToggle"
                onClick={() => setShowSwitchOption(!showSwitchOption)}
              />
              {!!showSwitchOption && !!switchToTextEditor && (
                <div
                  className="ThreadEditorBodySwitchOption"
                  onClick={switchToText}
                >
                  send {quotePaymentType ? 'quote ' : ''}via text instead
                </div>
              )}
              {!!showSwitchOption && !!expertSchedulePath && (
                <div
                  className="ThreadEditorBodySwitchOption"
                  onClick={copySchedulingLink}
                >
                  copy scheduling link
                </div>
              )}
              {!!showSwitchOption && !!expertEmailAddress && (
                <div
                  className="ThreadEditorBodySwitchOption"
                  onClick={copyEmailLink}
                >
                  copy email address
                </div>
              )}
              {!!showSwitchOption && !!expertPhoneNumber && (
                <div
                  className="ThreadEditorBodySwitchOption"
                  onClick={copyPhoneLink}
                >
                  copy phone number
                </div>
              )}
              {!!showSwitchOption && (
                <div
                  className="ThreadEditorBodySwitchOption"
                  onClick={switchEditorType}
                >
                  {usePureText ? 'use full editor' : 'use simple editor'}
                </div>
              )}
            </div>
            {!!inactiveQuoteEditingError && (
              <div className="ThreadEditorBodyError">
                This project has been updated. Please delete this draft and
                start over.
              </div>
            )}
            {!!showTemplates && (
              <div className="ThreadEditorBodyTemplates">
                <div className="ThreadEditorBodyTemplatesHeader">
                  Your Templates{' '}
                  <Link
                    to={
                      isAdmin ? '/extras/templates' : '/profile?templates=setup'
                    }
                  >
                    manage
                  </Link>
                </div>
                <div
                  className="ThreadEditorBodyTemplatesClose"
                  onClick={() => setShowTemplates(false)}
                />
                {emailTemplates.length ? (
                  <div className="ThreadEditorBodyTemplatesList">
                    {emailTemplates.map((t) => (
                      <div
                        className="ThreadEditorBodyTemplatesListItem"
                        key={t.id}
                        onClick={() => useTemplate(t.description)}
                      >
                        {t.title}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="ThreadEditorBodyTemplatesEmpty">
                    No templates found. Click &quot;manage&quot; to create your
                    first email template.
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="ThreadEditorTools">
            {!isHideFiles && (
              <ReactFilestack
                apikey="AGxdviGoVRwWPL6lKEdnXz"
                actionOptions={{
                  maxFiles: 10,
                  maxSize: 20 * 1024 * 1024,
                  storeTo: {
                    container: envUtils.pick(
                      'files.asklorem.com',
                      'files-dev.asklorem.com',
                      'files-dev.asklorem.com',
                    ),
                    location: 's3',
                    path: `${expertId}/`,
                    region: 'us-east-1',
                  },
                }}
                onSuccess={fileUploaded}
                onError={fileError}
                customRender={({ onPick }: { onPick: any }) => (
                  <div
                    onMouseDown={onPick}
                    className="ThreadEditorTool ThreadEditorToolFiles"
                  >
                    attach<span> files</span>
                  </div>
                )}
              />
            )}
            {!!isClaim && (
              <a
                className="ThreadEditorTool ThreadEditorToolExamples"
                href="https://kb.storetasker.com/Matching-Message-Examples-6df3be369d4d4392ada7d0d655e6ca02"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>see </span>examples
              </a>
            )}
            {!usePureText && (
              <div
                className="ThreadEditorTool ThreadEditorToolTemplate"
                onClick={() => setShowTemplates(!showTemplates)}
              >
                <span>use a </span>template
              </div>
            )}
          </div>
          <div className="ThreadEditorFooter">
            <div className="ThreadEditorFooterUpper">
              <div className="ThreadEditorFiles">
                {files.map((file) => (
                  <div
                    key={file.url}
                    className="ThreadEditorFile"
                    onClick={() => removeFile(file.url || '')}
                  >
                    <span className="ThreadEditorFileText">
                      {file.filename}
                    </span>
                    <div className="ThreadEditorFileRemove" />
                  </div>
                ))}
              </div>
            </div>
            <div className="ThreadEditorActions">
              <div
                className={
                  'ThreadEditorActionBtn ThreadEditorActionSend ' +
                  (isSending ? ' ThreadEditorActionSendLoading ' : '')
                }
                onClick={trySend}
              >
                {isClaim ? 'Claim Lead' : 'Send Email'}
              </div>
              {onDeleteDraft && (
                <div
                  className="ThreadEditorActionBtn ThreadEditorActionDelete"
                  onClick={tryDelete}
                >
                  Delete
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!quotePaymentType && (
        <div className="ThreadEditorQuoteAttachedWrapper">
          <div
            className="ThreadEditorQuoteAttached"
            onClick={() =>
              quoteActionType !== 'COMPLETE' &&
              backToQuoteEditor &&
              backToQuoteEditor()
            }
          >
            <div className="ThreadEditorQuoteAttachedLeft">
              {quoteActionType === 'CREATE' ? (
                <div className="ThreadEditorQuoteAttachedTitle">
                  {getQuoteTitleType(quotePaymentType)}
                </div>
              ) : (
                <div className="ThreadEditorQuoteAttachedTitle">
                  {quoteActionType === 'UPDATE'
                    ? 'Quote Update'
                    : 'Mark Complete'}
                </div>
              )}
              <div className="ThreadEditorQuoteAttachedText">{quoteTitle}</div>
            </div>
            <div className="ThreadEditorQuoteAttachedRight">
              <div className="ThreadEditorQuoteAttachedTitle">
                {getQuotePriceType(quotePaymentType)}
              </div>
              <div className="ThreadEditorQuoteAttachedText">
                $
                {formatNumberWithCommas(
                  (quotePaymentPrice || '').replace('$', ''),
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!!recipientsOpen && (
        <div className="ThreadEditorRecipientsPopoverWrapper">
          <div
            className="ThreadEditorRecipientsPopoverClose"
            onClick={() => setRecipientsOpen(false)}
          >
            cancel
          </div>
          <div className="ThreadEditorRecipientsPopover">
            <div className="ThreadEditorRecipientsOptions">
              {availableToAdd.map((h) => (
                <div
                  key={h.id}
                  className="ThreadEditorRecipientsOption"
                  onClick={() => addRecipient(h)}
                >
                  {`${(h.firstName || '').trim()} ${(
                    h.lastName || ''
                  ).trim()}`.trim() ||
                    (h.primaryEmail || '').trim() ||
                    ''}
                </div>
              ))}
            </div>
            <div className="ThreadEditorRecipientsInfo">
              Don&apos;t see who you&apos;re looking for? Update contact info{' '}
              <span onClick={() => goEditContacts && goEditContacts()}>
                here
              </span>
              .
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

EmailEditor.propTypes = {
  backToQuoteEditor: PropTypes.func,
  emailTemplates: PropTypes.array.isRequired,
  expertEmailAddress: PropTypes.string,
  expertId: PropTypes.string.isRequired,
  expertPhoneNumber: PropTypes.string,
  expertSchedulePath: PropTypes.string,
  files: PropTypes.array.isRequired,
  fixedRecipients: PropTypes.array,
  goEditContacts: PropTypes.func,
  inactiveQuoteEditingError: PropTypes.bool,
  initContent: PropTypes.string.isRequired,
  introContent: PropTypes.string,
  isAdmin: PropTypes.bool,
  isClaim: PropTypes.bool,
  isHideFiles: PropTypes.bool,
  isSending: PropTypes.bool,
  onDeleteDraft: PropTypes.func,
  onSaveDraft: PropTypes.func.isRequired,
  onSendEmail: PropTypes.func.isRequired,
  placeholderContent: PropTypes.string,
  quoteActionType: PropTypes.string,
  quotePaymentPrice: PropTypes.string,
  quotePaymentType: PropTypes.string,
  quoteTitle: PropTypes.string,
  recipients: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  switchToTextEditor: PropTypes.func,
  team: PropTypes.array,
};

EmailEditor.defaultProps = {
  backToQuoteEditor: () => {},
  expertEmailAddress: '',
  expertPhoneNumber: '',
  expertSchedulePath: '',
  fixedRecipients: [],
  goEditContacts: () => {},
  inactiveQuoteEditingError: false,
  introContent: '',
  isAdmin: false,
  isClaim: false,
  isHideFiles: false,
  isSending: false,
  onDeleteDraft: null,
  placeholderContent: '',
  quoteActionType: '',
  quotePaymentPrice: '',
  quotePaymentType: '',
  quoteTitle: '',
  switchToTextEditor: null,
  team: [],
};

export default EmailEditor;
