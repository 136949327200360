import { useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ClientWithOnReconnected } from '../../utils/apollo';
import {
  AuthHumanQuery,
  BrandForHumansQuery,
  RequestActiveForBrandsQuery,
  MatchActiveForBrandsSummaryQuery,
  MatchActiveForBrandsSummaryQueryVariables,
  ExpertInSpotlightQuery,
} from '../../gql/graphql';
import { MatchSummaryHuman, ExpertSummary } from '../../utils/gql';
import { SUPPORT_EXPERT_ID } from '../../utils/constants';
import errorUtils from '../../utils/error';
import { expertFocusType } from '../../utils/format';
import logo from '../../images/storetasker-logo-black.png';
import '../../styles/page/HumanExperts.scss';

const matchActiveForBrandsSummaryQuery = gql`
  query MatchActiveForBrandsSummary($brandIds: [ID!]!) {
    matchActiveForBrands(brandIds: $brandIds) {
      ...MatchSummaryHuman
    }
  }
  ${MatchSummaryHuman}
`;

const expertInSpotlightQuery = gql`
  query ExpertInSpotlight {
    expertInSpotlight {
      ...ExpertSummary
    }
  }
  ${ExpertSummary}
`;

interface HumanExpertsProps {
  activeRequests: RequestActiveForBrandsQuery['requestActiveForBrands'];
  brands: BrandForHumansQuery['brandForHumans'];
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
  socketClient: ClientWithOnReconnected;
}

const HumanExperts = ({
  activeRequests,
  brands,
  socketClient,
}: HumanExpertsProps) => {
  const {
    loading: loadingMatchesActive,
    error: errorMatchesActive,
    data: dataMatchesActive,
    refetch: refetchMatchesActive,
  } = useQuery<
    MatchActiveForBrandsSummaryQuery,
    MatchActiveForBrandsSummaryQueryVariables
  >(matchActiveForBrandsSummaryQuery, {
    returnPartialData: true,
    skip: !brands.length,
    variables: {
      brandIds: brands.map((b) => b.id),
    },
  });
  const activeExpertsForBrands = _.uniqBy(
    ((dataMatchesActive && dataMatchesActive.matchActiveForBrands) || [])
      .filter(
        (m) =>
          m.expertStr !== SUPPORT_EXPERT_ID &&
          m.expert &&
          m.expert.status &&
          ['pending', 'admin', 'support'].indexOf(m.expert.status) === -1 &&
          m.brandStr &&
          brands.find((b) => b.id === m.brandStr),
      )
      .sort(
        (a, b) =>
          (b.lastUpdated || b.createdAt || 0) -
          (a.lastUpdated || a.createdAt || 0),
      )
      .map((m) => m.expert),
    'id',
  ).filter(
    (e) =>
      e.photo && ['winback', 'churned', 'inactive'].indexOf(e.status) === -1,
  );
  const {
    loading: loadingExpertsSpotlight,
    error: errorExpertsSpotlight,
    data: dataExpertsSpotlight,
  } = useQuery<ExpertInSpotlightQuery>(expertInSpotlightQuery, {
    returnPartialData: true,
  });
  const expertsInSpotlight = _.uniqBy(
    (
      (dataExpertsSpotlight && dataExpertsSpotlight.expertInSpotlight) ||
      []
    ).filter(
      (e) =>
        e.inSpotlight &&
        e.photo &&
        !activeExpertsForBrands.find((o) => o.id === e.id),
    ),
    'id',
  );
  function renderExpertCards(
    experts: MatchActiveForBrandsSummaryQuery['matchActiveForBrands'][0]['expert'][],
  ) {
    return experts.map((e) => (
      <Link
        className="HumanExpertsExpert"
        key={e.id}
        to={'/experts/' + e.id}
        style={{
          backgroundImage: 'url(' + (e.photo || '') + ')',
        }}
      >
        <div className="HumanExpertsExpertFooter">
          <div className="HumanExpertsExpertTitle">
            {e.firstName || 'Expert'}
          </div>
          <div className="HumanExpertsExpertSubTitle">
            {expertFocusType(e.tagFocus) || 'Shopify Expert'}
          </div>
        </div>
      </Link>
    ));
  }
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('HumanExperts socketClient onReconnected');
      refetchMatchesActive().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchMatchesActive]);
  const noMatchActiveRequests = activeRequests.filter((r) => {
    if (r.isMultipleMatch && r.matchLog) {
      return r.matchLog.length < 3;
    }
    if (r.currentMatchStr) {
      return false;
    }
    return true;
  });
  if (errorMatchesActive || errorExpertsSpotlight) {
    console.error(errorMatchesActive || errorExpertsSpotlight);
  }
  if (loadingMatchesActive || loadingExpertsSpotlight) {
    // ignore these
  }
  return (
    <div className="DashboardScreen DashboardScreenHuman ExpertsScreen HumanExperts">
      <Link className="DashboardScreenLogoLink" to="/home">
        <img
          className="DashboardScreenLogo"
          src={logo}
          alt="Storetasker Logo"
        />
      </Link>
      <div className="DashboardScreenContent ExpertsScreenContent">
        {!loadingMatchesActive && (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">Your team</div>
            <div className="DashboardSectionScrollerSwitch">
              {renderExpertCards(activeExpertsForBrands)}
              {noMatchActiveRequests.map((r, i) => (
                <Link
                  className={`HumanExpertsExpert HumanExpertsExpertSearching HumanExpertsExpertSearching${i}`}
                  key={r.id}
                  to={'/requests/' + r.id}
                >
                  <div className="HumanExpertsExpertFooter">
                    <div className="HumanExpertsExpertTitle">
                      Searching <span />
                    </div>
                    <div className="HumanExpertsExpertSubTitle">
                      Finding your expert
                    </div>
                  </div>
                </Link>
              ))}
              <div className="HumanExpertsNewRequest">
                <Link
                  to="/new?another=true"
                  className="HumanExpertsNewRequestBtn"
                >
                  Hire another expert
                </Link>
                <div className="HumanExpertsNewRequestLinks">
                  <div className="HumanExpertsNewRequestLinkList">
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=developer">
                        Full-stack Shopify developers
                      </Link>
                    </div>
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=developer">
                        Headless Developers
                      </Link>
                    </div>
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=developer">
                        Custom Shopify App Developers
                      </Link>
                    </div>
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=designer">
                        UI/UX Designers
                      </Link>
                    </div>
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=marketer">
                        Klaviyo Email Experts
                      </Link>
                    </div>
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=developer">
                        Analytics &amp; Marketing Tag Experts
                      </Link>
                    </div>
                  </div>
                  <div className="HumanExpertsNewRequestLinkList HumanExpertsNewRequestLinkListSecond">
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=developer">
                        SEO Experts
                      </Link>
                    </div>
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=marketer">
                        CRO Experts
                      </Link>
                    </div>
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=marketer">Amazon</Link>
                    </div>
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=marketer">
                        Facebook Ads
                      </Link>
                    </div>
                    <div className="HumanExpertsNewRequestLinksItem">
                      <Link to="/new?another=true&skill=developer">
                        Site Speed
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Expert spotlight</div>
          <div className="DashboardSectionScrollerSwitch">
            {renderExpertCards(expertsInSpotlight)}
            {!expertsInSpotlight.length && (
              <div className="DashboardSectionEmpty">
                {errorExpertsSpotlight
                  ? errorUtils.getErrorMessage(errorExpertsSpotlight) ||
                    'Loading Error'
                  : 'No experts found.'}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

HumanExperts.propTypes = {
  activeRequests: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  human: PropTypes.object.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default HumanExperts;
