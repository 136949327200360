import { useState, useContext, Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { convertToRaw, ContentState } from 'draft-js';
import TextareaAutosize from 'react-autosize-textarea';
import RichTextEditor from '../feature/RichTextEditor';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import envUtils from '../../utils/env';
import { centsDollarsRounded, prefabPriceStr } from '../../utils/format';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { ProjectPrefabDetailHq } from '../../utils/gql';
import {
  PrefabDetailsAdminQuery,
  PrefabDetailsAdminQueryVariables,
  PrefabEditByAdminMutation,
  PrefabEditByAdminMutationVariables,
} from '../../gql/graphql';

const prefabDetailsAdminQuery = gql`
  query PrefabDetailsAdmin($prefabId: ID!) {
    prefabDetails(prefabId: $prefabId) {
      ...ProjectPrefabDetailHq
    }
  }
  ${ProjectPrefabDetailHq}
`;

const prefabEditByAdminMutation = gql`
  mutation PrefabEditByAdmin(
    $prefabId: ID!
    $prefabType: String!
    $title: String!
    $description: String!
    $descriptionPlainText: String!
    $internalNote: String
    $priceType: String!
    $cents: Int
    $estimatedCompletion: Int
    $expertId: ID
    $partnerId: ID
    $tagSkills: [String!]!
    $tagTools: [String!]!
  ) {
    prefabEditByAdmin(
      prefabId: $prefabId
      prefabType: $prefabType
      title: $title
      description: $description
      descriptionPlainText: $descriptionPlainText
      internalNote: $internalNote
      priceType: $priceType
      cents: $cents
      estimatedCompletion: $estimatedCompletion
      expertId: $expertId
      partnerId: $partnerId
      tagSkills: $tagSkills
      tagTools: $tagTools
    ) {
      ...ProjectPrefabDetailHq
    }
  }
  ${ProjectPrefabDetailHq}
`;

interface AdminPrefabDetailProps {
  prefabId: string;
}

const AdminPrefabDetail = ({ prefabId }: AdminPrefabDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const [actionLoading, setActionLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [editPrefabType, setEditPrefabType] = useState('GENERAL');
  const [editPrefabTitle, setEditPrefabTitle] = useState('');
  const [editPrefabDescription, setEditPrefabDescription] =
    useState<ContentState | null>(null);
  const [prefabInitDesc, setPrefabInitDesc] = useState('');
  const [editPrefabInternal, setEditPrefabInternal] =
    useState<ContentState | null>(null);
  const [prefabInitInternal, setPrefabInitInternal] = useState('');
  const [editPrefabPriceType, setEditPrefabPriceType] = useState('NONE');
  const [editPrefabCents, setEditPrefabCents] = useState('250');
  const [editPrefabEstimatedCompletion, setEditPrefabEstimatedCompletion] =
    useState('3');
  const priceVal =
    Math.round(parseFloat((editPrefabCents || '').replace(/\$/gi, '')) || 0) *
    100;
  const timelineVal = Math.round(
    parseFloat(editPrefabEstimatedCompletion || '') || 0,
  );
  const [primaryPanel, setPrimaryPanel] = useState('About');
  const {
    data: dataPrefab,
    error: errorPrefab,
    loading: loadingPrefab,
  } = useQuery<PrefabDetailsAdminQuery, PrefabDetailsAdminQueryVariables>(
    prefabDetailsAdminQuery,
    {
      returnPartialData: true,
      variables: {
        prefabId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorPrefab) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorPrefab) ||
      'Could not load prefab details';
  }
  const prefabDetails =
    dataPrefab && dataPrefab.prefabDetails && dataPrefab.prefabDetails.id
      ? dataPrefab.prefabDetails
      : undefined;
  const [tryEditPrefab] = useMutation<
    PrefabEditByAdminMutation,
    PrefabEditByAdminMutationVariables
  >(prefabEditByAdminMutation);
  function onSaveEditingInfo() {
    if (actionLoading || !prefabDetails) return;
    let draftContent = '';
    let plainTextContent = '';
    let draftContentInternal = '';
    if (editPrefabDescription && editPrefabDescription.hasText()) {
      plainTextContent = editPrefabDescription.getPlainText().trim();
      if (plainTextContent.replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(editPrefabDescription));
        } catch (rawError) {
          console.log('prefabDescription convertToRaw error', rawError);
        }
      }
    }
    if (
      editPrefabInternal &&
      editPrefabInternal.hasText() &&
      editPrefabInternal.getPlainText().trim().replace(/\s/gi, '')
    ) {
      try {
        draftContentInternal = JSON.stringify(convertToRaw(editPrefabInternal));
      } catch (rawError) {
        console.log('prefabInternal convertToRaw error', rawError);
      }
    }
    if (
      !editPrefabType ||
      !editPrefabTitle ||
      !draftContent ||
      !editPrefabPriceType ||
      (priceVal && priceVal < 0) ||
      (timelineVal && timelineVal < 0)
    ) {
      addNotification('Please submit all the fields!', undefined, 5000);
      return;
    }
    if (!prefabDetails.expert && editPrefabPriceType === 'EXACTLY') {
      addNotification(
        'Prefabs not associated with experts should not have a fixed price',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryEditPrefab({
      variables: {
        cents: priceVal,
        description: draftContent,
        descriptionPlainText: plainTextContent,
        estimatedCompletion: timelineVal,
        expertId: prefabDetails.expert ? prefabDetails.expert.id : null,
        internalNote: draftContentInternal,
        partnerId: prefabDetails.partner ? prefabDetails.partner.id : null,
        prefabId,
        prefabType: editPrefabType,
        priceType: editPrefabPriceType,
        tagSkills: [],
        tagTools: [],
        title: editPrefabTitle,
      },
    })
      .then(() => {
        setIsEditingInfo(false);
        setActionLoading(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Prefab Edit Error');
        logError(err, {
          component: 'AdminPrefabDetail',
          func: 'tryEditPrefab',
        });
      });
  }
  function startEditingInfo() {
    if (!prefabDetails) {
      return;
    }
    setIsEditingInfo(true);
    setEditPrefabType(prefabDetails.type || '');
    setEditPrefabTitle(prefabDetails.title || '');
    setPrefabInitDesc(prefabDetails.description || '');
    setEditPrefabDescription(null);
    setPrefabInitInternal(prefabDetails.internalNote || '');
    setEditPrefabInternal(null);
    setEditPrefabPriceType(prefabDetails.priceType || '');
    setEditPrefabCents('$' + centsDollarsRounded(prefabDetails.cents || 0));
    setEditPrefabEstimatedCompletion(
      prefabDetails.estimatedCompletion
        ? prefabDetails.estimatedCompletion.toString()
        : '3',
    );
  }
  const secondaryPanels = ['About'].filter((p) => p !== primaryPanel);
  if (loadingPrefab) {
    // ignore these
  }
  return (
    <div className="DashboardModal AdminPrefabDetailModal">
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/extras/prefabs')
              }
              className="ThreadDetailMainHeaderBack"
            >
              {prefabDetails ? prefabDetails.title || '' : 'Back'}
            </div>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody AdminPrefabDetailView">
            {!!prefabDetails && (
              <div className="AdminPrefabDetailViewDescription">
                <ReadOnlyEditor
                  className=""
                  content={prefabDetails.description || ''}
                />
              </div>
            )}
            {!!prefabDetails && !!prefabDetails.internalNote && (
              <div className="AdminPrefabDetailViewDescription">
                <ReadOnlyEditor
                  className=""
                  content={prefabDetails.internalNote || ''}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div className="ThreadDetailSidebar">
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => setPrimaryPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            <div className="ThreadDetailSidebarBodyPanels">
              {primaryPanel === 'About' && prefabDetails && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                  {isEditingInfo ? (
                    <div className="ThreadDetailSidebarAdminSection">
                      <div
                        className="ThreadDetailSidebarAdminSectionTopRightBtn"
                        onClick={() => setIsEditingInfo(false)}
                      >
                        cancel
                      </div>
                      <div className="ThreadDetailSidebarAdminForm">
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Prefab Title
                        </div>
                        <TextareaAutosize
                          type="text"
                          placeholder="Prefab Title"
                          spellCheck="true"
                          className="ThreadDetailSidebarAdminFormInput"
                          value={editPrefabTitle}
                          onChange={(e) => {
                            setEditPrefabTitle(e.currentTarget.value);
                          }}
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Prefab Description
                        </div>
                        <div className="ThreadDetailSidebarAdminFormRich">
                          <RichTextEditor
                            initContent={prefabInitDesc}
                            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                            onUpdated={setEditPrefabDescription}
                          />
                        </div>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Prefab Type
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editPrefabType}
                          onChange={(e) =>
                            setEditPrefabType(e.currentTarget.value)
                          }
                        >
                          <option value="REWRITE">Custom re-write</option>
                          <option value="GENERAL">General for all</option>
                        </select>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Estimated Deadline (Days)
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Estimated Deadline (Days)"
                          value={editPrefabEstimatedCompletion}
                          onChange={(e) =>
                            setEditPrefabEstimatedCompletion(
                              e.currentTarget.value,
                            )
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Price Type
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editPrefabPriceType}
                          onChange={(e) =>
                            setEditPrefabPriceType(e.currentTarget.value)
                          }
                        >
                          <option value="NONE">No Price</option>
                          {!!prefabDetails.expert && (
                            <option value="EXACTLY">Fixed Price</option>
                          )}
                          <option value="STARTING_AT">Starting At</option>
                        </select>
                        {editPrefabPriceType !== 'NONE' && (
                          <Fragment>
                            <div className="ThreadDetailSidebarAdminFormTitle">
                              Price $$
                            </div>
                            <input
                              className="ThreadDetailSidebarAdminFormInput"
                              type="text"
                              autoComplete="new-off"
                              spellCheck="false"
                              placeholder="Price $$"
                              value={editPrefabCents}
                              onChange={(e) =>
                                setEditPrefabCents(e.currentTarget.value)
                              }
                            />
                          </Fragment>
                        )}
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Internal Note? (only shown to experts)
                        </div>
                        <div className="ThreadDetailSidebarAdminFormRich">
                          <RichTextEditor
                            initContent={prefabInitInternal}
                            placeholder="Internal Note (optional)"
                            onUpdated={setEditPrefabInternal}
                          />
                        </div>
                        <div
                          className={
                            'ThreadDetailSidebarAdminFormAction ' +
                            (actionLoading
                              ? ' ThreadDetailSidebarAdminFormActionLoading '
                              : '')
                          }
                          onClick={onSaveEditingInfo}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      <div className="ThreadDetailSidebarAdminSection">
                        <div
                          className="ThreadDetailSidebarAdminSectionTopRightBtn"
                          onClick={startEditingInfo}
                        >
                          edit
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionTitle">
                          Prefab
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionBig">
                          {prefabDetails.title}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Type:{' '}
                          {prefabDetails.type === 'REWRITE'
                            ? 'Custom re-write'
                            : 'General project'}
                        </div>
                        {prefabDetails.createdAt && (
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Created:{' '}
                            {moment(prefabDetails.createdAt).format(
                              'MMMM Do, YYYY',
                            )}
                          </div>
                        )}
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Public Page:{' '}
                          {envUtils.pickWebAppUrlForUserType('web')}
                          {prefabDetails.expert &&
                          prefabDetails.expert.profilePath
                            ? '/experts/' + prefabDetails.expert.profilePath
                            : ''}
                          {prefabDetails.partner &&
                          prefabDetails.partner.trackingId
                            ? '/partners/' + prefabDetails.partner.trackingId
                            : ''}
                          /?p={prefabDetails.id}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Estimated Deadline (days):{' '}
                          {prefabDetails.estimatedCompletion || 'none'}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Price:{' '}
                          {prefabPriceStr(
                            prefabDetails.priceType,
                            prefabDetails.cents,
                          ) || 'none'}
                        </div>
                        {!!prefabDetails.wasDeleted && (
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Was deleted by expert: True
                          </div>
                        )}
                      </div>
                      {prefabDetails.partner && !prefabDetails.expert && (
                        <div className="ThreadDetailSidebarAdminSection">
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Partner
                          </div>
                          <Link
                            key={prefabDetails.partner.id}
                            to={'/extras/partners/' + prefabDetails.partner.id}
                            className="AdminLinkedRecord"
                          >
                            <div className="AdminLinkedRecordTitle">
                              {prefabDetails.partner.partnerName}
                            </div>
                            <div className="AdminLinkedRecordSubtitle">
                              {prefabDetails.partner.trackingId}
                            </div>
                            <div className="AdminLinkedRecordTag">Partner</div>
                          </Link>
                        </div>
                      )}
                      {prefabDetails.expert && !prefabDetails.partner && (
                        <div className="ThreadDetailSidebarAdminSection">
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Expert
                          </div>
                          <Link
                            key={prefabDetails.expert.id}
                            to={'/experts/' + prefabDetails.expert.id}
                            className="AdminLinkedRecord"
                          >
                            <div className="AdminLinkedRecordTitle">
                              {(prefabDetails.expert.firstName || '') +
                                ' ' +
                                (prefabDetails.expert.lastName || '')}
                            </div>
                            <div className="AdminLinkedRecordSubtitle">
                              {prefabDetails.expert.primaryPublicEmail}
                            </div>
                            <div className="AdminLinkedRecordTag">Expert</div>
                          </Link>
                        </div>
                      )}
                    </Fragment>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/extras/prefabs')
              }
              className="DashboardErrorCoverNav"
            >
              back
            </div>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminPrefabDetail.propTypes = {
  prefabId: PropTypes.string.isRequired,
};

export default AdminPrefabDetail;
