import tokenUtils from './token';
import envUtils from './env';
import urlUtils from './url';

const LOREM_TOKEN_KEY = 'lorem';
const LOREM_ENV_KEY = envUtils.pick('', '_staging', '_dev');

function getKey(userType: string) {
  return userType + '_' + LOREM_TOKEN_KEY + LOREM_ENV_KEY;
}

let cacheUserId = '';
function getCachedUserId() {
  return cacheUserId || '';
}

const tokenParam = urlUtils.getQueryParam('token');
let hasRunInit = false;
let cacheToken = '';
function getToken(userType: string) {
  if (!hasRunInit) {
    hasRunInit = true;
    if (tokenParam) {
      // if ?token, then write cookie
      tokenUtils.write(getKey(userType), tokenParam, 1000);
    }
    cacheToken = tokenParam || tokenUtils.read(getKey(userType)) || '';
  }
  return cacheToken;
}

function writeToken(token: string, userType: string, userId: string) {
  cacheUserId = userId;
  hasRunInit = true;
  if (token !== cacheToken) {
    cacheToken = token;
    tokenUtils.write(getKey(userType), token, 1000);
  }
  return cacheToken;
}

function logout(userType: string) {
  cacheUserId = '';
  hasRunInit = true;
  cacheToken = '';
  tokenUtils.erase(getKey(userType));
}

export default {
  getCachedUserId,
  getToken,
  logout,
  writeToken,
};
