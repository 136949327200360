import React, { useState, useContext, Fragment } from 'react';
import { useMutation, ApolloError, gql } from '@apollo/client';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import {
  AuthAdminEmailAccessMutation,
  AuthAdminEmailAccessMutationVariables,
} from '../../gql/graphql';
import logoWhite from '../../images/storetasker-logo-white-with.png';

const authAdminEmailAccessMutation = gql`
  mutation AuthAdminEmailAccess($email: String!) {
    authAdminEmailAccess(email: $email)
  }
`;

const AdminLogin = () => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [loginState, setLoginState] = useState('login');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [tryLogin] = useMutation<
    AuthAdminEmailAccessMutation,
    AuthAdminEmailAccessMutationVariables
  >(authAdminEmailAccessMutation, {
    variables: {
      email: email.trim(),
    },
  });

  function attemptLogin() {
    console.log('attemptLogin');
    if (loading) return;
    if (!email.trim()) {
      addNotification('Please enter your email.', undefined, 5000);
      return;
    }
    setLoading(true);
    tryLogin()
      .then(({ data }) => {
        setLoading(false);
        if (data && data.authAdminEmailAccess) {
          if (data.authAdminEmailAccess === 'SENT_LINK') {
            setLoginState('sent');
          } else {
            addNotification(
              'Error! Please check your login info and submit again.',
            );
          }
        } else {
          addNotification(
            'An error occured, please contact support@storetasker.com',
          );
          logError('Mutation missing result authAdminEmailAccess', {
            component: 'AdminLogin',
            func: 'authAdminEmailAccess',
          });
        }
      })
      .catch((err: ApolloError) => {
        setLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Login Error');
        logError(err, {
          component: 'AdminLogin',
          func: 'attemptLogin',
        });
      });
  }

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      attemptLogin();
    }
  }

  return (
    <div className="Login AdminLogin">
      <div className="LoginLogoWrap">
        <img className="LoginLogo" src={logoWhite} alt="Storetasker Logo" />
      </div>
      <div
        className={
          'LoginHeader ' +
          (loginState === 'login' ? '' : ' LoginHeaderWithout ')
        }
      >
        <div className="LoginHeaderIntro">fluorescent lighting is bad</div>
        <div className="LoginHeaderTitle">
          {loginState === 'login'
            ? 'Login to Admin Dashboard'
            : 'Check Your Email For Access'}
        </div>
      </div>
      <div className="LoginBody">
        {loginState === 'login' && (
          <Fragment>
            <div className="LoginField">
              <div className="LoginFieldName">Email</div>
              <input
                type="email"
                className="LoginFieldInput"
                placeholder="email address"
                spellCheck="false"
                value={email}
                onChange={(e) => setEmail(e.target.value || '')}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div
              className={'LoginBtn ' + (loading ? ' LoginBtnLoading ' : '')}
              onClick={attemptLogin}
            >
              Log in
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
