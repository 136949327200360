import React, { ReactNode, useState, useEffect } from 'react';
import {
  Editor,
  EditorState,
  ContentState,
  ContentBlock,
  CompositeDecorator,
  convertFromRaw,
  RawDraftContentState,
} from 'draft-js';
import PropTypes from 'prop-types';
import '../../styles/feature/ReadOnlyEditor.scss';

interface ReadOnlyEditorProps {
  className: string;
  content: string;
}

const ReadOnlyEditor = ({ className, content }: ReadOnlyEditorProps) => {
  const [editorState, setEditorState] = useState(() =>
    fromJSONEncodedString(content),
  );
  useEffect(() => {
    setEditorState(fromJSONEncodedString(content));
  }, [content]);
  return (
    <div className={'ReadOnlyEditor ' + className}>
      <Editor
        editorState={editorState}
        autoCorrect="off"
        autoComplete="off"
        spellCheck={false}
        readOnly
        onChange={() => {}}
      />
    </div>
  );
};

ReadOnlyEditor.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

interface LinkComponentProps {
  contentState: ContentState;
  entityKey: string;
  children: ReactNode | ReactNode[];
}

function blockClickPropagation(ev: React.MouseEvent) {
  if (ev) {
    // ev.preventDefault();
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();
  }
}
const Link = ({
  contentState: linkContentState,
  entityKey: linkEntityKey,
  children: linkChildren,
}: LinkComponentProps) => (
  <a
    href={linkContentState.getEntity(linkEntityKey).getData().url}
    className="link-entity"
    target="_blank"
    rel="noopener noreferrer"
    onClick={blockClickPropagation}
  >
    {linkChildren}
  </a>
);
const decorator = new CompositeDecorator([
  {
    component: Link,
    strategy: findLinkEntities,
  },
]);
function findLinkEntities(
  findContentBlock: ContentBlock,
  findCallback: (start: number, end: number) => void,
  findContentState: ContentState,
) {
  findContentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      findContentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, findCallback);
}
const fromJSONEncodedString = (jsonString: string) => {
  if (jsonString) {
    try {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(jsonString) as RawDraftContentState),
        decorator,
      );
    } catch (_e) {
      return EditorState.createWithContent(
        ContentState.createFromText(jsonString),
        decorator,
      );
    }
  }
  return EditorState.createWithContent(
    ContentState.createFromText(''),
    decorator,
  );
};

export default React.memo(ReadOnlyEditor);
