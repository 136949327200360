import _ from 'lodash';
import { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useQuery,
  useMutation,
  useSubscription,
  gql,
  ApolloError,
} from '@apollo/client';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  Link,
  NavLink,
  Route,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import apolloUtils, { ClientWithOnReconnected } from '../../utils/apollo';
import HumanHomescreen from './HumanHomescreen';
import HumanProjects from './HumanProjects';
import HumanExperts from './HumanExperts';
import HumanNewRequestFlow from './HumanNewRequestFlow';
import HumanRequestDetail from './HumanRequestDetail';
import HumanProjectDetail from './HumanProjectDetail';
import HumanExpertDetail from './HumanExpertDetail';
import HumanMatchDetail from './HumanMatchDetail';
import HumanAccountView from './HumanAccountView';
import HumanSupportView from './HumanSupportView';
import HumanUserBubble from '../feature/HumanUserBubble';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import {
  BrandForHumansQuery,
  BrandForHumansQueryVariables,
  RequestActiveForBrandsQuery,
  RequestActiveForBrandsQueryVariables,
  ProjectActiveForBrandsQuery,
  ProjectActiveForBrandsQueryVariables,
  MatchActiveForBrandsQuery,
  MatchActiveForBrandsQueryVariables,
  RequestUpdatedHumanSubscription,
  MatchUpdatedHumanSubscription,
  MeetingUpdatedHumanSubscription,
  ExpertUpdatedHumanSubscription,
  BrandUpdatedHumanSubscription,
  HumanUpdatedHumanSubscription,
  ProjectUpdatedHumanSubscription,
  QuoteUpdatedHumanSubscription,
  AuthHumanQuery,
  HumanCancelPendingPhoneMutation,
  HumanResendPendingPhoneMutation,
  HumanCreateSetupIntentUpgradeMutation,
  HumanConfirmSetupIntentUpgradeMutation,
  HumanConfirmSetupIntentUpgradeMutationVariables,
  HumanCreateSubscriptionIntentProMutation,
  HumanConfirmSubscriptionIntentProMutation,
  HumanConfirmSubscriptionIntentProMutationVariables,
} from '../../gql/graphql';
import errorUtils from '../../utils/error';
import logError from '../../utils/airbrake';
import urlUtils from '../../utils/url';
import { SUPPORT_EXPERT_ID } from '../../utils/constants';
import { validDiscountCode } from '../../utils/format';
import {
  ExpertDetailHuman,
  MatchDetail,
  BrandSummary,
  MeetingDetail,
  RequestDetail,
  RequestPartialTop,
  BrandDetail,
  HumanDetail,
  MatchPartialTop,
  ProjectPartialTop,
  ProjectDetailHuman,
  QuoteDetail,
} from '../../utils/gql';
import logoBlack from '../../images/storetasker-logo-black.png';
import logoWhite from '../../images/storetasker-logo-white.png';

const brandForHumansQuery = gql`
  query BrandForHumans($humanIds: [ID!]!) {
    brandForHumans(humanIds: $humanIds) {
      ...BrandSummary
    }
  }
  ${BrandSummary}
`;

const requestActiveForBrandsQuery = gql`
  query RequestActiveForBrands($brandIds: [ID!]!) {
    requestActiveForBrands(brandIds: $brandIds) {
      ...RequestPartialTop
    }
  }
  ${RequestPartialTop}
`;

const requestUpdatedHumanSubscription = gql`
  subscription RequestUpdatedHuman {
    requestUpdated {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const projectActiveForBrandsQuery = gql`
  query ProjectActiveForBrands($brandIds: [ID!]!) {
    projectActiveForBrands(brandIds: $brandIds) {
      ...ProjectPartialTop
    }
  }
  ${ProjectPartialTop}
`;

const matchActiveForBrandsQuery = gql`
  query MatchActiveForBrands($brandIds: [ID!]!) {
    matchActiveForBrands(brandIds: $brandIds) {
      ...MatchPartialTop
    }
  }
  ${MatchPartialTop}
`;

const projectUpdatedHumanSubscription = gql`
  subscription ProjectUpdatedHuman {
    projectUpdated {
      ...ProjectDetailHuman
    }
  }
  ${ProjectDetailHuman}
`;

const matchUpdatedHumanSubscription = gql`
  subscription MatchUpdatedHuman {
    matchUpdated {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const meetingUpdatedHumanSubscription = gql`
  subscription MeetingUpdatedHuman {
    meetingUpdated {
      ...MeetingDetail
    }
  }
  ${MeetingDetail}
`;

const expertUpdatedHumanSubscription = gql`
  subscription ExpertUpdatedHuman {
    expertUpdated {
      ...ExpertDetailHuman
    }
  }
  ${ExpertDetailHuman}
`;

const brandUpdatedHumanSubscription = gql`
  subscription BrandUpdatedHuman {
    brandUpdated {
      ...BrandDetail
    }
  }
  ${BrandDetail}
`;

const humanUpdatedHumanSubscription = gql`
  subscription HumanUpdatedHuman {
    humanUpdated {
      ...HumanDetail
    }
  }
  ${HumanDetail}
`;

const quoteUpdatedHumanSubscription = gql`
  subscription QuoteUpdatedHuman {
    quoteUpdated {
      ...QuoteDetail
    }
  }
  ${QuoteDetail}
`;

const humanCancelPendingPhoneMutation = gql`
  mutation HumanCancelPendingPhone {
    humanCancelPendingPhone {
      ...HumanDetail
    }
  }
  ${HumanDetail}
`;

const humanResendPendingPhoneMutation = gql`
  mutation HumanResendPendingPhone {
    humanResendPendingPhone
  }
`;

const humanCreateSetupIntentUpgradeMutation = gql`
  mutation HumanCreateSetupIntentUpgrade {
    humanCreateSetupIntent
  }
`;

const humanConfirmSetupIntentUpgradeMutation = gql`
  mutation HumanConfirmSetupIntentUpgrade($setupIntentId: String!) {
    humanConfirmSetupIntent(setupIntentId: $setupIntentId) {
      ...HumanDetail
    }
  }
  ${HumanDetail}
`;

const humanCreateSubscriptionIntentProMutation = gql`
  mutation HumanCreateSubscriptionIntentPro {
    humanCreateSubscriptionProIntent {
      clientSecret
      subscriptionId
    }
  }
`;

const humanConfirmSubscriptionIntentProMutation = gql`
  mutation HumanConfirmSubscriptionIntentPro($subscriptionId: String!) {
    humanConfirmSubscriptionProIntent(subscriptionId: $subscriptionId) {
      ...HumanDetail
    }
  }
  ${HumanDetail}
`;

const PATH_TO_CLASS = {
  account: 'HumanDashboardAccount',
  experts: 'HumanDashboardExperts',
  home: 'HumanDashboardHome',
  match: 'HumanDashboardMatch',
  new: 'HumanDashboardNew',
  projects: 'HumanDashboardProjects',
  requests: 'HumanDashboardRequests',
  support: 'HumanDashboardSupport',
} as { [key: string]: string };

const PATH_HAS_MODAL = [
  '/new',
  '/requests/',
  '/projects/',
  '/experts/',
  '/match/',
];

interface HumanDashboardProps {
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
  leadDiscountCode: string;
  socketClient: ClientWithOnReconnected;
}

const HumanDashboard = ({
  human,
  leadDiscountCode,
  socketClient,
}: HumanDashboardProps) => {
  const location = useLocation();
  const [resendOnce, setResendOnce] = useState(false);
  const [networkErrorMessage, setNetworkErrorMessage] = useState('');
  const [networkErrorType, setNetworkErrorType] = useState('');
  const { addNotification } = useContext(GlobalNotificationContext);
  const stripe = useStripe();
  const elements = useElements();
  const [showProModal, setShowProModal] = useState(() => {
    if (urlUtils.getQueryParam('upgrade') === 'true') return true;
    return false;
  });
  const [actionLoading, setActionLoading] = useState(false);
  const [switchPaymentMethod, setSwitchPaymentMethod] = useState(false);
  const [stripeElementError, setStripeElementError] = useState('');
  const [stripeCardReady, setStripeCardReady] = useState(false);
  const useExistingCard = !!(
    human.paymentMethodId &&
    human.cardBrand &&
    human.cardLast4 &&
    !switchPaymentMethod
  );
  const {
    loading: loadingBrands,
    error: errorBrands,
    data: dataBrands,
    refetch: refetchBrands,
    subscribeToMore: subscribeToMoreBrands,
  } = useQuery<BrandForHumansQuery, BrandForHumansQueryVariables>(
    brandForHumansQuery,
    {
      returnPartialData: true,
      variables: {
        humanIds: [human.id],
      },
    },
  );
  useEffect(() => {
    const unsub = subscribeToMoreBrands<BrandUpdatedHumanSubscription>({
      document: brandUpdatedHumanSubscription,
      onError: (err) => {
        addNotification(
          'An error occured, please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreBrands', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { brandUpdated },
          },
        },
      ) => {
        console.log('brandUpdated', prev, brandUpdated);
        if (!brandUpdated) {
          return prev;
        }
        const updatingIndex = (prev.brandForHumans || []).findIndex(
          (l) => l.id === brandUpdated.id,
        );
        if (updatingIndex >= 0) {
          // update existing
          return {
            brandForHumans: prev.brandForHumans
              .slice(0, updatingIndex)
              .concat({
                ...prev.brandForHumans[updatingIndex],
                ...brandUpdated,
              })
              .concat(prev.brandForHumans.slice(updatingIndex + 1)),
          };
        }
        // add new
        return {
          brandForHumans: (prev.brandForHumans || []).concat(brandUpdated),
        };
      },
    });
    return () => {
      unsub();
    };
  }, [addNotification, subscribeToMoreBrands]);
  let brandsForHuman: BrandForHumansQuery['brandForHumans'] = [];
  if (
    dataBrands &&
    dataBrands.brandForHumans &&
    dataBrands.brandForHumans.length
  ) {
    brandsForHuman = _.uniqBy(
      dataBrands.brandForHumans.filter(
        (b) => b.teamStr && b.teamStr.indexOf(human.id) >= 0,
      ),
      'id',
    );
  }
  const {
    loading: loadingRequestsActive,
    error: errorRequestsActive,
    data: dataRequestsActive,
    refetch: refetchRequestsActive,
    subscribeToMore: subscribeToMoreRequestsActive,
  } = useQuery<
    RequestActiveForBrandsQuery,
    RequestActiveForBrandsQueryVariables
  >(requestActiveForBrandsQuery, {
    returnPartialData: true,
    skip: !brandsForHuman.length,
    variables: {
      brandIds: brandsForHuman.map((b) => b.id),
    },
  });
  useEffect(() => {
    let unsub: () => void;
    if (subscribeToMoreRequestsActive) {
      unsub = subscribeToMoreRequestsActive<RequestUpdatedHumanSubscription>({
        document: requestUpdatedHumanSubscription,
        onError: (err) => {
          addNotification(
            'An error occured, please refresh and try again: ' + err.message,
          );
          console.error('onError: subscribeToMoreRequestsActive', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { requestUpdated },
            },
          },
        ) => {
          console.log('requestUpdated', prev, requestUpdated);
          if (!requestUpdated) {
            return prev;
          }
          const updatingIndex = (prev.requestActiveForBrands || []).findIndex(
            (l) => l.id === requestUpdated.id,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              requestActiveForBrands: prev.requestActiveForBrands
                .slice(0, updatingIndex)
                .concat({
                  ...prev.requestActiveForBrands[updatingIndex],
                  ...requestUpdated,
                })
                .concat(prev.requestActiveForBrands.slice(updatingIndex + 1)),
            };
          }
          // add new
          return {
            requestActiveForBrands: (prev.requestActiveForBrands || []).concat(
              requestUpdated,
            ),
          };
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [addNotification, subscribeToMoreRequestsActive]);
  const activeRequestsForBrands = brandsForHuman.length
    ? _.uniqBy(
        (
          (dataRequestsActive && dataRequestsActive.requestActiveForBrands) ||
          []
        ).filter(
          (r) =>
            r.isActive &&
            r.brandStr &&
            brandsForHuman.find((b) => b.id === r.brandStr),
        ),
        'id',
      )
    : [];
  const {
    loading: loadingProjectsActive,
    error: errorProjectsActive,
    refetch: refetchProjectsActive,
    subscribeToMore: subscribeToMoreProjectsActive,
  } = useQuery<
    ProjectActiveForBrandsQuery,
    ProjectActiveForBrandsQueryVariables
  >(projectActiveForBrandsQuery, {
    returnPartialData: true,
    skip: !brandsForHuman.length,
    variables: {
      brandIds: brandsForHuman.map((b) => b.id),
    },
  });
  useEffect(() => {
    let unsub: () => void;
    if (subscribeToMoreProjectsActive) {
      unsub = subscribeToMoreProjectsActive<ProjectUpdatedHumanSubscription>({
        document: projectUpdatedHumanSubscription,
        onError: (err) => {
          addNotification(
            'An error occured, please refresh and try again: ' + err.message,
          );
          console.error('onError: subscribeToMoreProjectsActive', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { projectUpdated },
            },
          },
        ) => {
          console.log('projectUpdated', prev, projectUpdated);
          if (!projectUpdated) {
            return prev;
          }
          const updatingIndex = (prev.projectActiveForBrands || []).findIndex(
            (l) => l.id === projectUpdated.id,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              projectActiveForBrands: prev.projectActiveForBrands
                .slice(0, updatingIndex)
                .concat({
                  ...prev.projectActiveForBrands[updatingIndex],
                  ...projectUpdated,
                })
                .concat(prev.projectActiveForBrands.slice(updatingIndex + 1)),
            };
          }
          // add new
          return {
            projectActiveForBrands: (prev.projectActiveForBrands || []).concat(
              projectUpdated,
            ),
          };
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [addNotification, subscribeToMoreProjectsActive]);
  const {
    loading: loadingMatchesActive,
    error: errorMatchesActive,
    data: dataMatchesActive,
    refetch: refetchMatchesActive,
    subscribeToMore: subscribeToMoreMatchesActive,
  } = useQuery<MatchActiveForBrandsQuery, MatchActiveForBrandsQueryVariables>(
    matchActiveForBrandsQuery,
    {
      returnPartialData: true,
      skip: !brandsForHuman.length,
      variables: {
        brandIds: brandsForHuman.map((b) => b.id),
      },
    },
  );
  useEffect(() => {
    let unsub: () => void;
    if (subscribeToMoreMatchesActive) {
      unsub = subscribeToMoreMatchesActive<MatchUpdatedHumanSubscription>({
        document: matchUpdatedHumanSubscription,
        onError: (err) => {
          addNotification(
            'An error occured, please refresh and try again: ' + err.message,
          );
          console.error('onError: subscribeToMoreMatchesActive', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { matchUpdated },
            },
          },
        ) => {
          console.log('matchUpdated', prev, matchUpdated);
          if (!matchUpdated) {
            return prev;
          }
          const updatingIndex = (prev.matchActiveForBrands || []).findIndex(
            (l) => l.id === matchUpdated.id,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              matchActiveForBrands: prev.matchActiveForBrands
                .slice(0, updatingIndex)
                .concat({
                  ...prev.matchActiveForBrands[updatingIndex],
                  ...matchUpdated,
                })
                .concat(prev.matchActiveForBrands.slice(updatingIndex + 1)),
            };
          }
          // add new
          return {
            matchActiveForBrands: (prev.matchActiveForBrands || []).concat(
              matchUpdated,
            ),
          };
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [addNotification, subscribeToMoreMatchesActive]);
  const activeMatchesForBrands = brandsForHuman.length
    ? _.uniqBy(
        (
          (dataMatchesActive && dataMatchesActive.matchActiveForBrands) ||
          []
        ).filter(
          (m) =>
            m.expertStr !== SUPPORT_EXPERT_ID &&
            m.brandStr &&
            brandsForHuman.find((b) => b.id === m.brandStr),
        ),
        'id',
      )
    : [];
  useEffect(() => {
    let networkTimeout: NodeJS.Timeout | undefined;
    function removeNetworkError() {
      showNetworkError('');
    }
    function showNetworkError(
      message: string,
      status?: string,
      timeout?: number,
    ) {
      if (networkTimeout) {
        clearTimeout(networkTimeout);
      }
      setNetworkErrorType(message ? status || 'BAD' : '');
      setNetworkErrorMessage(message || '');
      if (timeout) {
        networkTimeout = setTimeout(removeNetworkError, timeout);
      }
    }
    const connectingListener = socketClient.on('connecting', () => {
      console.log('HumanDashboard socketClient onConnecting');
    });
    const connectedListener = socketClient.on('connected', () => {
      console.log('HumanDashboard socketClient onConnected');
      removeNetworkError();
    });
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('HumanDashboard socketClient onReconnected');
      showNetworkError('Reconnected!', 'GOOD', 1500);
      refetchBrands().catch(() => {});
      refetchRequestsActive().catch(() => {});
      refetchProjectsActive().catch(() => {});
      refetchMatchesActive().catch(() => {});
    });
    const disconnectedListener = socketClient.on('closed', () => {
      console.log('HumanDashboard socketClient onDisconnected');
      if (!apolloUtils.getClosedOnPurpose()) {
        showNetworkError('Connection Error. Trying to reconnect.');
      }
    });
    const errorListener = socketClient.on('error', () => {
      console.log('HumanDashboard socketClient onError');
      if (!apolloUtils.getClosedOnPurpose()) {
        showNetworkError('Connection Error. Trying to reconnect.');
      }
    });
    return () => {
      connectingListener();
      connectedListener();
      reconnectedListener();
      disconnectedListener();
      errorListener();
    };
  }, [
    socketClient,
    refetchBrands,
    refetchRequestsActive,
    refetchProjectsActive,
    refetchMatchesActive,
  ]);
  useSubscription<MeetingUpdatedHumanSubscription>(
    meetingUpdatedHumanSubscription,
  );
  useSubscription<ExpertUpdatedHumanSubscription>(
    expertUpdatedHumanSubscription,
  );
  useSubscription<HumanUpdatedHumanSubscription>(humanUpdatedHumanSubscription);
  useSubscription<QuoteUpdatedHumanSubscription>(quoteUpdatedHumanSubscription);
  const [tryResendPendingPhone] = useMutation<HumanResendPendingPhoneMutation>(
    humanResendPendingPhoneMutation,
  );
  function resendPendingPhone() {
    if (!human) return;
    setResendOnce(true);
    tryResendPendingPhone().catch((error: ApolloError) => {
      logError(error, {
        component: 'HumanDashboard',
        func: 'tryResendPendingPhone',
      });
    });
  }
  const [tryCancelPendingPhone] = useMutation<HumanCancelPendingPhoneMutation>(
    humanCancelPendingPhoneMutation,
  );
  function cancelPendingPhone() {
    if (!human) return;
    setResendOnce(false);
    tryCancelPendingPhone({
      optimisticResponse: {
        humanCancelPendingPhone: {
          ...human,
          pendingPhone: null,
        },
      },
    }).catch((error: ApolloError) => {
      logError(error, {
        component: 'HumanDashboard',
        func: 'tryCancelPendingPhone',
      });
    });
  }
  function handleStripeElementChange(event: StripeCardElementChangeEvent) {
    setStripeCardReady(!event.empty && !event.error);
    setStripeElementError(event.error ? event.error.message : '');
  }
  function stopProUpgrade() {
    setSwitchPaymentMethod(false);
    setStripeElementError('');
    setStripeCardReady(false);
    setShowProModal(false);
  }
  const upgradeIsReadyForSubmit = !!(
    human &&
    elements &&
    stripe &&
    (useExistingCard || stripeCardReady)
  );
  const [tryCreateSetupIntent] =
    useMutation<HumanCreateSetupIntentUpgradeMutation>(
      humanCreateSetupIntentUpgradeMutation,
    );
  const [tryConfirmSetupIntent] = useMutation<
    HumanConfirmSetupIntentUpgradeMutation,
    HumanConfirmSetupIntentUpgradeMutationVariables
  >(humanConfirmSetupIntentUpgradeMutation);
  const [tryCreateSubscription] =
    useMutation<HumanCreateSubscriptionIntentProMutation>(
      humanCreateSubscriptionIntentProMutation,
    );
  const [tryConfirmSubscription] = useMutation<
    HumanConfirmSubscriptionIntentProMutation,
    HumanConfirmSubscriptionIntentProMutationVariables
  >(humanConfirmSubscriptionIntentProMutation);
  function tryAcceptUpgradeSubscription(paymentMethodId: string) {
    if (!elements || !stripe) {
      addNotification(
        'An error occured, please refresh and try again',
        undefined,
        5000,
      );
      setActionLoading(false);
      return;
    }
    tryCreateSubscription()
      .then(({ data }) => {
        if (
          !data ||
          !data.humanCreateSubscriptionProIntent ||
          !data.humanCreateSubscriptionProIntent.subscriptionId
        ) {
          setActionLoading(false);
          addNotification(
            'An error occured, please refresh and try again',
            undefined,
            5000,
          );
          return;
        }
        if (data.humanCreateSubscriptionProIntent.clientSecret) {
          stripe
            .confirmCardPayment(
              data.humanCreateSubscriptionProIntent.clientSecret,
              {
                payment_method: paymentMethodId,
                setup_future_usage: 'off_session',
              },
            )
            .then(({ error, paymentIntent }) => {
              if (
                paymentIntent &&
                paymentIntent.status === 'succeeded' &&
                paymentIntent.id
              ) {
                tryConfirmSubscription({
                  variables: {
                    subscriptionId:
                      data.humanCreateSubscriptionProIntent.subscriptionId,
                  },
                })
                  .then(() => {
                    setActionLoading(false);
                    stopProUpgrade();
                  })
                  .catch((err: ApolloError) => {
                    setActionLoading(false);
                    addNotification(
                      errorUtils.getErrorMessage(err) ||
                        'Subscription Confirm Error',
                    );
                    logError(err, {
                      component: 'ProjectDetailView',
                      func: 'tryConfirmSubscription',
                    });
                  });
                return;
              }
              setActionLoading(false);
              addNotification(
                error && error.message
                  ? error.message
                  : 'An error occured, please try again',
              );
              logError(
                (error && error.message) ||
                  'Confirm Subscription Payment Error',
                {
                  component: 'ProjectDetailView',
                  func: 'confirmCardPayment - subscription',
                },
              );
            })
            .catch((err: Error) => {
              setActionLoading(false);
              addNotification(err.message || 'Subscription Intent Error');
              logError(err, {
                component: 'ProjectDetailView',
                func: 'confirmCardPayment - subscription',
              });
            });
        } else {
          tryConfirmSubscription({
            variables: {
              subscriptionId:
                data.humanCreateSubscriptionProIntent.subscriptionId,
            },
          })
            .then(() => {
              setActionLoading(false);
              stopProUpgrade();
            })
            .catch((err: ApolloError) => {
              setActionLoading(false);
              addNotification(
                errorUtils.getErrorMessage(err) || 'Subscription Confirm Error',
              );
              logError(err, {
                component: 'ProjectDetailView',
                func: 'tryConfirmSubscription',
              });
            });
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Subscription Create Error',
        );
        logError(err, {
          component: 'ProjectDetailView',
          func: 'tryCreateSubscription',
        });
      });
  }
  function tryProUpgrade() {
    if (actionLoading) return;
    if (!elements || !stripe) {
      addNotification(
        'An error occured, please refresh and try again',
        undefined,
        5000,
      );
      return;
    }
    if (!useExistingCard && !stripeCardReady) {
      addNotification(
        'Please fill out your credit card details in order to upgrade.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    if (useExistingCard) {
      tryAcceptUpgradeSubscription(human.paymentMethodId!);
    } else {
      tryCreateSetupIntent()
        .then(({ data }) => {
          if (!data || !data.humanCreateSetupIntent) {
            setActionLoading(false);
            addNotification(
              'An error occured, please refresh and try again',
              undefined,
              5000,
            );
            return;
          }
          stripe
            .confirmCardSetup(data.humanCreateSetupIntent, {
              payment_method: {
                card: elements.getElement(CardElement)!,
              },
            })
            .then(({ error, setupIntent }) => {
              if (
                setupIntent &&
                setupIntent.status === 'succeeded' &&
                setupIntent.id
              ) {
                tryConfirmSetupIntent({
                  variables: {
                    setupIntentId: setupIntent.id,
                  },
                })
                  .then(({ data: dataTempHuman }) => {
                    if (
                      dataTempHuman &&
                      dataTempHuman.humanConfirmSetupIntent &&
                      dataTempHuman.humanConfirmSetupIntent.paymentMethodId
                    ) {
                      tryAcceptUpgradeSubscription(
                        dataTempHuman.humanConfirmSetupIntent.paymentMethodId,
                      );
                      return;
                    }
                    setActionLoading(false);
                    addNotification(
                      'An error occured, please refresh and try again',
                      undefined,
                      5000,
                    );
                    logError('Setup Intent Confirm Error', {
                      component: 'HumanDashboard',
                      func: 'tryConfirmSetupIntent',
                    });
                  })
                  .catch((err: ApolloError) => {
                    setActionLoading(false);
                    addNotification(
                      errorUtils.getErrorMessage(err) ||
                        'Setup Intent Confirm Error',
                    );
                    logError(err, {
                      component: 'HumanDashboard',
                      func: 'tryConfirmSetupIntent',
                    });
                  });
                return;
              }
              setActionLoading(false);
              addNotification(
                error && error.message
                  ? error.message
                  : 'An error occured, please try again',
              );
              logError((error && error.message) || 'Confirm Card Setup Error', {
                component: 'HumanDashboard',
                func: 'confirmCardSetup',
              });
            })
            .catch((err: Error) => {
              setActionLoading(false);
              addNotification(err.message || 'Setup Intent Error');
              logError(err, {
                component: 'HumanDashboard',
                func: 'confirmCardSetup',
              });
            });
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Card Setup Error',
          );
          logError(err, {
            component: 'HumanDashboard',
            func: 'tryCreateSetupIntent',
          });
        });
    }
  }
  const loadingError =
    errorBrands ||
    errorRequestsActive ||
    errorProjectsActive ||
    errorMatchesActive
      ? errorUtils.getErrorMessage(
          errorBrands ||
            errorRequestsActive ||
            errorProjectsActive ||
            errorMatchesActive!,
        )
      : '';
  if (
    loadingBrands &&
    loadingRequestsActive &&
    loadingProjectsActive &&
    loadingMatchesActive
  ) {
    // ignore these
  }
  const hasDiscountCode = validDiscountCode(
    (human && human.discountCode) || leadDiscountCode,
  );
  const pathnameClean = (location.pathname || '').trim().toLowerCase();
  const pathBase = pathnameClean.split('/')[1];
  const pathClass = PATH_TO_CLASS[pathBase] || '';
  const hasModal = PATH_HAS_MODAL.find((o) => pathnameClean.indexOf(o) === 0);
  return (
    <div
      className={
        'Dashboard HumanDashboard ' +
        pathClass +
        ' ' +
        (hasModal ? ' HumanDashboardWithModal ' : '') +
        ' '
      }
    >
      <div className="DashboardNav">
        <div className="DashboardNavHeader">
          <Link className="DashboardNavHeaderLeft" to="/home">
            <img
              className="DashboardNavHeaderLogo DashboardNavHeaderLogoWhite"
              src={logoWhite}
              alt="Storetasker Logo"
            />
            <img
              className="DashboardNavHeaderLogo DashboardNavHeaderLogoBlack"
              src={logoBlack}
              alt="Storetasker Logo"
            />
          </Link>
          <Link className="DashboardNavHeaderRight" to="/account">
            <HumanUserBubble
              className="DashboardNavHeaderProfile"
              firstName={human.firstName}
              lastName={human.lastName}
              fallbackEmail={human.primaryEmail}
              fallbackPhone={human.primaryPhone}
              hideTooltip
              initialsOnly
              primary
            />
          </Link>
        </div>
        <div className="DashboardNavIntro">
          <Switch location={location}>
            <Route path="/home">
              <div className="DashboardNavIntroHeader">Welcome Back</div>
              <div className="DashboardNavIntroBody">
                Access world-class freelance Shopify developers, designers, and
                marketers.
              </div>
            </Route>
            <Route path="/new">
              <div className="DashboardNavIntroHeader"></div>
              <div className="DashboardNavIntroBody"></div>
            </Route>
            <Route path="/requests">
              <div className="DashboardNavIntroHeader"></div>
              <div className="DashboardNavIntroBody"></div>
            </Route>
            <Route path="/match">
              <div className="DashboardNavIntroHeader"></div>
              <div className="DashboardNavIntroBody"></div>
            </Route>
            <Route path="/projects">
              <div className="DashboardNavIntroHeader">E-commerce Projects</div>
              <div className="DashboardNavIntroBody">
                Our experts are ready to help tackle your project roadmap.
                What&apos;s next?
              </div>
            </Route>
            <Route path="/experts">
              <div className="DashboardNavIntroHeader">Storetasker Experts</div>
              <div className="DashboardNavIntroBody">
                Add extraordinary Shopify developers, designers, and marketers
                to your team.
              </div>
            </Route>
            <Route path="/support">
              <div className="DashboardNavIntroHeader">Storetasker Support</div>
              <div className="DashboardNavIntroBody">
                We&apos;re here to help with your freelance e-comm talent needs
                at any time.
              </div>
            </Route>
            <Route path="/account">
              <div className="DashboardNavIntroHeader">Account Settings</div>
              <div className="DashboardNavIntroBody">
                You can manage your brands, payment info, and account details
                here.
              </div>
            </Route>
          </Switch>
        </div>
        <div className="DashboardNavLinks">
          <div className="DashboardNavLinkPointer" />
          <NavLink className="DashboardNavLink DashboardNavLinkHome" to="/home">
            <span className="DashboardNavLinkText">Home</span>
          </NavLink>
          <NavLink className="DashboardNavLink" to="/projects">
            <span className="DashboardNavLinkText">Projects</span>
          </NavLink>
          <NavLink className="DashboardNavLink" to="/experts">
            <span className="DashboardNavLinkText">Experts</span>
          </NavLink>
          <NavLink className="DashboardNavLink" to="/support">
            <span className="DashboardNavLinkText">Support</span>
          </NavLink>
          <a
            className="DashboardNavLink DashboardNavLinkPerks"
            href="https://resources.storetasker.com/perks-of-storetasker"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="DashboardNavLinkText">Exclusive Perks</span>
          </a>
          {!human.proStart && (
            <div
              className="DashboardNavLink DashboardNavLinkPro"
              onClick={() => setShowProModal(true)}
            >
              <span className="DashboardNavLinkText">Storetasker Pro</span>
            </div>
          )}
        </div>
        <div className="DashboardNavFooter">
          <NavLink className="DashboardNavFooterLink" to="/account">
            {(human.firstName || '') + ' ' + (human.lastName || '')}
          </NavLink>
        </div>
      </div>
      <div className="DashboardMain">
        <Switch location={location}>
          <Route path="/home">
            <HumanHomescreen
              brands={brandsForHuman}
              hasDiscountCode={hasDiscountCode}
              human={human}
              showUpgradeModal={() => setShowProModal(true)}
              socketClient={socketClient}
            />
          </Route>
          <Route path="/projects">
            <HumanProjects
              brands={brandsForHuman}
              hasDiscountCode={hasDiscountCode}
              human={human}
              socketClient={socketClient}
            />
          </Route>
          <Route path="/experts">
            <HumanExperts
              activeRequests={activeRequestsForBrands}
              brands={brandsForHuman}
              human={human}
              socketClient={socketClient}
            />
          </Route>
          <Route path="/support">
            <HumanSupportView brands={brandsForHuman} human={human} />
          </Route>
          <Route path="/account">
            <HumanAccountView
              brands={brandsForHuman}
              hasDiscountCode={hasDiscountCode}
              human={human}
              isShowingUpgradeModal={!!showProModal}
              showUpgradeModal={() => setShowProModal(true)}
            />
          </Route>
          <Route path="/new">
            <div className="NewRequestScreen" />
          </Route>
          <Route path="/requests">
            <div className="RequestDetailScreen" />
          </Route>
          <Route path="/match">
            <div className="RequestDetailScreen" />
          </Route>
          <Redirect to="/experts" />
        </Switch>
      </div>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/new"
            render={() => (
              <HumanNewRequestFlow
                activeRequests={activeRequestsForBrands}
                brands={brandsForHuman}
                expertsForBrands={_.uniq(
                  activeMatchesForBrands.map((m) => m.expertStr),
                )}
                hasDiscountCode={hasDiscountCode}
                human={human}
                showUpgradeModal={() => setShowProModal(true)}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/requests/:id"
            render={(routeProps) => (
              <HumanRequestDetail
                activeRequests={activeRequestsForBrands}
                requestId={routeProps.match.params.id}
                brands={brandsForHuman}
                hasDiscountCode={hasDiscountCode}
                human={human}
                showUpgradeModal={() => setShowProModal(true)}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/projects/:id"
            render={(routeProps) => (
              <HumanProjectDetail
                activeRequests={activeRequestsForBrands}
                projectId={routeProps.match.params.id}
                brands={brandsForHuman}
                hasDiscountCode={hasDiscountCode}
                human={human}
                showUpgradeModal={() => setShowProModal(true)}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/experts/:id"
            render={(routeProps) => (
              <HumanExpertDetail
                activeMatches={activeMatchesForBrands}
                activeRequests={activeRequestsForBrands}
                brands={brandsForHuman}
                expertId={routeProps.match.params.id}
                human={human}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/match/:id"
            render={(routeProps) => (
              <HumanMatchDetail
                human={human}
                matchId={routeProps.match.params.id}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      {!!networkErrorMessage && (
        <div
          className={
            'AppDashboardNetworkError AppDashboardNetworkError' +
            (networkErrorType || 'BAD')
          }
          onClick={() => setNetworkErrorMessage('')}
        >
          {networkErrorMessage}
        </div>
      )}
      {!loadingError && !!human && !!human.pendingPhone && (
        <div className="DashboardErrorCover DashboardErrorCoverPending">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div className="DashboardErrorCoverPopTitle">
              Verify your phone number!
            </div>
            <div className="DashboardErrorCoverPopText">
              We recently sent you a text. Once we receive a message back from
              you, we&apos;ll confirm your number and hide this popup.
              <br />
              <br />
              Unfortunately, we aren&apos;t able to text with some international
              numbers, so if you don&apos;t get a message from us or we
              don&apos;t get a text back from you within a few mintues, you
              should click &quot;cancel verification&quot; below and we should
              just stick with email for now.
            </div>
            <div>
              <div
                className="DashboardErrorCoverPopBtn"
                onClick={cancelPendingPhone}
              >
                cancel verification
              </div>
              {!resendOnce && (
                <div
                  className="DashboardErrorCoverPopBtn"
                  onClick={resendPendingPhone}
                >
                  re-send verification
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!loadingError && !!human && !human.proStart && !!showProModal && (
        <div className="DashboardProModalCover">
          <div
            className="DashboardProModalCoverOver"
            onClick={stopProUpgrade}
          />
          <div className="DashboardProModal">
            <div className="DashboardProModalClose" onClick={stopProUpgrade} />
            <div className="DashboardProModalTitle">
              Upgrade to Storetasker Pro
            </div>
            <div className="DashboardProModalCompare">
              <div className="DashboardProModalCompareBox DashboardProModalCompareBoxLeft">
                <div className="DashboardProModalCompareBoxTitle">
                  Storetasker Pro
                </div>
                <div className="DashboardProModalCompareBoxBullets">
                  <div className="DashboardProModalCompareBoxBulletItem">
                    Multiple expert intro&apos;s upfront
                  </div>
                  <div className="DashboardProModalCompareBoxBulletItem">
                    No 3.5% fee on projects
                  </div>
                  <div className="DashboardProModalCompareBoxBulletItem">
                    Filter by location and experience
                  </div>
                  <div className="DashboardProModalCompareBoxBulletItem">
                    Suggested freelancers to hire
                  </div>
                </div>
              </div>
              <div className="DashboardProModalCompareBox">
                <div className="DashboardProModalCompareBoxTitle">Standard</div>
                <div className="DashboardProModalCompareBoxBullets">
                  <div className="DashboardProModalCompareBoxBulletItem">
                    1 expert intro at a time
                  </div>
                  <div className="DashboardProModalCompareBoxBulletItem">
                    3.5% fee on projects
                  </div>
                  <div className="DashboardProModalCompareBoxBulletItem">
                    Standard matching filters
                  </div>
                  <div className="DashboardProModalCompareBoxBulletItem DashboardProModalCompareBoxBulletItemNone" />
                  <div className="DashboardProModalCompareBoxBulletItem DashboardProModalCompareBoxBulletItemNone" />
                </div>
              </div>
            </div>
            <div className="DashboardProModalPay">
              <div className="ProjectDetailPayment">
                {useExistingCard ? (
                  <div className="ProjectDetailPaymentStep">
                    <div className="ProjectDetailPaymentStepTitle">
                      Payment Method
                    </div>
                    <div className="ProjectDetailPaymentMethod">
                      <div className="ProjectDetailPaymentMethodDetail">
                        {human.cardBrand} - {human.cardLast4}
                      </div>
                      <div
                        className="ProjectDetailPaymentMethodSwitch"
                        onClick={() => setSwitchPaymentMethod(true)}
                      >
                        change
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="ProjectDetailPaymentStep">
                    <div className="ProjectDetailPaymentStepTitle">
                      Pay By Card
                    </div>
                    <div className="ProjectDetailPaymentCard">
                      <CardElement
                        options={{
                          style: {
                            base: {
                              fontFamily:
                                'Roboto, Open Sans, Segoe UI, sans-serif',
                              fontSize: '16px',
                            },
                          },
                        }}
                        onChange={handleStripeElementChange}
                      />
                    </div>
                    {!!stripeElementError && (
                      <div className="ProjectDetailPaymentCardError">
                        {stripeElementError}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="DashboardProModalPayFooter">
                <div
                  className={
                    'DashboardProModalPayBtn ' +
                    (!upgradeIsReadyForSubmit
                      ? ' DashboardProModalPayBtnInvalid '
                      : '') +
                    (actionLoading ? ' DashboardProModalPayBtnLoading ' : '')
                  }
                  onClick={tryProUpgrade}
                >
                  Upgrade
                </div>
                <div className="DashboardProModalPayText">
                  You will be billed <span>$99 monthly</span> and can cancel
                  anytime
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!!loadingError && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div className="DashboardErrorCoverContent">{loadingError}</div>
          </div>
        </div>
      )}
    </div>
  );
};

HumanDashboard.propTypes = {
  human: PropTypes.object.isRequired,
  leadDiscountCode: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default HumanDashboard;
