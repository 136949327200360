import { useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql, ApolloError } from '@apollo/client';
import { Link } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import {
  AuthHumanQuery,
  BrandForHumansQuery,
  HumanEditMutation,
  HumanEditMutationVariables,
  HumanCreateSetupIntentSettingsMutation,
  HumanConfirmSetupIntentSettingsMutation,
  HumanConfirmSetupIntentSettingsMutationVariables,
  HumanCancelSubscriptionProMutation,
  BrandEditByHumanSettingsMutation,
  BrandEditByHumanSettingsMutationVariables,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import PhoneNumberInput from '../feature/PhoneNumberInput';
import {
  formatValidPhoneNumber,
  phoneErrorSubtitle,
  phoneErrorTitle,
  notificationDiscountCode,
} from '../../utils/format';
import { HumanDetail, BrandSummary } from '../../utils/gql';
import errorUtils from '../../utils/error';
import logError from '../../utils/airbrake';
import oauthUtils from '../../utils/oauth';
import logo from '../../images/storetasker-logo-black.png';
import '../../styles/page/HumanAccountView.scss';

const humanEditMutation = gql`
  mutation HumanEdit(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $secondaryEmails: [String!]!
  ) {
    humanEditWithStatus(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      secondaryEmails: $secondaryEmails
    ) {
      phoneStatus
      human {
        ...HumanDetail
      }
    }
  }
  ${HumanDetail}
`;

const humanCreateSetupIntentSettingsMutation = gql`
  mutation HumanCreateSetupIntentSettings {
    humanCreateSetupIntent
  }
`;

const humanConfirmSetupIntentSettingsMutation = gql`
  mutation HumanConfirmSetupIntentSettings($setupIntentId: String!) {
    humanConfirmSetupIntent(setupIntentId: $setupIntentId) {
      ...HumanDetail
    }
  }
  ${HumanDetail}
`;

const humanCancelSubscriptionProMutation = gql`
  mutation HumanCancelSubscriptionPro {
    humanCancelSubscriptionPro {
      ...HumanDetail
    }
  }
  ${HumanDetail}
`;

const brandEditByHumanSettingsMutation = gql`
  mutation BrandEditByHumanSettings(
    $brandId: ID!
    $name: String!
    $url: String!
  ) {
    brandEditByHuman(brandId: $brandId, name: $name, url: $url) {
      ...BrandSummary
    }
  }
  ${BrandSummary}
`;

interface HumanAccountViewProps {
  brands: BrandForHumansQuery['brandForHumans'];
  hasDiscountCode: string;
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
  isShowingUpgradeModal: boolean;
  showUpgradeModal: () => void;
}

const HumanAccountView = ({
  brands,
  hasDiscountCode,
  human,
  isShowingUpgradeModal,
  showUpgradeModal,
}: HumanAccountViewProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const stripe = useStripe();
  const elements = useElements();
  const [actionLoading, setActionLoading] = useState(false);
  const [cardActionLoading, setCardActionLoading] = useState(false);
  const [proActionLoading, setProActionLoading] = useState(false);
  const [isEditingHuman, setIsEditingHuman] = useState(true);
  const [editHumanFirst, setEditHumanFirst] = useState(human.firstName || '');
  const [editHumanLast, setEditHumanLast] = useState(human.lastName || '');
  const [editHumanPhone, setEditHumanPhone] = useState(
    human.primaryPhone || '',
  );
  const [editHumanPhoneValid, setEditHumanPhoneValid] = useState(true);
  const [editHumanEmail, setEditHumanEmail] = useState(
    human.primaryEmail || '',
  );
  const [editHumanSecondaryEmails, setEditHumanSecondaryEmails] = useState(
    (human.secondaryEmails || []).filter((se) => se !== human.primaryEmail),
  );
  const [switchPaymentMethod, setSwitchPaymentMethod] = useState(false);
  const [stripeElementError, setStripeElementError] = useState('');
  const [stripeCardReady, setStripeCardReady] = useState(false);
  const [brandEditId, setBrandEditId] = useState('');
  const [brandEditName, setBrandEditName] = useState('');
  const [brandEditUrl, setBrandEditUrl] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [tryEditHuman] = useMutation<
    HumanEditMutation,
    HumanEditMutationVariables
  >(humanEditMutation);
  function saveHumanEdit() {
    if (actionLoading) return;
    if (!editHumanFirst || !editHumanLast || !editHumanEmail) {
      addNotification(
        'Please submit your name and a valid email address!',
        undefined,
        5000,
      );
      return;
    }
    if (editHumanPhone && !editHumanPhoneValid) {
      addNotification('Please submit a valid phone number!', undefined, 5000);
      return;
    }
    setActionLoading(true);
    setPhoneError('');
    tryEditHuman({
      optimisticResponse: {
        humanEditWithStatus: {
          __typename: 'HumanWithPhoneStatus',
          human: {
            ...human,
            firstName: editHumanFirst,
            lastName: editHumanLast,
            primaryEmail: editHumanEmail,
            primaryPhone: editHumanPhone,
            secondaryEmails: editHumanSecondaryEmails.filter(
              (se) => !!se && !!se.trim(),
            ),
          },
          phoneStatus: 'CONFIRMED',
        },
      },
      variables: {
        email: editHumanEmail,
        firstName: editHumanFirst,
        lastName: editHumanLast,
        phone: editHumanPhone,
        secondaryEmails: editHumanSecondaryEmails.filter(
          (se) => !!se && !!se.trim(),
        ),
      },
    })
      .then(({ data }) => {
        setActionLoading(false);
        if (
          data &&
          data.humanEditWithStatus &&
          data.humanEditWithStatus.phoneStatus
        ) {
          if (data.humanEditWithStatus.phoneStatus === 'PRE_REGISTER') {
            setPhoneError('PRE_REGISTER');
          } else if (data.humanEditWithStatus.phoneStatus === 'NOT_MOBILE') {
            setPhoneError('NOT_MOBILE');
          }
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Save Settings Error',
        );
        logError(err, {
          component: 'HumanAccountView',
          func: 'tryEditHuman',
        });
      });
    setIsEditingHuman(false);
  }
  function startHumanEdit() {
    setIsEditingHuman(true);
    setEditHumanFirst(human.firstName || '');
    setEditHumanLast(human.lastName || '');
    setEditHumanPhone(human.primaryPhone || '');
    setEditHumanPhoneValid(true);
    setEditHumanEmail(human.primaryEmail || '');
    setEditHumanSecondaryEmails(
      (human.secondaryEmails || []).filter((se) => se !== human.primaryEmail),
    );
  }
  function cancelHumanEdit() {
    setIsEditingHuman(false);
    setEditHumanFirst('');
    setEditHumanLast('');
    setEditHumanPhone('');
    setEditHumanPhoneValid(true);
    setEditHumanEmail('');
    setEditHumanSecondaryEmails([]);
  }
  function phoneNumberChange(phone: string, isValid: boolean) {
    setEditHumanPhone(phone);
    setPhoneError('');
    if (!phone || isValid) {
      setEditHumanPhoneValid(true);
    }
  }
  function phoneNumberBlur(isValid: boolean) {
    setEditHumanPhoneValid(!editHumanPhone || isValid);
    setPhoneError('');
  }
  function handleStripeElementChange(event: StripeCardElementChangeEvent) {
    setStripeCardReady(!event.empty && !event.error);
    setStripeElementError(event.error ? event.error.message : '');
  }
  const [tryCreateSetupIntent] =
    useMutation<HumanCreateSetupIntentSettingsMutation>(
      humanCreateSetupIntentSettingsMutation,
    );
  const [tryConfirmSetupIntent] = useMutation<
    HumanConfirmSetupIntentSettingsMutation,
    HumanConfirmSetupIntentSettingsMutationVariables
  >(humanConfirmSetupIntentSettingsMutation);
  function saveNewCard() {
    if (cardActionLoading || !stripe || !elements) return;
    if (!stripeCardReady) {
      addNotification(
        'Please fill out your credit card details in order to add this payment method.',
        undefined,
        5000,
      );
      return;
    }
    setCardActionLoading(true);
    tryCreateSetupIntent()
      .then(({ data }) => {
        if (!data || !data.humanCreateSetupIntent) {
          setCardActionLoading(false);
          addNotification(
            'An error occured, please try again',
            undefined,
            5000,
          );
          return;
        }
        stripe
          .confirmCardSetup(data.humanCreateSetupIntent, {
            payment_method: {
              card: elements.getElement(CardElement)!,
            },
          })
          .then(({ error, setupIntent }) => {
            if (
              setupIntent &&
              setupIntent.status === 'succeeded' &&
              setupIntent.id
            ) {
              tryConfirmSetupIntent({
                variables: {
                  setupIntentId: setupIntent.id,
                },
              })
                .then(({ data: dataTempHuman }) => {
                  setCardActionLoading(false);
                  if (
                    dataTempHuman &&
                    dataTempHuman.humanConfirmSetupIntent &&
                    dataTempHuman.humanConfirmSetupIntent.paymentMethodId
                  ) {
                    setSwitchPaymentMethod(false);
                    return;
                  }
                  addNotification(
                    'An error occured, please try again',
                    undefined,
                    5000,
                  );
                })
                .catch((err: ApolloError) => {
                  setCardActionLoading(false);
                  addNotification(
                    errorUtils.getErrorMessage(err) ||
                      'Setup Intent Confirm Error',
                  );
                  logError(err, {
                    component: 'HumanAccountView',
                    func: 'tryConfirmSetupIntent',
                  });
                });
              return;
            }
            setCardActionLoading(false);
            addNotification(
              error && error.message
                ? error.message
                : 'An error occured, please try again',
            );
            logError((error && error.message) || 'Confirm Card Setup Error', {
              component: 'HumanAccountView',
              func: 'confirmCardSetup',
            });
          })
          .catch((err: Error) => {
            setCardActionLoading(false);
            addNotification(err.message || 'Setup Intent Error');
            logError(err, {
              component: 'HumanAccountView',
              func: 'confirmCardSetup',
            });
          });
      })
      .catch((err: ApolloError) => {
        setCardActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Card Setup Error');
        logError(err, {
          component: 'HumanAccountView',
          func: 'tryCreateSetupIntent',
        });
      });
  }
  const [tryCancelPro] = useMutation<HumanCancelSubscriptionProMutation>(
    humanCancelSubscriptionProMutation,
  );
  function onCancelPro() {
    if (!human || !human.proStart || proActionLoading) return;
    setProActionLoading(true);
    tryCancelPro()
      .then(() => {
        setProActionLoading(false);
      })
      .catch((err: ApolloError) => {
        setProActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Cancel Pro Error');
        logError(err, {
          component: 'HumanAccountView',
          func: 'tryCancelPro',
        });
      });
  }
  function tryLogout() {
    oauthUtils.logout('customer');
    window.location.href = '/';
  }
  function startEditBrand(
    brandToEdit: BrandForHumansQuery['brandForHumans'][0],
  ) {
    setBrandEditName(brandToEdit.name || '');
    setBrandEditUrl(brandToEdit.url || brandToEdit.shopifyAdminURL || '');
    setBrandEditId(brandToEdit.id);
  }
  const [tryEditBrand] = useMutation<
    BrandEditByHumanSettingsMutation,
    BrandEditByHumanSettingsMutationVariables
  >(brandEditByHumanSettingsMutation);
  function onSaveBrand(brandToEdit: BrandForHumansQuery['brandForHumans'][0]) {
    if (!brandEditId || !brandToEdit || actionLoading) return;
    if (!brandEditName.trim() || !brandEditUrl.trim()) {
      addNotification(
        'Please submit a brand name and a website url!',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryEditBrand({
      optimisticResponse: {
        brandEditByHuman: {
          ...brandToEdit,
          name: brandEditName,
          url: brandEditUrl,
        },
      },
      variables: {
        brandId: brandEditId,
        name: brandEditName,
        url: brandEditUrl,
      },
    })
      .then(() => {
        setActionLoading(false);
        setBrandEditName('');
        setBrandEditUrl('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Brand Edit Error');
        logError(err, {
          component: 'HumanAccountView',
          func: 'tryEditBrand',
        });
      });
    setBrandEditId('');
  }

  return (
    <div className="DashboardScreen DashboardScreenHuman HumanAccountView">
      <Link className="DashboardScreenLogoLink" to="/home">
        <img
          className="DashboardScreenLogo"
          src={logo}
          alt="Storetasker Logo"
        />
      </Link>
      <div className="DashboardScreenContent HumanAccountViewScreenContent">
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Settings</div>
          {!isEditingHuman && (
            <div
              className="DashboardSectionHeaderAction"
              onClick={startHumanEdit}
            >
              edit
            </div>
          )}
          <div className="DashboardSectionAligned">
            <div className="BasicForm BasicFormSmallTop">
              <div className="BasicFormField BasicFormFieldSplit">
                <div className="BasicFormFieldTitle">First Name</div>
                {isEditingHuman ? (
                  <input
                    className="BasicFormFieldInput"
                    type="text"
                    autoComplete="new-off"
                    placeholder="First Name"
                    value={editHumanFirst}
                    onChange={(e) => setEditHumanFirst(e.currentTarget.value)}
                  />
                ) : (
                  <div className="BasicFormFieldValue">
                    {human.firstName || ''}
                  </div>
                )}
              </div>
              <div className="BasicFormField BasicFormFieldSplit">
                <div className="BasicFormFieldTitle">Last Name</div>
                {isEditingHuman ? (
                  <input
                    className="BasicFormFieldInput"
                    type="text"
                    autoComplete="new-off"
                    placeholder="Last Name"
                    value={editHumanLast}
                    onChange={(e) => setEditHumanLast(e.currentTarget.value)}
                  />
                ) : (
                  <div className="BasicFormFieldValue">
                    {human.lastName || ''}
                  </div>
                )}
              </div>
              <div className="BasicFormField BasicFormFieldMidSplit">
                <div className="BasicFormFieldTitle">
                  Email
                  {(isEditingHuman && editHumanSecondaryEmails.length) ||
                  (!isEditingHuman && human.secondaryEmails.length > 1)
                    ? 's'
                    : ''}
                </div>
                {isEditingHuman ? (
                  <Fragment>
                    <input
                      className="BasicFormFieldInput"
                      type="email"
                      autoComplete="new-off"
                      placeholder="Primary Email"
                      value={editHumanEmail}
                      onChange={(e) => setEditHumanEmail(e.currentTarget.value)}
                    />
                    {editHumanSecondaryEmails.map((se, i) => (
                      <input
                        key={i}
                        className="BasicFormFieldInput"
                        type="email"
                        autoComplete="new-off"
                        spellCheck="false"
                        placeholder="Secondary Email Address"
                        value={se}
                        onChange={(e) =>
                          setEditHumanSecondaryEmails(
                            editHumanSecondaryEmails
                              .slice(0, i)
                              .concat(e.currentTarget.value)
                              .concat(editHumanSecondaryEmails.slice(i + 1)),
                          )
                        }
                      />
                    ))}
                    <div
                      className="BasicFormFieldInputExtra"
                      onClick={() =>
                        setEditHumanSecondaryEmails(
                          editHumanSecondaryEmails.concat(''),
                        )
                      }
                    >
                      Add another email?
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="BasicFormFieldValue">
                      {human.primaryEmail || ''}
                    </div>
                    {(human.secondaryEmails || [])
                      .filter((se) => se !== human.primaryEmail)
                      .map((se) => (
                        <div key={se} className="BasicFormFieldValue">
                          {se}
                        </div>
                      ))}
                  </Fragment>
                )}
              </div>
              <div className="BasicFormField BasicFormFieldMidSplit">
                <div className="BasicFormFieldTitle">Phone Number</div>
                {isEditingHuman ? (
                  <PhoneNumberInput
                    className={
                      'BasicFormFieldInput ' +
                      (!editHumanPhoneValid
                        ? ' BasicFormFieldInputInvalid '
                        : '')
                    }
                    value={editHumanPhone}
                    onChange={phoneNumberChange}
                    onBlur={phoneNumberBlur}
                  />
                ) : (
                  <div className="BasicFormFieldValue">
                    {formatValidPhoneNumber(human.primaryPhone) ||
                      human.primaryPhone ||
                      'missing'}
                  </div>
                )}
              </div>
              {!!isEditingHuman && (
                <Fragment>
                  <div className="BasicFormFieldAction" onClick={saveHumanEdit}>
                    save
                  </div>
                  <div
                    className="BasicFormFieldActionInstead"
                    onClick={cancelHumanEdit}
                  >
                    cancel
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        {!isShowingUpgradeModal && (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">Payment Method</div>
            {!!hasDiscountCode && (
              <div className="DashboardSectionHeaderSub">
                {notificationDiscountCode(true, hasDiscountCode)}
              </div>
            )}
            <div className="DashboardSectionAligned">
              {!switchPaymentMethod &&
              human.paymentMethodId &&
              human.cardBrand &&
              human.cardLast4 ? (
                <div className="ProjectDetailPaymentMethod ProjectDetailPaymentMethodSettings">
                  <div className="ProjectDetailPaymentMethodDetail">
                    {human.cardBrand} - {human.cardLast4}
                  </div>
                  <div
                    className="ProjectDetailPaymentMethodSwitch"
                    onClick={() => setSwitchPaymentMethod(true)}
                  >
                    change
                  </div>
                </div>
              ) : (
                <div className="SettingsPaymentEdit">
                  <div className="ProjectDetailPaymentCard">
                    <CardElement onChange={handleStripeElementChange} />
                  </div>
                  {!!stripeElementError && (
                    <div className="ProjectDetailPaymentCardError">
                      {stripeElementError}
                    </div>
                  )}
                  {!!stripeCardReady && (
                    <div
                      className={
                        'SettingsPaymentEditAction ' +
                        (cardActionLoading
                          ? ' SettingsPaymentEditActionLoading '
                          : '')
                      }
                      onClick={saveNewCard}
                    >
                      save
                    </div>
                  )}
                  {!!switchPaymentMethod && (
                    <div
                      className="SettingsPaymentEditActionInstead"
                      onClick={() => setSwitchPaymentMethod(false)}
                    >
                      cancel
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {!!brands.length && (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">
              Brand{brands.length > 1 ? 's' : ''}
            </div>
            <div className="DashboardSectionAligned">
              <div className="BasicForm BasicFormLowTop">
                {brands
                  .filter((b) => !brandEditId || brandEditId === b.id)
                  .map((b) => (
                    <div
                      key={b.id}
                      className={
                        'SettingsBrand ' +
                        (b.id === brandEditId ? ' SettingsBrandEdit ' : '')
                      }
                    >
                      {b.id === brandEditId ? (
                        <Fragment>
                          <div className="BasicFormField BasicFormFieldMidSplit">
                            <div className="BasicFormFieldTitle">
                              Brand Name
                            </div>
                            <input
                              className="BasicFormFieldInput"
                              type="text"
                              autoComplete="new-off"
                              placeholder="Brand Name"
                              value={brandEditName}
                              onChange={(e) =>
                                setBrandEditName(e.currentTarget.value)
                              }
                            />
                          </div>
                          <div className="BasicFormField BasicFormFieldMidSplit">
                            <div className="BasicFormFieldTitle">
                              Website Url
                            </div>
                            <input
                              className="BasicFormFieldInput"
                              type="text"
                              autoComplete="new-off"
                              placeholder="example.com"
                              value={brandEditUrl}
                              onChange={(e) =>
                                setBrandEditUrl(e.currentTarget.value)
                              }
                            />
                          </div>
                          <div
                            className="BasicFormFieldAction"
                            onClick={() => onSaveBrand(b)}
                          >
                            save
                          </div>
                          <div
                            className="BasicFormFieldActionInstead"
                            onClick={() => setBrandEditId('')}
                          >
                            cancel
                          </div>
                        </Fragment>
                      ) : (
                        <div className="SettingsBrandCard">
                          <div className="SettingsBrandCardName">
                            {b.name || ''}
                          </div>
                          <div className="SettingsBrandCardUrl">
                            {b.url || b.shopifyAdminURL || ''}
                          </div>
                          <div
                            className="SettingsBrandCardEdit"
                            onClick={() => startEditBrand(b)}
                          >
                            edit
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Storetasker Pro</div>
          {human.proStart ? (
            <div className="DashboardSectionKickOff">
              <div
                onClick={onCancelPro}
                className={
                  'DashboardSectionKickOffBtn DashboardSectionKickOffBtnWhite ' +
                  (proActionLoading
                    ? ' DashboardSectionKickOffBtnLoading '
                    : '')
                }
              >
                cancel membership
              </div>
              <div className="DashboardSectionKickOffText">
                Your subscription is active. You can cancel anytime.
              </div>
            </div>
          ) : (
            <div className="DashboardSectionKickOff">
              <div
                onClick={showUpgradeModal}
                className="DashboardSectionKickOffBtn"
              >
                Upgrade
              </div>
              <div className="DashboardSectionKickOffText">
                Get multiple intros, premium filters, no fees, and more.
              </div>
            </div>
          )}
        </div>
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Log out?</div>
          <div className="DashboardSectionKickOff">
            <div
              onClick={tryLogout}
              className="DashboardSectionKickOffBtn DashboardSectionKickOffBtnWhite"
            >
              log out
            </div>
            <div className="DashboardSectionKickOffText">
              Click here if you want to log out of Storetasker.
            </div>
          </div>
        </div>
      </div>
      {!!phoneError && (
        <div className="DashboardErrorCover DashboardErrorCoverPending">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop DashboardErrorCoverPopCloseable">
            <div
              className="DashboardErrorCoverPopClose"
              onClick={() => setPhoneError('')}
            />
            <div className="DashboardErrorCoverPopTitle">
              {phoneErrorTitle(phoneError)}
            </div>
            <div className="DashboardErrorCoverPopText">
              {phoneErrorSubtitle(phoneError)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

HumanAccountView.propTypes = {
  brands: PropTypes.array.isRequired,
  hasDiscountCode: PropTypes.string.isRequired,
  human: PropTypes.object.isRequired,
  isShowingUpgradeModal: PropTypes.bool.isRequired,
  showUpgradeModal: PropTypes.func.isRequired,
};

export default HumanAccountView;
