import { useState, useContext } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import PropTypes from 'prop-types';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { MatchDetailsQuery } from '../../gql/graphql';

interface BrandSidebarEditorProps {
  brand: MatchDetailsQuery['matchDetails']['brand'];
  isActionLoading: boolean;
  onCancel: () => void;
  onSave: (
    name: string,
    url: string,
    description?: string,
    shopifyAdminURL?: string,
  ) => void;
}

const BrandSidebarEditor = ({
  brand,
  onCancel,
  onSave,
  isActionLoading,
}: BrandSidebarEditorProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [brandName, setBrandName] = useState((brand && brand.name) || '');
  const [brandUrl, setBrandUrl] = useState((brand && brand.url) || '');
  const [brandDescription, setBrandDescription] = useState(
    (brand && brand.description) || '',
  );
  const [brandAdminUrl, setBrandAdminUrl] = useState(
    (brand && brand.shopifyAdminURL) || '',
  );
  function trySave() {
    if (!brandName || !brandUrl) {
      addNotification(
        'Please submit a brand name and url at least.',
        undefined,
        5000,
      );
      return;
    }
    onSave(brandName, brandUrl, brandDescription, brandAdminUrl);
  }
  return (
    <div className="HumanBrandSidebarEditor BrandSidebarEditor">
      <div className="HumanBrandSidebarEditorTop">
        <div className="HumanBrandSidebarEditorTopTitle">Edit Brand</div>
        <div className="HumanBrandSidebarEditorTopActions">
          <div
            className="HumanBrandSidebarEditorTopActionBtn HumanBrandSidebarEditorTopActionIgnore"
            onClick={onCancel}
          >
            Cancel
          </div>
          <div
            className={
              'HumanBrandSidebarEditorTopActionBtn HumanBrandSidebarEditorTopActionSave ' +
              (isActionLoading
                ? ' HumanBrandSidebarEditorTopActionBtnLoading '
                : '')
            }
            onClick={trySave}
          >
            Save
          </div>
        </div>
      </div>
      <div className="HumanBrandSidebarEditorCardWrapper">
        <div className="HumanBrandSidebarEditorCard">
          <div className="HumanBrandSidebarEditorFormInput">
            <div className="HumanBrandSidebarEditorFormInputTitle">
              Brand Name
            </div>
            <input
              className="HumanBrandSidebarEditorFormInputField"
              type="text"
              autoComplete="new-off"
              placeholder="Brand Name"
              value={brandName}
              onChange={(e) => setBrandName(e.currentTarget.value)}
            />
          </div>
          <div className="HumanBrandSidebarEditorFormInput">
            <div className="HumanBrandSidebarEditorFormInputTitle">
              Brand Description
            </div>
            <TextareaAutosize
              type="text"
              placeholder="Brand Description"
              spellCheck="true"
              className="HumanBrandSidebarEditorFormInputField"
              value={brandDescription}
              onChange={(e) => {
                setBrandDescription(e.currentTarget.value);
              }}
            />
          </div>
          <div className="HumanBrandSidebarEditorFormInput">
            <div className="HumanBrandSidebarEditorFormInputTitle">
              Website Url
            </div>
            <input
              className="HumanBrandSidebarEditorFormInputField"
              type="text"
              autoComplete="new-off"
              placeholder="Website Url"
              value={brandUrl}
              onChange={(e) => setBrandUrl(e.currentTarget.value)}
            />
          </div>
          <div className="HumanBrandSidebarEditorFormInput">
            <div className="HumanBrandSidebarEditorFormInputTitle">
              Shopify Admin Url
            </div>
            <input
              className="HumanBrandSidebarEditorFormInputField"
              type="text"
              autoComplete="new-off"
              placeholder="Shopify Admin Url"
              value={brandAdminUrl}
              onChange={(e) => setBrandAdminUrl(e.currentTarget.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BrandSidebarEditor.propTypes = {
  brand: PropTypes.object.isRequired,
  isActionLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default BrandSidebarEditor;
