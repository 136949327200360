import axios from 'axios';
import envUtils from './env';
import urlUtils from './url';
import tokenUtils from './token';
import oauthUtils from './oauth';

const sendMarketingEvents = envUtils.get() === 'production';
const API_HOST = envUtils.pick(
  'https://api.storetasker.com',
  'https://dev.storetasker.com',
  'http://localhost:4000',
);
function postRequest(endpoint: string, payload: any) {
  return axios.post(API_HOST + endpoint, payload);
}

let leadLocal = tokenUtils.read('leadId') || urlUtils.getQueryParam('l') || '';
const discountUrlParam = urlUtils.getQueryParam('disc') || '';

function getCachedLeadId() {
  return leadLocal || '';
}
function recordGtagPageview(url: string) {
  if (!sendMarketingEvents || !window) return;
  if (window.gtag) {
    window.gtag('config', 'AW-848580571', {
      page_path: url,
    });
    window.gtag('config', 'G-DY6R98RZW5', {
      page_path: url,
    });
  }
}

function recordGtagEvent(
  gaEventName: string,
  gaAction: string,
  gaValue?: number,
  gaLabel?: string,
) {
  if (!sendMarketingEvents || !window || !window.gtag) return;
  window.gtag('event', gaAction, {
    event_category: gaEventName,
    ...(gaLabel && {
      event_label: gaLabel,
    }),
    ...(gaValue && {
      value: gaValue,
    }),
  });
}

function recordFbEvent(fbEventName: string) {
  if (!sendMarketingEvents || !window || !window.fbq) return;
  window.fbq('trackCustom', fbEventName);
}
// try to find real referral
const affliateParam = urlUtils.getQueryParam('a') || '';
const rfsnParam = urlUtils.getQueryParam('rfsn') || '';
const partnerParam = urlUtils.getQueryParam('ref') || '';
const partnerMediumParam = urlUtils.getQueryParam('ref-medium') || '';
const partnerTicketParam = urlUtils.getQueryParam('ref-ticket') || '';
const partnerCampaignParam = urlUtils.getQueryParam('ca') || '';
// try to find soft referral
let softRef = '';
const partnerEl = window.document.getElementById('partner-page-id');
if (partnerEl && partnerEl.textContent) {
  softRef = (partnerEl.textContent || '').trim();
}
function startLead(callback: (discountCode: string) => void) {
  // don't do anything if customer record is set
  if (oauthUtils.getToken('customer')) {
    if (leadLocal) {
      setUserId(leadLocal);
    }
    return;
  }
  // findOrCreate lead with this potential partner referral
  postRequest('/lead', {
    discount: discountUrlParam,
    leadId: leadLocal,
    partnerReferral: {
      affliate: affliateParam,
      partner: partnerParam,
      partnerCampaign: partnerCampaignParam,
      partnerMedium: partnerMediumParam,
      partnerTicket: partnerTicketParam,
      rfsn: rfsnParam,
      softRef,
    },
  })
    .then((res) => res.data)
    .then((result) => {
      if (!result || !result.lead) {
        console.log('createLoremLead No Lead Data Returned');
        return;
      }
      const leadObj = result.lead;
      // write leadId
      leadLocal = leadObj._id;
      tokenUtils.write('leadId', leadObj._id as string, 1000);
      // setUserId
      setUserId(leadObj._id as string);
      if (leadObj.discountCode) {
        callback(leadObj.discountCode as string);
      }
    })
    .catch((err) => {
      console.log('createLoremLead error: ', err);
    });
}

function setHumanLeadId(leadId?: string) {
  if (leadId && leadLocal !== leadId) {
    leadLocal = leadId;
    tokenUtils.write('leadId', leadId, 1000);
  }
}

function isLoggedIn(
  humanId: string,
  currentDiscountCode: string,
  callback: (discountCode: string) => void,
) {
  recordGtagEvent('IsLoggedIn', 'Click');
  recordFbEvent('IsLoggedIn');
  if (
    humanId &&
    (discountUrlParam || partnerParam || softRef) &&
    !currentDiscountCode
  ) {
    postRequest('/lead/human/' + humanId + '/discount', {
      discount: discountUrlParam,
      partnerRef: partnerParam,
      partnerSoft: softRef,
    })
      .then((res) => res.data)
      .then((result) => {
        if (!result || !result.saved) return;
        callback(result.saved as string);
      })
      .catch((err) => {
        console.log('potential discount error: ', err);
      });
  }
}

function setUserId(leadId: string) {
  if (!sendMarketingEvents || !window || !window.gtag) return;
  window.gtag('set', {
    dimension1: leadId,
    userId: leadId,
  });
  recordGtagEvent('SetUserId', 'Set');
}

function beginNewRequest() {
  // read localStorage
  const repeatStatus =
    tokenUtils.readLocalStorage('EventStatusStartJob') || 'First';
  // send event
  recordGtagEvent('StartJob', 'Click', undefined, repeatStatus);
  recordFbEvent('StartJob');
  // write localStorage
  tokenUtils.writeLocalStorage('EventStatusStartJob', 'Repeat');
}

function submitNewRequest() {
  // read localStorage
  const repeatStatus =
    tokenUtils.readLocalStorage('EventStatusSubmitRequest') || 'First';
  // send event
  recordGtagEvent('SubmitRequest', 'Click', undefined, repeatStatus);
  recordFbEvent('SubmitRequest');
  // write localStorage
  tokenUtils.writeLocalStorage('EventStatusSubmitRequest', 'Repeat');
}

function sendNewRequest() {
  // read localStorage
  const repeatStatus =
    tokenUtils.readLocalStorage('EventStatusSendRequest') || 'First';
  // send event
  recordGtagEvent('SendRequest', 'Click', undefined, repeatStatus);
  recordFbEvent('SendRequest');
  // write localStorage
  tokenUtils.writeLocalStorage('EventStatusSendRequest', 'Repeat');
}

export default {
  beginNewRequest,
  getCachedLeadId,
  isLoggedIn,
  postRequest,
  recordGtagPageview,
  sendNewRequest,
  setHumanLeadId,
  startLead,
  submitNewRequest,
};
