/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useContext,
  Fragment,
} from 'react';
import ReactTooltip from 'react-tooltip';
import { SingleDatePicker } from 'react-dates';
import { useDebouncedCallback } from 'use-debounce';
import { ClientWithOnReconnected } from '../../utils/apollo';
import moment from 'moment-timezone';
import TextareaAutosize from 'react-autosize-textarea';
import PropTypes from 'prop-types';
import {
  useMutation,
  useQuery,
  useLazyQuery,
  ApolloError,
  gql,
} from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import envUtils from '../../utils/env';
import {
  SUPPORT_EXPERT_ID,
  KPI_GOALS,
  FOCUS_OPTIONS,
  SKILL_OPTIONS,
  TOOL_OPTIONS,
  WEEKLY_CLAIM_LIMIT,
  COUNTRIES,
  CONTINENTS,
} from '../../utils/constants';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { IFilestackFileUpload } from '../../utils/filestack';
import MultiSelect from '../feature/MultiSelect';
import LocationTimestamp from '../feature/LocationTimestamp';
import {
  TextMessageEventDetail,
  EmailMessageEventDetail,
  MessageEventDetail,
  MeetingEventDetail,
  QuoteDetail,
  PhoneCallEventDetail,
  RequestEventDetail,
  MatchSummaryHq,
  ExpertDetailHq,
  ProjectDetailHq,
  UserNoteDetail,
  AccountBalanceEventDetail,
  ExpertSummary,
} from '../../utils/gql';
import templateUtils from '../../utils/template';
import {
  replyToSubject,
  unreadReasonFormatted,
  centsDollarsRounded,
  kpiPercentage,
  kpiDollars,
  kpiRating,
  getExpertLevelStr,
  quoteStatusFormatted,
  searchResultType,
  formatValidPhoneNumber,
  centsDollarsDecimal,
  amountClientFee,
  amountDiscountCode,
  formatNumberWithCommas,
  tagLabelFocus,
  tagLabelSkill,
  tagLabelTool,
  profileBandwidthValue,
} from '../../utils/format';
import tokenUtils from '../../utils/token';
import oauthUtils from '../../utils/oauth';
import UserNoteEditor from '../feature/UserNoteEditor';
import PhoneNumberInput from '../feature/PhoneNumberInput';
import ThreadSearchResults from '../feature/ThreadSearchResults';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import HumanUserBubble from '../feature/HumanUserBubble';
import EmailMessageThreadEvent from '../threadEvent/EmailMessageThreadEvent';
import TextMessageThreadEvent from '../threadEvent/TextMessageThreadEvent';
import MessageThreadEvent from '../threadEvent/MessageThreadEvent';
import PhoneCallThreadEvent from '../threadEvent/PhoneCallThreadEvent';
import EmailEditor from '../feature/EmailEditor';
import TextEditor from '../feature/TextEditor';
import {
  TemplatesQuery,
  ExpertDetailsAdminQuery,
  ExpertDetailsAdminQueryVariables,
  AdminGenerateTokenQuery,
  AdminGenerateTokenQueryVariables,
  AdminExpertThreadPaginatedQuery,
  AdminExpertThreadPaginatedQueryVariables,
  AdminExpertThreadEventUpdatedSubscription,
  AdminExpertThreadEventUpdatedSubscriptionVariables,
  UserNoteExpertAdminQuery,
  UserNoteExpertAdminQueryVariables,
  UserNoteExpertEditAdminMutation,
  UserNoteExpertEditAdminMutationVariables,
  UserNoteExpertUpdatedAdminSubscription,
  UserNoteExpertUpdatedAdminSubscriptionVariables,
  MatchPaginateExpertQuery,
  MatchPaginateExpertQueryVariables,
  ProjectPaginateExpertQuery,
  ProjectPaginateExpertQueryVariables,
  AdminThreadSendTextMessageExpertMutation,
  AdminThreadSendTextMessageExpertMutationVariables,
  AdminThreadSendEmailMessageExpertMutation,
  AdminThreadSendEmailMessageExpertMutationVariables,
  ExpertEditByAdminMutation,
  ExpertEditByAdminMutationVariables,
  ExpertSetReferrerMutation,
  ExpertSetReferrerMutationVariables,
  ExpertSearchForAdminExpertMutation,
  ExpertSearchForAdminExpertMutationVariables,
  ExpertSetPartnerDirectoriesMutation,
  ExpertSetPartnerDirectoriesMutationVariables,
  PartnerSearchForAdminExpertMutation,
  PartnerSearchForAdminExpertMutationVariables,
  SupportChannelMarkAsReadUnreadAdminMutation,
  SupportChannelMarkAsReadUnreadAdminMutationVariables,
  SupportChannelSnoozeAdminMutation,
  SupportChannelSnoozeAdminMutationVariables,
  SupportChannelAssignAdminMutation,
  SupportChannelAssignAdminMutationVariables,
  AdminBuyExpertPhoneMutation,
  AdminBuyExpertPhoneMutationVariables,
  AdminExpertLogoutMutation,
  AdminExpertLogoutMutationVariables,
  AdminThreadSearchExpertMutation,
  AdminThreadSearchExpertMutationVariables,
  AccountBalanceDetailsAdminQuery,
  AccountBalanceDetailsAdminQueryVariables,
  AccountBalanceDebitMutation,
  AccountBalanceDebitMutationVariables,
  AccountBalanceCreditMutation,
  AccountBalanceCreditMutationVariables,
  AccountBalanceEventPaginateAdminQuery,
  AccountBalanceEventPaginateAdminQueryVariables,
} from '../../gql/graphql';
const ReactFilestack = require('filestack-react').default; // eslint-disable-line @typescript-eslint/no-var-requires

export interface IProfileQuote {
  attribution: string;
  text: string;
}

const expertDetailsAdminQuery = gql`
  query ExpertDetailsAdmin($expertId: ID!) {
    expertDetails(expertId: $expertId) {
      ...ExpertDetailHq
    }
  }
  ${ExpertDetailHq}
`;

const adminGenerateTokenQuery = gql`
  query AdminGenerateToken($forId: ID!, $userType: String!) {
    adminGenerateToken(forId: $forId, userType: $userType)
  }
`;

// @connection(key: "thread", filter: ["supportChannelId"])
const adminExpertThreadPaginatedQuery = gql`
  query AdminExpertThreadPaginated(
    $supportChannelId: ID
    $direction: String!
    $fromDate: Date
    $limit: Int!
  ) {
    threadPaginated(
      supportChannelId: $supportChannelId
      direction: $direction
      fromDate: $fromDate
      limit: $limit
    ) {
      id
      edges {
        id
        cursor
        node {
          ... on TextMessageEvent {
            ...TextMessageEventDetail
          }
          ... on EmailMessageEvent {
            ...EmailMessageEventDetail
          }
          ... on RequestEvent {
            ...RequestEventDetail
          }
          ... on MessageEvent {
            ...MessageEventDetail
          }
          ... on MeetingEvent {
            ...MeetingEventDetail
          }
          ... on Quote {
            ...QuoteDetail
          }
          ... on PhoneCallEvent {
            ...PhoneCallEventDetail
          }
        }
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const adminExpertThreadEventUpdatedSubscription = gql`
  subscription AdminExpertThreadEventUpdated($threadId: ID!) {
    threadEventUpdated(threadId: $threadId) {
      ... on TextMessageEvent {
        ...TextMessageEventDetail
      }
      ... on EmailMessageEvent {
        ...EmailMessageEventDetail
      }
      ... on RequestEvent {
        ...RequestEventDetail
      }
      ... on MessageEvent {
        ...MessageEventDetail
      }
      ... on MeetingEvent {
        ...MeetingEventDetail
      }
      ... on Quote {
        ...QuoteDetail
      }
      ... on PhoneCallEvent {
        ...PhoneCallEventDetail
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const userNoteAdminQuery = gql`
  query UserNoteExpertAdmin($matchId: ID, $supportChannelId: ID, $humanId: ID) {
    userNoteForTarget(
      matchId: $matchId
      supportChannelId: $supportChannelId
      humanId: $humanId
    ) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const userNoteEditAdminMutation = gql`
  mutation UserNoteExpertEditAdmin(
    $body: String!
    $userNoteId: ID
    $matchId: ID
    $supportChannelId: ID
    $humanId: ID
  ) {
    userNoteEdit(
      body: $body
      userNoteId: $userNoteId
      matchId: $matchId
      supportChannelId: $supportChannelId
      humanId: $humanId
    ) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const adminUserNoteUpdatedSubscription = gql`
  subscription UserNoteExpertUpdatedAdmin($targetId: ID!) {
    userNoteUpdated(targetId: $targetId) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const adminThreadSearchExpertMutation = gql`
  mutation AdminThreadSearchExpert(
    $searchQuery: String!
    $supportChannelId: ID
    $limit: Int!
  ) {
    threadSearch(
      searchQuery: $searchQuery
      supportChannelId: $supportChannelId
      limit: $limit
    ) {
      id
      highlightsContent
      highlightsSubject
      node {
        ... on TextMessageEvent {
          ...TextMessageEventDetail
        }
        ... on EmailMessageEvent {
          ...EmailMessageEventDetail
        }
        ... on RequestEvent {
          ...RequestEventDetail
        }
        ... on MessageEvent {
          ...MessageEventDetail
        }
        ... on MeetingEvent {
          ...MeetingEventDetail
        }
        ... on Quote {
          ...QuoteDetail
        }
        ... on PhoneCallEvent {
          ...PhoneCallEventDetail
        }
      }
      plainTextContent
      plainTextSubject
      score
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const matchPaginateExpertQuery = gql`
  query MatchPaginateExpert(
    $expertId: ID!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    matchPaginateExpert(
      expertId: $expertId
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...MatchSummaryHq
    }
  }
  ${MatchSummaryHq}
`;

const projectPaginateExpertQuery = gql`
  query ProjectPaginateExpert(
    $expertId: ID!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    projectPaginateExpert(
      expertId: $expertId
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...ProjectDetailHq
    }
  }
  ${ProjectDetailHq}
`;

const adminThreadSendTextMessageExpertMutation = gql`
  mutation AdminThreadSendTextMessageExpert(
    $input: ThreadSendTextMessageInput!
  ) {
    threadSendTextMessageByAdmin(input: $input) {
      ...TextMessageEventDetail
    }
  }
  ${TextMessageEventDetail}
`;

const expertSearchForAdminExpertMutation = gql`
  mutation ExpertSearchForAdminExpert(
    $searchQuery: String!
    $onlyActives: Boolean
  ) {
    expertSearchForAdmin(searchQuery: $searchQuery, onlyActives: $onlyActives) {
      ...ExpertSummary
    }
  }
  ${ExpertSummary}
`;

const expertSetPartnerDirectoriesMutation = gql`
  mutation ExpertSetPartnerDirectories(
    $expertId: ID!
    $partnerDirectories: [ID!]!
  ) {
    expertSetPartnerDirectories(
      expertId: $expertId
      partnerDirectories: $partnerDirectories
    ) {
      ...ExpertDetailHq
    }
  }
  ${ExpertDetailHq}
`;

const partnerSearchForAdminExpertMutation = gql`
  mutation PartnerSearchForAdminExpert($searchQuery: String!) {
    partnerSearchForAdmin(searchQuery: $searchQuery) {
      id
      partnerName
      trackingId
    }
  }
`;

const adminThreadSendEmailMessageExpertMutation = gql`
  mutation AdminThreadSendEmailMessageExpert(
    $input: ThreadSendEmailMessageInput!
  ) {
    threadSendEmailMessageByAdmin(input: $input) {
      ...EmailMessageEventDetail
    }
  }
  ${EmailMessageEventDetail}
`;

const supportChannelMarkAsReadUnreadAdminMutation = gql`
  mutation SupportChannelMarkAsReadUnreadAdmin(
    $supportChannelId: ID!
    $unreadReason: String
  ) {
    supportChannelMarkAsReadUnread(
      supportChannelId: $supportChannelId
      unreadReason: $unreadReason
    ) {
      id
      expertUnread
      adminUnread
    }
  }
`;

const supportChannelAssignAdminMutation = gql`
  mutation SupportChannelAssignAdmin(
    $supportChannelId: ID!
    $adminAssignment: String!
  ) {
    supportChannelAssignAdmin(
      supportChannelId: $supportChannelId
      adminAssignment: $adminAssignment
    ) {
      id
      adminAssigned
      adminUnread
    }
  }
`;

const supportChannelSnoozeAdminMutation = gql`
  mutation SupportChannelSnoozeAdmin(
    $supportChannelId: ID!
    $untilDate: Date!
  ) {
    supportChannelSnoozeAdmin(
      supportChannelId: $supportChannelId
      untilDate: $untilDate
    ) {
      id
      adminSnoozedUntil
    }
  }
`;

const adminBuyExpertPhoneMutation = gql`
  mutation AdminBuyExpertPhone($expertId: ID!) {
    adminBuyExpertPhone(expertId: $expertId) {
      ...ExpertDetailHq
    }
  }
  ${ExpertDetailHq}
`;

const adminExpertLogoutMutation = gql`
  mutation AdminExpertLogout($expertId: ID!) {
    adminExpertLogout(expertId: $expertId)
  }
`;

const expertEditByAdminMutation = gql`
  mutation ExpertEditByAdmin(
    $claimsAllowed: Int!
    $clientEscalations: Int!
    $expertId: ID!
    $firstName: String!
    $lastName: String!
    $primaryPersonalEmail: String!
    $secondaryPersonalEmails: [String!]!
    $primaryPublicEmail: String!
    $secondaryPublicEmails: [String!]!
    $primaryPersonalPhone: String
    $locationContinent: String!
    $locationCountry: String!
    $locationStr: String!
    $locationTimezone: String!
    $photo: String!
    $profileBeyond: String!
    $profileBio: String!
    $profileClients: String!
    $profileEmailIntro: String!
    $profilePath: String!
    $profileQuotes: [ProfileQuoteInput!]!
    $profileTagline: String!
    $recipientId: String!
    $status: String!
    $startDate: Date!
    $skillLevel: String!
    $inSpotlight: Boolean!
    $isAgency: Boolean!
    $isAutoApproveBlocked: Boolean!
    $isNoKeyAccounts: Boolean!
    $isOnlyAdminTag: Boolean!
    $isOnlyMatchingTags: Boolean!
    $slackId: String!
    $tagFocus: [String!]!
    $tagSkills: [String!]!
    $tagTools: [String!]!
    $shouldExcludeFromKpiReports: Boolean
    $grandfatheredGMVLevel: String
    $profileBandwidth: String
    $profileHourlyRate: Int
    $profileLanguages: String
    $profilePortfolioUrl: String
    $profileStartingProject: Int
    $profileStartingRetainer: Int
  ) {
    expertEditByAdmin(
      claimsAllowed: $claimsAllowed
      clientEscalations: $clientEscalations
      expertId: $expertId
      firstName: $firstName
      lastName: $lastName
      primaryPersonalEmail: $primaryPersonalEmail
      secondaryPersonalEmails: $secondaryPersonalEmails
      primaryPublicEmail: $primaryPublicEmail
      secondaryPublicEmails: $secondaryPublicEmails
      primaryPersonalPhone: $primaryPersonalPhone
      locationContinent: $locationContinent
      locationCountry: $locationCountry
      locationStr: $locationStr
      locationTimezone: $locationTimezone
      photo: $photo
      profileBeyond: $profileBeyond
      profileBio: $profileBio
      profileClients: $profileClients
      profileEmailIntro: $profileEmailIntro
      profilePath: $profilePath
      profileQuotes: $profileQuotes
      profileTagline: $profileTagline
      recipientId: $recipientId
      startDate: $startDate
      status: $status
      skillLevel: $skillLevel
      inSpotlight: $inSpotlight
      isAgency: $isAgency
      isAutoApproveBlocked: $isAutoApproveBlocked
      isNoKeyAccounts: $isNoKeyAccounts
      isOnlyAdminTag: $isOnlyAdminTag
      isOnlyMatchingTags: $isOnlyMatchingTags
      slackId: $slackId
      tagFocus: $tagFocus
      tagSkills: $tagSkills
      tagTools: $tagTools
      shouldExcludeFromKpiReports: $shouldExcludeFromKpiReports
      grandfatheredGMVLevel: $grandfatheredGMVLevel
      profileBandwidth: $profileBandwidth
      profileHourlyRate: $profileHourlyRate
      profileLanguages: $profileLanguages
      profilePortfolioUrl: $profilePortfolioUrl
      profileStartingProject: $profileStartingProject
      profileStartingRetainer: $profileStartingRetainer
    ) {
      ...ExpertDetailHq
    }
  }
  ${ExpertDetailHq}
`;

const expertSetReferrerMutation = gql`
  mutation ExpertSetReferrer($expertId: ID!, $expertReferrerId: ID) {
    expertSetReferrer(
      expertId: $expertId
      expertReferrerId: $expertReferrerId
    ) {
      ...ExpertDetailHq
    }
  }
  ${ExpertDetailHq}
`;

const accountBalanceCreditMutation = gql`
  mutation AccountBalanceCredit(
    $expertId: ID!
    $amount: Float!
    $reason: String!
  ) {
    accountBalanceCredit(
      expertId: $expertId
      amount: $amount
      reason: $reason
    ) {
      ...AccountBalanceEventDetail
    }
  }
  ${AccountBalanceEventDetail}
`;

const accountBalanceDebitMutation = gql`
  mutation AccountBalanceDebit(
    $expertId: ID!
    $amount: Float!
    $reason: String!
  ) {
    accountBalanceDebit(expertId: $expertId, amount: $amount, reason: $reason) {
      ...AccountBalanceEventDetail
    }
  }
  ${AccountBalanceEventDetail}
`;

const accountBalanceDetailsAdminQuery = gql`
  query AccountBalanceDetailsAdmin($expertId: ID!, $recipientId: String!) {
    accountBalanceDetailsAdmin(expertId: $expertId, recipientId: $recipientId) {
      id
      accountIsActive
      accountNeedsTaxReview
      balance
      estimatedFees
      deliveryMethod
    }
  }
`;

const accountBalanceEventPaginateAdminQuery = gql`
  query AccountBalanceEventPaginateAdmin(
    $expertId: ID!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    accountBalanceEventPaginateAdmin(
      expertId: $expertId
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...AccountBalanceEventDetail
    }
  }
  ${AccountBalanceEventDetail}
`;

interface AdminExpertDetailProps {
  emailTemplates: TemplatesQuery['templates'];
  expertId: string;
  socketClient: ClientWithOnReconnected;
}

interface IGroupedEvents {
  group: AdminExpertThreadPaginatedQuery['threadPaginated']['edges'];
}

let scrollDownQuickTimeout: NodeJS.Timeout | undefined;
let isLoadingEvents = false;
const EVENT_PAGE_LIMIT = 20;
const ACTIVITY_PAGE_LIMIT = 5;
const PAGE_LIMIT = 8;
const initTime = new Date().getTime();

const AdminExpertDetail = ({
  expertId,
  emailTemplates,
  socketClient,
}: AdminExpertDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const threadEventsWindow = useRef<HTMLDivElement>(null);
  const [initDate] = useState(() => new Date());
  const [actionLoading, setActionLoading] = useState(false);
  const [adminLoading, setAdminLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [showLoadMoreMatches, setShowLoadMoreMatches] = useState(true);
  const [showLoadMoreProjects, setShowLoadMoreProjects] = useState(true);
  const [emailDraft, setEmailDraft] = useState(false);
  const [emailStatus, setEmailStatus] = useState('');
  const [emailFiles, setEmailFiles] = useState([] as IFilestackFileUpload[]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [textDraft, setTextDraft] = useState(false);
  const [textStatus, setTextStatus] = useState('');
  const [textFiles, setTextFiles] = useState([] as IFilestackFileUpload[]);
  const [textContent, setTextContent] = useState('');
  const [openActions, setOpenActions] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [showLoadMoreBalance, setShowLoadMoreBalance] = useState(true);
  const [tempBalanceEvents, setTempBalanceEvents] = useState(
    [] as AccountBalanceEventPaginateAdminQuery['accountBalanceEventPaginateAdmin'],
  );
  const [tempEdges, setTempEdges] = useState(
    [] as AdminExpertThreadPaginatedQuery['threadPaginated']['edges'],
  );
  // remoteEventIds tracks threadEventUpdated things that might cause pagination errors
  const [remoteEventIds, setRemoteEventIds] = useState([] as string[]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [dateFocused, setDateFocused] = useState(false);
  const [editExpertFirst, setEditExpertFirst] = useState('');
  const [editExpertLast, setEditExpertLast] = useState('');
  const [editExpertPersonalEmail, setEditExpertPersonalEmail] = useState('');
  const [
    editExpertSecondaryPersonalEmails,
    setEditExpertSecondaryPersonalEmails,
  ] = useState([] as string[]);
  const [editExpertPublicEmail, setEditExpertPublicEmail] = useState('');
  const [editExpertSecondaryPublicEmails, setEditExpertSecondaryPublicEmails] =
    useState([] as string[]);
  const [editExpertPersonalPhone, setEditExpertPersonalPhone] = useState('');
  const [editExpertPersonalPhoneValid, setEditExpertPersonalPhoneValid] =
    useState(true);
  const [editExpertExcludeKpis, setEditExpertExcludeKpis] = useState('false');
  const [editExpertInSpotlight, setEditExpertInSpotlight] = useState('false');
  const [editExpertIsAgency, setEditExpertIsAgency] = useState('false');
  const [editExpertClaimsAllowed, setEditExpertClaimsAllowed] =
    useState(WEEKLY_CLAIM_LIMIT);
  const [editExpertClientEscalations, setEditExpertClientEscalations] =
    useState(0);
  const [editExpertIsAutoApproveBlocked, setEditExpertIsAutoApproveBlocked] =
    useState('false');
  const [editExpertIsOnlyAdminTag, setEditExpertIsOnlyAdminTag] =
    useState('false');
  const [editExpertIsNoKeyAccounts, setEditExpertIsNoKeyAccounts] =
    useState('false');
  const [editExpertIsOnlyMatchingTags, setEditExpertIsOnlyMatchingTags] =
    useState('false');
  const [editExpertLocationContinent, setEditExpertLocationContinent] =
    useState('');
  const [editExpertLocationCountry, setEditExpertLocationCountry] =
    useState('');
  const [editExpertLocationStr, setEditExpertLocationStr] = useState('');
  const [editExpertLocationTimezone, setEditExpertLocationTimezone] =
    useState('');
  const [editExpertStartDate, setEditExpertStartDate] =
    useState<moment.Moment | null>(() => moment.utc().startOf('day'));
  const [editExpertProfileBeyond, setEditExpertProfileBeyond] = useState('');
  const [editExpertProfileBio, setEditExpertProfileBio] = useState('');
  const [editExpertProfileClients, setEditExpertProfileClients] = useState('');
  const [editExpertProfilePath, setEditExpertProfilePath] = useState('');
  const [editExpertProfileQuotes, setEditExpertProfileQuotes] = useState(
    [] as IProfileQuote[],
  );
  const [editExpertProfileTagline, setEditExpertProfileTagline] = useState('');
  const [editExpertProfileBandwidth, setEditExpertProfileBandwidth] =
    useState('');
  const [editExpertProfileHourlyRate, setEditExpertProfileHourlyRate] =
    useState(0);
  const [
    editExpertProfileStartingProject,
    setEditExpertProfileStartingProject,
  ] = useState(0);
  const [
    editExpertProfileStartingRetainer,
    setEditExpertProfileStartingRetainer,
  ] = useState(0);
  const [editExpertProfileLanguages, setEditExpertProfileLanguages] =
    useState('');
  const [editExpertProfilePortfolioUrl, setEditExpertProfilePortfolioUrl] =
    useState('');
  const [editExpertProfileEmailIntro, setEditExpertProfileEmailIntro] =
    useState('');
  const [editExpertRecipientId, setEditExpertRecipientId] = useState('');
  const [editExpertStatus, setEditExpertStatus] = useState('');
  const [editExpertSkillLevel, setEditExpertSkillLevel] = useState('');
  const [editExpertSlackId, setEditExpertSlackId] = useState('');
  const [editExpertTagFocus, setEditExpertTagFocus] = useState([] as string[]);
  const [editExpertTagSkills, setEditExpertTagSkills] = useState(
    [] as string[],
  );
  const [editExpertTagTools, setEditExpertTagTools] = useState([] as string[]);
  const [editExpertPhoto, setEditExpertPhoto] = useState('');
  const [editExpertGrandfatheredGMVLevel, setEditExpertGrandfatheredGMVLevel] =
    useState('');
  const [searchInputPartners, setSearchInputPartners] = useState('');
  const [searchResultsPartners, setSearchResultsPartners] = useState<
    PartnerSearchForAdminExpertMutation['partnerSearchForAdmin'] | null
  >(null);
  const [primaryPanel, setPrimaryPanel] = useState('About');
  const searchRef = useRef<HTMLInputElement>(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedSearchResult, setSelectedSearchResult] = useState<
    AdminThreadSearchExpertMutation['threadSearch'][0] | null
  >(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    AdminThreadSearchExpertMutation['threadSearch'] | null
  >(null);
  const [searchInputExperts, setSearchInputExperts] = useState('');
  const [searchResultsExperts, setSearchResultsExperts] = useState<
    ExpertSearchForAdminExpertMutation['expertSearchForAdmin'] | null
  >(null);
  const [adminSnoozed] = useState('');
  const [adminAssignment] = useState('');
  const [balanceReason, setBalanceReason] = useState('');
  const [balanceAmount, setBalanceAmount] = useState('');
  const [balanceAction, setBalanceAction] = useState('credit');
  const balanceAmountVal =
    (parseFloat((balanceAmount || '').replace(/\$/gi, '')) || 0) * 100;
  const timezones = useMemo(
    () =>
      moment.tz
        .names()
        .map((tz) => ({ offset: moment.tz(tz).utcOffset(), tz }))
        .sort((a, b) => a.offset - b.offset),
    [],
  );
  useEffect(() => {
    if (!searchResults) {
      ReactTooltip.hide();
    }
    ReactTooltip.rebuild();
  }, [searchLoading, searchResults]);
  const {
    data: dataExpert,
    error: errorExpert,
    loading: loadingExpert,
    refetch: refetchExpert,
  } = useQuery<ExpertDetailsAdminQuery, ExpertDetailsAdminQueryVariables>(
    expertDetailsAdminQuery,
    {
      returnPartialData: true,
      variables: {
        expertId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorExpert) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorExpert) ||
      'Could not load expert details';
  }
  const expertDetails =
    dataExpert && dataExpert.expertDetails && dataExpert.expertDetails.id
      ? dataExpert.expertDetails
      : undefined;
  if (
    expertDetails &&
    (expertDetails.status === 'admin' || expertDetails.status === 'support')
  ) {
    fullErrorCover = 'This expert is an admin!';
  }
  const {
    data: dataToken,
    error: errorToken,
    loading: loadingToken,
  } = useQuery<AdminGenerateTokenQuery, AdminGenerateTokenQueryVariables>(
    adminGenerateTokenQuery,
    {
      variables: {
        forId: expertId,
        userType: 'expert',
      },
    },
  );
  const adminSupportChannelId =
    expertDetails && expertDetails.adminSupportChannel
      ? expertDetails.adminSupportChannel.id
      : '';
  const {
    data: dataThread,
    error: errorThread,
    loading: loadingThread,
    fetchMore: fetchMoreThread,
    subscribeToMore: subscribeToMoreThread,
  } = useQuery<
    AdminExpertThreadPaginatedQuery,
    AdminExpertThreadPaginatedQueryVariables
  >(adminExpertThreadPaginatedQuery, {
    onCompleted: () => {
      scrollDown();
    },
    skip: !adminSupportChannelId,
    variables: {
      direction: 'before',
      fromDate: initDate.getTime(),
      limit: EVENT_PAGE_LIMIT,
      supportChannelId: adminSupportChannelId,
    },
  });
  if (errorThread) {
    fullErrorCover =
      'Error loading this thread: ' + errorUtils.getErrorMessage(errorThread);
  }
  const cleanThread =
    (dataThread &&
      dataThread.threadPaginated &&
      dataThread.threadPaginated.edges) ||
    [];
  useEffect(() => {
    setTempEdges([]);
    setRemoteEventIds([]);
  }, [adminSupportChannelId]);
  useEffect(() => {
    let unsub: () => void;
    if (adminSupportChannelId) {
      unsub = subscribeToMoreThread<
        AdminExpertThreadEventUpdatedSubscription,
        AdminExpertThreadEventUpdatedSubscriptionVariables
      >({
        document: adminExpertThreadEventUpdatedSubscription,
        onError: (err) => {
          addNotification(
            'Error loading thread. Please refresh and try again: ' +
              err.message,
          );
          console.error('onError: subscribeToMoreThread', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { threadEventUpdated },
            },
          },
        ) => {
          console.log('threadEventUpdated', prev, threadEventUpdated);
          if (!threadEventUpdated || !prev.threadPaginated) {
            return prev;
          }
          if (
            threadEventUpdated.__typename === 'RequestEvent' ||
            threadEventUpdated.__typename === 'Quote'
          ) {
            return prev;
          }
          if (
            (threadEventUpdated.__typename === 'MessageEvent' ||
              threadEventUpdated.__typename === 'EmailMessageEvent' ||
              threadEventUpdated.__typename === 'TextMessageEvent' ||
              threadEventUpdated.__typename === 'MeetingEvent' ||
              threadEventUpdated.__typename === 'PhoneCallEvent') &&
            prev.threadPaginated.id !== threadEventUpdated.supportChannelStr
          ) {
            return prev;
          }
          setTempEdges((prevTempEdges) =>
            prevTempEdges.filter(
              (t) =>
                t.id !== threadEventUpdated.threadEvent &&
                [
                  'tempEmailMessageThreadEventId',
                  'tempTextMessageThreadEventId',
                ].indexOf(t.id) === -1,
            ),
          );
          setRemoteEventIds((prevRemoteEventIds) =>
            prevRemoteEventIds.concat(threadEventUpdated.id),
          );
          const updatingIndex = (prev.threadPaginated.edges || []).findIndex(
            (e) => e.id === threadEventUpdated.threadEvent,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              threadPaginated: {
                __typename: prev.threadPaginated.__typename,
                edges: prev.threadPaginated.edges
                  .slice(0, updatingIndex)
                  .concat({
                    __typename:
                      prev.threadPaginated.edges[updatingIndex].__typename,
                    cursor: prev.threadPaginated.edges[updatingIndex].cursor,
                    id: prev.threadPaginated.edges[updatingIndex].id,
                    node: {
                      ...prev.threadPaginated.edges[updatingIndex].node,
                      ...threadEventUpdated,
                    } as AdminExpertThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
                  })
                  .concat(prev.threadPaginated.edges.slice(updatingIndex + 1)),
                id: prev.threadPaginated.id,
              },
            };
          }
          // add new
          scrollDown();
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: (prev.threadPaginated.edges || []).concat({
                __typename: 'ThreadEventEdge',
                cursor: threadEventUpdated.createdAt,
                id: threadEventUpdated.threadEvent || '',
                node: threadEventUpdated,
              }),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          threadId: adminSupportChannelId,
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [subscribeToMoreThread, adminSupportChannelId, addNotification]);
  const [
    getUserNotes,
    {
      called: calledUserNotes,
      data: dataUserNotes,
      error: errorUserNotes,
      loading: loadingUserNotes,
      subscribeToMore: subscribeToMoreUserNotes,
    },
  ] = useLazyQuery<UserNoteExpertAdminQuery, UserNoteExpertAdminQueryVariables>(
    userNoteAdminQuery,
    {
      variables: {
        supportChannelId: adminSupportChannelId,
      },
    },
  );
  const allUserNotes = (dataUserNotes && dataUserNotes.userNoteForTarget) || [];
  useEffect(() => {
    let unsub: () => void;
    if (adminSupportChannelId && calledUserNotes && subscribeToMoreUserNotes) {
      unsub = subscribeToMoreUserNotes<
        UserNoteExpertUpdatedAdminSubscription,
        UserNoteExpertUpdatedAdminSubscriptionVariables
      >({
        document: adminUserNoteUpdatedSubscription,
        onError: (err) => {
          addNotification(
            'Error loading notes. Please refresh and try again: ' + err.message,
          );
          console.error('onError: subscribeToMoreUserNotes', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { userNoteUpdated },
            },
          },
        ) => {
          console.log('userNoteUpdated', prev, userNoteUpdated);
          if (!userNoteUpdated || !userNoteUpdated.supportChannelStr) {
            return prev;
          }
          if (adminSupportChannelId !== userNoteUpdated.supportChannelStr) {
            return prev;
          }
          const updatingIndex = (prev.userNoteForTarget || []).findIndex(
            (un) => un.id === userNoteUpdated.id,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              userNoteForTarget: prev.userNoteForTarget
                .slice(0, updatingIndex)
                .concat({
                  ...prev.userNoteForTarget[updatingIndex],
                  ...userNoteUpdated,
                })
                .concat(prev.userNoteForTarget.slice(updatingIndex + 1)),
            };
          }
          return {
            userNoteForTarget: (prev.userNoteForTarget || []).concat(
              userNoteUpdated,
            ),
          };
        },
        variables: {
          targetId: adminSupportChannelId,
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [
    subscribeToMoreUserNotes,
    adminSupportChannelId,
    calledUserNotes,
    addNotification,
  ]);
  const [tryEditNote] = useMutation<
    UserNoteExpertEditAdminMutation,
    UserNoteExpertEditAdminMutationVariables
  >(userNoteEditAdminMutation);
  function onSaveNote(body: string, userNoteId?: string) {
    if (noteLoading || !adminSupportChannelId) return;
    setNoteLoading(true);
    tryEditNote({
      variables: {
        body,
        supportChannelId: adminSupportChannelId,
        userNoteId,
      },
    })
      .then(() => {
        setNoteLoading(false);
      })
      .catch((err: ApolloError) => {
        setNoteLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Edit Note Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'onSaveNote',
        });
      });
  }
  useEffect(() => {
    // check for drafts
    const hasEmailDraft = tokenUtils.readLocalStorage(
      expertId + 'support-email',
    );
    setEmailDraft(!!hasEmailDraft);
    if (hasEmailDraft) {
      setEmailSubject(
        tokenUtils.readLocalStorage(expertId + 'support-email-subject') || '',
      );
    } else {
      setEmailSubject('');
    }
    const hasTextDraft = tokenUtils.readLocalStorage(expertId + 'support-text');
    setTextDraft(!!hasTextDraft);
    if (hasTextDraft) {
      setTextContent(
        tokenUtils.readLocalStorage(expertId + 'support-text-content') || '',
      );
    } else {
      setTextContent('');
    }
  }, [adminSupportChannelId, expertId]);
  const [trySetReferrer] = useMutation<
    ExpertSetReferrerMutation,
    ExpertSetReferrerMutationVariables
  >(expertSetReferrerMutation);
  function confirmExpertReferrer(expertReferrerId?: string) {
    trySetReferrer({
      variables: {
        expertId,
        expertReferrerId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Set Referrer Error');
      logError(err, {
        component: 'AdminExpertDetail',
        func: 'trySetReferrer',
      });
    });
  }
  const [tryExpertSearch] = useMutation<
    ExpertSearchForAdminExpertMutation,
    ExpertSearchForAdminExpertMutationVariables
  >(expertSearchForAdminExpertMutation);
  function onSearchStartExperts() {
    console.log('onSearchStartExperts', searchInputExperts);
    if (!searchInputExperts || searchInputExperts.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    tryExpertSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        if (dataMatchSearch && dataMatchSearch.expertSearchForAdmin) {
          console.log('tryExpertSearch', dataMatchSearch.expertSearchForAdmin);
          setSearchResultsExperts(dataMatchSearch.expertSearchForAdmin);
        }
      },
      variables: {
        onlyActives: true,
        searchQuery: searchInputExperts,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Expert Search Error');
      logError(err, {
        component: 'AdminExpertDetail',
        func: 'tryExpertSearch',
      });
      setSearchResultsExperts(null);
    });
  }
  function handleSearchInputExpertsKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStartExperts();
    }
  }
  const [tryEditExpert] = useMutation<
    ExpertEditByAdminMutation,
    ExpertEditByAdminMutationVariables
  >(expertEditByAdminMutation);
  function onSaveEditingInfo() {
    if (actionLoading) return;
    if (
      !editExpertFirst ||
      !editExpertLast ||
      !editExpertPersonalEmail ||
      !editExpertPublicEmail ||
      !editExpertLocationTimezone ||
      !editExpertStartDate ||
      !editExpertStatus
    ) {
      addNotification(
        'Please submit first/last name, personal email, public email, timezone, and expert status at least.',
        undefined,
        5000,
      );
      return;
    }
    if (editExpertClaimsAllowed <= 0) {
      addNotification(
        'Claims Allowed must be at least 1. Switch status to remove claims completely.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryEditExpert({
      variables: {
        claimsAllowed: editExpertClaimsAllowed,
        clientEscalations: editExpertClientEscalations,
        expertId,
        firstName: editExpertFirst,
        grandfatheredGMVLevel: editExpertGrandfatheredGMVLevel,
        inSpotlight: editExpertInSpotlight === 'true',
        isAgency: editExpertIsAgency === 'true',
        isAutoApproveBlocked: editExpertIsAutoApproveBlocked === 'true',
        isNoKeyAccounts: editExpertIsNoKeyAccounts === 'true',
        isOnlyAdminTag: editExpertIsOnlyAdminTag === 'true',
        isOnlyMatchingTags: editExpertIsOnlyMatchingTags === 'true',
        lastName: editExpertLast,
        locationContinent: editExpertLocationContinent,
        locationCountry: editExpertLocationCountry,
        locationStr: editExpertLocationStr,
        locationTimezone: editExpertLocationTimezone,
        photo: editExpertPhoto,
        primaryPersonalEmail: editExpertPersonalEmail,
        primaryPersonalPhone: editExpertPersonalPhone,
        primaryPublicEmail: editExpertPublicEmail,
        profileBandwidth: editExpertProfileBandwidth,
        profileBeyond: editExpertProfileBeyond,
        profileBio: editExpertProfileBio,
        profileClients: editExpertProfileClients,
        profileEmailIntro: editExpertProfileEmailIntro,
        profileHourlyRate: editExpertProfileHourlyRate,
        profileLanguages: editExpertProfileLanguages,
        profilePath: editExpertProfilePath.replace('/', ''),
        profilePortfolioUrl: editExpertProfilePortfolioUrl,
        profileQuotes: editExpertProfileQuotes
          .filter((q) => !!q && !!q.text.trim() && !!q.attribution.trim())
          .map((q) => ({
            attribution: q.attribution,
            text: q.text.replace('"', ''),
          })),
        profileStartingProject: editExpertProfileStartingProject,
        profileStartingRetainer: editExpertProfileStartingRetainer,
        profileTagline: editExpertProfileTagline,
        recipientId: editExpertRecipientId,
        secondaryPersonalEmails: editExpertSecondaryPersonalEmails.filter(
          (se) => !!se && !!se.trim(),
        ),
        secondaryPublicEmails: editExpertSecondaryPublicEmails,
        shouldExcludeFromKpiReports: editExpertExcludeKpis === 'true',
        skillLevel: editExpertSkillLevel,
        slackId: editExpertSlackId,
        startDate: editExpertStartDate.utc().startOf('day').valueOf(),
        status: editExpertStatus,
        tagFocus: editExpertTagFocus,
        tagSkills: editExpertTagSkills,
        tagTools: editExpertTagTools,
      },
    })
      .then(() => {
        setActionLoading(false);
        setIsEditingInfo(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Edit Expert Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'tryEditExpert',
        });
      });
  }
  function startEditingInfo() {
    if (!expertDetails) {
      return;
    }
    setIsEditingInfo(true);
    setEditExpertClaimsAllowed(expertDetails.claimsAllowed);
    setEditExpertClientEscalations(expertDetails.clientEscalations || 0);
    setEditExpertFirst(expertDetails.firstName || '');
    setEditExpertLast(expertDetails.lastName || '');
    setEditExpertPersonalEmail(expertDetails.primaryPersonalEmail || '');
    setEditExpertSecondaryPersonalEmails(
      (expertDetails.secondaryPersonalEmails || []).filter(
        (se) => se !== expertDetails.primaryPersonalEmail,
      ),
    );
    setEditExpertPublicEmail(expertDetails.primaryPublicEmail || '');
    setEditExpertSecondaryPublicEmails(
      (expertDetails.secondaryPublicEmails || []).filter(
        (se) => se !== expertDetails.primaryPublicEmail,
      ),
    );
    setEditExpertPersonalPhone(expertDetails.primaryPersonalPhone || '');
    setEditExpertPersonalPhoneValid(true);
    setEditExpertExcludeKpis(
      expertDetails.shouldExcludeFromKpiReports ? 'true' : 'false',
    );
    setEditExpertInSpotlight(expertDetails.inSpotlight ? 'true' : 'false');
    setEditExpertIsAgency(expertDetails.isAgency ? 'true' : 'false');
    setEditExpertIsAutoApproveBlocked(
      expertDetails.isAutoApproveBlocked ? 'true' : 'false',
    );
    setEditExpertIsNoKeyAccounts(
      expertDetails.isNoKeyAccounts ? 'true' : 'false',
    );
    setEditExpertIsOnlyAdminTag(
      expertDetails.isOnlyAdminTag ? 'true' : 'false',
    );
    setEditExpertIsOnlyMatchingTags(
      expertDetails.isOnlyMatchingTags ? 'true' : 'false',
    );
    setEditExpertGrandfatheredGMVLevel(
      expertDetails.grandfatheredGMVLevel || '',
    );
    setEditExpertLocationContinent(expertDetails.locationContinent || '');
    setEditExpertLocationCountry(expertDetails.locationCountry || '');
    setEditExpertLocationStr(expertDetails.locationStr || '');
    setEditExpertLocationTimezone(expertDetails.locationTimezone || '');
    setEditExpertProfileBeyond(expertDetails.profileBeyond || '');
    setEditExpertProfileBio(expertDetails.profileBio || '');
    setEditExpertProfileClients(expertDetails.profileClients || '');
    setEditExpertProfileEmailIntro(expertDetails.profileEmailIntro || '');
    setEditExpertProfilePath(expertDetails.profilePath || '');
    setEditExpertProfileQuotes(expertDetails.profileQuotes || []);
    setEditExpertProfileTagline(expertDetails.profileTagline || '');
    setEditExpertProfileBandwidth(expertDetails.profileBandwidth || '');
    setEditExpertProfileHourlyRate(expertDetails.profileHourlyRate || 0);
    setEditExpertProfileStartingProject(
      expertDetails.profileStartingProject || 0,
    );
    setEditExpertProfileStartingRetainer(
      expertDetails.profileStartingRetainer || 0,
    );
    setEditExpertProfileLanguages(expertDetails.profileLanguages || '');
    setEditExpertProfilePortfolioUrl(expertDetails.profilePortfolioUrl || '');
    setEditExpertRecipientId(expertDetails.recipientId || '');
    setEditExpertStatus(expertDetails.status || '');
    setEditExpertSkillLevel(expertDetails.skillLevel || '');
    setEditExpertSlackId(expertDetails.slackId || '');
    setEditExpertTagFocus(expertDetails.tagFocus || []);
    setEditExpertTagSkills(expertDetails.tagSkills || []);
    setEditExpertTagTools(expertDetails.tagTools || []);
    setEditExpertPhoto(expertDetails.photo || '');
    setEditExpertStartDate(
      expertDetails.startedAt
        ? moment(expertDetails.startedAt).utc().startOf('day')
        : moment.utc().startOf('day'),
    );
  }
  function phoneNumberChange(phone: string, isValid: boolean) {
    setEditExpertPersonalPhone(phone);
    if (!phone || isValid) {
      setEditExpertPersonalPhoneValid(true);
    }
  }
  function phoneNumberBlur(isValid: boolean) {
    setEditExpertPersonalPhoneValid(!editExpertPersonalPhone || isValid);
  }
  const [tryBuyPhone] = useMutation<
    AdminBuyExpertPhoneMutation,
    AdminBuyExpertPhoneMutationVariables
  >(adminBuyExpertPhoneMutation);
  function onBuyPhone() {
    if (actionLoading || !expertDetails) return;
    if (expertDetails.primaryPublicPhone) {
      addNotification(
        'They already have a public phone, refresh page!',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryBuyPhone({
      variables: {
        expertId,
      },
    })
      .then(() => {
        setActionLoading(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Buy Phone Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'tryBuyPhone',
        });
      });
  }
  const [tryExpertLogout] = useMutation<
    AdminExpertLogoutMutation,
    AdminExpertLogoutMutationVariables
  >(adminExpertLogoutMutation);
  function onExpertLogout() {
    if (actionLoading || !expertDetails) return;
    setActionLoading(true);
    tryExpertLogout({
      variables: {
        expertId,
      },
    })
      .then(() => {
        setActionLoading(false);
        addNotification('This expert has now been logged out', 'GOOD', 5000);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Buy Phone Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'tryExpertLogout',
        });
      });
  }
  const [
    getBalanceDetails,
    {
      called: calledBalanceDetails,
      data: dataBalanceDetails,
      error: errorBalanceDetails,
      loading: loadingBalanceDetails,
      refetch: refetchBalanceDetails,
    },
  ] = useLazyQuery<
    AccountBalanceDetailsAdminQuery,
    AccountBalanceDetailsAdminQueryVariables
  >(accountBalanceDetailsAdminQuery, {
    returnPartialData: true,
  });
  const [tryDebitBalance] = useMutation<
    AccountBalanceDebitMutation,
    AccountBalanceDebitMutationVariables
  >(accountBalanceDebitMutation, {
    onCompleted: () => {
      if (refetchBalanceDetails) {
        refetchBalanceDetails().catch((err: ApolloError) => {
          addNotification(errorUtils.getErrorMessage(err) || 'Refetch Error');
          logError(err, {
            component: 'AdminExpertDetail',
            func: 'refetchBalanceDetails',
          });
        });
      }
    },
  });
  const [tryCreditBalance] = useMutation<
    AccountBalanceCreditMutation,
    AccountBalanceCreditMutationVariables
  >(accountBalanceCreditMutation, {
    onCompleted: () => {
      if (refetchBalanceDetails) {
        refetchBalanceDetails().catch((err: ApolloError) => {
          addNotification(errorUtils.getErrorMessage(err) || 'Refetch Error');
          logError(err, {
            component: 'AdminExpertDetail',
            func: 'refetchBalanceDetails',
          });
        });
      }
    },
  });
  const balanceDetails =
    dataBalanceDetails && dataBalanceDetails.accountBalanceDetailsAdmin;
  let balanceStr = '0';
  if (balanceDetails && balanceDetails.balance) {
    balanceStr = formatNumberWithCommas(
      centsDollarsDecimal(balanceDetails.balance),
    );
  }
  const [tryMarkReadUnread] = useMutation<
    SupportChannelMarkAsReadUnreadAdminMutation,
    SupportChannelMarkAsReadUnreadAdminMutationVariables
  >(supportChannelMarkAsReadUnreadAdminMutation);
  function markAsRead() {
    if (adminLoading || !expertDetails || !expertDetails.adminSupportChannel)
      return;
    setAdminLoading(true);
    tryMarkReadUnread({
      optimisticResponse: {
        supportChannelMarkAsReadUnread: {
          ...expertDetails.adminSupportChannel,
          adminUnread: null,
        },
      },
      variables: {
        supportChannelId: adminSupportChannelId,
      },
    })
      .then(() => {
        setAdminLoading(false);
      })
      .catch((err: ApolloError) => {
        setAdminLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Mark As Read Error',
        );
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'tryMarkReadUnread',
        });
      });
  }
  function markAsUnread() {
    if (adminLoading || !expertDetails || !expertDetails.adminSupportChannel)
      return;
    setAdminLoading(true);
    tryMarkReadUnread({
      optimisticResponse: {
        supportChannelMarkAsReadUnread: {
          ...expertDetails.adminSupportChannel,
          adminUnread: 'MANUAL_MARKED_UNREAD',
        },
      },
      variables: {
        supportChannelId: adminSupportChannelId,
        unreadReason: 'MANUAL_MARKED_UNREAD',
      },
    })
      .then(() => {
        setAdminLoading(false);
      })
      .catch((err: ApolloError) => {
        setAdminLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Mark As Unread Error',
        );
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'markAsUnread',
        });
      });
  }
  const [tryAssignAdmin] = useMutation<
    SupportChannelAssignAdminMutation,
    SupportChannelAssignAdminMutationVariables
  >(supportChannelAssignAdminMutation);
  function onAssignAdmin(theAssignment: string) {
    if (adminLoading || !expertDetails || !expertDetails.adminSupportChannel)
      return;
    setAdminLoading(true);
    tryAssignAdmin({
      variables: {
        adminAssignment: theAssignment,
        supportChannelId: expertDetails.adminSupportChannel.id,
      },
    })
      .then(() => {
        setAdminLoading(false);
      })
      .catch((err: ApolloError) => {
        setAdminLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Assign Admin Error',
        );
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'tryAssignAdmin',
        });
      });
  }
  const [trySnoozeAdmin] = useMutation<
    SupportChannelSnoozeAdminMutation,
    SupportChannelSnoozeAdminMutationVariables
  >(supportChannelSnoozeAdminMutation);
  function onSnoozeAdmin(snoozeDayValue?: string) {
    if (adminLoading || !expertDetails || !expertDetails.adminSupportChannel)
      return;
    let untilDate: Date;
    if (snoozeDayValue) {
      const days = parseInt(snoozeDayValue, 10);
      untilDate = moment().add(days, 'days').toDate();
    } else {
      untilDate = moment().subtract(1, 'days').toDate();
    }
    setAdminLoading(true);
    trySnoozeAdmin({
      variables: {
        supportChannelId: expertDetails.adminSupportChannel.id,
        untilDate: untilDate.getTime(),
      },
    })
      .then(() => {
        setAdminLoading(false);
      })
      .catch((err: ApolloError) => {
        setAdminLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Snooze Admin Error',
        );
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'trySnoozeAdmin',
        });
      });
  }
  const [trySendEmailMessage] = useMutation<
    AdminThreadSendEmailMessageExpertMutation,
    AdminThreadSendEmailMessageExpertMutationVariables
  >(adminThreadSendEmailMessageExpertMutation);
  function onSendEmail(drafHtml: string) {
    if (sendLoading || !expertDetails || !adminSupportChannelId) return;
    if (!emailSubject.trim() || !drafHtml) {
      addNotification(
        'You need a subject, recipients, and an email body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    trySendEmailMessage({
      optimisticResponse: {
        threadSendEmailMessageByAdmin: {
          __typename: 'EmailMessageEvent',
          content: drafHtml,
          contentExpanded: null,
          createdAt: Date.now(),
          files: emailFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempEmailMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: null,
            orphanStr: null,
            supportChannelStr: adminSupportChannelId,
          })),
          id: 'tempEmailMessageEventId',
          isTransactional: false,
          matchStr: null,
          noExpertRecipientsFound: [],
          orphanStr: null,
          ownerExpert: {
            __typename: 'Expert',
            firstName: 'Storetasker',
            id: SUPPORT_EXPERT_ID,
            lastName: 'Support',
            photo: '',
          },
          ownerHuman: null,
          quote: null,
          recipientCopiedExperts: [],
          recipientExpert: {
            ...expertDetails,
            id: expertId,
          },
          recipientHumans: [],
          sendingError: null,
          sendingStatus: 'DELIVERED',
          subject: emailSubject,
          supportChannelStr: adminSupportChannelId,
          threadEvent: 'tempEmailMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentEmailMutation }) => {
        if (
          dataSentEmailMutation &&
          dataSentEmailMutation.threadSendEmailMessageByAdmin
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempEmailMessageThreadEventId' &&
              e.id !==
                dataSentEmailMutation.threadSendEmailMessageByAdmin.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataSentEmailMutation.threadSendEmailMessageByAdmin.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor:
                dataSentEmailMutation.threadSendEmailMessageByAdmin.createdAt,
              id: dataSentEmailMutation.threadSendEmailMessageByAdmin
                .threadEvent,
              node: dataSentEmailMutation.threadSendEmailMessageByAdmin,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          files: emailFiles,
          html: drafHtml,
          recipientHumans: [],
          subject: emailSubject,
          supportChannelId: adminSupportChannelId,
        },
      },
    })
      .then(() => {
        onDeleteEmailDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempEmailMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Send Email Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'sendEmailMessage',
        });
      });
  }
  const [trySendTextMessage] = useMutation<
    AdminThreadSendTextMessageExpertMutation,
    AdminThreadSendTextMessageExpertMutationVariables
  >(adminThreadSendTextMessageExpertMutation);
  function onSendText() {
    if (sendLoading || !expertDetails || !adminSupportChannelId) return;
    if (!textContent) {
      addNotification(
        'You need recipients and a message body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    trySendTextMessage({
      optimisticResponse: {
        threadSendTextMessageByAdmin: {
          __typename: 'TextMessageEvent',
          content: textContent,
          createdAt: Date.now(),
          files: textFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempTextMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: null,
            orphanStr: null,
            supportChannelStr: adminSupportChannelId,
          })),
          id: 'tempTextMessageEventId',
          isTransactional: false,
          matchStr: null,
          orphanStr: null,
          ownerExpert: {
            __typename: 'Expert',
            firstName: 'Storetasker',
            id: SUPPORT_EXPERT_ID,
            lastName: 'Support',
            photo: '',
          },
          ownerHuman: null,
          quote: null,
          recipientExpert: {
            ...expertDetails,
            id: expertId,
          },
          recipientHumans: [],
          sendingError: null,
          supportChannelStr: adminSupportChannelId,
          threadEvent: 'tempTextMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentTextMutation }) => {
        if (
          dataSentTextMutation &&
          dataSentTextMutation.threadSendTextMessageByAdmin
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempTextMessageThreadEventId' &&
              e.id !==
                dataSentTextMutation.threadSendTextMessageByAdmin.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataSentTextMutation.threadSendTextMessageByAdmin.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor:
                dataSentTextMutation.threadSendTextMessageByAdmin.createdAt,
              id: dataSentTextMutation.threadSendTextMessageByAdmin.threadEvent,
              node: dataSentTextMutation.threadSendTextMessageByAdmin,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          content: textContent,
          files: textFiles,
          recipientHuman: '',
          supportChannelId: adminSupportChannelId,
        },
      },
    })
      .then(() => {
        onDeleteTextDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempTextMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Send Text Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'sendTextMessage',
        });
      });
  }
  function groupThreadEvents(
    events: AdminExpertThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    const groupedEvents: IGroupedEvents[] = [];
    events.forEach((ev) => {
      let isSameEventType = false;
      let isSameSenderSide = false;
      let isSameHuman = false;
      let isCurrGroupable = false;
      let isLastGroupable = false;
      const isFirstGroup = !groupedEvents.length;
      if (
        !isFirstGroup &&
        (ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'MessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent')
      ) {
        isCurrGroupable = true;
        isLastGroupable = true;
        const lastGroup = groupedEvents[groupedEvents.length - 1];
        const lastGroupEvent = lastGroup.group[0];
        // is same event type as last
        if (lastGroupEvent.node.__typename === ev.node.__typename) {
          isSameEventType = true;
          // is both sent from expert or both from Human
          isSameSenderSide =
            !!(ev.node.ownerHuman && lastGroupEvent.node.ownerHuman) ||
            !!(ev.node.ownerExpert && lastGroupEvent.node.ownerExpert);
          // is human interacting same
          isSameHuman =
            (ev.node.ownerHuman &&
              lastGroupEvent.node.ownerHuman &&
              ev.node.ownerHuman.id === lastGroupEvent.node.ownerHuman.id) ||
            (ev.node.recipientHumans[0] &&
              lastGroupEvent.node.recipientHumans[0] &&
              ev.node.recipientHumans[0].id ===
                lastGroupEvent.node.recipientHumans[0].id);
          // don't group texts with quotes attached
          if (ev.node.__typename === 'TextMessageEvent') {
            if (ev.node.quote) {
              isCurrGroupable = false;
            }
          }
          if (lastGroupEvent.node.__typename === 'TextMessageEvent') {
            if (lastGroupEvent.node.quote) {
              isLastGroupable = false;
            }
          }
        }
      }
      if (
        isFirstGroup ||
        !isCurrGroupable ||
        !isLastGroupable ||
        !isSameEventType ||
        !isSameSenderSide ||
        !isSameHuman
      ) {
        groupedEvents.push({
          group: [ev],
        });
      } else {
        groupedEvents[groupedEvents.length - 1].group.push(ev);
      }
    });
    return groupedEvents;
  }
  const totalEdges = cleanThread.concat(tempEdges);
  const overPageLimit = totalEdges.length >= EVENT_PAGE_LIMIT;
  const threadEventEdges = _.uniqBy(totalEdges, 'id').sort(
    (a, b) => a.cursor - b.cursor,
  );
  function scrollDown() {
    scrollDownQuickTimeout = setTimeout(() => {
      if (threadEventsWindow && threadEventsWindow.current) {
        threadEventsWindow.current.scrollTop =
          threadEventsWindow.current.scrollHeight;
      }
    }, 5);
  }
  useEffect(() => {
    function onScroll() {
      if (threadEventsWindow && threadEventsWindow.current) {
        const scrollTemp = threadEventsWindow.current.scrollTop;
        if (
          scrollTemp < 500 &&
          overPageLimit &&
          canLoadMore &&
          !isLoadingEvents
        ) {
          isLoadingEvents = true;
          const heightTemp = threadEventsWindow.current.scrollHeight;
          fetchMoreThread({
            updateQuery: (prev, { fetchMoreResult }) => {
              if (
                !fetchMoreResult ||
                !prev.threadPaginated ||
                !fetchMoreResult.threadPaginated
              )
                return prev;
              if (
                fetchMoreResult.threadPaginated.edges.length < EVENT_PAGE_LIMIT
              ) {
                setCanLoadMore(false);
              }
              return {
                threadPaginated: {
                  __typename: prev.threadPaginated.__typename,
                  edges: _.uniqBy(
                    fetchMoreResult.threadPaginated.edges.concat(
                      prev.threadPaginated.edges || [],
                    ),
                    'id',
                  ),
                  id: prev.threadPaginated.id,
                },
              };
            },
            variables: {
              direction: 'before',
              fromDate: threadEventEdges.filter(
                (ev) => ev.node.id && remoteEventIds.indexOf(ev.node.id) === -1,
              )[0].cursor,
              limit: EVENT_PAGE_LIMIT,
              supportChannelId: adminSupportChannelId,
            },
          })
            .catch((err: ApolloError) => {
              addNotification(
                errorUtils.getErrorMessage(err) || 'Load More Error',
              );
              logError(err, {
                component: 'AdminExpertDetail',
                func: 'fetchMoreThread',
              });
            })
            .finally(() => {
              isLoadingEvents = false;
              if (threadEventsWindow && threadEventsWindow.current) {
                threadEventsWindow.current.scrollTop =
                  Math.max(
                    threadEventsWindow.current.scrollHeight - heightTemp,
                    0,
                  ) + scrollTemp;
              }
            });
        }
      }
    }
    if (threadEventsWindow && threadEventsWindow.current) {
      threadEventsWindow.current.addEventListener('scroll', onScroll);
    }
    const tempWindow = threadEventsWindow.current;
    return () => {
      if (tempWindow) {
        tempWindow.removeEventListener('scroll', onScroll);
      }
    };
  }, [
    canLoadMore,
    overPageLimit,
    addNotification,
    fetchMoreThread,
    adminSupportChannelId,
    threadEventEdges,
    remoteEventIds,
  ]);
  useEffect(() => {
    scrollDown();
    const scrollDownEffectTimeout = setTimeout(() => {
      scrollDown();
    }, 500);
    return () => {
      if (scrollDownEffectTimeout) {
        clearTimeout(scrollDownEffectTimeout);
      }
      if (scrollDownQuickTimeout) {
        clearTimeout(scrollDownQuickTimeout);
      }
    };
  }, [adminSupportChannelId]);
  const threadEventGroups = groupThreadEvents(threadEventEdges);
  function generateComponentsForGroup(
    groupedEvents: AdminExpertThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    return groupedEvents
      .map((ev, i) => {
        if (
          ev.node.__typename === 'RequestEvent' ||
          ev.node.__typename === 'MeetingEvent' ||
          ev.node.__typename === 'Quote'
        ) {
          return <div key={ev.id} />;
        }
        if (ev.node.__typename === 'MessageEvent') {
          return (
            <MessageThreadEvent
              key={ev.id}
              expertId={SUPPORT_EXPERT_ID}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              messageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        if (ev.node.__typename === 'EmailMessageEvent') {
          return (
            <EmailMessageThreadEvent
              key={ev.id}
              expertId={SUPPORT_EXPERT_ID}
              emailMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
              isAdmin
            />
          );
        }
        if (ev.node.__typename === 'TextMessageEvent') {
          return (
            <TextMessageThreadEvent
              key={ev.id}
              expertId={SUPPORT_EXPERT_ID}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              textMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        if (ev.node.__typename === 'PhoneCallEvent') {
          return (
            <PhoneCallThreadEvent
              key={ev.id}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              phoneCallEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        console.log('Missing ThreadEvent type');
        return null;
      })
      .filter((c) => c);
  }
  function onReplyClick(
    edge: AdminExpertThreadPaginatedQuery['threadPaginated']['edges'][0],
  ) {
    console.log('onReplyClick', edge.id);
    if (edge.node.__typename === 'EmailMessageEvent') {
      const newSubject = replyToSubject(edge.node.subject);
      openEmailEditor(newSubject);
    } else if (edge.node.__typename === 'TextMessageEvent') {
      openTextEditor();
    } else if (edge.node.__typename === 'PhoneCallEvent') {
      openTextEditor();
    } else if (edge.node.__typename === 'MessageEvent') {
      openEmailEditor();
    } else {
      scrollDown();
    }
  }
  function closeCurrentAction() {
    setCurrentAction('');
    scrollDown();
  }
  function openEmailEditor(
    subject?: string,
    scenario?: string,
    content?: string,
    isSwitchFromText?: boolean,
  ) {
    // check currentAction
    if (isSwitchFromText) {
      onDeleteTextDraft();
    } else if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    // check draft
    if (emailDraft) {
      subject =
        tokenUtils.readLocalStorage(expertId + 'support-email-subject') || '';
      content =
        tokenUtils.readLocalStorage(expertId + 'support-email-content') || '';
    }
    if (subject === undefined && content === undefined) {
      const lastEmail = _.findLast(
        threadEventEdges,
        (ev) => ev.node.__typename === 'EmailMessageEvent',
      );
      // look for the latest email
      if (lastEmail) {
        // simulate as if they clicked that email
        onReplyClick(lastEmail);
        return;
      }
    }
    if (!content) {
      if (scenario) {
        // FUTURE customer support templates
      }
      content = templateUtils.getDefaultEmail(
        expertDetails
          ? [
              {
                firstName: expertDetails.firstName,
                lastName: expertDetails.lastName,
              },
            ]
          : [],
        {
          firstName: 'Storetasker Support',
          lastName: '',
        },
      );
    }
    setCurrentAction('EMAIL');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    setEmailSubject(subject || '');
    setEmailContent(content || '');
    setEmailFiles([]);
    setEmailStatus(emailDraft ? 'Saved' : '');
  }
  const debouncedEmailDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(expertId + 'support-email', 'true');
    tokenUtils.writeLocalStorage(
      expertId + 'support-email-subject',
      emailSubject,
    );
    tokenUtils.writeLocalStorage(
      expertId + 'support-email-content',
      emailContent,
    );
    setEmailDraft(true);
    setEmailStatus('Saved');
  }, 1000);
  function onDeleteEmailDraft() {
    debouncedEmailDraft.cancel();
    tokenUtils.removeLocalStorage(expertId + 'support-email');
    tokenUtils.removeLocalStorage(expertId + 'support-email-subject');
    tokenUtils.removeLocalStorage(expertId + 'support-email-content');
    setEmailDraft(false);
    setEmailStatus('');
    setEmailSubject('');
    setEmailContent('');
    setEmailFiles([]);
    setCurrentAction('');
  }
  function onSaveEmailDraft(
    subject: string,
    draftContent: string | null,
    files: IFilestackFileUpload[],
  ) {
    let saveDraft = false;
    if (emailSubject !== subject) {
      saveDraft = true;
      setEmailSubject(subject || '');
    }
    if (emailFiles !== files) {
      setEmailFiles(files || []);
    }
    if (draftContent !== null && emailContent !== draftContent) {
      saveDraft = true;
      setEmailContent(draftContent);
    }
    if (saveDraft) {
      setEmailStatus('Saving');
      debouncedEmailDraft.callback();
    }
  }
  function openTextEditor(
    scenario?: string,
    content?: string,
    isSwitchFromEmail?: boolean,
  ) {
    // check currentAction
    if (isSwitchFromEmail) {
      onDeleteEmailDraft();
    } else if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    // check draft
    if (textDraft) {
      content =
        tokenUtils.readLocalStorage(expertId + 'support-text-content') || '';
    }
    if (!content) {
      if (scenario) {
        // FUTURE customer support templates
      }
      content = templateUtils.getDefaultText();
    }
    setCurrentAction('TEXT');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    setTextContent(content || '');
    setTextFiles([]);
    setTextStatus(textDraft ? 'Saved' : '');
  }
  const debouncedTextDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(expertId + 'support-text', 'true');
    tokenUtils.writeLocalStorage(
      expertId + 'support-text-content',
      textContent,
    );
    setTextDraft(true);
    setTextStatus('Saved');
  }, 1000);
  function onDeleteTextDraft() {
    debouncedTextDraft.cancel();
    tokenUtils.removeLocalStorage(expertId + 'support-text');
    tokenUtils.removeLocalStorage(expertId + 'support-text-content');
    setTextDraft(false);
    setTextStatus('');
    setTextContent('');
    setTextFiles([]);
    setCurrentAction('');
  }
  function onSaveTextDraft(content: string, files: IFilestackFileUpload[]) {
    let saveDraft = false;
    if (textFiles !== files) {
      setTextFiles(files || []);
    }
    if (textContent !== content) {
      saveDraft = true;
      setTextContent(content);
    }
    if (saveDraft) {
      setTextStatus('Saving');
      debouncedTextDraft.callback();
    }
  }
  function renderCurrentActionEditor() {
    if (currentAction === 'EMAIL') {
      return (
        <div
          className={
            'ThreadActionEmail ' +
            (sendLoading ? ' ThreadActionEmailSending ' : '')
          }
        >
          <EmailEditor
            emailTemplates={emailTemplates}
            expertId={SUPPORT_EXPERT_ID}
            files={emailFiles}
            fixedRecipients={expertDetails ? [expertDetails] : []}
            initContent={emailContent}
            isAdmin
            onDeleteDraft={onDeleteEmailDraft}
            onSaveDraft={onSaveEmailDraft}
            onSendEmail={onSendEmail}
            recipients={[]}
            subject={emailSubject}
            status={emailStatus}
            switchToTextEditor={(contentStr?: string) =>
              openTextEditor(undefined, contentStr, true)
            }
          />
        </div>
      );
    }
    if (currentAction === 'TEXT') {
      return (
        <div
          className={
            'ThreadActionText ' +
            (sendLoading ? ' ThreadActionTextSending ' : '')
          }
        >
          <TextEditor
            expertId={SUPPORT_EXPERT_ID}
            files={textFiles}
            fixedRecipients={expertDetails ? [expertDetails] : []}
            content={textContent}
            onDeleteDraft={onDeleteTextDraft}
            onSaveDraft={onSaveTextDraft}
            onSendText={onSendText}
            recipients={[]}
            status={textStatus}
            switchToEmailEditor={(contentStr?: string) =>
              openEmailEditor(undefined, undefined, contentStr, true)
            }
          />
        </div>
      );
    }
    return null;
  }
  const [tryThreadSearch] = useMutation<
    AdminThreadSearchExpertMutation,
    AdminThreadSearchExpertMutationVariables
  >(adminThreadSearchExpertMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!adminSupportChannelId) return;
    if (!searchInput) {
      toggleSearch();
      return;
    }
    if (searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchResults([]);
    tryThreadSearch({
      update: (_cache, { data: dataThreadSearch }) => {
        setSearchLoading(false);
        if (dataThreadSearch && dataThreadSearch.threadSearch) {
          console.log('tryThreadSearch', dataThreadSearch.threadSearch);
          setSearchResults(
            dataThreadSearch.threadSearch
              .slice()
              .sort((a, b) => b.score - a.score),
          );
        }
      },
      variables: {
        limit: 5,
        searchQuery: searchInput,
        supportChannelId: adminSupportChannelId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Thread Search Error');
      logError(err, {
        component: 'AdminExpertDetail',
        func: 'tryThreadSearch',
      });
      setSearchLoading(false);
      setSearchResults(null);
    });
  }
  function onSelectSearchResult(
    s: AdminThreadSearchExpertMutation['threadSearch'][0],
  ) {
    setSelectedSearchResult(s);
    setShowSidebar(false);
  }
  function blockClickPropagation(ev: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  function renderSearchResult(
    s: AdminThreadSearchExpertMutation['threadSearch'][0],
  ) {
    const nowMoment = moment.tz(initTime, moment.tz.guess());
    const eventMoment = moment.tz(s.node.createdAt, moment.tz.guess());
    const eventDateFull =
      eventMoment.format('MMMM Do, YYYY') +
      ' at ' +
      eventMoment.format('h:mma z');
    let eventDateSummary = eventMoment.format('M/D/YY');
    if (nowMoment.isSame(eventMoment, 'day')) {
      eventDateSummary = eventMoment.format('h:mma');
    } else if (
      nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')
    ) {
      eventDateSummary = 'Yesterday';
    } else if (nowMoment.isSame(eventMoment, 'week')) {
      eventDateSummary = eventMoment.format('dddd');
    } else if (nowMoment.isSame(eventMoment, 'year')) {
      eventDateSummary = eventMoment.format('MMMM Do');
    }
    const isFromExpert = !s.node.ownerHuman;
    const primaryHuman:
      | Extract<
          AdminExpertThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
          { __typename?: 'EmailMessageEvent' | undefined }
        >['ownerHuman']
      | undefined = s.node.ownerHuman || undefined;
    let humanRecipients: Exclude<
      Extract<
        AdminExpertThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
        { __typename?: 'EmailMessageEvent' | undefined }
      >['ownerHuman'],
      null | undefined
    >[] = [];
    if (
      s.node.__typename === 'MessageEvent' ||
      s.node.__typename === 'EmailMessageEvent' ||
      s.node.__typename === 'TextMessageEvent' ||
      s.node.__typename === 'MeetingEvent' ||
      s.node.__typename === 'PhoneCallEvent'
    ) {
      humanRecipients = s.node.recipientHumans || [];
    }
    // find highlights in text
    let beforeHighlight = '';
    let inHighlight = '';
    let afterHighlight = '';
    const charsBeforeAfter = 70;
    const highlightContent = s.highlightsContent[0] || '';
    if (highlightContent && s.plainTextContent) {
      const highlightIndex = s.plainTextContent.indexOf(highlightContent);
      if (highlightIndex >= 0) {
        const beforeIndex = Math.max(0, highlightIndex - charsBeforeAfter);
        const afterIndex =
          highlightIndex + highlightContent.length + charsBeforeAfter;
        const textAroundHighlight =
          (beforeIndex > 0 ? '...' : '') +
          s.plainTextContent.slice(beforeIndex, afterIndex) +
          (afterIndex < s.plainTextContent.length - 1 ? '...' : '');
        const newHighlightIndex = textAroundHighlight.indexOf(highlightContent);
        if (newHighlightIndex >= 0) {
          beforeHighlight = textAroundHighlight.slice(0, newHighlightIndex);
          inHighlight = highlightContent;
          afterHighlight = textAroundHighlight.slice(
            newHighlightIndex + highlightContent.length,
          );
        }
      }
    }
    return (
      <div
        className={
          'ThreadDetailSidebarSearchResult ' +
          (selectedSearchResult && selectedSearchResult.id === s.id
            ? ' ThreadDetailSidebarSearchResultSelected '
            : '')
        }
        key={s.id}
      >
        <div
          className="ThreadDetailSidebarSearchResultCard"
          onClick={() => onSelectSearchResult(s)}
        >
          <div className="ThreadDetailSidebarSearchResultCardRecipients">
            {primaryHuman && (
              <HumanUserBubble
                firstName={primaryHuman.firstName}
                lastName={primaryHuman.lastName}
                fallbackEmail={primaryHuman.primaryEmail}
                fallbackPhone={primaryHuman.primaryPhone}
                initialsOnly
                primary
              />
            )}
            {humanRecipients.map((r) => (
              <HumanUserBubble
                key={r.id}
                firstName={r.firstName}
                lastName={r.lastName}
                fallbackEmail={r.primaryEmail}
                fallbackPhone={r.primaryPhone}
                initialsOnly
              />
            ))}
            {(s.node.__typename === 'MessageEvent' ||
              s.node.__typename === 'EmailMessageEvent' ||
              s.node.__typename === 'TextMessageEvent' ||
              s.node.__typename === 'MeetingEvent' ||
              s.node.__typename === 'PhoneCallEvent') &&
              s.node.recipientExpert && (
                <ExpertUserBubble
                  expertId={s.node.recipientExpert.id}
                  expertDetails={s.node.recipientExpert}
                />
              )}
          </div>
          {!!isFromExpert &&
            (s.node.__typename === 'MessageEvent' ||
              s.node.__typename === 'EmailMessageEvent' ||
              s.node.__typename === 'TextMessageEvent' ||
              s.node.__typename === 'MeetingEvent' ||
              s.node.__typename === 'PhoneCallEvent' ||
              s.node.__typename === 'Quote') && (
              <div className="ThreadDetailSidebarSearchResultCardFromExpert">
                <ExpertUserBubble
                  expertId={(s.node.ownerExpert && s.node.ownerExpert.id) || ''}
                  expertDetails={s.node.ownerExpert}
                  primary
                />
              </div>
            )}
          <div className="ThreadDetailSidebarSearchResultCardSubject">
            {s.plainTextSubject || ''}
          </div>
          {!inHighlight && (
            <div className="ThreadDetailSidebarSearchResultCardText">
              {s.plainTextContent.length > 2 * charsBeforeAfter
                ? s.plainTextContent.slice(0, 2 * charsBeforeAfter) + '...'
                : s.plainTextContent}
            </div>
          )}
          {inHighlight && (
            <div className="ThreadDetailSidebarSearchResultCardText">
              {beforeHighlight}
              <span>{inHighlight}</span>
              {afterHighlight}
            </div>
          )}
        </div>
        <div className="ThreadDetailSidebarSearchResultBottom">
          <div className="ThreadDetailSidebarSearchResultBottomLeft">
            {searchResultType(s.node.__typename!)}
          </div>
          <div
            className="ThreadDetailSidebarSearchResultBottomRight"
            data-tip={eventDateFull}
          >
            {eventDateSummary}
          </div>
        </div>
      </div>
    );
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      toggleSearch();
    }
  }
  function toggleSearch() {
    const nextShowSearch = !showSearch;
    setSearchInput('');
    setSearchResults(null);
    setSelectedSearchResult(null);
    setSearchLoading(false);
    setShowSearch(nextShowSearch);
    if (nextShowSearch) {
      setTimeout(() => {
        if (searchRef && searchRef.current) {
          searchRef.current.focus();
        }
      });
    }
  }
  const {
    data: dataProjectPaginated,
    error: errorProjectPaginated,
    loading: loadingProjectPaginated,
    fetchMore: fetchMoreProjectPaginated,
  } = useQuery<ProjectPaginateExpertQuery, ProjectPaginateExpertQueryVariables>(
    projectPaginateExpertQuery,
    {
      variables: {
        direction: 'BACKWARD',
        expertId,
        fromDate: initDate.getTime(),
        limit: ACTIVITY_PAGE_LIMIT,
      },
    },
  );
  const {
    data: dataMatchPaginated,
    error: errorMatchPaginated,
    loading: loadingMatchPaginated,
    fetchMore: fetchMoreMatchPaginated,
  } = useQuery<MatchPaginateExpertQuery, MatchPaginateExpertQueryVariables>(
    matchPaginateExpertQuery,
    {
      variables: {
        direction: 'BACKWARD',
        expertId,
        fromDate: initDate.getTime(),
        limit: ACTIVITY_PAGE_LIMIT,
      },
    },
  );
  const allMatchesForExpert = _.uniqBy(
    [
      ...((dataMatchPaginated && dataMatchPaginated.matchPaginateExpert) || []),
    ].filter((r) => r.expertStr === expertId),
    'id',
  ).sort((a, b) => b.lastUpdated - a.lastUpdated);
  const oldestUpdatedMatch =
    allMatchesForExpert[allMatchesForExpert.length - 1];
  function loadMoreMatches() {
    fetchMoreMatchPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.matchPaginateExpert)
          return prev;
        if (fetchMoreResult.matchPaginateExpert.length < ACTIVITY_PAGE_LIMIT) {
          setShowLoadMoreMatches(false);
        }
        return {
          matchPaginateExpert: (prev.matchPaginateExpert || []).concat(
            fetchMoreResult.matchPaginateExpert,
          ),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate: oldestUpdatedMatch.lastUpdated,
        limit: ACTIVITY_PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'AdminExpertDetail',
        func: 'loadMoreMatches',
      });
    });
  }
  const allProjectsForExpert = _.uniqBy(
    [
      ...((dataProjectPaginated &&
        dataProjectPaginated.projectPaginateExpert) ||
        []),
    ].filter((r) => r.expertStr === expertId),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  const oldestCreatedProject =
    allProjectsForExpert[allProjectsForExpert.length - 1];
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
    if (
      panelName === 'KPIs' &&
      !calledBalanceDetails &&
      expertDetails &&
      expertDetails.recipientId
    ) {
      getBalanceDetails({
        variables: {
          expertId,
          recipientId: expertDetails.recipientId,
        },
      }).catch((err: ApolloError) => {
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'getBalanceDetails',
        });
      });
    } else if (
      panelName === 'Notes' &&
      !calledUserNotes &&
      adminSupportChannelId
    ) {
      getUserNotes().catch((err: ApolloError) => {
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'getUserNotes',
        });
      });
    }
  }
  function loadMoreProjects() {
    fetchMoreProjectPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.projectPaginateExpert)
          return prev;
        if (
          fetchMoreResult.projectPaginateExpert.length < ACTIVITY_PAGE_LIMIT
        ) {
          setShowLoadMoreProjects(false);
        }
        return {
          projectPaginateExpert: (prev.projectPaginateExpert || []).concat(
            fetchMoreResult.projectPaginateExpert,
          ),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate: oldestCreatedProject.createdAt,
        limit: ACTIVITY_PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'AdminExpertDetail',
        func: 'loadMoreProjects',
      });
    });
  }
  function fileError(error: Error) {
    console.log('Filestack fileError', error);
    addNotification(
      (error && error.message) || 'File upload error. Please try again.',
      undefined,
      5000,
    );
  }
  function fileUploaded(filestackRes: any) {
    console.log('Filestack fileUploaded', filestackRes);
    if (
      filestackRes &&
      filestackRes.filesUploaded &&
      filestackRes.filesUploaded[0] &&
      filestackRes.filesUploaded[0].url
    ) {
      setEditExpertPhoto(filestackRes.filesUploaded[0].url as string);
    }
  }
  const {
    data: dataAccountBalanceEventPaginated,
    error: errorAccountBalanceEventPaginated,
    loading: loadingAccountBalanceEventPaginated,
    fetchMore: fetchMoreAccountBalanceEventPaginated,
  } = useQuery<
    AccountBalanceEventPaginateAdminQuery,
    AccountBalanceEventPaginateAdminQueryVariables
  >(accountBalanceEventPaginateAdminQuery, {
    variables: {
      direction: 'BACKWARD',
      expertId,
      fromDate: initDate.getTime(),
      limit: PAGE_LIMIT,
    },
  });
  const allAccountBalanceEvents = _.uniqBy(
    [
      ...((dataAccountBalanceEventPaginated &&
        dataAccountBalanceEventPaginated.accountBalanceEventPaginateAdmin) ||
        []),
    ]
      .concat(tempBalanceEvents)
      .filter((abe) => abe.ownerExpertStr === expertId),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  function loadMoreBalanceEvents() {
    fetchMoreAccountBalanceEventPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (
          !fetchMoreResult ||
          !fetchMoreResult.accountBalanceEventPaginateAdmin
        )
          return prev;
        if (
          fetchMoreResult.accountBalanceEventPaginateAdmin.length < PAGE_LIMIT
        ) {
          setShowLoadMoreBalance(false);
        }
        return {
          accountBalanceEventPaginateAdmin: (
            prev.accountBalanceEventPaginateAdmin || []
          ).concat(fetchMoreResult.accountBalanceEventPaginateAdmin),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate:
          allAccountBalanceEvents[allAccountBalanceEvents.length - 1].createdAt,
        limit: PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'AdminExpertDetail',
        func: 'loadMoreBalanceEvents',
      });
    });
  }
  useEffect(() => {
    const loadTime = new Date();
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminExpertDetail socketClient onReconnected');
      refetchExpert().catch(() => {});
      if (refetchBalanceDetails) refetchBalanceDetails().catch(() => {});
      fetchMoreThread({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !prev.threadPaginated ||
            !fetchMoreResult.threadPaginated
          )
            return prev;
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: _.uniqBy(
                fetchMoreResult.threadPaginated.edges.concat(
                  prev.threadPaginated.edges || [],
                ),
                'id',
              ),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          direction: 'after',
          fromDate: loadTime.getTime(),
          limit: 50,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'reconnect fetchMoreThread',
        });
      });
      fetchMoreProjectPaginated({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            projectPaginateExpert: (prev.projectPaginateExpert || []).concat(
              fetchMoreResult.projectPaginateExpert,
            ),
          };
        },
        variables: {
          direction: 'FORWARD',
          fromDate: loadTime.getTime(),
          limit: 5,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'reconnect loadMoreProjects',
        });
      });
      fetchMoreAccountBalanceEventPaginated({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            accountBalanceEventPaginateAdmin: (
              prev.accountBalanceEventPaginateAdmin || []
            ).concat(fetchMoreResult.accountBalanceEventPaginateAdmin),
          };
        },
        variables: {
          direction: 'FORWARD',
          fromDate: loadTime.getTime(),
          limit: 5,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'reconnect loadMoreBalanceEvents',
        });
      });
      fetchMoreMatchPaginated({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            matchPaginateExpert: (prev.matchPaginateExpert || []).concat(
              fetchMoreResult.matchPaginateExpert,
            ),
          };
        },
        variables: {
          direction: 'FORWARD',
          fromDate: loadTime.getTime(),
          limit: 5,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'reconnect loadMoreMatches',
        });
      });
    });
    return () => {
      reconnectedListener();
    };
  }, [
    socketClient,
    refetchExpert,
    refetchBalanceDetails,
    fetchMoreThread,
    fetchMoreAccountBalanceEventPaginated,
    fetchMoreMatchPaginated,
    fetchMoreProjectPaginated,
    addNotification,
  ]);

  const [trySetPartnerDirectories] = useMutation<
    ExpertSetPartnerDirectoriesMutation,
    ExpertSetPartnerDirectoriesMutationVariables
  >(expertSetPartnerDirectoriesMutation);
  function onRemoveSpecific(removeSpecific: string) {
    if (!expertDetails || !expertDetails.partnerDirectories) {
      return;
    }
    trySetPartnerDirectories({
      variables: {
        expertId,
        partnerDirectories: expertDetails.partnerDirectories
          .filter((p) => p.id !== removeSpecific)
          .map((p) => p.id),
      },
    })
      .then(() => {
        setSearchInputPartners('');
        setSearchResultsPartners(null);
      })
      .catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Remove Specifc Error',
        );
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'onRemoveSpecific',
        });
      });
  }
  function onAddSpecific(addSpecific: string) {
    if (!expertDetails || !expertDetails.partnerDirectories) {
      return;
    }
    trySetPartnerDirectories({
      variables: {
        expertId,
        partnerDirectories: expertDetails.partnerDirectories
          .map((p) => p.id)
          .concat(addSpecific),
      },
    })
      .then(() => {
        setSearchInputPartners('');
        setSearchResultsPartners(null);
      })
      .catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Add Specifc Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'onAddSpecific',
        });
      });
  }
  const [tryPartnerSearch] = useMutation<
    PartnerSearchForAdminExpertMutation,
    PartnerSearchForAdminExpertMutationVariables
  >(partnerSearchForAdminExpertMutation);
  function onSearchStartPartners() {
    console.log('onSearchStartPartners', searchInputPartners);
    if (!searchInputPartners || searchInputPartners.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    tryPartnerSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        if (dataMatchSearch && dataMatchSearch.partnerSearchForAdmin) {
          console.log('tryHumanSearch', dataMatchSearch.partnerSearchForAdmin);
          setSearchResultsPartners(dataMatchSearch.partnerSearchForAdmin);
        }
      },
      variables: {
        searchQuery: searchInputPartners,
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Partner Search Error',
      );
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'tryPartnerSearch',
      });
      setSearchResultsPartners(null);
    });
  }
  function handleSearchInputPartnersKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStartPartners();
    }
  }
  function onCreditBalance() {
    if (actionLoading) return;
    if (!balanceReason || !balanceAmountVal || balanceAmountVal <= 0) {
      addNotification('Please submit all the fields!', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryCreditBalance({
      update: (_cache, { data: dataCreditMutation }) => {
        if (dataCreditMutation && dataCreditMutation.accountBalanceCredit) {
          const nextTempEdges = tempBalanceEvents.filter(
            (e) => e.id !== dataCreditMutation.accountBalanceCredit.id,
          );
          if (
            !allAccountBalanceEvents.find(
              (e) => e.id === dataCreditMutation.accountBalanceCredit.id,
            )
          ) {
            nextTempEdges.push(dataCreditMutation.accountBalanceCredit);
          }
          setTempBalanceEvents(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        amount: balanceAmountVal,
        expertId,
        reason: balanceReason,
      },
    })
      .then(() => {
        setActionLoading(false);
        setBalanceAmount('');
        setBalanceReason('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Credit Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'tryCreditBalance',
        });
      });
  }
  function onDebitBalance() {
    if (actionLoading) return;
    if (!balanceReason || !balanceAmountVal || balanceAmountVal <= 0) {
      addNotification('Please submit all the fields!', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryDebitBalance({
      update: (_cache, { data: dataDebitMutation }) => {
        if (dataDebitMutation && dataDebitMutation.accountBalanceDebit) {
          const nextTempEdges = tempBalanceEvents.filter(
            (e) => e.id !== dataDebitMutation.accountBalanceDebit.id,
          );
          if (
            !allAccountBalanceEvents.find(
              (e) => e.id === dataDebitMutation.accountBalanceDebit.id,
            )
          ) {
            nextTempEdges.push(dataDebitMutation.accountBalanceDebit);
          }
          setTempBalanceEvents(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        amount: balanceAmountVal,
        expertId,
        reason: balanceReason,
      },
    })
      .then(() => {
        setActionLoading(false);
        setBalanceAmount('');
        setBalanceReason('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Debit Error');
        logError(err, {
          component: 'AdminExpertDetail',
          func: 'tryDebitBalance',
        });
      });
  }
  function updateBalance() {
    if (balanceAction === 'credit') {
      onCreditBalance();
    } else {
      onDebitBalance();
    }
  }
  const levelStr = expertDetails
    ? getExpertLevelStr(expertDetails.levelEstimate, expertDetails.createdAt)
    : null;
  const balanceUpdateStr =
    balanceAction === 'credit' ? 'Add Credit' : 'Debit Account';
  const secondaryPanels = ['About', 'Activity', 'KPIs', 'Notes'].filter(
    (p) => p !== primaryPanel,
  );
  const quoteValuesStr =
    expertDetails &&
    expertDetails.profileQuotes &&
    expertDetails.profileQuotes.length
      ? expertDetails.profileQuotes
          .map((q) => '"' + q.text + '" - ' + q.attribution)
          .join(', ')
      : 'No quotes set.';
  if (
    loadingExpert ||
    loadingThread ||
    loadingUserNotes ||
    loadingMatchPaginated ||
    loadingProjectPaginated ||
    loadingBalanceDetails ||
    loadingAccountBalanceEventPaginated
  ) {
    // ignore these
  }
  let loginToken = '';
  if (!errorToken && !loadingToken && dataToken) {
    loginToken = dataToken.adminGenerateToken || '';
  }
  const loginUrl =
    envUtils.pickWebAppUrlForUserType('expert') + '?token=' + loginToken;
  const profileUrl = envUtils.pickWebAppUrlForUserType('web') + '/experts/';
  return (
    <div className="DashboardModal AdminExpertDetailModal">
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '') +
          (selectedSearchResult ? ' ThreadDetailViewWithModal ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/experts')
              }
              className="ThreadDetailMainHeaderBack"
            >
              {expertDetails
                ? expertDetails.firstName + ' ' + expertDetails.lastName
                : 'Back'}
            </div>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div
            className="ThreadDetailMainBody AdminExpertDetailView"
            ref={threadEventsWindow}
          >
            <div
              className={
                'ThreadEvents ' +
                (currentAction ? ' ThreadEventsHasAction ' : '')
              }
            >
              {threadEventGroups.map((evGroup) => (
                <div className="ThreadEventGroup" key={evGroup.group[0].id}>
                  {generateComponentsForGroup(evGroup.group)}
                </div>
              ))}
            </div>
            {!!currentAction && !!expertDetails && (
              <div className="ThreadCurrentAction">
                {renderCurrentActionEditor()}
              </div>
            )}
          </div>
          <div
            className={
              'ThreadDetailActions ' +
              (openActions ? ' ThreadDetailActionsOpen ' : '') +
              (currentAction ? ' ThreadDetailActionsCurrent ' : '')
            }
          >
            <div className="ThreadDetailActionRow">
              <div
                className="ThreadDetailActionItem"
                onClick={() => openTextEditor()}
              >
                <span>send a </span>text
              </div>
              <div
                className="ThreadDetailActionItem"
                onClick={() => openEmailEditor()}
              >
                <span>send an </span>email
              </div>
            </div>
            <div className="ThreadDetailActionRow">
              <div
                className="ThreadDetailActionTrigger"
                onClick={() =>
                  currentAction
                    ? closeCurrentAction()
                    : setOpenActions(!openActions)
                }
              >
                <div className="ThreadDetailActionBtn">+</div>
                <div className="ThreadDetailActionText">actions</div>
              </div>
            </div>
          </div>
          {!!selectedSearchResult && (
            <div className="ThreadDetailMainFocusModal" onClick={toggleSearch}>
              <div
                className="ThreadDetailMainFocusModalContainer ThreadDetailMainFocusModalContainerSearch"
                onClick={blockClickPropagation}
              >
                <ThreadSearchResults
                  expertId={SUPPORT_EXPERT_ID}
                  supportChannelId={adminSupportChannelId}
                  searchResultFound={selectedSearchResult}
                />
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={toggleSearch}
                >
                  close
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div className="ThreadDetailSidebar">
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
            <div
              className={
                'ThreadDetailSidebarHeaderSearch ThreadDetailSidebarHeaderSearch' +
                (showSearch ? 'Active ' : '')
              }
            >
              <div className="ThreadDetailSidebarHeaderSearchBar">
                <input
                  type="text"
                  ref={searchRef}
                  className="ThreadDetailSidebarHeaderSearchBarInput"
                  placeholder="Search"
                  autoComplete="new-off"
                  spellCheck="false"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={handleSearchInputKeyDown}
                />
                <div
                  className="ThreadDetailSidebarHeaderSearchToggle"
                  onClick={toggleSearch}
                />
              </div>
            </div>
          </div>
          <div className="ThreadDetailSidebarBody">
            {!searchLoading && searchResults === null ? (
              <div className="ThreadDetailSidebarBodyPanels">
                {primaryPanel === 'About' && expertDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                    <div className="ThreadDetailSidebarUpNextItems">
                      {expertDetails.adminSupportChannel &&
                        expertDetails.adminSupportChannel.adminUnread && (
                          <div className="ThreadDetailSidebarUpNextItem">
                            <div
                              className="ThreadDetailSidebarUpNextUnread"
                              onClick={markAsRead}
                            >
                              <div className="ThreadDetailSidebarUpNextUnreadSummary">
                                {unreadReasonFormatted(
                                  expertDetails.adminSupportChannel.adminUnread,
                                )}
                              </div>
                              <div className="ThreadDetailSidebarUpNextUnreadClose" />
                            </div>
                          </div>
                        )}
                      {!!emailDraft && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardDraft ThreadDetailSidebarUpNextCardDraftEmail">
                            <div
                              className="ThreadDetailSidebarUpNextCardHeader"
                              onClick={() => openEmailEditor()}
                            >
                              <div className="ThreadDetailSidebarUpNextCardType">
                                Draft Email
                              </div>
                              <div className="ThreadDetailSidebarUpNextCardSubtitle">
                                {emailSubject}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!!textDraft && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardDraft ThreadDetailSidebarUpNextCardDraftEmail">
                            <div
                              className="ThreadDetailSidebarUpNextCardHeader"
                              onClick={() => openTextEditor()}
                            >
                              <div className="ThreadDetailSidebarUpNextCardType">
                                Draft Text
                              </div>
                              <div className="ThreadDetailSidebarUpNextCardSubtitle">
                                {textContent}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {isEditingInfo ? (
                      <div className="ThreadDetailSidebarAdminSection ThreadDetailSidebarAdminSectionPaddingTop">
                        <div
                          className="ThreadDetailSidebarAdminSectionTopRightBtn"
                          onClick={() => setIsEditingInfo(false)}
                        >
                          cancel
                        </div>
                        <div className="ThreadDetailSidebarAdminForm">
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            First Name
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="First Name"
                            value={editExpertFirst}
                            onChange={(e) =>
                              setEditExpertFirst(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Last Name
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Last Name"
                            value={editExpertLast}
                            onChange={(e) =>
                              setEditExpertLast(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Personal Phone Number
                          </div>
                          <PhoneNumberInput
                            className={
                              'ThreadDetailSidebarAdminFormInput ' +
                              (!editExpertPersonalPhoneValid
                                ? ' ThreadDetailSidebarAdminFormInputInvalid '
                                : '')
                            }
                            value={editExpertPersonalPhone}
                            onChange={phoneNumberChange}
                            onBlur={phoneNumberBlur}
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Primary Personal Email
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="email"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Primary Personal Email"
                            value={editExpertPersonalEmail}
                            onChange={(e) =>
                              setEditExpertPersonalEmail(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Secondary Personal Emails
                          </div>
                          {editExpertSecondaryPersonalEmails.map((se, i) => (
                            <input
                              key={i}
                              className="ThreadDetailSidebarAdminFormInput ThreadDetailSidebarAdminFormInputNoBottom"
                              type="email"
                              autoComplete="new-off"
                              spellCheck="false"
                              placeholder="Secondary Personal Email Address"
                              value={se}
                              onChange={(e) =>
                                setEditExpertSecondaryPersonalEmails(
                                  editExpertSecondaryPersonalEmails
                                    .slice(0, i)
                                    .concat(e.currentTarget.value)
                                    .concat(
                                      editExpertSecondaryPersonalEmails.slice(
                                        i + 1,
                                      ),
                                    ),
                                )
                              }
                            />
                          ))}
                          <div
                            className="ThreadDetailSidebarAdminFormExtra"
                            onClick={() =>
                              setEditExpertSecondaryPersonalEmails(
                                editExpertSecondaryPersonalEmails.concat(''),
                              )
                            }
                          >
                            Add another personal email?
                          </div>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Unique Profile Path (joe-mello)
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="joe-mello"
                            value={editExpertProfilePath}
                            onChange={(e) =>
                              setEditExpertProfilePath(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Primary Public Email
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="email"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Primary Public Email"
                            value={editExpertPublicEmail}
                            onChange={(e) =>
                              setEditExpertPublicEmail(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Secondary Public Emails
                          </div>
                          {editExpertSecondaryPublicEmails.map((se, i) => (
                            <input
                              key={i}
                              className="ThreadDetailSidebarAdminFormInput ThreadDetailSidebarAdminFormInputNoBottom"
                              type="email"
                              autoComplete="new-off"
                              spellCheck="false"
                              placeholder="Secondary Public Email Address"
                              value={se}
                              onChange={(e) =>
                                setEditExpertSecondaryPublicEmails(
                                  editExpertSecondaryPublicEmails
                                    .slice(0, i)
                                    .concat(e.currentTarget.value)
                                    .concat(
                                      editExpertSecondaryPublicEmails.slice(
                                        i + 1,
                                      ),
                                    ),
                                )
                              }
                            />
                          ))}
                          <div
                            className="ThreadDetailSidebarAdminFormExtra"
                            onClick={() =>
                              setEditExpertSecondaryPublicEmails(
                                editExpertSecondaryPublicEmails.concat(''),
                              )
                            }
                          >
                            Add another public email?
                          </div>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Payment Rails ID
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Payment Rails ID"
                            value={editExpertRecipientId}
                            onChange={(e) =>
                              setEditExpertRecipientId(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Location
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Location"
                            value={editExpertLocationStr}
                            onChange={(e) =>
                              setEditExpertLocationStr(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Country
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertLocationCountry}
                            onChange={(e) =>
                              setEditExpertLocationCountry(
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value="" disabled>
                              --
                            </option>
                            {COUNTRIES.map((c) => (
                              <option key={c} value={c}>
                                {c}
                              </option>
                            ))}
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Continent
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertLocationContinent}
                            onChange={(e) =>
                              setEditExpertLocationContinent(
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value="" disabled>
                              --
                            </option>
                            {CONTINENTS.map((c) => (
                              <option key={c} value={c}>
                                {c}
                              </option>
                            ))}
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Claims Allowed
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="number"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Claims Allowed"
                            value={editExpertClaimsAllowed}
                            min="1"
                            onChange={(e) =>
                              setEditExpertClaimsAllowed(
                                Number(e.currentTarget.value),
                              )
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Client Escalations
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="number"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Client Escalations"
                            value={editExpertClientEscalations}
                            min="0"
                            onChange={(e) =>
                              setEditExpertClientEscalations(
                                Number(e.currentTarget.value),
                              )
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Timezone
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertLocationTimezone}
                            onChange={(e) =>
                              setEditExpertLocationTimezone(
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value="" disabled>
                              Timezone
                            </option>
                            {timezones.map((tzObj) => (
                              <option key={tzObj.tz} value={tzObj.tz}>
                                (GMT{moment.tz(tzObj.tz).format('Z')}){' '}
                                {tzObj.tz}
                              </option>
                            ))}
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Status
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertStatus}
                            onChange={(e) =>
                              setEditExpertStatus(e.currentTarget.value)
                            }
                          >
                            <option value="pending">pending</option>
                            <option value="new">new</option>
                            <option value="active">active</option>
                            <option value="back">back of queue</option>
                            <option value="no-match">no matches</option>
                            <option value="no-match-quote">
                              no matches or quotes
                            </option>
                            <option value="churned">churned</option>
                            <option value="winback">winback</option>
                            <option value="inactive">
                              inactive / blacklist
                            </option>
                            <option value="hq">hq team</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Skill Level
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertSkillLevel}
                            onChange={(e) =>
                              setEditExpertSkillLevel(e.currentTarget.value)
                            }
                          >
                            <option value="ALL_STAR">All Star</option>
                            <option value="RISING_STAR">Rising Star</option>
                            <option value="DEPENDABLE">Dependable</option>
                            <option value="UNKNOWN">Unknown</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitleCounter">
                            Focus
                            <div className="ThreadDetailSidebarAdminFormTitleCounterAmount">
                              {editExpertTagFocus.length} / 1
                            </div>
                          </div>
                          <MultiSelect
                            options={FOCUS_OPTIONS}
                            currValues={editExpertTagFocus}
                            onChange={setEditExpertTagFocus}
                            withTags
                            max={1}
                          />
                          <div className="ThreadDetailSidebarAdminFormTitleCounter">
                            Skills
                            <div className="ThreadDetailSidebarAdminFormTitleCounterAmount">
                              {editExpertTagSkills.length} / 5
                            </div>
                          </div>
                          <MultiSelect
                            options={SKILL_OPTIONS}
                            currValues={editExpertTagSkills}
                            onChange={setEditExpertTagSkills}
                            withTags
                            max={5}
                          />
                          <div className="ThreadDetailSidebarAdminFormTitleCounter">
                            Tool Expertise
                            <div className="ThreadDetailSidebarAdminFormTitleCounterAmount">
                              {editExpertTagTools.length} / 8
                            </div>
                          </div>
                          <MultiSelect
                            options={TOOL_OPTIONS}
                            currValues={editExpertTagTools}
                            onChange={setEditExpertTagTools}
                            withTags
                            max={8}
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Slack ID
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Slack ID (U07V8N0JG)"
                            value={editExpertSlackId}
                            onChange={(e) =>
                              setEditExpertSlackId(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Expert Spotlight?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertInSpotlight}
                            onChange={(e) =>
                              setEditExpertInSpotlight(e.currentTarget.value)
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Agency / Team?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertIsAgency}
                            onChange={(e) =>
                              setEditExpertIsAgency(e.currentTarget.value)
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Is blocked from auto approve?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertIsAutoApproveBlocked}
                            onChange={(e) =>
                              setEditExpertIsAutoApproveBlocked(
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Only show matching tags?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertIsOnlyMatchingTags}
                            onChange={(e) =>
                              setEditExpertIsOnlyMatchingTags(
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Never Show Key Accounts
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertIsNoKeyAccounts}
                            onChange={(e) =>
                              setEditExpertIsNoKeyAccounts(
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Only Admin Can Tag?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertIsOnlyAdminTag}
                            onChange={(e) =>
                              setEditExpertIsOnlyAdminTag(e.currentTarget.value)
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Grandfathered GMV Level
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertGrandfatheredGMVLevel}
                            onChange={(e) =>
                              setEditExpertGrandfatheredGMVLevel(
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value="">None</option>
                            <option value="level-1">Level 1</option>
                            <option value="level-2">Level 2</option>
                            <option value="level-3">Level 3</option>
                            <option value="level-4">Level 4</option>
                            <option value="level-5">Level 5</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Bandwidth
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertProfileBandwidth}
                            onChange={(e) =>
                              setEditExpertProfileBandwidth(
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value=""></option>
                            <option value="0_25">0-25%</option>
                            <option value="25_50">25-50%</option>
                            <option value="50_75">50-75%</option>
                            <option value="75_100">75-100%</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Hourly Rate
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="number"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Hourly Rate"
                            value={editExpertProfileHourlyRate}
                            min="0"
                            step={1}
                            onChange={(e) =>
                              setEditExpertProfileHourlyRate(
                                Number(e.currentTarget.value),
                              )
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Starting Project Price
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="number"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Starting Project Price"
                            value={editExpertProfileStartingProject}
                            min="0"
                            step={1}
                            onChange={(e) =>
                              setEditExpertProfileStartingProject(
                                Number(e.currentTarget.value),
                              )
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Starting Project Retainer
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="number"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Starting Project Retainer"
                            value={editExpertProfileStartingRetainer}
                            min="0"
                            step={1}
                            onChange={(e) =>
                              setEditExpertProfileStartingRetainer(
                                Number(e.currentTarget.value),
                              )
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Languages
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Spanish,French"
                            value={editExpertProfileLanguages}
                            onChange={(e) =>
                              setEditExpertProfileLanguages(
                                e.currentTarget.value,
                              )
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Portfolio Url
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Portfolio Url"
                            value={editExpertProfilePortfolioUrl}
                            onChange={(e) =>
                              setEditExpertProfilePortfolioUrl(
                                e.currentTarget.value,
                              )
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Profile Clients
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Red Bull,Nike"
                            value={editExpertProfileClients}
                            onChange={(e) =>
                              setEditExpertProfileClients(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Email Intro Sentence
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Ex: They’ve been a Shopify Developer for over 5 years and previously worked at a notable agency in the eComm world."
                            value={editExpertProfileEmailIntro}
                            onChange={(e) =>
                              setEditExpertProfileEmailIntro(
                                e.currentTarget.value,
                              )
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Profile Tagline
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Ex Red Antler Design Director"
                            value={editExpertProfileTagline}
                            onChange={(e) =>
                              setEditExpertProfileTagline(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Profile Bio
                          </div>
                          <TextareaAutosize
                            type="text"
                            placeholder="Profile bio..."
                            spellCheck="true"
                            className="ThreadDetailSidebarAdminFormInput"
                            value={editExpertProfileBio}
                            onChange={(e) => {
                              setEditExpertProfileBio(e.currentTarget.value);
                            }}
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Profile Beyond Shopify
                          </div>
                          <TextareaAutosize
                            type="text"
                            placeholder="Profile beyond shopify..."
                            spellCheck="true"
                            className="ThreadDetailSidebarAdminFormInput"
                            value={editExpertProfileBeyond}
                            onChange={(e) => {
                              setEditExpertProfileBeyond(e.currentTarget.value);
                            }}
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Profile Quotes
                          </div>
                          {editExpertProfileQuotes.map((q, i) => (
                            <div key={i}>
                              <TextareaAutosize
                                type="text"
                                placeholder="Quote text"
                                spellCheck="true"
                                className="ThreadDetailSidebarAdminFormInput"
                                value={q.text}
                                onChange={(e) => {
                                  setEditExpertProfileQuotes(
                                    editExpertProfileQuotes
                                      .slice(0, i)
                                      .concat({
                                        attribution:
                                          editExpertProfileQuotes[i]
                                            .attribution || '',
                                        text: e.currentTarget.value,
                                      })
                                      .concat(
                                        editExpertProfileQuotes.slice(i + 1),
                                      ),
                                  );
                                }}
                              />
                              <input
                                className="ThreadDetailSidebarAdminFormInput ThreadDetailSidebarAdminFormInputNoBottom"
                                type="email"
                                autoComplete="new-off"
                                spellCheck="false"
                                placeholder="Quote attribution (Anne, Founder of Tomen)"
                                value={q.attribution}
                                onChange={(e) =>
                                  setEditExpertProfileQuotes(
                                    editExpertProfileQuotes
                                      .slice(0, i)
                                      .concat({
                                        attribution: e.currentTarget.value,
                                        text:
                                          editExpertProfileQuotes[i].text || '',
                                      })
                                      .concat(
                                        editExpertProfileQuotes.slice(i + 1),
                                      ),
                                  )
                                }
                              />
                            </div>
                          ))}
                          <div
                            className="ThreadDetailSidebarAdminFormExtra"
                            onClick={() =>
                              setEditExpertProfileQuotes(
                                editExpertProfileQuotes.concat({
                                  attribution: '',
                                  text: '',
                                }),
                              )
                            }
                          >
                            Add another quote?
                          </div>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Exclude from KPIs?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editExpertExcludeKpis}
                            onChange={(e) =>
                              setEditExpertExcludeKpis(e.currentTarget.value)
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Start Date
                          </div>
                          <div className="ThreadDetailSidebarAdminFormDatePicker">
                            <SingleDatePicker
                              date={editExpertStartDate}
                              onDateChange={(date) => {
                                setEditExpertStartDate(date);
                              }}
                              focused={dateFocused}
                              onFocusChange={({ focused }) =>
                                setDateFocused(focused || false)
                              }
                              showClearDate={false}
                              displayFormat="MM/DD/YY"
                              small
                              isOutsideRange={() => false}
                              noBorder
                              hideKeyboardShortcutsPanel
                              numberOfMonths={1}
                              withPortal
                              id="uniqueId"
                              placeholder="Jan 4th"
                            />
                          </div>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Photo
                          </div>
                          {editExpertPhoto ? (
                            <div
                              className="ThreadDetailSidebarAdminFormUpload"
                              onClick={() => setEditExpertPhoto('')}
                            >
                              remove current photo
                            </div>
                          ) : (
                            <ReactFilestack
                              apikey="AGxdviGoVRwWPL6lKEdnXz"
                              actionOptions={{
                                maxFiles: 1,
                                storeTo: {
                                  container: envUtils.pick(
                                    'files.asklorem.com',
                                    'files-dev.asklorem.com',
                                    'files-dev.asklorem.com',
                                  ),
                                  location: 's3',
                                  path: `${expertId}/`,
                                  region: 'us-east-1',
                                },
                              }}
                              onSuccess={fileUploaded}
                              onError={fileError}
                              customRender={({ onPick }: { onPick: any }) => (
                                <div
                                  onMouseDown={onPick}
                                  className="ThreadDetailSidebarAdminFormUpload"
                                >
                                  Attach a file
                                </div>
                              )}
                            />
                          )}
                          <div
                            className={
                              'ThreadDetailSidebarAdminFormAction ' +
                              (actionLoading
                                ? ' ThreadDetailSidebarAdminFormActionLoading '
                                : '')
                            }
                            onClick={onSaveEditingInfo}
                          >
                            Save
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Fragment>
                        <div className="ThreadDetailSidebarAdminSection ThreadDetailSidebarAdminSectionPaddingTop">
                          <div
                            className="ThreadDetailSidebarAdminSectionTopRightBtn"
                            onClick={startEditingInfo}
                          >
                            edit
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Profile
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionBig">
                            {expertDetails.firstName +
                              ' ' +
                              expertDetails.lastName}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Primary public email:{' '}
                            <span className="SelectAllText">
                              {expertDetails.primaryPublicEmail || ''}
                            </span>
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Secondary public emails:{' '}
                            {(expertDetails.secondaryPublicEmails || [])
                              .filter(
                                (se) => se !== expertDetails.primaryPublicEmail,
                              )
                              .map((se, i) => (
                                <Fragment key={i}>
                                  <span className="SelectAllText">{se}</span>,{' '}
                                </Fragment>
                              ))}
                          </div>
                          {expertDetails.primaryPublicPhone ? (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Primary public phone:{' '}
                              <span className="SelectAllText">
                                {formatValidPhoneNumber(
                                  expertDetails.primaryPublicPhone,
                                  true,
                                ) || expertDetails.primaryPublicPhone}
                              </span>
                            </div>
                          ) : (
                            <div
                              className={
                                'ThreadDetailSidebarAdminAction ' +
                                (actionLoading
                                  ? ' ThreadDetailSidebarAdminActionLoading '
                                  : '')
                              }
                              onClick={onBuyPhone}
                            >
                              buy phone for expert
                            </div>
                          )}
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            <a
                              className="ThreadDetailSidebarAboutWebsiteLink"
                              href={profileUrl + expertDetails.profilePath}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Profile path
                            </a>{' '}
                            <span className="SelectAllText">
                              {expertDetails.profilePath || 'missing!!!!!'}
                            </span>
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Location:{' '}
                            <LocationTimestamp
                              locationStr={expertDetails.locationStr}
                              locationTimezone={expertDetails.locationTimezone}
                            />
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Country:{' '}
                            {expertDetails.locationCountry || 'Missing'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Continent:{' '}
                            {expertDetails.locationContinent || 'Missing'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Bandwidth:{' '}
                            {profileBandwidthValue(
                              expertDetails.profileBandwidth || '',
                            ) || 'Missing'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Hourly Rate: $
                            {expertDetails.profileHourlyRate || '0'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Starting Project Price: $
                            {expertDetails.profileStartingProject || '0'}+
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Starting Retainer Price: $
                            {expertDetails.profileStartingRetainer || '0'}+
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Languages:{' '}
                            {(expertDetails.profileLanguages || '')
                              .split(',')
                              .join(', ') || 'missing'}
                          </div>
                          {!!expertDetails.profilePortfolioUrl && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              <a
                                className="ThreadDetailSidebarAboutWebsiteLink"
                                href={expertDetails.profilePortfolioUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Portfolio Url
                              </a>
                            </div>
                          )}
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Clients:{' '}
                            {expertDetails.profileClients ||
                              'No clients found.'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Email Intro Sentence:{' '}
                            {expertDetails.profileEmailIntro ||
                              'No intro sentence found.'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Tagline:{' '}
                            {expertDetails.profileTagline ||
                              'No tagline found.'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionParagraph">
                            Bio:{' '}
                            {expertDetails.profileBio ||
                              'No description found.'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionParagraph">
                            Beyond:{' '}
                            {expertDetails.profileBeyond || 'No beyond found.'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionParagraph">
                            {quoteValuesStr}
                          </div>
                          {expertDetails.photo ? (
                            <img
                              className="ThreadDetailSidebarAdminSectionImage"
                              src={expertDetails.photo}
                              alt="Expert Profile"
                            />
                          ) : (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Photo: MISSING
                            </div>
                          )}
                          <div
                            className={
                              'ThreadDetailSidebarAdminAction ' +
                              (actionLoading
                                ? ' ThreadDetailSidebarAdminActionLoading '
                                : '')
                            }
                            onClick={onExpertLogout}
                          >
                            logout expert now
                          </div>
                        </div>
                        <div className="ThreadDetailSidebarAdminSection">
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Details
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Status: {expertDetails.status || ''}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Primary personal email:{' '}
                            <span className="SelectAllText">
                              {expertDetails.primaryPersonalEmail || ''}
                            </span>
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Secondary personal emails:{' '}
                            {(expertDetails.secondaryPersonalEmails || [])
                              .filter(
                                (se) =>
                                  se !== expertDetails.primaryPersonalEmail,
                              )
                              .map((se, i) => (
                                <Fragment key={i}>
                                  <span className="SelectAllText">{se}</span>,{' '}
                                </Fragment>
                              ))}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Primary personal phone:{' '}
                            <span className="SelectAllText">
                              {formatValidPhoneNumber(
                                expertDetails.primaryPersonalPhone,
                              ) || expertDetails.primaryPersonalPhone}
                            </span>
                          </div>
                          {expertDetails.startedAt && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Start Date:{' '}
                              {moment(expertDetails.startedAt)
                                .utc()
                                .format('MMMM Do, YYYY')}
                            </div>
                          )}
                          {!!expertDetails.grandfatheredGMVLevel && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Grandfathered GMV Level:{' '}
                              {expertDetails.grandfatheredGMVLevel || 'None'}
                            </div>
                          )}
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Skill Level: {expertDetails.skillLevel || 'MISSING'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Claims Allowed: {expertDetails.claimsAllowed}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Client Escalations:{' '}
                            {expertDetails.clientEscalations || 0}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Focus Tags:{' '}
                            {(expertDetails.tagFocus || [])
                              .map(tagLabelFocus)
                              .join(', ')}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Skill Tags:{' '}
                            {(expertDetails.tagSkills || [])
                              .map(tagLabelSkill)
                              .join(', ')}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Tool Tags:{' '}
                            {(expertDetails.tagTools || [])
                              .map(tagLabelTool)
                              .join(', ')}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Slack ID: {expertDetails.slackId || 'missing'}
                          </div>
                          {expertDetails.inSpotlight && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Expert Spotlight: True
                            </div>
                          )}
                          {expertDetails.isAgency && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Is Agency / Team: True
                            </div>
                          )}
                          {expertDetails.isAutoApproveBlocked && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Is blocked from auto-approve: True
                            </div>
                          )}
                          {expertDetails.isNoKeyAccounts && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Never Show Key Accounts: True
                            </div>
                          )}
                          {expertDetails.isOnlyAdminTag && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Is Only Admin Can Tag: True
                            </div>
                          )}
                          {expertDetails.isOnlyMatchingTags && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Is Only Matching Tags: True
                            </div>
                          )}
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Recipient ID:{' '}
                            <span className="SelectAllText">
                              {expertDetails.recipientId || 'missing'}
                            </span>
                          </div>
                          {expertDetails.shouldExcludeFromKpiReports && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Exclude from KPIs: True
                            </div>
                          )}
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            <a
                              className="ThreadDetailSidebarAboutWebsiteLink"
                              href={loginUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Impersonate Expert
                            </a>
                          </div>
                        </div>
                        <div className="ThreadDetailSidebarAdminSection">
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Referred By An Expert?
                          </div>
                          {expertDetails.expertReferral ? (
                            <div>
                              <Link
                                to={
                                  '/expert/' + expertDetails.expertReferral.id
                                }
                                className="AdminLinkedRecord"
                              >
                                <div className="AdminLinkedRecordTitle">
                                  {(expertDetails.expertReferral.firstName ||
                                    '') +
                                    ' ' +
                                    (expertDetails.expertReferral.lastName ||
                                      '')}
                                </div>
                                <div className="AdminLinkedRecordSubtitle">
                                  Referred this expert
                                </div>
                                <div className="AdminLinkedRecordTag">
                                  Expert
                                </div>
                              </Link>
                              <div
                                className="ThreadDetailSidebarAdminSectionRowBtn"
                                onClick={() => confirmExpertReferrer()}
                              >
                                Remove referral?
                              </div>
                            </div>
                          ) : (
                            <div className="ThreadDetailSidebarAdminSearch">
                              <div className="ThreadDetailSidebarAdminSearchBar">
                                <input
                                  type="text"
                                  className="ThreadDetailSidebarAdminSearchBarInput"
                                  autoComplete="new-off"
                                  spellCheck="false"
                                  placeholder="Search"
                                  value={searchInputExperts}
                                  onChange={(e) =>
                                    setSearchInputExperts(e.target.value)
                                  }
                                  onKeyDown={handleSearchInputExpertsKeyDown}
                                />
                              </div>
                              <div className="ThreadDetailSidebarAdminSearchResults">
                                {searchResultsExperts &&
                                  !searchResultsExperts.length && (
                                    <div className="ThreadDetailSidebarAdminEmpty">
                                      No experts found. Try another search query
                                      to find who you&apos;re looking for.
                                    </div>
                                  )}
                                {searchResultsExperts &&
                                  searchResultsExperts.map((expertResult) => (
                                    <div
                                      key={expertResult.id}
                                      className="ThreadDetailSidebarAdminSearchResult"
                                      onClick={() =>
                                        confirmExpertReferrer(expertResult.id)
                                      }
                                    >
                                      <div className="ThreadDetailSidebarAdminSearchResultTitle">
                                        {(
                                          (expertResult.firstName || '') +
                                          ' ' +
                                          (expertResult.lastName || '')
                                        ).trim()}
                                      </div>
                                      <div className="ThreadDetailSidebarAdminSearchResultSubtitle">
                                        {expertResult.primaryPublicEmail || ''}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="ThreadDetailSidebarAdminSection ThreadDetailSidebarAdminSectionMinHeight">
                          {adminLoading ? (
                            <div className="ThreadDetailSidebarAdminSectionLoading" />
                          ) : (
                            <Fragment>
                              {expertDetails.adminSupportChannel &&
                                !expertDetails.adminSupportChannel
                                  .adminUnread && (
                                  <div
                                    className="ThreadDetailSidebarAdminAction"
                                    onClick={markAsUnread}
                                  >
                                    mark as unread
                                  </div>
                                )}
                              {!!expertDetails.adminSupportChannel &&
                              expertDetails.adminSupportChannel
                                .adminSnoozedUntil &&
                              expertDetails.adminSupportChannel
                                .adminSnoozedUntil > initTime ? (
                                <Fragment>
                                  <div className="ThreadDetailSidebarAdminSectionDescription">
                                    Snoozed until:{' '}
                                    {moment(
                                      expertDetails.adminSupportChannel
                                        .adminSnoozedUntil,
                                    ).format('MMMM Do') +
                                      ' at ' +
                                      moment(
                                        expertDetails.adminSupportChannel
                                          .adminSnoozedUntil,
                                      ).format('h:mma z')}
                                  </div>
                                  <div
                                    className="ThreadDetailSidebarAdminAction"
                                    onClick={() => onSnoozeAdmin()}
                                  >
                                    Unsnooze Expert
                                  </div>
                                </Fragment>
                              ) : (
                                <select
                                  className="ThreadDetailSidebarAdminActionSelect"
                                  value={adminSnoozed}
                                  onChange={(e) =>
                                    onSnoozeAdmin(e.currentTarget.value)
                                  }
                                >
                                  <option value="" disabled>
                                    Snooze for X days
                                  </option>
                                  <option value="1">1 Day</option>
                                  <option value="2">2 Days</option>
                                  <option value="3">3 Days</option>
                                  <option value="4">4 Days</option>
                                  <option value="5">5 Days</option>
                                  <option value="6">6 Days</option>
                                  <option value="7">7 Days</option>
                                </select>
                              )}
                              {!!expertDetails.adminSupportChannel &&
                                !!expertDetails.adminSupportChannel
                                  .adminAssigned && (
                                  <div className="ThreadDetailSidebarAdminSectionDescription">
                                    Assigned to:{' '}
                                    {
                                      expertDetails.adminSupportChannel
                                        .adminAssigned
                                    }
                                  </div>
                                )}
                              {!!expertDetails.adminSupportChannel &&
                                expertDetails.adminSupportChannel
                                  .adminAssigned && (
                                  <div
                                    className="ThreadDetailSidebarAdminAction"
                                    onClick={() => onAssignAdmin('')}
                                  >
                                    Remove Assignment:{' '}
                                    {
                                      expertDetails.adminSupportChannel
                                        .adminAssigned
                                    }
                                  </div>
                                )}
                              <select
                                className="ThreadDetailSidebarAdminActionSelect"
                                value={adminAssignment}
                                onChange={(e) =>
                                  onAssignAdmin(e.currentTarget.value)
                                }
                              >
                                <option value="" disabled>
                                  Assign team member
                                </option>
                                <option value="Richard">Richard</option>
                                <option value="Tim">Tim</option>
                                <option value="Robin">Robin</option>
                              </select>
                            </Fragment>
                          )}
                        </div>

                        <div className="ThreadDetailSidebarAdminSection">
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Partner Directories
                          </div>
                          {!(expertDetails.partnerDirectories || []).length && (
                            <div className="ThreadDetailSidebarAdminEmpty">
                              No partner directories yet.
                            </div>
                          )}
                          {(expertDetails.partnerDirectories || []).map((p) => (
                            <div key={p.id}>
                              <Link
                                to={'/extras/partners/' + p.id}
                                className="AdminLinkedRecord AdminLinkedRecordWithBtn"
                              >
                                <div className="AdminLinkedRecordTitle">
                                  {p.partnerName}
                                </div>
                                <div className="AdminLinkedRecordSubtitle">
                                  {p.trackingId}
                                </div>
                                <div className="AdminLinkedRecordTag">
                                  Partner
                                </div>
                              </Link>
                              <div
                                className="ThreadDetailSidebarAdminSectionRowBtn"
                                onClick={() => onRemoveSpecific(p.id)}
                              >
                                Remove from directory?
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="ThreadDetailSidebarAdminSection">
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Add to partner directory?
                          </div>
                          <div className="ThreadDetailSidebarAdminSearch">
                            <div className="ThreadDetailSidebarAdminSearchBar">
                              <input
                                type="text"
                                className="ThreadDetailSidebarAdminSearchBarInput"
                                autoComplete="new-off"
                                spellCheck="false"
                                placeholder="Search"
                                value={searchInputPartners}
                                onChange={(e) =>
                                  setSearchInputPartners(e.target.value)
                                }
                                onKeyDown={handleSearchInputPartnersKeyDown}
                              />
                            </div>
                            <div className="ThreadDetailSidebarAdminSearchResults">
                              {searchResultsPartners &&
                                !searchResultsPartners.length && (
                                  <div className="ThreadDetailSidebarAdminEmpty">
                                    No partners found. Try another search query
                                    to find who you&apos;re looking for.
                                  </div>
                                )}
                              {searchResultsPartners &&
                                searchResultsPartners.map((partnerResult) => (
                                  <div
                                    key={partnerResult.id}
                                    className="ThreadDetailSidebarAdminSearchResult"
                                    onClick={() =>
                                      onAddSpecific(partnerResult.id)
                                    }
                                  >
                                    <div className="ThreadDetailSidebarAdminSearchResultTitle">
                                      {partnerResult.partnerName}
                                    </div>
                                    <div className="ThreadDetailSidebarAdminSearchResultSubtitle">
                                      {partnerResult.trackingId}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                )}
                {primaryPanel === 'Activity' && expertDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelActivity">
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Matches
                      </div>
                      {!allMatchesForExpert.length && (
                        <div className="ThreadDetailSidebarAdminEmpty">
                          {errorMatchPaginated
                            ? errorUtils.getErrorMessage(errorMatchPaginated)
                            : 'No matches yet.'}
                        </div>
                      )}
                      {allMatchesForExpert.map((m) => (
                        <Link
                          key={m.id}
                          to={'/match/' + m.id}
                          className="AdminLinkedRecord"
                        >
                          <div className="AdminLinkedRecordTitle">
                            {m.brand.name || ''}
                          </div>
                          {m.lastUpdated && (
                            <div className="AdminLinkedRecordSubtitle">
                              Updated:{' '}
                              {moment(m.lastUpdated).format('MMMM Do, YYYY')}
                            </div>
                          )}
                          <div className="AdminLinkedRecordTag">Match</div>
                        </Link>
                      ))}
                      {allMatchesForExpert.length >= ACTIVITY_PAGE_LIMIT &&
                        oldestUpdatedMatch &&
                        showLoadMoreMatches && (
                          <div
                            className="AdminListItemLoader"
                            onClick={loadMoreMatches}
                          >
                            load more matches
                          </div>
                        )}
                    </div>
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Projects
                      </div>
                      {!allProjectsForExpert.length && (
                        <div className="ThreadDetailSidebarAdminEmpty">
                          {errorProjectPaginated
                            ? errorUtils.getErrorMessage(
                                errorProjectPaginated,
                              ) || 'Loading Error'
                            : 'No projects yet.'}
                        </div>
                      )}
                      {allProjectsForExpert.map((p) => (
                        <Link
                          key={p.id}
                          to={'/match/' + p.matchStr}
                          className="AdminLinkedRecord"
                        >
                          <div className="AdminLinkedRecordTitle">
                            {(p.quote && p.quote.title) || ''}
                          </div>
                          <div className="AdminLinkedRecordSubtitle">
                            {p.brand.name || ''}
                          </div>
                          <div className="AdminLinkedRecordSubtitle">
                            $
                            {centsDollarsRounded(
                              (p.quote && p.quote.cents) || 0,
                            )}
                            :{' '}
                            {quoteStatusFormatted(
                              (p.quote && p.quote.status) || '',
                            )}
                          </div>
                          {p.createdAt && (
                            <div className="AdminLinkedRecordSubtitle">
                              Created:{' '}
                              {moment(p.createdAt).format('MMMM Do, YYYY')}
                            </div>
                          )}
                          {p.clientFee && (
                            <div className="AdminLinkedRecordSubtitle AdminLinkedRecordSubtitleAlert">
                              Client fee of {amountClientFee(p.clientFee)}%
                            </div>
                          )}
                          {p.discountCode && (
                            <div className="AdminLinkedRecordSubtitle AdminLinkedRecordSubtitleAlert">
                              Client used {amountDiscountCode(p.discountCode)}%
                              off discount code
                            </div>
                          )}
                          <div className="AdminLinkedRecordTag">Project</div>
                        </Link>
                      ))}
                      {allProjectsForExpert.length >= ACTIVITY_PAGE_LIMIT &&
                        oldestCreatedProject &&
                        showLoadMoreProjects && (
                          <div
                            className="AdminListItemLoader"
                            onClick={loadMoreProjects}
                          >
                            load more projects
                          </div>
                        )}
                    </div>
                  </div>
                )}
                {primaryPanel === 'KPIs' && expertDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelKpis">
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        KPIs
                      </div>
                      {expertDetails.kpis &&
                        expertDetails.kpis.updatedAt &&
                        levelStr && (
                          <Fragment>
                            <div className="ThreadDetailSidebarAdminSectionBig">
                              {levelStr.name}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Level Explanation: {levelStr.info}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Total Sales:{' '}
                              {kpiDollars(expertDetails.kpis.gmvTotal || 0)}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Total Clients:{' '}
                              {expertDetails.kpis.clientsTotal || 0}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Avg Account Value:{' '}
                              {kpiDollars(
                                (expertDetails.kpis.gmvTotal || 0) /
                                  (expertDetails.kpis.clientsTotal || 1),
                              )}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.closeRateRecent || 0) <
                                KPI_GOALS['close-rate']
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Close Rate Recent:{' '}
                              {kpiPercentage(
                                expertDetails.kpis.closeRateRecent || 0,
                              )}{' '}
                              vs goal of{' '}
                              {kpiPercentage(KPI_GOALS['close-rate'])}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.closeRateOverall || 0) <
                                KPI_GOALS['close-rate']
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Close Rate Overall:{' '}
                              {kpiPercentage(
                                expertDetails.kpis.closeRateOverall || 0,
                              )}{' '}
                              vs goal of{' '}
                              {kpiPercentage(KPI_GOALS['close-rate'])}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.repeatRateRecent || 0) <
                                KPI_GOALS['repeat-rate']
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Repeat Rate Recent:{' '}
                              {kpiPercentage(
                                expertDetails.kpis.repeatRateRecent || 0,
                              )}{' '}
                              vs goal of{' '}
                              {kpiPercentage(KPI_GOALS['repeat-rate'])}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.repeatRateOverall || 0) <
                                KPI_GOALS['repeat-rate']
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Repeat Rate Overall:{' '}
                              {kpiPercentage(
                                expertDetails.kpis.repeatRateOverall || 0,
                              )}{' '}
                              vs goal of{' '}
                              {kpiPercentage(KPI_GOALS['repeat-rate'])}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.completionRateRecent ||
                                  0) < KPI_GOALS['completion-rate']
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Completion Rate Recent:{' '}
                              {kpiPercentage(
                                expertDetails.kpis.completionRateRecent || 0,
                              )}{' '}
                              vs goal of{' '}
                              {kpiPercentage(KPI_GOALS['completion-rate'])}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.completionRateOverall ||
                                  0) < KPI_GOALS['completion-rate']
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Completion Rate Overall:{' '}
                              {kpiPercentage(
                                expertDetails.kpis.completionRateOverall || 0,
                              )}{' '}
                              vs goal of{' '}
                              {kpiPercentage(KPI_GOALS['completion-rate'])}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.deadlinesMetRecent || 0) <
                                KPI_GOALS['deadlines-met']
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Deadlines Met Recent:{' '}
                              {kpiPercentage(
                                expertDetails.kpis.deadlinesMetRecent || 0,
                              )}{' '}
                              vs goal of{' '}
                              {kpiPercentage(KPI_GOALS['deadlines-met'])}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.deadlinesMetOverall || 0) <
                                KPI_GOALS['deadlines-met']
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Deadlines Met Overall:{' '}
                              {kpiPercentage(
                                expertDetails.kpis.deadlinesMetOverall || 0,
                              )}{' '}
                              vs goal of{' '}
                              {kpiPercentage(KPI_GOALS['deadlines-met'])}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.feedbackRatingRecent ||
                                  0) < KPI_GOALS['feedback-rating']
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Feedback Rating Recent:{' '}
                              {kpiRating(
                                expertDetails.kpis.feedbackRatingRecent || 0,
                              )}{' '}
                              stars vs goal of{' '}
                              {kpiRating(KPI_GOALS['feedback-rating'])} stars
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.feedbackRatingOverall ||
                                  0) < KPI_GOALS['feedback-rating']
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Feedback Rating Overall:{' '}
                              {kpiRating(
                                expertDetails.kpis.feedbackRatingOverall || 0,
                              )}{' '}
                              stars vs goal of{' '}
                              {kpiRating(KPI_GOALS['feedback-rating'])} stars
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Sales This Month:{' '}
                              {kpiDollars(
                                expertDetails.kpis.gmvCurrentMonth || 0,
                              )}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Sales Last Month:{' '}
                              {kpiDollars(
                                expertDetails.kpis.gmvPreviousMonth || 0,
                              )}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Matches Total:{' '}
                              {expertDetails.kpis.matchesTotal || 0}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.supportCasesTotal || 0) > 0
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Support Cases Total:{' '}
                              {expertDetails.kpis.supportCasesTotal || 0}
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarAdminSectionDescription ' +
                                ((expertDetails.kpis.supportCasesOpen || 0) > 0
                                  ? ' ThreadDetailSidebarAdminSectionDescriptionBad '
                                  : '')
                              }
                            >
                              Support Cases Open:{' '}
                              {expertDetails.kpis.supportCasesOpen || 0}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Claims Left This Week: {expertDetails.claimsLeft}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Active Projects:{' '}
                              {expertDetails.activeProjectCount}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Active Escrow:{' '}
                              {kpiDollars(expertDetails.activeEscrowCents || 0)}
                            </div>
                          </Fragment>
                        )}
                    </div>
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Account Balance
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Current Balance:{' '}
                        {errorBalanceDetails
                          ? errorUtils.getErrorMessage(errorBalanceDetails) ||
                            'Loading Error'
                          : '$' + balanceStr}
                      </div>
                      <div className="ThreadDetailSidebarAdminForm">
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Credit or Debit?
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={balanceAction}
                          onChange={(e) =>
                            setBalanceAction(e.currentTarget.value)
                          }
                        >
                          <option value="debit">Debit</option>
                          <option value="credit">Credit</option>
                        </select>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Credit/Debit amount
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="$ amount"
                          value={balanceAmount}
                          onChange={(e) =>
                            setBalanceAmount(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Credit/Debit Reason
                        </div>
                        <TextareaAutosize
                          type="text"
                          placeholder="Credit/debit reason..."
                          spellCheck="true"
                          className="ThreadDetailSidebarAdminFormInput"
                          value={balanceReason}
                          onChange={(e) => {
                            setBalanceReason(e.currentTarget.value);
                          }}
                        />
                        <div
                          className={
                            'ThreadDetailSidebarAdminFormAction ' +
                            (actionLoading
                              ? ' ThreadDetailSidebarAdminFormActionLoading '
                              : '')
                          }
                          onClick={updateBalance}
                        >
                          {balanceUpdateStr}
                        </div>
                      </div>
                      <div className="ThreadDetailSidebarAdminBalanceLog">
                        {!allAccountBalanceEvents.length && (
                          <div className="ThreadDetailSidebarAdminEmpty">
                            {errorAccountBalanceEventPaginated
                              ? errorUtils.getErrorMessage(
                                  errorAccountBalanceEventPaginated,
                                ) || 'Loading Error'
                              : 'No transactions yet.'}
                          </div>
                        )}
                        <div className="ExpertProfileTransactions">
                          {allAccountBalanceEvents.map((abe) => {
                            let titleStr = '';
                            let subStr = '';
                            if (abe.brand && abe.project && abe.project.quote) {
                              titleStr = abe.brand.name || 'Project';
                              subStr =
                                abe.project.quote.title || abe.reason || '';
                            } else if (abe.paymentRailsPaymentID) {
                              titleStr = 'Cashed Out';
                              subStr =
                                abe.paymentRailsPaymentStatus ||
                                abe.reason ||
                                '';
                            } else if (abe.action === 'credit') {
                              titleStr = 'Credit Added';
                              subStr = abe.reason || '';
                            } else if (abe.action === 'debit') {
                              titleStr = 'Credit Removed';
                              subStr = abe.reason || '';
                            }
                            return (
                              <div
                                key={abe.id}
                                className={
                                  'ExpertProfileTransaction ' +
                                  (abe.action === 'credit'
                                    ? ' ExpertProfileTransactionCredit '
                                    : ' ExpertProfileTransactionDebit ')
                                }
                              >
                                <div className="ExpertProfileTransactionTitle">
                                  {titleStr}
                                </div>
                                <div className="ExpertProfileTransactionSub">
                                  {subStr}
                                </div>
                                <div className="ExpertProfileTransactionAmount">
                                  $
                                  {formatNumberWithCommas(
                                    centsDollarsDecimal(abe.amount || 0),
                                  )}
                                </div>
                                <div className="ExpertProfileTransactionDate">
                                  {moment(abe.createdAt).format(
                                    'MMMM Do, YYYY',
                                  )}
                                </div>
                              </div>
                            );
                          })}
                          {allAccountBalanceEvents.length >= PAGE_LIMIT &&
                            showLoadMoreBalance && (
                              <div
                                className="ExpertProfileTransactionsLoader"
                                onClick={loadMoreBalanceEvents}
                              >
                                load more
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {primaryPanel === 'Notes' && expertDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelTeam">
                    <div className="ThreadDetailSidebarAdminSection">
                      <UserNoteEditor
                        onSave={onSaveNote}
                        isLoading={noteLoading}
                        errorFound={errorUserNotes}
                        userNotes={allUserNotes}
                      />
                    </div>
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Export Raw Data
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        <a
                          href={`${envUtils.pick(
                            'https://api.storetasker.com',
                            'https://dev.storetasker.com',
                            'http://localhost:4000',
                          )}/export/expert?expert=${
                            expertDetails.id
                          }&token=${oauthUtils.getToken('admin')}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ThreadDetailSidebarAboutWebsiteLink"
                        >
                          Export All Platform Chat History
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="ThreadDetailSidebarBodySearch">
                {!searchLoading && searchResults && !searchResults.length && (
                  <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                    <div className="ThreadDetailSidebarBodyPanelEmpty">
                      <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                        No search results found
                      </div>
                      <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                        Please try again with another search.
                      </div>
                    </div>
                  </div>
                )}
                {!searchLoading && searchResults && !!searchResults.length && (
                  <div className="ThreadDetailSidebarSearchResults">
                    {searchResults.map(renderSearchResult)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/experts')
              }
              className="DashboardErrorCoverNav"
            >
              back
            </div>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminExpertDetail.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  expertId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default AdminExpertDetail;
