import { useState, useRef, useEffect, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import TextareaAutosize from 'react-autosize-textarea';
import copyToClipboard from 'clipboard-copy';
import PropTypes from 'prop-types';
import '../../styles/feature/TextEditor.scss';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import envUtils from '../../utils/env';
import {
  formatNumberWithCommas,
  formatValidPhoneNumber,
} from '../../utils/format';
import {
  IFilestackFileUpload,
  ACCEPTED_TEXT_MIME,
  SUPPORTED_TEXT_MIME,
} from '../../utils/filestack';
import { MatchDetailsQuery } from '../../gql/graphql';
const ReactFilestack = require('filestack-react').default; // eslint-disable-line @typescript-eslint/no-var-requires

interface IFixedRecipient {
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  primaryEmail?: string | null;
  primaryPhone?: string | null;
}
interface TextEditorProps {
  backToQuoteEditor?: () => void;
  content: string;
  expertEmailAddress?: string;
  expertId: string;
  expertPhoneNumber?: string;
  expertSchedulePath?: string;
  files: IFilestackFileUpload[];
  fixedRecipients?: IFixedRecipient[];
  goEditContacts?: () => void;
  inactiveQuoteEditingError?: boolean;
  isClaim?: boolean;
  isHideFiles?: boolean;
  isSending?: boolean;
  onDeleteDraft?: () => void;
  onSaveDraft: (
    content: string,
    files: IFilestackFileUpload[],
    recipients: MatchDetailsQuery['matchDetails']['brand']['team'],
  ) => void;
  onSendText: () => void;
  quoteActionType?: string;
  quotePaymentPrice?: string;
  quotePaymentType?: string;
  quoteTitle?: string;
  recipients: MatchDetailsQuery['matchDetails']['brand']['team'];
  status: string;
  switchToEmailEditor?: (content: string) => void;
  team?: MatchDetailsQuery['matchDetails']['brand']['team'];
}

const TextEditor = ({
  backToQuoteEditor,
  goEditContacts,
  content,
  expertId,
  expertEmailAddress,
  expertPhoneNumber,
  expertSchedulePath,
  files,
  fixedRecipients,
  inactiveQuoteEditingError,
  isClaim,
  isHideFiles,
  isSending,
  onDeleteDraft,
  onSaveDraft,
  onSendText,
  quoteActionType,
  quotePaymentPrice,
  quotePaymentType,
  quoteTitle,
  recipients,
  status,
  switchToEmailEditor,
  team,
}: TextEditorProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [fixedRecipients, recipients]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [showSwitchOption, setShowSwitchOption] = useState(false);
  const [recipientsOpen, setRecipientsOpen] = useState(false);
  const { addNotification } = useContext(GlobalNotificationContext);
  function trySaveDraft(
    draftContent: string | null,
    draftRecipients: MatchDetailsQuery['matchDetails']['brand']['team'] | null,
    draftFiles: IFilestackFileUpload[] | null,
  ) {
    onSaveDraft(
      draftContent === null ? content : draftContent,
      draftFiles === null ? files : draftFiles,
      draftRecipients === null ? recipients : draftRecipients,
    );
  }
  function trySend() {
    if (inactiveQuoteEditingError) {
      addNotification(
        'This project has been updated. Please delete this draft and start over.',
        undefined,
        5000,
      );
      return;
    }
    if (
      (!(fixedRecipients || []).length && !recipients.length) ||
      !content ||
      !content.replace(/\s/gi, '').trim()
    ) {
      addNotification(
        'You need recipients and a message body.',
        undefined,
        5000,
      );
      return;
    }
    if (content.length >= 1500) {
      addNotification(
        'Twilio does not allow sending text messages over 1500 characters.',
        undefined,
        5000,
      );
      return;
    }
    onSendText();
  }
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);
  function fileError(error: Error) {
    console.log('Filestack fileError', error);
    addNotification(
      (error && error.message) || 'File upload error. Please try again.',
      undefined,
      5000,
    );
  }
  function fileUploaded(filestackRes: any) {
    console.log('Filestack fileUploaded', filestackRes);
    if (filestackRes && filestackRes.filesUploaded) {
      const allowedFiles = filestackRes.filesUploaded.filter(
        (f: any) =>
          f.mimetype &&
          f.size &&
          ACCEPTED_TEXT_MIME.indexOf(f.mimetype as string) !== -1,
      );
      if (filestackRes.filesUploaded.length > allowedFiles.length) {
        addNotification(
          'Twilio only allows sending certain types of files. We removed the rest.',
          undefined,
          5000,
        );
      }
      const notTooBigImages = allowedFiles.filter(
        (f: any) =>
          SUPPORTED_TEXT_MIME.indexOf(f.mimetype as string) === -1 ||
          f.size < 1024 * 1024,
      );
      if (allowedFiles.length > notTooBigImages.length) {
        addNotification(
          'Image files (gif, jpeg, png) must be under 1MB',
          undefined,
          5000,
        );
      }
      const notTooBigOther = notTooBigImages.filter(
        (f: any) =>
          SUPPORTED_TEXT_MIME.indexOf(f.mimetype as string) >= 0 ||
          f.size < 600 * 1024,
      );
      if (notTooBigImages.length > notTooBigOther.length) {
        addNotification(
          'All files besides images (gif, jpeg, png) must be under 600KB',
          undefined,
          5000,
        );
      }
      trySaveDraft(
        null,
        null,
        files.concat(
          notTooBigOther.map((f: any) => ({
            container: f.container || '',
            filename: f.filename || '',
            handle: f.handle || '',
            key: f.key || '',
            mimetype: f.mimetype || '',
            size: (f.size || '').toString(),
            source: f.source || '',
            uploadId: f.uploadId || '',
            url: f.url || '',
          })) as IFilestackFileUpload[],
        ),
      );
    }
  }
  function removeFile(filestackUploadUrl: string) {
    console.log('Filestack removeFile', filestackUploadUrl);
    trySaveDraft(
      null,
      null,
      files.filter((f) => f.url !== filestackUploadUrl),
    );
  }
  function addRecipient(
    recipientToAdd: MatchDetailsQuery['matchDetails']['brand']['team'][0],
  ) {
    trySaveDraft(null, recipients.concat(recipientToAdd), null);
    setRecipientsOpen(false);
  }
  function getQuoteTitleType(paymentType: string) {
    if (paymentType === 'PROJECT') return 'Project';
    if (paymentType === 'BILL') return 'Bill';
    return 'Subscription';
  }
  function getQuotePriceType(paymentType: string) {
    if (paymentType === 'MONTHLY_SUBSCRIPTION') return 'Monthly';
    if (paymentType === 'WEEKLY_SUBSCRIPTION') return 'Weekly';
    return 'Price';
  }
  function copySchedulingLink() {
    if (expertSchedulePath) {
      copyToClipboard('meet.storetasker.com/' + expertSchedulePath).catch(
        () => {},
      );
    }
    setShowSwitchOption(false);
  }
  function copyEmailLink() {
    if (expertEmailAddress) {
      copyToClipboard(expertEmailAddress).catch(() => {});
    }
    setShowSwitchOption(false);
  }
  function copyPhoneLink() {
    if (expertPhoneNumber) {
      copyToClipboard(expertPhoneNumber).catch(() => {});
    }
    setShowSwitchOption(false);
  }
  const availableToAdd = (team || []).filter(
    (h) => !recipients.find((r) => r.id === h.id),
  );
  return (
    <div className="ThreadEditor TextEditor">
      <div className="ThreadEditorTop">
        <div className="ThreadEditorTopStatus">{status}</div>
        <div className="ThreadEditorRow">
          <div className="ThreadEditorRowTitle">To</div>
          <div className="ThreadEditorRowBubbles">
            {(fixedRecipients || []).map((f) => (
              <div
                key={f.id}
                className="ThreadEditorRowBubble ThreadEditorRowBubbleFixed"
                data-tip={
                  formatValidPhoneNumber(f.primaryPhone) || f.primaryPhone || ''
                }
              >
                <span className="ThreadEditorRowBubbleText">
                  {`${(f.firstName || '').trim()} ${(
                    f.lastName || ''
                  ).trim()}`.trim() ||
                    formatValidPhoneNumber(f.primaryPhone) ||
                    f.primaryPhone ||
                    ''}
                </span>
              </div>
            ))}
            {recipients.slice(0, 1).map((h) => (
              <div
                key={h.id}
                className="ThreadEditorRowBubble"
                data-tip={
                  formatValidPhoneNumber(h.primaryPhone) || h.primaryPhone || ''
                }
                onClick={() =>
                  trySaveDraft(
                    null,
                    recipients.filter((r) => r.id !== h.id),
                    null,
                  )
                }
              >
                <span className="ThreadEditorRowBubbleText">
                  {`${(h.firstName || '').trim()} ${(
                    h.lastName || ''
                  ).trim()}`.trim() ||
                    (
                      formatValidPhoneNumber(h.primaryPhone) ||
                      h.primaryPhone ||
                      ''
                    ).trim() ||
                    ''}
                </span>
                <div className="ThreadEditorRowBubbleRemove" />
              </div>
            ))}
            {!(fixedRecipients || []).length && !recipients.length && (
              <div
                className={
                  'ThreadEditorRowBubbleAdd ' +
                  (recipientsOpen ? ' ThreadEditorRowBubbleAddCollapse ' : ' ')
                }
                onClick={() => setRecipientsOpen(!recipientsOpen)}
              >
                +
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="ThreadEditorCardWrapper">
        <div className="ThreadEditorCard">
          <div className="ThreadEditorBody">
            <div className="TextEditorWrap">
              <TextareaAutosize
                type="text"
                rows={3}
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna."
                spellCheck="true"
                ref={textareaRef}
                className="TextEditorTextarea"
                value={content}
                onChange={(e) => {
                  trySaveDraft(e.currentTarget.value, null, null);
                }}
              />
            </div>
            {!!(
              switchToEmailEditor ||
              expertEmailAddress ||
              expertPhoneNumber ||
              expertSchedulePath
            ) && (
              <div
                className={
                  'ThreadEditorBodySwitch ThreadEditorBodySwitch' +
                  (showSwitchOption ? 'Active' : 'Inactive')
                }
              >
                <div
                  className="ThreadEditorBodySwitchToggle"
                  onClick={() => setShowSwitchOption(!showSwitchOption)}
                />
                {!!showSwitchOption && !!switchToEmailEditor && (
                  <div
                    className="ThreadEditorBodySwitchOption"
                    onClick={() => switchToEmailEditor(content.trim())}
                  >
                    send {quotePaymentType ? 'quote ' : ''}via email instead
                  </div>
                )}
                {!!showSwitchOption && !!expertSchedulePath && (
                  <div
                    className="ThreadEditorBodySwitchOption"
                    onClick={copySchedulingLink}
                  >
                    copy scheduling link
                  </div>
                )}
                {!!showSwitchOption && !!expertEmailAddress && (
                  <div
                    className="ThreadEditorBodySwitchOption"
                    onClick={copyEmailLink}
                  >
                    copy email address
                  </div>
                )}
                {!!showSwitchOption && !!expertPhoneNumber && (
                  <div
                    className="ThreadEditorBodySwitchOption"
                    onClick={copyPhoneLink}
                  >
                    copy phone number
                  </div>
                )}
              </div>
            )}
            {!!inactiveQuoteEditingError && (
              <div className="ThreadEditorBodyError">
                This project has been updated. Please delete this draft and
                start over.
              </div>
            )}
          </div>
          {!!isClaim && (
            <div className="ThreadEditorTools">
              <a
                className="ThreadEditorTool ThreadEditorToolExamples"
                href="https://kb.storetasker.com/Matching-Message-Examples-6df3be369d4d4392ada7d0d655e6ca02"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>see </span>examples
              </a>
              <a
                className="ThreadEditorTool ThreadEditorToolLines"
                href="https://kb.storetasker.com/Writing-a-Great-Matching-Message-d98020ebf3e5421f85fd52dfacc060be"
                target="_blank"
                rel="noopener noreferrer"
              >
                tips to improve<span> matching</span>
              </a>
            </div>
          )}
          <div className="ThreadEditorFooter">
            <div
              className={
                files.length
                  ? 'ThreadEditorFooterUpper'
                  : 'ThreadEditorFooterLower'
              }
            >
              <div className="ThreadEditorFiles">
                {files.map((file) => (
                  <div
                    key={file.url}
                    className="ThreadEditorFile"
                    onClick={() => removeFile(file.url || '')}
                  >
                    <span className="ThreadEditorFileText">
                      {file.filename}
                    </span>
                    <div className="ThreadEditorFileRemove" />
                  </div>
                ))}
                {!isHideFiles && (
                  <ReactFilestack
                    apikey="AGxdviGoVRwWPL6lKEdnXz"
                    actionOptions={{
                      accept: ACCEPTED_TEXT_MIME,
                      maxFiles: 3,
                      maxSize: 1024 * 1024,
                      storeTo: {
                        container: envUtils.pick(
                          'files.asklorem.com',
                          'files-dev.asklorem.com',
                          'files-dev.asklorem.com',
                        ),
                        location: 's3',
                        path: `${expertId}/`,
                        region: 'us-east-1',
                      },
                    }}
                    onSuccess={fileUploaded}
                    onError={fileError}
                    customRender={({ onPick }: { onPick: any }) => (
                      <div
                        onMouseDown={onPick}
                        className={
                          'ThreadEditorUpload ' +
                          (files.length ? ' ThreadEditorUploadHasFiles ' : '')
                        }
                      >
                        {files.length ? '+' : 'Attach Files'}
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
            <div className="ThreadEditorActions">
              <div
                className={
                  'ThreadEditorActionBtn ThreadEditorActionSend ' +
                  (isSending ? ' ThreadEditorActionSendLoading ' : '')
                }
                onClick={trySend}
              >
                {isClaim ? 'Claim Lead' : 'Send Text'}
              </div>
              {onDeleteDraft && (
                <div
                  className="ThreadEditorActionBtn ThreadEditorActionDelete"
                  onClick={() => onDeleteDraft && onDeleteDraft()}
                >
                  Delete
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {quotePaymentType && (
        <div className="ThreadEditorQuoteAttachedWrapper">
          <div
            className="ThreadEditorQuoteAttached"
            onClick={() =>
              quoteActionType !== 'COMPLETE' &&
              backToQuoteEditor &&
              backToQuoteEditor()
            }
          >
            <div className="ThreadEditorQuoteAttachedLeft">
              {quoteActionType === 'CREATE' ? (
                <div className="ThreadEditorQuoteAttachedTitle">
                  {getQuoteTitleType(quotePaymentType)}
                </div>
              ) : (
                <div className="ThreadEditorQuoteAttachedTitle">
                  {quoteActionType === 'UPDATE'
                    ? 'Quote Update'
                    : 'Mark Complete'}
                </div>
              )}
              <div className="ThreadEditorQuoteAttachedText">{quoteTitle}</div>
            </div>
            <div className="ThreadEditorQuoteAttachedRight">
              <div className="ThreadEditorQuoteAttachedTitle">
                {getQuotePriceType(quotePaymentType)}
              </div>
              <div className="ThreadEditorQuoteAttachedText">
                $
                {formatNumberWithCommas(
                  (quotePaymentPrice || '').replace('$', ''),
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!!recipientsOpen && (
        <div className="ThreadEditorRecipientsPopoverWrapper">
          <div
            className="ThreadEditorRecipientsPopoverClose"
            onClick={() => setRecipientsOpen(false)}
          >
            cancel
          </div>
          <div className="ThreadEditorRecipientsPopover">
            <div className="ThreadEditorRecipientsOptions">
              {availableToAdd.map((h) => (
                <div
                  key={h.id}
                  className="ThreadEditorRecipientsOption"
                  onClick={() => addRecipient(h)}
                >
                  {`${(h.firstName || '').trim()} ${(
                    h.lastName || ''
                  ).trim()}`.trim() ||
                    (
                      formatValidPhoneNumber(h.primaryPhone) ||
                      h.primaryPhone ||
                      ''
                    ).trim() ||
                    ''}
                </div>
              ))}
            </div>
            <div className="ThreadEditorRecipientsInfo">
              Don&apos;t see who you&apos;re looking for? Update contact info{' '}
              <span onClick={() => goEditContacts && goEditContacts()}>
                here
              </span>
              .
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TextEditor.propTypes = {
  backToQuoteEditor: PropTypes.func,
  content: PropTypes.string.isRequired,
  expertEmailAddress: PropTypes.string,
  expertId: PropTypes.string.isRequired,
  expertPhoneNumber: PropTypes.string,
  expertSchedulePath: PropTypes.string,
  files: PropTypes.array.isRequired,
  fixedRecipients: PropTypes.array,
  goEditContacts: PropTypes.func,
  inactiveQuoteEditingError: PropTypes.bool,
  isClaim: PropTypes.bool,
  isHideFiles: PropTypes.bool,
  isSending: PropTypes.bool,
  onDeleteDraft: PropTypes.func,
  onSaveDraft: PropTypes.func.isRequired,
  onSendText: PropTypes.func.isRequired,
  quoteActionType: PropTypes.string,
  quotePaymentPrice: PropTypes.string,
  quotePaymentType: PropTypes.string,
  quoteTitle: PropTypes.string,
  recipients: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  switchToEmailEditor: PropTypes.func,
  team: PropTypes.array,
};

TextEditor.defaultProps = {
  backToQuoteEditor: () => {},
  expertEmailAddress: '',
  expertPhoneNumber: '',
  expertSchedulePath: '',
  fixedRecipients: [],
  goEditContacts: () => {},
  inactiveQuoteEditingError: false,
  isClaim: false,
  isHideFiles: false,
  isSending: false,
  onDeleteDraft: null,
  quoteActionType: '',
  quotePaymentPrice: '',
  quotePaymentType: '',
  quoteTitle: '',
  switchToEmailEditor: null,
  team: [],
};

export default TextEditor;
