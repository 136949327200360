import React, {
  useState,
  useRef,
  useContext,
  Fragment,
  useEffect,
} from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { ClientWithOnReconnected } from '../../utils/apollo';
import '../../styles/page/ExpertActiveClients.scss';
import { useQuery, gql, useMutation, ApolloError } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import {
  MatchAlertsForExpertFullQuery,
  MatchLeadsForExpertQuery,
  MatchActiveProjectsForExpertQuery,
  MatchPaginateRecentClientsForExpertQuery,
  MatchPaginateRecentClientsForExpertQueryVariables,
  MatchDismissLostLeadMutation,
  MatchDismissLostLeadMutationVariables,
  ExpertCreateClientMutation,
  ExpertCreateClientMutationVariables,
  MatchSearchForExpertMutation,
  MatchSearchForExpertMutationVariables,
  QuoteStatus,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { MatchActionSummaryWithBrand, MatchDetail } from '../../utils/gql';
import {
  centsDollarsRounded,
  formatNumberWithCommas,
  paymentTypeFormatted,
  quoteStatusFormatted,
  unreadReasonFormatted,
} from '../../utils/format';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';

const matchAlertsForExpertQuery = gql`
  query MatchAlertsForExpertFull {
    matchAlertsForExpert {
      ...MatchActionSummaryWithBrand
    }
  }
  ${MatchActionSummaryWithBrand}
`;

const matchLeadsForExpertQuery = gql`
  query MatchLeadsForExpert {
    matchLeadsForExpert {
      ...MatchActionSummaryWithBrand
    }
  }
  ${MatchActionSummaryWithBrand}
`;

const matchActiveProjectsForExpertQuery = gql`
  query MatchActiveProjectsForExpert {
    matchActiveProjectsForExpert {
      ...MatchActionSummaryWithBrand
    }
  }
  ${MatchActionSummaryWithBrand}
`;

const matchPaginateRecentClientsForExpertQuery = gql`
  query MatchPaginateRecentClientsForExpert(
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    matchPaginateRecentClientsForExpert(
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...MatchActionSummaryWithBrand
    }
  }
  ${MatchActionSummaryWithBrand}
`;

const matchDismissLostLeadMutation = gql`
  mutation MatchDismissLostLead($matchId: ID!, $hideDate: Date!) {
    matchDismissLostLead(matchId: $matchId, hideDate: $hideDate) {
      ...MatchActionSummaryWithBrand
    }
  }
  ${MatchActionSummaryWithBrand}
`;

const matchSearchForExpertMutation = gql`
  mutation MatchSearchForExpert($searchQuery: String!) {
    matchSearchForExpert(searchQuery: $searchQuery) {
      id
      brandNameMatch
      humanNameMatch {
        id
        firstName
        lastName
      }
      match {
        ...MatchDetail
      }
      shopifyAdminMatch
      urlMatch
    }
  }
  ${MatchDetail}
`;

const expertCreateClientMutation = gql`
  mutation ExpertCreateClient(
    $brandName: String!
    $brandUrl: String!
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    expertCreateClient(
      brandName: $brandName
      brandUrl: $brandUrl
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      createStatus
      match {
        ...MatchDetail
      }
    }
  }
  ${MatchDetail}
`;

interface ExpertActiveClientsProps {
  expertId: string;
  socketClient: ClientWithOnReconnected;
}

const PAGE_LIMIT = 15;
const initTime = new Date().getTime();

const ExpertActiveClients = ({
  expertId,
  socketClient,
}: ExpertActiveClientsProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [initDate] = useState(() => new Date());
  const searchRef = useRef<HTMLInputElement>(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    MatchSearchForExpertMutation['matchSearchForExpert'] | null
  >(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [newHumanFirst, setNewHumanFirst] = useState('');
  const [newHumanLast, setNewHumanLast] = useState('');
  const [newHumanEmail, setNewHumanEmail] = useState('');
  const [newBrandName, setNewBrandName] = useState('');
  const [newBrandUrl, setNewBrandUrl] = useState('');
  const {
    data: dataMatchAlerts,
    error: errorMatchAlerts,
    loading: loadingMatchAlerts,
    refetch: refetchMatchAlerts,
  } = useQuery<MatchAlertsForExpertFullQuery>(matchAlertsForExpertQuery, {
    returnPartialData: true,
  });
  const {
    data: dataMatchLeads,
    error: errorMatchLeads,
    loading: loadingMatchLeads,
    refetch: refetchMatchLeads,
  } = useQuery<MatchLeadsForExpertQuery>(matchLeadsForExpertQuery, {
    returnPartialData: true,
  });
  const {
    data: dataMatchActiveProjects,
    error: errorMatchActiveProjects,
    loading: loadingMatchActiveProjects,
    refetch: refetchMatchActiveProjects,
  } = useQuery<MatchActiveProjectsForExpertQuery>(
    matchActiveProjectsForExpertQuery,
    { returnPartialData: true },
  );
  const {
    data: dataMatchRecentClients,
    error: errorMatchRecentClients,
    loading: loadingMatchRecentClients,
    fetchMore: fetchMoreMatchRecentClients,
  } = useQuery<
    MatchPaginateRecentClientsForExpertQuery,
    MatchPaginateRecentClientsForExpertQueryVariables
  >(matchPaginateRecentClientsForExpertQuery, {
    returnPartialData: true,
    variables: {
      direction: 'BACKWARD',
      fromDate: initDate.getTime(),
      limit: PAGE_LIMIT,
    },
  });
  const allPaginatedRecentClients = _.uniqBy(
    [
      ...((dataMatchRecentClients &&
        dataMatchRecentClients.matchPaginateRecentClientsForExpert) ||
        []),
    ].filter(
      (m) =>
        (!m.isBlocked || m.isBlocked > initTime) && m.expertStr === expertId,
    ),
    'id',
  ).sort((a, b) => b.lastUpdated - a.lastUpdated);
  const oldestPaginatedMatch =
    allPaginatedRecentClients[allPaginatedRecentClients.length - 1];
  function loadMoreMatches() {
    fetchMoreMatchRecentClients({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (
          !fetchMoreResult ||
          !fetchMoreResult.matchPaginateRecentClientsForExpert
        )
          return prev;
        if (
          fetchMoreResult.matchPaginateRecentClientsForExpert.length <
          PAGE_LIMIT
        ) {
          setShowLoadMore(false);
        }
        return {
          matchPaginateRecentClientsForExpert: (
            prev.matchPaginateRecentClientsForExpert || []
          ).concat(fetchMoreResult.matchPaginateRecentClientsForExpert),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate: oldestPaginatedMatch.lastUpdated,
        limit: PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'ExpertActiveClients',
        func: 'loadMoreMatches',
      });
    });
  }
  useEffect(() => {
    const loadTime = new Date();
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('ExpertActiveClients socketClient onReconnected');
      refetchMatchAlerts().catch(() => {});
      refetchMatchLeads().catch(() => {});
      refetchMatchActiveProjects().catch(() => {});
      fetchMoreMatchRecentClients({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            matchPaginateRecentClientsForExpert: (
              prev.matchPaginateRecentClientsForExpert || []
            ).concat(fetchMoreResult.matchPaginateRecentClientsForExpert),
          };
        },
        variables: {
          direction: 'FORWARD',
          fromDate: loadTime.getTime(),
          limit: 10,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'ExpertActiveClients',
          func: 'reconnect loadMoreMatches',
        });
      });
    });
    return () => {
      reconnectedListener();
    };
  }, [
    socketClient,
    refetchMatchAlerts,
    refetchMatchLeads,
    refetchMatchActiveProjects,
    fetchMoreMatchRecentClients,
    addNotification,
  ]);
  // combine all data, dedup, filter, sort into 4 groups, render
  const allMatchesForExpert:
    | MatchAlertsForExpertFullQuery['matchAlertsForExpert']
    | MatchLeadsForExpertQuery['matchLeadsForExpert']
    | MatchActiveProjectsForExpertQuery['matchActiveProjectsForExpert']
    | MatchPaginateRecentClientsForExpertQuery['matchPaginateRecentClientsForExpert'] =
    _.uniqBy(
      [
        ...((dataMatchAlerts && dataMatchAlerts.matchAlertsForExpert) || []),
        ...((dataMatchLeads && dataMatchLeads.matchLeadsForExpert) || []),
        ...((dataMatchActiveProjects &&
          dataMatchActiveProjects.matchActiveProjectsForExpert) ||
          []),
        ...allPaginatedRecentClients,
      ].filter(
        (m) =>
          (!m.isBlocked || m.isBlocked > initTime) && m.expertStr === expertId,
      ),
      'id',
    );
  const unreadActiveClients = allMatchesForExpert
    .filter((m) => m.expertUnread)
    .sort((a, b) => b.lastUpdated - a.lastUpdated);
  const leadActiveClients = allMatchesForExpert
    .filter((m) => m.isLead)
    .sort(
      (a, b) =>
        (a.wasBlocked || a.isBlocked ? 0 : a.leadCloseTimeline || Infinity) -
        (b.wasBlocked || b.isBlocked ? 0 : b.leadCloseTimeline || Infinity),
    );
  const projectActiveClients = allMatchesForExpert
    .filter(
      (m) =>
        !m.isLead &&
        ((m.hasActiveProjectsStr && m.hasActiveProjectsStr.length) ||
          (m.hasActiveQuotesStr && m.hasActiveQuotesStr.length)),
    )
    .sort(
      (a, b) =>
        ((a.hasActiveProjects &&
          a.hasActiveProjects.length &&
          a.hasActiveProjects[0].quote &&
          a.hasActiveProjects[0].quote.estimatedCompletionDate) ||
          Infinity) -
        ((b.hasActiveProjects &&
          b.hasActiveProjects.length &&
          b.hasActiveProjects[0].quote &&
          b.hasActiveProjects[0].quote.estimatedCompletionDate) ||
          Infinity),
    );
  const otherRecentClients = allMatchesForExpert
    .filter(
      (m) =>
        !m.expertUnread &&
        !m.isLead &&
        (!m.hasActiveProjectsStr || !m.hasActiveProjectsStr.length) &&
        (!m.hasActiveQuotesStr || !m.hasActiveQuotesStr.length),
    )
    .sort((a, b) => b.lastUpdated - a.lastUpdated);
  const [tryDismissLostLead] = useMutation<
    MatchDismissLostLeadMutation,
    MatchDismissLostLeadMutationVariables
  >(matchDismissLostLeadMutation);
  function dismissLostLead(
    matchDetails: MatchLeadsForExpertQuery['matchLeadsForExpert'][0],
  ) {
    if (!matchDetails) {
      return;
    }
    tryDismissLostLead({
      optimisticResponse: {
        matchDismissLostLead: {
          ...matchDetails,
          expertUnread: null,
          isBlocked: new Date(initTime - 1).getTime(),
          isLead: null,
          lastUpdated: new Date().getTime(),
        },
      },
      variables: {
        hideDate: initTime - 1,
        matchId: matchDetails.id,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Archive Lead Error');
      logError(err, {
        component: 'ExpertActiveClients',
        func: 'tryDismissLostLead',
      });
    });
  }
  const [tryCreateClient] = useMutation<
    ExpertCreateClientMutation,
    ExpertCreateClientMutationVariables
  >(expertCreateClientMutation);
  function doCreateClient() {
    if (actionLoading) return;
    if (
      !newHumanFirst ||
      !newHumanLast ||
      !newHumanEmail ||
      !newBrandUrl ||
      !newBrandName
    ) {
      addNotification('Please submit all the fields', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryCreateClient({
      variables: {
        brandName: newBrandName,
        brandUrl: newBrandUrl,
        email: newHumanEmail,
        firstName: newHumanFirst,
        lastName: newHumanLast,
      },
    })
      .then(({ data: mutationData }) => {
        setActionLoading(false);
        setNewHumanFirst('');
        setNewHumanLast('');
        setNewHumanEmail('');
        setNewBrandUrl('');
        setNewBrandName('');
        if (
          mutationData &&
          mutationData.expertCreateClient &&
          mutationData.expertCreateClient.createStatus &&
          mutationData.expertCreateClient.match &&
          mutationData.expertCreateClient.match.id
        ) {
          if (mutationData.expertCreateClient.createStatus === 'EXISTING') {
            addNotification(
              'They are already an existing client of Storetaker',
            );
          }
          history.push('/clients/' + mutationData.expertCreateClient.match.id);
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Client Create Error',
        );
        logError(err, {
          component: 'ExpertActiveClients',
          func: 'tryCreateClient',
        });
      });
  }
  const [tryMatchSearch] = useMutation<
    MatchSearchForExpertMutation,
    MatchSearchForExpertMutationVariables
  >(matchSearchForExpertMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput) {
      toggleSearch();
      return;
    }
    if (searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchResults([]);
    tryMatchSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        setSearchLoading(false);
        if (dataMatchSearch && dataMatchSearch.matchSearchForExpert) {
          console.log('tryMatchSearch', dataMatchSearch.matchSearchForExpert);
          setSearchResults(
            dataMatchSearch.matchSearchForExpert
              .slice()
              .sort((a, b) => b.match.lastUpdated - a.match.lastUpdated),
          );
        }
      },
      variables: {
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Client Search Error');
      logError(err, {
        component: 'ExpertActiveClients',
        func: 'tryMatchSearch',
      });
      setSearchLoading(false);
      setSearchResults(null);
    });
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      toggleSearch();
    }
  }
  function toggleSearch() {
    const nextShowSearch = !showSearch;
    setSearchInput('');
    setSearchResults(null);
    setSearchLoading(false);
    setShowSearch(nextShowSearch);
    if (nextShowSearch) {
      setTimeout(() => {
        if (searchRef && searchRef.current) {
          searchRef.current.focus();
        }
      });
    }
  }
  if (
    loadingMatchAlerts ||
    loadingMatchLeads ||
    loadingMatchActiveProjects ||
    loadingMatchRecentClients
  ) {
    // ignore these
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div
        className={
          'ClientsScreenSearch ClientsScreenSearch' +
          (showSearch ? 'Active ' : '')
        }
      >
        <div className="ClientsScreenSearchBar">
          <input
            type="text"
            ref={searchRef}
            className="ClientsScreenSearchBarInput"
            autoComplete="new-off"
            spellCheck="false"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleSearchInputKeyDown}
          />
          <div className="ClientsScreenSearchToggle" onClick={toggleSearch}>
            Search Clients
          </div>
        </div>
      </div>
      <div className="DashboardScreenContent ClientsScreenContent">
        {!searchLoading && searchResults === null ? (
          <Fragment>
            <div className="DashboardSection">
              <div className="DashboardSectionHeader">
                Alerts &amp; Reminders
              </div>
              {!unreadActiveClients.length && (
                <div className="DashboardSectionEmpty">
                  {errorMatchAlerts
                    ? errorUtils.getErrorMessage(errorMatchAlerts)
                    : 'New to-do items will show up here, like missed messages, upcoming meetings, etc.'}
                </div>
              )}
              <div className="DashboardSectionScrollerSwitch">
                {unreadActiveClients.map((m) => (
                  <div key={m.id} className="ClientSummary ClientSummaryAlert">
                    <Link to={'/clients/' + m.id} className="ClientSummaryBody">
                      <div className="ClientSummaryTitle">
                        {m.brand && m.brand.name ? m.brand.name : ''}
                      </div>
                      <div className="ClientSummarySubTitle">
                        {unreadReasonFormatted(m.expertUnread)}
                      </div>
                    </Link>
                    <div className="ClientSummaryFooter" />
                  </div>
                ))}
              </div>
            </div>
            <div className="DashboardSection">
              <div className="DashboardSectionHeader">Leads To Close</div>
              {!leadActiveClients.length && (
                <div className="DashboardSectionEmpty">
                  {errorMatchLeads
                    ? errorUtils.getErrorMessage(errorMatchLeads)
                    : "New leads you've claimed who haven't accepted a quote yet will show up here."}
                </div>
              )}
              <div className="DashboardSectionScrollerSwitch">
                {leadActiveClients.map((m) => {
                  const projectWithQuoteSent = (m.hasActiveQuotes || []).find(
                    (p) =>
                      p.quote &&
                      p.quote.cents &&
                      p.quote.status &&
                      ['CREATED', 'UPDATED'].indexOf(p.quote.status) >= 0,
                  );
                  let closeDateStr = '';
                  if (m.leadCloseTimeline) {
                    const nowMoment = moment.tz(initTime, moment.tz.guess());
                    const closeMoment = moment.tz(
                      m.leadCloseTimeline,
                      moment.tz.guess(),
                    );
                    if (nowMoment.isSame(closeMoment, 'day')) {
                      closeDateStr = 'Today';
                    } else if (m.leadCloseTimeline < initTime) {
                      closeDateStr = '';
                    } else if (
                      nowMoment.clone().add(1, 'day').isSame(closeMoment, 'day')
                    ) {
                      closeDateStr = 'Tomorrow';
                    } else if (nowMoment.isSame(closeMoment, 'week')) {
                      closeDateStr = closeMoment.format('dddd');
                    } else {
                      closeDateStr = closeMoment.format('MMMM Do');
                    }
                  }
                  return (
                    <div
                      key={m.id}
                      className={
                        'ClientSummary ClientSummaryLead ' +
                        (m.isBlocked ? ' ClientSummaryLeadArchive ' : '')
                      }
                    >
                      {m.expertUnread && !m.isBlocked && (
                        <div className="ClientSummaryUnreadTag">new!</div>
                      )}
                      <Link
                        to={'/clients/' + m.id}
                        className="ClientSummaryBody"
                      >
                        <div className="ClientSummaryTitle">
                          {m.brand && m.brand.name ? m.brand.name : ''}
                        </div>
                        <div className="ClientSummarySubTitle">
                          {(projectWithQuoteSent &&
                            projectWithQuoteSent.quote &&
                            projectWithQuoteSent.quote.title) ||
                            'Lead To Close'}
                        </div>
                        {projectWithQuoteSent && (
                          <div className="ClientSummaryLeftCorner">
                            $
                            {formatNumberWithCommas(
                              centsDollarsRounded(
                                projectWithQuoteSent.quote.cents,
                              ),
                            )}
                          </div>
                        )}
                        <div
                          className={
                            'ClientSummaryStatusLine CardStatusLine' +
                            (projectWithQuoteSent ? 'CREATED' : 'CANCELED')
                          }
                        />
                        <div className="ClientSummaryRightCorner">
                          {projectWithQuoteSent
                            ? 'Quote Sent'
                            : 'Needs A Quote'}
                        </div>
                      </Link>
                      <div className="ClientSummaryArchiveCover">
                        <div
                          className="ClientSummaryArchive"
                          onClick={() => dismissLostLead(m)}
                        >
                          Archive
                        </div>
                      </div>
                      <div className="ClientSummaryFooter">
                        <div className="ClientSummaryFooterText">
                          {m.isBlocked ? 'Canceled' : closeDateStr || 'Lead'}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="DashboardSection">
              <div className="DashboardSectionHeader">Active Projects</div>
              {!projectActiveClients.length && (
                <div className="DashboardSectionEmpty">
                  {errorMatchActiveProjects
                    ? errorUtils.getErrorMessage(errorMatchActiveProjects)
                    : 'Projects and quotes for your existing clients will show up here to help you stay organized.'}
                </div>
              )}
              <div className="DashboardSectionScrollerSwitch">
                {projectActiveClients.map((m) => {
                  const projectWithQuoteAccepted = (
                    m.hasActiveProjects || []
                  ).find(
                    (p) =>
                      p.quote &&
                      p.quote.cents &&
                      p.quote.status &&
                      [
                        'ACCEPTED',
                        'COMPLETED',
                        'REVISIONS_REQUESTED',
                        'SUBSCRIPTION_PAYMENT_MADE',
                        'IN_PROGRESS',
                      ].indexOf(p.quote.status) >= 0,
                  );
                  const projectWithQuoteSent = (m.hasActiveQuotes || []).find(
                    (p) =>
                      p.quote &&
                      p.quote.cents &&
                      p.quote.status &&
                      ['CREATED', 'UPDATED'].indexOf(p.quote.status) >= 0,
                  );
                  let projectTitleStr = '';
                  let projectCentsStr = '';
                  let projectDueStr = '';
                  let projectStatusStr = '';
                  let projectStatusLine = '';
                  if (projectWithQuoteAccepted) {
                    projectTitleStr = projectWithQuoteAccepted.quote.title;
                    projectCentsStr = formatNumberWithCommas(
                      centsDollarsRounded(projectWithQuoteAccepted.quote.cents),
                    );
                    projectDueStr = paymentTypeFormatted(
                      projectWithQuoteAccepted.quote.paymentType,
                    );
                    if (
                      projectWithQuoteAccepted.quote.estimatedCompletionDate
                    ) {
                      const nowMoment = moment.tz(initTime, moment.tz.guess());
                      const dueMoment = moment
                        .utc(
                          projectWithQuoteAccepted.quote
                            .estimatedCompletionDate,
                        )
                        .tz('UTC');
                      if (nowMoment.isSame(dueMoment, 'day')) {
                        projectDueStr = 'Due Today';
                      } else if (dueMoment.valueOf() < nowMoment.valueOf()) {
                        projectDueStr = 'Past Deadline';
                      } else if (
                        nowMoment.clone().add(1, 'day').isSame(dueMoment, 'day')
                      ) {
                        projectDueStr = 'Due Tomorrow';
                      } else if (nowMoment.isSame(dueMoment, 'week')) {
                        projectDueStr = 'Due by ' + dueMoment.format('dddd');
                      } else {
                        projectDueStr = 'Due by ' + dueMoment.format('MMMM Do');
                      }
                    }
                    projectStatusStr = quoteStatusFormatted(
                      projectWithQuoteAccepted.quote.status,
                    );
                    projectStatusLine = projectWithQuoteAccepted.quote.status;
                  } else if (projectWithQuoteSent) {
                    projectTitleStr = projectWithQuoteSent.quote.title;
                    projectCentsStr = formatNumberWithCommas(
                      centsDollarsRounded(projectWithQuoteSent.quote.cents),
                    );
                    projectDueStr = paymentTypeFormatted(
                      projectWithQuoteSent.quote.paymentType,
                    );
                    projectStatusStr =
                      projectWithQuoteSent.quote.status === QuoteStatus.Updated
                        ? 'Quote Updated'
                        : 'Quote Sent';
                    projectStatusLine = projectWithQuoteSent.quote.status;
                  }
                  return (
                    <div
                      key={m.id}
                      className="ClientSummary ClientSummaryProject"
                    >
                      {m.expertUnread && (
                        <div className="ClientSummaryUnreadTag">new!</div>
                      )}
                      <Link
                        to={'/clients/' + m.id}
                        className="ClientSummaryBody"
                      >
                        <div className="ClientSummaryTitle">
                          {projectTitleStr || 'Project'}
                        </div>
                        <div className="ClientSummarySubTitle">
                          {m.brand && m.brand.name ? m.brand.name : ''}
                        </div>
                        {projectCentsStr && (
                          <div className="ClientSummaryLeftCorner">
                            ${projectCentsStr}
                          </div>
                        )}
                        <div
                          className={
                            'ClientSummaryStatusLine CardStatusLine' +
                            projectStatusLine
                          }
                        />
                        <div className="ClientSummaryRightCorner">
                          {projectStatusStr}
                        </div>
                      </Link>
                      <div className="ClientSummaryFooter">
                        <div className="ClientSummaryFooterText">
                          {projectDueStr || 'Project'}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="DashboardSection">
              <div className="DashboardSectionHeader">Recent Clients</div>
              {!otherRecentClients.length && (
                <div className="DashboardSectionEmpty">
                  {errorMatchRecentClients
                    ? errorUtils.getErrorMessage(errorMatchRecentClients)
                    : "Clients who don't have an active project will be sorted chronologically by your most recent interactions."}
                </div>
              )}
              <div className="DashboardSectionScrollerSwitch">
                {otherRecentClients.map((m) => {
                  let lastUpdatedStr = '';
                  const nowMoment = moment.tz(initTime, moment.tz.guess());
                  if (m.expertSnoozedUntil && m.expertSnoozedUntil > initTime) {
                    const eventMoment = moment.tz(
                      m.expertSnoozedUntil,
                      moment.tz.guess(),
                    );
                    let eventDateSummary = eventMoment.format('MMMM Do, YYYY');
                    if (nowMoment.isSame(eventMoment, 'year')) {
                      eventDateSummary = eventMoment.format('MMMM Do');
                    }
                    lastUpdatedStr = 'Snoozed Until: ' + eventDateSummary;
                  } else if (m.lastUpdated) {
                    const eventMoment = moment.tz(
                      m.lastUpdated,
                      moment.tz.guess(),
                    );
                    let eventDateSummary = eventMoment.format('MMMM Do, YYYY');
                    if (nowMoment.isSame(eventMoment, 'day')) {
                      eventDateSummary = 'Today';
                    } else if (
                      nowMoment
                        .clone()
                        .subtract(1, 'day')
                        .isSame(eventMoment, 'day')
                    ) {
                      eventDateSummary = 'Yesterday';
                    } else if (nowMoment.isSame(eventMoment, 'week')) {
                      eventDateSummary = eventMoment.format('dddd');
                    } else if (nowMoment.isSame(eventMoment, 'year')) {
                      eventDateSummary = eventMoment.format('MMMM Do');
                    }
                    lastUpdatedStr = 'Updated: ' + eventDateSummary;
                  }
                  return (
                    <div
                      key={m.id}
                      className="ClientSummary ClientSummaryRecent"
                    >
                      <Link
                        to={'/clients/' + m.id}
                        className="ClientSummaryBody"
                      >
                        <div className="ClientSummaryTitle">
                          {m.brand && m.brand.name ? m.brand.name : ''}
                        </div>
                        <div className="ClientSummarySubTitle">
                          {m.brand && m.brand.teamLeader && m.brand.teamLeader
                            ? (
                                (m.brand.teamLeader.firstName || '').trim() +
                                ' ' +
                                (m.brand.teamLeader.lastName || '').trim()
                              ).trim()
                            : ''}
                        </div>
                      </Link>
                      <div className="ClientSummaryFooter">
                        <div className="ClientSummaryFooterText">
                          {lastUpdatedStr}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {allPaginatedRecentClients.length >= PAGE_LIMIT &&
                oldestPaginatedMatch &&
                showLoadMore && (
                  <div
                    className="DashboardSectionLoadMore DashboardSectionLoadMoreMarginLeft"
                    onClick={loadMoreMatches}
                  >
                    load more
                  </div>
                )}
            </div>
            <div className="AdminDashboardForm">
              <div>
                <div className="AdminDashboardFormTitle">
                  Bring your own clients?
                </div>
                <div className="AdminDashboardFormSubtitle">
                  Storetasker only takes a 3% cut on clients you bring to the
                  platform and you&apos;re welcome to charge them any hourly
                  rate you like.
                </div>
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Brand Name</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Brand Name"
                  value={newBrandName}
                  onChange={(e) => setNewBrandName(e.currentTarget.value)}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Website Url</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Website Url"
                  value={newBrandUrl}
                  onChange={(e) => setNewBrandUrl(e.currentTarget.value)}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">First Name</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="First Name"
                  value={newHumanFirst}
                  onChange={(e) => setNewHumanFirst(e.currentTarget.value)}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Last Name</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Last Name"
                  value={newHumanLast}
                  onChange={(e) => setNewHumanLast(e.currentTarget.value)}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">
                  Email Address
                </div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="email"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Email Address"
                  value={newHumanEmail}
                  onChange={(e) => setNewHumanEmail(e.currentTarget.value)}
                />
              </div>
              <div
                className={
                  'AdminDashboardFormAction ' +
                  (actionLoading ? ' AdminDashboardFormActionLoading ' : '')
                }
                onClick={doCreateClient}
              >
                Create
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">Search Results</div>
            {!searchLoading && searchResults && !searchResults.length && (
              <div className="DashboardSectionEmpty">
                No search results found. Try another search query to find what
                you&apos;re looking for.
              </div>
            )}
            <div className="DashboardSectionRows">
              {!searchLoading &&
                searchResults &&
                !!searchResults.length &&
                searchResults.map((result) => {
                  let subtitle = '';
                  if (result.humanNameMatch) {
                    subtitle =
                      (
                        (result.humanNameMatch.firstName || '').trim() +
                        ' ' +
                        (result.humanNameMatch.lastName || '').trim()
                      ).trim() || '';
                  } else if (result.urlMatch) {
                    subtitle =
                      (result.match.brand && result.match.brand.url) || '';
                  } else if (result.shopifyAdminMatch) {
                    subtitle =
                      (result.match.brand &&
                        result.match.brand.shopifyAdminURL) ||
                      '';
                  }
                  if (
                    !subtitle &&
                    result.match.brand &&
                    result.match.brand.teamLeader
                  ) {
                    subtitle =
                      (
                        (result.match.brand.teamLeader.firstName || '').trim() +
                        ' ' +
                        (result.match.brand.teamLeader.lastName || '').trim()
                      ).trim() || '';
                  }
                  return (
                    <div
                      key={result.match.id}
                      className="ClientSummary ClientSummarySearch"
                    >
                      <Link
                        to={'/clients/' + result.match.id}
                        className="ClientSummaryBody"
                      >
                        <div className="ClientSummaryTitle">
                          {result.match.brand && result.match.brand.name
                            ? result.match.brand.name
                            : ''}
                        </div>
                        <div className="ClientSummarySubTitle">{subtitle}</div>
                      </Link>
                      <div className="ClientSummaryFooter" />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ExpertActiveClients.propTypes = {
  expertId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default ExpertActiveClients;
