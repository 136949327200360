/* eslint-disable react-hooks/rules-of-hooks */
import moment from 'moment-timezone';
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import { ClientWithOnReconnected } from '../../utils/apollo';
import TextareaAutosize from 'react-autosize-textarea';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { PayPalButton } from 'react-paypal-button-v2';
import { convertToRaw, ContentState } from 'draft-js';
import {
  AuthHumanQuery,
  BrandForHumansQuery,
  RequestActiveForBrandsQuery,
  QuoteStatus,
  ProjectDetailsHumanQuery,
  ProjectDetailsHumanQueryVariables,
  BrandEditInvoiceMutation,
  BrandEditInvoiceMutationVariables,
  ProjectCreatePaymentIntentMutation,
  ProjectCreatePaymentIntentMutationVariables,
  ProjectConfirmPaymentIntentMutation,
  ProjectConfirmPaymentIntentMutationVariables,
  ProjectCreatePaypalOrderMutation,
  ProjectCreatePaypalOrderMutationVariables,
  ProjectConfirmPaypalOrderMutation,
  ProjectConfirmPaypalOrderMutationVariables,
  ProjectCreateSubscriptionIntentMutation,
  ProjectCreateSubscriptionIntentMutationVariables,
  ProjectConfirmSubscriptionIntentMutation,
  ProjectConfirmSubscriptionIntentMutationVariables,
  ProjectVerifyFreeAcceptMutation,
  ProjectVerifyFreeAcceptMutationVariables,
  HumanCreateSetupIntentMutation,
  HumanConfirmSetupIntentMutation,
  HumanConfirmSetupIntentMutationVariables,
  ProjectRequestRevisionsMutation,
  ProjectRequestRevisionsMutationVariables,
  ProjectApproveMutation,
  ProjectApproveMutationVariables,
  ProjectFeedbackRatingMutation,
  ProjectFeedbackRatingMutationVariables,
} from '../../gql/graphql';
import urlUtils from '../../utils/url';
import { PAYPAL_CLIENT_ID } from '../../utils/constants';
import envUtils from '../../utils/env';
import { HumanDetail, BrandSummary, ProjectDetailHuman } from '../../utils/gql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import errorUtils from '../../utils/error';
import logError from '../../utils/airbrake';
import {
  applyDiscountCode,
  formatNumberWithCommas,
  centsDollarsRounded,
  projectNotificationStatus,
  starValueFormatted,
  starValueToStr,
  amountClientFee,
  validClientFee,
  valueClientFee,
  validDiscountCode,
  valueDiscountCode,
} from '../../utils/format';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import RichTextEditor from '../feature/RichTextEditor';
import '../../styles/page/HumanProjectDetail.scss';

const projectDetailsHumanQuery = gql`
  query ProjectDetailsHuman($projectId: ID!) {
    projectDetails(projectId: $projectId) {
      ...ProjectDetailHuman
    }
  }
  ${ProjectDetailHuman}
`;

const brandEditInvoiceMutation = gql`
  mutation BrandEditInvoice(
    $brandId: ID!
    $addressLineOne: String
    $addressLineTwo: String
    $legalName: String
    $vatNumber: String
  ) {
    brandEditInvoice(
      brandId: $brandId
      addressLineOne: $addressLineOne
      addressLineTwo: $addressLineTwo
      legalName: $legalName
      vatNumber: $vatNumber
    ) {
      ...BrandSummary
    }
  }
  ${BrandSummary}
`;

const projectCreatePaymentIntentMutation = gql`
  mutation ProjectCreatePaymentIntent(
    $quoteId: ID!
    $matchId: ID!
    $useExistingPaymentMethod: Boolean!
  ) {
    projectCreatePaymentIntent(
      quoteId: $quoteId
      matchId: $matchId
      useExistingPaymentMethod: $useExistingPaymentMethod
    )
  }
`;

const projectConfirmPaymentIntentMutation = gql`
  mutation ProjectConfirmPaymentIntent(
    $quoteId: ID!
    $matchId: ID!
    $paymentIntentId: String!
  ) {
    projectConfirmPaymentIntent(
      quoteId: $quoteId
      matchId: $matchId
      paymentIntentId: $paymentIntentId
    ) {
      ...ProjectDetailHuman
    }
  }
  ${ProjectDetailHuman}
`;

const projectCreatePaypalOrderMutation = gql`
  mutation ProjectCreatePaypalOrder($quoteId: ID!, $matchId: ID!) {
    projectCreatePaypalOrder(quoteId: $quoteId, matchId: $matchId)
  }
`;

const projectConfirmPaypalOrderMutation = gql`
  mutation ProjectConfirmPaypalOrder(
    $quoteId: ID!
    $matchId: ID!
    $paypalOrderId: String!
  ) {
    projectConfirmPaypalOrder(
      quoteId: $quoteId
      matchId: $matchId
      paypalOrderId: $paypalOrderId
    ) {
      ...ProjectDetailHuman
    }
  }
  ${ProjectDetailHuman}
`;

const projectCreateSubscriptionIntentMutation = gql`
  mutation ProjectCreateSubscriptionIntent($quoteId: ID!, $matchId: ID!) {
    projectCreateSubscriptionIntent(quoteId: $quoteId, matchId: $matchId) {
      clientSecret
      subscriptionId
    }
  }
`;

const projectConfirmSubscriptionIntentMutation = gql`
  mutation ProjectConfirmSubscriptionIntent(
    $quoteId: ID!
    $matchId: ID!
    $subscriptionId: String!
  ) {
    projectConfirmSubscriptionIntent(
      quoteId: $quoteId
      matchId: $matchId
      subscriptionId: $subscriptionId
    ) {
      ...ProjectDetailHuman
    }
  }
  ${ProjectDetailHuman}
`;

const projectRequestRevisionsMutation = gql`
  mutation ProjectRequestRevisions(
    $quoteId: ID!
    $matchId: ID!
    $revisionsRequested: String!
  ) {
    projectRequestRevisions(
      quoteId: $quoteId
      matchId: $matchId
      revisionsRequested: $revisionsRequested
    ) {
      ...ProjectDetailHuman
    }
  }
  ${ProjectDetailHuman}
`;

const projectApproveMutation = gql`
  mutation ProjectApprove($quoteId: ID!, $matchId: ID!) {
    projectApprove(quoteId: $quoteId, matchId: $matchId) {
      ...ProjectDetailHuman
    }
  }
  ${ProjectDetailHuman}
`;

const projectFeedbackRatingMutation = gql`
  mutation ProjectFeedbackRating(
    $projectId: ID!
    $feedbackDetail: String!
    $feedbackValue: String!
  ) {
    projectFeedbackRating(
      projectId: $projectId
      feedbackDetail: $feedbackDetail
      feedbackValue: $feedbackValue
    ) {
      ...ProjectDetailHuman
    }
  }
  ${ProjectDetailHuman}
`;

const projectVerifyFreeAcceptMutation = gql`
  mutation ProjectVerifyFreeAccept($quoteId: ID!, $matchId: ID!) {
    projectVerifyFreeAccept(quoteId: $quoteId, matchId: $matchId) {
      ...ProjectDetailHuman
    }
  }
  ${ProjectDetailHuman}
`;

const humanCreateSetupIntentMutation = gql`
  mutation HumanCreateSetupIntent {
    humanCreateSetupIntent
  }
`;

const humanConfirmSetupIntentMutation = gql`
  mutation HumanConfirmSetupIntent($setupIntentId: String!) {
    humanConfirmSetupIntent(setupIntentId: $setupIntentId) {
      ...HumanDetail
    }
  }
  ${HumanDetail}
`;

interface HumanProjectDetailProps {
  activeRequests: RequestActiveForBrandsQuery['requestActiveForBrands'];
  brands: BrandForHumansQuery['brandForHumans'];
  hasDiscountCode: string;
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
  projectId: string;
  showUpgradeModal: () => void;
  socketClient: ClientWithOnReconnected;
}

const initTime = new Date().getTime();

const HumanProjectDetail = ({
  activeRequests,
  brands,
  hasDiscountCode,
  human,
  projectId,
  showUpgradeModal,
  socketClient,
}: HumanProjectDetailProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const [primaryPanel, setPrimaryPanel] = useState('Details');
  const secondaryPanels = ['Details'].filter((p) => p !== primaryPanel);
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
  }
  const [currModal, setCurrModal] = useState(() => {
    if (urlUtils.getQueryParam('pay') === 'true') return 'pay';
    if (urlUtils.getQueryParam('revisions') === 'true') return 'revisions';
    return '';
  });
  const stripe = useStripe();
  const elements = useElements();
  const [actionLoading, setActionLoading] = useState(false);
  const [invoiceName, setInvoiceName] = useState('');
  const [invoiceAddressOne, setInvoiceAddressOne] = useState('');
  const [invoiceAddressTwo, setInvoiceAddressTwo] = useState('');
  const [invoiceVat, setInvoiceVat] = useState('');
  const [switchPaymentMethod, setSwitchPaymentMethod] = useState(false);
  const [stripeElementError, setStripeElementError] = useState('');
  const [stripeCardReady, setStripeCardReady] = useState(false);
  const [revisionsContent, setRevisionsContent] = useState<ContentState | null>(
    null,
  );
  const [ratingStars, setRatingStars] = useState(0);
  const [ratingStarsHover, setRatingStarsHover] = useState(0);
  const [ratingText, setRatingText] = useState('');
  const [tryCreatePaypalOrder] = useMutation<
    ProjectCreatePaypalOrderMutation,
    ProjectCreatePaypalOrderMutationVariables
  >(projectCreatePaypalOrderMutation);
  const [tryConfirmPaypalOrder] = useMutation<
    ProjectConfirmPaypalOrderMutation,
    ProjectConfirmPaypalOrderMutationVariables
  >(projectConfirmPaypalOrderMutation);
  const [tryGiveFeedback] = useMutation<
    ProjectFeedbackRatingMutation,
    ProjectFeedbackRatingMutationVariables
  >(projectFeedbackRatingMutation);
  const [tryApproveProject] = useMutation<
    ProjectApproveMutation,
    ProjectApproveMutationVariables
  >(projectApproveMutation);
  const [tryRequestRevisions] = useMutation<
    ProjectRequestRevisionsMutation,
    ProjectRequestRevisionsMutationVariables
  >(projectRequestRevisionsMutation);
  const [tryVerifyFreeAccept] = useMutation<
    ProjectVerifyFreeAcceptMutation,
    ProjectVerifyFreeAcceptMutationVariables
  >(projectVerifyFreeAcceptMutation);
  const [tryEditInvoice] = useMutation<
    BrandEditInvoiceMutation,
    BrandEditInvoiceMutationVariables
  >(brandEditInvoiceMutation);
  const [tryCreatePaymentIntent] = useMutation<
    ProjectCreatePaymentIntentMutation,
    ProjectCreatePaymentIntentMutationVariables
  >(projectCreatePaymentIntentMutation);
  const [tryConfirmPaymentIntent] = useMutation<
    ProjectConfirmPaymentIntentMutation,
    ProjectConfirmPaymentIntentMutationVariables
  >(projectConfirmPaymentIntentMutation);
  const [tryCreateSubscription] = useMutation<
    ProjectCreateSubscriptionIntentMutation,
    ProjectCreateSubscriptionIntentMutationVariables
  >(projectCreateSubscriptionIntentMutation);
  const [tryConfirmSubscription] = useMutation<
    ProjectConfirmSubscriptionIntentMutation,
    ProjectConfirmSubscriptionIntentMutationVariables
  >(projectConfirmSubscriptionIntentMutation);
  const [tryCreateSetupIntent] = useMutation<HumanCreateSetupIntentMutation>(
    humanCreateSetupIntentMutation,
  );
  const [tryConfirmSetupIntent] = useMutation<
    HumanConfirmSetupIntentMutation,
    HumanConfirmSetupIntentMutationVariables
  >(humanConfirmSetupIntentMutation);
  const {
    loading: loadingProject,
    error: errorProject,
    data: dataProject,
    refetch: refetchProject,
  } = useQuery<ProjectDetailsHumanQuery, ProjectDetailsHumanQueryVariables>(
    projectDetailsHumanQuery,
    {
      returnPartialData: true,
      variables: {
        projectId,
      },
    },
  );
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('HumanProjectDetail socketClient onReconnected');
      refetchProject().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchProject]);
  const projectDetails =
    dataProject && dataProject.projectDetails && dataProject.projectDetails.id
      ? dataProject.projectDetails
      : undefined;
  let brandForProject: BrandForHumansQuery['brandForHumans'][0] | undefined;
  if (projectDetails && projectDetails.brandStr) {
    brandForProject = brands.find((b) => b.id === projectDetails.brandStr);
  }
  let requestForProject:
    | RequestActiveForBrandsQuery['requestActiveForBrands'][0]
    | undefined;
  if (projectDetails && projectDetails.matchStr) {
    requestForProject = activeRequests.find(
      (r) =>
        r.matchLog &&
        r.matchLog.find(
          (ml) => !ml.unmatchedAt && ml.matchStr === projectDetails.matchStr,
        ),
    );
  }
  let cleanModal = currModal;
  if (projectDetails && projectDetails.quote) {
    if (
      cleanModal === 'pay' &&
      ['CREATED', 'UPDATED'].indexOf(projectDetails.quote.status) === -1
    ) {
      cleanModal = 'info';
    }
  }

  let autoApproveStr = '3 days';
  if (
    projectDetails &&
    projectDetails.quote &&
    projectDetails.quote.status === QuoteStatus.Completed &&
    projectDetails.autoApproveAt
  ) {
    const timeDiff = projectDetails.autoApproveAt - initTime;
    if (timeDiff > 0) {
      const daysLeft = Math.round(timeDiff / (1000 * 60 * 60 * 24));
      if (daysLeft) {
        autoApproveStr =
          daysLeft.toString() + ' day' + (daysLeft > 1 ? 's' : '');
      } else {
        autoApproveStr = 'less than a day';
      }
    }
  }
  let timelineStr = '';
  if (
    projectDetails &&
    projectDetails.quote &&
    projectDetails.quote.paymentType === 'PROJECT'
  ) {
    if (projectDetails.quote.estimatedCompletionDate) {
      const nowMoment = moment.tz(initTime, moment.tz.guess());
      const dueMoment = moment
        .utc(projectDetails.quote.estimatedCompletionDate)
        .tz('UTC');
      if (nowMoment.isSame(dueMoment, 'year')) {
        timelineStr = dueMoment.format('MMMM Do');
      } else {
        timelineStr = dueMoment.format('MMMM Do, YYYY');
      }
    } else if (projectDetails.quote.estimatedCompletionDays) {
      timelineStr = `${
        projectDetails.quote.estimatedCompletionDays
      } business day${
        projectDetails.quote.estimatedCompletionDays > 1 ? 's' : ''
      }`;
    } else {
      timelineStr = '3 days';
    }
  }
  const showRevisionsRequested = !!(
    projectDetails &&
    projectDetails.quote &&
    projectDetails.quote.revisionsRequested &&
    ['ACCEPTED', 'COMPLETED', 'REVISIONS_REQUESTED', 'IN_PROGRESS'].indexOf(
      projectDetails.quote.status,
    ) >= 0
  );
  const withDiscountCode = validDiscountCode(
    (projectDetails && projectDetails.discountCode) || hasDiscountCode,
  );
  const isProjectDiscountCode = !!(
    projectDetails && projectDetails.discountCode
  );
  const discountPercent = valueDiscountCode(withDiscountCode);
  const inBuyingMode = !!(
    projectDetails &&
    (projectDetails.quote.status === QuoteStatus.Created ||
      projectDetails.quote.status === QuoteStatus.Updated)
  );
  const projectClientFee = (projectDetails && projectDetails.clientFee) || '';
  const withClientFee = validClientFee(
    inBuyingMode && human && human.proStart ? '' : projectClientFee,
  );
  const fakeWithClientFee = validClientFee(
    inBuyingMode && human && human.proStart
      ? projectClientFee || human.clientFee
      : '',
  );
  const clientFeePercent = valueClientFee(withClientFee);
  const fakeClientFeePercent = valueClientFee(fakeWithClientFee);
  let quoteAcceptInfoStr =
    "We'll hold your payment in escrow. Your expert only gets paid after you review & approve their work.";
  let priceDiffRaw = 0;
  let priceDiffRawDiscount = 0;
  let samePriceAsAccepted = false;
  let priceDiffStr = '';
  let priceDiffStrDiscount = '';
  const useExistingCard = !!(
    human.paymentMethodId &&
    human.cardBrand &&
    human.cardLast4 &&
    !switchPaymentMethod
  );
  if (
    projectDetails &&
    projectDetails.quote &&
    (projectDetails.quote.status === QuoteStatus.Updated ||
      projectDetails.quote.status === QuoteStatus.Created) &&
    projectDetails.quote.cents &&
    projectDetails.latestAcceptCents
  ) {
    const paidSoFarStr = formatNumberWithCommas(
      centsDollarsRounded(
        projectDetails.latestAcceptCents *
          (1 - discountPercent) *
          (1 + clientFeePercent),
      ),
    );
    if (projectDetails.latestAcceptCents !== projectDetails.quote.cents) {
      priceDiffRaw =
        projectDetails.latestAcceptCents - projectDetails.quote.cents;
      priceDiffStr = formatNumberWithCommas(
        centsDollarsRounded(Math.abs(priceDiffRaw)),
      );
      priceDiffRawDiscount =
        (projectDetails.latestAcceptCents - projectDetails.quote.cents) *
        (1 - discountPercent) *
        (1 + clientFeePercent);
      priceDiffStrDiscount = formatNumberWithCommas(
        centsDollarsRounded(Math.abs(priceDiffRawDiscount)),
      );
      quoteAcceptInfoStr =
        priceDiffRaw > 0
          ? `The price has decreased by $${priceDiffStrDiscount}. Once you accept the new quote, our Support Team will refund you the difference.`
          : `You've already paid $${paidSoFarStr} in escrow, so you'll only get charged for the additional $${priceDiffStrDiscount}.`;
    } else {
      samePriceAsAccepted = true;
      quoteAcceptInfoStr = `You've already paid $${paidSoFarStr} in escrow, so you won't be charged any additional cost to accept the updated quote.`;
    }
  }
  if (
    projectDetails &&
    projectDetails.quote &&
    (projectDetails.quote.paymentType === 'WEEKLY_SUBSCRIPTION' ||
      projectDetails.quote.paymentType === 'MONTHLY_SUBSCRIPTION')
  ) {
    quoteAcceptInfoStr = `You'll be charged on a ${
      projectDetails.quote.paymentType === 'WEEKLY_SUBSCRIPTION'
        ? 'weekly'
        : 'monthly'
    } basis until the subscription is canceled.`;
  } else if (
    projectDetails &&
    projectDetails.quote &&
    projectDetails.quote.paymentType === 'BILL'
  ) {
    quoteAcceptInfoStr =
      "You'll be charged now to cover the cost of the project bill.";
    if (brandForProject && brandForProject.invoicingEnabled) {
      quoteAcceptInfoStr =
        "You'll get an invoice for this project bill within a few days from our Support Team.";
    }
  } else if (brandForProject && brandForProject.invoicingEnabled) {
    quoteAcceptInfoStr =
      "You'll be invoiced after the project is done and you've approved the work.";
  }
  function tryFreeAccept() {
    if (actionLoading || !projectDetails || !projectDetails.quote) {
      return;
    }
    setActionLoading(true);
    tryVerifyFreeAccept({
      optimisticResponse: {
        projectVerifyFreeAccept: {
          ...projectDetails,
          isActive: projectDetails.quote.paymentType !== 'BILL',
          latestAcceptCents: projectDetails.quote.cents,
          quote: {
            ...projectDetails.quote,
            id: 'tempQuoteId',
            status:
              projectDetails.quote.paymentType === 'BILL'
                ? QuoteStatus.BillPaid
                : QuoteStatus.Accepted,
          },
        },
      },
      variables: {
        matchId: projectDetails.matchStr,
        quoteId: projectDetails.quote.id,
      },
    })
      .then(() => {
        setActionLoading(false);
        setCurrModal(
          projectDetails.quote.paymentType === 'PROJECT' &&
            activeRequests.length < 3
            ? 'post-accept'
            : '',
        );
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Accept Error');
        logError(err, {
          component: 'ProjectDetailView',
          func: 'tryFreeAccept',
        });
      });
  }
  function tryAcceptQuote() {
    if (
      projectDetails &&
      projectDetails.quote &&
      projectDetails.quote.paymentType !== 'PROJECT' &&
      projectDetails.quote.paymentType !== 'BILL'
    ) {
      setCurrModal('pay');
    } else if (brandForProject && brandForProject.invoicingEnabled) {
      tryFreeAccept();
    } else if (priceDiffRaw > 0 || samePriceAsAccepted) {
      tryFreeAccept();
    } else {
      setCurrModal('pay');
    }
  }
  function tryRequestChanges() {
    setCurrModal('revisions');
  }
  function doRequestChanges() {
    if (actionLoading) return;
    if (!projectDetails || !projectDetails.quote) {
      addNotification(
        'An error occured, please refresh and try again',
        undefined,
        5000,
      );
      return;
    }
    if (!brandForProject) {
      addNotification(
        "We can't find your account on this brand's team. Please contact support@storetasker.com",
        undefined,
        5000,
      );
      return;
    }
    let draftContent = '';
    if (revisionsContent && revisionsContent.hasText()) {
      if (revisionsContent.getPlainText().trim().replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(revisionsContent));
        } catch (rawError) {
          console.log('request changes convertToRaw error', rawError);
        }
      }
    }
    if (!draftContent) {
      addNotification(
        'Please fill out your message details before submitting your request for changes.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryRequestRevisions({
      optimisticResponse: {
        projectRequestRevisions: {
          ...projectDetails,
          autoApproveAt: null,
          isActive: true,
          quote: {
            ...projectDetails.quote,
            id: 'tempQuoteId',
            revisionsRequested: draftContent,
            status: QuoteStatus.RevisionsRequested,
          },
        },
      },
      variables: {
        matchId: projectDetails.matchStr,
        quoteId: projectDetails.quote.id,
        revisionsRequested: draftContent,
      },
    })
      .then(() => {
        setActionLoading(false);
        setCurrModal('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Request Changes Error',
        );
        logError(err, {
          component: 'ProjectDetailView',
          func: 'tryRequestRevisions',
        });
      });
  }
  function doApprove() {
    if (actionLoading) return;
    if (!projectDetails || !projectDetails.quote) {
      addNotification(
        'An error occured, please refresh and try again',
        undefined,
        5000,
      );
      return;
    }
    if (!brandForProject) {
      addNotification(
        "We can't find your account on this brand's team. Please contact support@storetasker.com",
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryApproveProject({
      optimisticResponse: {
        projectApprove: {
          ...projectDetails,
          autoApproveAt: null,
          isActive: false,
          quote: {
            ...projectDetails.quote,
            id: 'tempQuoteId',
            status: QuoteStatus.Approved,
          },
        },
      },
      variables: {
        matchId: projectDetails.matchStr,
        quoteId: projectDetails.quote.id,
      },
    })
      .then(() => {
        setActionLoading(false);
        setCurrModal('rating');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Approve Project Error',
        );
        logError(err, {
          component: 'ProjectDetailView',
          func: 'tryApproveProject',
        });
      });
  }
  const formattedStars = starValueFormatted(ratingStars);
  function doGiveFeedback() {
    if (actionLoading) return;
    if (!projectDetails) {
      addNotification(
        'An error occured, please refresh and try again',
        undefined,
        5000,
      );
      return;
    }
    if (!formattedStars || !ratingText.trim()) {
      addNotification(
        'Please give a star rating and write a quick blurb to leave a review for your expert!',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryGiveFeedback({
      optimisticResponse: {
        projectFeedbackRating: {
          ...projectDetails,
          feedbackDetail: ratingText,
          feedbackValue: formattedStars,
        },
      },
      variables: {
        feedbackDetail: ratingText,
        feedbackValue: formattedStars,
        projectId: projectDetails.id,
      },
    })
      .then(() => {
        setActionLoading(false);
        setCurrModal(
          activeRequests.length < 3 && formattedStars === 'GOOD'
            ? 'post-approve'
            : '',
        );
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Rating Error');
        logError(err, {
          component: 'ProjectDetailView',
          func: 'tryGiveFeedback',
        });
      });
  }
  function onPaypalCreateOrder() {
    if (actionLoading) return '';
    if (!projectDetails || !projectDetails.quote) {
      addNotification(
        'An error occured, please refresh and try again',
        undefined,
        5000,
      );
      return '';
    }
    return tryCreatePaypalOrder({
      variables: {
        matchId: projectDetails.matchStr,
        quoteId: projectDetails.quote.id,
      },
    })
      .then(({ data }) => {
        if (data && data.projectCreatePaypalOrder) {
          return data.projectCreatePaypalOrder;
        }
        addNotification(
          'An error occured, please refresh and try again',
          undefined,
          5000,
        );
        return '';
      })
      .catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Paypal Order Create Error',
        );
        logError(err, {
          component: 'ProjectDetailView',
          func: 'tryCreatePaypalOrder',
        });
        return '';
      });
  }
  function onPaypalError(paypalError: Error) {
    setActionLoading(false);
    addNotification(
      paypalError && paypalError.message
        ? paypalError.message
        : 'Paypal payment was not successful. Please try again.',
    );
    logError(paypalError, {
      component: 'ProjectDetailView',
      func: 'onPaypalError',
    });
  }
  function onPaypalApproveOrder(orderDetailData: any) {
    if (actionLoading) return '';
    if (
      !projectDetails ||
      !projectDetails.quote ||
      !orderDetailData ||
      !orderDetailData.orderID
    ) {
      addNotification(
        'An error occured, please refresh and try again',
        undefined,
        5000,
      );
      return '';
    }
    setActionLoading(true);
    return tryConfirmPaypalOrder({
      optimisticResponse: {
        projectConfirmPaypalOrder: {
          ...projectDetails,
          isActive: projectDetails.quote.paymentType !== 'BILL',
          latestAcceptCents: projectDetails.quote.cents,
          quote: {
            ...projectDetails.quote,
            id: 'tempQuoteId',
            status:
              projectDetails.quote.paymentType === 'BILL'
                ? QuoteStatus.BillPaid
                : QuoteStatus.Accepted,
          },
        },
      },
      variables: {
        matchId: projectDetails.matchStr,
        paypalOrderId: orderDetailData.orderID,
        quoteId: projectDetails.quote.id,
      },
    })
      .then(() => {
        setActionLoading(false);
        setCurrModal(
          projectDetails.quote.paymentType === 'PROJECT' &&
            activeRequests.length < 3
            ? 'post-accept'
            : '',
        );
      })
      .catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Paypal Order Confirm Error',
        );
        logError(err, {
          component: 'ProjectDetailView',
          func: 'tryConfirmPaypalOrder',
        });
        return '';
      });
  }
  function tryAcceptSubscription(paymentMethodId: string) {
    if (!projectDetails || !projectDetails.quote || !elements || !stripe) {
      addNotification(
        'An error occured, please refresh and try again',
        undefined,
        5000,
      );
      setActionLoading(false);
      return;
    }
    tryCreateSubscription({
      variables: {
        matchId: projectDetails.matchStr,
        quoteId: projectDetails.quote.id,
      },
    })
      .then(({ data }) => {
        if (
          !data ||
          !data.projectCreateSubscriptionIntent ||
          !data.projectCreateSubscriptionIntent.subscriptionId
        ) {
          setActionLoading(false);
          addNotification(
            'An error occured, please refresh and try again',
            undefined,
            5000,
          );
          return;
        }
        if (data.projectCreateSubscriptionIntent.clientSecret) {
          stripe
            .confirmCardPayment(
              data.projectCreateSubscriptionIntent.clientSecret,
              {
                payment_method: paymentMethodId,
                setup_future_usage: 'off_session',
              },
            )
            .then(({ error, paymentIntent }) => {
              if (
                paymentIntent &&
                paymentIntent.status === 'succeeded' &&
                paymentIntent.id
              ) {
                tryConfirmSubscription({
                  optimisticResponse: {
                    projectConfirmSubscriptionIntent: {
                      ...projectDetails,
                      isActive: true,
                      latestAcceptCents: projectDetails.quote.cents,
                      quote: {
                        ...projectDetails.quote,
                        id: 'tempQuoteId',
                        status: QuoteStatus.SubscriptionPaymentMade,
                      },
                    },
                  },
                  variables: {
                    matchId: projectDetails.matchStr,
                    quoteId: projectDetails.quote.id,
                    subscriptionId:
                      data.projectCreateSubscriptionIntent.subscriptionId,
                  },
                })
                  .then(() => {
                    setActionLoading(false);
                    setCurrModal('');
                  })
                  .catch((err: ApolloError) => {
                    setActionLoading(false);
                    addNotification(
                      errorUtils.getErrorMessage(err) ||
                        'Subscription Confirm Error',
                    );
                    logError(err, {
                      component: 'ProjectDetailView',
                      func: 'tryConfirmSubscription',
                    });
                  });
                return;
              }
              setActionLoading(false);
              addNotification(
                error && error.message
                  ? error.message
                  : 'An error occured, please try again',
              );
              logError(
                (error && error.message) ||
                  'Confirm Subscription Payment Error',
                {
                  component: 'ProjectDetailView',
                  func: 'confirmCardPayment - subscription',
                },
              );
            })
            .catch((err: Error) => {
              setActionLoading(false);
              addNotification(err.message || 'Subscription Intent Error');
              logError(err, {
                component: 'ProjectDetailView',
                func: 'confirmCardPayment - subscription',
              });
            });
        } else {
          tryConfirmSubscription({
            optimisticResponse: {
              projectConfirmSubscriptionIntent: {
                ...projectDetails,
                isActive: true,
                latestAcceptCents: projectDetails.quote.cents,
                quote: {
                  ...projectDetails.quote,
                  id: 'tempQuoteId',
                  status: QuoteStatus.SubscriptionPaymentMade,
                },
              },
            },
            variables: {
              matchId: projectDetails.matchStr,
              quoteId: projectDetails.quote.id,
              subscriptionId:
                data.projectCreateSubscriptionIntent.subscriptionId,
            },
          })
            .then(() => {
              setActionLoading(false);
              setCurrModal('');
            })
            .catch((err: ApolloError) => {
              setActionLoading(false);
              addNotification(
                errorUtils.getErrorMessage(err) || 'Subscription Confirm Error',
              );
              logError(err, {
                component: 'ProjectDetailView',
                func: 'tryConfirmSubscription',
              });
            });
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Subscription Create Error',
        );
        logError(err, {
          component: 'ProjectDetailView',
          func: 'tryCreateSubscription',
        });
      });
  }
  function tryPaidAccept() {
    if (actionLoading) return;
    if (!projectDetails || !projectDetails.quote || !elements || !stripe) {
      addNotification(
        'An error occured, please refresh and try again',
        undefined,
        5000,
      );
      return;
    }
    if (!useExistingCard && !stripeCardReady) {
      addNotification(
        'Please fill out your credit card details in order to use this payment method.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    if (
      projectDetails.quote.paymentType === 'PROJECT' ||
      projectDetails.quote.paymentType === 'BILL'
    ) {
      tryCreatePaymentIntent({
        variables: {
          matchId: projectDetails.matchStr,
          quoteId: projectDetails.quote.id,
          useExistingPaymentMethod: useExistingCard,
        },
      })
        .then(({ data }) => {
          if (!data || !data.projectCreatePaymentIntent) {
            setActionLoading(false);
            addNotification(
              'An error occured, please refresh and try again',
              undefined,
              5000,
            );
            return;
          }
          stripe
            .confirmCardPayment(data.projectCreatePaymentIntent, {
              payment_method: useExistingCard
                ? human.paymentMethodId!
                : {
                    card: elements.getElement(CardElement)!,
                  },
              setup_future_usage: 'off_session',
            })
            .then(({ error, paymentIntent }) => {
              if (
                paymentIntent &&
                paymentIntent.status === 'succeeded' &&
                paymentIntent.id
              ) {
                tryConfirmPaymentIntent({
                  optimisticResponse: {
                    projectConfirmPaymentIntent: {
                      ...projectDetails,
                      isActive: projectDetails.quote.paymentType !== 'BILL',
                      latestAcceptCents: projectDetails.quote.cents,
                      quote: {
                        ...projectDetails.quote,
                        id: 'tempQuoteId',
                        status:
                          projectDetails.quote.paymentType === 'BILL'
                            ? QuoteStatus.BillPaid
                            : QuoteStatus.Accepted,
                      },
                    },
                  },
                  variables: {
                    matchId: projectDetails.matchStr,
                    paymentIntentId: paymentIntent.id,
                    quoteId: projectDetails.quote.id,
                  },
                })
                  .then(() => {
                    setActionLoading(false);
                    setCurrModal(
                      projectDetails.quote.paymentType === 'PROJECT' &&
                        activeRequests.length < 3
                        ? 'post-accept'
                        : '',
                    );
                  })
                  .catch((err: ApolloError) => {
                    setActionLoading(false);
                    addNotification(
                      errorUtils.getErrorMessage(err) ||
                        'Payment Intent Confirm Error',
                    );
                    logError(err, {
                      component: 'ProjectDetailView',
                      func: 'tryConfirmPaymentIntent',
                    });
                  });
                return;
              }
              setActionLoading(false);
              addNotification(
                error && error.message
                  ? error.message
                  : 'An error occured, please try again',
              );
              logError(
                (error && error.message) || 'Confirm Card Payment Error',
                {
                  component: 'ProjectDetailView',
                  func: 'confirmCardPayment - project',
                },
              );
            })
            .catch((err: Error) => {
              setActionLoading(false);
              addNotification(err.message || 'Payment Intent Error');
              logError(err, {
                component: 'ProjectDetailView',
                func: 'confirmCardPayment - project',
              });
            });
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Payment Intent Create Error',
          );
          logError(err, {
            component: 'ProjectDetailView',
            func: 'tryCreatePaymentIntent',
          });
        });
    } else if (useExistingCard) {
      tryAcceptSubscription(human.paymentMethodId!);
    } else {
      tryCreateSetupIntent()
        .then(({ data }) => {
          if (!data || !data.humanCreateSetupIntent) {
            setActionLoading(false);
            addNotification(
              'An error occured, please refresh and try again',
              undefined,
              5000,
            );
            return;
          }
          stripe
            .confirmCardSetup(data.humanCreateSetupIntent, {
              payment_method: {
                card: elements.getElement(CardElement)!,
              },
            })
            .then(({ error, setupIntent }) => {
              if (
                setupIntent &&
                setupIntent.status === 'succeeded' &&
                setupIntent.id
              ) {
                tryConfirmSetupIntent({
                  variables: {
                    setupIntentId: setupIntent.id,
                  },
                })
                  .then(({ data: dataTempHuman }) => {
                    if (
                      dataTempHuman &&
                      dataTempHuman.humanConfirmSetupIntent &&
                      dataTempHuman.humanConfirmSetupIntent.paymentMethodId
                    ) {
                      tryAcceptSubscription(
                        dataTempHuman.humanConfirmSetupIntent.paymentMethodId,
                      );
                      return;
                    }
                    setActionLoading(false);
                    addNotification(
                      'An error occured, please refresh and try again',
                      undefined,
                      5000,
                    );
                    logError('Setup Intent Confirm Error', {
                      component: 'ProjectDetailView',
                      func: 'tryConfirmSetupIntent',
                    });
                  })
                  .catch((err: ApolloError) => {
                    setActionLoading(false);
                    addNotification(
                      errorUtils.getErrorMessage(err) ||
                        'Setup Intent Confirm Error',
                    );
                    logError(err, {
                      component: 'ProjectDetailView',
                      func: 'tryConfirmSetupIntent',
                    });
                  });
                return;
              }
              setActionLoading(false);
              addNotification(
                error && error.message
                  ? error.message
                  : 'An error occured, please try again',
              );
              logError((error && error.message) || 'Confirm Card Setup Error', {
                component: 'ProjectDetailView',
                func: 'confirmCardSetup',
              });
            })
            .catch((err: Error) => {
              setActionLoading(false);
              addNotification(err.message || 'Setup Intent Error');
              logError(err, {
                component: 'ProjectDetailView',
                func: 'confirmCardSetup',
              });
            });
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Card Setup Error',
          );
          logError(err, {
            component: 'ProjectDetailView',
            func: 'tryCreateSetupIntent',
          });
        });
    }
  }
  function tryPaymentAccept() {
    if (
      projectDetails &&
      projectDetails.quote &&
      projectDetails.quote.paymentType !== 'PROJECT' &&
      projectDetails.quote.paymentType !== 'BILL'
    ) {
      tryPaidAccept();
    } else if (brandForProject && brandForProject.invoicingEnabled) {
      tryFreeAccept();
    } else if (priceDiffRaw > 0 || samePriceAsAccepted) {
      tryFreeAccept();
    } else {
      tryPaidAccept();
    }
  }
  function handleStripeElementChange(event: StripeCardElementChangeEvent) {
    setStripeCardReady(!event.empty && !event.error);
    setStripeElementError(event.error ? event.error.message : '');
  }
  function trySaveInvoice() {
    if (actionLoading) return;
    if (!brandForProject) {
      addNotification(
        'An error occured, please refresh and try again',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryEditInvoice({
      variables: {
        addressLineOne: invoiceAddressOne,
        addressLineTwo: invoiceAddressTwo,
        brandId: brandForProject.id,
        legalName: invoiceName,
        vatNumber: invoiceVat,
      },
    })
      .then(() => {
        setActionLoading(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Invoice Edit Error',
        );
        logError(err, {
          component: 'ProjectDetailView',
          func: 'tryEditInvoice',
        });
      });
  }
  function blockClickPropagation(ev: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  function startInvoice() {
    if (brandForProject && brandForProject.businessInfo) {
      setInvoiceName(brandForProject.businessInfo.legalName || '');
      setInvoiceVat(brandForProject.businessInfo.vatNumber || '');
      setInvoiceAddressOne(brandForProject.businessInfo.addressLineOne || '');
      setInvoiceAddressTwo(brandForProject.businessInfo.addressLineTwo || '');
    }
    setShowSidebar(false);
    setCurrModal('invoice');
  }
  function stopInvoice() {
    setInvoiceName('');
    setInvoiceVat('');
    setInvoiceAddressOne('');
    setInvoiceAddressTwo('');
    setCurrModal('');
  }
  function stopPostAccept() {
    setCurrModal('');
  }
  function stopPostApprove() {
    setCurrModal('');
  }
  function stopRevisions() {
    setRevisionsContent(null);
    setCurrModal('');
  }
  function stopRating() {
    setRatingStars(0);
    setRatingStarsHover(0);
    setRatingText('');
    setCurrModal('');
  }
  function stopPay() {
    setSwitchPaymentMethod(false);
    setStripeElementError('');
    setStripeCardReady(false);
    setCurrModal('');
  }
  const anyEditsToInvoiceSettings = !!(
    brandForProject &&
    brandForProject.businessInfo &&
    ((brandForProject.businessInfo.legalName || '') !== invoiceName ||
      (brandForProject.businessInfo.vatNumber || '') !== invoiceVat ||
      (brandForProject.businessInfo.addressLineOne || '') !==
        invoiceAddressOne ||
      (brandForProject.businessInfo.addressLineTwo || '') !== invoiceAddressTwo)
  );
  const billPayStr =
    brandForProject && brandForProject.invoicingEnabled
      ? 'Accept Bill'
      : 'Pay Bill';
  const billNowStr =
    brandForProject && brandForProject.invoicingEnabled
      ? 'Accept Bill'
      : 'Pay Now';

  let projectPayTitle = 'Your project';
  if (projectDetails && projectDetails.quote) {
    if (projectDetails.quote.paymentType === 'BILL') {
      projectPayTitle = 'Your bill';
    } else if (projectDetails.quote.paymentType === 'WEEKLY_SUBSCRIPTION') {
      projectPayTitle = 'Weekly subscription';
    } else if (projectDetails.quote.paymentType === 'MONTHLY_SUBSCRIPTION') {
      projectPayTitle = 'Monthly subscription';
    }
  }

  const payIsReadyForSubmit = !!(
    projectDetails &&
    projectDetails.quote &&
    elements &&
    stripe &&
    (useExistingCard || stripeCardReady)
  );
  const ratingIsReadyForSubmit = !!(
    projectDetails &&
    projectDetails.quote &&
    formattedStars &&
    ratingText.trim()
  );
  if (loadingProject) {
    // ignore these
  }

  return (
    <div className="DashboardModal ClientDetailModal HumanProjectDetailModal">
      <div className="DashboardModalTopCircle" />
      <div className="DashboardModalBottomCircle" />
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '') +
          (cleanModal ? ' ThreadDetailViewWithModal ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <Link to="/projects" className="ThreadDetailMainHeaderBack">
              Projects
            </Link>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody">
            {!!projectDetails && (
              <div className="ThreadDetailMainBodyFocus">
                <div
                  className={
                    'ThreadDetailMainBodyFocusStatusTag ThreadDetailMainBodyFocusStatusTag-' +
                    projectDetails.quote.status
                  }
                >
                  {projectNotificationStatus(
                    projectDetails.quote.status,
                    projectDetails.quote.paymentType,
                  )}
                </div>
                <div className="ThreadDetailMainBodyFocusMedtitle">
                  {projectDetails.quote.title}
                </div>
                <div className="ProjectDetailFeatures">
                  {projectDetails.quote.paymentType === 'PROJECT' && (
                    <div className="ProjectDetailFeature">
                      <div className="ProjectDetailFeatureTitle">Timeline</div>
                      <div className="ProjectDetailFeatureValue">
                        {timelineStr}
                      </div>
                    </div>
                  )}
                  {(projectDetails.quote.paymentType ===
                    'WEEKLY_SUBSCRIPTION' ||
                    projectDetails.quote.paymentType ===
                      'MONTHLY_SUBSCRIPTION') && (
                    <div className="ProjectDetailFeature">
                      <div className="ProjectDetailFeatureTitle">
                        Subscription
                      </div>
                      <div className="ProjectDetailFeatureValue">
                        {projectDetails.quote.paymentType ===
                        'WEEKLY_SUBSCRIPTION'
                          ? 'Weekly'
                          : 'Monthly'}
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      'ProjectDetailFeature ' +
                      (withDiscountCode
                        ? ' ProjectDetailFeatureDiscounted '
                        : '')
                    }
                  >
                    <div className="ProjectDetailFeatureTitle">Cost</div>
                    <div className="ProjectDetailFeatureValue">
                      $
                      {formatNumberWithCommas(
                        centsDollarsRounded(projectDetails.quote.cents),
                      )}
                      {!!priceDiffRaw && (
                        <span
                          className={
                            priceDiffRaw > 0
                              ? 'ProjectDetailPriceRefund'
                              : 'ProjectDetailPriceExtra'
                          }
                        >
                          ${priceDiffStr}
                        </span>
                      )}
                    </div>
                  </div>
                  {!!withDiscountCode && (
                    <div className="ProjectDetailFeature ProjectDetailFeatureWithDiscount">
                      <div className="ProjectDetailFeatureTitle">
                        With Discount
                      </div>
                      <div className="ProjectDetailFeatureValue">
                        $
                        {formatNumberWithCommas(
                          centsDollarsRounded(
                            projectDetails.quote.cents * (1 - discountPercent),
                          ),
                        )}
                        {!!priceDiffRaw && (
                          <span
                            className={
                              priceDiffRaw > 0
                                ? 'ProjectDetailPriceRefund'
                                : 'ProjectDetailPriceExtra'
                            }
                          >
                            ${priceDiffStrDiscount}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {!!withDiscountCode && (
                  <div className="ProjectDetailScope">
                    <div className="ProjectDetailScopeTitle">Discount Code</div>
                    <div className="ProjectDetailScopeBody">
                      {applyDiscountCode(
                        isProjectDiscountCode,
                        withDiscountCode,
                      )}
                    </div>
                  </div>
                )}
                <div className="ProjectDetailScope">
                  <div className="ProjectDetailScopeTitle">Scope</div>
                  <ReadOnlyEditor
                    className="ProjectDetailScopeBody"
                    content={projectDetails.quote.description || ''}
                  />
                </div>
                {showRevisionsRequested && (
                  <div className="ProjectDetailScope">
                    <div className="ProjectDetailScopeTitle">
                      Requested Changes
                    </div>
                    <ReadOnlyEditor
                      className="ProjectDetailScopeBody"
                      content={projectDetails.quote.revisionsRequested || ''}
                    />
                  </div>
                )}
                {!!projectDetails.quote &&
                  !!inBuyingMode &&
                  !!(withClientFee || fakeWithClientFee) && (
                    <div className="ProjectDetailClientFee">
                      <div className="ProjectDetailClientFeeRow">
                        <div className="ProjectDetailClientFeeRowTitle">
                          Project Cost
                        </div>
                        <div className="ProjectDetailClientFeeRowValue">
                          $
                          {formatNumberWithCommas(
                            centsDollarsRounded(projectDetails.quote.cents),
                          )}
                        </div>
                      </div>
                      {!!(withClientFee || fakeWithClientFee) && (
                        <div className="ProjectDetailClientFeeRow">
                          <div className="ProjectDetailClientFeeRowTitle">
                            Storetasker Fee (
                            {amountClientFee(
                              withClientFee || fakeWithClientFee,
                            )}
                            %)
                          </div>
                          <div className="ProjectDetailClientFeeRowValue">
                            $
                            {formatNumberWithCommas(
                              centsDollarsRounded(
                                projectDetails.quote.cents *
                                  (clientFeePercent || fakeClientFeePercent),
                              ),
                            )}
                          </div>
                        </div>
                      )}
                      {!!fakeWithClientFee && (
                        <div className="ProjectDetailClientFeeRow ProjectDetailClientFeeRowFake">
                          <div className="ProjectDetailClientFeeRowTitle">
                            Storetasker Pro Discount
                          </div>
                          <div className="ProjectDetailClientFeeRowValue">
                            -$
                            {formatNumberWithCommas(
                              centsDollarsRounded(
                                projectDetails.quote.cents *
                                  fakeClientFeePercent,
                              ),
                            )}
                          </div>
                        </div>
                      )}
                      <div className="ProjectDetailClientFeeRow ProjectDetailClientFeeRowTotal">
                        <div className="ProjectDetailClientFeeRowTitle">
                          Total
                        </div>
                        <div className="ProjectDetailClientFeeRowValue">
                          $
                          {formatNumberWithCommas(
                            centsDollarsRounded(
                              projectDetails.quote.cents *
                                (1 + clientFeePercent),
                            ),
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                {!currModal &&
                  !!projectDetails.quote &&
                  (projectDetails.quote.status === QuoteStatus.Created ||
                    projectDetails.quote.status === QuoteStatus.Updated) && (
                    <div className="ProjectDetailFooter">
                      {withClientFee && inBuyingMode && !human.proStart && (
                        <div className="ProjectDetailFooterUpsellPro">
                          <div className="ProjectDetailFooterUpsellProText">
                            <span>
                              Save $
                              {formatNumberWithCommas(
                                centsDollarsRounded(
                                  projectDetails.quote.cents *
                                    (clientFeePercent || fakeClientFeePercent),
                                ),
                              )}
                            </span>{' '}
                            with Storetasker Pro
                          </div>
                          <div
                            className="ProjectDetailFooterUpsellProBtn"
                            onClick={showUpgradeModal}
                          >
                            upgrade
                          </div>
                        </div>
                      )}
                      <div className="ProjectDetailFooterText">
                        {quoteAcceptInfoStr}
                      </div>
                      <div
                        className={
                          'ProjectDetailFooterAction ' +
                          (actionLoading
                            ? ' ProjectDetailFooterActionLoading '
                            : '')
                        }
                        onClick={() => tryAcceptQuote()}
                      >
                        {projectDetails.quote.paymentType === 'BILL'
                          ? billPayStr
                          : 'Accept Quote'}
                      </div>
                    </div>
                  )}
                {!currModal &&
                  !!projectDetails.quote &&
                  projectDetails.quote.status === QuoteStatus.Completed && (
                    <div className="ProjectDetailFooter">
                      <div className="ProjectDetailFooterText">
                        You have <span>{autoApproveStr}</span> left to approve
                        or request changes.
                      </div>
                      <div
                        className={
                          'ProjectDetailFooterAction ProjectDetailFooterActionPrimary ' +
                          (actionLoading
                            ? ' ProjectDetailFooterActionLoading '
                            : '')
                        }
                        onClick={doApprove}
                      >
                        Approve Project
                      </div>
                      <div
                        className="ProjectDetailFooterAction ProjectDetailFooterActionOther"
                        onClick={() => tryRequestChanges()}
                      >
                        Request Changes
                      </div>
                    </div>
                  )}
                {!!projectDetails.latestAcceptCents &&
                  !!projectDetails.quote &&
                  !!brandForProject &&
                  !brandForProject.invoicingEnabled &&
                  projectDetails.quote.paymentType === 'PROJECT' &&
                  projectDetails.quote.status === QuoteStatus.Canceled && (
                    <div className="ProjectDetailFooter">
                      <div className="ProjectDetailFooterWarning">
                        This project was canceled after you had already accepted
                        the quote, so our Support Team will get in contact to
                        refund your payment.
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
          {!!projectDetails && cleanModal === 'pay' && (
            <div className="ThreadDetailMainFocusModal" onClick={stopPay}>
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                  <div className="ThreadDetailMainFocusModalContainerTitle">
                    {projectPayTitle}
                  </div>
                  <div className="ThreadDetailMainFocusModalContainerSubtitle ThreadDetailMainFocusModalContainerSubtitleLowMargin">
                    {applyDiscountCode(isProjectDiscountCode, withDiscountCode)}{' '}
                    {quoteAcceptInfoStr}
                  </div>
                  {!withClientFee ? (
                    <div className="ProjectDetailFeatures">
                      {projectDetails.quote.paymentType === 'PROJECT' && (
                        <div className="ProjectDetailFeature">
                          <div className="ProjectDetailFeatureTitle">
                            Timeline
                          </div>
                          <div className="ProjectDetailFeatureValue">
                            {timelineStr}
                          </div>
                        </div>
                      )}
                      <div
                        className={
                          'ProjectDetailFeature ' +
                          (withDiscountCode
                            ? ' ProjectDetailFeatureDiscounted '
                            : '')
                        }
                      >
                        <div className="ProjectDetailFeatureTitle">Cost</div>
                        <div className="ProjectDetailFeatureValue">
                          $
                          {formatNumberWithCommas(
                            centsDollarsRounded(projectDetails.quote.cents),
                          )}
                          {!!priceDiffRaw && (
                            <span
                              className={
                                priceDiffRaw > 0
                                  ? 'ProjectDetailPriceRefund'
                                  : 'ProjectDetailPriceExtra'
                              }
                            >
                              ${priceDiffStr}
                            </span>
                          )}
                        </div>
                      </div>
                      {!!withDiscountCode && (
                        <div className="ProjectDetailFeature ProjectDetailFeatureWithDiscount">
                          <div className="ProjectDetailFeatureTitle">
                            With Discount
                          </div>
                          <div className="ProjectDetailFeatureValue">
                            $
                            {formatNumberWithCommas(
                              centsDollarsRounded(
                                projectDetails.quote.cents *
                                  (1 - discountPercent),
                              ),
                            )}
                            {!!priceDiffRaw && (
                              <span
                                className={
                                  priceDiffRaw > 0
                                    ? 'ProjectDetailPriceRefund'
                                    : 'ProjectDetailPriceExtra'
                                }
                              >
                                ${priceDiffStrDiscount}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="ProjectDetailClientFee ProjectDetailClientFeeModal">
                      <div className="ProjectDetailClientFeeRow">
                        <div className="ProjectDetailClientFeeRowTitle">
                          Project Cost
                        </div>
                        <div className="ProjectDetailClientFeeRowValue">
                          $
                          {formatNumberWithCommas(
                            centsDollarsRounded(projectDetails.quote.cents),
                          )}
                        </div>
                      </div>
                      <div className="ProjectDetailClientFeeRow">
                        <div className="ProjectDetailClientFeeRowTitle">
                          Storetasker Fee ({amountClientFee(withClientFee)}%)
                        </div>
                        <div className="ProjectDetailClientFeeRowValue">
                          $
                          {formatNumberWithCommas(
                            centsDollarsRounded(
                              projectDetails.quote.cents * clientFeePercent,
                            ),
                          )}
                        </div>
                      </div>
                      <div className="ProjectDetailClientFeeRow ProjectDetailClientFeeRowTotal">
                        <div className="ProjectDetailClientFeeRowTitle">
                          Total
                        </div>
                        <div className="ProjectDetailClientFeeRowValue">
                          $
                          {formatNumberWithCommas(
                            centsDollarsRounded(
                              projectDetails.quote.cents *
                                (1 + clientFeePercent),
                            ),
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {((projectDetails.quote.paymentType !== 'PROJECT' &&
                    projectDetails.quote.paymentType !== 'BILL') ||
                    (priceDiffRaw <= 0 &&
                      !samePriceAsAccepted &&
                      (!brandForProject ||
                        !brandForProject.invoicingEnabled))) && (
                    <div className="ProjectDetailPayment">
                      {useExistingCard ? (
                        <div className="ProjectDetailPaymentStep">
                          <div className="ProjectDetailPaymentStepTitle">
                            Payment Method
                          </div>
                          <div className="ProjectDetailPaymentMethod">
                            <div className="ProjectDetailPaymentMethodDetail">
                              {human.cardBrand} - {human.cardLast4}
                            </div>
                            <div
                              className="ProjectDetailPaymentMethodSwitch"
                              onClick={() => setSwitchPaymentMethod(true)}
                            >
                              change
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Fragment>
                          <div className="ProjectDetailPaymentStep">
                            <div className="ProjectDetailPaymentStepTitle">
                              Pay By Card
                            </div>
                            <div className="ProjectDetailPaymentCard">
                              <CardElement
                                options={{
                                  style: {
                                    base: {
                                      fontFamily:
                                        'Roboto, Open Sans, Segoe UI, sans-serif',
                                      fontSize: '16px',
                                    },
                                  },
                                }}
                                onChange={handleStripeElementChange}
                              />
                            </div>
                            {!!stripeElementError && (
                              <div className="ProjectDetailPaymentCardError">
                                {stripeElementError}
                              </div>
                            )}
                          </div>
                          {(projectDetails.quote.paymentType === 'PROJECT' ||
                            projectDetails.quote.paymentType === 'BILL') && (
                            <div className="ProjectDetailPaymentStep">
                              <div className="ProjectDetailPaymentStepTitle">
                                Or Pay Another Way
                              </div>
                              <div className="ProjectDetailPaymentOthers">
                                <div className="ProjectDetailPaymentOther">
                                  <PayPalButton
                                    createOrder={onPaypalCreateOrder}
                                    onApprove={onPaypalApproveOrder}
                                    catchError={onPaypalError}
                                    onError={onPaypalError}
                                    options={{
                                      clientId: PAYPAL_CLIENT_ID,
                                      disableFunding: 'card,credit',
                                    }}
                                    shippingPreference="NO_SHIPPING"
                                    style={{
                                      tagline: false,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </Fragment>
                      )}
                    </div>
                  )}
                  <div className="BasicFormFooter BasicFormFooterMarginTop">
                    <div
                      className={
                        'BasicFormAction ' +
                        (!payIsReadyForSubmit
                          ? ' BasicFormActionInvalid '
                          : '') +
                        (actionLoading ? ' BasicFormActionLoading ' : '')
                      }
                      onClick={tryPaymentAccept}
                    >
                      {projectDetails.quote.paymentType === 'BILL'
                        ? billNowStr
                        : 'Accept Quote'}
                    </div>
                  </div>
                </div>
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={stopPay}
                >
                  cancel
                </div>
              </div>
            </div>
          )}
          {!!projectDetails && cleanModal === 'invoice' && (
            <div className="ThreadDetailMainFocusModal">
              <div
                className="ThreadDetailMainFocusModalCover"
                onClick={stopInvoice}
              />
              <div className="ThreadDetailMainFocusModalContainer">
                <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                  <div className="ThreadDetailMainFocusModalContainerTitle">
                    Invoice details
                  </div>
                  <div className="ThreadDetailMainFocusModalContainerSubtitle">
                    Fill out your invoice details and then download a copy.
                  </div>
                  <div className="BasicForm">
                    <div className="BasicFormField BasicFormFieldSplit">
                      <div className="BasicFormFieldTitle">
                        Business Legal Name
                      </div>
                      <input
                        className="BasicFormFieldInput"
                        type="text"
                        autoComplete="new-off"
                        placeholder="Business Legal Name"
                        value={invoiceName}
                        onChange={(e) => setInvoiceName(e.currentTarget.value)}
                      />
                    </div>
                    <div className="BasicFormField BasicFormFieldSplit">
                      <div className="BasicFormFieldTitle">VAT ID</div>
                      <input
                        className="BasicFormFieldInput"
                        type="text"
                        autoComplete="new-off"
                        placeholder="if applicable"
                        value={invoiceVat}
                        onChange={(e) => setInvoiceVat(e.currentTarget.value)}
                      />
                    </div>
                    <div className="BasicFormField BasicFormFieldMidSplit">
                      <div className="BasicFormFieldTitle">Address Line 1</div>
                      <input
                        className="BasicFormFieldInput"
                        type="text"
                        autoComplete="new-off"
                        placeholder="Address Line 1"
                        value={invoiceAddressOne}
                        onChange={(e) =>
                          setInvoiceAddressOne(e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="BasicFormField BasicFormFieldMidSplit">
                      <div className="BasicFormFieldTitle">Address Line 2</div>
                      <input
                        className="BasicFormFieldInput"
                        type="text"
                        autoComplete="new-off"
                        placeholder="Address Line 2"
                        value={invoiceAddressTwo}
                        onChange={(e) =>
                          setInvoiceAddressTwo(e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="BasicFormFooter">
                      {anyEditsToInvoiceSettings || actionLoading ? (
                        <div
                          className={
                            'BasicFormAction ' +
                            (actionLoading ? ' BasicFormActionLoading ' : '')
                          }
                          onClick={trySaveInvoice}
                        >
                          Save Details
                        </div>
                      ) : (
                        <a
                          className="BasicFormAction BasicFormActionDownload"
                          href={
                            envUtils.pickRestApi() +
                            '/project/invoice/' +
                            projectId
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </a>
                      )}
                      <div
                        className="BasicFormActionInstead"
                        onClick={stopInvoice}
                      >
                        never mind
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={stopInvoice}
                >
                  never mind
                </div>
              </div>
            </div>
          )}
          {!!projectDetails && cleanModal === 'revisions' && (
            <div className="ThreadDetailMainFocusModal" onClick={stopRevisions}>
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                  <div className="ThreadDetailMainFocusModalContainerTitle">
                    Request Changes
                  </div>
                  <div className="ThreadDetailMainFocusModalContainerSubtitle">
                    We&apos;ll notify your expert immediately and let you know
                    once they&apos;ve made these changes.
                  </div>
                  <div className="BasicForm">
                    <div className="ThreadDetailMainFocusModalChoicesSection">
                      <div className="ThreadDetailMainFocusModalChoicesTitle">
                        What specific changes need to be made?
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSubTitle">
                        A bullet point list or linking to a Google Doc can be
                        super helpful here. Thank you!
                      </div>
                      <div className="ThreadDetailMainFocusModalSummarizeInput">
                        <div className="ThreadDetailMainFocusModalSummarizeRich">
                          <RichTextEditor
                            initContent={''}
                            placeholder="Please share as much detail as you can."
                            onUpdated={setRevisionsContent}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="BasicFormFooter">
                      <div
                        className={
                          'BasicFormAction ' +
                          (actionLoading ? ' BasicFormActionLoading ' : '')
                        }
                        onClick={doRequestChanges}
                      >
                        Request Changes
                      </div>
                      <div
                        className="BasicFormActionInstead"
                        onClick={stopRevisions}
                      >
                        never mind
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={stopRevisions}
                >
                  never mind
                </div>
              </div>
            </div>
          )}
          {!!projectDetails && cleanModal === 'rating' && (
            <div className="ThreadDetailMainFocusModal" onClick={stopRating}>
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                  <div className="ThreadDetailMainFocusModalContainerTitle">
                    Project Feedback
                  </div>
                  <div className="ThreadDetailMainFocusModalContainerFeedbackTitle">
                    <ExpertUserBubble
                      expertId={projectDetails.expert.id}
                      expertDetails={projectDetails.expert}
                      className="ThreadDetailMainFocusModalContainerFeedbackTitlePic"
                      primary
                    />
                    <div className="ThreadDetailMainFocusModalContainerFeedbackTitleText">
                      It was a pleasure working with you
                      {human.firstName ? ', ' + human.firstName : ''}!
                    </div>
                  </div>
                  <div className="BasicForm">
                    <div className="ThreadDetailMainFocusModalChoicesSection">
                      <div className="ThreadDetailMainFocusModalChoicesTitle">
                        How&apos;d this project go with{' '}
                        {projectDetails.expert.firstName}?
                      </div>
                      <div className="ProjectDetailFeedback">
                        <div
                          className="ProjectDetailFeedbackStars"
                          onMouseLeave={() => setRatingStarsHover(0)}
                        >
                          {[1, 2, 3, 4, 5].map((starValue) => (
                            <div
                              key={starValue}
                              className={
                                'ProjectDetailFeedbackStar ' +
                                ((ratingStarsHover || ratingStars) >= starValue
                                  ? ' ProjectDetailFeedbackStarSelected '
                                  : '')
                              }
                              onClick={() => setRatingStars(starValue)}
                              onMouseEnter={() =>
                                setRatingStarsHover(starValue)
                              }
                            />
                          ))}
                          <div className="ProjectDetailFeedbackStarValue">
                            {starValueToStr(ratingStarsHover || ratingStars)}
                          </div>
                        </div>
                      </div>
                      <div className="ThreadDetailMainFocusModalSummarizeInput">
                        <TextareaAutosize
                          type="text"
                          placeholder="Thanks again for using Storetasker! We really appreciate your feedback."
                          spellCheck="true"
                          className="ThreadDetailMainFocusModalSummarizeTextarea"
                          value={ratingText}
                          onChange={(e) => {
                            setRatingText(e.currentTarget.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="BasicFormFooter">
                      <div
                        className={
                          'BasicFormAction ' +
                          (actionLoading ? ' BasicFormActionLoading ' : '') +
                          (!ratingIsReadyForSubmit
                            ? ' BasicFormActionInvalid '
                            : '')
                        }
                        onClick={doGiveFeedback}
                      >
                        Share Feedback
                      </div>
                      <div
                        className="BasicFormActionInstead"
                        onClick={stopRating}
                      >
                        skip
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={stopRating}
                >
                  skip
                </div>
              </div>
            </div>
          )}
          {!!projectDetails &&
            !!projectDetails.expert &&
            cleanModal === 'post-accept' && (
              <div
                className="ThreadDetailMainFocusModal"
                onClick={stopPostAccept}
              >
                <div
                  className="ThreadDetailMainFocusModalContainer"
                  onClick={blockClickPropagation}
                >
                  <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                    <div className="RequestDetailWhatNext RequestDetailWhatNextExtraBottom">
                      <div className="RequestDetailWhatNextTitle">
                        What comes next
                      </div>
                      <div className="RequestDetailWhatNextBox">
                        <div className="RequestDetailWhatNextBoxItem">
                          1. {projectDetails.expert.firstName || 'Your expert'}{' '}
                          will be working on your project
                        </div>
                        <div className="RequestDetailWhatNextBoxItem">
                          2. Collaborate over email, text, zoom, or slack
                        </div>
                        <div className="RequestDetailWhatNextBoxItem">
                          3. When finished, you&apos;ll review and approve their
                          work
                        </div>
                      </div>
                    </div>
                    <div className="RequestDetailWhatNextTitle RequestDetailWhatNextTitleExtraBottom">
                      Do you want to hire another e-commerce expert?
                    </div>
                    <div className="HumanExpertsNewRequestLinks">
                      <div className="HumanExpertsNewRequestLinkList">
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            Full-stack Shopify developers
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            Headless Developers
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            Custom Shopify App Developers
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=designer">
                            UI/UX Designers
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=marketer">
                            Klaviyo Email Experts
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            Analytics &amp; Marketing Tag Experts
                          </Link>
                        </div>
                      </div>
                      <div className="HumanExpertsNewRequestLinkList HumanExpertsNewRequestLinkListSecond">
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            SEO Experts
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=marketer">
                            CRO Experts
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=marketer">
                            Amazon
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=marketer">
                            Facebook Ads
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            Site Speed
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="RequestDetailWhatNextBtns">
                      <Link
                        to="/new?another=true"
                        className="RequestDetailWhatNextBtn RequestDetailWhatNextBtnBold"
                      >
                        Hire another expert
                      </Link>
                      <div
                        onClick={stopPostAccept}
                        className="RequestDetailWhatNextBtn"
                      >
                        Not right now
                      </div>
                    </div>
                  </div>
                  <div
                    className="ThreadDetailMainFocusModalClose"
                    onClick={stopPostAccept}
                  >
                    close
                  </div>
                </div>
              </div>
            )}
          {!!projectDetails &&
            !!projectDetails.expert &&
            cleanModal === 'post-approve' && (
              <div
                className="ThreadDetailMainFocusModal"
                onClick={stopPostApprove}
              >
                <div
                  className="ThreadDetailMainFocusModalContainer"
                  onClick={blockClickPropagation}
                >
                  <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                    <div className="RequestDetailWhatNext RequestDetailWhatNextSection">
                      <div className="RequestDetailWhatNextTitle RequestDetailWhatNextTitleBigBottom">
                        Work with{' '}
                        {projectDetails.expert.firstName || 'your expert'}{' '}
                        again?
                      </div>
                      <div>
                        <Link
                          to={'/new?expert=' + projectDetails.expert.id}
                          className="RequestDetailWhatNextBtn RequestDetailWhatNextBtnBold"
                        >
                          Start another project
                        </Link>
                      </div>
                    </div>
                    <div className="RequestDetailWhatNextTitle RequestDetailWhatNextTitleExtraBottom">
                      Or hire another e-commerce expert?
                    </div>
                    <div className="HumanExpertsNewRequestLinks">
                      <div className="HumanExpertsNewRequestLinkList">
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            Full-stack Shopify developers
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            Headless Developers
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            Custom Shopify App Developers
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=designer">
                            UI/UX Designers
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=marketer">
                            Klaviyo Email Experts
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            Analytics &amp; Marketing Tag Experts
                          </Link>
                        </div>
                      </div>
                      <div className="HumanExpertsNewRequestLinkList HumanExpertsNewRequestLinkListSecond">
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            SEO Experts
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=marketer">
                            CRO Experts
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=marketer">
                            Amazon
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=marketer">
                            Facebook Ads
                          </Link>
                        </div>
                        <div className="HumanExpertsNewRequestLinksItem">
                          <Link to="/new?another=true&skill=developer">
                            Site Speed
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="RequestDetailWhatNextBtns">
                      <Link
                        to="/new?another=true"
                        className="RequestDetailWhatNextBtn RequestDetailWhatNextBtnBold"
                      >
                        Hire another expert
                      </Link>
                      <div
                        onClick={stopPostApprove}
                        className="RequestDetailWhatNextBtn"
                      >
                        Not right now
                      </div>
                    </div>
                  </div>
                  <div
                    className="ThreadDetailMainFocusModalClose"
                    onClick={stopPostApprove}
                  >
                    close
                  </div>
                </div>
              </div>
            )}
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div
          className={
            'ThreadDetailSidebar ' +
            (!secondaryPanels.length ? ' ThreadDetailSidebarSolo ' : '')
          }
        >
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            {!!projectDetails && (
              <div className="ThreadDetailSidebarBodyPanels">
                {primaryPanel === 'Details' && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                    {!!projectDetails.expert && (
                      <div className="ThreadDetailSidebarHumanSection">
                        <div className="ThreadDetailSidebarHumanSectionTitle">
                          From{' '}
                          {projectDetails.expert.firstName || 'your expert'}
                        </div>
                        {!!projectDetails.expert.profileBio && (
                          <div className="ThreadDetailSidebarHumanSectionSubTitle">
                            {projectDetails.expert.profileBio.slice(0, 140) +
                              (projectDetails.expert.profileBio.length > 140
                                ? '...'
                                : '')}
                          </div>
                        )}
                        <div className="ProjectDetailExpertWrap">
                          <Link
                            className="ProjectDetailExpert"
                            to={'/experts/' + projectDetails.expert.id}
                          >
                            <ExpertUserBubble
                              expertId={projectDetails.expert.id}
                              expertDetails={projectDetails.expert}
                              className="ProjectDetailExpertPic"
                              primary
                            />
                            <div className="ProjectDetailExpertName">
                              <div className="ProjectDetailExpertNameTag">
                                Expert
                              </div>
                              <div className="ProjectDetailExpertNameText">
                                {(projectDetails.expert.firstName || '') +
                                  ' ' +
                                  (projectDetails.expert.lastName || '')}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                    {!!brandForProject &&
                      !brandForProject.invoicingEnabled &&
                      !!projectDetails.quote &&
                      projectDetails.quote.status !== QuoteStatus.Canceled &&
                      (projectDetails.quote.paymentType === 'PROJECT' ||
                        projectDetails.quote.paymentType === 'BILL') && (
                        <div className="ThreadDetailSidebarHumanSection">
                          <div className="ThreadDetailSidebarHumanSectionTitle">
                            Download invoice
                          </div>
                          <div className="ThreadDetailSidebarHumanSectionSubTitle">
                            Feel free to download an invoice for your records.
                          </div>
                          <div
                            className="ThreadDetailSidebarHumanSectionBtn"
                            onClick={startInvoice}
                          >
                            download invoice
                          </div>
                        </div>
                      )}
                    {requestForProject ? (
                      <div className="ThreadDetailSidebarHumanSection">
                        <div className="ThreadDetailSidebarHumanSectionTitle">
                          Other options
                        </div>
                        <div className="ThreadDetailSidebarHumanSectionSubTitle">
                          Let us know if we can answer questions or help in any
                          way.
                        </div>
                        <Link
                          className="ThreadDetailSidebarHumanSectionBtn"
                          to={'/requests/' + requestForProject.id}
                        >
                          view active request
                        </Link>
                        {!requestForProject.isMultipleMatch && (
                          <Link
                            className="ThreadDetailSidebarHumanSectionBtn"
                            to={
                              '/requests/' +
                              requestForProject.id +
                              '?change=true'
                            }
                          >
                            change expert
                          </Link>
                        )}
                        {!requestForProject.isMultipleMatch && (
                          <Link
                            className="ThreadDetailSidebarHumanSectionBtn"
                            to={
                              '/requests/' +
                              requestForProject.id +
                              '?cancel=true'
                            }
                          >
                            cancel project
                          </Link>
                        )}
                        <Link
                          className="ThreadDetailSidebarHumanSectionBtn"
                          to="/support"
                        >
                          contact support
                        </Link>
                      </div>
                    ) : (
                      <div className="ThreadDetailSidebarHumanSection">
                        <div className="ThreadDetailSidebarHumanSectionTitle">
                          Need help?
                        </div>
                        <div className="ThreadDetailSidebarHumanSectionSubTitle">
                          Our Support Team is also here to help anytime.
                        </div>
                        <Link
                          className="ThreadDetailSidebarHumanSectionBtn"
                          to="/support"
                        >
                          contact support
                        </Link>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!errorProject && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <Link to="/projects" className="DashboardErrorCoverNav">
              back
            </Link>
            <div className="DashboardErrorCoverContent">
              An error occured while trying to load this project. Please try
              again.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HumanProjectDetail;
