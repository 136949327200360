import { useEffect, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  useMutation,
  useQuery,
  ApolloError,
  ServerError,
  gql,
} from '@apollo/client';
import { ClientWithOnReconnected } from './utils/apollo';
import { GlobalNotificationContext } from './components/context/GlobalNotification';
import ExpertLogin from './components/page/ExpertLogin';
import ExpertDashboard from './components/page/ExpertDashboard';
import oauthUtils from './utils/oauth';
import errorUtils from './utils/error';
import logError from './utils/airbrake';
import {
  AuthExpertQuery,
  HeartbeatUserMutation,
  HeartbeatUserMutationVariables,
} from './gql/graphql';

const authExpertQuery = gql`
  query AuthExpert {
    auth {
      id
      token
      user {
        ... on Expert {
          id
          isSuperAdmin
          claimsLeft
          firstName
          isNoKeyAccounts
          lastName
          nylasAccessToken
          nylasCalendarId
          scheduleTimeEnabled
          ski
          status
          tagSkills
          tagTools
        }
      }
    }
  }
`;

const heartbeatUserMutation = gql`
  mutation HeartbeatUser($isActive: Boolean!) {
    userHeartbeat(isActive: $isActive)
  }
`;

interface AppExpertProps {
  socketClient: ClientWithOnReconnected;
}

let oneHeartbeatAtATime = false;

const AppExpert = ({ socketClient }: AppExpertProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [refetched, setRefetched] = useState(false);
  const skipAuthCheck = !oauthUtils.getToken('expert');
  const {
    loading: loadingExpert,
    error: errorExpert,
    data: dataExpert,
    refetch: refetchExpert,
  } = useQuery<AuthExpertQuery>(authExpertQuery, {
    onError: (err) => {
      console.log(err, 'onError');
      // onError 401/403 erase token
      if (err.networkError && err.networkError.name === 'ServerError') {
        const networkError = err.networkError as ServerError;
        if (
          networkError.statusCode === 401 ||
          networkError.statusCode === 403
        ) {
          oauthUtils.logout('expert');
          return;
        }
      }
      if (err.networkError) {
        addNotification(
          'A network error occured, please refresh and try again: ' +
            (errorUtils.getErrorMessage(err) || 'Loading Error'),
          undefined,
          300000,
        );
      } else {
        addNotification(errorUtils.getErrorMessage(err) || 'Loading Error');
      }
    },
    skip: skipAuthCheck,
  });
  const refetchTop = useCallback(() => {
    setRefetched(false);
    refetchExpert()
      .catch(() => {})
      .finally(() => {
        setRefetched(true);
      });
  }, [refetchExpert]);
  const [sendHeartbeat] = useMutation<
    HeartbeatUserMutation,
    HeartbeatUserMutationVariables
  >(heartbeatUserMutation);
  useEffect(() => {
    function onKeepAlive() {
      if (oauthUtils.getCachedUserId()) {
        if (!oneHeartbeatAtATime) {
          oneHeartbeatAtATime = true;
          sendHeartbeat({
            variables: {
              isActive: true,
            },
          })
            .catch((error: ApolloError) => {
              logError(error, {
                component: 'AppExpert',
                func: 'heartbeatMutation',
              });
            })
            .finally(() => {
              oneHeartbeatAtATime = false;
            });
        }
      }
    }
    const keepAliveTimer = setInterval(onKeepAlive, 50000);
    return () => {
      clearInterval(keepAliveTimer);
    };
  }, [sendHeartbeat]);
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AppExpert socketClient onReconnected');
      refetchTop();
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchTop]);
  if (refetched || errorExpert) {
    // ignore these
  }
  if (loadingExpert) {
    return <div className="AppLoadingFull">[storetasker]</div>;
  }
  if (
    !skipAuthCheck &&
    dataExpert &&
    dataExpert.auth &&
    dataExpert.auth.token &&
    dataExpert.auth.user &&
    dataExpert.auth.user.__typename === 'Expert' &&
    dataExpert.auth.user.id &&
    !dataExpert.auth.user.isSuperAdmin &&
    dataExpert.auth.user.status &&
    ['inactive', 'pending'].indexOf(dataExpert.auth.user.status) === -1
  ) {
    oauthUtils.writeToken(
      dataExpert.auth.token,
      'expert',
      dataExpert.auth.user.id,
    );
    return (
      <ExpertDashboard
        expertId={dataExpert.auth.user.id}
        socketClient={socketClient}
      />
    );
  }
  return <ExpertLogin refetchTopQuery={refetchTop} />;
};

AppExpert.propTypes = {
  socketClient: PropTypes.object.isRequired,
};

export default AppExpert;
