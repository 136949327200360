import React, { useState, useRef, useContext } from 'react';
// import _ from 'lodash';
import { gql, useMutation, ApolloError } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { convertToRaw, ContentState } from 'draft-js';
import TextareaAutosize from 'react-autosize-textarea';
import RichTextEditor from '../feature/RichTextEditor';
import {
  PrefabCreateByAdminMutation,
  PrefabCreateByAdminMutationVariables,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { ProjectPrefabDetailHq } from '../../utils/gql';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import { SKILL_OPTIONS, TOOL_OPTIONS } from '../../utils/constants';
import MultiSelect from '../feature/MultiSelect';

const prefabCreateByAdminMutation = gql`
  mutation PrefabCreateByAdmin(
    $prefabType: String!
    $title: String!
    $description: String!
    $descriptionPlainText: String!
    $internalNote: String
    $priceType: String!
    $cents: Int
    $estimatedCompletion: Int
    $tagSkills: [String!]!
    $tagTools: [String!]!
  ) {
    prefabCreateByAdmin(
      prefabType: $prefabType
      title: $title
      description: $description
      descriptionPlainText: $descriptionPlainText
      internalNote: $internalNote
      priceType: $priceType
      cents: $cents
      estimatedCompletion: $estimatedCompletion
      tagSkills: $tagSkills
      tagTools: $tagTools
    ) {
      ...ProjectPrefabDetailHq
    }
  }
  ${ProjectPrefabDetailHq}
`;

const AdminPrefabs = () => {
  const history = useHistory();
  const searchRef = useRef<HTMLInputElement>(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [newPrefabType, setNewPrefabType] = useState('GENERAL');
  const [newPrefabTitle, setNewPrefabTitle] = useState('');
  const [newPrefabInternal, setNewPrefabInternal] =
    useState<ContentState | null>(null);
  const [newPrefabDescription, setNewPrefabDescription] =
    useState<ContentState | null>(null);
  const [newPrefabPriceType, setNewPrefabPriceType] = useState('NONE');
  const [newPrefabCents, setNewPrefabCents] = useState('250');
  const [newPrefabEstimatedCompletion, setNewPrefabEstimatedCompletion] =
    useState('3');
  const [newPrefabTagSkills, setNewPrefabTagSkills] = useState([] as string[]);
  const [newPrefabTagTools, setNewPrefabTagTools] = useState([] as string[]);
  const priceVal =
    Math.round(parseFloat((newPrefabCents || '').replace(/\$/gi, '')) || 0) *
    100;
  const timelineVal = Math.round(
    parseFloat(newPrefabEstimatedCompletion || '') || 0,
  );
  const { addNotification } = useContext(GlobalNotificationContext);
  const [tryPrefabCreate] = useMutation<
    PrefabCreateByAdminMutation,
    PrefabCreateByAdminMutationVariables
  >(prefabCreateByAdminMutation);
  function saveNewPrefab() {
    if (actionLoading) return;
    let draftContent = '';
    let plainTextContent = '';
    let draftContentInternal = '';
    if (newPrefabDescription && newPrefabDescription.hasText()) {
      plainTextContent = newPrefabDescription.getPlainText().trim();
      if (plainTextContent.replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(newPrefabDescription));
        } catch (rawError) {
          console.log('prefabDescription convertToRaw error', rawError);
        }
      }
    }
    if (
      newPrefabInternal &&
      newPrefabInternal.hasText() &&
      newPrefabInternal.getPlainText().trim().replace(/\s/gi, '')
    ) {
      try {
        draftContentInternal = JSON.stringify(convertToRaw(newPrefabInternal));
      } catch (rawError) {
        console.log('prefabInternal convertToRaw error', rawError);
      }
    }
    if (
      !newPrefabType ||
      !newPrefabTitle ||
      !draftContent ||
      !newPrefabPriceType ||
      (priceVal && priceVal < 0) ||
      (timelineVal && timelineVal < 0)
    ) {
      addNotification('Please submit all the fields!', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryPrefabCreate({
      variables: {
        cents: priceVal,
        description: draftContent,
        descriptionPlainText: plainTextContent,
        estimatedCompletion: timelineVal,
        internalNote: draftContentInternal,
        prefabType: newPrefabType,
        priceType: newPrefabPriceType,
        tagSkills: newPrefabTagSkills,
        tagTools: newPrefabTagTools,
        title: newPrefabTitle,
      },
    })
      .then(({ data: mutationData }) => {
        setActionLoading(false);
        setNewPrefabType('GENERAL');
        setNewPrefabTitle('');
        setNewPrefabInternal(null);
        setNewPrefabDescription(null);
        setNewPrefabPriceType('NONE');
        setNewPrefabCents('250');
        setNewPrefabTagSkills([]);
        setNewPrefabTagTools([]);
        setNewPrefabEstimatedCompletion('3');
        if (mutationData && mutationData.prefabCreateByAdmin) {
          history.push(
            '/extras/prefabs/' + mutationData.prefabCreateByAdmin.id,
          );
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Prefab Create Error',
        );
        logError(err, {
          component: 'AdminPrefabs',
          func: 'tryPrefabCreate',
        });
      });
  }
  function onSearchStart() {
    if (!searchInput) {
      toggleSearch();
      return;
    }
    history.push('/extras/prefabs/' + searchInput);
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      toggleSearch();
    }
  }
  function toggleSearch() {
    const nextShowSearch = !showSearch;
    setSearchInput('');
    setShowSearch(nextShowSearch);
    if (nextShowSearch) {
      setTimeout(() => {
        if (searchRef && searchRef.current) {
          searchRef.current.focus();
        }
      });
    }
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div
        className={
          'ClientsScreenSearch ClientsScreenSearch' +
          (showSearch ? 'Active ' : '')
        }
      >
        <div className="ClientsScreenSearchBar">
          <input
            type="text"
            ref={searchRef}
            className="ClientsScreenSearchBarInput"
            autoComplete="new-off"
            spellCheck="false"
            placeholder="Edit Prefab Id"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleSearchInputKeyDown}
          />
          <div className="ClientsScreenSearchToggle" onClick={toggleSearch}>
            Edit Prefab Id
          </div>
        </div>
      </div>
      <div className="DashboardScreenContent ClientsScreenContent">
        <div className="AdminDashboardForm">
          <div className="AdminDashboardFormTitle">Create a new prefab?</div>
          <div className="AdminDashboardFormField AdminDashboardFormFieldFull">
            <div className="AdminDashboardFormFieldTitle">Prefab Title</div>
            <TextareaAutosize
              type="text"
              placeholder="Prefab Title"
              spellCheck="true"
              className="AdminDashboardFormFieldInput"
              value={newPrefabTitle}
              onChange={(e) => {
                setNewPrefabTitle(e.currentTarget.value);
              }}
            />
          </div>
          <div className="AdminDashboardFormField AdminDashboardFormFieldFull">
            <div className="AdminDashboardFormFieldTitle">
              Prefab Description
            </div>
            <div className="AdminDashboardFormFieldRich">
              <RichTextEditor
                initContent={''}
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                onUpdated={setNewPrefabDescription}
              />
            </div>
          </div>
          <div className="AdminDashboardFormField">
            <div className="AdminDashboardFormFieldTitle">Prefab Type</div>
            <select
              className="AdminDashboardFormFieldInput"
              required
              value={newPrefabType}
              onChange={(e) => setNewPrefabType(e.currentTarget.value)}
            >
              <option value="REWRITE">Custom re-write</option>
              <option value="GENERAL">General for all</option>
            </select>
          </div>
          <div className="AdminDashboardFormField">
            <div className="AdminDashboardFormFieldTitle">
              Estimated Deadline (Days)
            </div>
            <input
              className="AdminDashboardFormFieldInput"
              type="text"
              autoComplete="new-off"
              spellCheck="false"
              placeholder="Estimated Deadline (Days)"
              value={newPrefabEstimatedCompletion}
              onChange={(e) =>
                setNewPrefabEstimatedCompletion(e.currentTarget.value)
              }
            />
          </div>
          <div className="AdminDashboardFormField">
            <div className="AdminDashboardFormFieldTitle">Price Type</div>
            <select
              className="AdminDashboardFormFieldInput"
              required
              value={newPrefabPriceType}
              onChange={(e) => setNewPrefabPriceType(e.currentTarget.value)}
            >
              <option value="NONE">No Price</option>
              <option value="STARTING_AT">Starting At</option>
            </select>
          </div>
          {newPrefabPriceType !== 'NONE' && (
            <div className="AdminDashboardFormField">
              <div className="AdminDashboardFormFieldTitle">Price $</div>
              <input
                className="AdminDashboardFormFieldInput"
                type="text"
                autoComplete="new-off"
                spellCheck="false"
                placeholder="Company Name"
                value={newPrefabCents}
                onChange={(e) => setNewPrefabCents(e.currentTarget.value)}
              />
            </div>
          )}
          <div className="AdminDashboardFormField AdminDashboardFormFieldFull">
            <div className="AdminDashboardFormFieldTitle">
              Internal Note? (only shown to experts)
            </div>
            <div className="AdminDashboardFormFieldRich">
              <RichTextEditor
                initContent={''}
                placeholder="Internal Note (optional)"
                onUpdated={setNewPrefabInternal}
              />
            </div>
          </div>
          <div className="AdminDashboardFormField AdminDashboardFormFieldFull">
            <div className="AdminDashboardFormFieldTitle">
              Skills ({newPrefabTagSkills.length} / 5)
            </div>
            <MultiSelect
              options={SKILL_OPTIONS}
              currValues={newPrefabTagSkills}
              onChange={setNewPrefabTagSkills}
              withTags
              max={5}
            />
          </div>
          <div className="AdminDashboardFormField AdminDashboardFormFieldFull">
            <div className="AdminDashboardFormFieldTitle">
              Tools ({newPrefabTagTools.length} / 8)
            </div>
            <MultiSelect
              options={TOOL_OPTIONS}
              currValues={newPrefabTagTools}
              onChange={setNewPrefabTagTools}
              withTags
              max={8}
            />
          </div>
          <div
            className={
              'AdminDashboardFormAction ' +
              (actionLoading ? ' AdminDashboardFormActionLoading ' : '')
            }
            onClick={saveNewPrefab}
          >
            Create
          </div>
        </div>
      </div>
    </div>
  );
};

AdminPrefabs.propTypes = {};

export default AdminPrefabs;
