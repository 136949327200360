import React, { useState, useContext, useRef } from 'react';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import { MatchDetail, BrandDetail } from '../../utils/gql';
import {
  OrphanDetailsQuery,
  BrandForHumansAdminOrphanQuery,
  BrandForHumansAdminOrphanQueryVariables,
  MatchSearchForExpertOrphanAdminMutationVariables,
  MatchSearchForExpertOrphanAdminMutation,
} from '../../gql/graphql';

const brandForHumansAdminOrphanQuery = gql`
  query BrandForHumansAdminOrphan($humanIds: [ID!]!) {
    brandForHumans(humanIds: $humanIds) {
      ...BrandDetail
    }
  }
  ${BrandDetail}
`;

const matchSearchForExpertOrphanAdminMutation = gql`
  mutation MatchSearchForExpertOrphanAdmin($searchQuery: String!) {
    matchSearchForExpert(searchQuery: $searchQuery) {
      id
      brandNameMatch
      humanNameMatch {
        id
        firstName
        lastName
      }
      match {
        ...MatchDetail
      }
      shopifyAdminMatch
      urlMatch
    }
  }
  ${MatchDetail}
`;

interface AdminOrphanClientEditorProps {
  humans: OrphanDetailsQuery['orphanDetails']['humans'];
  isActionLoading: boolean;
  onCancel: () => void;
  onSelectBrand: (brandId: string) => void;
}

const AdminOrphanClientEditor = ({
  humans,
  isActionLoading,
  onCancel,
  onSelectBrand,
}: AdminOrphanClientEditorProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchInput, setSearchInput] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    MatchSearchForExpertOrphanAdminMutation['matchSearchForExpert'] | null
  >(null);
  const {
    data: dataHumanBrands,
    error: errorHumanBrands,
    loading: loadingHumanBrands,
  } = useQuery<
    BrandForHumansAdminOrphanQuery,
    BrandForHumansAdminOrphanQueryVariables
  >(brandForHumansAdminOrphanQuery, {
    returnPartialData: true,
    variables: {
      humanIds: humans.map((h) => h.id),
    },
  });
  const [tryMatchSearch] = useMutation<
    MatchSearchForExpertOrphanAdminMutation,
    MatchSearchForExpertOrphanAdminMutationVariables
  >(matchSearchForExpertOrphanAdminMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput) {
      setSearchResults(null);
      return;
    }
    if (!searchInput || searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchResults([]);
    tryMatchSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        setSearchLoading(false);
        if (dataMatchSearch && dataMatchSearch.matchSearchForExpert) {
          console.log('tryMatchSearch', dataMatchSearch.matchSearchForExpert);
          setSearchResults(
            dataMatchSearch.matchSearchForExpert
              .slice()
              .sort((a, b) => b.match.lastUpdated - a.match.lastUpdated),
          );
        }
      },
      variables: {
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Client Search Error');
      logError(err, {
        component: 'ExpertActiveClients',
        func: 'tryMatchSearch',
      });
      setSearchLoading(false);
      setSearchResults(null);
    });
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
  }
  const humanBrands = (dataHumanBrands && dataHumanBrands.brandForHumans) || [];
  if (errorHumanBrands || loadingHumanBrands) {
    // ignore these
  }
  return (
    <div className="ThreadEditor OrphanClientEditor AdminOrphanClientEditor">
      <div className="ThreadEditorTop">
        <div className="ThreadEditorTopTitle">Associate brand</div>
        {!isActionLoading && (
          <div className="ThreadEditorTopAction" onClick={onCancel}>
            Cancel
          </div>
        )}
      </div>
      <div className="ThreadEditorCardWrapper">
        <div className="ThreadEditorCard">
          <div className="ThreadEditorOrphan">
            {isActionLoading ? (
              <div className="ThreadEditorOrphanLoading" />
            ) : (
              <div className="ThreadEditorOrphanSection">
                <div className="ThreadEditorOrphanSectionTitle">
                  Merge thread into another brand?
                </div>
                <div className="ThreadEditorOrphanSectionSearch">
                  <input
                    type="text"
                    ref={searchRef}
                    className="ThreadEditorOrphanSectionSearchInput"
                    placeholder="Search Clients"
                    autoComplete="new-off"
                    spellCheck="false"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    onKeyDown={handleSearchInputKeyDown}
                  />
                </div>
                <div className="ThreadEditorOrphanSectionResults">
                  {!searchLoading &&
                    searchResults === null &&
                    humanBrands.map((b) => {
                      const teamMember =
                        (b.team || []).find((t) =>
                          humans.find((h) => t.id === h.id),
                        ) || b.teamLeader;
                      const subtitle = teamMember
                        ? (
                            (teamMember.firstName || '').trim() +
                            ' ' +
                            (teamMember.lastName || '').trim()
                          ).trim()
                        : '';
                      return (
                        <div
                          key={b.id}
                          className="ThreadEditorOrphanSectionResultsItem"
                          onClick={() => onSelectBrand(b.id)}
                        >
                          <div className="ThreadEditorOrphanSectionResultsItemTitle">
                            {b.name || ''}
                          </div>
                          <div className="ThreadEditorOrphanSectionResultsItemSub">
                            {subtitle}
                          </div>
                        </div>
                      );
                    })}
                  {!searchLoading &&
                    searchResults &&
                    !!searchResults.length &&
                    searchResults.map((result) => {
                      let subtitle = '';
                      if (result.humanNameMatch) {
                        subtitle =
                          (
                            (result.humanNameMatch.firstName || '').trim() +
                            ' ' +
                            (result.humanNameMatch.lastName || '').trim()
                          ).trim() || '';
                      } else if (result.urlMatch) {
                        subtitle =
                          (result.match.brand && result.match.brand.url) || '';
                      } else if (result.shopifyAdminMatch) {
                        subtitle =
                          (result.match.brand &&
                            result.match.brand.shopifyAdminURL) ||
                          '';
                      }
                      if (
                        !subtitle &&
                        result.match.brand &&
                        result.match.brand.teamLeader
                      ) {
                        subtitle =
                          (
                            (
                              result.match.brand.teamLeader.firstName || ''
                            ).trim() +
                            ' ' +
                            (
                              result.match.brand.teamLeader.lastName || ''
                            ).trim()
                          ).trim() || '';
                      }
                      return (
                        <div
                          key={result.match.id}
                          className="ThreadEditorOrphanSectionResultsItem"
                          onClick={() =>
                            onSelectBrand(
                              (result.match.brand && result.match.brand.id) ||
                                '',
                            )
                          }
                        >
                          <div className="ThreadEditorOrphanSectionResultsItemTitle">
                            {result.match.brand && result.match.brand.name
                              ? result.match.brand.name
                              : ''}
                          </div>
                          <div className="ThreadEditorOrphanSectionResultsItemSub">
                            {subtitle}
                          </div>
                        </div>
                      );
                    })}
                  {!searchLoading && searchResults && !searchResults.length && (
                    <div className="ThreadEditorOrphanSectionResultsEmpty">
                      No search results found...
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AdminOrphanClientEditor.propTypes = {
  humans: PropTypes.array.isRequired,
  isActionLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelectBrand: PropTypes.func.isRequired,
};

export default AdminOrphanClientEditor;
