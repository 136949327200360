import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import HumanUserBubble from '../feature/HumanUserBubble';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import {
  prefabPriceStr,
  tagLabelBudget,
  tagLabelFocus,
  tagLabelSkill,
  tagLabelTimeline,
  tagLabelTool,
} from '../../utils/format';
import { MatchThreadPaginatedQuery } from '../../gql/graphql';

interface RequestThreadEventProps {
  className?: string;
  onReplyClick?: () => void;
  requestEvent: Extract<
    MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
    { __typename?: 'RequestEvent' | undefined }
  >;
  showProTip?: boolean;
  threadEventId: string;
  threadEventTimestamp: number;
}
const initTime = new Date().getTime();

const RequestThreadEvent = ({
  className,
  showProTip,
  requestEvent,
  threadEventTimestamp,
}: RequestThreadEventProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const nowMoment = moment.tz(initTime, moment.tz.guess());
  const eventMoment = moment.tz(threadEventTimestamp, moment.tz.guess());
  // January 4th, 2020 at 5:37pm PST
  const eventDateFull =
    eventMoment.format('MMMM Do, YYYY') +
    ' at ' +
    eventMoment.format('h:mma z');
  let eventDateSummary = eventMoment.format('M/D/YY');
  if (nowMoment.isSame(eventMoment, 'day')) {
    eventDateSummary = eventMoment.format('h:mma');
  } else if (nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')) {
    eventDateSummary = 'Yesterday';
  } else if (nowMoment.isSame(eventMoment, 'week')) {
    eventDateSummary = eventMoment.format('dddd');
  } else if (nowMoment.isSame(eventMoment, 'year')) {
    eventDateSummary = eventMoment.format('MMM Do');
  }
  const tagLabels = (requestEvent.tagFocus || [])
    .map(tagLabelFocus)
    .concat(requestEvent.tagLocations || [])
    .concat((requestEvent.tagSkills || []).map(tagLabelSkill))
    .concat((requestEvent.tagTools || []).map(tagLabelTool))
    .concat(
      requestEvent.tagTimeline
        ? [tagLabelTimeline(requestEvent.tagTimeline)]
        : [],
    )
    .concat(
      requestEvent.tagBudgetMin || requestEvent.tagBudgetMax
        ? [tagLabelBudget(requestEvent.tagBudgetMin, requestEvent.tagBudgetMax)]
        : [],
    );
  return (
    <div
      className={
        'ThreadEvent RequestThreadEvent ThreadEventOwnerHuman ThreadEventSolo ' +
        (className || '')
      }
    >
      <div className="ThreadEventWrapper">
        <div className="ThreadEventTop">
          <div className="ThreadEventTopBubbles">
            <HumanUserBubble
              firstName={
                requestEvent.ownerHuman && requestEvent.ownerHuman.firstName
              }
              lastName={
                requestEvent.ownerHuman && requestEvent.ownerHuman.lastName
              }
              primary
            />
          </div>
          <div className="ThreadEventTopTitle">
            {requestEvent.projectPrefab && requestEvent.projectPrefab.title
              ? 'New Project Request'
              : ''}
          </div>
          <div className="ThreadEventTopDate" data-tip={eventDateFull}>
            {eventDateSummary}
          </div>
        </div>
        <div className="ThreadEventCardWrapper">
          <div className="ThreadEventCardLeft">
            <div className="ThreadEventCardLeftUser">
              <HumanUserBubble
                firstName={
                  requestEvent.ownerHuman && requestEvent.ownerHuman.firstName
                }
                lastName={
                  requestEvent.ownerHuman && requestEvent.ownerHuman.lastName
                }
                primary
              />
            </div>
          </div>
          <div className="ThreadEventCard">
            <div className="ThreadEventCardBody">
              {requestEvent.projectPrefab &&
              requestEvent.projectPrefab.title ? (
                <div className="ThreadEventCardBodyTitle">
                  {requestEvent.projectPrefab.title}{' '}
                  {requestEvent.projectPrefab.priceType &&
                    requestEvent.projectPrefab.priceType !== 'NONE' &&
                    requestEvent.projectPrefab.cents && (
                      <span>
                        (
                        {prefabPriceStr(
                          requestEvent.projectPrefab.priceType,
                          requestEvent.projectPrefab.cents,
                        )}
                        )
                      </span>
                    )}
                </div>
              ) : (
                <div className="ThreadEventCardBodyTitle">
                  New Project Request
                </div>
              )}
              {requestEvent.projectPrefab &&
                requestEvent.projectPrefab.estimatedCompletion && (
                  <div className="ThreadEventCardBodySubtitle">
                    Estimated completion:{' '}
                    {requestEvent.projectPrefab.estimatedCompletion} day
                    {requestEvent.projectPrefab.estimatedCompletion > 1
                      ? 's'
                      : ''}
                  </div>
                )}
              <ReadOnlyEditor
                className="ThreadEventCardBodyContent ThreadEventCardBodyContentExtra"
                content={
                  requestEvent.projectPrefab
                    ? requestEvent.projectPrefab.description
                    : requestEvent.content || ''
                }
              />
              {requestEvent.projectPrefab && requestEvent.content && (
                <ReadOnlyEditor
                  className="ThreadEventCardBodyContent ThreadEventCardBodyContentExtra"
                  content={requestEvent.content || ''}
                />
              )}
              {!!requestEvent.projectPrefab &&
                !!requestEvent.projectPrefab.internalNote && (
                  <div className="RequestDetailViewCardBodyInternal">
                    <div className="RequestDetailViewCardBodySubtitle">
                      Note from Storetasker HQ:
                    </div>
                    <ReadOnlyEditor
                      className="RequestDetailViewCardBodyContent"
                      content={requestEvent.projectPrefab.internalNote || ''}
                    />
                  </div>
                )}
            </div>
            {!!tagLabels.length && (
              <div className="ThreadEventCardTags">
                {tagLabels.map((f) => (
                  <div key={f} className="ThreadEventCardTag">
                    {f}
                  </div>
                ))}
              </div>
            )}
            {!!(requestEvent.files && requestEvent.files.length) && (
              <div className="ThreadEventCardFiles">
                {requestEvent.files.map((f) => (
                  <a
                    key={f.id}
                    className="ThreadEventCardFile"
                    href={f.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {f.filename}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="ThreadEventBottom"></div>
        <div className="ThreadEventRightSide"></div>
      </div>
      {!!showProTip && (
        <div className="ThreadEventWrapper">
          <div className="ThreadEventCardWrapper">
            <div className="ThreadEventCard ThreadEventCardTip">
              <div className="ThreadEventCardBody ">
                <div className="ThreadActionQuoteTipTitle">Pro Tip!</div>
                <div className="ThreadActionQuoteTipSection">
                  <div className="ThreadActionQuoteTipSectionHeader">
                    From Project To Client
                  </div>

                  <div className="ThreadActionQuoteTipBody">
                    Every brand has a roadmap with lots of problems to solve.
                    Learning more about what’s next, building trust, and suggest
                    solutions can turn a $1k client into a $100k client.
                  </div>
                </div>
                <div className="ThreadActionQuoteTipSection">
                  <div className="ThreadActionQuoteTipSectionHeader">
                    A Call Is Worth 10 Emails
                  </div>

                  <div className="ThreadActionQuoteTipBody">
                    It may be a no-brainer, but jumping on a call builds trust,
                    let’s you quickly understand the scope, and significantly
                    increases the chances of a sale.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

RequestThreadEvent.propTypes = {
  className: PropTypes.string,
  onReplyClick: PropTypes.func,
  requestEvent: PropTypes.object.isRequired,
  showProTip: PropTypes.bool,
  threadEventId: PropTypes.string.isRequired,
  threadEventTimestamp: PropTypes.number.isRequired,
};

RequestThreadEvent.defaultProps = {
  className: '',
  onReplyClick: null,
  showProTip: false,
};

export default RequestThreadEvent;
