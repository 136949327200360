import { useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ClientWithOnReconnected } from '../../utils/apollo';
import {
  AuthHumanQuery,
  BrandForHumansQuery,
  RequestActiveForBrandsHomeQuery,
  RequestActiveForBrandsHomeQueryVariables,
  ProjectActiveForBrandsHomeQuery,
  ProjectActiveForBrandsHomeQueryVariables,
} from '../../gql/graphql';
import { ProjectDetail, RequestSummaryHuman } from '../../utils/gql';
import {
  centsDollarsRounded,
  notificationDiscountCode,
  formatNumberWithCommas,
  quoteStatusFormattedHuman,
} from '../../utils/format';
import logoWhite from '../../images/storetasker-logo-white.png';

const requestActiveForBrandsHomeQuery = gql`
  query RequestActiveForBrandsHome($brandIds: [ID!]!) {
    requestActiveForBrands(brandIds: $brandIds) {
      ...RequestSummaryHuman
    }
  }
  ${RequestSummaryHuman}
`;

const projectActiveForBrandsHomeQuery = gql`
  query ProjectActiveForBrandsHome($brandIds: [ID!]!) {
    projectActiveForBrands(brandIds: $brandIds) {
      ...ProjectDetail
    }
  }
  ${ProjectDetail}
`;

interface HumanHomescreenProps {
  brands: BrandForHumansQuery['brandForHumans'];
  hasDiscountCode: string;
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
  showUpgradeModal: () => void;
  socketClient: ClientWithOnReconnected;
}

const HumanHomescreen = ({
  brands,
  hasDiscountCode,
  human,
  showUpgradeModal,
  socketClient,
}: HumanHomescreenProps) => {
  const {
    loading: loadingRequestsActive,
    error: errorRequestsActive,
    data: dataRequestsActive,
    refetch: refetchRequestsActive,
  } = useQuery<
    RequestActiveForBrandsHomeQuery,
    RequestActiveForBrandsHomeQueryVariables
  >(requestActiveForBrandsHomeQuery, {
    returnPartialData: true,
    skip: !brands.length,
    variables: {
      brandIds: brands.map((b) => b.id),
    },
  });
  const activeRequestsForBrands = (
    brands.length
      ? _.uniqBy(
          (
            (dataRequestsActive && dataRequestsActive.requestActiveForBrands) ||
            []
          ).filter(
            (r) =>
              r.isActive &&
              r.brandStr &&
              brands.find((b) => b.id === r.brandStr),
          ),
          'id',
        )
      : []
  ).sort((a, b) => b.createdAt - a.createdAt);
  const {
    loading: loadingProjectsActive,
    error: errorProjectsActive,
    data: dataProjectsActive,
    refetch: refetchProjectsActive,
  } = useQuery<
    ProjectActiveForBrandsHomeQuery,
    ProjectActiveForBrandsHomeQueryVariables
  >(projectActiveForBrandsHomeQuery, {
    returnPartialData: true,
    skip: !brands.length,
    variables: {
      brandIds: brands.map((b) => b.id),
    },
  });
  const activeProjectsForBrands = (
    brands.length
      ? _.uniqBy(
          (
            (dataProjectsActive && dataProjectsActive.projectActiveForBrands) ||
            []
          ).filter(
            (p) =>
              p.isActive &&
              p.quote &&
              p.quote.createdAt &&
              p.brandStr &&
              brands.find((b) => b.id === p.brandStr),
          ),
          'id',
        )
      : []
  ).sort((a, b) => b.quote.createdAt - a.quote.createdAt);
  const activeRequestWithoutProjects = activeRequestsForBrands.filter(
    (r) =>
      !r.currentMatchStr ||
      !activeProjectsForBrands.find((p) => p.matchStr === r.currentMatchStr),
  );
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('HumanHomescreen socketClient onReconnected');
      refetchRequestsActive().catch(() => {});
      refetchProjectsActive().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchRequestsActive, refetchProjectsActive]);
  const announcementItems = [
    ...(!human || !human.proStart
      ? [
          {
            footer: 'Storetasker Pro',
            href: 'https://www.storetasker.com/experts/brielle-wilson',
            id: 'st-pro',
            size: 'Wide',
            title: 'Want multiple intros, premium filters, no fees?',
          },
        ]
      : []),
    {
      footer: 'Meet The Experts',
      href: 'https://www.storetasker.com/experts/brielle-wilson',
      id: 'brielle-spotlight',
      size: 'Medium',
      title: 'Designer Spotlight: Brielle Wilson',
    },
    {
      footer: 'Customer Story',
      href: 'https://resources.storetasker.com/blog/customer-story-meng-from-native',
      id: 'native-story',
      size: 'Wide',
      title: 'Customer Story: Meng from Native',
    },
    {
      footer: 'Storetasker Friends',
      href: 'https://resources.storetasker.com/blog/sarah-ryan-hecht',
      id: 'sarah-friend',
      size: 'Medium',
      title: 'Friends of Storetasker: Sarah Ryan Hecht',
    },
    {
      footer: 'Referral Program',
      href: 'https://support.storetasker.com/Refer-your-friends-help-us-grow-abbc815dab954634aac32f5741fa5791',
      id: 'refer-friend',
      size: 'Medium',
      title: 'Refer your friends - help us grow!',
    },
    {
      footer: 'Meet The Experts',
      href: 'https://www.storetasker.com/experts/connor-rolain',
      id: 'connor-spotlight',
      size: 'Medium',
      title: 'Marketer Spotlight: Connor Rolain',
    },
    {
      footer: 'Knowledge Base',
      href: 'https://support.storetasker.com/Upgrade-to-a-retainer-ff973b5ff8a648009a9c856775b5e37d',
      id: 'retainer-upgrade',
      size: 'Medium',
      title: 'Upgrade to a retainer',
    },
    {
      footer: 'Customer Story',
      href: 'https://resources.storetasker.com/blog/customer-story-stephanie-from-lagatta',
      id: 'lagatta-story',
      size: 'Wide',
      title: 'Customer Story: Stephanie from Lagatta',
    },
    {
      footer: 'Storetasker Friends',
      href: 'https://resources.storetasker.com/blog/how-i-became-a-designer-caley-from-wildes-district',
      id: 'caley-friend',
      size: 'Medium',
      title: 'Friends of Storetasker: Caley Adams',
    },
  ];
  if (errorRequestsActive || errorProjectsActive) {
    console.error(errorRequestsActive || errorProjectsActive);
  }
  if (loadingRequestsActive || loadingProjectsActive) {
    // ignore these
  }
  return (
    <div className="DashboardScreen HomeScreen">
      <Link className="DashboardScreenLogoLink" to="/home">
        <img
          className="DashboardScreenLogo DashboardScreenLogoWhite"
          src={logoWhite}
          alt="Storetasker Logo"
        />
      </Link>
      <div className="DashboardScreenContent HomeScreenContent">
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Up Next</div>
          <div className="DashboardSectionScrollerSwitch">
            {activeRequestWithoutProjects.map((r) => {
              const brandFound = brands.find((b) => b.id === r.brandStr);
              let hasCurrentMatchOrMultiple3 = false;
              if (r) {
                if (r.isMultipleMatch && r.matchLog) {
                  hasCurrentMatchOrMultiple3 = r.matchLog.length >= 3;
                } else if (r.currentMatchStr) {
                  hasCurrentMatchOrMultiple3 = true;
                }
              }
              const requestStatus = hasCurrentMatchOrMultiple3
                ? 'MATCHED'
                : 'FINDING EXPERT' + (r.isMultipleMatch ? 'S' : '');
              return (
                <div
                  key={r.id}
                  className="HomeScreenUpNext HomeScreenUpNextLead"
                >
                  <Link
                    to={'/requests/' + r.id}
                    className="HomeScreenUpNextBody"
                  >
                    {hasCurrentMatchOrMultiple3 ? (
                      <div className="HomeScreenUpNextTitle">
                        You&apos;ve been matched!
                      </div>
                    ) : (
                      <div className="HomeScreenUpNextTitle">
                        We&apos;re looking for your expert
                        {r.isMultipleMatch ? 's' : ''}
                      </div>
                    )}
                    <div className="HomeScreenUpNextSubTitle">
                      {brandFound && brandFound.name ? brandFound.name : ''}
                    </div>
                    <div className="HomeScreenUpNextRightCorner">
                      {requestStatus}
                    </div>
                  </Link>
                  <div className="HomeScreenUpNextFooter">
                    <div className="HomeScreenUpNextFooterText">
                      {hasCurrentMatchOrMultiple3 ? 'Matched' : 'Request'}
                    </div>
                  </div>
                </div>
              );
            })}
            {activeProjectsForBrands.map((p) => (
              <div
                key={p.id}
                className="HomeScreenUpNext HomeScreenUpNextProject"
              >
                <Link to={'/projects/' + p.id} className="HomeScreenUpNextBody">
                  <div className="HomeScreenUpNextTitle">
                    {p.quote.title || 'Project'}
                  </div>
                  <div className="HomeScreenUpNextSubTitle">
                    {p.expert && p.expert.firstName ? p.expert.firstName : ''}
                  </div>
                  {p.quote.cents && (
                    <div className="HomeScreenUpNextLeftCorner">
                      $
                      {formatNumberWithCommas(
                        centsDollarsRounded(p.quote.cents),
                      )}
                    </div>
                  )}
                  <div
                    className={
                      'HomeScreenUpNextStatusLine CardStatusLine' +
                      p.quote.status
                    }
                  />
                  <div className="HomeScreenUpNextRightCorner">
                    {quoteStatusFormattedHuman(
                      p.quote.status,
                      p.quote.paymentType,
                    )}
                  </div>
                </Link>
                <div className="HomeScreenUpNextFooter">
                  <div className="HomeScreenUpNextFooterText">Project</div>
                </div>
              </div>
            ))}
            {activeRequestsForBrands.length < 3 && (
              <div className="HomeScreenUpNext HomeScreenUpNextQueue">
                <Link to="/new" className="HomeScreenUpNextBody">
                  <div className="HomeScreenUpNextTitle">
                    Start a new project
                  </div>
                  <div className="HomeScreenUpNextSubTitle HomeScreenUpNextSubTitleNoEllipsis">
                    {hasDiscountCode
                      ? notificationDiscountCode(true, hasDiscountCode)
                      : 'Work with the same expert or connect with someone new'}
                  </div>
                  <div className="HomeScreenUpNextRightCorner">Projects</div>
                </Link>
                <div className="HomeScreenUpNextFooter">
                  <div className="HomeScreenUpNextFooterText">Today</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Community</div>
          <div className="DashboardSectionScroller">
            {announcementItems.map((announcementItem) => (
              <div
                key={announcementItem.href}
                className={
                  'HomeScreenAnnouncement HomeScreenAnnouncement' +
                  announcementItem.size +
                  ' HomeScreenAnnouncementItem-' +
                  announcementItem.id
                }
              >
                {announcementItem.id === 'st-pro' ? (
                  <div
                    className="HomeScreenAnnouncementBody"
                    onClick={showUpgradeModal}
                  >
                    <div className="HomeScreenAnnouncementTitle">
                      {announcementItem.title}
                    </div>
                  </div>
                ) : (
                  <a
                    className="HomeScreenAnnouncementBody"
                    href={announcementItem.href}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="HomeScreenAnnouncementTitle">
                      {announcementItem.title}
                    </div>
                  </a>
                )}
                <div className="HomeScreenAnnouncementFooter">
                  <div className="HomeScreenAnnouncementFooterText">
                    {announcementItem.footer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

HumanHomescreen.propTypes = {
  brands: PropTypes.array.isRequired,
  hasDiscountCode: PropTypes.string.isRequired,
  human: PropTypes.object.isRequired,
  showUpgradeModal: PropTypes.func.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default HumanHomescreen;
