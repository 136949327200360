import { useEffect, useContext, useState, useCallback, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useQuery, useLazyQuery, gql, ApolloError } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ClientWithOnReconnected } from '../../utils/apollo';
import {
  AuthHumanQuery,
  BrandForHumansQuery,
  RequestActiveForBrandsProjectsQuery,
  RequestActiveForBrandsProjectsQueryVariables,
  ProjectActiveForBrandsProjectsQuery,
  ProjectActiveForBrandsProjectsQueryVariables,
  ProjectPastPaginateBrandsQuery,
  ProjectPastPaginateBrandsQueryVariables,
  RequestFailedPaginateBrandsQuery,
  RequestFailedPaginateBrandsQueryVariables,
} from '../../gql/graphql';
import { ProjectDetail, RequestSummaryHq } from '../../utils/gql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import {
  centsDollarsRounded,
  notificationDiscountCode,
  formatNumberWithCommas,
  expertFocusPosition,
  quoteStatusFormattedHuman,
  paymentTypeFormatted,
} from '../../utils/format';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import logo from '../../images/storetasker-logo-black.png';
import '../../styles/page/HumanProjects.scss';

const requestActiveForBrandsProjectsQuery = gql`
  query RequestActiveForBrandsProjects($brandIds: [ID!]!) {
    requestActiveForBrands(brandIds: $brandIds) {
      ...RequestSummaryHq
    }
  }
  ${RequestSummaryHq}
`;

const projectActiveForBrandsProjectsQuery = gql`
  query ProjectActiveForBrandsProjects($brandIds: [ID!]!) {
    projectActiveForBrands(brandIds: $brandIds) {
      ...ProjectDetail
    }
  }
  ${ProjectDetail}
`;
const projectPastPaginateBrandsQuery = gql`
  query ProjectPastPaginateBrands(
    $brandIds: [ID!]!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    projectPastPaginateBrands(
      brandIds: $brandIds
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...ProjectDetail
    }
  }
  ${ProjectDetail}
`;

const requestFailedPaginateBrandsQuery = gql`
  query RequestFailedPaginateBrands(
    $brandIds: [ID!]!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    requestFailedPaginateBrands(
      brandIds: $brandIds
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...RequestSummaryHq
    }
  }
  ${RequestSummaryHq}
`;

interface HumanProjectsProps {
  brands: BrandForHumansQuery['brandForHumans'];
  hasDiscountCode: string;
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
  socketClient: ClientWithOnReconnected;
}

const PAGE_LIMIT = 4;

const HumanProjects = ({
  brands,
  hasDiscountCode,
  socketClient,
}: HumanProjectsProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [pastLoaded, setPastLoaded] = useState(false);
  const [showLoadMoreProjects, setShowLoadMoreProjects] = useState(true);
  const [showLoadMoreRequests, setShowLoadMoreRequests] = useState(true);
  const {
    loading: loadingRequestsActive,
    error: errorRequestsActive,
    data: dataRequestsActive,
    refetch: refetchRequestsActive,
  } = useQuery<
    RequestActiveForBrandsProjectsQuery,
    RequestActiveForBrandsProjectsQueryVariables
  >(requestActiveForBrandsProjectsQuery, {
    returnPartialData: true,
    skip: !brands.length,
    variables: {
      brandIds: brands.map((b) => b.id),
    },
  });
  const [
    getFailedRequests,
    {
      called: calledRequestFailed,
      data: dataRequestFailed,
      error: errorRequestFailed,
      loading: loadingRequestFailed,
      fetchMore: fetchMoreRequestFailed,
    },
  ] = useLazyQuery<
    RequestFailedPaginateBrandsQuery,
    RequestFailedPaginateBrandsQueryVariables
  >(requestFailedPaginateBrandsQuery, {
    returnPartialData: true,
    variables: {
      brandIds: brands.map((b) => b.id),
      direction: 'BACKWARD',
      limit: PAGE_LIMIT,
    },
  });
  const {
    loading: loadingProjectsActive,
    error: errorProjectsActive,
    data: dataProjectsActive,
    refetch: refetchProjectsActive,
  } = useQuery<
    ProjectActiveForBrandsProjectsQuery,
    ProjectActiveForBrandsProjectsQueryVariables
  >(projectActiveForBrandsProjectsQuery, {
    returnPartialData: true,
    skip: !brands.length,
    variables: {
      brandIds: brands.map((b) => b.id),
    },
  });
  const [
    getPastProjects,
    {
      called: calledProjectPast,
      data: dataProjectPast,
      error: errorProjectPast,
      loading: loadingProjectPast,
      fetchMore: fetchMoreProjectPast,
    },
  ] = useLazyQuery<
    ProjectPastPaginateBrandsQuery,
    ProjectPastPaginateBrandsQueryVariables
  >(projectPastPaginateBrandsQuery, {
    returnPartialData: true,
    variables: {
      brandIds: brands.map((b) => b.id),
      direction: 'BACKWARD',
      limit: PAGE_LIMIT,
    },
  });
  const allProjectsForBrands = _.uniqBy(
    [
      ...((dataProjectsActive && dataProjectsActive.projectActiveForBrands) ||
        []),
      ...((calledProjectPast &&
        dataProjectPast &&
        dataProjectPast.projectPastPaginateBrands) ||
        []),
    ].filter(
      (p) => p.quote && p.brandStr && brands.find((b) => b.id === p.brandStr),
    ),
    'id',
  );
  const activeProjectsForBrands = allProjectsForBrands
    .filter((p) => p.isActive)
    .sort(
      (a, b) =>
        (b.quote.createdAt || b.createdAt || 0) -
        (a.quote.createdAt || a.createdAt || 0),
    );
  const pastProjectsForBrands = allProjectsForBrands
    .filter((p) => !p.isActive)
    .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));

  const allRequestsForBrands = _.uniqBy(
    [
      ...((dataRequestsActive && dataRequestsActive.requestActiveForBrands) ||
        []),
      ...((calledRequestFailed &&
        dataRequestFailed &&
        dataRequestFailed.requestFailedPaginateBrands) ||
        []),
    ].filter((r) => r.brandStr && brands.find((b) => b.id === r.brandStr)),
    'id',
  ).sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));
  const activeRequestsForBrands = allRequestsForBrands.filter(
    (r) => r.isActive,
  );
  const activeRequestWithoutProjects = activeRequestsForBrands.filter(
    (r) =>
      !r.currentMatchStr ||
      !activeProjectsForBrands.find((p) => p.matchStr === r.currentMatchStr),
  );
  const pastRequestsForBrands = allRequestsForBrands.filter((r) => !r.isActive);
  const loadThePastInit = useCallback(() => {
    getPastProjects().catch((err: ApolloError) => {
      logError(err, {
        component: 'HumanProjects',
        func: 'getPastProjects',
      });
    });
    getFailedRequests().catch((err: ApolloError) => {
      logError(err, {
        component: 'HumanProjects',
        func: 'getFailedRequests',
      });
    });
    setPastLoaded(true);
  }, [getPastProjects, getFailedRequests]);
  function loadMoreProjects() {
    if (fetchMoreProjectPast) {
      fetchMoreProjectPast({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || !fetchMoreResult.projectPastPaginateBrands)
            return prev;
          if (fetchMoreResult.projectPastPaginateBrands.length < PAGE_LIMIT) {
            setShowLoadMoreProjects(false);
          }
          return {
            projectPastPaginateBrands: (
              prev.projectPastPaginateBrands || []
            ).concat(fetchMoreResult.projectPastPaginateBrands),
          };
        },
        variables: {
          direction: 'BACKWARD',
          fromDate:
            pastProjectsForBrands[pastProjectsForBrands.length - 1].createdAt,
          limit: PAGE_LIMIT,
        },
      }).catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Past Projects Error',
        );
        logError(err, {
          component: 'HumanProjects',
          func: 'loadMoreProjects',
        });
      });
    }
  }
  function loadMoreRequests() {
    if (fetchMoreRequestFailed) {
      fetchMoreRequestFailed({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || !fetchMoreResult.requestFailedPaginateBrands)
            return prev;
          if (fetchMoreResult.requestFailedPaginateBrands.length < PAGE_LIMIT) {
            setShowLoadMoreRequests(false);
          }
          return {
            requestFailedPaginateBrands: (
              prev.requestFailedPaginateBrands || []
            ).concat(fetchMoreResult.requestFailedPaginateBrands),
          };
        },
        variables: {
          direction: 'BACKWARD',
          fromDate:
            pastRequestsForBrands[pastRequestsForBrands.length - 1].createdAt,
          limit: PAGE_LIMIT,
        },
      }).catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Past Requests Error',
        );
        logError(err, {
          component: 'HumanProjects',
          func: 'loadMoreRequests',
        });
      });
    }
  }
  function loadMoreAll() {
    if (showLoadMoreProjects && pastProjectsForBrands.length) {
      loadMoreProjects();
    }
    if (showLoadMoreRequests && pastRequestsForBrands.length) {
      loadMoreRequests();
    }
  }
  function renderProjectCards(
    projects: ProjectActiveForBrandsProjectsQuery['projectActiveForBrands'],
  ) {
    return projects.map((p) => {
      const brandFound = brands.find((b) => b.id === p.brandStr);
      return (
        <Link
          className="ProjectsScreenProject"
          key={p.id}
          to={'/projects/' + p.id}
        >
          <div className="ProjectsScreenProjectTitle">
            {p.quote.title || 'Project'}
          </div>
          <div className="ProjectsScreenProjectBrand">
            {p.quote.paymentType === 'PROJECT' || p.quote.paymentType === 'BILL'
              ? (brandFound && brandFound.name) || 'Request'
              : paymentTypeFormatted(p.quote.paymentType)}
          </div>
          {p.expert && (
            <div className="ProjectsScreenProjectMatch">
              <ExpertUserBubble
                expertId={p.expert.id}
                expertDetails={p.expert}
                className="ProjectsScreenProjectMatchBubble"
                primary
              />
              <div className="ProjectsScreenProjectMatchName">
                {(p.expert.firstName || '') + ' ' + (p.expert.lastName || '')}
              </div>
            </div>
          )}
          <div className="ProjectsScreenProjectPrice">
            ${formatNumberWithCommas(centsDollarsRounded(p.quote.cents))}
          </div>
          <div className="ProjectsScreenProjectStatus">
            {quoteStatusFormattedHuman(p.quote.status)}
          </div>
        </Link>
      );
    });
  }
  function renderRequestCards(
    requests: RequestActiveForBrandsProjectsQuery['requestActiveForBrands'],
  ) {
    return requests.map((r) => {
      let hasCurrentMatchOrMultiple3 = false;
      if (r) {
        if (r.isMultipleMatch && r.matchLog) {
          hasCurrentMatchOrMultiple3 = r.matchLog.length >= 3;
        } else if (r.currentMatchStr) {
          hasCurrentMatchOrMultiple3 = true;
        }
      }
      return (
        <Link
          className="ProjectsScreenRequest"
          key={r.id}
          to={'/requests/' + r.id}
        >
          {r.isActive ? (
            <div className="ProjectsScreenRequestHeader">
              <div className="ProjectsScreenRequestHeaderTitle">
                Project Details
              </div>
              {hasCurrentMatchOrMultiple3 ? (
                <div className="ProjectsScreenRequestStatus ProjectsScreenRequestStatusMatched">
                  matched
                </div>
              ) : (
                <div className="ProjectsScreenRequestStatus ProjectsScreenRequestStatusWaiting">
                  finding your expert{r.isMultipleMatch ? 's' : ''}
                </div>
              )}
            </div>
          ) : (
            <div className="ProjectsScreenRequestHeader">
              <div className="ProjectsScreenRequestHeaderTitle">
                {r.currentMatchStr ? 'Project Details' : 'Project Canceled'}
              </div>
              {r.currentMatchStr ? (
                <div className="ProjectsScreenRequestStatus ProjectsScreenRequestStatusMatched">
                  matched
                </div>
              ) : (
                <div className="ProjectsScreenRequestStatus ProjectsScreenRequestStatusCanceled">
                  canceled
                </div>
              )}
            </div>
          )}
          <div className="ProjectsScreenRequestBody">
            <ReadOnlyEditor
              className="ProjectsScreenRequestText"
              content={
                r.projectPrefab
                  ? r.projectPrefab.description || ''
                  : r.content || ''
              }
            />
          </div>
          {!!r.isMultipleMatch && !!r.matchLog && !!r.matchLog.length && (
            <div className="ProjectsScreenRequestMatches">
              <div className="ProjectsScreenRequestMatchesTitle">Intros</div>
              {r.matchLog.map((ml) => (
                <div
                  key={ml.matchStr}
                  className="ProjectsScreenRequestMatchItem"
                >
                  {!!ml.matchedWho && (
                    <Fragment>
                      <ExpertUserBubble
                        expertId={ml.matchedWho.id}
                        expertDetails={ml.matchedWho}
                        className="ProjectsScreenRequestMatchItemPhoto"
                      />
                      <div className="ProjectsScreenRequestMatchItemName">
                        {ml.matchedWho.firstName || ''}
                      </div>
                      <div className="ProjectsScreenRequestMatchItemSub">
                        {expertFocusPosition(ml.matchedWho.tagFocus) ||
                          'Developer'}
                      </div>
                    </Fragment>
                  )}
                </div>
              ))}
            </div>
          )}
        </Link>
      );
    });
  }
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('HumanProjects socketClient onReconnected');
      refetchRequestsActive().catch(() => {});
      refetchProjectsActive().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchRequestsActive, refetchProjectsActive]);
  const activeRequestWithoutProjectsLength =
    activeRequestWithoutProjects.length;
  const activeProjectsForBrandsLength = activeProjectsForBrands.length;
  const brandsLength = brands.length;
  useEffect(() => {
    if (
      brandsLength &&
      !pastLoaded &&
      !loadingRequestsActive &&
      !loadingProjectsActive &&
      !activeRequestWithoutProjectsLength &&
      !activeProjectsForBrandsLength
    ) {
      loadThePastInit();
    }
  }, [
    pastLoaded,
    loadingRequestsActive,
    loadingProjectsActive,
    activeRequestWithoutProjectsLength,
    activeProjectsForBrandsLength,
    loadThePastInit,
    brandsLength,
  ]);
  if (errorRequestsActive || errorProjectsActive) {
    console.error(errorRequestsActive || errorProjectsActive);
  }
  if (
    loadingRequestsActive ||
    loadingProjectsActive ||
    loadingProjectPast ||
    loadingRequestFailed
  ) {
    // ignore these
  }
  return (
    <div className="DashboardScreen DashboardScreenHuman ProjectsScreen">
      <Link className="DashboardScreenLogoLink" to="/home">
        <img
          className="DashboardScreenLogo"
          src={logo}
          alt="Storetasker Logo"
        />
      </Link>
      <div className="DashboardScreenContent ProjectsScreenContent">
        {activeRequestsForBrands.length < 3 && !loadingRequestsActive && (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">Kick off a new project</div>
            <div className="DashboardSectionKickOff">
              <Link to="/new" className="DashboardSectionKickOffBtn">
                Get Started
              </Link>
              <div className="DashboardSectionKickOffText">
                {hasDiscountCode
                  ? notificationDiscountCode(true, hasDiscountCode)
                  : 'Work with the same expert or connect with someone new'}
              </div>
            </div>
          </div>
        )}
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Active projects</div>
          <div className="DashboardSectionRows">
            {renderRequestCards(activeRequestWithoutProjects)}
            {renderProjectCards(activeProjectsForBrands)}
            {!activeRequestWithoutProjects.length &&
              !activeProjectsForBrands.length && (
                <div className="DashboardSectionEmpty">
                  {errorRequestsActive || errorProjectsActive
                    ? errorUtils.getErrorMessage(
                        errorRequestsActive || errorProjectsActive!,
                      ) || 'Loading Error'
                    : 'No active projects found.'}
                </div>
              )}
            {!pastLoaded && (
              <div
                className="DashboardSectionLoadMore DashboardSectionLoadMoreBig"
                onClick={loadThePastInit}
              >
                view past projects
              </div>
            )}
          </div>
        </div>
        {!!pastLoaded && (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">Past projects</div>
            <div className="DashboardSectionRows">
              {renderProjectCards(pastProjectsForBrands)}
              {renderRequestCards(pastRequestsForBrands)}
              {((pastProjectsForBrands.length >= PAGE_LIMIT &&
                showLoadMoreProjects) ||
                (pastRequestsForBrands.length >= PAGE_LIMIT &&
                  showLoadMoreRequests)) && (
                <div className="DashboardSectionLoadMore" onClick={loadMoreAll}>
                  load more
                </div>
              )}
              {!pastProjectsForBrands.length &&
                !pastRequestsForBrands.length && (
                  <div className="DashboardSectionEmpty">
                    {errorProjectPast || errorRequestFailed
                      ? errorUtils.getErrorMessage(
                          errorProjectPast || errorRequestFailed!,
                        )
                      : 'No past projects found.'}
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

HumanProjects.propTypes = {
  brands: PropTypes.array.isRequired,
  hasDiscountCode: PropTypes.string.isRequired,
  human: PropTypes.object.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default HumanProjects;
