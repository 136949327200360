import _ from 'lodash';
import moment from 'moment-timezone';
import { useState, useContext } from 'react';
import { useQuery, gql, useMutation, ApolloError } from '@apollo/client';
import PropTypes from 'prop-types';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import envUtils from '../../utils/env';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { AccountBalanceEventDetail } from '../../utils/gql';
import {
  kpiDollars,
  getPartnerLevelStr,
  formatNumberWithCommas,
  centsDollarsRounded,
  centsDollarsDecimal,
  partnerReferralMedium,
} from '../../utils/format';
import {
  AccountBalanceEventPaginatePartnerQuery,
  AccountBalanceEventPaginatePartnerQueryVariables,
  PartnerReferralReportPaginateQuery,
  PartnerReferralReportPaginateQueryVariables,
  AccountBalanceDetailsPartnerQuery,
  AccountBalanceCashoutForPartnerMutation,
  AuthPartnerQuery,
} from '../../gql/graphql';
import logo from '../../images/storetasker-logo-black.png';

const accountBalanceDetailsPartnerQuery = gql`
  query AccountBalanceDetailsPartner {
    accountBalanceDetails {
      id
      accountIsActive
      accountNeedsTaxReview
      balance
      estimatedFees
      deliveryMethod
      totalCommission
    }
  }
`;

const accountBalanceEventPaginatePartnerQuery = gql`
  query AccountBalanceEventPaginatePartner(
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    accountBalanceEventPaginate(
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...AccountBalanceEventDetail
    }
  }
  ${AccountBalanceEventDetail}
`;

const partnerReferralReportPaginateQuery = gql`
  query PartnerReferralReportPaginate($first: Int!, $skip: Int!) {
    partnerReferralReportPaginate(first: $first, skip: $skip) {
      id
      partnerStr
      sortDate
      expert {
        id
        firstName
        lastName
      }
      human {
        id
        firstName
        lastName
        primaryEmail
        partnerReferral {
          medium
          ticket
        }
      }
      isDiscount
      isRepeat
      quote {
        title
        cents
      }
      status
    }
  }
`;

const accountBalanceCashoutForPartnerMutation = gql`
  mutation AccountBalanceCashoutForPartner {
    accountBalanceCashoutForPartner {
      ...AccountBalanceEventDetail
    }
  }
  ${AccountBalanceEventDetail}
`;

interface PartnerOverviewProps {
  partnerDetails: Extract<
    Exclude<AuthPartnerQuery['auth'], null | undefined>['user'],
    { __typename?: 'Partner' | undefined }
  >;
}

const TRANS_PAGE_LIMIT = 5;
const REF_PAGE_LIMIT = 10;

const PartnerOverview = ({ partnerDetails }: PartnerOverviewProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [initDate] = useState(() => new Date());
  const [actionLoading, setActionLoading] = useState(false);
  const [showLoadMoreRefs, setShowLoadMoreRefs] = useState(true);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [showCashoutModal, setShowCashoutModal] = useState(false);
  const [tempBalanceEvents, setTempBalanceEvents] = useState(
    [] as AccountBalanceEventPaginatePartnerQuery['accountBalanceEventPaginate'],
  );
  const {
    data: dataAccountDetails,
    error: errorAccountDetails,
    loading: loadingAccountDetails,
    refetch: refetchAccountDetails,
  } = useQuery<AccountBalanceDetailsPartnerQuery>(
    accountBalanceDetailsPartnerQuery,
  );
  const {
    data: dataAccountBalanceEventPaginated,
    error: errorAccountBalanceEventPaginated,
    loading: loadingAccountBalanceEventPaginated,
    fetchMore: fetchMoreAccountBalanceEventPaginated,
  } = useQuery<
    AccountBalanceEventPaginatePartnerQuery,
    AccountBalanceEventPaginatePartnerQueryVariables
  >(accountBalanceEventPaginatePartnerQuery, {
    variables: {
      direction: 'BACKWARD',
      fromDate: initDate.getTime(),
      limit: TRANS_PAGE_LIMIT,
    },
  });
  const {
    data: dataPartnerReferralReportPaginated,
    error: errorPartnerReferralReportPaginated,
    loading: loadingPartnerReferralReportPaginated,
    fetchMore: fetchMorePartnerReferralReportPaginated,
  } = useQuery<
    PartnerReferralReportPaginateQuery,
    PartnerReferralReportPaginateQueryVariables
  >(partnerReferralReportPaginateQuery, {
    variables: {
      first: REF_PAGE_LIMIT,
      skip: 0,
    },
  });
  const [tryCashout] = useMutation<AccountBalanceCashoutForPartnerMutation>(
    accountBalanceCashoutForPartnerMutation,
  );
  const accountDetails =
    dataAccountDetails && dataAccountDetails.accountBalanceDetails;
  const isCashoutAllowed =
    accountDetails &&
    accountDetails.accountIsActive &&
    !accountDetails.accountNeedsTaxReview &&
    !actionLoading &&
    accountDetails.balance >= 5000;
  let balanceInfoStr = '';
  if (accountDetails && !actionLoading) {
    balanceInfoStr = `Your current balance is $${formatNumberWithCommas(
      centsDollarsRounded(accountDetails.balance || 0),
    )}. `;
    if (accountDetails.balance < 5000) {
      balanceInfoStr += 'You need at least $50 in order to cash out. ';
    } else if (accountDetails.accountNeedsTaxReview) {
      balanceInfoStr +=
        'Before you can cash out, we need to finish reviewing your PaymentRails account (1-2 days). ';
    } else if (!accountDetails.accountIsActive) {
      balanceInfoStr +=
        'Before you can cash out, you need to fully set up your PaymentRails account. Click the settings link above. ';
    } else {
      balanceInfoStr += 'Feel free to cash out at any time.';
    }
  }
  const allAccountBalanceEvents = _.uniqBy(
    [
      ...((dataAccountBalanceEventPaginated &&
        dataAccountBalanceEventPaginated.accountBalanceEventPaginate) ||
        []),
    ]
      .concat(tempBalanceEvents)
      .filter((abe) => abe.ownerPartnerStr === partnerDetails.id),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  function loadMoreBalanceEvents() {
    fetchMoreAccountBalanceEventPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.accountBalanceEventPaginate)
          return prev;
        if (
          fetchMoreResult.accountBalanceEventPaginate.length < TRANS_PAGE_LIMIT
        ) {
          setShowLoadMore(false);
        }
        return {
          accountBalanceEventPaginate: (
            prev.accountBalanceEventPaginate || []
          ).concat(fetchMoreResult.accountBalanceEventPaginate),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate:
          allAccountBalanceEvents[allAccountBalanceEvents.length - 1].createdAt,
        limit: TRANS_PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'PartnerOverview',
        func: 'loadMoreBalanceEvents',
      });
    });
  }
  const allRefReports = _.uniqBy(
    [
      ...((dataPartnerReferralReportPaginated &&
        dataPartnerReferralReportPaginated.partnerReferralReportPaginate) ||
        []),
    ].filter((ref) => ref.partnerStr === partnerDetails.id),
    'id',
  ).sort((a, b) => b.sortDate - a.sortDate);
  function loadMoreRefReports() {
    fetchMorePartnerReferralReportPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.partnerReferralReportPaginate)
          return prev;
        if (
          fetchMoreResult.partnerReferralReportPaginate.length <
          TRANS_PAGE_LIMIT
        ) {
          setShowLoadMoreRefs(false);
        }
        return {
          partnerReferralReportPaginate: (
            prev.partnerReferralReportPaginate || []
          ).concat(fetchMoreResult.partnerReferralReportPaginate),
        };
      },
      variables: {
        first: REF_PAGE_LIMIT,
        skip: allRefReports.length,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'PartnerOverview',
        func: 'loadMoreRefReports',
      });
    });
  }
  function startCashout() {
    if (actionLoading) return;
    if (!accountDetails || !accountDetails.balance) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryCashout()
      .then(({ data: mutationData }) => {
        if (mutationData && mutationData.accountBalanceCashoutForPartner) {
          setTempBalanceEvents(
            _.uniqBy(
              tempBalanceEvents.concat(
                mutationData.accountBalanceCashoutForPartner,
              ),
              'id',
            ),
          );
        }
        if (refetchAccountDetails) {
          refetchAccountDetails()
            .catch((err: ApolloError) => {
              logError(err, {
                component: 'PartnerOverview',
                func: 'refetchAccountDetails',
              });
            })
            .finally(() => {
              setActionLoading(false);
              setShowCashoutModal(false);
            });
        } else {
          setActionLoading(false);
          setShowCashoutModal(false);
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Cashout Error');
        logError(err, {
          component: 'PartnerOverview',
          func: 'tryCashout',
        });
      });
  }
  const profileUrlFull = partnerDetails.trackingId
    ? 'https://www.storetasker.com/partners/' + partnerDetails.trackingId
    : '';
  const profileUrlUnique = partnerDetails.trackingId
    ? 'www.storetasker.com/partners/' + partnerDetails.trackingId
    : '';
  let lastUpdatedStr = '3 hours ago';
  if (partnerDetails.kpisUpdated) {
    lastUpdatedStr = moment(partnerDetails.kpisUpdated).fromNow();
  }
  const levelStr = partnerDetails.levelEstimate
    ? getPartnerLevelStr(partnerDetails.levelEstimate)
    : null;
  if (
    errorAccountBalanceEventPaginated ||
    errorPartnerReferralReportPaginated
  ) {
    console.log(
      'loading error: ',
      errorAccountBalanceEventPaginated,
      errorPartnerReferralReportPaginated,
    );
  }
  if (
    loadingAccountDetails ||
    loadingAccountBalanceEventPaginated ||
    loadingPartnerReferralReportPaginated
  ) {
    // ignore these
  }
  return (
    <div className="DashboardModal ExpertProfileViewModal">
      <div className="DashboardModalTopCircle" />
      <div className="DashboardModalBottomCircle" />
      <div className="ExpertProfileView">
        <div className="ExpertProfileMain">
          <div className="ExpertProfileMainHeader">
            <div className="ExpertProfileMainHeaderLeft">
              {partnerDetails.partnerName}
            </div>
            <img
              className="ExpertProfileMainHeaderRight"
              src={logo}
              alt="Storetasker Logo"
            />
          </div>
          <div className="ExpertProfilePanel">
            {!partnerDetails.isNoCommission && (
              <div className="ExpertProfileSection">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Overview
                </div>
                <div className="ExpertProfileSectionAction">
                  <a
                    className="ExpertProfileSectionActionLink"
                    href={`https://${envUtils.pick(
                      'storetasker.portal.paymentrails.com',
                      'storetaskerdev.portal.paymentrails.com',
                      'storetaskerdev.portal.paymentrails.com',
                    )}?email=${partnerDetails.contactEmail || ''}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Settings
                  </a>
                </div>
                {!loadingAccountDetails && (
                  <div className="ExpertProfileBalances">
                    {!actionLoading && (
                      <div className="ExpertProfileBalance">
                        <div className="ExpertProfileBalanceNumber">
                          {accountDetails
                            ? '$' +
                              formatNumberWithCommas(
                                centsDollarsRounded(
                                  accountDetails.balance || 0,
                                ),
                              )
                            : '$'}
                        </div>
                        <div className="ExpertProfileBalanceTitle">
                          Available Balance
                        </div>
                        <div className="ExpertProfileBalanceDescription">
                          {errorAccountDetails
                            ? 'Error: ' +
                              (errorUtils.getErrorMessage(
                                errorAccountDetails,
                              ) || 'Account Error')
                            : balanceInfoStr}
                        </div>
                        {!!isCashoutAllowed && (
                          <div
                            className="ExpertProfileBalanceBtn"
                            onClick={() => setShowCashoutModal(true)}
                          >
                            Cash out
                          </div>
                        )}
                      </div>
                    )}
                    <div className="ExpertProfileBalance">
                      <div className="ExpertProfileBalanceNumber">
                        {accountDetails
                          ? kpiDollars(accountDetails.totalCommission || 0)
                          : '$'}
                      </div>
                      <div className="ExpertProfileBalanceTitle">
                        Commission Earned
                      </div>
                      {levelStr && (
                        <div className="ExpertProfileBalanceDescription">
                          {levelStr.info}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              className={
                'ExpertProfileSection' +
                (partnerDetails.isNoCommission
                  ? ''
                  : ' ExpertProfileSectionBelow ')
              }
            >
              <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                Metrics
              </div>
              <div className="ExpertProfileSectionAction">
                <div className="ExpertProfileSectionActionInfo">
                  updated {lastUpdatedStr}
                </div>
              </div>
              <div className="ExpertProfileBalances">
                <div className="ExpertProfileBalance">
                  <div className="ExpertProfileBalanceNumber">
                    {formatNumberWithCommas(
                      (partnerDetails.uniqueLeadsEstimate || 0).toString(),
                    )}
                  </div>
                  <div className="ExpertProfileBalanceTitle">Unique Clicks</div>
                  <div className="ExpertProfileBalanceDescription">
                    Send potential clients to your partner page or use your
                    tracking ID in order to get credit for a referral.
                  </div>
                </div>
                <div className="ExpertProfileBalance">
                  <div className="ExpertProfileBalanceNumber">
                    {formatNumberWithCommas(
                      (partnerDetails.uniqueSignupsEstimate || 0).toString(),
                    )}
                  </div>
                  <div className="ExpertProfileBalanceTitle">Signups</div>
                  <div className="ExpertProfileBalanceDescription">
                    When one of your referrals signs up with Storetasker,
                    we&apos;ll associate their customer record with your account
                    going forward, so that you get credit when they do a
                    project.
                  </div>
                </div>
                <div className="ExpertProfileBalance">
                  <div className="ExpertProfileBalanceNumber">
                    {formatNumberWithCommas(
                      (partnerDetails.allPurchasesEstimate || 0).toString(),
                    )}
                  </div>
                  <div className="ExpertProfileBalanceTitle">
                    {partnerDetails.isNoCommission ? 'Customers' : 'Purchases'}
                  </div>
                  {partnerDetails.isNoCommission ? (
                    <div className="ExpertProfileBalanceDescription">
                      We&apos;ll also keep track of your referrals that
                      successfully complete a project.
                    </div>
                  ) : (
                    <div className="ExpertProfileBalanceDescription">
                      {partnerDetails.levelEstimate === 'level-5' ||
                      partnerDetails.levelEstimate === 'level-6'
                        ? `Every time they complete a project, you'll get paid. You've referred ${formatNumberWithCommas(
                            (
                              partnerDetails.uniquePurchasesEstimate || 0
                            ).toString(),
                          )} paying customers so far.`
                        : "Once they complete their first project, you'll get paid your share of the project revenue."}
                    </div>
                  )}
                </div>
                {!!partnerDetails.uniqueActiveProjectsEstimate && (
                  <div className="ExpertProfileBalance">
                    <div className="ExpertProfileBalanceNumber">
                      {formatNumberWithCommas(
                        partnerDetails.uniqueActiveProjectsEstimate.toString(),
                      )}
                    </div>
                    <div className="ExpertProfileBalanceTitle">
                      Active Projects
                    </div>
                    <div className="ExpertProfileBalanceDescription">
                      Currently, there{' '}
                      {partnerDetails.uniqueActiveProjectsEstimate > 1
                        ? 'are'
                        : 'is'}{' '}
                      {partnerDetails.uniqueActiveProjectsEstimate} active
                      project
                      {partnerDetails.uniqueActiveProjectsEstimate > 1
                        ? 's'
                        : ''}{' '}
                      with work in progress getting close to completion.
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!!allRefReports.length && (
              <div className="ExpertProfileSection ExpertProfileSectionBelow">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Referrals
                </div>
                {partnerDetails.levelEstimate === 'level-5' && (
                  <div className="ExpertProfileSectionAction">
                    <a
                      className="ExpertProfileSectionActionLink"
                      href={`${envUtils.pick(
                        'https://api.storetasker.com',
                        'https://dev.storetasker.com',
                        'http://localhost:4000',
                      )}/partnerMetrics/csv/${partnerDetails.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      download csv
                    </a>
                  </div>
                )}
                <div className="ExpertProfileTransactions">
                  {allRefReports.map((referral) => {
                    const refDate = moment(referral.sortDate).format(
                      'MMM Do, YYYY',
                    );
                    let projectTitle = '';
                    let statusText = '';
                    let expertText = '';
                    const expertName = referral.expert
                      ? (
                          (referral.expert.firstName || '') +
                          ' ' +
                          (referral.expert.lastName || '')
                        ).trim()
                      : '';
                    if (expertName) expertText = 'Expert: ' + expertName;
                    if (referral.quote) {
                      projectTitle =
                        referral.quote.title +
                        ': $' +
                        formatNumberWithCommas(
                          centsDollarsRounded(referral.quote.cents || 0),
                        );
                    }
                    let humanText =
                      referral.human.firstName || referral.human.lastName;
                    if (referral.human.primaryEmail) {
                      if (humanText) {
                        humanText += ' (' + referral.human.primaryEmail + ')';
                      } else {
                        humanText = referral.human.primaryEmail;
                      }
                    }
                    if (referral.status === 'PROJECT_COMPLETED') {
                      statusText = 'Project Completed';
                    } else if (referral.status === 'QUOTE_ACCEPTED') {
                      statusText = 'Quote Accepted';
                    } else if (referral.status === 'PROJECT_CANCELED') {
                      statusText = 'Project Canceled';
                      expertText = 'Waiting for next project';
                      projectTitle = '';
                    } else if (referral.status === 'QUOTE_SENT') {
                      statusText = 'Quote Sent';
                    } else if (referral.status === 'MATCHED_REQUEST') {
                      statusText = 'Matched';
                      projectTitle = 'Waiting for cusotmer response';
                    } else if (referral.status === 'REQUEST_CANCELED') {
                      statusText = 'Request Canceled';
                      projectTitle = 'Waiting for next project';
                    } else if (referral.status === 'SUBMITTED_REQUEST') {
                      statusText = 'Submitted Request';
                      projectTitle = 'Waiting for match';
                    } else if (referral.status === 'EMAIL_INTRO') {
                      statusText = 'Introduced';
                      projectTitle = 'No project submission yet';
                    } else {
                      // SIGNED_UP is the default
                      statusText = 'Signed Up';
                      projectTitle = 'No project submission yet';
                    }
                    return (
                      <div
                        key={referral.id}
                        className={
                          'ExpertProfileTransaction ' +
                          (referral.status === 'PROJECT_COMPLETED'
                            ? ' ExpertProfileTransactionCredit '
                            : '')
                        }
                      >
                        <div className="ExpertProfileTransactionTitle">
                          {humanText}
                        </div>
                        {!!projectTitle && (
                          <div className="ExpertProfileTransactionSub">
                            {projectTitle}
                          </div>
                        )}
                        {!!expertText && (
                          <div className="ExpertProfileTransactionSub">
                            {expertText}
                          </div>
                        )}
                        {!!referral.human.partnerReferral && (
                          <div className="ExpertProfileTransactionSub">
                            via{' '}
                            {referral.human.partnerReferral.ticket
                              ? 'Ticket: ' +
                                referral.human.partnerReferral.ticket
                              : partnerReferralMedium(
                                  referral.human.partnerReferral.medium,
                                )}
                          </div>
                        )}
                        <div className="ExpertProfileTransactionAmount">
                          {referral.isDiscount
                            ? 'Discount Code Used'
                            : statusText}
                        </div>
                        <div className="ExpertProfileTransactionDate">
                          {refDate}
                        </div>
                      </div>
                    );
                  })}
                  {allRefReports.length >= REF_PAGE_LIMIT &&
                    showLoadMoreRefs && (
                      <div
                        className="ExpertProfileTransactionsLoader"
                        onClick={loadMoreRefReports}
                      >
                        load more
                      </div>
                    )}
                </div>
              </div>
            )}
            <div className="ExpertProfileSection ExpertProfileSectionBelow">
              <div className="ExpertProfileSectionTitle">Partner Tools</div>
              <div className="ExpertProfileSectionMinis">
                <div className="ExpertProfileSectionMini">
                  <div className="ExpertProfileSectionMiniTitle">
                    Partner Page Url
                  </div>
                  <a
                    className="ExpertProfileSectionMiniBody"
                    href={profileUrlFull}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {profileUrlUnique}
                  </a>
                  <div className="ExpertProfileSectionMiniTitle">
                    Tracking ID
                  </div>
                  <div className="ExpertProfileSectionMiniBody">
                    Add <span>?ref={partnerDetails.trackingId}</span> to the end
                    of any Storetasker link to get credit for a referral.
                  </div>
                </div>
                <div className="ExpertProfileSectionMini ExpertProfileSectionMiniSecond">
                  <div className="ExpertProfileSectionMiniTitle">
                    Client Intros
                  </div>
                  <a
                    href="mailto:intros@hq.storetasker.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ExpertProfileSectionMiniBody"
                  >
                    intros@hq.storetasker.com
                  </a>
                  <div className="ExpertProfileSectionMiniTitle">
                    Support Team
                  </div>
                  <a
                    href="mailto:support@storetasker.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ExpertProfileSectionMiniBody"
                  >
                    support@storetasker.com
                  </a>
                  <div className="ExpertProfileSectionMiniTitle">
                    Head Of Partnerships
                  </div>
                  <a
                    href="mailto:charlie@hq.storetasker.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ExpertProfileSectionMiniBody"
                  >
                    charlie@hq.storetasker.com
                  </a>
                </div>
              </div>
            </div>
            {!partnerDetails.isNoCommission &&
              !!allAccountBalanceEvents.length && (
                <div className="ExpertProfileSection ExpertProfileSectionBelow">
                  <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                    Withdrawals
                  </div>
                  <div className="ExpertProfileSectionAction">
                    <a
                      className="ExpertProfileSectionActionLink"
                      href={`https://${envUtils.pick(
                        'storetasker.portal.paymentrails.com',
                        'storetaskerdev.portal.paymentrails.com',
                        'storetaskerdev.portal.paymentrails.com',
                      )}?email=${partnerDetails.contactEmail || ''}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Settings
                    </a>
                  </div>
                  <div className="ExpertProfileTransactions">
                    {allAccountBalanceEvents.map((abe) => {
                      let titleStr = '';
                      let subStr = '';
                      if (abe.brand && abe.project && abe.project.quote) {
                        titleStr = abe.brand.name || 'Project';
                        subStr = abe.project.quote.title || abe.reason || '';
                      } else if (abe.paymentRailsPaymentID) {
                        titleStr = 'Cashed Out';
                        subStr =
                          abe.paymentRailsPaymentStatus || abe.reason || '';
                      } else if (abe.action === 'credit') {
                        titleStr = 'Credit Added';
                        subStr = abe.reason || '';
                      } else if (abe.action === 'debit') {
                        titleStr = 'Credit Removed';
                        subStr = abe.reason || '';
                      }
                      return (
                        <div
                          key={abe.id}
                          className="ExpertProfileTransaction ExpertProfileTransactionCredit"
                        >
                          <div className="ExpertProfileTransactionTitle">
                            {titleStr}
                          </div>
                          <div className="ExpertProfileTransactionSub">
                            {subStr}
                          </div>
                          <div className="ExpertProfileTransactionAmount">
                            $
                            {formatNumberWithCommas(
                              centsDollarsDecimal(abe.amount || 0),
                            )}
                          </div>
                          <div className="ExpertProfileTransactionDate">
                            {moment(abe.createdAt).format('MMMM Do, YYYY')}
                          </div>
                        </div>
                      );
                    })}
                    {allAccountBalanceEvents.length >= TRANS_PAGE_LIMIT &&
                      showLoadMore && (
                        <div
                          className="ExpertProfileTransactionsLoader"
                          onClick={loadMoreBalanceEvents}
                        >
                          load more
                        </div>
                      )}
                  </div>
                </div>
              )}
            {!!showCashoutModal && !!isCashoutAllowed && accountDetails && (
              <div className="ExpertProfileModal">
                <div
                  className="ExpertProfileModalCover"
                  onClick={() => !actionLoading && setShowCashoutModal(false)}
                />
                <div className="ExpertProfileModalContainer">
                  <div className="ExpertProfileModalContainerBody ExpertProfileModalContainerBodyCashout">
                    <div className="ExpertProfileModalContainerTitle">
                      Ready to cash out?
                    </div>
                    <div className="ExpertProfileModalCashout">
                      <div className="ExpertProfileModalCashoutTitle">
                        $
                        {formatNumberWithCommas(
                          centsDollarsDecimal(accountDetails.balance || 0),
                        )}
                      </div>
                      <div
                        className={
                          'ExpertProfileModalCashoutAction ' +
                          (actionLoading
                            ? ' ExpertProfileModalCashoutActionLoading '
                            : '')
                        }
                        onClick={startCashout}
                      >
                        Cash out
                      </div>
                      <div className="ExpertProfileModalCashoutSub">
                        {accountDetails.deliveryMethod === 'paypal'
                          ? 'Funds will hit your PayPal account immediately.'
                          : 'Funds will direct deposit to your bank account in 24-48 hours.'}{' '}
                        {accountDetails.estimatedFees
                          ? `A cashout fee of ${accountDetails.estimatedFees} will be applied.`
                          : ''}
                      </div>
                      <a
                        className="ExpertProfileModalCashoutLink"
                        href={`https://${envUtils.pick(
                          'storetasker.portal.paymentrails.com',
                          'storetaskerdev.portal.paymentrails.com',
                          'storetaskerdev.portal.paymentrails.com',
                        )}?email=${partnerDetails.contactEmail || ''}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        update payment settings
                      </a>
                    </div>
                  </div>
                  {!actionLoading && (
                    <div
                      className="ExpertProfileModalClose"
                      onClick={() => setShowCashoutModal(false)}
                    >
                      cancel
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PartnerOverview.propTypes = {
  partnerDetails: PropTypes.object.isRequired,
};

export default PartnerOverview;
