import _ from 'lodash';
import { useEffect, useState, useContext, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import alertUtils from '../../utils/alert';
import { replyToSubject, unreadReasonFormatted } from '../../utils/format';
import { SUPPORT_EXPERT_ID } from '../../utils/constants';
import tokenUtils from '../../utils/token';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { IFilestackFileUpload } from '../../utils/filestack';
import EmailMessageThreadEvent from '../threadEvent/EmailMessageThreadEvent';
import TextMessageThreadEvent from '../threadEvent/TextMessageThreadEvent';
import MessageThreadEvent from '../threadEvent/MessageThreadEvent';
import EmailEditor from '../feature/EmailEditor';
import {
  TextMessageEventDetail,
  EmailMessageEventDetail,
  MessageEventDetail,
  MeetingEventDetail,
  QuoteDetail,
  PhoneCallEventDetail,
  RequestEventDetail,
  FileUploadDetail,
} from '../../utils/gql';
import {
  ExpertDetailsQuery,
  TemplatesQuery,
  FileActiveForSupportChannelQuery,
  FileActiveForSupportChannelQueryVariables,
  SupportChannelThreadPaginatedQuery,
  SupportChannelThreadPaginatedQueryVariables,
  SupportChannelThreadEventUpdatedSubscription,
  SupportChannelThreadEventUpdatedSubscriptionVariables,
  SupportChannelFileUploadUpdatedSubscription,
  SupportChannelFileUploadUpdatedSubscriptionVariables,
  SupportChannelMarkAsReadUnreadMutation,
  SupportChannelMarkAsReadUnreadMutationVariables,
  SupportChannelThreadSendEmailMessageMutation,
  SupportChannelThreadSendEmailMessageMutationVariables,
} from '../../gql/graphql';

const fileActiveForSupportChannelQuery = gql`
  query FileActiveForSupportChannel($supportChannelId: ID!) {
    fileActiveForThread(supportChannelId: $supportChannelId) {
      ...FileUploadDetail
    }
  }
  ${FileUploadDetail}
`;

// @connection(key: "thread", filter: ["supportChannel"])
const supportChannelThreadPaginatedQuery = gql`
  query SupportChannelThreadPaginated(
    $supportChannelId: ID
    $direction: String!
    $fromDate: Date
    $limit: Int!
  ) {
    threadPaginated(
      supportChannelId: $supportChannelId
      direction: $direction
      fromDate: $fromDate
      limit: $limit
    ) {
      id
      edges {
        id
        cursor
        node {
          ... on TextMessageEvent {
            ...TextMessageEventDetail
          }
          ... on EmailMessageEvent {
            ...EmailMessageEventDetail
          }
          ... on RequestEvent {
            ...RequestEventDetail
          }
          ... on MessageEvent {
            ...MessageEventDetail
          }
          ... on MeetingEvent {
            ...MeetingEventDetail
          }
          ... on Quote {
            ...QuoteDetail
          }
          ... on PhoneCallEvent {
            ...PhoneCallEventDetail
          }
        }
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const supportChannelMarkAsReadUnreadMutation = gql`
  mutation SupportChannelMarkAsReadUnread($supportChannelId: ID!) {
    supportChannelMarkAsReadUnread(supportChannelId: $supportChannelId) {
      id
      expertUnread
    }
  }
`;

const supportChannelThreadSendEmailMessageMutation = gql`
  mutation SupportChannelThreadSendEmailMessage(
    $input: ThreadSendEmailMessageInput!
  ) {
    threadSendEmailMessage(input: $input) {
      ...EmailMessageEventDetail
    }
  }
  ${EmailMessageEventDetail}
`;

const supportChannelThreadEventUpdatedSubscription = gql`
  subscription SupportChannelThreadEventUpdated($threadId: ID!) {
    threadEventUpdated(threadId: $threadId) {
      ... on TextMessageEvent {
        ...TextMessageEventDetail
      }
      ... on EmailMessageEvent {
        ...EmailMessageEventDetail
      }
      ... on RequestEvent {
        ...RequestEventDetail
      }
      ... on MessageEvent {
        ...MessageEventDetail
      }
      ... on MeetingEvent {
        ...MeetingEventDetail
      }
      ... on Quote {
        ...QuoteDetail
      }
      ... on PhoneCallEvent {
        ...PhoneCallEventDetail
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const supportChannelFileUploadUpdatedSubscription = gql`
  subscription SupportChannelFileUploadUpdated($threadId: ID!) {
    fileUploadUpdated(threadId: $threadId) {
      ...FileUploadDetail
    }
  }
  ${FileUploadDetail}
`;

interface ExpertSupportChannelDetailProps {
  emailTemplates: TemplatesQuery['templates'];
  expertDetails?: ExpertDetailsQuery['expertDetails'];
  expertId: string;
  socketClient: ClientWithOnReconnected;
}

interface IGroupedEvents {
  group: SupportChannelThreadPaginatedQuery['threadPaginated']['edges'];
}

let scrollDownQuickTimeout: NodeJS.Timeout | undefined;
let isLoadingEvents = false;
const PAGE_LIMIT = 20;

const ExpertSupportChannelDetail = ({
  emailTemplates,
  expertDetails,
  expertId,
  socketClient,
}: ExpertSupportChannelDetailProps) => {
  const threadEventsWindow = useRef<HTMLDivElement>(null);
  const { addNotification } = useContext(GlobalNotificationContext);
  const [initDate] = useState(() => new Date());
  const [sendLoading, setSendLoading] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const [emailDraft, setEmailDraft] = useState(false);
  const [emailStatus, setEmailStatus] = useState('');
  const [emailFiles, setEmailFiles] = useState([] as IFilestackFileUpload[]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [primaryPanel, setPrimaryPanel] = useState('Up Next');
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [tempEdges, setTempEdges] = useState(
    [] as SupportChannelThreadPaginatedQuery['threadPaginated']['edges'],
  );
  // remoteEventIds tracks threadEventUpdated things that might cause pagination errors
  const [remoteEventIds, setRemoteEventIds] = useState([] as string[]);
  const supportChannelForExpert =
    expertDetails && expertDetails.adminSupportChannel;
  const supportChannelId = supportChannelForExpert
    ? supportChannelForExpert.id
    : '';
  const {
    data: dataThread,
    error: errorThread,
    loading: loadingThread,
    fetchMore: fetchMoreThread,
    subscribeToMore: subscribeToMoreThread,
  } = useQuery<
    SupportChannelThreadPaginatedQuery,
    SupportChannelThreadPaginatedQueryVariables
  >(supportChannelThreadPaginatedQuery, {
    onCompleted: () => {
      scrollDown();
    },
    skip: !supportChannelId,
    variables: {
      direction: 'before',
      fromDate: initDate.getTime(),
      limit: PAGE_LIMIT,
      supportChannelId,
    },
  });
  let fullErrorCover = '';
  if (errorThread) {
    fullErrorCover =
      'Error loading this thread: ' + errorUtils.getErrorMessage(errorThread);
  }
  const cleanThread =
    (dataThread &&
      dataThread.threadPaginated &&
      dataThread.threadPaginated.edges) ||
    [];
  useEffect(() => {
    setTempEdges([]);
    setRemoteEventIds([]);
  }, [supportChannelId]);
  useEffect(() => {
    let unsub: () => void;
    if (supportChannelId) {
      unsub = subscribeToMoreThread<
        SupportChannelThreadEventUpdatedSubscription,
        SupportChannelThreadEventUpdatedSubscriptionVariables
      >({
        document: supportChannelThreadEventUpdatedSubscription,
        onError: (err) => {
          addNotification(
            'Error loading thread. Please refresh and try again: ' +
              err.message,
          );
          console.error('onError: subscribeToMoreThread', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { threadEventUpdated },
            },
          },
        ) => {
          console.log('threadEventUpdated', prev, threadEventUpdated);
          if (!threadEventUpdated || !prev.threadPaginated) {
            return prev;
          }
          if (
            threadEventUpdated.__typename === 'RequestEvent' ||
            threadEventUpdated.__typename === 'Quote'
          ) {
            return prev;
          }
          if (
            (threadEventUpdated.__typename === 'MessageEvent' ||
              threadEventUpdated.__typename === 'EmailMessageEvent' ||
              threadEventUpdated.__typename === 'TextMessageEvent' ||
              threadEventUpdated.__typename === 'MeetingEvent' ||
              threadEventUpdated.__typename === 'PhoneCallEvent') &&
            prev.threadPaginated.id !== threadEventUpdated.supportChannelStr
          ) {
            return prev;
          }
          setTempEdges((prevTempEdges) =>
            prevTempEdges.filter(
              (t) =>
                t.id !== threadEventUpdated.threadEvent &&
                ['tempEmailMessageThreadEventId'].indexOf(t.id) === -1,
            ),
          );
          setRemoteEventIds((prevRemoteEventIds) =>
            prevRemoteEventIds.concat(threadEventUpdated.id),
          );
          const updatingIndex = (prev.threadPaginated.edges || []).findIndex(
            (e) => e.id === threadEventUpdated.threadEvent,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              threadPaginated: {
                __typename: prev.threadPaginated.__typename,
                edges: prev.threadPaginated.edges
                  .slice(0, updatingIndex)
                  .concat({
                    __typename:
                      prev.threadPaginated.edges[updatingIndex].__typename,
                    cursor: prev.threadPaginated.edges[updatingIndex].cursor,
                    id: prev.threadPaginated.edges[updatingIndex].id,
                    node: {
                      ...prev.threadPaginated.edges[updatingIndex].node,
                      ...threadEventUpdated,
                    } as SupportChannelThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
                  })
                  .concat(prev.threadPaginated.edges.slice(updatingIndex + 1)),
                id: prev.threadPaginated.id,
              },
            };
          }
          // add new
          scrollDown();
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: (prev.threadPaginated.edges || []).concat({
                __typename: 'ThreadEventEdge',
                cursor: threadEventUpdated.createdAt,
                id: threadEventUpdated.threadEvent || '',
                node: threadEventUpdated,
              }),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          threadId: supportChannelId,
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [subscribeToMoreThread, supportChannelId, addNotification]);
  useEffect(() => {
    // check for drafts
    const hasEmailDraft = tokenUtils.readLocalStorage('support-email');
    setEmailDraft(!!hasEmailDraft);
    if (hasEmailDraft) {
      setEmailSubject(
        tokenUtils.readLocalStorage('support-email-subject') || '',
      );
    } else {
      setEmailSubject('');
    }
  }, []);
  const {
    data: dataFileActive,
    error: errorFileActive,
    loading: loadingFileActive,
    subscribeToMore: subscribeToMoreFile,
  } = useQuery<
    FileActiveForSupportChannelQuery,
    FileActiveForSupportChannelQueryVariables
  >(fileActiveForSupportChannelQuery, {
    returnPartialData: true,
    skip: !supportChannelId,
    variables: {
      supportChannelId,
    },
  });
  const activeFiles = [
    ...((dataFileActive && dataFileActive.fileActiveForThread) || []),
  ]
    .filter(
      (f) =>
        f.supportChannelStr === supportChannelId &&
        !!f.url &&
        !f.isHiddenByExpert &&
        !f.isInlineEmail,
    )
    .sort((a, b) => b.createdAt - a.createdAt);
  useEffect(() => {
    let unsub: () => void;
    if (supportChannelId) {
      unsub = subscribeToMoreFile<
        SupportChannelFileUploadUpdatedSubscription,
        SupportChannelFileUploadUpdatedSubscriptionVariables
      >({
        document: supportChannelFileUploadUpdatedSubscription,
        onError: (err) => {
          // ignore error
          console.log('onError: subscribeToMoreFile', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { fileUploadUpdated },
            },
          },
        ) => {
          console.log('fileUploadUpdated', prev, fileUploadUpdated);
          if (!fileUploadUpdated) {
            return prev;
          }
          if (supportChannelId !== fileUploadUpdated.supportChannelStr) {
            return prev;
          }
          const updatingIndex = (prev.fileActiveForThread || []).findIndex(
            (f) => f.id === fileUploadUpdated.id,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              fileActiveForThread: prev.fileActiveForThread
                .slice(0, updatingIndex)
                .concat({
                  ...prev.fileActiveForThread[updatingIndex],
                  ...fileUploadUpdated,
                })
                .concat(prev.fileActiveForThread.slice(updatingIndex + 1)),
            };
          }
          return {
            fileActiveForThread: (prev.fileActiveForThread || []).concat(
              fileUploadUpdated,
            ),
          };
        },
        variables: {
          threadId: supportChannelId,
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [subscribeToMoreFile, supportChannelId]);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [primaryPanel, activeFiles]);
  const [tryMarkReadUnread] = useMutation<
    SupportChannelMarkAsReadUnreadMutation,
    SupportChannelMarkAsReadUnreadMutationVariables
  >(supportChannelMarkAsReadUnreadMutation);
  function markAsRead() {
    if (!supportChannelForExpert) return;
    alertUtils.removeUnread(supportChannelId);
    tryMarkReadUnread({
      optimisticResponse: {
        supportChannelMarkAsReadUnread: {
          ...supportChannelForExpert,
          expertUnread: null,
        },
      },
      variables: {
        supportChannelId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Mark As Read Error');
      logError(err, {
        component: 'ExpertSupportChannelDetail',
        func: 'markAsRead',
      });
    });
  }
  const [trySendEmailMessage] = useMutation<
    SupportChannelThreadSendEmailMessageMutation,
    SupportChannelThreadSendEmailMessageMutationVariables
  >(supportChannelThreadSendEmailMessageMutation);
  function onSendEmail(drafHtml: string) {
    if (sendLoading || !expertDetails) return;
    if (!emailSubject.trim() || !drafHtml) {
      addNotification(
        'You need a subject, recipients, and an email body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    console.log('sendEmailMessage', emailSubject, drafHtml);
    trySendEmailMessage({
      optimisticResponse: {
        threadSendEmailMessage: {
          __typename: 'EmailMessageEvent',
          content: drafHtml,
          contentExpanded: null,
          createdAt: Date.now(),
          files: emailFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempEmailMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: null,
            orphanStr: null,
            supportChannelStr: supportChannelId,
          })),
          id: 'tempEmailMessageEventId',
          isTransactional: false,
          matchStr: null,
          noExpertRecipientsFound: [],
          orphanStr: null,
          ownerExpert: {
            ...expertDetails,
            id: expertId,
          },
          ownerHuman: null,
          quote: null,
          recipientCopiedExperts: [],
          recipientExpert: {
            __typename: 'Expert',
            firstName: 'Storetasker',
            id: SUPPORT_EXPERT_ID,
            lastName: 'Support',
            photo: '',
          },
          recipientHumans: [],
          sendingError: null,
          sendingStatus: 'DELIVERED',
          subject: emailSubject,
          supportChannelStr: supportChannelId,
          threadEvent: 'tempEmailMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentEmailMutation }) => {
        if (
          dataSentEmailMutation &&
          dataSentEmailMutation.threadSendEmailMessage
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempEmailMessageThreadEventId' &&
              e.id !== dataSentEmailMutation.threadSendEmailMessage.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataSentEmailMutation.threadSendEmailMessage.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor: dataSentEmailMutation.threadSendEmailMessage.createdAt,
              id: dataSentEmailMutation.threadSendEmailMessage.threadEvent,
              node: dataSentEmailMutation.threadSendEmailMessage,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          files: emailFiles,
          html: drafHtml,
          recipientHumans: [],
          subject: emailSubject,
          supportChannelId,
        },
      },
    })
      .then(() => {
        onDeleteEmailDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempEmailMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Send Email Error');
        logError(err, {
          component: 'ExpertSupportChannelDetail',
          func: 'sendEmailMessage',
        });
      });
  }
  function onReplyClick(
    edge: SupportChannelThreadPaginatedQuery['threadPaginated']['edges'][0],
  ) {
    console.log('onReplyClick', edge.id);
    if (edge.node.__typename === 'EmailMessageEvent') {
      const newSubject = replyToSubject(edge.node.subject);
      openEmailEditor(newSubject);
    } else if (edge.node.__typename === 'TextMessageEvent') {
      openEmailEditor();
    } else if (edge.node.__typename === 'MessageEvent') {
      openEmailEditor();
    } else {
      scrollDown();
    }
  }
  const secondaryPanels = ['Up Next', 'About', 'Files'].filter(
    (p) => p !== primaryPanel,
  );
  if (loadingThread && loadingFileActive) {
    // ignore these
  }
  function groupThreadEvents(
    events: SupportChannelThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    const groupedEvents: IGroupedEvents[] = [];
    events.forEach((ev) => {
      groupedEvents.push({
        group: [ev],
      });
    });
    return groupedEvents;
  }
  const totalEdges = cleanThread.concat(tempEdges);
  const overPageLimit = totalEdges.length >= PAGE_LIMIT;
  const threadEventEdges = _.uniqBy(totalEdges, 'id').sort(
    (a, b) => a.cursor - b.cursor,
  );
  function scrollDown() {
    scrollDownQuickTimeout = setTimeout(() => {
      if (threadEventsWindow && threadEventsWindow.current) {
        threadEventsWindow.current.scrollTop =
          threadEventsWindow.current.scrollHeight;
      }
    }, 5);
  }
  useEffect(() => {
    function onScroll() {
      if (
        threadEventsWindow &&
        threadEventsWindow.current &&
        supportChannelId
      ) {
        const scrollTemp = threadEventsWindow.current.scrollTop;
        if (
          scrollTemp < 500 &&
          overPageLimit &&
          canLoadMore &&
          !isLoadingEvents
        ) {
          isLoadingEvents = true;
          const heightTemp = threadEventsWindow.current.scrollHeight;
          fetchMoreThread({
            updateQuery: (prev, { fetchMoreResult }) => {
              if (
                !fetchMoreResult ||
                !prev.threadPaginated ||
                !fetchMoreResult.threadPaginated
              )
                return prev;
              if (fetchMoreResult.threadPaginated.edges.length < PAGE_LIMIT) {
                setCanLoadMore(false);
              }
              return {
                threadPaginated: {
                  __typename: prev.threadPaginated.__typename,
                  edges: _.uniqBy(
                    fetchMoreResult.threadPaginated.edges.concat(
                      prev.threadPaginated.edges || [],
                    ),
                    'id',
                  ),
                  id: prev.threadPaginated.id,
                },
              };
            },
            variables: {
              direction: 'before',
              fromDate: threadEventEdges.filter(
                (ev) => ev.node.id && remoteEventIds.indexOf(ev.node.id) === -1,
              )[0].cursor,
              limit: PAGE_LIMIT,
              supportChannelId,
            },
          })
            .catch((err: ApolloError) => {
              addNotification(
                errorUtils.getErrorMessage(err) || 'Load More Error',
              );
              logError(err, {
                component: 'ExpertSupportChannelDetail',
                func: 'fetchMoreThread',
              });
            })
            .finally(() => {
              isLoadingEvents = false;
              if (threadEventsWindow && threadEventsWindow.current) {
                threadEventsWindow.current.scrollTop =
                  Math.max(
                    threadEventsWindow.current.scrollHeight - heightTemp,
                    0,
                  ) + scrollTemp;
              }
            });
        }
      }
    }
    if (threadEventsWindow && threadEventsWindow.current) {
      threadEventsWindow.current.addEventListener('scroll', onScroll);
    }
    const tempWindow = threadEventsWindow.current;
    return () => {
      if (tempWindow) {
        tempWindow.removeEventListener('scroll', onScroll);
      }
    };
  }, [
    canLoadMore,
    overPageLimit,
    addNotification,
    fetchMoreThread,
    supportChannelId,
    threadEventEdges,
    remoteEventIds,
  ]);
  useEffect(() => {
    scrollDown();
    const scrollDownEffectTimeout = setTimeout(() => {
      scrollDown();
    }, 500);
    return () => {
      if (scrollDownEffectTimeout) {
        clearTimeout(scrollDownEffectTimeout);
      }
      if (scrollDownQuickTimeout) {
        clearTimeout(scrollDownQuickTimeout);
      }
    };
  }, [supportChannelId]);
  useEffect(() => {
    const loadTime = new Date();
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('ExpertSupportChannelDetail socketClient onReconnected');
      fetchMoreThread({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !prev.threadPaginated ||
            !fetchMoreResult.threadPaginated
          )
            return prev;
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: _.uniqBy(
                fetchMoreResult.threadPaginated.edges.concat(
                  prev.threadPaginated.edges || [],
                ),
                'id',
              ),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          direction: 'after',
          fromDate: loadTime.getTime(),
          limit: 50,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'ExpertSupportChannelDetail',
          func: 'reconnect fetchMoreThread',
        });
      });
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, fetchMoreThread, addNotification]);
  const threadEventGroups = groupThreadEvents(threadEventEdges);
  function generateComponentsForGroup(
    groupedEvents: SupportChannelThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    return groupedEvents
      .map((ev, i) => {
        if (
          ev.node.__typename === 'RequestEvent' ||
          ev.node.__typename === 'Quote' ||
          ev.node.__typename === 'PhoneCallEvent' ||
          ev.node.__typename === 'MeetingEvent'
        ) {
          return <div key={ev.id} />;
        }
        if (ev.node.__typename === 'MessageEvent') {
          return (
            <MessageThreadEvent
              key={ev.id}
              expertId={expertId}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              messageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        if (ev.node.__typename === 'EmailMessageEvent') {
          return (
            <EmailMessageThreadEvent
              key={ev.id}
              expertId={expertId}
              emailMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        if (ev.node.__typename === 'TextMessageEvent') {
          return (
            <TextMessageThreadEvent
              key={ev.id}
              expertId={expertId}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              textMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        console.log('Missing ThreadEvent type');
        return null;
      })
      .filter((c) => c);
  }
  function closeCurrentAction() {
    setCurrentAction('');
    scrollDown();
  }
  function openEmailEditor(subject?: string, content?: string) {
    // check currentAction
    if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    // check draft
    if (emailDraft) {
      subject = tokenUtils.readLocalStorage('support-email-subject') || '';
      content = tokenUtils.readLocalStorage('support-email-content') || '';
    }
    if (subject === undefined && content === undefined) {
      const lastEmail = _.findLast(
        threadEventEdges,
        (ev) => ev.node.__typename === 'EmailMessageEvent',
      );
      // look for the latest email
      if (lastEmail) {
        // simulate as if they clicked that email
        onReplyClick(lastEmail);
        return;
      }
    }
    setCurrentAction('EMAIL');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    setEmailSubject(subject || '');
    setEmailContent(content || '');
    setEmailFiles([]);
    setEmailStatus(emailDraft ? 'Saved' : '');
  }
  const debouncedEmailDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage('support-email', 'true');
    tokenUtils.writeLocalStorage('support-email-subject', emailSubject);
    tokenUtils.writeLocalStorage('support-email-content', emailContent);
    setEmailDraft(true);
    setEmailStatus('Saved');
  }, 1000);
  function onDeleteEmailDraft() {
    debouncedEmailDraft.cancel();
    tokenUtils.removeLocalStorage('support-email');
    tokenUtils.removeLocalStorage('support-email-subject');
    tokenUtils.removeLocalStorage('support-email-content');
    setEmailDraft(false);
    setEmailStatus('');
    setEmailSubject('');
    setEmailContent('');
    setEmailFiles([]);
    setCurrentAction('');
  }
  function onSaveEmailDraft(
    subject: string,
    draftContent: string | null,
    files: IFilestackFileUpload[],
  ) {
    let saveDraft = false;
    if (emailSubject !== subject) {
      saveDraft = true;
      setEmailSubject(subject || '');
    }
    if (emailFiles !== files) {
      setEmailFiles(files || []);
    }
    if (draftContent !== null && emailContent !== draftContent) {
      saveDraft = true;
      setEmailContent(draftContent);
    }
    if (saveDraft) {
      setEmailStatus('Saving');
      debouncedEmailDraft.callback();
    }
  }
  const supportTips = [
    {
      footer: 'Expert Community',
      href: 'https://kb.storetasker.com/Your-Support-Team-292329cafc7c417f806aab360c226461',
      title: 'Meet Your Support Team',
    },
    {
      footer: 'Product Updates',
      href: 'https://kb.storetasker.com/Performance-Metrics-Explained-97e25112998e493795cda075bf60a31a',
      title: 'Account Metrics FAQ',
    },
    {
      footer: 'Knowledge Base',
      href: 'https://kb.storetasker.com/Escalated-Support-Tickets-0d52ac7024c74a46807975a9d817ce43',
      title: 'Handling Support Tickets',
    },
  ];
  const emptyCurrent =
    (!supportChannelForExpert || !supportChannelForExpert.expertUnread) &&
    !emailDraft &&
    !supportTips.length;
  function renderCurrentActionEditor() {
    if (currentAction === 'EMAIL') {
      return (
        <div
          className={
            'ThreadActionEmail ' +
            (sendLoading ? ' ThreadActionEmailSending ' : '')
          }
        >
          <EmailEditor
            emailTemplates={emailTemplates}
            expertId={expertId}
            files={emailFiles}
            fixedRecipients={[
              {
                firstName: 'Storetasker',
                id: SUPPORT_EXPERT_ID,
                lastName: 'Support',
              },
            ]}
            initContent={emailContent}
            onDeleteDraft={onDeleteEmailDraft}
            onSaveDraft={onSaveEmailDraft}
            onSendEmail={onSendEmail}
            placeholderContent={
              "We'll get back to you as soon as possible, typically within 1 business day. If you are asking about a specific case, be sure to include the brand name and any relevant project details."
            }
            recipients={[]}
            subject={emailSubject}
            status={emailStatus}
          />
        </div>
      );
    }
    return null;
  }
  return (
    <div className="DashboardModal SupportChannelDetailModal">
      <div className="DashboardModalTopCircle" />
      <div className="DashboardModalBottomCircle" />
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <Link to="/home" className="ThreadDetailMainHeaderBack">
              Storetasker Support
            </Link>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody" ref={threadEventsWindow}>
            <div
              className={
                'ThreadEvents ' +
                (currentAction ? ' ThreadEventsHasAction ' : '')
              }
            >
              {threadEventGroups.map((evGroup) => (
                <div className="ThreadEventGroup" key={evGroup.group[0].id}>
                  {generateComponentsForGroup(evGroup.group)}
                </div>
              ))}
            </div>
            {!!currentAction && !!supportChannelForExpert && (
              <div className="ThreadCurrentAction">
                {renderCurrentActionEditor()}
              </div>
            )}
          </div>
          <div
            className={
              'ThreadDetailActions ' +
              (openActions ? ' ThreadDetailActionsOpen ' : '') +
              (currentAction ? ' ThreadDetailActionsCurrent ' : '')
            }
          >
            <div className="ThreadDetailActionRow">
              <div
                className="ThreadDetailActionTrigger"
                onClick={() =>
                  currentAction
                    ? closeCurrentAction()
                    : setOpenActions(!openActions)
                }
              >
                <div className="ThreadDetailActionBtn">+</div>
                <div className="ThreadDetailActionText">actions</div>
              </div>
              <div
                className="ThreadDetailActionItem"
                onClick={() => openEmailEditor()}
              >
                <span>send an </span>email
              </div>
            </div>
          </div>
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div className="ThreadDetailSidebar">
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => setPrimaryPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            <div className="ThreadDetailSidebarBodyPanels">
              {primaryPanel === 'Up Next' && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelUpNext">
                  <div className="ThreadDetailSidebarUpNextCurrent">
                    {supportChannelForExpert &&
                      supportChannelForExpert.expertUnread && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div
                            className="ThreadDetailSidebarUpNextUnread"
                            onClick={markAsRead}
                          >
                            <div className="ThreadDetailSidebarUpNextUnreadSummary">
                              {unreadReasonFormatted(
                                supportChannelForExpert.expertUnread,
                              )}
                            </div>
                            <div className="ThreadDetailSidebarUpNextUnreadClose" />
                          </div>
                        </div>
                      )}
                    {!!emailDraft && (
                      <div className="ThreadDetailSidebarUpNextItem">
                        <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardDraft ThreadDetailSidebarUpNextCardDraftEmail">
                          <div
                            className="ThreadDetailSidebarUpNextCardHeader"
                            onClick={() => openEmailEditor()}
                          >
                            <div className="ThreadDetailSidebarUpNextCardType">
                              Draft Email
                            </div>
                            <div className="ThreadDetailSidebarUpNextCardSubtitle">
                              {emailSubject}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {supportTips.map((supportTip) => (
                      <div
                        key={supportTip.href}
                        className="ThreadDetailSidebarUpNextItem"
                      >
                        <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardSupportTip">
                          <a
                            className="ThreadDetailSidebarUpNextCardHeader"
                            href={supportTip.href}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <div className="ThreadDetailSidebarUpNextCardTitle">
                              {supportTip.title}
                            </div>
                            <div className="ThreadDetailSidebarUpNextCardSubtitle">
                              {supportTip.footer}
                            </div>
                            <div className="ThreadDetailSidebarUpNextCardTopRightLinkOut" />
                          </a>
                        </div>
                      </div>
                    ))}
                    {!!emptyCurrent && (
                      <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                        <div className="ThreadDetailSidebarBodyPanelEmpty">
                          <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                            Support Channel
                          </div>
                          <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                            News from Storetasker HQ will show up here (ex:
                            events, product updates, etc)
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {primaryPanel === 'About' && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                  <div className="ThreadDetailSidebarAboutSupport">
                    <div className="ThreadDetailSidebarAboutSupportTitle">
                      Support Team
                    </div>
                    <div className="ThreadDetailSidebarAboutSupportTeam">
                      <div className="ThreadDetailSidebarAboutSupportTeamMember">
                        <div className="ThreadDetailSidebarAboutSupportTeamMemberPic ThreadDetailSidebarAboutSupportTeamMemberPicRichard" />
                        <div className="ThreadDetailSidebarAboutSupportTeamMemberName">
                          Richard Peevers
                        </div>
                        <div className="ThreadDetailSidebarAboutSupportTeamMemberDescription">
                          I’m the director of operations here at Storetasker
                          based in Los Angeles, California. Ask me anything
                          about Shopify, client de-escalation, filling out tax
                          forms, or how to run an Apple Store.
                        </div>
                      </div>
                      <div className="ThreadDetailSidebarAboutSupportTeamMember">
                        <div className="ThreadDetailSidebarAboutSupportTeamMemberPic ThreadDetailSidebarAboutSupportTeamMemberPicRobin" />
                        <div className="ThreadDetailSidebarAboutSupportTeamMemberName">
                          Robin Coleman
                        </div>
                        <div className="ThreadDetailSidebarAboutSupportTeamMemberDescription">
                          I’m on the support team here at Storetasker, based in
                          Nashville. Ask me anything about providing excellent
                          customer experiences, analytics, or true-crime
                          podcasting.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {primaryPanel === 'Files' && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelFiles">
                  {activeFiles.map((f) => {
                    const fileMoment = moment.tz(
                      f.createdAt,
                      moment.tz.guess(),
                    );
                    const fileDateFull =
                      fileMoment.format('MMMM Do, YYYY') +
                      ' at ' +
                      fileMoment.format('h:mma z');
                    return (
                      <div key={f.id} className="ThreadDetailSidebarFileItem">
                        <a
                          className="ThreadDetailSidebarFileItemTitle"
                          href={f.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          data-tip={fileDateFull}
                        >
                          {f.filename}
                        </a>
                      </div>
                    );
                  })}
                  {!activeFiles.length && (
                    <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                      <div className="ThreadDetailSidebarBodyPanelEmpty">
                        <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                          No files found
                        </div>
                        <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                          {errorFileActive
                            ? errorUtils.getErrorMessage(errorFileActive)
                            : 'When you send or receive files, they will be saved here.'}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <Link to="/home" className="DashboardErrorCoverNav">
              back
            </Link>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

ExpertSupportChannelDetail.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  expertDetails: PropTypes.object,
  expertId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

ExpertSupportChannelDetail.defaultProps = {
  expertDetails: null,
};

export default ExpertSupportChannelDetail;
