import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  ApolloLink,
} from '@apollo/client';
import { Kind, OperationTypeNode } from 'graphql';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient, Client, ClientOptions } from 'graphql-ws';
import oauthUtils from './oauth';
import envUtils from './env';
import logError from './airbrake';
// FUTURE maybe add @apollo/client/link/batch-http later

const devApiNgrok = '9d2483972e89.ngrok.io';
const devApiUseNgrok = false;
let closedOnPurpose = false;

const cache = new InMemoryCache({
  possibleTypes: {
    ThreadEvent: [
      'MessageEvent',
      'EmailMessageEvent',
      'RequestEvent',
      'TextMessageEvent',
      'MeetingEvent',
      'PhoneCallEvent',
      'Quote',
    ],
    User: ['Human', 'Expert'],
  },
  typePolicies: {
    MatchLog: {
      keyFields: ['matchStr', 'matchedWhoStr'],
    },
    NeedsApproval: {
      keyFields: ['expertStr'],
    },
    Query: {
      fields: {
        brandDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'Brand',
            id: (args && args.brandId) || '',
          });
        },
        expertDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'Expert',
            id: (args && args.expertId) || '',
          });
        },
        humanDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'Human',
            id: (args && args.humanId) || '',
          });
        },
        matchDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'Match',
            id: (args && args.matchId) || '',
          });
        },
        meetingDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'Meeting',
            id: (args && args.meetingId) || '',
          });
        },
        orphanDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'Orphan',
            id: (args && args.orphanId) || '',
          });
        },
        partnerDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'Partner',
            id: (args && args.partnerId) || '',
          });
        },
        prefabDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'ProjectPrefab',
            id: (args && args.prefabId) || '',
          });
        },
        projectDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'Project',
            id: (args && args.projectId) || '',
          });
        },
        requestDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'Request',
            id: (args && args.requestId) || '',
          });
        },
        supportChannelDetails(_, { args, toReference }) {
          return toReference({
            __typename: 'SupportChannel',
            id: (args && args.supportChannelId) || '',
          });
        },
      },
    },
  },
});

const retryLink = new RetryLink({
  attempts: {
    retryIf: (error: any) => {
      if (error.statusCode === 401 || error.statusCode === 403) {
        return false;
      }
      return true;
    },
  },
});

function getToken(userType: string) {
  return oauthUtils.getToken(userType) || '';
}

/**
 * Generate an AuthLink (Apollo middleware) that sets an authorization token
 * on Apollo queries.
 * @param  {String} userType 'expert' or 'customer' or 'partner'
 * @return {Object}          headers object, added to Apollo's context
 */
function getAuthLink(userType: string) {
  return setContext((_, { headers }) => {
    const token = getToken(userType);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
    // ^ return the headers to the context so httpLink can read them
  });
}

const httpLink = new HttpLink({
  uri: envUtils.pick(
    'https://api.storetasker.com/graphql',
    'https://dev.storetasker.com/graphql',
    devApiUseNgrok
      ? `https://${devApiNgrok}/graphql`
      : 'http://localhost:4000/graphql',
  ),
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      logError(`[GraphQL error]: ${message}`, { locations, path }),
    );
  }
});

const isAdmin = () => window.location.hostname.startsWith('admin');

export interface ClientWithOnReconnected extends Client {
  onReconnected(cb: () => void): () => void;
}

function createClientWithOnReconnected(
  options: ClientOptions,
): ClientWithOnReconnected {
  let abruptlyClosed = false;
  const reconnectedCbs: (() => void)[] = [];

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      closed: (event) => {
        options.on?.closed?.(event);
        // non-1000 close codes are abrupt closes
        if ((event as CloseEvent).code !== 1000) {
          abruptlyClosed = true;
        }
      },
      connected: (...args) => {
        options.on?.connected?.(...args);
        // if the client abruptly closed, this is then a reconnect
        if (abruptlyClosed) {
          abruptlyClosed = false;
          reconnectedCbs.forEach((cb) => cb());
        }
      },
    },
  });

  return {
    ...client,
    onReconnected: (cb) => {
      reconnectedCbs.push(cb);
      return () => {
        reconnectedCbs.splice(reconnectedCbs.indexOf(cb), 1);
      };
    },
  };
}

// factory for apollo client so we use the correct token based on user type
const create = (userType: string) => {
  const wsClient = createClientWithOnReconnected({
    connectionParams: () => ({
      jwtToken: getToken(userType),
    }),
    lazy: true,
    retryAttempts: Infinity,
    shouldRetry: () => true,
    url: envUtils.pick(
      'wss://api.storetasker.com/graphql',
      'wss://dev.storetasker.com/graphql',
      devApiUseNgrok
        ? `wss://${devApiNgrok}/graphql`
        : 'ws://localhost:4000/graphql',
    ),
  });
  const wsLink = new GraphQLWsLink(wsClient);

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === Kind.OPERATION_DEFINITION &&
        (definition.operation === OperationTypeNode.SUBSCRIPTION ||
          !!(
            definition.name &&
            [
              'HeartbeatUserMutation',
              'HeartbeatHumanMutation',
              'HeartbeatAdminMutation',
            ].indexOf(definition.name.value) >= 0
          ))
      );
    },
    wsLink,
    ApolloLink.from([retryLink, errorLink, httpLink]),
  );

  const client = new ApolloClient({
    cache: cache.restore({
      'UserWithAuth:me': { __typename: 'UserWithAuth', id: 'me', token: '' },
    }),
    connectToDevTools: isAdmin() || !!envUtils.pick('', 'true', 'true'),
    link: ApolloLink.from([getAuthLink(userType), link]),
  });
  window.addEventListener('beforeunload', () => {
    if (wsClient) {
      closedOnPurpose = true;
      wsClient.terminate();
    }
  });
  return {
    apolloClient: client,
    socketClient: wsClient,
  };
};

function getClosedOnPurpose() {
  return closedOnPurpose;
}

export default {
  create,
  getClosedOnPurpose,
};
