import { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ClientWithOnReconnected } from '../../utils/apollo';
import {
  MatchLeadsForExpertHomeQuery,
  MatchActiveProjectsForExpertHomeQuery,
  MeetingActiveForExpertQuery,
  ExpertDetailsQuery,
  QuoteStatus,
} from '../../gql/graphql';
import { MatchActionSummary, MeetingSummary } from '../../utils/gql';
import {
  centsDollarsRounded,
  formatNumberWithCommas,
  paymentTypeFormatted,
  meetingResponseFormatted,
  quoteStatusFormatted,
} from '../../utils/format';
import logoWhite from '../../images/storetasker-logo-white.png';

const matchLeadsForExpertQuery = gql`
  query MatchLeadsForExpertHome {
    matchLeadsForExpert {
      ...MatchActionSummary
    }
  }
  ${MatchActionSummary}
`;

const matchActiveProjectsForExpertQuery = gql`
  query MatchActiveProjectsForExpertHome {
    matchActiveProjectsForExpert {
      ...MatchActionSummary
    }
  }
  ${MatchActionSummary}
`;

const meetingActiveForExpertQuery = gql`
  query MeetingActiveForExpert {
    meetingActiveForExpert {
      ...MeetingSummary
    }
  }
  ${MeetingSummary}
`;

interface ExpertHomescreenProps {
  alertCount: number;
  alertOldCount: number;
  dzCount: number;
  dzProjectCount: number;
  expertDetails?: ExpertDetailsQuery['expertDetails'];
  expertId: string;
  queueCount: number;
  socketClient: ClientWithOnReconnected;
}

const initTime = new Date().getTime();

const ExpertHomescreen = ({
  alertCount,
  alertOldCount,
  dzCount,
  dzProjectCount,
  expertDetails,
  expertId,
  queueCount,
  socketClient,
}: ExpertHomescreenProps) => {
  const {
    data: dataMatchLeads,
    error: errorMatchLeads,
    loading: loadingMatchLeads,
    refetch: refetchMatchLeads,
  } = useQuery<MatchLeadsForExpertHomeQuery>(matchLeadsForExpertQuery, {
    returnPartialData: true,
  });
  const {
    data: dataMatchActiveProjects,
    error: errorMatchActiveProjects,
    loading: loadingMatchActiveProjects,
    refetch: refetchMatchActiveProjects,
  } = useQuery<MatchActiveProjectsForExpertHomeQuery>(
    matchActiveProjectsForExpertQuery,
    { returnPartialData: true },
  );
  const {
    data: dataMeetingActive,
    error: errorMeetingActive,
    loading: loadingMeetingActive,
    refetch: refetchMeetingActive,
  } = useQuery<MeetingActiveForExpertQuery>(meetingActiveForExpertQuery, {
    returnPartialData: true,
  });
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('ExpertHomescreen socketClient onReconnected');
      refetchMatchLeads().catch(() => {});
      refetchMatchActiveProjects().catch(() => {});
      refetchMeetingActive().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [
    socketClient,
    refetchMatchLeads,
    refetchMatchActiveProjects,
    refetchMeetingActive,
  ]);
  const upNextLeadsForExpert = _.uniqBy(
    [...((dataMatchLeads && dataMatchLeads.matchLeadsForExpert) || [])].filter(
      (m) => !m.isBlocked && m.expertStr === expertId && m.isLead,
    ),
    'id',
  );
  const hasNoLeads =
    !loadingMatchLeads &&
    queueCount > 0 &&
    !upNextLeadsForExpert.length &&
    !dzCount &&
    !dzProjectCount;
  const upNextProjectsForExpert = _.uniqBy(
    [
      ...((dataMatchActiveProjects &&
        dataMatchActiveProjects.matchActiveProjectsForExpert) ||
        []),
    ].filter(
      (m) =>
        !m.isBlocked &&
        m.expertStr === expertId &&
        !m.isLead &&
        ((m.hasActiveProjectsStr && m.hasActiveProjectsStr.length) ||
          (m.hasActiveQuotesStr && m.hasActiveQuotesStr.length)),
    ),
    'id',
  );
  const upNextMeetingsForExpert = _.uniqBy(
    [
      ...((dataMeetingActive && dataMeetingActive.meetingActiveForExpert) ||
        []),
    ].filter(
      (m) =>
        m.eventEnd >= initTime &&
        m.eventStatus === 'ACTIVE' &&
        m.expertResponse !== 'DECLINED' &&
        ((m.organizerExpert && m.organizerExpert.id === expertId) ||
          (m.participantExpert && m.participantExpert.id === expertId)),
    ),
    'id',
  );
  const upNextCombined = [
    ...upNextLeadsForExpert.map((l) => ({
      data: l,
      sortTime: l.leadCloseTimeline || Infinity,
    })),
    ...upNextProjectsForExpert.map((p) => ({
      data: p,
      sortTime:
        (p.hasActiveProjects &&
          p.hasActiveProjects.length &&
          p.hasActiveProjects[0].quote &&
          p.hasActiveProjects[0].quote.estimatedCompletionDate) ||
        Infinity,
    })),
    ...upNextMeetingsForExpert.map((m) => ({
      data: m,
      sortTime: m.eventStart || Infinity,
    })),
  ]
    .sort((a, b) => a.sortTime - b.sortTime)
    .map((a) => a.data);
  const showLinkCal = !!(
    expertDetails &&
    (!expertDetails.nylasAccessToken ||
      !expertDetails.nylasCalendarId ||
      !expertDetails.scheduleTimeEnabled)
  );
  const emptyUpNext =
    !showLinkCal &&
    !alertCount &&
    !alertOldCount &&
    !dzCount &&
    !dzProjectCount &&
    !upNextCombined.length &&
    !hasNoLeads;
  const announcementItems = [
    {
      footer: 'Knowledge Base',
      href: 'https://kb.storetasker.com/Why-We-Love-Retainers-7c6169a9034b457786fd8adafb493326',
      id: 'love-retainers',
      size: 'Wide',
      title: 'Why we love retainers',
    },
    {
      footer: 'Guide',
      href: 'https://kb.storetasker.com/Making-150-000-Per-Year-8dedf288ae7547af971bd588225084f0',
      id: 'sales-playbook',
      size: 'Medium',
      title: 'Sales Playbook',
    },
    {
      footer: 'Expert Referral Program',
      href: '/collab',
      id: 'refer-experts',
      isLink: true,
      size: 'Wide',
      title: 'Invite your collaborators',
    },
    {
      footer: 'Resources',
      href: 'https://kb.storetasker.com/Quote-Calculator-905a7ceb638b403d8f2bb2b85a1075db',
      id: 'quote-calc',
      size: 'Thin',
      title: 'Quote Calculator',
    },
    {
      footer: 'Product Updates',
      href: 'https://kb.storetasker.com/Bring-Your-Own-Client-ee6420051a2147bd93388c86eecb17f3',
      id: 'byo-clients',
      size: 'Medium',
      title: 'Bring Your Own Clients',
    },
    {
      footer: 'Knowledge Base',
      href: 'https://kb.storetasker.com',
      id: 'knowledge-base',
      size: 'Wide',
      title: 'The Expert Knowledge Base',
    },
    {
      footer: 'Opportunity',
      href: 'https://www.notion.so/Become-a-Featured-Expert-6ef306f73c6d406ea6e4eadbc454aa4d',
      id: 'featured-expert',
      size: 'Medium',
      title: 'Become A Featured Expert',
    },
    {
      footer: 'Knowledge Base',
      href: 'https://kb.storetasker.com/How-Payments-are-Calculated-716a98d289b74a148a0d5ff959286c66',
      id: 'expert-cut',
      size: 'Thin',
      title: 'Project Payments',
    },
    {
      footer: 'Feedback',
      href: 'https://loremtech.typeform.com/to/qz6SOAtC',
      id: 'bug-tracker',
      size: 'Thin',
      title: 'Bug Tracker',
    },
  ];
  if (errorMatchLeads || errorMatchActiveProjects || errorMeetingActive) {
    console.error(
      errorMatchLeads || errorMatchActiveProjects || errorMeetingActive,
    );
  }
  if (loadingMatchLeads || loadingMatchActiveProjects || loadingMeetingActive) {
    // ignore these
  }
  return (
    <div className="DashboardScreen HomeScreen">
      <Link className="DashboardScreenLogoLink" to="/home">
        <img
          className="DashboardScreenLogo DashboardScreenLogoWhite"
          src={logoWhite}
          alt="Storetasker Logo"
        />
      </Link>
      <div className="DashboardScreenContent HomeScreenContent">
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Announcements</div>
          {!announcementItems.length && (
            <div className="DashboardSectionEmpty">
              Announcements from Storetasker HQ will show up here (ex: events,
              product updates, etc)
            </div>
          )}
          <div className="DashboardSectionScroller">
            {announcementItems.map((announcementItem) => (
              <div
                key={announcementItem.href}
                className={
                  'HomeScreenAnnouncement HomeScreenAnnouncement' +
                  announcementItem.size +
                  ' HomeScreenAnnouncementItem-' +
                  announcementItem.id
                }
              >
                {announcementItem.isLink ? (
                  <Link
                    className="HomeScreenAnnouncementBody"
                    to={announcementItem.href}
                  >
                    <div className="HomeScreenAnnouncementTitle">
                      {announcementItem.title}
                    </div>
                  </Link>
                ) : (
                  <a
                    className="HomeScreenAnnouncementBody"
                    href={announcementItem.href}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="HomeScreenAnnouncementTitle">
                      {announcementItem.title}
                    </div>
                  </a>
                )}
                <div className="HomeScreenAnnouncementFooter">
                  <div className="HomeScreenAnnouncementFooterText">
                    {announcementItem.footer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Up Next</div>
          {!!emptyUpNext && (
            <div className="DashboardSectionEmpty">
              Reminders about leads, projects, and meetings will show up here to
              keep you always up to date.
            </div>
          )}
          <div className="DashboardSectionScrollerSwitch">
            {dzCount > 0 && (
              <div className="HomeScreenUpNext HomeScreenUpNextDangerZone">
                <div className="HomeScreenUpNextUnreadTag">new!</div>
                <Link to="/dangerzone" className="HomeScreenUpNextBody">
                  <div className="HomeScreenUpNextTitle">
                    You have {dzCount} active support case
                    {dzCount > 1 ? 's' : ''}
                  </div>
                  <div className="HomeScreenUpNextRightCorner">Danger Zone</div>
                </Link>
                <div className="HomeScreenUpNextFooter">
                  <div className="HomeScreenUpNextFooterText">Today</div>
                </div>
              </div>
            )}
            {dzProjectCount > 0 && (
              <div className="HomeScreenUpNext HomeScreenUpNextDangerZone">
                <div className="HomeScreenUpNextUnreadTag">new!</div>
                <Link to="/dangerzone" className="HomeScreenUpNextBody">
                  <div className="HomeScreenUpNextTitle">
                    You have {dzProjectCount} project
                    {dzProjectCount > 1 ? 's' : ''} that need
                    {dzProjectCount > 1 ? '' : 's'} your attention
                  </div>
                  <div className="HomeScreenUpNextRightCorner">Danger Zone</div>
                </Link>
                <div className="HomeScreenUpNextFooter">
                  <div className="HomeScreenUpNextFooterText">Today</div>
                </div>
              </div>
            )}
            {alertOldCount > 0 && (
              <div className="HomeScreenUpNext HomeScreenUpNextDangerZone">
                <div className="HomeScreenUpNextUnreadTag">new!</div>
                <Link to="/dangerzone" className="HomeScreenUpNextBody">
                  <div className="HomeScreenUpNextTitle">
                    You have {alertOldCount} client
                    {alertOldCount > 1 ? 's' : ''} that need
                    {alertOldCount > 1 ? '' : 's'} your attention
                  </div>
                  <div className="HomeScreenUpNextRightCorner">Danger Zone</div>
                </Link>
                <div className="HomeScreenUpNextFooter">
                  <div className="HomeScreenUpNextFooterText">Today</div>
                </div>
              </div>
            )}
            {alertCount > 0 && (
              <div className="HomeScreenUpNext HomeScreenUpNextAlerts">
                <div className="HomeScreenUpNextUnreadTag">new!</div>
                <Link to="/clients" className="HomeScreenUpNextBody">
                  <div className="HomeScreenUpNextTitle">
                    You have {alertCount} unread alert
                    {alertCount > 1 ? 's' : ''}
                  </div>
                  <div className="HomeScreenUpNextRightCorner">Clients</div>
                </Link>
                <div className="HomeScreenUpNextFooter">
                  <div className="HomeScreenUpNextFooterText">Today</div>
                </div>
              </div>
            )}
            {!!showLinkCal && (
              <div className="HomeScreenUpNext HomeScreenUpNextCalendar">
                <Link
                  to="/profile?calendar=setup"
                  className="HomeScreenUpNextBody"
                >
                  <div className="HomeScreenUpNextTitle">
                    {!expertDetails || !expertDetails.nylasCalendarId
                      ? 'Link your calendar'
                      : 'Set up scheduling link'}
                  </div>
                  <div className="HomeScreenUpNextSubTitle HomeScreenUpNextSubTitleNoEllipsis">
                    {!expertDetails || !expertDetails.nylasCalendarId
                      ? 'Sync your personal calendar with your Storetasker client meetings.'
                      : 'Similar to Calendly, clients can book time on your calendar to meet.'}
                  </div>
                  <div className="HomeScreenUpNextRightCorner">Profile</div>
                </Link>
                <div className="HomeScreenUpNextFooter">
                  <div className="HomeScreenUpNextFooterText">Today</div>
                </div>
              </div>
            )}
            {upNextCombined.map((m) => {
              if (m.__typename === 'Meeting') {
                let linkDestination = '';
                if (m.supportChannelStr) {
                  linkDestination = '/support';
                } else if (m.matchStr) {
                  linkDestination = '/clients/' + m.matchStr;
                } else if (m.orphanStr) {
                  linkDestination = '/inbound/' + m.orphanStr;
                }
                let eventDateStr = '';
                if (m.eventStart) {
                  const nowMoment = moment.tz(
                    initTime,
                    m.eventTimezone || moment.tz.guess(),
                  );
                  const eventMoment = moment.tz(
                    m.eventStart,
                    m.eventTimezone || moment.tz.guess(),
                  );
                  let dayStr = eventMoment.format('MMMM Do');
                  if (nowMoment.isSame(eventMoment, 'day')) {
                    dayStr = 'Today';
                  } else if (
                    nowMoment.clone().add(1, 'day').isSame(eventMoment, 'day')
                  ) {
                    dayStr = 'Tomorrow';
                  } else if (
                    nowMoment.isSame(eventMoment, 'week') &&
                    m.eventStart > initTime
                  ) {
                    dayStr = eventMoment.format('dddd');
                  }
                  const timeStr = m.eventAllDay
                    ? ', all day'
                    : ' at ' + eventMoment.format('h:mma z');
                  eventDateStr = dayStr + timeStr;
                }
                let highLevelStatus =
                  m.eventStatus === 'ACTIVE' ? m.expertResponse : 'CANCELED';
                if (m.organizerExpert) {
                  if (
                    m.participantHumansStatus.find(
                      (p) => p.status === 'ACCEPTED',
                    )
                  ) {
                    highLevelStatus = 'ACCEPTED';
                  } else if (
                    m.participantHumansStatus.find(
                      (p) => p.status === 'DECLINED',
                    )
                  ) {
                    highLevelStatus = 'DECLINED';
                  } else {
                    highLevelStatus = 'NEEDS-ACTION';
                  }
                }
                return (
                  <div
                    key={m.id}
                    className="HomeScreenUpNext HomeScreenUpNextMeeting"
                  >
                    {m.expertResponse === 'NEEDS-ACTION' && (
                      <div className="HomeScreenUpNextUnreadTag">new!</div>
                    )}
                    <Link to={linkDestination} className="HomeScreenUpNextBody">
                      <div className="HomeScreenUpNextTitle">
                        {m.eventTitle || 'Meeting'}
                      </div>
                      <div className="HomeScreenUpNextSubTitle">
                        {eventDateStr}
                      </div>
                      <div
                        className={
                          'HomeScreenUpNextStatusLine CardStatusLine' +
                          highLevelStatus
                        }
                      />
                      <div className="HomeScreenUpNextRightCorner">
                        {meetingResponseFormatted(
                          highLevelStatus,
                          !!m.organizerExpert,
                        )}
                      </div>
                    </Link>
                    <div className="HomeScreenUpNextFooter">
                      <div className="HomeScreenUpNextFooterText">
                        {m.organizerHuman && m.organizerHuman.firstName
                          ? `Meeting with ${m.organizerHuman.firstName}`
                          : 'Meeting'}
                      </div>
                    </div>
                  </div>
                );
              }
              if (m.__typename === 'Match') {
                if (m.isLead) {
                  const projectWithQuoteSent = (m.hasActiveQuotes || []).find(
                    (p) =>
                      p.quote &&
                      p.quote.cents &&
                      p.quote.status &&
                      ['CREATED', 'UPDATED'].indexOf(p.quote.status) >= 0,
                  );
                  let closeDateStr = '';
                  if (m.leadCloseTimeline) {
                    const nowMoment = moment.tz(initTime, moment.tz.guess());
                    const closeMoment = moment.tz(
                      m.leadCloseTimeline,
                      moment.tz.guess(),
                    );
                    if (nowMoment.isSame(closeMoment, 'day')) {
                      closeDateStr = 'Today';
                    } else if (m.leadCloseTimeline < initTime) {
                      closeDateStr = '';
                    } else if (
                      nowMoment.clone().add(1, 'day').isSame(closeMoment, 'day')
                    ) {
                      closeDateStr = 'Tomorrow';
                    } else if (nowMoment.isSame(closeMoment, 'week')) {
                      closeDateStr = closeMoment.format('dddd');
                    } else {
                      closeDateStr = closeMoment.format('MMMM Do');
                    }
                  }
                  return (
                    <div
                      key={m.id}
                      className="HomeScreenUpNext HomeScreenUpNextLead"
                    >
                      {m.expertUnread && (
                        <div className="HomeScreenUpNextUnreadTag">new!</div>
                      )}
                      <Link
                        to={'/clients/' + m.id}
                        className="HomeScreenUpNextBody"
                      >
                        <div className="HomeScreenUpNextTitle">
                          Lead To Close
                        </div>
                        <div className="HomeScreenUpNextSubTitle">
                          {m.brand && m.brand.name ? m.brand.name : ''}
                        </div>
                        {projectWithQuoteSent && (
                          <div className="HomeScreenUpNextLeftCorner">
                            $
                            {formatNumberWithCommas(
                              centsDollarsRounded(
                                projectWithQuoteSent.quote.cents,
                              ),
                            )}
                          </div>
                        )}
                        <div
                          className={
                            'HomeScreenUpNextStatusLine CardStatusLine' +
                            (projectWithQuoteSent ? 'CREATED' : 'CANCELED')
                          }
                        />
                        <div className="HomeScreenUpNextRightCorner">
                          {projectWithQuoteSent
                            ? 'Quote Sent'
                            : 'Needs A Quote'}
                        </div>
                      </Link>
                      <div className="HomeScreenUpNextFooter">
                        <div className="HomeScreenUpNextFooterText">
                          {closeDateStr || 'Lead'}
                        </div>
                      </div>
                    </div>
                  );
                }
                const projectWithQuoteAccepted = (
                  m.hasActiveProjects || []
                ).find(
                  (p) =>
                    p.quote &&
                    p.quote.cents &&
                    p.quote.status &&
                    [
                      'ACCEPTED',
                      'COMPLETED',
                      'REVISIONS_REQUESTED',
                      'SUBSCRIPTION_PAYMENT_MADE',
                      'IN_PROGRESS',
                    ].indexOf(p.quote.status) >= 0,
                );
                const projectWithQuoteSent = (m.hasActiveQuotes || []).find(
                  (p) =>
                    p.quote &&
                    p.quote.cents &&
                    p.quote.status &&
                    ['CREATED', 'UPDATED'].indexOf(p.quote.status) >= 0,
                );
                let projectTitleStr = '';
                let projectCentsStr = '';
                let projectDueStr = '';
                let projectStatusStr = '';
                let projectStatusLine = '';
                if (projectWithQuoteAccepted) {
                  projectTitleStr = projectWithQuoteAccepted.quote.title;
                  projectCentsStr = formatNumberWithCommas(
                    centsDollarsRounded(projectWithQuoteAccepted.quote.cents),
                  );
                  projectDueStr = paymentTypeFormatted(
                    projectWithQuoteAccepted.quote.paymentType,
                  );
                  if (projectWithQuoteAccepted.quote.estimatedCompletionDate) {
                    const nowMoment = moment.tz(initTime, moment.tz.guess());
                    const dueMoment = moment
                      .utc(
                        projectWithQuoteAccepted.quote.estimatedCompletionDate,
                      )
                      .tz('UTC');
                    if (nowMoment.isSame(dueMoment, 'day')) {
                      projectDueStr = 'Due Today';
                    } else if (dueMoment.valueOf() < nowMoment.valueOf()) {
                      projectDueStr = 'Past Deadline';
                    } else if (
                      nowMoment.clone().add(1, 'day').isSame(dueMoment, 'day')
                    ) {
                      projectDueStr = 'Due Tomorrow';
                    } else if (nowMoment.isSame(dueMoment, 'week')) {
                      projectDueStr = 'Due by ' + dueMoment.format('dddd');
                    } else {
                      projectDueStr = 'Due by ' + dueMoment.format('MMMM Do');
                    }
                  }
                  projectStatusStr = quoteStatusFormatted(
                    projectWithQuoteAccepted.quote.status,
                  );
                  projectStatusLine = projectWithQuoteAccepted.quote.status;
                } else if (projectWithQuoteSent) {
                  projectTitleStr = projectWithQuoteSent.quote.title;
                  projectCentsStr = formatNumberWithCommas(
                    centsDollarsRounded(projectWithQuoteSent.quote.cents),
                  );
                  projectDueStr = paymentTypeFormatted(
                    projectWithQuoteSent.quote.paymentType,
                  );
                  projectStatusStr =
                    projectWithQuoteSent.quote.status === QuoteStatus.Updated
                      ? 'Quote Updated'
                      : 'Quote Sent';
                  projectStatusLine = projectWithQuoteSent.quote.status;
                }
                return (
                  <div
                    key={m.id}
                    className="HomeScreenUpNext HomeScreenUpNextProject"
                  >
                    {m.expertUnread && (
                      <div className="HomeScreenUpNextUnreadTag">new!</div>
                    )}
                    <Link
                      to={'/clients/' + m.id}
                      className="HomeScreenUpNextBody"
                    >
                      <div className="HomeScreenUpNextTitle">
                        {projectTitleStr || 'Project'}
                      </div>
                      <div className="HomeScreenUpNextSubTitle">
                        {m.brand && m.brand.name ? m.brand.name : ''}
                      </div>
                      {projectCentsStr && (
                        <div className="HomeScreenUpNextLeftCorner">
                          ${projectCentsStr}
                        </div>
                      )}
                      <div
                        className={
                          'HomeScreenUpNextStatusLine CardStatusLine' +
                          projectStatusLine
                        }
                      />
                      <div className="HomeScreenUpNextRightCorner">
                        {projectStatusStr}
                      </div>
                    </Link>
                    <div className="HomeScreenUpNextFooter">
                      <div className="HomeScreenUpNextFooterText">
                        {projectDueStr || 'Project'}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            {hasNoLeads && (
              <div className="HomeScreenUpNext HomeScreenUpNextQueue">
                <div className="HomeScreenUpNextUnreadTag">new!</div>
                <Link to="/leads" className="HomeScreenUpNextBody">
                  <div className="HomeScreenUpNextTitle">Find more clients</div>
                  <div className="HomeScreenUpNextSubTitle HomeScreenUpNextSubTitleNoEllipsis">
                    There&apos;s {queueCount} new project
                    {queueCount > 1 ? 's' : ''} available in the queue right
                    now.
                  </div>
                  <div className="HomeScreenUpNextRightCorner">Leads</div>
                </Link>
                <div className="HomeScreenUpNextFooter">
                  <div className="HomeScreenUpNextFooterText">Today</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ExpertHomescreen.propTypes = {
  alertCount: PropTypes.number.isRequired,
  alertOldCount: PropTypes.number.isRequired,
  dzCount: PropTypes.number.isRequired,
  dzProjectCount: PropTypes.number.isRequired,
  expertDetails: PropTypes.object,
  expertId: PropTypes.string.isRequired,
  queueCount: PropTypes.number.isRequired,
  socketClient: PropTypes.object.isRequired,
};

ExpertHomescreen.defaultProps = {
  expertDetails: null,
};

export default ExpertHomescreen;
