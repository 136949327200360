import { useState, useMemo } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import '../../styles/feature/PhoneNumberInput.scss';

interface PhoneNumberInputProps {
  className?: string;
  onBlur: (isValid: boolean) => void;
  onChange: (phone: string, isValid: boolean) => void;
  value: string;
  readOnly?: boolean;
}

const countryOptionsOrder = [
  'US',
  'CA',
  'GB',
  'AU',
  '|',
  '...',
] as CountryCode[];

const PhoneNumberInput = ({
  className,
  onChange,
  onBlur,
  value,
  readOnly,
}: PhoneNumberInputProps) => {
  let countryCode = 'US';
  const phoneNumber = useMemo(
    () => parsePhoneNumberFromString(value || ''),
    [value],
  );
  if (phoneNumber) {
    countryCode = phoneNumber.country || 'US';
  }
  const [defaultCountry] = useState(countryCode);
  function onPhoneNumberChange(phone: string) {
    onChange(phone || '', isValidPhoneNumber(phone || ''));
  }
  function onInputBlur() {
    onBlur(isValidPhoneNumber(value || ''));
  }
  if (readOnly) {
    return (
      <input
        className="UnknownHumanFormInputField UnknownHumanFormInputFieldReadOnly"
        type="text"
        autoComplete="new-off"
        spellCheck="false"
        readOnly
        placeholder="987-654-3210"
        value={value}
      />
    );
  }
  return (
    <PhoneInput
      countryOptionsOrder={countryOptionsOrder}
      defaultCountry={defaultCountry as CountryCode}
      countrySelectProps={{ unicodeFlags: true }}
      displayInitialValueAsLocalNumber
      value={value}
      onChange={onPhoneNumberChange}
      className={'PhoneNumberInput ' + (className || '')}
      autoComplete="new-off"
      placeholder="987-654-3210"
      onBlur={onInputBlur}
    />
  );
};

PhoneNumberInput.propTypes = {
  className: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

PhoneNumberInput.defaultProps = {
  className: '',
  readOnly: false,
};

export default PhoneNumberInput;
