import { useState, useContext } from 'react';
import { gql, useMutation, ApolloError } from '@apollo/client';
import { AdminPublishWebsiteMutation } from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';

const adminPublishWebsiteMutation = gql`
  mutation AdminPublishWebsite {
    adminPublishWebsite
  }
`;

const AdminPublish = () => {
  const [isPublishing, setIsPublishing] = useState(false);
  const { addNotification } = useContext(GlobalNotificationContext);
  const [tryPublishWebsite] = useMutation<AdminPublishWebsiteMutation>(
    adminPublishWebsiteMutation,
  );
  function startPublish() {
    if (isPublishing) {
      return;
    }
    setIsPublishing(true);
    tryPublishWebsite()
      .then(({ data: mutationData }) => {
        setIsPublishing(false);
        if (mutationData) {
          if (!mutationData.adminPublishWebsite) {
            addNotification('Website publish failed, let Sam know');
          }
          addNotification(
            'Website published, this should take 5-10 minutes',
            'GOOD',
            5000,
          );
        }
      })
      .catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Website Publish Error',
        );
        logError(err, {
          component: 'AdminPublish',
          func: 'tryPublishWebsite',
        });
      });
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div className="DashboardScreenContent ClientsScreenContent">
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Publish Website</div>
          <div className="DashboardSectionEmpty">
            This publishes the entire site for either staging or production
          </div>
          <div
            className={
              'DashboardSectionOnlyButton ' +
              (isPublishing ? ' DashboardSectionOnlyButtonLoading ' : '')
            }
            onClick={startPublish}
          >
            Publish Now
          </div>
        </div>
      </div>
    </div>
  );
};

AdminPublish.propTypes = {};

export default AdminPublish;
