import { useState, useContext } from 'react';
import { gql, useMutation, ApolloError } from '@apollo/client';
import { convertToRaw, ContentState } from 'draft-js';
import PropTypes from 'prop-types';
import RichTextEditor from '../feature/RichTextEditor';
import {
  TemplatesQuery,
  TemplateCreateAdminMutation,
  TemplateCreateAdminMutationVariables,
  TemplateEditAdminMutation,
  TemplateEditAdminMutationVariables,
  TemplateDeleteAdminMutation,
  TemplateDeleteAdminMutationVariables,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { TemplateSummary } from '../../utils/gql';
import templateUtils from '../../utils/template';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';

const templateCreateAdminMutation = gql`
  mutation TemplateCreateAdmin(
    $title: String!
    $description: String!
    $isQuote: Boolean!
  ) {
    templateCreate(
      title: $title
      description: $description
      isQuote: $isQuote
    ) {
      ...TemplateSummary
    }
  }
  ${TemplateSummary}
`;
const templateEditAdminMutation = gql`
  mutation TemplateEditAdmin(
    $templateId: ID!
    $title: String!
    $description: String!
  ) {
    templateEdit(
      templateId: $templateId
      title: $title
      description: $description
    ) {
      ...TemplateSummary
    }
  }
  ${TemplateSummary}
`;
const templateDeleteAdminMutation = gql`
  mutation TemplateDeleteAdmin($templateId: ID!) {
    templateDelete(templateId: $templateId)
  }
`;

interface AdminTemplatesProps {
  emailTemplates: TemplatesQuery['templates'];
  refetchTemplates: () => void;
}

const AdminTemplates = ({
  emailTemplates,
  refetchTemplates,
}: AdminTemplatesProps) => {
  const [actionLoading, setActionLoading] = useState(false);
  const [editTemplate, setEditTemplate] = useState('');
  const [templateInitDesc, setTemplateInitDesc] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');
  const { addNotification } = useContext(GlobalNotificationContext);
  const [templateDescription, setTemplateDescription] =
    useState<ContentState | null>(null);
  const [tryCreateTemplate] = useMutation<
    TemplateCreateAdminMutation,
    TemplateCreateAdminMutationVariables
  >(templateCreateAdminMutation);
  const [tryEditTemplate] = useMutation<
    TemplateEditAdminMutation,
    TemplateEditAdminMutationVariables
  >(templateEditAdminMutation);
  const [tryDeleteTemplate] = useMutation<
    TemplateDeleteAdminMutation,
    TemplateDeleteAdminMutationVariables
  >(templateDeleteAdminMutation);
  function openTemplateEditor(templateId: string) {
    if (templateId === 'new') {
      setTemplateTitle('');
      setTemplateDescription(null);
      setTemplateInitDesc(
        templateUtils.getDefaultEmail([], {
          firstName: 'Storetasker Support',
          lastName: '',
        }),
      );
    } else {
      const template = emailTemplates.find((p) => p.id === templateId);
      if (!template) {
        return;
      }
      setTemplateTitle(template.title || '');
      setTemplateInitDesc(template.description || '');
    }
    setEditTemplate(templateId);
  }
  function deleteTemplate(templateId: string) {
    if (actionLoading) return;
    const template = emailTemplates.find((p) => p.id === templateId);
    if (!template) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryDeleteTemplate({
      variables: {
        templateId,
      },
    })
      .then(() => {
        setActionLoading(false);
        refetchTemplates();
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Delete Template Error',
        );
        logError(err, {
          component: 'ExpertProfileView',
          func: 'tryDeleteTemplate',
        });
      });
  }
  function templateSubmit() {
    if (actionLoading) return;
    let draftContent = '';
    let plainTextContent = '';
    if (templateDescription && templateDescription.hasText()) {
      plainTextContent = templateDescription.getPlainText().trim();
      if (plainTextContent.replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(templateDescription));
        } catch (rawError) {
          console.log('templateDescription convertToRaw error', rawError);
        }
      }
    }
    if (!templateTitle.trim() || !draftContent) {
      addNotification(
        'Please submit a title and a description.',
        undefined,
        5000,
      );
      return;
    }
    if (editTemplate === 'new') {
      setActionLoading(true);
      tryCreateTemplate({
        variables: {
          description: draftContent,
          isQuote: false,
          title: templateTitle,
        },
      })
        .then(() => {
          setActionLoading(false);
          setEditTemplate('');
          refetchTemplates();
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Create Template Error',
          );
          logError(err, {
            component: 'ExpertProfileView',
            func: 'tryCreateTemplate',
          });
        });
    } else {
      const template = emailTemplates.find((p) => p.id === editTemplate);
      if (!template) {
        addNotification(
          'An error occured, please refresh and try again.',
          undefined,
          5000,
        );
        return;
      }
      setActionLoading(true);
      tryEditTemplate({
        optimisticResponse: {
          templateEdit: {
            ...template,
            description: draftContent,
            title: templateTitle,
          },
        },
        variables: {
          description: draftContent,
          templateId: editTemplate,
          title: templateTitle,
        },
      })
        .then(() => {
          setActionLoading(false);
          setEditTemplate('');
          refetchTemplates();
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Edit Template Error',
          );
          logError(err, {
            component: 'ExpertProfileView',
            func: 'tryEditTemplate',
          });
        });
    }
  }
  function closeTemplateEditor() {
    setTemplateTitle('');
    setTemplateDescription(null);
    setTemplateInitDesc('');
    setEditTemplate('');
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div className="DashboardScreenContent ClientsScreenContent">
        <div className="ExpertProfilePanel">
          <div className="ExpertProfileSection">
            <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
              Email Templates
            </div>
            <div className="ExpertProfileSectionAction">
              <div
                className="ExpertProfileSectionActionBtn"
                onClick={() => openTemplateEditor('new')}
              >
                Create Email
              </div>
            </div>
            {!emailTemplates.length && (
              <div className="ExpertProfileInfo">
                <div className="ExpertProfileInfoTitle">
                  No email templates found
                </div>
                <div className="ExpertProfileInfoDesc">
                  Email templates are shared across all admin users.
                </div>
              </div>
            )}
            <div className="ExpertProfileServices">
              {emailTemplates.map((p) => (
                <div key={p.id} className="ExpertProfileService">
                  <div
                    className="ExpertProfileServiceCard"
                    onClick={() => openTemplateEditor(p.id)}
                  >
                    <div className="ExpertProfileServiceTitle">{p.title}</div>
                  </div>
                  <div className="ExpertProfileServiceFooter">
                    <div
                      className="ExpertProfileServiceFooterLeft"
                      onClick={() => openTemplateEditor(p.id)}
                    >
                      Edit
                    </div>
                    <div
                      className="ExpertProfileServiceFooterLeftNext"
                      onClick={() => deleteTemplate(p.id)}
                    >
                      Delete
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {!!editTemplate && (
            <div className="ExpertProfileModal">
              <div
                className="ExpertProfileModalCover"
                onClick={closeTemplateEditor}
              />
              <div className="ExpertProfileModalContainer">
                <div className="ExpertProfileModalContainerBody ExpertProfileModalContainerBodyEditTemplate">
                  <div className="ExpertProfileModalContainerTitle">
                    {editTemplate === 'new' ? 'Create' : 'Edit'} Email Template
                  </div>
                  <div className="ExpertProfileModalForm">
                    <div className="ExpertProfileModalFormField">
                      <div className="ExpertProfileModalFormFieldTitle">
                        Title
                      </div>
                      <div className="ExpertProfileModalFormFieldInput">
                        <input
                          type="text"
                          autoComplete="new-off"
                          spellCheck="true"
                          placeholder="Title"
                          value={templateTitle}
                          onChange={(e) =>
                            setTemplateTitle(e.currentTarget.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="ExpertProfileModalFormField">
                      <div className="ExpertProfileModalFormFieldTitle">
                        Description
                      </div>
                      <div className="ExpertProfileModalFormFieldRich">
                        <RichTextEditor
                          initContent={templateInitDesc}
                          placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                          onUpdated={setTemplateDescription}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        'ExpertProfileModalFormAction ' +
                        (actionLoading
                          ? ' ExpertProfileModalFormActionLoading '
                          : '')
                      }
                      onClick={templateSubmit}
                    >
                      Save
                    </div>
                  </div>
                </div>
                <div
                  className="ExpertProfileModalClose"
                  onClick={closeTemplateEditor}
                >
                  cancel
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AdminTemplates.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  refetchTemplates: PropTypes.func.isRequired,
};

export default AdminTemplates;
