import { ApolloError } from '@apollo/client';

const getErrorMessage = (error: ApolloError) => {
  if (!error) return '';
  if (
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].message
  )
    return error.graphQLErrors[0].message;
  // if (error.response && error.response.data && error.response.data.message)
  //   return error.response.data.message;
  return error.message || '';
};

export default {
  getErrorMessage,
};
