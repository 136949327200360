import React, {
  useState,
  useRef,
  useContext,
  Fragment,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import { useQuery, gql, useMutation, ApolloError } from '@apollo/client';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { Link, useHistory } from 'react-router-dom';
import {
  SupportChannelUnreadForAdminFullQuery,
  ExpertSearchForAdminMutation,
  ExpertSearchForAdminMutationVariables,
  AdminCreateExpertMutation,
  AdminCreateExpertMutationVariables,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import {
  SupportChannelSummary,
  ExpertSummary,
  ExpertDetailHq,
} from '../../utils/gql';
import PhoneNumberInput from '../feature/PhoneNumberInput';
import { unreadReasonFormatted } from '../../utils/format';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';

const supportChannelUnreadForAdminFullQuery = gql`
  query SupportChannelUnreadForAdminFull {
    supportChannelUnreadForAdmin {
      ...SupportChannelSummary
    }
  }
  ${SupportChannelSummary}
`;

const expertSearchForAdminMutation = gql`
  mutation ExpertSearchForAdmin($searchQuery: String!) {
    expertSearchForAdmin(searchQuery: $searchQuery) {
      ...ExpertSummary
    }
  }
  ${ExpertSummary}
`;

const adminCreateExpertMutation = gql`
  mutation AdminCreateExpert(
    $personalEmail: String!
    $publicEmail: String!
    $firstName: String!
    $lastName: String!
    $profilePath: String!
    $locationTimezone: String!
    $personalPhone: String
    $entityType: String!
    $startDate: Date!
    $companyName: String
  ) {
    adminCreateExpert(
      personalEmail: $personalEmail
      publicEmail: $publicEmail
      firstName: $firstName
      lastName: $lastName
      profilePath: $profilePath
      locationTimezone: $locationTimezone
      personalPhone: $personalPhone
      startDate: $startDate
      entityType: $entityType
      companyName: $companyName
    ) {
      ...ExpertDetailHq
    }
  }
  ${ExpertDetailHq}
`;

interface AdminExpertsProps {
  socketClient: ClientWithOnReconnected;
}

const initTime = new Date().getTime();

const AdminExperts = ({ socketClient }: AdminExpertsProps) => {
  const history = useHistory();
  const searchRef = useRef<HTMLInputElement>(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    ExpertSearchForAdminMutation['expertSearchForAdmin'] | null
  >(null);
  const [dateFocused, setDateFocused] = useState(false);
  const [newExpertFirst, setNewExpertFirst] = useState('');
  const [newExpertLast, setNewExpertLast] = useState('');
  const [newExpertProfilePath, setNewExpertProfilePath] = useState('');
  const [newExpertLocationTimezone, setNewExpertLocationTimezone] =
    useState('');
  const [newExpertPublicEmail, setNewExpertPublicEmail] = useState('');
  const [newExpertPersonalEmail, setNewExpertPersonalEmail] = useState('');
  const [newExpertPersonalPhone, setNewExpertPersonalPhone] = useState('');
  const [newExpertPersonalPhoneValid, setNewExpertPersonalPhoneValid] =
    useState(true);
  const [newExpertStartDate, setNewExpertStartDate] =
    useState<moment.Moment | null>(() => moment.utc().startOf('day'));
  const [newExpertEntityType, setNewExpertEntityType] = useState('individual');
  const [newExpertCompanyName, setNewExpertCompanyName] = useState('');
  const timezones = useMemo(
    () =>
      moment.tz
        .names()
        .map((tz) => ({ offset: moment.tz(tz).utcOffset(), tz }))
        .sort((a, b) => a.offset - b.offset),
    [],
  );
  const { addNotification } = useContext(GlobalNotificationContext);
  const {
    data: dataSupportChannels,
    error: errorSupportChannels,
    loading: loadingSupportChannels,
    refetch: refetchSupportChannels,
  } = useQuery<SupportChannelUnreadForAdminFullQuery>(
    supportChannelUnreadForAdminFullQuery,
    {
      returnPartialData: true,
    },
  );
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminExperts socketClient onReconnected');
      refetchSupportChannels().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchSupportChannels]);
  // combine all data, dedup, filter, sort into 4 groups, render
  const unreadSupportChannels = _.uniqBy(
    [
      ...((dataSupportChannels &&
        dataSupportChannels.supportChannelUnreadForAdmin) ||
        []),
    ].filter(
      (m) =>
        m.adminUnread &&
        (!m.adminSnoozedUntil || m.adminSnoozedUntil <= initTime),
    ),
    'id',
  ).sort((a, b) => a.lastUpdated - b.lastUpdated);
  const [tryExpertCreateAdmin] = useMutation<
    AdminCreateExpertMutation,
    AdminCreateExpertMutationVariables
  >(adminCreateExpertMutation);
  function saveNewExpert() {
    if (actionLoading) return;
    if (
      !newExpertFirst ||
      !newExpertLast ||
      !newExpertProfilePath ||
      !newExpertPersonalEmail ||
      !newExpertPublicEmail ||
      !newExpertEntityType ||
      !newExpertLocationTimezone ||
      !newExpertPersonalPhoneValid ||
      !newExpertStartDate
    ) {
      addNotification('Please submit all the fields!', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryExpertCreateAdmin({
      variables: {
        companyName: newExpertCompanyName,
        entityType: newExpertEntityType,
        firstName: newExpertFirst,
        lastName: newExpertLast,
        locationTimezone: newExpertLocationTimezone,
        personalEmail: newExpertPersonalEmail,
        personalPhone: newExpertPersonalPhone,
        profilePath: newExpertProfilePath.replace('/', ''),
        publicEmail: newExpertPublicEmail,
        startDate: newExpertStartDate.utc().startOf('day').valueOf(),
      },
    })
      .then(({ data: mutationData }) => {
        setActionLoading(false);
        setNewExpertFirst('');
        setNewExpertLast('');
        setNewExpertProfilePath('');
        setNewExpertLocationTimezone('');
        setNewExpertPersonalEmail('');
        setNewExpertPublicEmail('');
        setNewExpertPersonalPhone('');
        setNewExpertCompanyName('');
        setNewExpertStartDate(moment.utc().startOf('day'));
        setNewExpertEntityType('individual');
        if (mutationData && mutationData.adminCreateExpert) {
          history.push('/experts/' + mutationData.adminCreateExpert.id);
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Expert Create Error',
        );
        logError(err, {
          component: 'AdminExperts',
          func: 'tryExpertCreateAdmin',
        });
      });
  }
  const [tryExpertSearch] = useMutation<
    ExpertSearchForAdminMutation,
    ExpertSearchForAdminMutationVariables
  >(expertSearchForAdminMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput) {
      toggleSearch();
      return;
    }
    if (searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchResults([]);
    tryExpertSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        setSearchLoading(false);
        if (dataMatchSearch && dataMatchSearch.expertSearchForAdmin) {
          console.log('tryExpertSearch', dataMatchSearch.expertSearchForAdmin);
          setSearchResults(dataMatchSearch.expertSearchForAdmin);
        }
      },
      variables: {
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Expert Search Error');
      logError(err, {
        component: 'AdminExperts',
        func: 'tryExpertSearch',
      });
      setSearchLoading(false);
      setSearchResults(null);
    });
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      toggleSearch();
    }
  }
  function toggleSearch() {
    const nextShowSearch = !showSearch;
    setSearchInput('');
    setSearchResults(null);
    setSearchLoading(false);
    setShowSearch(nextShowSearch);
    if (nextShowSearch) {
      setTimeout(() => {
        if (searchRef && searchRef.current) {
          searchRef.current.focus();
        }
      });
    }
  }
  function phoneNumberChange(phone: string, isValid: boolean) {
    setNewExpertPersonalPhone(phone);
    if (!phone || isValid) {
      setNewExpertPersonalPhoneValid(true);
    }
  }
  function phoneNumberBlur(isValid: boolean) {
    setNewExpertPersonalPhoneValid(!newExpertPersonalPhone || isValid);
  }
  if (loadingSupportChannels) {
    // ignore these
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div
        className={
          'ClientsScreenSearch ClientsScreenSearch' +
          (showSearch ? 'Active ' : '')
        }
      >
        <div className="ClientsScreenSearchBar">
          <input
            type="text"
            ref={searchRef}
            className="ClientsScreenSearchBarInput"
            autoComplete="new-off"
            spellCheck="false"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleSearchInputKeyDown}
          />
          <div className="ClientsScreenSearchToggle" onClick={toggleSearch}>
            Search Experts
          </div>
        </div>
      </div>
      <div className="DashboardScreenContent ClientsScreenContent">
        {!searchLoading && searchResults === null ? (
          <Fragment>
            <div className="DashboardSection">
              <div className="DashboardSectionHeader">Unread Messages</div>
              {!unreadSupportChannels.length && (
                <div className="DashboardSectionEmpty">
                  {errorSupportChannels
                    ? errorUtils.getErrorMessage(errorSupportChannels) ||
                      'Loading Error'
                    : 'New messages from experts to Support will show up here.'}
                </div>
              )}
              <div className="DashboardSectionScrollerSwitch">
                {unreadSupportChannels.map((sc) => (
                  <div key={sc.id} className="ClientSummary ClientSummaryAlert">
                    <Link
                      to={'/experts/' + sc.expertStr}
                      className={
                        'ClientSummaryBody ' +
                        (sc.adminAssigned ? ' ClientSummaryBodyExtended ' : '')
                      }
                    >
                      <div className="ClientSummaryTitle">
                        {sc.expert
                          ? (
                              (sc.expert.firstName || '') +
                              ' ' +
                              (sc.expert.lastName || '')
                            ).trim()
                          : ''}
                      </div>
                      <div className="ClientSummarySubTitle">
                        {unreadReasonFormatted(sc.adminUnread)}
                      </div>
                      {sc.adminAssigned && (
                        <div
                          className={
                            'ClientSummaryAssignedTo ClientSummaryAssignedTo' +
                            (sc.adminAssigned || '').toUpperCase()
                          }
                        >
                          {sc.adminAssigned}
                        </div>
                      )}
                      {sc.expert && (
                        <div
                          className={
                            'ClientSummaryStatusTag ClientSummaryStatusTag' +
                            (sc.expert.status || '').toUpperCase()
                          }
                        >
                          {sc.expert.status}
                        </div>
                      )}
                    </Link>
                    <div className="ClientSummaryFooter" />
                  </div>
                ))}
              </div>
            </div>
            <div className="AdminDashboardForm">
              <div className="AdminDashboardFormTitle">
                Create a new expert?
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">First Name</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="First Name"
                  value={newExpertFirst}
                  onChange={(e) => setNewExpertFirst(e.currentTarget.value)}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Last Name</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Last Name"
                  value={newExpertLast}
                  onChange={(e) => setNewExpertLast(e.currentTarget.value)}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">
                  Personal Email Address
                </div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="email"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="joe.mello@gmail.com"
                  value={newExpertPersonalEmail}
                  onChange={(e) =>
                    setNewExpertPersonalEmail(e.currentTarget.value)
                  }
                />
              </div>
              <div
                className={
                  'AdminDashboardFormField ' +
                  (!newExpertPersonalPhoneValid
                    ? ' AdminDashboardFormFieldInvalid '
                    : '')
                }
              >
                <div className="AdminDashboardFormFieldTitle">
                  Personal Phone Number
                </div>
                <PhoneNumberInput
                  className="AdminDashboardFormFieldInput"
                  value={newExpertPersonalPhone}
                  onChange={phoneNumberChange}
                  onBlur={phoneNumberBlur}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">
                  Profile Unique Path (joe-mello)
                </div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="joe-mello"
                  value={newExpertProfilePath}
                  onChange={(e) =>
                    setNewExpertProfilePath(e.currentTarget.value)
                  }
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">
                  Public Email Address
                </div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="email"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="joe.mello@storetasker.com"
                  value={newExpertPublicEmail}
                  onChange={(e) =>
                    setNewExpertPublicEmail(e.currentTarget.value)
                  }
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Timezone</div>
                <select
                  className="AdminDashboardFormFieldInput"
                  required
                  value={newExpertLocationTimezone}
                  onChange={(e) =>
                    setNewExpertLocationTimezone(e.currentTarget.value)
                  }
                >
                  <option value="" disabled>
                    Timezone
                  </option>
                  {timezones.map((tzObj) => (
                    <option key={tzObj.tz} value={tzObj.tz}>
                      (GMT{moment.tz(tzObj.tz).format('Z')}) {tzObj.tz}
                    </option>
                  ))}
                </select>
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Start Date</div>
                <div className="AdminDashboardFormFieldDatePicker">
                  <SingleDatePicker
                    date={newExpertStartDate}
                    onDateChange={(date) => {
                      setNewExpertStartDate(date);
                    }}
                    focused={dateFocused}
                    onFocusChange={({ focused }) =>
                      setDateFocused(focused || false)
                    }
                    showClearDate={false}
                    displayFormat="MMM Do"
                    isOutsideRange={() => false}
                    small
                    noBorder
                    hideKeyboardShortcutsPanel
                    numberOfMonths={1}
                    withPortal
                    id="uniqueId"
                    placeholder="Jan 4th"
                  />
                </div>
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Entity Type</div>
                <select
                  className="AdminDashboardFormFieldInput"
                  required
                  value={newExpertEntityType}
                  onChange={(e) =>
                    setNewExpertEntityType(e.currentTarget.value)
                  }
                >
                  <option value="individual">Individual</option>
                  <option value="business">Business</option>
                </select>
              </div>
              {newExpertEntityType === 'business' && (
                <div className="AdminDashboardFormField">
                  <div className="AdminDashboardFormFieldTitle">
                    Company Name
                  </div>
                  <input
                    className="AdminDashboardFormFieldInput"
                    type="text"
                    autoComplete="new-off"
                    spellCheck="false"
                    placeholder="Company Name"
                    value={newExpertCompanyName}
                    onChange={(e) =>
                      setNewExpertCompanyName(e.currentTarget.value)
                    }
                  />
                </div>
              )}
              <div
                className={
                  'AdminDashboardFormAction ' +
                  (actionLoading ? ' AdminDashboardFormActionLoading ' : '')
                }
                onClick={saveNewExpert}
              >
                Create
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="DashboardSection">
            <div className="DashboardSectionHeader">Search Results</div>
            {!searchLoading && searchResults && !searchResults.length && (
              <div className="DashboardSectionEmpty">
                No experts found. Try another search query to find who
                you&apos;re looking for.
              </div>
            )}
            <div className="DashboardSectionRows">
              {!searchLoading &&
                searchResults &&
                !!searchResults.length &&
                searchResults.map((expertResult) => (
                  <div
                    key={expertResult.id}
                    className="ClientSummary ClientSummarySearch"
                  >
                    <Link
                      to={'/experts/' + expertResult.id}
                      className="ClientSummaryBody"
                    >
                      <div className="ClientSummaryTitle">
                        {(
                          (expertResult.firstName || '') +
                          ' ' +
                          (expertResult.lastName || '')
                        ).trim()}
                      </div>
                      <div className="ClientSummarySubTitle">
                        {expertResult.primaryPublicEmail || ''}
                      </div>
                    </Link>
                    <div className="ClientSummaryFooter" />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

AdminExperts.propTypes = {
  socketClient: PropTypes.object.isRequired,
};

export default AdminExperts;
