import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import HumanUserBubble from '../feature/HumanUserBubble';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import DangerousHTML from '../feature/DangerousHTML';
import QuoteAttachedThreadEvent from './QuoteAttachedThreadEvent';
import { sendingStatusFormatted } from '../../utils/format';
import { MatchThreadPaginatedQuery } from '../../gql/graphql';

interface EmailMessageThreadEventProps {
  className?: string;
  emailMessageEvent: Extract<
    MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
    { __typename?: 'EmailMessageEvent' | undefined }
  >;
  expertId: string;
  onReplyClick?: () => void;
  onUnthreadEmail?: (emailMessageEventId: string) => void;
  threadEventId: string;
  threadEventTimestamp: number;
  hasUnknownHuman?: boolean;
  isUnthreadLoading?: boolean;
  isAdmin?: boolean;
}
const initTime = new Date().getTime();

const EmailMessageThreadEvent = ({
  className,
  emailMessageEvent,
  expertId,
  onReplyClick,
  onUnthreadEmail,
  threadEventTimestamp,
  hasUnknownHuman,
  isUnthreadLoading,
  isAdmin,
}: EmailMessageThreadEventProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const [expanded, setExpanded] = useState(false);
  const [showUnthreadOption, setShowUnthreadOption] = useState(false);
  const nowMoment = moment.tz(initTime, moment.tz.guess());
  const eventMoment = moment.tz(threadEventTimestamp, moment.tz.guess());
  // January 4th, 2020 at 5:37pm PST
  const eventDateFull =
    eventMoment.format('MMMM Do, YYYY') +
    ' at ' +
    eventMoment.format('h:mma z');
  let eventDateSummary = eventMoment.format('M/D/YY');
  if (nowMoment.isSame(eventMoment, 'day')) {
    eventDateSummary = eventMoment.format('h:mma');
  } else if (nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')) {
    eventDateSummary = 'Yesterday';
  } else if (nowMoment.isSame(eventMoment, 'week')) {
    eventDateSummary = eventMoment.format('dddd');
  } else if (nowMoment.isSame(eventMoment, 'year')) {
    eventDateSummary = eventMoment.format('MMM Do');
  }
  const isSolo =
    emailMessageEvent.ownerHuman &&
    (emailMessageEvent.ownerHuman.firstName ||
      emailMessageEvent.ownerHuman.lastName) &&
    (!emailMessageEvent.recipientHumans ||
      !emailMessageEvent.recipientHumans.length) &&
    (!isAdmin ||
      !emailMessageEvent.noExpertRecipientsFound ||
      !emailMessageEvent.noExpertRecipientsFound.length) &&
    (!isAdmin ||
      !emailMessageEvent.recipientCopiedExperts ||
      !emailMessageEvent.recipientCopiedExperts.length);

  return (
    <div
      className={
        'ThreadEvent EmailMessageThreadEvent ' +
        (emailMessageEvent.ownerHuman ||
        (emailMessageEvent.ownerExpert &&
          emailMessageEvent.recipientExpert &&
          emailMessageEvent.ownerExpert.id !== expertId)
          ? ' ThreadEventOwnerHuman '
          : ' ThreadEventOwnerExpert ') +
        (isSolo ? ' ThreadEventSolo ' : '') +
        (hasUnknownHuman ? ' ThreadEventWithUnknown ' : '') +
        (className || '')
      }
    >
      <div className="ThreadEventWrapper">
        <div className="ThreadEventTop">
          <div className="ThreadEventTopBubbles">
            {emailMessageEvent.ownerHuman && (
              <HumanUserBubble
                firstName={
                  emailMessageEvent.ownerHuman &&
                  emailMessageEvent.ownerHuman.firstName
                }
                lastName={
                  emailMessageEvent.ownerHuman &&
                  emailMessageEvent.ownerHuman.lastName
                }
                fallbackEmail={
                  emailMessageEvent.ownerHuman &&
                  emailMessageEvent.ownerHuman.primaryEmail
                }
                primary
              />
            )}
            {emailMessageEvent.recipientHumans.map((r) => (
              <HumanUserBubble
                key={r.id}
                firstName={r.firstName}
                lastName={r.lastName}
                fallbackEmail={r.primaryEmail}
              />
            ))}
            {emailMessageEvent.ownerExpert &&
              emailMessageEvent.recipientExpert && (
                <ExpertUserBubble
                  expertId={
                    emailMessageEvent.ownerExpert.id === expertId
                      ? emailMessageEvent.recipientExpert.id
                      : emailMessageEvent.ownerExpert.id
                  }
                  expertDetails={
                    emailMessageEvent.ownerExpert.id === expertId
                      ? emailMessageEvent.recipientExpert
                      : emailMessageEvent.ownerExpert
                  }
                  primary={emailMessageEvent.ownerExpert.id !== expertId}
                />
              )}
            {isAdmin &&
              emailMessageEvent.noExpertRecipientsFound &&
              !!emailMessageEvent.noExpertRecipientsFound.length &&
              emailMessageEvent.noExpertRecipientsFound.map((rStr) => (
                <div
                  key={rStr}
                  className="UserBubble ExpertUserBubble UserBubbleFallback UserBubbleError UserBubbleInitials"
                >
                  {rStr}
                </div>
              ))}
            {isAdmin &&
              emailMessageEvent.recipientCopiedExperts &&
              !!emailMessageEvent.recipientCopiedExperts.length &&
              emailMessageEvent.recipientCopiedExperts.map((e) => (
                <ExpertUserBubble
                  key={e.id}
                  expertId={e.id}
                  expertDetails={e}
                />
              ))}
          </div>
          <div className="ThreadEventTopTitle">
            {emailMessageEvent.subject
              ? emailMessageEvent.subject
              : 'No Subject'}
          </div>
          <div className="ThreadEventTopDate" data-tip={eventDateFull}>
            {eventDateSummary}
          </div>
        </div>
        <div className="ThreadEventCardWrapper">
          <div className="ThreadEventCardLeft">
            {emailMessageEvent.ownerHuman && (
              <div className="ThreadEventCardLeftUser">
                <HumanUserBubble
                  firstName={
                    emailMessageEvent.ownerHuman &&
                    emailMessageEvent.ownerHuman.firstName
                  }
                  lastName={
                    emailMessageEvent.ownerHuman &&
                    emailMessageEvent.ownerHuman.lastName
                  }
                  primary
                />
              </div>
            )}
          </div>
          <div className="ThreadEventCard">
            <div
              className="ThreadEventCardBody"
              onClick={() => showUnthreadOption && setShowUnthreadOption(false)}
            >
              {emailMessageEvent.contentExpanded && expanded ? (
                <DangerousHTML
                  className="ThreadEventCardBodyContent"
                  content={emailMessageEvent.contentExpanded}
                />
              ) : (
                <DangerousHTML
                  className="ThreadEventCardBodyContent"
                  content={emailMessageEvent.content}
                />
              )}
            </div>
            {!!hasUnknownHuman && (
              <div
                className={
                  'ThreadEventCardBodyUnthread ThreadEventCardBodyUnthread' +
                  (showUnthreadOption ? 'Active' : 'Inactive')
                }
              >
                <div
                  className="ThreadEventCardBodyUnthreadToggle"
                  onClick={() => setShowUnthreadOption(!showUnthreadOption)}
                />
                {!!showUnthreadOption && (
                  <div
                    className={
                      'ThreadEventCardBodyUnthreadOption ' +
                      (isUnthreadLoading
                        ? ' ThreadEventCardBodyUnthreadOptionLoading '
                        : '')
                    }
                    onClick={() =>
                      onUnthreadEmail && onUnthreadEmail(emailMessageEvent.id)
                    }
                  >
                    Unthread and move to inbound
                  </div>
                )}
              </div>
            )}
            {!!(emailMessageEvent.files && emailMessageEvent.files.length) && (
              <div
                className="ThreadEventCardFiles"
                onClick={() =>
                  showUnthreadOption && setShowUnthreadOption(false)
                }
              >
                {emailMessageEvent.files.map((f) => (
                  <a
                    key={f.id}
                    className="ThreadEventCardFile"
                    href={f.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {f.filename}
                  </a>
                ))}
              </div>
            )}
            {!!emailMessageEvent.sendingError && (
              <div className="ThreadEventCardSendingError">
                Sending Error. Please try again or get in touch with support:{' '}
                {emailMessageEvent.sendingError}
              </div>
            )}
          </div>
          <div className="ThreadEventCardRight">
            {emailMessageEvent.ownerExpert &&
              (!emailMessageEvent.recipientExpert ||
                emailMessageEvent.ownerExpert.id === expertId) && (
                <div className="ThreadEventCardRightUser">
                  <ExpertUserBubble
                    expertId={emailMessageEvent.ownerExpert.id}
                    expertDetails={emailMessageEvent.ownerExpert}
                    primary
                  />
                </div>
              )}
          </div>
        </div>
        {!!emailMessageEvent.quote && (
          <QuoteAttachedThreadEvent quote={emailMessageEvent.quote} />
        )}
        {!hasUnknownHuman && (
          <div className="ThreadEventBottom">
            {emailMessageEvent.contentExpanded && (
              <div
                className="ThreadEventBottomExpand"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? 'Collapse' : 'Show Full'} Thread
              </div>
            )}
            {!emailMessageEvent.sendingError &&
              !!emailMessageEvent.sendingStatus &&
              !!emailMessageEvent.ownerExpert &&
              !emailMessageEvent.ownerHuman &&
              !emailMessageEvent.recipientExpert && (
                <div className="ThreadEventBottomStatus">
                  {sendingStatusFormatted(emailMessageEvent.sendingStatus)}
                </div>
              )}
            {!!onReplyClick && (
              <div className="ThreadEventBottomReply" onClick={onReplyClick}>
                Reply Via Email
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

EmailMessageThreadEvent.propTypes = {
  className: PropTypes.string,
  emailMessageEvent: PropTypes.object.isRequired,
  expertId: PropTypes.string.isRequired,
  hasUnknownHuman: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isUnthreadLoading: PropTypes.bool,
  onReplyClick: PropTypes.func,
  onUnthreadEmail: PropTypes.func,
  threadEventId: PropTypes.string.isRequired,
  threadEventTimestamp: PropTypes.number.isRequired,
};

EmailMessageThreadEvent.defaultProps = {
  className: '',
  hasUnknownHuman: false,
  isAdmin: false,
  isUnthreadLoading: false,
  onReplyClick: null,
  onUnthreadEmail: () => {},
};

export default EmailMessageThreadEvent;
