import { useState, useEffect, useRef, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { convertToRaw, ContentState } from 'draft-js';
import {
  AuthHumanQuery,
  BrandForHumansQuery,
  RequestActiveForBrandsQuery,
  ExpertDetailsHumanQuery,
  ExpertDetailsHumanQueryVariables,
  ExpertDetailsManyQuery,
  ExpertDetailsManyQueryVariables,
  BrandCreatedByHumanMutation,
  BrandCreatedByHumanMutationVariables,
  RequestSubmitMutation,
  RequestSubmitMutationVariables,
  PrefabDetailsQuery,
  PrefabDetailsQueryVariables,
  ProjectFixedPrefabCreateMutation,
  ProjectFixedPrefabCreateMutationVariables,
} from '../../gql/graphql';
import urlUtils from '../../utils/url';
import {
  NRF_TEMPLATE,
  EX1_TEMPLATE,
  EX2_TEMPLATE,
  EX3_TEMPLATE,
  FOCUS_OPTIONS,
} from '../../utils/constants';
import envUtils from '../../utils/env';
import tokenUtils from '../../utils/token';
import {
  ProjectPrefabDetailHuman,
  ExpertSummary,
  // HumanSummary,
  BrandSummary,
  RequestPartialTop,
  ProjectDetail,
} from '../../utils/gql';
import { IFilestackFileUpload } from '../../utils/props';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import marketingUtils from '../../utils/marketing';
import errorUtils from '../../utils/error';
import logError from '../../utils/airbrake';
import {
  formatNumberWithCommas,
  centsDollarsRounded,
  notificationDiscountCode,
  expertFocusPosition,
  expertFocusWhat,
} from '../../utils/format';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import RichTextEditor from '../feature/RichTextEditor';
import '../../styles/page/HumanNewRequestFlow.scss';
const ReactFilestack = require('filestack-react').default; // eslint-disable-line @typescript-eslint/no-var-requires

const prefabDetailsQuery = gql`
  query PrefabDetails($prefabId: ID!) {
    prefabDetails(prefabId: $prefabId) {
      ...ProjectPrefabDetailHuman
    }
  }
  ${ProjectPrefabDetailHuman}
`;

const expertDetailsHumanQuery = gql`
  query ExpertDetailsHuman($expertId: ID!) {
    expertDetails(expertId: $expertId) {
      ...ExpertSummary
    }
  }
  ${ExpertSummary}
`;

const expertDetailsManyQuery = gql`
  query ExpertDetailsMany($expertIds: [ID!]!) {
    expertDetailsMany(expertIds: $expertIds) {
      ...ExpertSummary
    }
  }
  ${ExpertSummary}
`;

const brandCreatedByHumanMutation = gql`
  mutation BrandCreatedByHuman(
    $url: String!
    $name: String
    $isAgency: String
  ) {
    brandCreatedByHuman(url: $url, name: $name, isAgency: $isAgency) {
      ...BrandSummary
    }
  }
  ${BrandSummary}
`;

const requestSubmitMutation = gql`
  mutation RequestSubmit($input: RequestSubmitInput!) {
    requestSubmit(input: $input) {
      ...RequestPartialTop
    }
  }
  ${RequestPartialTop}
`;

const projectFixedPrefabCreateMutation = gql`
  mutation ProjectFixedPrefabCreate($prefabId: ID!, $matchId: ID!) {
    projectFixedPrefabCreate(prefabId: $prefabId, matchId: $matchId) {
      ...ProjectDetail
    }
  }
  ${ProjectDetail}
`;

const initPopParam = urlUtils.getQueryParam('pop') || '';
const initEditParam = urlUtils.getQueryParam('edit') || '';
function formatSkillParam(skillParam: string) {
  if (skillParam === 'developer') return 'FOCUS_WEB_DEVELOPMENT';
  if (skillParam === 'designer') return 'FOCUS_DESIGN';
  if (skillParam === 'marketer') return 'FOCUS_MARKETING';
  if (skillParam === 'ecomm') return 'FOCUS_MANAGER';
  return '';
}
let autoBrandSet = false;

interface HumanNewRequestFlowProps {
  activeRequests: RequestActiveForBrandsQuery['requestActiveForBrands'];
  brands: BrandForHumansQuery['brandForHumans'];
  expertsForBrands: string[];
  hasDiscountCode: string;
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
  showUpgradeModal: () => void;
  socketClient: ClientWithOnReconnected;
}

const HumanNewRequestFlow = ({
  activeRequests,
  brands,
  expertsForBrands,
  hasDiscountCode,
  human,
  showUpgradeModal,
}: HumanNewRequestFlowProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const [isAnotherExpert] = useState(() => !!urlUtils.getQueryParam('another'));
  const [requestExcludeExisting, setRequestExcludeExisting] = useState(
    () => isAnotherExpert,
  );
  const [prefabToQuery] = useState(() => urlUtils.getQueryParam('p') || '');
  const [expertToQuery] = useState(
    () => urlUtils.getQueryParam('expert') || '',
  );
  const [requestFocusTag, setRequestFocusTag] = useState(
    () => formatSkillParam(urlUtils.getQueryParam('skill')) || '',
  );
  const [showSidebar, setShowSidebar] = useState(false);
  const [primaryPanel, setPrimaryPanel] = useState('Example Projects');
  const secondaryPanels = ['Example Projects'].filter(
    (p) => p !== primaryPanel,
  );
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
  }
  const urlInputRef = useRef<HTMLInputElement>(null);
  const [requestUrl, setRequestUrl] = useState(
    () =>
      urlUtils.getQueryParam('url') ||
      tokenUtils.readLocalStorage('request-url') ||
      '',
  );
  const [requestBrandId, setRequestBrandId] = useState(() =>
    requestUrl
      ? ''
      : urlUtils.getQueryParam('brand') || (brands[0] && brands[0].id) || '',
  );
  const [prefabDescView, setPrefabDescView] = useState(false);
  const [urlInputFocus, setUrlInputFocus] = useState(false);
  const [urlInputIsWrong, setUrlInputIsWrong] = useState(false);
  const [urlInputIsWrongAgency, setUrlInputIsWrongAgency] = useState(false);
  const [showUseTemplate, setShowUseTemplate] = useState(false);
  const [isOngoing, setIsOngoing] = useState(false);
  const [isAgency, setIsAgency] = useState(false);
  const [agencyUrl, setAgencyUrl] = useState('');
  const [requestContent, setRequestContent] = useState<ContentState | null>(
    null,
  );
  const [initRequestContent, setInitRequestContent] = useState(
    () => tokenUtils.readLocalStorage('request-content') || '',
  );
  const [requestFiles, setRequestFiles] = useState<IFilestackFileUpload[]>([]);
  const [requestContentLengthError, setRequestContentLengthError] =
    useState(false);
  const [requestExpertId, setRequestExpertId] = useState('');
  const [requestDraftStatus, setRequestDraftStatus] = useState('');
  const [actionLoading, setActionLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  useEffect(() => {
    marketingUtils.beginNewRequest();
  }, []);
  useEffect(() => {
    if (brands.length) {
      if (!autoBrandSet && !requestUrl && !requestBrandId) {
        setRequestBrandId(brands[0].id);
      }
      autoBrandSet = true;
    }
  }, [brands, requestUrl, requestBrandId]);
  const debouncedSaveDraft = useDebouncedCallback(() => {
    let draftContent: string | null = '';
    if (
      requestContent &&
      requestContent.hasText() &&
      requestContent.getPlainText().replace(/\s/gi, '').trim()
    ) {
      try {
        draftContent = JSON.stringify(convertToRaw(requestContent));
      } catch (rawError) {
        console.log('save nrf draft convertToRaw error', rawError);
      }
    } else {
      draftContent = '';
    }
    tokenUtils.writeLocalStorage('request-content', draftContent);
    tokenUtils.writeLocalStorage('request-url', requestUrl);
    setRequestDraftStatus('saved');
  }, 1000);
  function onEditorUpdated(updatedContent: ContentState) {
    setShowUseTemplate(!updatedContent.hasText());
    setInitRequestContent('');
    setRequestContent(updatedContent);
    setRequestContentLengthError(false);
    setRequestDraftStatus('saving');
    debouncedSaveDraft.callback();
  }
  function useTemplate() {
    setShowUseTemplate(false);
    setInitRequestContent(NRF_TEMPLATE);
    setRequestContentLengthError(false);
  }
  const {
    data: dataPrefab,
    error: errorPrefab,
    loading: loadingPrefab,
  } = useQuery<PrefabDetailsQuery, PrefabDetailsQueryVariables>(
    prefabDetailsQuery,
    {
      returnPartialData: true,
      skip: !prefabToQuery,
      variables: {
        prefabId: prefabToQuery,
      },
    },
  );
  const prefabDetails =
    dataPrefab &&
    dataPrefab.prefabDetails &&
    dataPrefab.prefabDetails.id &&
    !dataPrefab.prefabDetails.wasDeleted &&
    (!dataPrefab.prefabDetails.expert ||
      dataPrefab.prefabDetails.expert.status !== 'inactive')
      ? dataPrefab.prefabDetails
      : undefined;
  console.log('prefabDetails', prefabDetails);
  const {
    data: dataExpert,
    error: errorExpert,
    loading: loadingExpert,
  } = useQuery<ExpertDetailsHumanQuery, ExpertDetailsHumanQueryVariables>(
    expertDetailsHumanQuery,
    {
      returnPartialData: true,
      skip: !expertToQuery,
      variables: {
        expertId: expertToQuery,
      },
    },
  );
  const expertDetails =
    (dataExpert && dataExpert.expertDetails) ||
    (prefabDetails && prefabDetails.expert) ||
    undefined;
  const expertsManyToQuery =
    expertToQuery || isAnotherExpert ? [] : expertsForBrands;
  const {
    data: dataExpertsMany,
    error: errorExpertsMany,
    loading: loadingExpertsMany,
  } = useQuery<ExpertDetailsManyQuery, ExpertDetailsManyQueryVariables>(
    expertDetailsManyQuery,
    {
      returnPartialData: true,
      skip: !expertsManyToQuery.length,
      variables: {
        expertIds: expertsManyToQuery,
      },
    },
  );
  const manyExpertChoices = (
    (dataExpertsMany && dataExpertsMany.expertDetailsMany) ||
    []
  ).filter(
    (e) =>
      e.photo && ['winback', 'churned', 'inactive'].indexOf(e.status) === -1,
  );
  function fileError(error: Error) {
    addNotification(
      (error && error.message) || 'File upload error. Please try again.',
      undefined,
      5000,
    );
    console.log('Filestack fileError', error);
  }
  function fileUploaded(filestackRes: any) {
    console.log('Filestack fileUploaded', filestackRes);
    if (filestackRes && filestackRes.filesUploaded) {
      setRequestFiles(
        requestFiles.concat(
          filestackRes.filesUploaded.map((f: any) => ({
            container: f.container || '',
            filename: f.filename || '',
            handle: f.handle || '',
            key: f.key || '',
            mimetype: f.mimetype || '',
            size: (f.size || '').toString(),
            source: f.source || '',
            uploadId: f.uploadId || '',
            url: f.url || '',
          })) as IFilestackFileUpload[],
        ),
      );
    }
  }
  function removeFile(filestackUploadUrl: string) {
    console.log('Filestack removeFile', filestackUploadUrl);
    setRequestFiles(requestFiles.filter((f) => f.url !== filestackUploadUrl));
  }
  const brandSelected = brands.find((b) => b.id === requestBrandId);
  const isFixedPrefab = !!(prefabDetails && initEditParam !== 'true');
  const needsExtraDetails =
    !!prefabDetails &&
    !prefabDetails.expert &&
    prefabDetails.priceType !== 'EXACTLY' &&
    prefabDetails.type === 'GENERAL' &&
    initEditParam !== 'true' &&
    initPopParam !== 'true';
  if (
    errorPrefab ||
    loadingPrefab ||
    errorExpert ||
    loadingExpert ||
    errorExpertsMany ||
    loadingExpertsMany
  ) {
    // ignore these
  }
  const [tryCreatePrefabProject] = useMutation<
    ProjectFixedPrefabCreateMutation,
    ProjectFixedPrefabCreateMutationVariables
  >(projectFixedPrefabCreateMutation);
  function onCreateFixedPricePrefab(
    matchId: string,
    prefabId: string,
    requestId: string,
  ) {
    setActionLoading(true);
    tryCreatePrefabProject({
      variables: {
        matchId,
        prefabId,
      },
    })
      .then(({ data }) => {
        setActionLoading(false);
        if (!data || !data.projectFixedPrefabCreate) {
          history.push('/requests/' + requestId);
          return;
        }
        history.push(
          '/projects/' + data.projectFixedPrefabCreate.id + '?pay=true',
        );
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        logError(err, {
          component: 'HumanNewRequestFlow',
          func: 'tryCreatePrefabProject',
        });
        history.push('/requests/' + requestId);
      });
  }
  const [tryRequestSubmit] = useMutation<
    RequestSubmitMutation,
    RequestSubmitMutationVariables
  >(requestSubmitMutation);
  function submitRequestForBrand(brandId: string) {
    tokenUtils.removeLocalStorage('request-url');
    const selectedExpertId = expertDetails
      ? expertDetails.id
      : requestExpertId || '';
    let draftContent = '';
    let plainTextContent = '';
    if (requestContent && requestContent.hasText()) {
      plainTextContent = requestContent.getPlainText().trim();
      if (plainTextContent.replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(requestContent));
        } catch (rawError) {
          console.log('submit nrf convertToRaw error', rawError);
        }
      }
    }
    if ((!isFixedPrefab || needsExtraDetails) && !draftContent) {
      addNotification('An error occured, please try again');
      logError(
        'somehow we do not have draftContent and not isFixedPrefab or is needsExtraDetails',
        {
          component: 'HumanNewRequestFlow',
          func: 'submitRequestForBrand',
        },
      );
    } else {
      setActionLoading(true);
      tryRequestSubmit({
        variables: {
          input: {
            brandId,
            ...(!!draftContent && { description: draftContent }),
            ...(!!draftContent && { descriptionPlainText: plainTextContent }),
            excludeExisting: requestExcludeExisting,
            ...(!!selectedExpertId && { expertId: selectedExpertId }),
            files: requestFiles,
            isOngoing,
            ...(!!requestFocusTag && {
              tagFocus: [requestFocusTag],
            }),
            ...(!!(isFixedPrefab || needsExtraDetails) && {
              projectPrefabId: prefabDetails.id,
            }),
          },
        },
      })
        .then(({ data }) => {
          setSubmitSuccess(true);
          setActionLoading(false);
          if (data && data.requestSubmit && data.requestSubmit.id) {
            tokenUtils.removeLocalStorage('request-content');
            marketingUtils.sendNewRequest();
            if (
              data.requestSubmit.currentMatchStr &&
              prefabDetails &&
              prefabDetails.priceType === 'EXACTLY'
            ) {
              onCreateFixedPricePrefab(
                data.requestSubmit.currentMatchStr,
                prefabDetails.id,
                data.requestSubmit.id,
              );
            } else {
              history.push('/requests/' + data.requestSubmit.id);
            }
          } else {
            setSubmitSuccess(false);
            addNotification(
              'An error occured, please contact support@storetasker.com',
            );
            logError('Mutation missing requestSubmit', {
              component: 'HumanNewRequestFlow',
              func: 'requestSubmit',
            });
          }
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(errorUtils.getErrorMessage(err) || 'Request Error');
          logError(err, {
            component: 'HumanNewRequestFlow',
            func: 'tryRequestSubmit',
          });
        });
    }
  }
  const [tryBrandCreate] = useMutation<
    BrandCreatedByHumanMutation,
    BrandCreatedByHumanMutationVariables
  >(brandCreatedByHumanMutation);
  function submitRequest() {
    if (brandSelected) {
      submitRequestForBrand(brandSelected.id);
    } else {
      setActionLoading(true);
      tryBrandCreate({
        variables: isAgency
          ? {
              isAgency: agencyUrl,
              url: requestUrl || agencyUrl,
            }
          : {
              url: requestUrl,
            },
      })
        .then(({ data }) => {
          setActionLoading(false);
          if (data && data.brandCreatedByHuman && data.brandCreatedByHuman.id) {
            submitRequestForBrand(data.brandCreatedByHuman.id);
          } else {
            addNotification(
              'An error occured, please contact support@storetasker.com',
            );
            logError('Mutation missing brandCreatedByHuman', {
              component: 'HumanNewRequestFlow',
              func: 'brandCreatedByHuman',
            });
          }
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(errorUtils.getErrorMessage(err) || 'Brand Error');
          logError(err, {
            component: 'HumanNewRequestFlow',
            func: 'tryBrandCreate',
          });
        });
    }
  }
  function onRequestNextStep() {
    if (!brandSelected) {
      const checkUrl = (isAgency ? agencyUrl : requestUrl || '').trim();
      const findDot = checkUrl.lastIndexOf('.');
      if (
        !checkUrl ||
        checkUrl.indexOf(' ') >= 0 ||
        findDot <= 0 ||
        findDot >= checkUrl.length - 2
      ) {
        addNotification(
          "This website url doesn't look right. Please use a valid url.",
          undefined,
          5000,
        );
        return;
      }
    }
    if (!isFixedPrefab || needsExtraDetails) {
      const checkContent =
        (requestContent &&
          requestContent.hasText() &&
          requestContent.getPlainText().replace(/\s/gi, '').trim()) ||
        '';
      if (checkContent.length < 75) {
        setRequestContentLengthError(true);
        return;
      }
    }
    marketingUtils.submitNewRequest();
    submitRequest();
  }
  function undoBrandSelected() {
    setRequestUrl('');
    setRequestBrandId('');
    setUrlInputFocus(false);
    setTimeout(() => {
      if (urlInputRef && urlInputRef.current) {
        urlInputRef.current.focus();
      }
    });
    setRequestDraftStatus('saving');
    debouncedSaveDraft.callback();
  }
  function pickBrandSelected(brandId: string) {
    setRequestUrl('');
    setRequestBrandId(brandId);
    setIsAgency(false);
    setUrlInputFocus(false);
    setRequestDraftStatus('saving');
    debouncedSaveDraft.callback();
  }
  function updateRequestUrl(updatedUrl: string) {
    setRequestUrl(updatedUrl);
    setRequestDraftStatus('saving');
    debouncedSaveDraft.callback();
  }
  const cleanRequestUrl = (requestUrl || '').trim().toLowerCase();
  function checkUrlOnFocus() {
    setUrlInputFocus(true);
    setUrlInputIsWrong(false);
  }
  function checkUrlOnBlur() {
    setUrlInputFocus(false);
    if (cleanRequestUrl) {
      const matchingBrand = brands.find(
        (b) =>
          b.url === cleanRequestUrl ||
          b.shopifyAdminURL === cleanRequestUrl ||
          (b.name || '').toLowerCase() === cleanRequestUrl,
      );
      if (matchingBrand) {
        pickBrandSelected(matchingBrand.id);
      }
    }
    if (cleanRequestUrl && cleanRequestUrl.indexOf('.') === -1) {
      setUrlInputIsWrong(true);
    } else {
      setUrlInputIsWrong(false);
    }
  }
  const brandsFiltered = brands.filter(
    (b) =>
      !cleanRequestUrl ||
      (b.name || '').toLowerCase().indexOf(cleanRequestUrl) >= 0 ||
      (b.url || '').indexOf(cleanRequestUrl) >= 0 ||
      (b.shopifyAdminURL || '').indexOf(cleanRequestUrl) >= 0,
  );
  function updateAgencyUrl(updatedUrl: string) {
    setAgencyUrl(updatedUrl);
  }
  const cleanAgencyUrl = (agencyUrl || '').trim().toLowerCase();
  function checkUrlOnFocusAgency() {
    setUrlInputIsWrongAgency(false);
  }
  function checkUrlOnBlurAgency() {
    if (cleanAgencyUrl && cleanAgencyUrl.indexOf('.') === -1) {
      setUrlInputIsWrongAgency(true);
    } else {
      setUrlInputIsWrongAgency(false);
    }
  }
  const requestActionCtaText =
    prefabDetails &&
    prefabDetails.priceType &&
    prefabDetails.priceType === 'EXACTLY'
      ? 'Next'
      : 'Submit';
  const urlIsReadyForSubmit = !!(
    isAgency ||
    (requestUrl && requestUrl.trim() && requestUrl.indexOf('.') >= 1) ||
    brandSelected
  );
  const urlIsReadyForSubmitAgency = !!(
    !isAgency ||
    (agencyUrl && agencyUrl.trim() && agencyUrl.indexOf('.') >= 1) ||
    brandSelected
  );
  function toggleSelectedExpert(expertId: string) {
    setRequestExcludeExisting(false);
    setRequestExpertId(expertId);
  }
  function toggleExcludeExisting() {
    setRequestExcludeExisting(!requestExcludeExisting);
    setRequestExpertId('');
  }
  let prefabCard = <Fragment />;
  if (prefabDetails) {
    prefabCard = (
      <div className="BasicFormPrefab">
        <div className="BasicFormPrefabTop">
          {prefabDetails.title && (
            <div className="BasicFormPrefabTitle">{prefabDetails.title}</div>
          )}
          <div
            className={
              'BasicFormPrefabToggle ' +
              (prefabDescView ? 'BasicFormPrefabToggleOpen' : '')
            }
            onClick={() => setPrefabDescView(!prefabDescView)}
          >
            {prefabDescView ? 'hide description' : 'view description'}
          </div>
          <div className="BasicFormPrefabFeatures">
            {prefabDetails.priceType &&
              prefabDetails.priceType !== 'NONE' &&
              prefabDetails.cents && (
                <div className="BasicFormPrefabFeature">
                  <div className="BasicFormPrefabFeatureTitle">
                    {prefabDetails.priceType === 'EXACTLY'
                      ? 'cost'
                      : 'starting at'}
                  </div>
                  <div className="BasicFormPrefabFeatureValue">
                    $
                    {formatNumberWithCommas(
                      centsDollarsRounded(prefabDetails.cents),
                    )}
                  </div>
                </div>
              )}
            {prefabDetails.estimatedCompletion && (
              <div className="BasicFormPrefabFeature">
                <div className="BasicFormPrefabFeatureTitle">timeline</div>
                <div className="BasicFormPrefabFeatureValue">
                  {prefabDetails.estimatedCompletion} day
                  {prefabDetails.estimatedCompletion > 1 ? 's' : ''}
                </div>
              </div>
            )}
          </div>
        </div>
        {!!prefabDescView && (
          <div className="BasicFormPrefabDesc">
            <ReadOnlyEditor
              className="BasicFormPrefabContent"
              content={prefabDetails.description || ''}
            />
          </div>
        )}
      </div>
    );
  }
  let tellUsStr = 'Tell Us About Your Project';
  let formTitle = isAnotherExpert ? 'Hire a new Expert' : 'New Project';
  let formSubtitle = isAnotherExpert
    ? "Let's kick off another project. We'll introduce you to someone fantastic in our curated network."
    : "Let's kick off another project. You can work with the same expert again or we can introduce you to someone new.";
  if (expertDetails) {
    formTitle = 'Work with ' + (expertDetails.firstName || 'your expert');
    tellUsStr = 'Tell Them About Your Project';
    formSubtitle =
      'Send your project brief to ' +
      (expertDetails.firstName || 'your expert') +
      " who will reply via email after they've reviewed your message.";
  }
  return (
    <div className="DashboardModal ClientDetailModal">
      <div className="DashboardModalTopCircle" />
      <div className="DashboardModalBottomCircle" />
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <Link
              to={isAnotherExpert ? '/experts' : '/projects'}
              className="ThreadDetailMainHeaderBack"
            >
              {isAnotherExpert ? 'Experts' : 'Projects'}
            </Link>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody">
            <div className="ThreadDetailMainBodyFocus">
              <div className="ThreadDetailMainBodyFocusTitle">{formTitle}</div>
              <div className="ThreadDetailMainBodyFocusSubtitle">
                {formSubtitle} {notificationDiscountCode(true, hasDiscountCode)}
              </div>
              <div className="BasicForm">
                {!!needsExtraDetails && (
                  <div className="BasicFormField">{prefabCard}</div>
                )}
                {!!isAgency && (
                  <div className="BasicFormField">
                    <div className="BasicFormFieldTitle">
                      Your agency&apos;s website
                    </div>
                    <div
                      className="BasicFormFieldExamples"
                      onClick={() => setIsAgency(false)}
                    >
                      Are you a brand?
                    </div>
                    <div className="BrandSelector">
                      <div className="BrandOptions">
                        <div className="BrandOptionsInput">
                          <input
                            className={
                              'BrandOptionsInputField ' +
                              (urlInputIsWrongAgency
                                ? ' BrandOptionsInputFieldInvalid '
                                : '')
                            }
                            type="text"
                            autoComplete="new-off"
                            placeholder="agency.com"
                            value={agencyUrl}
                            onChange={(e) =>
                              updateAgencyUrl(e.currentTarget.value)
                            }
                            onFocus={checkUrlOnFocusAgency}
                            onBlur={checkUrlOnBlurAgency}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="BasicFormField">
                  <div className="BasicFormFieldTitle">
                    {isAgency ? "Your client's website" : 'Your website'}
                    {!!isAgency && <span> optional</span>}
                  </div>
                  {!isAgency && !brandSelected && (
                    <div
                      className="BasicFormFieldExamples"
                      onClick={() => setIsAgency(true)}
                    >
                      Are you an agency?
                    </div>
                  )}
                  <div className="BrandSelector">
                    {brandSelected ? (
                      <div
                        className="BrandSelected"
                        onClick={undoBrandSelected}
                      >
                        <div className="BrandSelectedTitle">
                          {brandSelected.name}
                        </div>
                        <div className="BrandSelectedSubtitle">
                          {brandSelected.url ||
                            brandSelected.shopifyAdminURL ||
                            ''}
                        </div>
                      </div>
                    ) : (
                      <div className="BrandOptions">
                        <div className="BrandOptionsInput">
                          <input
                            ref={urlInputRef}
                            className={
                              'BrandOptionsInputField ' +
                              (urlInputIsWrong
                                ? ' BrandOptionsInputFieldInvalid '
                                : '')
                            }
                            type="text"
                            autoComplete="new-off"
                            placeholder="example.com"
                            value={requestUrl}
                            onChange={(e) =>
                              updateRequestUrl(e.currentTarget.value)
                            }
                            onFocus={checkUrlOnFocus}
                            onBlur={checkUrlOnBlur}
                          />
                        </div>
                        {!!urlInputFocus && !!brandsFiltered.length && (
                          <div className="BrandOptionsList">
                            {brandsFiltered.map((b) => (
                              <div
                                key={b.id}
                                className="BrandOptionsListItem"
                                onClick={() => pickBrandSelected(b.id)}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                <div className="BrandOptionsListItemTitle">
                                  {b.name}
                                </div>
                                <div className="BrandOptionsListItemSubtitle">
                                  {b.url || b.shopifyAdminURL || ''}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {!expertDetails && (
                  <div className="BasicFormField">
                    <div className="BasicFormFieldTitle BasicFormFieldTitleFull">
                      What expert would you like to hire?
                    </div>
                    <div className="BasicFormFieldOptions">
                      <div
                        onClick={() => setRequestFocusTag('')}
                        className={
                          'BasicFormFieldOption ' +
                          (requestFocusTag === ''
                            ? ' BasicFormFieldOptionActive'
                            : '')
                        }
                      >
                        Let Storetasker decide
                      </div>
                      {FOCUS_OPTIONS.map((o) => (
                        <div
                          key={o.value}
                          onClick={() => setRequestFocusTag(o.value)}
                          className={
                            'BasicFormFieldOption ' +
                            (requestFocusTag === o.value
                              ? ' BasicFormFieldOptionActive'
                              : '')
                          }
                        >
                          {o.person}
                        </div>
                      ))}
                    </div>
                    <div className="BasicFormFieldOptionsDesc">
                      {!!requestFocusTag && (
                        <span>
                          {expertFocusPosition([requestFocusTag])}s in the
                          network:{' '}
                        </span>
                      )}
                      {expertFocusWhat([requestFocusTag]) ||
                        "We'll introduce you to an expert in our highly-vetted network of eCommerce talent who we think will be a great fit"}
                    </div>
                  </div>
                )}
                {!expertDetails && (
                  <div className="BasicFormField">
                    <div className="BasicFormFieldTitle BasicFormFieldTitleFull">
                      What do you need to get done?
                    </div>
                    <div className="BasicFormFieldOptions">
                      <div
                        onClick={() => setIsOngoing(false)}
                        className={
                          'BasicFormFieldOption ' +
                          (isOngoing ? '' : ' BasicFormFieldOptionActive')
                        }
                      >
                        one off project
                      </div>
                      <div
                        onClick={() => setIsOngoing(true)}
                        className={
                          'BasicFormFieldOption ' +
                          (!isOngoing ? '' : ' BasicFormFieldOptionActive')
                        }
                      >
                        ongoing work
                      </div>
                    </div>
                  </div>
                )}
                <div className="BasicFormField">
                  <div className="BasicFormFieldTitle">
                    {isFixedPrefab && !needsExtraDetails
                      ? 'Project Details'
                      : tellUsStr}
                  </div>
                  {isFixedPrefab && !needsExtraDetails ? (
                    prefabCard
                  ) : (
                    <div className="BasicFormFieldRichWrapper">
                      <div className="BasicFormFieldRichWrapperText">
                        {(!prefabToQuery || !loadingPrefab) && (
                          <RichTextEditor
                            initContent={
                              !needsExtraDetails &&
                              prefabDetails &&
                              prefabDetails.description
                                ? prefabDetails.description
                                : initRequestContent
                            }
                            placeholder="Tell us as much as you can about your project. This helps us find the best experts to introduce you to. Feel free to link to a Google doc, share a screenshot, record a video, etc."
                            onUpdated={onEditorUpdated}
                          />
                        )}
                      </div>
                      <div className="BasicFormFieldRichFooter">
                        <ReactFilestack
                          apikey="AGxdviGoVRwWPL6lKEdnXz"
                          actionOptions={{
                            maxFiles: 20,
                            storeTo: {
                              container: envUtils.pick(
                                'files.asklorem.com',
                                'files-dev.asklorem.com',
                                'files-dev.asklorem.com',
                              ),
                              location: 's3',
                              path: `${human.id}/`,
                              region: 'us-east-1',
                            },
                          }}
                          onSuccess={fileUploaded}
                          onError={fileError}
                          customRender={({ onPick }: { onPick: any }) => (
                            <div
                              onMouseDown={onPick}
                              className="BasicFormFieldRichFooterAttachments"
                            >
                              attach files
                            </div>
                          )}
                        />
                        {!!showUseTemplate && !prefabDetails && (
                          <div
                            className="BasicFormFieldRichFooterTemplate"
                            onClick={useTemplate}
                          >
                            use a template
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {!!requestContentLengthError && (
                    <div className="BasicFormInlineError">
                      please write at least 75 characters!
                    </div>
                  )}
                  <div className="BasicFormFiles">
                    {requestFiles.map((file) => (
                      <div
                        key={file.url}
                        className="BasicFormFile"
                        onClick={() => removeFile(file.url || '')}
                      >
                        <span className="BasicFormFileText">
                          {file.filename}
                        </span>
                        <div className="BasicFormFileRemove" />
                      </div>
                    ))}
                  </div>
                </div>
                {!!expertDetails && !!expertDetails.photo && (
                  <div className="BasicFormField">
                    <div className="BasicFormFieldTitle">Expert</div>
                    <div className="BasicFormExpertChoices">
                      <div className="BasicFormExpert BasicFormExpertNoEffects">
                        <div
                          className="BasicFormExpertPhoto"
                          style={{
                            backgroundImage: 'url(' + expertDetails.photo + ')',
                          }}
                        />
                        <div className="BasicFormExpertName">
                          {expertDetails.firstName || 'Expert'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!expertDetails && !!manyExpertChoices.length && (
                  <div className="BasicFormField">
                    <div className="BasicFormFieldTitle">
                      Work With The Same Expert Again? <span>optional</span>
                    </div>
                    <div className="BasicFormExpertChoices">
                      {manyExpertChoices.map((e) => (
                        <div
                          key={e.id}
                          onClick={() =>
                            toggleSelectedExpert(
                              requestExpertId && requestExpertId === e.id
                                ? ''
                                : e.id,
                            )
                          }
                          className={
                            'BasicFormExpert ' +
                            (requestExpertId && requestExpertId === e.id
                              ? ' BasicFormExpertSelected '
                              : '')
                          }
                        >
                          <div
                            className="BasicFormExpertPhoto"
                            style={{
                              backgroundImage: 'url(' + (e.photo || '') + ')',
                            }}
                          />
                          <div className="BasicFormExpertName">
                            {e.firstName || 'Expert'}
                          </div>
                        </div>
                      ))}
                      <div
                        className={
                          'BasicFormExpert ' +
                          (requestExcludeExisting
                            ? ' BasicFormExpertSelected '
                            : '')
                        }
                        onClick={() => toggleExcludeExisting()}
                      >
                        <div className="BasicFormExpertNew">
                          Connect with someone new
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="BasicFormFooter">
                  <div
                    className={
                      'BasicFormAction ' +
                      (actionLoading ? ' BasicFormActionLoading ' : '') +
                      (!urlIsReadyForSubmitAgency ||
                      !urlIsReadyForSubmit ||
                      requestContentLengthError
                        ? ' BasicFormActionInvalid '
                        : '')
                    }
                    onClick={onRequestNextStep}
                  >
                    {requestActionCtaText}
                  </div>
                  <div
                    className={
                      'BasicFormDraftStatus BasicFormDraftStatus-' +
                      requestDraftStatus
                    }
                  >
                    {requestDraftStatus}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div
          className={
            'ThreadDetailSidebar ' +
            (!secondaryPanels.length ? ' ThreadDetailSidebarSolo ' : '')
          }
        >
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            <div className="ThreadDetailSidebarBodyPanels">
              {primaryPanel === 'Example Projects' && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                  <div className="ThreadDetailsSidebarAboutExampleItemTitle">
                    Great project briefs include:
                  </div>
                  <ul className="ThreadDetailsSidebarAboutBullets">
                    <li className="ThreadDetailsSidebarAboutBulletItem">
                      A quick introduction for you and your business
                    </li>
                    <li className="ThreadDetailsSidebarAboutBulletItem">
                      A detailed description of what you need help with
                    </li>
                    <li className="ThreadDetailsSidebarAboutBulletItem">
                      Context or the goal you&apos;re trying to achieve
                    </li>
                  </ul>
                  <div className="ThreadDetailsSidebarAboutExamples">
                    <div className="ThreadDetailsSidebarAboutExampleItem">
                      <div className="ThreadDetailsSidebarAboutExampleItemTitle">
                        Example 1:
                      </div>
                      <ReadOnlyEditor
                        className="ThreadDetailsSidebarAboutExampleItemContent"
                        content={EX1_TEMPLATE}
                      />
                    </div>
                    <div className="ThreadDetailsSidebarAboutExampleItem">
                      <div className="ThreadDetailsSidebarAboutExampleItemTitle">
                        Example 2:
                      </div>
                      <ReadOnlyEditor
                        className="ThreadDetailsSidebarAboutExampleItemContent"
                        content={EX2_TEMPLATE}
                      />
                    </div>
                    <div className="ThreadDetailsSidebarAboutExampleItem">
                      <div className="ThreadDetailsSidebarAboutExampleItemTitle">
                        Example 3:
                      </div>
                      <ReadOnlyEditor
                        className="ThreadDetailsSidebarAboutExampleItemContent"
                        content={EX3_TEMPLATE}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!(activeRequests.length >= 3) && !actionLoading && !submitSuccess && (
        <div className="DashboardErrorCover DashboardErrorCoverSee">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverTopper">
            <div className="DashboardErrorCoverTopperText">
              You already have 3 active projects. You&apos;ll need to cancel one
              first, before you can submit a new one.
            </div>
            <Link to="/projects" className="DashboardErrorCoverTopperBtn">
              view projects
            </Link>
          </div>
        </div>
      )}
      {!!(!human.proStart && activeRequests.length >= 1) &&
        !actionLoading &&
        !submitSuccess && (
          <div className="DashboardErrorCover DashboardErrorCoverSee DashboardErrorCoverPending">
            <div className="DashboardErrorCoverOver" />
            <div className="DashboardErrorCoverPop DashboardErrorCoverPopCloseable">
              <div className="DashboardErrorCoverPopClose" />
              <Link to="/projects" className="DashboardErrorCoverPopClose" />
              <div className="DashboardErrorCoverPopTitle">
                Start a second search?
              </div>
              <div className="DashboardErrorCoverPopText">
                It looks like you already have an active project. Upgrade to
                Storetasker Pro to do multiple freelancer searches at the same
                time.
              </div>
              <div>
                <div
                  className="DashboardErrorCoverPopBtn"
                  onClick={showUpgradeModal}
                >
                  Upgrade to Pro
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

HumanNewRequestFlow.propTypes = {
  activeRequests: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  expertsForBrands: PropTypes.array.isRequired,
  hasDiscountCode: PropTypes.string.isRequired,
  human: PropTypes.object.isRequired,
  showUpgradeModal: PropTypes.func.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default HumanNewRequestFlow;
