import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import HumanUserBubble from '../feature/HumanUserBubble';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import QuoteAttachedThreadEvent from './QuoteAttachedThreadEvent';
import { sendingErrorFormatted } from '../../utils/format';
import { MatchThreadPaginatedQuery } from '../../gql/graphql';

interface TextMessageThreadEventProps {
  className?: string;
  expertId: string;
  isGroupFirst: boolean;
  isGroupLast: boolean;
  onReplyClick?: () => void;
  onUnthreadText?: (textMessageEventId: string) => void;
  textMessageEvent: Extract<
    MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
    { __typename?: 'TextMessageEvent' | undefined }
  >;
  threadEventId: string;
  threadEventTimestamp: number;
  hasUnknownHuman?: boolean;
  isUnthreadLoading?: boolean;
}
const initTime = new Date().getTime();

const TextMessageThreadEvent = ({
  className,
  expertId,
  isGroupFirst,
  isGroupLast,
  onReplyClick,
  onUnthreadText,
  textMessageEvent,
  threadEventTimestamp,
  hasUnknownHuman,
  isUnthreadLoading,
}: TextMessageThreadEventProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isGroupFirst]);
  const [showUnthreadOption, setShowUnthreadOption] = useState(false);
  const nowMoment = moment.tz(initTime, moment.tz.guess());
  const eventMoment = moment.tz(threadEventTimestamp, moment.tz.guess());
  // January 4th, 2020 at 5:37pm PST
  const eventDateFull =
    eventMoment.format('MMMM Do, YYYY') +
    ' at ' +
    eventMoment.format('h:mma z');
  let eventDateSummary = eventMoment.format('M/D/YY');
  if (nowMoment.isSame(eventMoment, 'day')) {
    eventDateSummary = eventMoment.format('h:mma');
  } else if (nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')) {
    eventDateSummary = 'Yesterday';
  } else if (nowMoment.isSame(eventMoment, 'week')) {
    eventDateSummary = eventMoment.format('dddd');
  } else if (nowMoment.isSame(eventMoment, 'year')) {
    eventDateSummary = eventMoment.format('MMM Do');
  }
  const isSolo =
    textMessageEvent.ownerHuman &&
    (textMessageEvent.ownerHuman.firstName ||
      textMessageEvent.ownerHuman.lastName) &&
    (!textMessageEvent.recipientHumans ||
      !textMessageEvent.recipientHumans.length);
  return (
    <div
      className={
        'ThreadEvent TextMessageThreadEvent ' +
        (textMessageEvent.ownerHuman ||
        (textMessageEvent.ownerExpert &&
          textMessageEvent.recipientExpert &&
          textMessageEvent.ownerExpert.id !== expertId)
          ? ' ThreadEventOwnerHuman '
          : ' ThreadEventOwnerExpert ') +
        (isSolo ? ' ThreadEventSolo ' : '') +
        (hasUnknownHuman ? ' ThreadEventWithUnknown ' : '') +
        (className || '')
      }
    >
      <div className="ThreadEventWrapper">
        {isGroupFirst && (
          <div className="ThreadEventTop">
            <div className="ThreadEventTopBubbles">
              {textMessageEvent.ownerHuman && (
                <HumanUserBubble
                  firstName={
                    textMessageEvent.ownerHuman &&
                    textMessageEvent.ownerHuman.firstName
                  }
                  lastName={
                    textMessageEvent.ownerHuman &&
                    textMessageEvent.ownerHuman.lastName
                  }
                  fallbackPhone={
                    textMessageEvent.ownerHuman &&
                    textMessageEvent.ownerHuman.primaryPhone
                  }
                  primary
                />
              )}
              {textMessageEvent.recipientHumans.map((r) => (
                <HumanUserBubble
                  key={r.id}
                  firstName={r.firstName}
                  lastName={r.lastName}
                  fallbackPhone={r.primaryPhone}
                />
              ))}
              {textMessageEvent.ownerExpert &&
                textMessageEvent.recipientExpert && (
                  <ExpertUserBubble
                    expertId={
                      textMessageEvent.ownerExpert.id === expertId
                        ? textMessageEvent.recipientExpert.id
                        : textMessageEvent.ownerExpert.id
                    }
                    expertDetails={
                      textMessageEvent.ownerExpert.id === expertId
                        ? textMessageEvent.recipientExpert
                        : textMessageEvent.ownerExpert
                    }
                    primary={textMessageEvent.ownerExpert.id !== expertId}
                  />
                )}
            </div>
            <div className="ThreadEventTopDate" data-tip={eventDateFull}>
              {eventDateSummary}
            </div>
          </div>
        )}
        <div className="ThreadEventCardWrapper">
          <div className="ThreadEventCardLeft">
            {isSolo && textMessageEvent.ownerHuman && isGroupFirst && (
              <div className="ThreadEventCardLeftUser">
                <HumanUserBubble
                  firstName={
                    textMessageEvent.ownerHuman &&
                    textMessageEvent.ownerHuman.firstName
                  }
                  lastName={
                    textMessageEvent.ownerHuman &&
                    textMessageEvent.ownerHuman.lastName
                  }
                  primary
                />
              </div>
            )}
            {textMessageEvent.ownerExpert && (
              <div className="ThreadEventCardLeftDate" data-tip={eventDateFull}>
                {eventDateSummary}
              </div>
            )}
          </div>
          <div className="ThreadEventCard">
            <div
              className="ThreadEventCardBody"
              onClick={() => showUnthreadOption && setShowUnthreadOption(false)}
            >
              <ReadOnlyEditor
                className="ThreadEventCardBodyContent"
                content={textMessageEvent.content}
              />
            </div>
            {!!hasUnknownHuman && (
              <div
                className={
                  'ThreadEventCardBodyUnthread ThreadEventCardBodyUnthread' +
                  (showUnthreadOption ? 'Active' : 'Inactive')
                }
              >
                <div
                  className="ThreadEventCardBodyUnthreadToggle"
                  onClick={() => setShowUnthreadOption(!showUnthreadOption)}
                />
                {!!showUnthreadOption && (
                  <div
                    className={
                      'ThreadEventCardBodyUnthreadOption ' +
                      (isUnthreadLoading
                        ? ' ThreadEventCardBodyUnthreadOptionLoading '
                        : '')
                    }
                    onClick={() =>
                      onUnthreadText && onUnthreadText(textMessageEvent.id)
                    }
                  >
                    Unthread and move to inbound
                  </div>
                )}
              </div>
            )}
            {!!(textMessageEvent.files && textMessageEvent.files.length) && (
              <div
                className="ThreadEventCardFiles"
                onClick={() =>
                  showUnthreadOption && setShowUnthreadOption(false)
                }
              >
                {textMessageEvent.files.map((f) => (
                  <a
                    key={f.id}
                    className="ThreadEventCardFile"
                    href={f.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {f.filename}
                  </a>
                ))}
              </div>
            )}
            {!!textMessageEvent.sendingError && (
              <div className="ThreadEventCardSendingError">
                Sending Error. Please try again or send via email:{' '}
                {sendingErrorFormatted(textMessageEvent.sendingError)}
              </div>
            )}
          </div>
          <div className="ThreadEventCardRight">
            {!textMessageEvent.ownerExpert && (
              <div
                className="ThreadEventCardRightDate"
                data-tip={eventDateFull}
              >
                {eventDateSummary}
              </div>
            )}
            {textMessageEvent.ownerExpert &&
              (!textMessageEvent.recipientExpert ||
                textMessageEvent.ownerExpert.id === expertId) &&
              isGroupFirst && (
                <div className="ThreadEventCardRightUser">
                  <ExpertUserBubble
                    expertId={textMessageEvent.ownerExpert.id}
                    expertDetails={textMessageEvent.ownerExpert}
                    primary
                  />
                </div>
              )}
          </div>
        </div>
        {!!textMessageEvent.quote && (
          <QuoteAttachedThreadEvent quote={textMessageEvent.quote} />
        )}
        {!hasUnknownHuman && isGroupLast && (
          <div className="ThreadEventBottom">
            {!!onReplyClick && (
              <div className="ThreadEventBottomReply" onClick={onReplyClick}>
                Reply Via Text
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

TextMessageThreadEvent.propTypes = {
  className: PropTypes.string,
  expertId: PropTypes.string.isRequired,
  hasUnknownHuman: PropTypes.bool,
  isGroupFirst: PropTypes.bool.isRequired,
  isGroupLast: PropTypes.bool.isRequired,
  isUnthreadLoading: PropTypes.bool,
  onReplyClick: PropTypes.func,
  onUnthreadText: PropTypes.func,
  textMessageEvent: PropTypes.object.isRequired,
  threadEventId: PropTypes.string.isRequired,
  threadEventTimestamp: PropTypes.number.isRequired,
};

TextMessageThreadEvent.defaultProps = {
  className: '',
  hasUnknownHuman: false,
  isUnthreadLoading: false,
  onReplyClick: null,
  onUnthreadText: () => {},
};

export default TextMessageThreadEvent;
