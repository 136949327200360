import { useState, useEffect, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import HumanUserBubble from '../feature/HumanUserBubble';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import {
  MatchThreadPaginatedQuery,
  ExpertDetailsQuery,
} from '../../gql/graphql';
import { meetingResponseFormatted } from '../../utils/format';

interface MeetingThreadEventProps {
  className?: string;
  expertDetails?: ExpertDetailsQuery['expertDetails'];
  meetingEvent: Extract<
    MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
    { __typename?: 'MeetingEvent' | undefined }
  >;
  onReplyClick?: () => void;
  onUnthreadMeeting?: (meetingEventId: string) => void;
  onInviteResponse?: (
    meeting: Extract<
      MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
      { __typename?: 'MeetingEvent' | undefined }
    >['meeting'],
    response: string,
  ) => void;
  threadEventId: string;
  threadEventTimestamp: number;
  hasUnknownHuman?: boolean;
  isUnthreadLoading?: boolean;
}
const initTime = new Date().getTime();

const MeetingThreadEvent = ({
  className,
  expertDetails,
  meetingEvent,
  onReplyClick,
  onUnthreadMeeting,
  onInviteResponse,
  threadEventTimestamp,
  hasUnknownHuman,
  isUnthreadLoading,
}: MeetingThreadEventProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const [showUnthreadOption, setShowUnthreadOption] = useState(false);
  const nowMoment = moment.tz(initTime, moment.tz.guess());
  const eventMoment = moment.tz(threadEventTimestamp, moment.tz.guess());
  // January 4th, 2020 at 5:37pm PST
  const eventDateFull =
    eventMoment.format('MMMM Do, YYYY') +
    ' at ' +
    eventMoment.format('h:mma z');
  let eventDateSummary = eventMoment.format('M/D/YY');
  if (nowMoment.isSame(eventMoment, 'day')) {
    eventDateSummary = eventMoment.format('h:mma');
  } else if (nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')) {
    eventDateSummary = 'Yesterday';
  } else if (nowMoment.isSame(eventMoment, 'week')) {
    eventDateSummary = eventMoment.format('dddd');
  } else if (nowMoment.isSame(eventMoment, 'year')) {
    eventDateSummary = eventMoment.format('MMM Do');
  }
  const isSolo =
    meetingEvent.ownerHuman &&
    (meetingEvent.ownerHuman.firstName || meetingEvent.ownerHuman.lastName) &&
    (!meetingEvent.recipientHumans || !meetingEvent.recipientHumans.length);

  // response
  let cardTitle = '';
  if (meetingEvent.action === 'CREATE') {
    cardTitle = 'Meeting Invite';
  } else if (meetingEvent.action === 'UPDATE') {
    cardTitle = 'Meeting Updated';
  } else if (meetingEvent.action === 'CANCEL') {
    cardTitle = 'Meeting Canceled';
  }
  const meetingTitle =
    (meetingEvent.meeting && meetingEvent.meeting.eventTitle) || '';
  let replyText = '';
  if (meetingEvent.response === 'NEEDS-ACTION') {
    replyText = `Undecided Response To Invite: ${meetingTitle}`;
  } else if (meetingEvent.response === 'ACCEPTED') {
    replyText = `Accepted Invite: ${meetingTitle}`;
  } else if (meetingEvent.response === 'DECLINED') {
    replyText = `Declined Invite: ${meetingTitle}`;
  } else if (meetingEvent.response === 'TENTATIVE') {
    replyText = `Tentatively Accepted Invite: ${meetingTitle}`;
  } else if (meetingEvent.response) {
    // OTHER or something else
    replyText = `Unknown Response To Invite: ${meetingTitle}`;
  }
  let meetingDateStr = '';
  let meetingDateFull = '';
  if (
    !replyText &&
    meetingEvent.meeting &&
    meetingEvent.meeting.eventStart &&
    meetingEvent.meeting.eventEnd
  ) {
    const meetingStartMoment = moment.tz(
      meetingEvent.meeting.eventStart,
      meetingEvent.meeting.eventTimezone || moment.tz.guess(),
    );
    const meetingEndMoment = moment.tz(
      meetingEvent.meeting.eventEnd,
      meetingEvent.meeting.eventTimezone || moment.tz.guess(),
    );
    if (meetingEvent.meeting.eventAllDay) {
      meetingDateFull = `All Day: ${meetingStartMoment.format(
        'M/D/YY',
      )} - ${meetingEndMoment.format('M/D/YY')}`;
      if (meetingStartMoment.isSame(meetingEndMoment, 'day')) {
        if (nowMoment.isSame(meetingStartMoment, 'year')) {
          meetingDateStr = `All Day: ${meetingStartMoment.format('MMMM Do')}`;
        } else {
          meetingDateStr = `All Day: ${meetingStartMoment.format('M/D/YY')}`;
        }
      }
    } else {
      const startTimezone = meetingStartMoment.format('z');
      meetingDateFull = `${meetingStartMoment.format(
        'M/D/YY',
      )} at ${meetingStartMoment.format('h:mma')} - ${meetingEndMoment.format(
        'M/D/YY',
      )} at ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
      if (meetingStartMoment.isSame(meetingEndMoment, 'day')) {
        if (nowMoment.isSame(meetingStartMoment, 'day')) {
          meetingDateStr = `Today: ${meetingStartMoment.format(
            'h:mma',
          )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
        } else if (meetingStartMoment.isBefore(nowMoment)) {
          if (
            nowMoment
              .clone()
              .subtract(1, 'day')
              .isSame(meetingStartMoment, 'day')
          ) {
            meetingDateStr = `Yesterday: ${meetingStartMoment.format(
              'h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          } else if (nowMoment.isSame(meetingStartMoment, 'year')) {
            meetingDateStr = `${meetingStartMoment.format(
              'MMMM Do: h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          } else {
            meetingDateStr = `${meetingStartMoment.format(
              'M/D/YY: h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          }
        } else if (meetingStartMoment.isAfter(nowMoment)) {
          if (nowMoment.isSame(meetingStartMoment, 'week')) {
            meetingDateStr = `${meetingStartMoment.format(
              'dddd: h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          } else if (nowMoment.isSame(meetingStartMoment, 'year')) {
            meetingDateStr = `${meetingStartMoment.format(
              'MMMM Do: h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          } else {
            meetingDateStr = `${meetingStartMoment.format(
              'M/D/YY: h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          }
        }
      } else if (
        nowMoment.isSame(meetingStartMoment, 'year') &&
        nowMoment.isSame(meetingEndMoment, 'year')
      ) {
        meetingDateStr = `${meetingStartMoment.format(
          'MMMM Do',
        )} at ${meetingStartMoment.format('h:mma')} - ${meetingEndMoment.format(
          'MMMM Do',
        )} at ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
      }
    }
    if (!meetingDateStr) {
      meetingDateStr = meetingDateFull;
    }
  }
  let meetingStatus = '';
  let meetingStatusStr = '';
  if (
    meetingEvent.meeting &&
    meetingEvent.meeting.eventStatus === 'CANCELLED'
  ) {
    meetingStatus = 'CANCELED';
    meetingStatusStr = 'Meeting Canceled';
  } else if (
    meetingEvent.meeting &&
    meetingEvent.meeting.expertResponse === 'ACCEPTED'
  ) {
    meetingStatus = 'ACCEPTED';
    meetingStatusStr = 'Meeting Accepted';
  } else if (
    meetingEvent.meeting &&
    meetingEvent.meeting.expertResponse === 'DECLINED'
  ) {
    meetingStatus = 'DECLINED';
    meetingStatusStr = 'Meeting Declined';
  }
  return (
    <div
      className={
        'ThreadEvent MeetingThreadEvent ' +
        (meetingEvent.ownerHuman
          ? ' ThreadEventOwnerHuman '
          : ' ThreadEventOwnerExpert ') +
        (isSolo ? ' ThreadEventSolo ' : '') +
        (replyText
          ? ' MeetingThreadEventReply '
          : ' MeetingThreadEventInvite ') +
        (hasUnknownHuman ? ' ThreadEventWithUnknown ' : '') +
        (className || '')
      }
    >
      <div className="ThreadEventWrapper">
        <div className="ThreadEventTop">
          <div className="ThreadEventTopBubbles">
            {meetingEvent.ownerHuman && (
              <HumanUserBubble
                firstName={
                  meetingEvent.ownerHuman && meetingEvent.ownerHuman.firstName
                }
                lastName={
                  meetingEvent.ownerHuman && meetingEvent.ownerHuman.lastName
                }
                fallbackEmail={
                  meetingEvent.ownerHuman &&
                  meetingEvent.ownerHuman.primaryEmail
                }
                primary
              />
            )}
            {meetingEvent.recipientHumans.map((r) => (
              <HumanUserBubble
                key={r.id}
                firstName={r.firstName}
                lastName={r.lastName}
                fallbackEmail={r.primaryEmail}
              />
            ))}
          </div>
          {cardTitle && <div className="ThreadEventTopTitle">{cardTitle}</div>}
          <div className="ThreadEventTopDate" data-tip={eventDateFull}>
            {eventDateSummary}
          </div>
        </div>
        <div className="ThreadEventCardWrapper">
          <div className="ThreadEventCardLeft">
            {meetingEvent.ownerHuman && (
              <div className="ThreadEventCardLeftUser">
                <HumanUserBubble
                  firstName={
                    meetingEvent.ownerHuman && meetingEvent.ownerHuman.firstName
                  }
                  lastName={
                    meetingEvent.ownerHuman && meetingEvent.ownerHuman.lastName
                  }
                  primary
                />
              </div>
            )}
          </div>
          <div className="ThreadEventCard">
            <div
              className="ThreadEventCardBody"
              onClick={() => showUnthreadOption && setShowUnthreadOption(false)}
            >
              {replyText ? (
                <div className="ThreadEventCardBodyContent">{replyText}</div>
              ) : (
                <div className="ThreadEventCardBodyMeeting">
                  <div className="ThreadEventCardBodyMeetingTitle">
                    {(meetingEvent.meeting &&
                      meetingEvent.meeting.eventTitle) ||
                      ''}
                  </div>
                  <div
                    className="ThreadEventCardBodyMeetingDate"
                    data-tip={meetingDateFull}
                  >
                    {meetingDateStr}
                  </div>
                  <div className="ThreadEventCardBodyMeetingLocation">
                    {(meetingEvent.meeting &&
                      meetingEvent.meeting.eventLocation) ||
                      ''}
                  </div>
                  <div className="ThreadEventCardBodyMeetingNotes">
                    {(meetingEvent.meeting &&
                      meetingEvent.meeting.eventNotes) ||
                      ''}
                  </div>
                  {meetingEvent.meeting &&
                  meetingEvent.meeting.organizerHuman ? (
                    <Fragment>
                      {meetingEvent.meeting &&
                      meetingEvent.meeting.eventStatus === 'ACTIVE' &&
                      meetingEvent.meeting.expertResponse === 'NEEDS-ACTION' &&
                      !!onInviteResponse ? (
                        <div className="ThreadEventCardBodyMeetingAction">
                          {expertDetails &&
                            (!expertDetails.nylasAccessToken ||
                              !expertDetails.nylasCalendarId) && (
                              <div className="ThreadEventCardBodyMeetingActionAlert">
                                Reminder to link{' '}
                                <Link
                                  to="/profile?calendar=setup"
                                  className="ThreadEventCardBodyMeetingActionAlertLink"
                                >
                                  your personal calendar
                                </Link>{' '}
                                to stay up to date with your Storetasker
                                meetings.
                              </div>
                            )}
                          <div
                            onClick={() =>
                              onInviteResponse &&
                              onInviteResponse(meetingEvent.meeting, 'ACCEPTED')
                            }
                            className="ThreadEventCardBodyMeetingActionBtn ThreadEventCardBodyMeetingActionAccept"
                          >
                            Accept
                          </div>
                          <div
                            onClick={() =>
                              onInviteResponse &&
                              onInviteResponse(meetingEvent.meeting, 'DECLINED')
                            }
                            className="ThreadEventCardBodyMeetingActionBtn ThreadEventCardBodyMeetingActionDecline"
                          >
                            Decline
                          </div>
                        </div>
                      ) : (
                        <div
                          className={
                            'ThreadEventCardBodyMeetingStatus ThreadEventCardBodyMeetingStatus' +
                            meetingStatus
                          }
                        >
                          {meetingStatusStr}
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {!!meetingEvent.meeting &&
                        meetingEvent.action === 'CREATE' &&
                        !!meetingEvent.meeting.requestedByHuman && (
                          <div className="ThreadEventCardBodyMeetingAction">
                            <div className="ThreadEventCardBodyMeetingActionAlert">
                              Client used scheduling link
                            </div>
                          </div>
                        )}
                      {!!meetingEvent.meeting &&
                        meetingEvent.meeting.eventStatus === 'ACTIVE' &&
                        !!meetingEvent.meeting.participantHumansStatus &&
                        !!meetingEvent.meeting.participantHumansStatus
                          .length && (
                          <ul className="ThreadEventCardBodyMeetingInvites">
                            {meetingEvent.meeting.participantHumansStatus.map(
                              (p) =>
                                p.human &&
                                (p.human.firstName ||
                                  p.human.lastName ||
                                  p.human.primaryEmail) ? (
                                  <li
                                    key={p.human.id}
                                    className="ThreadEventCardBodyMeetingInvitePerson"
                                  >
                                    {(
                                      (p.human.firstName || '').trim() +
                                      ' ' +
                                      (p.human.lastName || '').trim()
                                    ).trim() ||
                                      (p.human.primaryEmail || '').trim()}{' '}
                                    -{' '}
                                    <span>
                                      {meetingResponseFormatted(p.status, true)}
                                    </span>
                                  </li>
                                ) : null,
                            )}
                          </ul>
                        )}
                    </Fragment>
                  )}
                </div>
              )}
            </div>
            {!!hasUnknownHuman && (
              <div
                className={
                  'ThreadEventCardBodyUnthread ThreadEventCardBodyUnthread' +
                  (showUnthreadOption ? 'Active' : 'Inactive')
                }
              >
                <div
                  className="ThreadEventCardBodyUnthreadToggle"
                  onClick={() => setShowUnthreadOption(!showUnthreadOption)}
                />
                {!!showUnthreadOption && (
                  <div
                    className={
                      'ThreadEventCardBodyUnthreadOption ' +
                      (isUnthreadLoading
                        ? ' ThreadEventCardBodyUnthreadOptionLoading '
                        : '')
                    }
                    onClick={() =>
                      onUnthreadMeeting && onUnthreadMeeting(meetingEvent.id)
                    }
                  >
                    Unthread and move to inbound
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="ThreadEventCardRight">
            {meetingEvent.ownerExpert && (
              <div className="ThreadEventCardRightUser">
                <ExpertUserBubble
                  expertId={meetingEvent.ownerExpert.id}
                  expertDetails={meetingEvent.ownerExpert}
                  primary
                />
              </div>
            )}
          </div>
        </div>
        {!hasUnknownHuman && (
          <div className="ThreadEventBottom">
            {!!onReplyClick && (
              <div className="ThreadEventBottomReply" onClick={onReplyClick}>
                Reply Via Email
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

MeetingThreadEvent.propTypes = {
  className: PropTypes.string.isRequired,
  expertDetails: PropTypes.object,
  hasUnknownHuman: PropTypes.bool,
  isUnthreadLoading: PropTypes.bool,
  meetingEvent: PropTypes.object.isRequired,
  onInviteResponse: PropTypes.func,
  onReplyClick: PropTypes.func,
  onUnthreadMeeting: PropTypes.func,
  threadEventId: PropTypes.string.isRequired,
  threadEventTimestamp: PropTypes.number.isRequired,
};

MeetingThreadEvent.defaultProps = {
  className: '',
  expertDetails: null,
  hasUnknownHuman: false,
  isUnthreadLoading: false,
  onInviteResponse: null,
  onReplyClick: null,
  onUnthreadMeeting: () => {},
};

export default MeetingThreadEvent;
