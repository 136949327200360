/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import React, { useState, useEffect, useContext, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { convertToRaw, ContentState } from 'draft-js';
import TextareaAutosize from 'react-autosize-textarea';
import {
  AuthHumanQuery,
  BrandForHumansQuery,
  RequestActiveForBrandsQuery,
  RequestDetailsHumanQuery,
  RequestDetailsHumanQueryVariables,
  RequestSwitchToTextMutation,
  RequestSwitchToTextMutationVariables,
  BrandSetStorefrontPasswordMutation,
  BrandSetStorefrontPasswordMutationVariables,
  RequestEditByHumanMutation,
  RequestEditByHumanMutationVariables,
  RequestTagsByHumanMutation,
  RequestTagsByHumanMutationVariables,
  BrandEditByHumanMutation,
  BrandEditByHumanMutationVariables,
  RequestUnmatchByHumanMutation,
  RequestUnmatchByHumanMutationVariables,
  RequestStopSearchMultipleByHumanMutation,
  RequestStopSearchMultipleByHumanMutationVariables,
  RequestCancelByHumanMutation,
  RequestCancelByHumanMutationVariables,
} from '../../gql/graphql';
import urlUtils from '../../utils/url';
import {
  EXPERIENCE_OPTIONS,
  FOCUS_OPTIONS,
  CONTINENTS,
  SKILL_OPTIONS,
  TOOL_OPTIONS,
  TIMELINE_OPTIONS,
} from '../../utils/constants';
import envUtils from '../../utils/env';
import { HumanSummary, BrandSummary, RequestDetail } from '../../utils/gql';
import { IFilestackFileUpload } from '../../utils/props';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import errorUtils from '../../utils/error';
import logError from '../../utils/airbrake';
import {
  formatNumberWithCommas,
  centsDollarsRounded,
  phoneErrorSubtitle,
  phoneErrorTitle,
  tagLabelExperience,
  tagLabelFocus,
  tagLabelSkill,
  tagLabelTimeline,
  tagLabelTool,
} from '../../utils/format';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import RichTextEditor from '../feature/RichTextEditor';
import MultiSelect from '../feature/MultiSelect';
import PhoneNumberInput from '../feature/PhoneNumberInput';
import '../../styles/page/HumanRequestDetail.scss';
const ReactFilestack = require('filestack-react').default; // eslint-disable-line @typescript-eslint/no-var-requires

const requestDetailsHumanQuery = gql`
  query RequestDetailsHuman($requestId: ID!) {
    requestDetails(requestId: $requestId) {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const requestSwitchToTextMutation = gql`
  mutation RequestSwitchToText($requestId: ID!, $phone: String!) {
    requestSwitchToTextWithStatus(requestId: $requestId, phone: $phone) {
      phoneStatus
      human {
        ...HumanSummary
      }
    }
  }
  ${HumanSummary}
`;

const brandSetStorefrontPasswordMutation = gql`
  mutation BrandSetStorefrontPassword(
    $brandId: ID!
    $shopifyStorefrontPassword: String!
  ) {
    brandSetStorefrontPassword(
      brandId: $brandId
      shopifyStorefrontPassword: $shopifyStorefrontPassword
    ) {
      ...BrandSummary
    }
  }
  ${BrandSummary}
`;

const requestEditByHumanMutation = gql`
  mutation RequestEditByHuman(
    $requestId: ID!
    $description: String!
    $descriptionPlainText: String!
    $newFiles: [FileDetails!]!
    $existingFiles: [ID!]!
  ) {
    requestEditByHuman(
      requestId: $requestId
      description: $description
      descriptionPlainText: $descriptionPlainText
      newFiles: $newFiles
      existingFiles: $existingFiles
    ) {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const requestTagsByHumanMutation = gql`
  mutation RequestTagsByHuman(
    $requestId: ID!
    $tagBudgetMax: String!
    $tagBudgetMin: String!
    $tagExperience: String!
    $tagFocus: [String!]!
    $tagLocations: [String!]!
    $tagSkills: [String!]!
    $tagTimeline: String!
    $tagTools: [String!]!
  ) {
    requestTagsByHuman(
      requestId: $requestId
      tagBudgetMax: $tagBudgetMax
      tagBudgetMin: $tagBudgetMin
      tagExperience: $tagExperience
      tagFocus: $tagFocus
      tagLocations: $tagLocations
      tagSkills: $tagSkills
      tagTimeline: $tagTimeline
      tagTools: $tagTools
    ) {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const brandEditByHumanMutation = gql`
  mutation BrandEditByHuman($brandId: ID!, $name: String!, $url: String!) {
    brandEditByHuman(brandId: $brandId, name: $name, url: $url) {
      ...BrandSummary
    }
  }
  ${BrandSummary}
`;

const requestUnmatchByHumanMutation = gql`
  mutation RequestUnmatchByHuman(
    $requestId: ID!
    $reason: String!
    $moreDetail: String
  ) {
    requestUnmatchByHuman(
      requestId: $requestId
      reason: $reason
      moreDetail: $moreDetail
    ) {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const requestStopSearchMultipleByHumanMutation = gql`
  mutation RequestStopSearchMultipleByHuman($requestId: ID!, $reason: String!) {
    requestStopSearchMultipleByHuman(requestId: $requestId, reason: $reason) {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const requestCancelByHumanMutation = gql`
  mutation RequestCancelByHuman(
    $requestId: ID!
    $reason: String!
    $moreDetail: String
  ) {
    requestCancelByHuman(
      requestId: $requestId
      reason: $reason
      moreDetail: $moreDetail
    ) {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const tzGuess = moment.tz.guess();
const alsoInEst =
  tzGuess === 'America/New_York' ||
  moment.tz(new Date(), tzGuess).hour() ===
    moment.tz(new Date(), 'America/New_York').hour();

interface HumanRequestDetailProps {
  activeRequests: RequestActiveForBrandsQuery['requestActiveForBrands'];
  brands: BrandForHumansQuery['brandForHumans'];
  hasDiscountCode: string;
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
  requestId: string;
  showUpgradeModal: () => void;
  socketClient: ClientWithOnReconnected;
}

const HumanRequestDetail = ({
  activeRequests,
  brands,
  human,
  requestId,
  showUpgradeModal,
  socketClient,
}: HumanRequestDetailProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const [primaryPanel, setPrimaryPanel] = useState('Details');
  const secondaryPanels = ['Details'].filter((p) => p !== primaryPanel);
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
  }
  const [currModal, setCurrModal] = useState(() => {
    if (urlUtils.getQueryParam('cancel') === 'true') return 'cancel';
    if (urlUtils.getQueryParam('change') === 'true') return 'change';
    if (urlUtils.getQueryParam('stop') === 'true') return 'stop';
    return '';
  });
  const [currTime, setCurrTime] = useState(() => new Date().getTime());
  const [showTagEditing, setShowTagEditing] = useState(false);
  const [sitePassword, setSitePassword] = useState('');
  const [sitePasswordFocus, setSitePasswordFocus] = useState(false);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(true);
  const [contactPhone, setContactPhone] = useState('');
  const [contactPhoneValid, setContactPhoneValid] = useState(true);
  const [editBrandName, setEditBrandName] = useState('');
  const [editBrandUrl, setEditBrandUrl] = useState('');
  const [editRequestContent, setEditRequestContent] =
    useState<ContentState | null>(null);
  const [initEditRequestContent, setInitEditRequestContent] = useState('');
  const [editRequestFiles, setEditRequestFiles] = useState<
    IFilestackFileUpload[]
  >([]);
  const [editRequestTagTools, setEditRequestTagTools] = useState(
    [] as string[],
  );
  const [editRequestTagFocus, setEditRequestTagFocus] = useState(
    [] as string[],
  );
  const [editRequestTagLocations, setEditRequestTagLocations] = useState(
    [] as string[],
  );
  const [editRequestTagSkills, setEditRequestTagSkills] = useState(
    [] as string[],
  );
  const [editRequestTagExperience, setEditRequestTagExperience] = useState('');
  const [editRequestTagTimeline, setEditRequestTagTimeline] = useState('');
  const [editRequestTagBudgetMax, setEditRequestTagBudgetMax] = useState('');
  const [editRequestTagBudgetMin, setEditRequestTagBudgetMin] = useState('');
  const [cancelReason, setCancelReason] = useState('');
  const [cancelMisfit, setCancelMisfit] = useState('');
  const [cancelSaved, setCancelSaved] = useState('');
  const [cancelMoreDetail, setCancelMoreDetail] = useState('');
  const [changeReason, setChangeReason] = useState('');
  const [changeMoreDetail, setChangeMoreDetail] = useState('');
  const [actionLoading, setActionLoading] = useState(false);
  const [calendlyScheduled, setCalendlyScheduled] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  useEffect(() => {
    const getTime = () => {
      setCurrTime(new Date().getTime());
    };
    const interval = setInterval(getTime, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useCalendlyEventListener({
    onEventScheduled: () => setCalendlyScheduled(true),
  });
  const [trySwitchToText] = useMutation<
    RequestSwitchToTextMutation,
    RequestSwitchToTextMutationVariables
  >(requestSwitchToTextMutation);
  const [trySavePassword] = useMutation<
    BrandSetStorefrontPasswordMutation,
    BrandSetStorefrontPasswordMutationVariables
  >(brandSetStorefrontPasswordMutation);
  const [tryUnmatchRequest] = useMutation<
    RequestUnmatchByHumanMutation,
    RequestUnmatchByHumanMutationVariables
  >(requestUnmatchByHumanMutation);
  const [tryCancelRequest] = useMutation<
    RequestCancelByHumanMutation,
    RequestCancelByHumanMutationVariables
  >(requestCancelByHumanMutation);
  const [tryStopSearch] = useMutation<
    RequestStopSearchMultipleByHumanMutation,
    RequestStopSearchMultipleByHumanMutationVariables
  >(requestStopSearchMultipleByHumanMutation);
  const [tryEditBrand] = useMutation<
    BrandEditByHumanMutation,
    BrandEditByHumanMutationVariables
  >(brandEditByHumanMutation);
  const [tryEditRequest] = useMutation<
    RequestEditByHumanMutation,
    RequestEditByHumanMutationVariables
  >(requestEditByHumanMutation);
  const [tryTagsRequest] = useMutation<
    RequestTagsByHumanMutation,
    RequestTagsByHumanMutationVariables
  >(requestTagsByHumanMutation);
  const {
    loading: loadingRequest,
    error: errorRequest,
    data: dataRequest,
    refetch: refetchRequest,
  } = useQuery<RequestDetailsHumanQuery, RequestDetailsHumanQueryVariables>(
    requestDetailsHumanQuery,
    {
      onCompleted: ({ requestDetails: requestDetailsCompleted }) => {
        if (
          requestDetailsCompleted &&
          requestDetailsCompleted.isActive &&
          !requestDetailsCompleted.currentMatchStr &&
          !requestDetailsCompleted.projectPrefab
        ) {
          setEditRequestTagExperience(requestDetailsCompleted.tagExperience);
          setEditRequestTagFocus(requestDetailsCompleted.tagFocus);
          setEditRequestTagLocations(requestDetailsCompleted.tagLocations);
          setEditRequestTagSkills(requestDetailsCompleted.tagSkills);
          setEditRequestTagTools(requestDetailsCompleted.tagTools);
          setEditRequestTagTimeline(requestDetailsCompleted.tagTimeline);
          setEditRequestTagBudgetMax(requestDetailsCompleted.tagBudgetMax);
          setEditRequestTagBudgetMin(requestDetailsCompleted.tagBudgetMin);
          setShowTagEditing(true);
        }
      },
      returnPartialData: true,
      variables: {
        requestId,
      },
    },
  );
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('HumanRequestDetail socketClient onReconnected');
      refetchRequest().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchRequest]);
  const requestDetails =
    dataRequest && dataRequest.requestDetails && dataRequest.requestDetails.id
      ? dataRequest.requestDetails
      : undefined;
  let brandForRequest: BrandForHumansQuery['brandForHumans'][0] | undefined;
  let requestLoadingError = false;
  if (requestDetails && requestDetails.brandStr) {
    brandForRequest = brands.find((b) => b.id === requestDetails.brandStr);
    if (!brandForRequest) {
      requestLoadingError = true;
    }
  }
  if (errorRequest) {
    requestLoadingError = true;
  }
  let cleanModal = currModal;
  if (requestDetails) {
    if (cleanModal === 'change' && requestDetails.isMultipleMatch) {
      cleanModal = '';
    } else if (cleanModal === 'change' && !requestDetails.isActive) {
      cleanModal = 'change-support';
    } else if (!requestDetails.isActive) {
      cleanModal = '';
    } else if (
      cleanModal === 'edit' &&
      (requestDetails.currentMatchStr || requestDetails.projectPrefab)
    ) {
      cleanModal = '';
    } else if (cleanModal === 'change' && !requestDetails.currentMatchStr) {
      cleanModal = '';
    } else if (cleanModal === 'stop' && !requestDetails.isMultipleMatch) {
      cleanModal = '';
    } else if (cleanModal === 'stop' && !requestDetails.currentMatchStr) {
      cleanModal = '';
    } else if (
      cleanModal === 'cancel' &&
      requestDetails.isMultipleMatch &&
      requestDetails.currentMatchStr
    ) {
      cleanModal = '';
    }
  }
  function phoneNumberChange(phone: string, isValid: boolean) {
    setContactPhone(phone);
    setPhoneError('');
    if (!phone || isValid) {
      setContactPhoneValid(true);
    }
  }
  function phoneNumberBlur(isValid: boolean) {
    setPhoneError('');
    setContactPhoneValid(!contactPhone || isValid);
  }
  function savePhoneNumber() {
    if (!contactPhone || !contactPhoneValid) {
      addNotification('Please submit a valid phone number!', undefined, 5000);
      return;
    }
    setPhoneError('');
    trySwitchToText({
      optimisticResponse: {
        requestSwitchToTextWithStatus: {
          __typename: 'HumanWithPhoneStatus',
          human: {
            ...human,
            primaryPhone: contactPhone,
          },
          phoneStatus: 'CONFIRMED',
        },
      },
      variables: {
        phone: contactPhone,
        requestId,
      },
    })
      .then(({ data }) => {
        if (
          data &&
          data.requestSwitchToTextWithStatus &&
          data.requestSwitchToTextWithStatus.phoneStatus
        ) {
          if (
            data.requestSwitchToTextWithStatus.phoneStatus === 'PRE_REGISTER'
          ) {
            setPhoneError('PRE_REGISTER');
          } else if (
            data.requestSwitchToTextWithStatus.phoneStatus === 'NOT_MOBILE'
          ) {
            setPhoneError('NOT_MOBILE');
          }
        }
      })
      .catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Save Phone Error');
        logError(err, {
          component: 'HumanRequestDetail',
          func: 'trySwitchToText',
        });
      });
  }
  function saveSitePassword() {
    if (!requestDetails || !requestDetails.brandStr) return;
    if (!sitePassword || !sitePassword.trim()) {
      setShowPasswordPrompt(false);
      return;
    }
    setShowPasswordPrompt(false);
    trySavePassword({
      variables: {
        brandId: requestDetails.brandStr,
        shopifyStorefrontPassword: sitePassword,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Save Password Error');
      logError(err, {
        component: 'HumanRequestDetail',
        func: 'trySavePassword',
      });
    });
  }
  function fileError(error: Error) {
    addNotification(
      (error && error.message) || 'File upload error. Please try again.',
      undefined,
      5000,
    );
    console.log('Filestack fileError', error);
  }
  function fileUploaded(filestackRes: any) {
    console.log('Filestack fileUploaded', filestackRes);
    if (filestackRes && filestackRes.filesUploaded) {
      setEditRequestFiles(
        editRequestFiles.concat(
          filestackRes.filesUploaded.map((f: any) => ({
            container: f.container || '',
            filename: f.filename || '',
            handle: f.handle || '',
            key: f.key || '',
            mimetype: f.mimetype || '',
            size: (f.size || '').toString(),
            source: f.source || '',
            uploadId: f.uploadId || '',
            url: f.url || '',
          })) as IFilestackFileUpload[],
        ),
      );
    }
  }
  function removeFile(filestackUploadUrl: string) {
    console.log('Filestack removeFile', filestackUploadUrl);
    setEditRequestFiles(
      editRequestFiles.filter((f) => f.url !== filestackUploadUrl),
    );
  }
  function startEditing() {
    if (!requestDetails || !brandForRequest) {
      return;
    }
    setEditBrandName(brandForRequest.name);
    setEditBrandUrl(
      brandForRequest.url || brandForRequest.shopifyAdminURL || '',
    );
    setEditRequestFiles(
      (requestDetails.files || []).map((f) => ({
        container: '',
        filename: f.filename,
        key: '',
        url: f.url,
      })),
    );
    setInitEditRequestContent(requestDetails.content);
    setCurrModal('edit');
    setShowSidebar(false);
  }
  function stopEditing() {
    setEditBrandName('');
    setEditBrandUrl('');
    setEditRequestFiles([]);
    setInitEditRequestContent('');
    setCurrModal('');
  }
  function startTagging() {
    if (!requestDetails) {
      return;
    }
    setEditRequestTagExperience(requestDetails.tagExperience);
    setEditRequestTagFocus(requestDetails.tagFocus);
    setEditRequestTagLocations(requestDetails.tagLocations);
    setEditRequestTagSkills(requestDetails.tagSkills);
    setEditRequestTagTools(requestDetails.tagTools);
    setEditRequestTagTimeline(requestDetails.tagTimeline);
    setEditRequestTagBudgetMax(requestDetails.tagBudgetMax);
    setEditRequestTagBudgetMin(requestDetails.tagBudgetMin);
    setShowTagEditing(true);
  }
  function onTagRequest() {
    if (actionLoading || !requestDetails || requestDetails.projectPrefab) {
      return;
    }
    setActionLoading(true);
    tryTagsRequest({
      optimisticResponse: {
        requestTagsByHuman: {
          ...requestDetails,
          tagBudgetMax: editRequestTagBudgetMax,
          tagBudgetMin: editRequestTagBudgetMin,
          tagExperience: editRequestTagExperience,
          tagFocus: editRequestTagFocus,
          tagLocations: editRequestTagLocations,
          tagSkills: editRequestTagSkills,
          tagTimeline: editRequestTagTimeline,
          tagTools: editRequestTagTools,
        },
      },
      variables: {
        requestId,
        tagBudgetMax: editRequestTagBudgetMax,
        tagBudgetMin: editRequestTagBudgetMin,
        tagExperience: editRequestTagExperience,
        tagFocus: editRequestTagFocus,
        tagLocations: editRequestTagLocations,
        tagSkills: editRequestTagSkills,
        tagTimeline: editRequestTagTimeline,
        tagTools: editRequestTagTools,
      },
    })
      .then(() => {
        setActionLoading(false);
        setShowTagEditing(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Request Tag Error');
        logError(err, {
          component: 'HumanRequestDetail',
          func: 'tryTagsRequest',
        });
      });
  }
  function onSaveRequest() {
    let draftContent = '';
    let plainTextContent = '';
    if (editRequestContent && editRequestContent.hasText()) {
      plainTextContent = editRequestContent.getPlainText().trim();
      if (plainTextContent.replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(editRequestContent));
        } catch (rawError) {
          console.log('edit request convertToRaw error', rawError);
        }
      }
    }
    if (plainTextContent.length < 75) {
      addNotification('Please write at least 75 characters!', undefined, 5000);
      return Promise.reject();
    }
    if (!requestDetails || !editRequestFiles || !draftContent) {
      addNotification('An error occured, please try again');
      logError('somehow we do not have requestDetails or draftContent', {
        component: 'HumanRequestDetail',
        func: 'onSaveRequest',
      });
      return Promise.reject();
    }
    const existingFiles = (requestDetails.files || [])
      .filter((f) => editRequestFiles.find((o) => o.url === f.url))
      .map((f) => f.id);
    const newFiles = editRequestFiles.filter(
      (f) => !(requestDetails.files || []).find((o) => o.url === f.url),
    );
    return tryEditRequest({
      optimisticResponse: {
        requestEditByHuman: {
          ...requestDetails,
          content: draftContent,
          contentPlainText: plainTextContent,
          files: editRequestFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            id: 'tempRequestFileId' + i.toString(),
          })),
        },
      },
      variables: {
        description: draftContent,
        descriptionPlainText: plainTextContent,
        existingFiles,
        newFiles,
        requestId,
      },
    })
      .then(() => true)
      .catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Request Edit Error',
        );
        logError(err, {
          component: 'HumanRequestDetail',
          func: 'tryEditRequest',
        });
        return false;
      });
  }
  function onSaveBrand() {
    if (!editBrandName.trim()) {
      addNotification(
        'Please submit a brand name and a website url!',
        undefined,
        5000,
      );
      return Promise.reject();
    }
    const checkUrl = (editBrandUrl || '').trim();
    const findDot = checkUrl.lastIndexOf('.');
    if (
      !checkUrl ||
      checkUrl.indexOf(' ') >= 0 ||
      findDot <= 0 ||
      findDot >= checkUrl.length - 2
    ) {
      addNotification(
        "This website url doesn't look right. Please use a valid url.",
        undefined,
        5000,
      );
      return Promise.reject();
    }
    if (!brandForRequest) {
      addNotification('An error occured, please try again');
      logError('somehow we do not have brandForRequest', {
        component: 'HumanRequestDetail',
        func: 'onSaveBrand',
      });
      return Promise.reject();
    }
    return tryEditBrand({
      optimisticResponse: {
        brandEditByHuman: {
          ...brandForRequest,
          name: editBrandName,
          url: editBrandUrl,
        },
      },
      variables: {
        brandId: brandForRequest.id,
        name: editBrandName,
        url: editBrandUrl,
      },
    })
      .then(() => true)
      .catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Brand Edit Error');
        logError(err, {
          component: 'HumanRequestDetail',
          func: 'tryEditBrand',
        });
        return false;
      });
  }
  function saveRequestAndBrand() {
    if (actionLoading) return;
    setActionLoading(true);
    const promises = [];
    if (requestDetails && !requestDetails.projectPrefab) {
      promises.push(onSaveRequest());
    }
    promises.push(onSaveBrand());
    Promise.all(promises)
      .then((results) => {
        setActionLoading(false);
        if (results.indexOf(false) === -1) {
          setCurrModal('');
        }
      })
      .catch(() => {
        setActionLoading(false);
      });
  }
  const isCancelTopLevelReady =
    [
      'TAKING_TOO_LONG',
      'FIGURED_IT_OUT_MYSELF',
      'NO_LONGER_PURSUING_THIS_PROJECT',
    ].indexOf(cancelReason) >= 0;
  const isCancelExtraLevelReady =
    cancelMoreDetail &&
    ['FOUND_SOMEONE_ELSE', 'OTHER'].indexOf(cancelReason) >= 0;
  const isCancelWhyLevelReady =
    cancelSaved === 'NO' &&
    cancelMoreDetail &&
    ['EXPERT_IS_UNRESPONSIVE', 'EXPERT_DIFFICULT_TO_WORK_WITH'].indexOf(
      cancelReason,
    ) >= 0;

  const isCancelFullLevelReady =
    cancelReason && cancelMisfit && cancelSaved === 'NO' && cancelMoreDetail;
  const isCancelReady =
    isCancelTopLevelReady ||
    isCancelExtraLevelReady ||
    isCancelWhyLevelReady ||
    isCancelFullLevelReady;
  const isChangeReady = !!(changeReason && changeMoreDetail);
  function onUnmatchRequest() {
    if (actionLoading) return;
    if (!isChangeReady) {
      addNotification('Please fill out all the fields!', undefined, 5000);
      return;
    }
    if (!requestDetails) {
      addNotification('An error occured, please try again');
      logError('somehow we do not have requestDetails', {
        component: 'HumanRequestDetail',
        func: 'onUnmatchRequest',
      });
      return;
    }
    setActionLoading(true);
    tryUnmatchRequest({
      optimisticResponse: {
        requestUnmatchByHuman: {
          ...requestDetails,
          currentMatchStr: null,
          waitSlowDelay: new Date().getTime() + 3 * 60 * 60 * 1000,
          waitStart: new Date().getTime(),
          waitType: 'WEEKDAY_NORMAL',
        },
      },
      variables: {
        moreDetail: changeMoreDetail,
        reason: changeReason,
        requestId,
      },
    })
      .then(() => {
        setActionLoading(false);
        setCurrModal('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Change Expert Error',
        );
        logError(err, {
          component: 'HumanRequestDetail',
          func: 'tryUnmatchRequest',
        });
      });
  }
  function onCancel() {
    if (actionLoading) return;
    if (!isCancelReady) {
      addNotification('Please fill out all the fields!', undefined, 5000);
      return;
    }
    if (!requestDetails) {
      addNotification('An error occured, please try again');
      logError('somehow we do not have requestDetails', {
        component: 'HumanRequestDetail',
        func: 'onCancel',
      });
      return;
    }
    setActionLoading(true);
    tryCancelRequest({
      optimisticResponse: {
        requestCancelByHuman: {
          ...requestDetails,
          cancelledAt: new Date().getTime(),
          cancelledHow: 'brand',
          cancelledMoreDetail: cancelMoreDetail,
          cancelledReason:
            cancelReason === 'NOT_A_FIT' ? cancelMisfit : cancelReason,
          currentMatchStr: null,
          isActive: false,
        },
      },
      variables: {
        moreDetail: cancelMoreDetail,
        reason: cancelReason === 'NOT_A_FIT' ? cancelMisfit : cancelReason,
        requestId,
      },
    })
      .then(() => {
        setActionLoading(false);
        setCurrModal('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Cancel Request Error',
        );
        logError(err, {
          component: 'HumanRequestDetail',
          func: 'tryCancelRequest',
        });
      });
  }
  function onStopSearch() {
    if (actionLoading) return;
    if (!requestDetails) {
      addNotification('An error occured, please try again');
      logError('somehow we do not have requestDetails', {
        component: 'HumanRequestDetail',
        func: 'onStopSearch',
      });
      return;
    }
    setActionLoading(true);
    tryStopSearch({
      optimisticResponse: {
        requestStopSearchMultipleByHuman: {
          ...requestDetails,
          isActive: false,
        },
      },
      variables: {
        reason: 'HUMAN_STOPPED',
        requestId,
      },
    })
      .then(() => {
        setActionLoading(false);
        setCurrModal('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Stop Search Error');
        logError(err, {
          component: 'HumanRequestDetail',
          func: 'tryStopSearch',
        });
      });
  }
  function pickCancelReason(selectedReason: string) {
    setCancelReason(cancelReason === selectedReason ? '' : selectedReason);
    setCancelMisfit('');
    setCancelSaved('');
    setCancelMoreDetail('');
  }
  function pickCancelMisfit(selectedMisfit: string) {
    setCancelMisfit(cancelMisfit === selectedMisfit ? '' : selectedMisfit);
    setCancelSaved('');
    setCancelMoreDetail('');
  }
  function pickCancelSaved(selectedSaved: string) {
    setCancelSaved(cancelSaved === selectedSaved ? '' : selectedSaved);
    setCancelMoreDetail('');
  }
  function switchToChangeExpert() {
    setChangeReason(cancelMisfit || cancelReason || '');
    setChangeMoreDetail('');
    setCancelReason('');
    setCancelMisfit('');
    setCancelSaved('');
    setCancelMoreDetail('');
    setShowSidebar(false);
    setCurrModal('change');
  }
  function startChange() {
    setChangeReason('');
    setChangeMoreDetail('');
    setShowSidebar(false);
    setCurrModal('change');
  }
  function stopChange() {
    setChangeReason('');
    setChangeMoreDetail('');
    setCurrModal('');
  }
  function startStop() {
    setShowSidebar(false);
    setCurrModal('stop');
  }
  function stopStop() {
    setCurrModal('');
  }
  function startCancel() {
    setCancelReason('');
    setCancelMisfit('');
    setCancelSaved('');
    setCancelMoreDetail('');
    setShowSidebar(false);
    setCurrModal('cancel');
  }
  function stopCancel() {
    setCancelReason('');
    setCancelMisfit('');
    setCancelSaved('');
    setCancelMoreDetail('');
    setCurrModal('');
  }
  function pickChangeReason(selectedReason: string) {
    setChangeReason(changeReason === selectedReason ? '' : selectedReason);
    setChangeMoreDetail('');
  }
  let trySaveCancelStr = '';
  if (cancelMisfit === 'LOCATION_NOT_A_FIT') {
    trySaveCancelStr = 'yes, if they are closer to me';
  } else if (cancelMisfit === 'SKILLS_NOT_A_FIT') {
    trySaveCancelStr = 'yes, if they have the right skills';
  } else if (cancelMisfit === 'PORTFOLIO_NOT_A_FIT') {
    trySaveCancelStr = 'yes, if they have the right experience';
  } else if (cancelMisfit === 'TIMELINE_NOT_A_FIT') {
    trySaveCancelStr = 'yes, if they fit my timeline';
  } else if (cancelMisfit === 'QUOTE_NOT_A_FIT') {
    trySaveCancelStr = 'yes, if they fit my budget';
  } else if (cancelReason === 'EXPERT_IS_UNRESPONSIVE') {
    trySaveCancelStr = 'yes, please find someone else';
  } else if (cancelReason === 'EXPERT_DIFFICULT_TO_WORK_WITH') {
    trySaveCancelStr = 'yes, please find someone else';
  }
  let waitingRoomTop = '';
  let waitingRoomTitle = '';
  let waitingRoomExpectation = <Fragment />;
  if (
    requestDetails &&
    requestDetails.isActive &&
    !requestDetails.currentMatchStr &&
    requestDetails.waitSlowDelay &&
    requestDetails.waitStart
  ) {
    const currMoment = moment.tz(currTime, 'America/New_York');
    const startMoment = moment.tz(requestDetails.waitStart, 'America/New_York');
    const currTimeFull = currMoment.format('h:mma z');
    const currTimeSummary = currMoment.format('h:mma');
    const currTimeDay = currMoment.format('dddd');
    const currTimeExplanation = `Storetasker is based in New York City. It's ${currTimeSummary} on a ${currTimeDay} there.`;
    if (requestDetails.isMultipleMatch) {
      waitingRoomTop = 'No need to stick around.';
      waitingRoomTitle = "We're searching for your experts!";
      waitingRoomExpectation = (
        <Fragment>
          We&apos;ll leave your project brief open for up to 3 days or until
          we&apos;ve made 3 intros.
        </Fragment>
      );
    } else if (currTime < requestDetails.waitSlowDelay) {
      waitingRoomTop = requestDetails.matchLog.length
        ? 'Searching for a better fit...'
        : 'No need to stick around.';
      waitingRoomTitle = requestDetails.matchLog.length
        ? "We're looking for another expert for your project."
        : "We'll intro you to a talented Expert once we've found a great fit.";
      if (requestDetails.waitType === 'WEEKEND') {
        waitingRoomExpectation = (
          <Fragment>
            Our goal is to get you matched this weekend, but it may take until
            Monday morning
            {!alsoInEst ? (
              <Fragment>
                {' '}
                (<span data-tip={currTimeExplanation}>east coast time</span>).
              </Fragment>
            ) : (
              '.'
            )}
          </Fragment>
        );
      } else if (requestDetails.waitType === 'WEEKDAY_EVENING') {
        const isSameDay = startMoment.isSame(currMoment, 'day');
        const nextDayStr = requestDetails.matchLog.length
          ? "We're still searching for another expert who would a better fit. Our goal is to get you matched later this morning"
          : "We're still searching for your expert. Our goal is to get you matched later this morning";
        waitingRoomExpectation = (
          <Fragment>
            {isSameDay
              ? 'Our goal is to get you matched later tonight, but it may take until tomorrow morning'
              : nextDayStr}
            {!alsoInEst ? (
              <Fragment>
                {' '}
                (<span data-tip={currTimeExplanation}>east coast time</span>).
              </Fragment>
            ) : (
              '.'
            )}
          </Fragment>
        );
      } else if (requestDetails.waitType === 'WEEKDAY_NIGHT') {
        const isSameDay = startMoment.isSame(currMoment, 'day');
        const nextDayStr = requestDetails.matchLog.length
          ? "We're still searching for another expert who would a better fit. Our goal is to get you matched later this morning"
          : "We're still searching for your expert. Our goal is to get you matched later this morning";
        waitingRoomExpectation = isSameDay ? (
          <Fragment>
            It&apos;s getting late
            {!alsoInEst ? (
              <Fragment>
                {' '}
                (<span data-tip={currTimeExplanation}>{currTimeFull}</span>)
              </Fragment>
            ) : (
              ''
            )}
            , so our goal is to get you matched tomorrow morning. We&apos;ll let
            you know if it happens sooner.
          </Fragment>
        ) : (
          <Fragment>
            {nextDayStr}
            {!alsoInEst ? (
              <Fragment>
                {' '}
                (<span data-tip={currTimeExplanation}>east coast time</span>).
              </Fragment>
            ) : (
              '.'
            )}
          </Fragment>
        );
      } else if (requestDetails.waitType === 'WEEKDAY_LATE') {
        const isBeforeThree =
          startMoment.isSame(currMoment, 'day') && currMoment.hour() < 3;
        const afterThreeStr = requestDetails.matchLog.length
          ? "We're still searching for another expert who would a better fit. Our goal is to get you matched later this morning"
          : "We're still searching for your expert. Our goal is to get you matched later this morning";
        waitingRoomExpectation = isBeforeThree ? (
          <Fragment>
            It&apos;s pretty late
            {!alsoInEst ? (
              <Fragment>
                {' '}
                (<span data-tip={currTimeExplanation}>{currTimeFull}</span>)
              </Fragment>
            ) : (
              ''
            )}
            , so our goal is to get you matched in the morning. We&apos;ll let
            you know if it happens sooner.
          </Fragment>
        ) : (
          <Fragment>
            {afterThreeStr}
            {!alsoInEst ? (
              <Fragment>
                {' '}
                (<span data-tip={currTimeExplanation}>east coast time</span>).
              </Fragment>
            ) : (
              '.'
            )}
          </Fragment>
        );
      } else if (requestDetails.waitType === 'WEEKDAY_EARLY') {
        const isBeforeEight =
          startMoment.isSame(currMoment, 'day') && currMoment.hour() < 8;
        const afterEightStr = requestDetails.matchLog.length
          ? "We're still searching for another expert who would a better fit. Our goal is to get you matched later this morning"
          : "We're still searching for your expert. Our goal is to get you matched later this morning";
        waitingRoomExpectation = isBeforeEight ? (
          <Fragment>
            It&apos;s pretty early
            {!alsoInEst ? (
              <Fragment>
                {' '}
                (<span data-tip={currTimeExplanation}>{currTimeFull}</span>)
              </Fragment>
            ) : (
              ''
            )}
            , so our goal is to get you matched later this morning. We&apos;ll
            let you know if it happens sooner.
          </Fragment>
        ) : (
          <Fragment>
            {afterEightStr}
            {!alsoInEst ? (
              <Fragment>
                {' '}
                (<span data-tip={currTimeExplanation}>east coast time</span>).
              </Fragment>
            ) : (
              '.'
            )}
          </Fragment>
        );
      } else {
        // WEEKDAY_NORMAL or INSTANT
        waitingRoomExpectation = (
          <Fragment>
            Typically this takes 2-3 hours, but occasionally it takes a bit
            longer
          </Fragment>
        );
      }
    } else {
      waitingRoomTop = requestDetails.matchLog.length
        ? 'Still finding your new expert...'
        : 'Still finding your expert...';
      waitingRoomTitle = 'Sorry, this is taking a bit longer than expected.';
      waitingRoomExpectation = (
        <Fragment>
          We&apos;ll let you know as soon as we&apos;ve found a match. If we
          can&apos;t find someone in the next 24 hours, our Support Team will be
          notified to come help as well.
        </Fragment>
      );
    }
  }
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [requestDetails, currTime]);
  function blockClickPropagation(ev: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  if (loadingRequest) {
    // ignore these
  }
  const askForPassword =
    showPasswordPrompt &&
    requestDetails &&
    requestDetails.brand &&
    requestDetails.brand.createdAt &&
    currTime - requestDetails.brand.createdAt < 1000 * 60 * 60 * 24 * 15 &&
    requestDetails.brand.shopifyStorefrontPassword === 'has-secret-password';
  console.log(
    'askForPassword',
    askForPassword,
    showPasswordPrompt,
    requestDetails,
  );
  const editIsReadyForSubmit =
    !!editBrandName.trim() &&
    !!(editBrandUrl && editBrandUrl.trim() && editBrandUrl.indexOf('.') >= 1);
  const allExpertMatchLogs = (
    (requestDetails && requestDetails.matchLog) ||
    []
  ).filter((ml) => ml.matchedWho);
  const allExpertMatches = _.uniqBy(
    allExpertMatchLogs.map((ml) => ml.matchedWho),
    'id',
  );
  const activeExpertMatches = _.uniqBy(
    allExpertMatchLogs
      .filter((ml) => !ml.unmatchedAt)
      .map((ml) => ml.matchedWho),
    'id',
  );
  let titleWithAtLeastOneMatch = '';
  let subWithAtLeastOneMatch = <Fragment />;
  const showProTag =
    !!requestDetails &&
    !!requestDetails.isMultipleMatch &&
    !!human &&
    !!human.proStart;
  if (requestDetails && requestDetails.currentMatchStr) {
    if (activeExpertMatches.length === 1) {
      if (requestDetails.isActive && requestDetails.isMultipleMatch) {
        titleWithAtLeastOneMatch = "We've intro'd one expert";
        subWithAtLeastOneMatch = (
          <div className="ThreadDetailMainBodyFocusSubtitle">
            We&apos;re still searching for two more intros. Already found the
            perfect fit?{' '}
            <span
              className="ThreadDetailMainBodyFocusSubtitleLink"
              onClick={startStop}
            >
              Stop searching
            </span>
          </div>
        );
      } else {
        titleWithAtLeastOneMatch =
          'Matched with ' + (activeExpertMatches[0].firstName || 'your expert');
        subWithAtLeastOneMatch = (
          <div className="ThreadDetailMainBodyFocusSubtitle">
            We&apos;ve introduced you to{' '}
            {activeExpertMatches[0].firstName || 'your expert'} for this
            project.
          </div>
        );
      }
    } else {
      titleWithAtLeastOneMatch =
        "We've intro'd " +
        (activeExpertMatches.length === 2 ? 'two' : 'three') +
        ' experts';
      if (requestDetails.isActive) {
        subWithAtLeastOneMatch = (
          <div className="ThreadDetailMainBodyFocusSubtitle">
            We&apos;re still searching for one more intro. Already found the
            perfect fit?{' '}
            <span
              className="ThreadDetailMainBodyFocusSubtitleLink"
              onClick={startStop}
            >
              Stop searching
            </span>
          </div>
        );
      } else {
        subWithAtLeastOneMatch = (
          <div className="ThreadDetailMainBodyFocusSubtitle">
            If you still haven&apos;t found the perfect fit, you can{' '}
            <Link
              className="ThreadDetailMainBodyFocusSubtitleLink"
              to="/new?another=true"
            >
              start another search
            </Link>
          </div>
        );
      }
    }
  }
  return (
    <div className="DashboardModal ClientDetailModal HumanRequestDetailModal">
      <div className="DashboardModalTopCircle" />
      <div className="DashboardModalBottomCircle" />
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '') +
          (cleanModal ? ' ThreadDetailViewWithModal ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <Link to="/projects" className="ThreadDetailMainHeaderBack">
              Projects
            </Link>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody">
            {!!requestDetails && (
              <div className="ThreadDetailMainBodyFocus">
                {!requestDetails.currentMatchStr ? (
                  <Fragment>
                    {requestDetails.isActive ? (
                      <Fragment>
                        {showProTag ? (
                          <div className="ThreadDetailMainBodyFocusTitleTag">
                            Storetasker Pro
                          </div>
                        ) : (
                          <div className="ThreadDetailMainBodyFocusTitleSuper">
                            {waitingRoomTop}
                          </div>
                        )}
                        <div className="ThreadDetailMainBodyFocusMedtitle">
                          {waitingRoomTitle}
                        </div>
                        <div className="RequestDetailArrowDown">
                          <div className="RequestDetailWaitExpectation">
                            {waitingRoomExpectation}
                          </div>
                        </div>
                        {!requestDetails.isMultipleMatch &&
                          !(requestDetails.matchLog || []).length &&
                          !human.proStart &&
                          !calendlyScheduled &&
                          !!(
                            requestDetails.isKeyAccount ||
                            requestDetails.isAgency
                          ) && (
                            <div className="RequestDetailWhatNext RequestDetailWhatNextSchedule">
                              <div className="RequestDetailWhatNextTitle">
                                Next: Book a call with your account lead
                              </div>
                              <InlineWidget
                                styles={{
                                  height: '750px',
                                }}
                                url="https://calendly.com/d/dvt-yqq-dhn/storetasker-freelance-talent-call?hide_gdpr_banner=1&hide_event_type_details=1"
                              />
                            </div>
                          )}
                        <div className="RequestDetailWhatNext">
                          <div className="RequestDetailWhatNextTitle">
                            {!requestDetails.isMultipleMatch &&
                            !(requestDetails.matchLog || []).length &&
                            !human.proStart &&
                            !calendlyScheduled &&
                            !!(
                              requestDetails.isKeyAccount ||
                              requestDetails.isAgency
                            )
                              ? 'After that'
                              : 'What comes next'}
                          </div>
                          <div className="RequestDetailWhatNextBox">
                            <div className="RequestDetailWhatNextBoxItem">
                              1. Once introduced, they&apos;ll discuss the scope
                              and give you a price quote
                            </div>
                            <div className="RequestDetailWhatNextBoxItem">
                              {requestDetails.isMultipleMatch
                                ? '2. Keep an eye out for up to 3 intros over the next 3 days'
                                : "2. If for any reason it's not a perfect fit, you can request a different expert"}
                            </div>
                            {!requestDetails.isMultipleMatch &&
                              !human.proStart && (
                                <div className="RequestDetailWhatNextBoxItem RequestDetailWhatNextBoxItemUpsell">
                                  3. Prefer a few intros upfront?{' '}
                                  <span onClick={showUpgradeModal}>
                                    Upgrade to Storetasker Pro
                                  </span>{' '}
                                  for multiple intros, premium filters, no
                                  client fee, and more
                                </div>
                              )}
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {!!showProTag && (
                          <div className="ThreadDetailMainBodyFocusTitleTag">
                            Storetasker Pro
                          </div>
                        )}
                        <div className="ThreadDetailMainBodyFocusMedtitle">
                          Project Canceled
                        </div>
                        <div className="ThreadDetailMainBodyFocusSubtitle">
                          {requestDetails.cancelledHow === 'brand'
                            ? 'You canceled this project.'
                            : 'This project was canceled.'}
                        </div>
                        {!!allExpertMatches.length && (
                          <div className="RequestDetailPrevMatch">
                            <div className="RequestDetailPrevMatchTitle">
                              Previous Matches
                            </div>
                            <div className="RequestDetailPrevMatchItems">
                              {allExpertMatches.map((e) => (
                                <Link
                                  key={e.id}
                                  to={'/experts/' + e.id}
                                  className="RequestDetailPrevMatchItem"
                                >
                                  <ExpertUserBubble
                                    expertId={e.id}
                                    expertDetails={e}
                                    className="RequestDetailPrevMatchItemPic"
                                    primary
                                  />
                                  <div className="RequestDetailPrevMatchItemName">
                                    {(e.firstName || '').trim()}
                                  </div>
                                </Link>
                              ))}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {!!showProTag && (
                      <div className="ThreadDetailMainBodyFocusTitleTag">
                        Storetasker Pro
                      </div>
                    )}
                    <div className="ThreadDetailMainBodyFocusMedtitle">
                      {titleWithAtLeastOneMatch}
                    </div>
                    {subWithAtLeastOneMatch}
                    <div className="RequestDetailPrevMatch">
                      <div className="RequestDetailPrevMatchItems">
                        {activeExpertMatches.map((e) => (
                          <Link
                            key={e.id}
                            to={'/experts/' + e.id}
                            className="RequestDetailPrevMatchItem"
                          >
                            <ExpertUserBubble
                              expertId={e.id}
                              expertDetails={e}
                              className="RequestDetailPrevMatchItemPic"
                              primary
                            />
                            <div className="RequestDetailPrevMatchItemName">
                              {(e.firstName || '').trim()}
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div className="RequestDetailWhatNext RequestDetailWhatNextExtraTop">
                      <div className="RequestDetailWhatNextTitle">
                        What comes next
                      </div>
                      <div className="RequestDetailWhatNextBox">
                        <div className="RequestDetailWhatNextBoxItem">
                          1. Your expert
                          {requestDetails.isMultipleMatch &&
                          (requestDetails.isActive ||
                            (requestDetails.matchLog || []).length > 1)
                            ? 's'
                            : ''}{' '}
                          will reply after they&apos;ve reviewed your message
                        </div>
                        <div className="RequestDetailWhatNextBoxItem">
                          2. If it&apos;s a fit, they&apos;ll discuss the scope
                          and give you a price quote
                        </div>
                        {!!(
                          requestDetails.isActive || activeRequests.length < 3
                        ) &&
                          !requestDetails.isMultipleMatch && (
                            <div className="RequestDetailWhatNextBoxItem">
                              3. If for any reason it&apos;s not a perfect fit,
                              you can{' '}
                              {requestDetails.isActive ? (
                                <span
                                  className="RequestDetailWhatNextBoxLink"
                                  onClick={startChange}
                                >
                                  find a different expert
                                </span>
                              ) : (
                                <Link
                                  className="RequestDetailWhatNextBoxLink"
                                  to="/new?another=true"
                                >
                                  find a different expert
                                </Link>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  </Fragment>
                )}
                {!requestDetails.currentMatchStr &&
                  activeRequests.length < 3 && (
                    <div className="RequestDetailUpsell">
                      <div className="RequestDetailUpsellTitle">
                        Need another expert?
                      </div>
                      <div className="RequestDetailUpsellItem">
                        <div className="RequestDetailUpsellItemTop">
                          <Link
                            to="/new?another=true&skill=developer"
                            className="RequestDetailUpsellItemTopBtn"
                          >
                            Hire a developer
                          </Link>
                          <div className="RequestDetailUpsellItemTopText">
                            Build new features, customize your site, solve any
                            tech challenge.
                          </div>
                        </div>
                        <Link
                          to="/new?another=true&skill=developer"
                          className="RequestDetailUpsellItemRow RequestDetailUpsellItemRowDeveloper"
                        />
                        <div className="RequestDetailUpsellItemLinks">
                          <Link
                            to="/new?another=true&skill=developer"
                            className="RequestDetailUpsellItemLink"
                          >
                            Full-Stack Shopify devs
                          </Link>
                          <Link
                            to="/new?another=true&skill=developer"
                            className="RequestDetailUpsellItemLink"
                          >
                            Frontend devs
                          </Link>
                          <Link
                            to="/new?another=true&skill=developer"
                            className="RequestDetailUpsellItemLink"
                          >
                            Headless devs
                          </Link>
                          <Link
                            to="/new?another=true&skill=developer"
                            className="RequestDetailUpsellItemLink"
                          >
                            Shopify App devs
                          </Link>
                        </div>
                      </div>
                      <div className="RequestDetailUpsellItem">
                        <div className="RequestDetailUpsellItemTop">
                          <Link
                            to="/new?another=true&skill=designer"
                            className="RequestDetailUpsellItemTopBtn"
                          >
                            Hire a designer
                          </Link>
                          <div className="RequestDetailUpsellItemTopText">
                            Design new landing pages, features, site upgrades,
                            emails, and more.
                          </div>
                        </div>
                        <Link
                          to="/new?another=true&skill=designer"
                          className="RequestDetailUpsellItemRow RequestDetailUpsellItemRowDesigner"
                        />
                        <div className="RequestDetailUpsellItemLinks">
                          <Link
                            to="/new?another=true&skill=designer"
                            className="RequestDetailUpsellItemLink"
                          >
                            UI/UX designers
                          </Link>
                          <Link
                            to="/new?another=true&skill=designer"
                            className="RequestDetailUpsellItemLink"
                          >
                            Email designers
                          </Link>
                          <Link
                            to="/new?another=true&skill=designer"
                            className="RequestDetailUpsellItemLink"
                          >
                            Strategists
                          </Link>
                          <Link
                            to="/new?another=true&skill=designer"
                            className="RequestDetailUpsellItemLink"
                          >
                            Brand designers
                          </Link>
                        </div>
                      </div>
                      <div className="RequestDetailUpsellItem">
                        <div className="RequestDetailUpsellItemTop">
                          <Link
                            to="/new?another=true&skill=marketer"
                            className="RequestDetailUpsellItemTopBtn"
                          >
                            Hire a marketer
                          </Link>
                          <div className="RequestDetailUpsellItemTopText">
                            Launch campaigns, improve SEO, improve your
                            analytics, and more.
                          </div>
                        </div>
                        <Link
                          to="/new?another=true&skill=marketer"
                          className="RequestDetailUpsellItemRow RequestDetailUpsellItemRowMarketer"
                        />
                        <div className="RequestDetailUpsellItemLinks">
                          <Link
                            to="/new?another=true&skill=marketer"
                            className="RequestDetailUpsellItemLink"
                          >
                            Paid marketing experts
                          </Link>
                          <Link
                            to="/new?another=true&skill=marketer"
                            className="RequestDetailUpsellItemLink"
                          >
                            Amazon experts
                          </Link>
                          <Link
                            to="/new?another=true&skill=marketer"
                            className="RequestDetailUpsellItemLink"
                          >
                            Klaviyo email experts
                          </Link>
                          <Link
                            to="/new?another=true&skill=marketer"
                            className="RequestDetailUpsellItemLink"
                          >
                            CRO experts
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="RequestDetailContent">
                  <div className="RequestDetailContentHeader">
                    <div className="RequestDetailContentHeaderTitle">
                      {!!requestDetails.projectPrefab &&
                      !!requestDetails.projectPrefab.title
                        ? requestDetails.projectPrefab.title
                        : 'Project Details'}
                    </div>
                    {!!requestDetails.isActive &&
                      !requestDetails.currentMatchStr &&
                      !requestDetails.projectPrefab && (
                        <div
                          className="RequestDetailContentHeaderEdit"
                          onClick={startEditing}
                        >
                          edit project
                        </div>
                      )}
                  </div>
                  <div className="RequestDetailContentBody">
                    <ReadOnlyEditor
                      className="RequestDetailContentText"
                      content={
                        requestDetails.projectPrefab
                          ? requestDetails.projectPrefab.description || ''
                          : requestDetails.content || ''
                      }
                    />
                    <div className="RequestDetailContentFiles">
                      {(requestDetails.files || []).map((f) => (
                        <a
                          key={f.id}
                          className="RequestDetailContentFileLink"
                          href={f.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {f.filename}
                        </a>
                      ))}
                    </div>
                    {!!requestDetails.projectPrefab &&
                      !!requestDetails.projectPrefab.priceType &&
                      requestDetails.projectPrefab.priceType !== 'NONE' &&
                      !!requestDetails.projectPrefab.cents && (
                        <div className="RequestDetailContentExtra">
                          <div className="RequestDetailContentExtraTitle">
                            {requestDetails.projectPrefab.priceType ===
                            'EXACTLY'
                              ? 'Cost'
                              : 'Starting At'}
                          </div>
                          <div className="RequestDetailContentExtraValue">
                            $
                            {formatNumberWithCommas(
                              centsDollarsRounded(
                                requestDetails.projectPrefab.cents,
                              ),
                            )}
                          </div>
                        </div>
                      )}
                    {!!requestDetails.projectPrefab &&
                      !!requestDetails.projectPrefab.estimatedCompletion && (
                        <div className="RequestDetailContentExtra">
                          <div className="RequestDetailContentExtraTitle">
                            Estimated Completion
                          </div>
                          <div className="RequestDetailContentExtraValue">
                            {requestDetails.projectPrefab.estimatedCompletion}{' '}
                            day
                            {requestDetails.projectPrefab.estimatedCompletion >
                            1
                              ? 's'
                              : ''}
                          </div>
                        </div>
                      )}
                    {!!requestDetails.projectPrefab &&
                      !!requestDetails.content && (
                        <div className="RequestDetailContentExtra">
                          <div className="RequestDetailContentExtraTitle">
                            Extra Details
                          </div>
                          <ReadOnlyEditor
                            className="RequestDetailContentExtraValue"
                            content={requestDetails.content || ''}
                          />
                        </div>
                      )}
                    {!!requestDetails.brand && (
                      <div className="RequestDetailContentExtra">
                        <div className="RequestDetailContentExtraTitle">
                          Brand
                        </div>
                        <div className="RequestDetailContentExtraValue">
                          {requestDetails.brand.name} (
                          {requestDetails.brand.url ||
                            requestDetails.brand.shopifyAdminURL}
                          )
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {cleanModal === 'edit' && (
            <div className="ThreadDetailMainFocusModal" onClick={stopEditing}>
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {actionLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Edit Project
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        Feel free to edit your project description while we find
                        your expert. More detail the better!
                      </div>
                      <div className="BasicForm">
                        <div className="BasicFormField">
                          <div className="BasicFormFieldTitle">Brand Name</div>
                          <input
                            className="BasicFormFieldInput"
                            type="text"
                            autoComplete="new-off"
                            placeholder="Your Brand"
                            value={editBrandName}
                            onChange={(e) =>
                              setEditBrandName(e.currentTarget.value)
                            }
                          />
                        </div>
                        <div className="BasicFormField">
                          <div className="BasicFormFieldTitle">Website Url</div>
                          <input
                            className="BasicFormFieldInput"
                            type="text"
                            autoComplete="new-off"
                            placeholder="Your Website"
                            value={editBrandUrl}
                            onChange={(e) =>
                              setEditBrandUrl(e.currentTarget.value)
                            }
                          />
                        </div>
                        <div className="BasicFormField">
                          <div className="BasicFormFieldTitle">
                            Your Project
                          </div>
                          <div className="BasicFormFieldRichWrapper">
                            <div className="BasicFormFieldRichWrapperText">
                              {!!initEditRequestContent && (
                                <RichTextEditor
                                  initContent={initEditRequestContent}
                                  placeholder="Tell us as much as you can about your project. This helps us find the best expert to match you with. Feel free to link to a Google doc, share a screenshot, record a video, etc."
                                  onUpdated={setEditRequestContent}
                                />
                              )}
                            </div>
                            <div className="BasicFormFieldRichFooter">
                              <ReactFilestack
                                apikey="AGxdviGoVRwWPL6lKEdnXz"
                                actionOptions={{
                                  maxFiles: 20,
                                  storeTo: {
                                    container: envUtils.pick(
                                      'files.asklorem.com',
                                      'files-dev.asklorem.com',
                                      'files-dev.asklorem.com',
                                    ),
                                    location: 's3',
                                    path: `${human.id}/`,
                                    region: 'us-east-1',
                                  },
                                }}
                                onSuccess={fileUploaded}
                                onError={fileError}
                                customRender={({ onPick }: { onPick: any }) => (
                                  <div
                                    onMouseDown={onPick}
                                    className="BasicFormFieldRichFooterAttachments"
                                  >
                                    attach files
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className="BasicFormFiles">
                            {editRequestFiles.map((file) => (
                              <div
                                key={file.url}
                                className="BasicFormFile"
                                onClick={() => removeFile(file.url || '')}
                              >
                                <span className="BasicFormFileText">
                                  {file.filename}
                                </span>
                                <div className="BasicFormFileRemove" />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="BasicFormFooter">
                          <div
                            className={
                              'BasicFormAction ' +
                              (!editIsReadyForSubmit
                                ? ' BasicFormActionInvalid '
                                : '')
                            }
                            onClick={saveRequestAndBrand}
                          >
                            Save
                          </div>
                          <div
                            className="BasicFormActionInstead"
                            onClick={stopEditing}
                          >
                            never mind
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={stopEditing}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {cleanModal === 'change-suppprt' && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={() => setCurrModal('')}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {actionLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Change Expert
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        You&apos;ll need to contact our Support Team to help you
                        find a new expert at this point.
                      </div>
                      <Link
                        to="/support"
                        className="ThreadDetailMainFocusModalContainerBtn"
                      >
                        Contact Support
                      </Link>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={() => setCurrModal('')}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {cleanModal === 'stop' && (
            <div className="ThreadDetailMainFocusModal" onClick={stopStop}>
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {actionLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Are you sure you want to stop searching?
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        If so, we’ll stop searching for more freelance
                        candidates for you. You’ll still be able to work with
                        the experts who we have already introduced.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={onStopSearch}
                          >
                            Yes, stop searching
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={stopStop}
                          >
                            No, continue searching
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={stopStop}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {cleanModal === 'change' && (
            <div className="ThreadDetailMainFocusModal" onClick={stopChange}>
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {actionLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Change Expert
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        Let&apos;s introduce you to a new expert as quickly as
                        possible.
                      </div>
                      <div className="BasicForm">
                        <div className="ThreadDetailMainFocusModalChoicesSection">
                          <div className="ThreadDetailMainFocusModalChoicesTitle">
                            Why was this match not a good fit?
                          </div>
                          <div className="ThreadDetailMainFocusModalChoicesOptions">
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (changeReason === 'LOCATION_NOT_A_FIT'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() =>
                                pickChangeReason('LOCATION_NOT_A_FIT')
                              }
                            >
                              location / timezone
                            </div>
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (changeReason === 'QUOTE_NOT_A_FIT'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() =>
                                pickChangeReason('QUOTE_NOT_A_FIT')
                              }
                            >
                              quote / budget
                            </div>
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (changeReason === 'SKILLS_NOT_A_FIT'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() =>
                                pickChangeReason('SKILLS_NOT_A_FIT')
                              }
                            >
                              skills required
                            </div>
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (changeReason === 'PORTFOLIO_NOT_A_FIT'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() =>
                                pickChangeReason('PORTFOLIO_NOT_A_FIT')
                              }
                            >
                              portfolio / experience
                            </div>
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (changeReason === 'TIMELINE_NOT_A_FIT'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() =>
                                pickChangeReason('TIMELINE_NOT_A_FIT')
                              }
                            >
                              timeline / deadline
                            </div>
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (changeReason === 'EXPERT_IS_UNRESPONSIVE'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() =>
                                pickChangeReason('EXPERT_IS_UNRESPONSIVE')
                              }
                            >
                              expert is unresponsive
                            </div>
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (changeReason ===
                                'EXPERT_DIFFICULT_TO_WORK_WITH'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() =>
                                pickChangeReason(
                                  'EXPERT_DIFFICULT_TO_WORK_WITH',
                                )
                              }
                            >
                              issue with my expert
                            </div>
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (changeReason === 'OTHER'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() => pickChangeReason('OTHER')}
                            >
                              other
                            </div>
                          </div>
                        </div>
                        {!!changeReason && (
                          <div className="ThreadDetailMainFocusModalChoicesSection">
                            <div className="ThreadDetailMainFocusModalChoicesTitle">
                              Can you tell us a bit more?
                            </div>
                            <div className="ThreadDetailMainFocusModalChoicesSubTitle">
                              This helps us find a better match for your
                              project.
                            </div>
                            <div className="ThreadDetailMainFocusModalSummarizeInput">
                              <TextareaAutosize
                                type="text"
                                placeholder="Please share as much detail as you can. Any specific criteria or other things we should know? Thank you!"
                                spellCheck="true"
                                className="ThreadDetailMainFocusModalSummarizeTextarea"
                                value={changeMoreDetail}
                                onChange={(e) => {
                                  setChangeMoreDetail(e.currentTarget.value);
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div className="BasicFormFooter">
                          <div
                            className={
                              'BasicFormAction ' +
                              (!isChangeReady ? ' BasicFormActionInvalid ' : '')
                            }
                            onClick={onUnmatchRequest}
                          >
                            Change Expert
                          </div>
                          <div
                            className="BasicFormActionInstead"
                            onClick={stopChange}
                          >
                            never mind
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={stopChange}
                    >
                      never mind
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!requestDetails && cleanModal === 'cancel' && (
            <div className="ThreadDetailMainFocusModal" onClick={stopCancel}>
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {actionLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyEditRequest">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Cancel Project
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        If you&apos;re having any issues with your project,
                        please let our Support Team (
                        <span>support@storetasker.com</span>) know and
                        we&apos;ll come help ASAP.
                      </div>
                      <div className="BasicForm">
                        <div className="ThreadDetailMainFocusModalChoicesSection">
                          <div className="ThreadDetailMainFocusModalChoicesTitle">
                            Why do you want to cancel?
                          </div>
                          <div className="ThreadDetailMainFocusModalChoicesOptions">
                            {requestDetails.currentMatchStr ? (
                              <Fragment>
                                <div
                                  className={
                                    'ThreadDetailMainFocusModalChoicesOption ' +
                                    (cancelReason === 'NOT_A_FIT'
                                      ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                      : '')
                                  }
                                  onClick={() => pickCancelReason('NOT_A_FIT')}
                                >
                                  expert is not a good fit
                                </div>
                                <div
                                  className={
                                    'ThreadDetailMainFocusModalChoicesOption ' +
                                    (cancelReason === 'EXPERT_IS_UNRESPONSIVE'
                                      ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                      : '')
                                  }
                                  onClick={() =>
                                    pickCancelReason('EXPERT_IS_UNRESPONSIVE')
                                  }
                                >
                                  expert is unresponsive
                                </div>
                                <div
                                  className={
                                    'ThreadDetailMainFocusModalChoicesOption ' +
                                    (cancelReason ===
                                    'EXPERT_DIFFICULT_TO_WORK_WITH'
                                      ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                      : '')
                                  }
                                  onClick={() =>
                                    pickCancelReason(
                                      'EXPERT_DIFFICULT_TO_WORK_WITH',
                                    )
                                  }
                                >
                                  issue with my expert
                                </div>
                              </Fragment>
                            ) : (
                              <div
                                className={
                                  'ThreadDetailMainFocusModalChoicesOption ' +
                                  (cancelReason === 'TAKING_TOO_LONG'
                                    ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                    : '')
                                }
                                onClick={() =>
                                  pickCancelReason('TAKING_TOO_LONG')
                                }
                              >
                                taking too long
                              </div>
                            )}
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (cancelReason === 'FIGURED_IT_OUT_MYSELF'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() =>
                                pickCancelReason('FIGURED_IT_OUT_MYSELF')
                              }
                            >
                              figured out myself
                            </div>
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (cancelReason === 'FOUND_SOMEONE_ELSE'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() =>
                                pickCancelReason('FOUND_SOMEONE_ELSE')
                              }
                            >
                              found someone else to do it
                            </div>
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (cancelReason ===
                                'NO_LONGER_PURSUING_THIS_PROJECT'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() =>
                                pickCancelReason(
                                  'NO_LONGER_PURSUING_THIS_PROJECT',
                                )
                              }
                            >
                              project not needed anymore
                            </div>
                            <div
                              className={
                                'ThreadDetailMainFocusModalChoicesOption ' +
                                (cancelReason === 'OTHER'
                                  ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                  : '')
                              }
                              onClick={() => pickCancelReason('OTHER')}
                            >
                              other
                            </div>
                          </div>
                        </div>
                        {cancelReason === 'NOT_A_FIT' && (
                          <div className="ThreadDetailMainFocusModalChoicesSection">
                            <div className="ThreadDetailMainFocusModalChoicesTitle">
                              Why was this match not a good fit?
                            </div>
                            <div className="ThreadDetailMainFocusModalChoicesOptions">
                              <div
                                className={
                                  'ThreadDetailMainFocusModalChoicesOption ' +
                                  (cancelMisfit === 'LOCATION_NOT_A_FIT'
                                    ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                    : '')
                                }
                                onClick={() =>
                                  pickCancelMisfit('LOCATION_NOT_A_FIT')
                                }
                              >
                                location / timezone
                              </div>
                              <div
                                className={
                                  'ThreadDetailMainFocusModalChoicesOption ' +
                                  (cancelMisfit === 'QUOTE_NOT_A_FIT'
                                    ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                    : '')
                                }
                                onClick={() =>
                                  pickCancelMisfit('QUOTE_NOT_A_FIT')
                                }
                              >
                                quote / budget
                              </div>
                              <div
                                className={
                                  'ThreadDetailMainFocusModalChoicesOption ' +
                                  (cancelMisfit === 'SKILLS_NOT_A_FIT'
                                    ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                    : '')
                                }
                                onClick={() =>
                                  pickCancelMisfit('SKILLS_NOT_A_FIT')
                                }
                              >
                                skills required
                              </div>
                              <div
                                className={
                                  'ThreadDetailMainFocusModalChoicesOption ' +
                                  (cancelMisfit === 'PORTFOLIO_NOT_A_FIT'
                                    ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                    : '')
                                }
                                onClick={() =>
                                  pickCancelMisfit('PORTFOLIO_NOT_A_FIT')
                                }
                              >
                                portfolio / experience
                              </div>
                              <div
                                className={
                                  'ThreadDetailMainFocusModalChoicesOption ' +
                                  (cancelMisfit === 'TIMELINE_NOT_A_FIT'
                                    ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                    : '')
                                }
                                onClick={() =>
                                  pickCancelMisfit('TIMELINE_NOT_A_FIT')
                                }
                              >
                                timeline / deadline
                              </div>
                            </div>
                          </div>
                        )}
                        {(!!cancelMisfit ||
                          cancelReason === 'EXPERT_IS_UNRESPONSIVE' ||
                          cancelReason === 'EXPERT_DIFFICULT_TO_WORK_WITH') && (
                          <div className="ThreadDetailMainFocusModalChoicesSection">
                            <div className="ThreadDetailMainFocusModalChoicesTitle">
                              Can we find you another expert?
                            </div>
                            <div className="ThreadDetailMainFocusModalChoicesOptions">
                              <div
                                className={
                                  'ThreadDetailMainFocusModalChoicesOption ' +
                                  (cancelSaved === 'NO'
                                    ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                    : '')
                                }
                                onClick={() => pickCancelSaved('NO')}
                              >
                                no, cancel the project
                              </div>
                              <div
                                className={
                                  'ThreadDetailMainFocusModalChoicesOption ' +
                                  (cancelSaved === 'YES'
                                    ? ' ThreadDetailMainFocusModalChoicesOptionActive '
                                    : '')
                                }
                                onClick={switchToChangeExpert}
                              >
                                {trySaveCancelStr}
                              </div>
                            </div>
                          </div>
                        )}
                        {(!!cancelSaved ||
                          cancelReason === 'OTHER' ||
                          cancelReason === 'FOUND_SOMEONE_ELSE') && (
                          <div className="ThreadDetailMainFocusModalChoicesSection">
                            <div className="ThreadDetailMainFocusModalChoicesTitle">
                              {cancelSaved ? 'Last question. ' : ''}Can you tell
                              us a bit more?
                            </div>
                            <div className="ThreadDetailMainFocusModalChoicesSubTitle">
                              This helps us learn from our failures and make
                              better matches in the future. We really appreciate
                              it.
                            </div>
                            <div className="ThreadDetailMainFocusModalSummarizeInput">
                              <TextareaAutosize
                                type="text"
                                placeholder="Please share as much detail as you can. Thank you!"
                                spellCheck="true"
                                className="ThreadDetailMainFocusModalSummarizeTextarea"
                                value={cancelMoreDetail}
                                onChange={(e) => {
                                  setCancelMoreDetail(e.currentTarget.value);
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div className="BasicFormFooter">
                          <div
                            className={
                              'BasicFormAction ' +
                              (!isCancelReady ? ' BasicFormActionInvalid ' : '')
                            }
                            onClick={onCancel}
                          >
                            Save
                          </div>
                          <div
                            className="BasicFormActionInstead"
                            onClick={stopCancel}
                          >
                            never mind
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={stopCancel}
                    >
                      never mind
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div
          className={
            'ThreadDetailSidebar ' +
            (!secondaryPanels.length ? ' ThreadDetailSidebarSolo ' : '')
          }
        >
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            {!!requestDetails && (
              <div className="ThreadDetailSidebarBodyPanels">
                {primaryPanel === 'Details' && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout ThreadDetailSidebarBodyPanelNewRequest">
                    {!!requestDetails.isActive &&
                      !requestDetails.currentMatchStr &&
                      !requestDetails.projectPrefab && (
                        <div className="ThreadDetailSidebarHumanSection">
                          {showTagEditing ? (
                            <div className="HumanBrandSidebarEditor">
                              <div className="HumanBrandSidebarEditorTop">
                                <div className="HumanBrandSidebarEditorTopTitle">
                                  Add details
                                </div>
                                <div className="HumanBrandSidebarEditorTopActions">
                                  <div
                                    className="HumanBrandSidebarEditorTopActionBtn HumanBrandSidebarEditorTopActionIgnore"
                                    onClick={() => setShowTagEditing(false)}
                                  >
                                    Cancel
                                  </div>
                                  <div
                                    className={
                                      'HumanBrandSidebarEditorTopActionBtn HumanBrandSidebarEditorTopActionSave ' +
                                      (actionLoading
                                        ? ' HumanBrandSidebarEditorTopActionBtnLoading '
                                        : '')
                                    }
                                    onClick={onTagRequest}
                                  >
                                    Save
                                  </div>
                                </div>
                              </div>
                              <div className="HumanBrandSidebarEditorCardWrapper">
                                <div className="HumanBrandSidebarEditorCard">
                                  <div className="HumanBrandSidebarEditorFormInput">
                                    <div className="HumanBrandSidebarEditorFormInputTitle">
                                      Who would be best suited for the project?
                                    </div>
                                    <select
                                      className="HumanBrandSidebarEditorFormInputSelect"
                                      required
                                      value={editRequestTagFocus[0] || ''}
                                      onChange={(e) =>
                                        setEditRequestTagFocus(
                                          e.currentTarget.value
                                            ? [e.currentTarget.value]
                                            : [],
                                        )
                                      }
                                    >
                                      <option value="">
                                        Let Storetasker decide
                                      </option>
                                      {FOCUS_OPTIONS.map((o) => (
                                        <option key={o.value} value={o.value}>
                                          {o.person}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="HumanBrandSidebarEditorFormInput">
                                    <div className="HumanBrandSidebarEditorFormInputTitle">
                                      What skills are needed?
                                    </div>
                                    <MultiSelect
                                      options={SKILL_OPTIONS}
                                      currValues={editRequestTagSkills}
                                      onChange={setEditRequestTagSkills}
                                      withTags
                                      max={3}
                                    />
                                  </div>
                                  <div className="HumanBrandSidebarEditorFormInput">
                                    <div className="HumanBrandSidebarEditorFormInputTitle">
                                      Do they need to know particular tools?
                                    </div>
                                    <MultiSelect
                                      options={TOOL_OPTIONS}
                                      currValues={editRequestTagTools}
                                      onChange={setEditRequestTagTools}
                                      withTags
                                      max={5}
                                    />
                                  </div>
                                  <div className="HumanBrandSidebarEditorFormInput">
                                    <div className="HumanBrandSidebarEditorFormInputTitle">
                                      When do you want to start the project?
                                    </div>
                                    <select
                                      className="HumanBrandSidebarEditorFormInputSelect"
                                      required
                                      value={editRequestTagTimeline}
                                      onChange={(e) =>
                                        setEditRequestTagTimeline(
                                          e.currentTarget.value,
                                        )
                                      }
                                    >
                                      <option value=""></option>
                                      {TIMELINE_OPTIONS.map((o) => (
                                        <option key={o.value} value={o.value}>
                                          {o.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className={
                                      'HumanBrandSidebarEditorCardPro ' +
                                      (!human.proStart &&
                                      !requestDetails.isMultipleMatch
                                        ? ' HumanBrandSidebarEditorCardProBlue '
                                        : '')
                                    }
                                  >
                                    {!human.proStart &&
                                      !requestDetails.isMultipleMatch && (
                                        <div className="HumanBrandSidebarEditorFormInput">
                                          <div className="HumanBrandSidebarEditorFormInputTitle">
                                            Pick from multiple candidates?
                                          </div>
                                          <select
                                            className="HumanBrandSidebarEditorFormInputSelect"
                                            required
                                            value=""
                                          >
                                            <option value="">
                                              No, one at a time
                                            </option>
                                            <option value="MULTIPLE">
                                              Multiple intros upfront
                                            </option>
                                          </select>
                                        </div>
                                      )}
                                    <div className="HumanBrandSidebarEditorFormInput">
                                      <div className="HumanBrandSidebarEditorFormInputTitle">
                                        Preferred Location?
                                      </div>
                                      <MultiSelect
                                        options={CONTINENTS.map((v) => ({
                                          label: v,
                                          value: v,
                                        }))}
                                        currValues={editRequestTagLocations}
                                        placeholder="Anywhere"
                                        onChange={setEditRequestTagLocations}
                                        withTags
                                      />
                                    </div>
                                    <div className="HumanBrandSidebarEditorFormInput">
                                      <div className="HumanBrandSidebarEditorFormInputTitle">
                                        Level of Experience
                                      </div>
                                      <select
                                        className="HumanBrandSidebarEditorFormInputSelect"
                                        required
                                        value={editRequestTagTimeline}
                                        onChange={(e) =>
                                          setEditRequestTagTimeline(
                                            e.currentTarget.value,
                                          )
                                        }
                                      >
                                        <option value="">Everyone</option>
                                        {EXPERIENCE_OPTIONS.map((o) => (
                                          <option key={o.value} value={o.value}>
                                            {o.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    {!human.proStart &&
                                      !requestDetails.isMultipleMatch && (
                                        <div className="HumanBrandSidebarEditorCardProTitle">
                                          <span>
                                            Upgrade to Storetasker Pro
                                          </span>{' '}
                                          for advanced matching filters
                                        </div>
                                      )}
                                    {!human.proStart &&
                                      !requestDetails.isMultipleMatch && (
                                        <div
                                          className="HumanBrandSidebarEditorCardProCover"
                                          onClick={showUpgradeModal}
                                        />
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <Fragment>
                              <div className="ThreadDetailSidebarHumanSectionTitle">
                                Help us match you faster
                              </div>
                              <div className="ThreadDetailSidebarHumanSectionSubTitle">
                                We&apos;re able to make an even better
                                introduction with a few extra details.
                              </div>
                              {!!requestDetails.tagFocus &&
                                !!requestDetails.tagFocus.length && (
                                  <div className="ThreadDetailSidebarHumanSectionValue">
                                    <span>Focus: </span>
                                    {requestDetails.tagFocus
                                      .map(tagLabelFocus)
                                      .join(', ')}
                                  </div>
                                )}
                              {!!requestDetails.tagSkills &&
                                !!requestDetails.tagSkills.length && (
                                  <div className="ThreadDetailSidebarHumanSectionValue">
                                    <span>Skills: </span>
                                    {requestDetails.tagSkills
                                      .map(tagLabelSkill)
                                      .join(', ')}
                                  </div>
                                )}
                              {!!requestDetails.tagTools &&
                                !!requestDetails.tagTools.length && (
                                  <div className="ThreadDetailSidebarHumanSectionValue">
                                    <span>Tools: </span>
                                    {requestDetails.tagTools
                                      .map(tagLabelTool)
                                      .join(', ')}
                                  </div>
                                )}
                              {!!requestDetails.tagTimeline && (
                                <div className="ThreadDetailSidebarHumanSectionValue">
                                  <span>Timeline: </span>
                                  {tagLabelTimeline(requestDetails.tagTimeline)}
                                </div>
                              )}
                              {!!requestDetails.tagExperience && (
                                <div className="ThreadDetailSidebarHumanSectionValue">
                                  <span>Experience: </span>
                                  {tagLabelExperience(
                                    requestDetails.tagExperience,
                                  )}
                                </div>
                              )}
                              {!!requestDetails.tagLocations &&
                                !!requestDetails.tagLocations.length && (
                                  <div className="ThreadDetailSidebarHumanSectionValue">
                                    <span>Locations: </span>
                                    {requestDetails.tagLocations.join(', ')}
                                  </div>
                                )}
                              <div
                                className="ThreadDetailSidebarHumanSectionBtn"
                                onClick={startTagging}
                              >
                                Add more detail
                              </div>
                            </Fragment>
                          )}
                        </div>
                      )}
                    {!!requestDetails.isActive &&
                      !requestDetails.currentMatchStr && (
                        <Fragment>
                          {!!askForPassword && (
                            <div className="ThreadDetailSidebarHumanSection">
                              <div className="RequestDetailPhoneCapture">
                                <div className="ThreadDetailSidebarHumanSectionTitle">
                                  We need your Storefront password:
                                </div>
                                <div className="ThreadDetailSidebarHumanSectionSubTitle">
                                  This helps our experts evaluate your project
                                </div>
                                <div className="RequestDetailPhoneCaptureField">
                                  <input
                                    className={
                                      'RequestDetailPhoneCaptureInput ' +
                                      (!sitePassword && !sitePasswordFocus
                                        ? ' RequestDetailPhoneCaptureInputInvalid '
                                        : '')
                                    }
                                    type="text"
                                    autoComplete="new-off"
                                    placeholder="Storefront Password"
                                    value={sitePassword}
                                    onFocus={() => setSitePasswordFocus(true)}
                                    onChange={(e) =>
                                      setSitePassword(e.currentTarget.value)
                                    }
                                  />
                                  {(!!sitePassword || !!sitePasswordFocus) && (
                                    <div
                                      className="RequestDetailPhoneCaptureBtn"
                                      onClick={saveSitePassword}
                                    >
                                      save
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {!human.primaryPhone &&
                            !human.pendingPhone &&
                            !askForPassword && (
                              <div className="ThreadDetailSidebarHumanSection">
                                <div className="RequestDetailPhoneCapture">
                                  <div className="ThreadDetailSidebarHumanSectionTitle">
                                    Your expert will email you
                                  </div>
                                  <div className="ThreadDetailSidebarHumanSectionSubTitle">
                                    Prefer a text? Add your phone number
                                  </div>
                                  <div className="RequestDetailPhoneCaptureField">
                                    <PhoneNumberInput
                                      className={
                                        'RequestDetailPhoneCaptureInput ' +
                                        (!contactPhoneValid
                                          ? ' RequestDetailPhoneCaptureInputInvalid '
                                          : '')
                                      }
                                      value={contactPhone}
                                      onChange={phoneNumberChange}
                                      onBlur={phoneNumberBlur}
                                    />
                                    {!!contactPhone && (
                                      <div
                                        className="RequestDetailPhoneCaptureBtn"
                                        onClick={savePhoneNumber}
                                      >
                                        save
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                        </Fragment>
                      )}
                    {activeExpertMatches.map((expertMatch) => (
                      <div
                        className="ThreadDetailSidebarHumanSection"
                        key={expertMatch.id}
                      >
                        <div className="ThreadDetailSidebarHumanSectionTitle">
                          Meet {expertMatch.firstName || 'your expert'}
                        </div>
                        {!!expertMatch.profileBio && (
                          <div className="ThreadDetailSidebarHumanSectionSubTitle">
                            {expertMatch.profileBio.slice(0, 140) +
                              (expertMatch.profileBio.length > 140
                                ? '...'
                                : '')}
                          </div>
                        )}
                        <Link
                          className="ThreadDetailSidebarHumanSectionBtn"
                          to={'/experts/' + expertMatch.id}
                        >
                          view full profile
                        </Link>
                      </div>
                    ))}
                    {!!requestDetails.isActive &&
                      !!requestDetails.isMultipleMatch && (
                        <div className="ThreadDetailSidebarHumanSection">
                          <div className="ThreadDetailSidebarHumanSectionTitle">
                            {requestDetails.currentMatchStr
                              ? 'Found the perfect fit?'
                              : 'Other options'}
                          </div>
                          <div className="ThreadDetailSidebarHumanSectionSubTitle">
                            {requestDetails.currentMatchStr
                              ? 'Have we already introduced a great fit? If so, you can pause the search.'
                              : 'If needed, you can cancel this project.'}
                          </div>
                          {requestDetails.currentMatchStr ? (
                            <div
                              className="ThreadDetailSidebarHumanSectionBtn"
                              onClick={startStop}
                            >
                              stop searching
                            </div>
                          ) : (
                            <div
                              className="ThreadDetailSidebarHumanSectionBtn"
                              onClick={startCancel}
                            >
                              cancel project
                            </div>
                          )}
                        </div>
                      )}
                    {!!requestDetails.isActive &&
                      !requestDetails.isMultipleMatch && (
                        <div className="ThreadDetailSidebarHumanSection">
                          <div className="ThreadDetailSidebarHumanSectionTitle">
                            Other options
                          </div>
                          <div className="ThreadDetailSidebarHumanSectionSubTitle">
                            {requestDetails.currentMatchStr
                              ? "If they're not a fit for any reason, we'll be happy to introduce you to someone new."
                              : 'If needed, you can cancel this project.'}
                          </div>
                          {!!requestDetails.currentMatchStr && (
                            <div
                              className="ThreadDetailSidebarHumanSectionBtn"
                              onClick={startChange}
                            >
                              change expert
                            </div>
                          )}
                          <div
                            className="ThreadDetailSidebarHumanSectionBtn"
                            onClick={startCancel}
                          >
                            cancel project
                          </div>
                        </div>
                      )}
                    {!requestDetails.isActive &&
                      !requestDetails.currentMatchStr && (
                        <div className="ThreadDetailSidebarHumanSection">
                          <div className="ThreadDetailSidebarHumanSectionTitle">
                            Start a new project?
                          </div>
                          <div className="ThreadDetailSidebarHumanSectionSubTitle">
                            Work with the same expert or connect with someone
                            new
                          </div>
                          <Link
                            className="ThreadDetailSidebarHumanSectionBtn"
                            to="/new"
                          >
                            get started
                          </Link>
                        </div>
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!phoneError && (
        <div className="DashboardErrorCover DashboardErrorCoverPending">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop DashboardErrorCoverPopCloseable">
            <div
              className="DashboardErrorCoverPopClose"
              onClick={() => setPhoneError('')}
            />
            <div className="DashboardErrorCoverPopTitle">
              {phoneErrorTitle(phoneError)}
            </div>
            <div className="DashboardErrorCoverPopText">
              {phoneErrorSubtitle(phoneError)}
            </div>
          </div>
        </div>
      )}
      {!!requestLoadingError && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <Link to="/projects" className="DashboardErrorCoverNav">
              back
            </Link>
            <div className="DashboardErrorCoverContent">
              An error occured while trying to load this project. Please try
              again.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

HumanRequestDetail.propTypes = {
  activeRequests: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  hasDiscountCode: PropTypes.string.isRequired,
  human: PropTypes.object.isRequired,
  requestId: PropTypes.string.isRequired,
  showUpgradeModal: PropTypes.func.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default HumanRequestDetail;
