import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import HumanUserBubble from '../feature/HumanUserBubble';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import { MatchThreadPaginatedQuery } from '../../gql/graphql';

interface MessageThreadEventProps {
  className?: string;
  expertId: string;
  isGroupFirst: boolean;
  isGroupLast: boolean;
  messageEvent: Extract<
    MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
    { __typename?: 'MessageEvent' | undefined }
  >;
  onReplyClick?: () => void;
  threadEventId: string;
  threadEventTimestamp: number;
}
const initTime = new Date().getTime();

const MessageThreadEvent = ({
  className,
  expertId,
  isGroupFirst,
  isGroupLast,
  messageEvent,
  onReplyClick,
  threadEventTimestamp,
}: MessageThreadEventProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isGroupFirst]);
  const nowMoment = moment.tz(initTime, moment.tz.guess());
  const eventMoment = moment.tz(threadEventTimestamp, moment.tz.guess());
  // January 4th, 2020 at 5:37pm PST
  const eventDateFull =
    eventMoment.format('MMMM Do, YYYY') +
    ' at ' +
    eventMoment.format('h:mma z');
  let eventDateSummary = eventMoment.format('M/D/YY');
  if (nowMoment.isSame(eventMoment, 'day')) {
    eventDateSummary = eventMoment.format('h:mma');
  } else if (nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')) {
    eventDateSummary = 'Yesterday';
  } else if (nowMoment.isSame(eventMoment, 'week')) {
    eventDateSummary = eventMoment.format('dddd');
  } else if (nowMoment.isSame(eventMoment, 'year')) {
    eventDateSummary = eventMoment.format('MMM Do');
  }
  const isSolo =
    messageEvent.ownerHuman &&
    (messageEvent.ownerHuman.firstName || messageEvent.ownerHuman.lastName) &&
    (!messageEvent.recipientHumans || !messageEvent.recipientHumans.length);
  return (
    <div
      className={
        'ThreadEvent MessageThreadEvent ' +
        (messageEvent.ownerHuman ||
        (messageEvent.ownerExpert &&
          messageEvent.recipientExpert &&
          messageEvent.ownerExpert.id !== expertId)
          ? ' ThreadEventOwnerHuman '
          : ' ThreadEventOwnerExpert ') +
        (isSolo ? ' ThreadEventSolo ' : '') +
        (className || '')
      }
    >
      <div className="ThreadEventWrapper">
        {isGroupFirst && (
          <div className="ThreadEventTop">
            <div className="ThreadEventTopBubbles">
              {messageEvent.ownerHuman && (
                <HumanUserBubble
                  firstName={
                    messageEvent.ownerHuman && messageEvent.ownerHuman.firstName
                  }
                  lastName={
                    messageEvent.ownerHuman && messageEvent.ownerHuman.lastName
                  }
                  primary
                />
              )}
              {messageEvent.recipientHumans.map((r) => (
                <HumanUserBubble
                  key={r.id}
                  firstName={r.firstName}
                  lastName={r.lastName}
                />
              ))}
              {messageEvent.ownerExpert && messageEvent.recipientExpert && (
                <ExpertUserBubble
                  expertId={
                    messageEvent.ownerExpert.id === expertId
                      ? messageEvent.recipientExpert.id
                      : messageEvent.ownerExpert.id
                  }
                  expertDetails={
                    messageEvent.ownerExpert.id === expertId
                      ? messageEvent.recipientExpert
                      : messageEvent.ownerExpert
                  }
                  primary={messageEvent.ownerExpert.id !== expertId}
                />
              )}
            </div>
            <div className="ThreadEventTopDate" data-tip={eventDateFull}>
              {eventDateSummary}
            </div>
          </div>
        )}
        <div className="ThreadEventCardWrapper">
          <div className="ThreadEventCardLeft">
            {isSolo && messageEvent.ownerHuman && isGroupFirst && (
              <div className="ThreadEventCardLeftUser">
                <HumanUserBubble
                  firstName={
                    messageEvent.ownerHuman && messageEvent.ownerHuman.firstName
                  }
                  lastName={
                    messageEvent.ownerHuman && messageEvent.ownerHuman.lastName
                  }
                  primary
                />
              </div>
            )}
            {messageEvent.ownerExpert && (
              <div className="ThreadEventCardLeftDate" data-tip={eventDateFull}>
                {eventDateSummary}
              </div>
            )}
          </div>
          <div className="ThreadEventCard">
            <div className="ThreadEventCardBody">
              <ReadOnlyEditor
                className="ThreadEventCardBodyContent"
                content={messageEvent.content}
              />
            </div>
          </div>
          <div className="ThreadEventCardRight">
            {!messageEvent.ownerExpert && (
              <div
                className="ThreadEventCardRightDate"
                data-tip={eventDateFull}
              >
                {eventDateSummary}
              </div>
            )}
            {messageEvent.ownerExpert &&
              (!messageEvent.recipientExpert ||
                messageEvent.ownerExpert.id === expertId) &&
              isGroupFirst && (
                <div className="ThreadEventCardRightUser">
                  <ExpertUserBubble
                    expertId={messageEvent.ownerExpert.id}
                    expertDetails={messageEvent.ownerExpert}
                    primary
                  />
                </div>
              )}
          </div>
        </div>
        {isGroupLast && (
          <div className="ThreadEventBottom">
            {!!onReplyClick && (
              <div className="ThreadEventBottomReply" onClick={onReplyClick}>
                Reply Via Email
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

MessageThreadEvent.propTypes = {
  className: PropTypes.string,
  expertId: PropTypes.string.isRequired,
  isGroupFirst: PropTypes.bool.isRequired,
  isGroupLast: PropTypes.bool.isRequired,
  messageEvent: PropTypes.object.isRequired,
  onReplyClick: PropTypes.func,
  threadEventId: PropTypes.string.isRequired,
  threadEventTimestamp: PropTypes.number.isRequired,
};

MessageThreadEvent.defaultProps = {
  className: '',
  onReplyClick: null,
};

export default MessageThreadEvent;
