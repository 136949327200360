export interface IFilestackFileUpload {
  container: string;
  filename: string;
  handle?: string;
  key: string;
  mimetype?: string;
  size?: string;
  source?: string;
  uploadId?: string;
  url: string;
}

export const ACCEPTED_TEXT_MIME = [
  'audio/basic',
  'audio/L24',
  'audio/mp4',
  'audio/mpeg',
  'audio/ogg',
  'audio/vorbis',
  'audio/vnd.rn-realaudio',
  'audio/vnd.wave',
  'audio/3gpp',
  'audio/3gpp2',
  'audio/ac3',
  'audio/vnd.wave',
  'audio/webm',
  'audio/amr-nb',
  'audio/amr',
  'video/mpeg',
  'video/mp4',
  'video/quicktime',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
  'video/3gpp-tt',
  'video/H261',
  'video/H263',
  'video/H263-1998',
  'video/H263-2000',
  'video/H264',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/bmp',
  'text/vcard',
  'text/csv',
  'text/rtf',
  'text/richtext',
  'text/calendar',
  'text/directory',
  'application/pdf',
];

export const SUPPORTED_TEXT_MIME = ['image/jpeg', 'image/gif', 'image/png'];

export default {};
