import _ from 'lodash';
import { useState, useContext, Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import {
  useMutation,
  useQuery,
  useLazyQuery,
  ApolloError,
  gql,
} from '@apollo/client';
import { useHistory } from 'react-router-dom';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import envUtils from '../../utils/env';
import MultiSelect from '../feature/MultiSelect';
import {
  formatNumberWithCommas,
  centsDollarsDecimal,
  getPartnerLevelStr,
  kpiDollars,
  tagLabelTool,
} from '../../utils/format';
import { TOOL_OPTIONS } from '../../utils/constants';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { PartnerDetail, AccountBalanceEventDetail } from '../../utils/gql';
import {
  PartnerDetailsAdminQuery,
  PartnerDetailsAdminQueryVariables,
  AdminGenerateTokenPartnerQuery,
  AdminGenerateTokenPartnerQueryVariables,
  PartnerEditByAdminMutation,
  PartnerEditByAdminMutationVariables,
  AccountBalanceDetailsAdminPartnerQuery,
  AccountBalanceDetailsAdminPartnerQueryVariables,
  AccountBalanceDebitPartnerMutation,
  AccountBalanceDebitPartnerMutationVariables,
  AccountBalanceCreditPartnerMutation,
  AccountBalanceCreditPartnerMutationVariables,
  AccountBalanceEventPaginateAdminPartnerQuery,
  AccountBalanceEventPaginateAdminPartnerQueryVariables,
} from '../../gql/graphql';
const ReactFilestack = require('filestack-react').default; // eslint-disable-line @typescript-eslint/no-var-requires

const partnerDetailsAdminQuery = gql`
  query PartnerDetailsAdmin($partnerId: ID!) {
    partnerDetails(partnerId: $partnerId) {
      ...PartnerDetail
    }
  }
  ${PartnerDetail}
`;

const adminGenerateTokenPartnerQuery = gql`
  query AdminGenerateTokenPartner($forId: ID!, $userType: String!) {
    adminGenerateToken(forId: $forId, userType: $userType)
  }
`;

const partnerEditByAdminMutation = gql`
  mutation PartnerEditByAdmin(
    $partnerId: ID!
    $affiliateId: String
    $contactEmail: String!
    $contactName: String!
    $partnerLogo: String
    $partnerName: String!
    $partnerType: String!
    $grandfatheredGMVLevel: String
    $partnerUrl: String
    $recipientId: String
    $rfsnId: String
    $secondaryEmails: [String!]!
    $isNoCommission: Boolean
    $isAlwaysDiscount: String
    $shouldExcludeFromKpiReports: Boolean
    $stAmbassadorId: String
    $statusNote: String
    $trackingId: String!
    $tagTool: String!
  ) {
    partnerEditByAdmin(
      partnerId: $partnerId
      affiliateId: $affiliateId
      contactEmail: $contactEmail
      contactName: $contactName
      partnerLogo: $partnerLogo
      partnerName: $partnerName
      partnerType: $partnerType
      partnerUrl: $partnerUrl
      grandfatheredGMVLevel: $grandfatheredGMVLevel
      recipientId: $recipientId
      rfsnId: $rfsnId
      secondaryEmails: $secondaryEmails
      isNoCommission: $isNoCommission
      isAlwaysDiscount: $isAlwaysDiscount
      shouldExcludeFromKpiReports: $shouldExcludeFromKpiReports
      stAmbassadorId: $stAmbassadorId
      statusNote: $statusNote
      trackingId: $trackingId
      tagTool: $tagTool
    ) {
      ...PartnerDetail
    }
  }
  ${PartnerDetail}
`;

const accountBalanceCreditPartnerMutation = gql`
  mutation AccountBalanceCreditPartner(
    $partnerId: ID!
    $amount: Float!
    $reason: String!
  ) {
    accountBalanceCredit(
      partnerId: $partnerId
      amount: $amount
      reason: $reason
    ) {
      ...AccountBalanceEventDetail
    }
  }
  ${AccountBalanceEventDetail}
`;

const accountBalanceDebitPartnerMutation = gql`
  mutation AccountBalanceDebitPartner(
    $partnerId: ID!
    $amount: Float!
    $reason: String!
  ) {
    accountBalanceDebit(
      partnerId: $partnerId
      amount: $amount
      reason: $reason
    ) {
      ...AccountBalanceEventDetail
    }
  }
  ${AccountBalanceEventDetail}
`;

const accountBalanceDetailsAdminPartnerQuery = gql`
  query AccountBalanceDetailsAdminPartner(
    $partnerId: ID!
    $recipientId: String!
  ) {
    accountBalanceDetailsAdmin(
      partnerId: $partnerId
      recipientId: $recipientId
    ) {
      id
      accountIsActive
      accountNeedsTaxReview
      balance
      estimatedFees
      deliveryMethod
    }
  }
`;

const accountBalanceEventPaginateAdminPartnerQuery = gql`
  query AccountBalanceEventPaginateAdminPartner(
    $partnerId: ID!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    accountBalanceEventPaginateAdmin(
      partnerId: $partnerId
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...AccountBalanceEventDetail
    }
  }
  ${AccountBalanceEventDetail}
`;

interface AdminPartnerDetailProps {
  partnerId: string;
}

const PAGE_LIMIT = 8;

const AdminPartnerDetail = ({ partnerId }: AdminPartnerDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const [initDate] = useState(() => new Date());
  const [actionLoading, setActionLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [editPartnerAffiliateId, setEditPartnerAffiliateId] = useState('');
  const [editPartnerContactEmail, setEditPartnerContactEmail] = useState('');
  const [editPartnerContactName, setEditPartnerContactName] = useState('');
  const [editPartnerLogo, setEditPartnerLogo] = useState('');
  const [editPartnerName, setEditPartnerName] = useState('');
  const [editPartnerType, setEditPartnerType] = useState('app');
  const [editPartnerUrl, setEditPartnerUrl] = useState('');
  const [editPartnerRecipientId, setEditPartnerRecipientId] = useState('');
  const [
    editPartnerGrandfatheredGMVLevel,
    setEditPartnerGrandfatheredGMVLevel,
  ] = useState('');
  const [editPartnerRfsnId, setEditPartnerRfsnId] = useState('');
  const [editPartnerSecondaryEmails, setEditPartnerSecondaryEmails] = useState(
    [] as string[],
  );
  const [editPartnerExcludeKpis, setEditPartnerExcludeKpis] = useState('false');
  const [editPartnerNoCommission, setEditPartnerNoCommission] =
    useState('false');
  const [editPartnerIsDiscount, setEditPartnerIsDiscount] = useState('');
  const [editPartnerStAmbassadorId, setEditPartnerStAmbassadorId] =
    useState('');
  const [editPartnerStatusNote, setEditPartnerStatusNote] = useState('');
  const [editPartnerTrackingId, setEditPartnerTrackingId] = useState('');
  const [editPartnerTagTool, setEditPartnerTagTool] = useState([] as string[]);
  const [showLoadMoreBalance, setShowLoadMoreBalance] = useState(true);
  const [tempBalanceEvents, setTempBalanceEvents] = useState(
    [] as AccountBalanceEventPaginateAdminPartnerQuery['accountBalanceEventPaginateAdmin'],
  );
  const [balanceReason, setBalanceReason] = useState('');
  const [balanceAmount, setBalanceAmount] = useState('');
  const [balanceAction, setBalanceAction] = useState('credit');
  const balanceAmountVal =
    (parseFloat((balanceAmount || '').replace(/\$/gi, '')) || 0) * 100;
  const [primaryPanel, setPrimaryPanel] = useState('About');
  const {
    data: dataPartner,
    error: errorPartner,
    loading: loadingPartner,
  } = useQuery<PartnerDetailsAdminQuery, PartnerDetailsAdminQueryVariables>(
    partnerDetailsAdminQuery,
    {
      returnPartialData: true,
      variables: {
        partnerId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorPartner) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorPartner) ||
      'Could not load partner details';
  }
  const partnerDetails =
    dataPartner && dataPartner.partnerDetails && dataPartner.partnerDetails.id
      ? dataPartner.partnerDetails
      : undefined;
  const {
    data: dataToken,
    error: errorToken,
    loading: loadingToken,
  } = useQuery<
    AdminGenerateTokenPartnerQuery,
    AdminGenerateTokenPartnerQueryVariables
  >(adminGenerateTokenPartnerQuery, {
    variables: {
      forId: partnerId,
      userType: 'partner',
    },
  });
  const [tryEditPartner] = useMutation<
    PartnerEditByAdminMutation,
    PartnerEditByAdminMutationVariables
  >(partnerEditByAdminMutation);
  function onSaveEditingInfo() {
    if (actionLoading) return;
    if (
      !editPartnerContactName ||
      !editPartnerContactEmail ||
      !editPartnerName ||
      !editPartnerType ||
      !editPartnerTrackingId
    ) {
      addNotification(
        'Please submit trackingId, name, contact email, contact email, and type at least!',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryEditPartner({
      variables: {
        affiliateId: editPartnerAffiliateId,
        contactEmail: editPartnerContactEmail,
        contactName: editPartnerContactName,
        grandfatheredGMVLevel: editPartnerGrandfatheredGMVLevel,
        isAlwaysDiscount: editPartnerIsDiscount || null,
        isNoCommission: editPartnerNoCommission === 'true',
        partnerId,
        partnerLogo: editPartnerLogo,
        partnerName: editPartnerName,
        partnerType: editPartnerType,
        partnerUrl: editPartnerUrl,
        recipientId: editPartnerRecipientId,
        rfsnId: editPartnerRfsnId,
        secondaryEmails: editPartnerSecondaryEmails.filter(
          (se) => !!se && !!se.trim(),
        ),
        shouldExcludeFromKpiReports: editPartnerExcludeKpis === 'true',
        stAmbassadorId: editPartnerStAmbassadorId,
        statusNote: editPartnerStatusNote,
        tagTool: editPartnerTagTool[0] || '',
        trackingId: editPartnerTrackingId
          .trim()
          .toLowerCase()
          .replace(/\s/gi, ''),
      },
    })
      .then(() => {
        setIsEditingInfo(false);
        setActionLoading(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Partner Edit Error',
        );
        logError(err, {
          component: 'AdminPartnerDetail',
          func: 'tryEditPartner',
        });
      });
  }
  function startEditingInfo() {
    if (!partnerDetails) {
      return;
    }
    setIsEditingInfo(true);
    setEditPartnerAffiliateId(partnerDetails.affiliateId || '');
    setEditPartnerContactEmail(partnerDetails.contactEmail || '');
    setEditPartnerContactName(partnerDetails.contactName || '');
    setEditPartnerLogo(partnerDetails.partnerLogo || '');
    setEditPartnerName(partnerDetails.partnerName || '');
    setEditPartnerType(partnerDetails.partnerType || 'app');
    setEditPartnerUrl(partnerDetails.partnerUrl || '');
    setEditPartnerGrandfatheredGMVLevel(
      partnerDetails.grandfatheredGMVLevel || '',
    );
    setEditPartnerRecipientId(partnerDetails.recipientId || '');
    setEditPartnerRfsnId(partnerDetails.rfsnId || '');
    setEditPartnerSecondaryEmails(
      (partnerDetails.secondaryEmails || []).filter(
        (se) => se !== partnerDetails.contactEmail,
      ),
    );
    setEditPartnerExcludeKpis(
      partnerDetails.shouldExcludeFromKpiReports ? 'true' : 'false',
    );
    setEditPartnerIsDiscount(partnerDetails.isAlwaysDiscount || '');
    setEditPartnerNoCommission(
      partnerDetails.isNoCommission ? 'true' : 'false',
    );
    setEditPartnerStAmbassadorId(partnerDetails.stAmbassadorId || '');
    setEditPartnerStatusNote(partnerDetails.statusNote || '');
    setEditPartnerTagTool(
      partnerDetails.tagTool ? [partnerDetails.tagTool] : [],
    );
    setEditPartnerTrackingId(partnerDetails.trackingId || '');
  }
  function fileError(error: Error) {
    addNotification(
      (error && error.message) || 'File upload error. Please try again.',
      undefined,
      5000,
    );
    console.log('Filestack fileError', error);
  }
  function fileUploaded(filestackRes: any) {
    console.log('Filestack fileUploaded', filestackRes);
    if (
      filestackRes &&
      filestackRes.filesUploaded &&
      filestackRes.filesUploaded[0] &&
      filestackRes.filesUploaded[0].url
    ) {
      setEditPartnerLogo(filestackRes.filesUploaded[0].url as string);
    }
  }
  const [
    getBalanceDetails,
    {
      called: calledBalanceDetails,
      data: dataBalanceDetails,
      error: errorBalanceDetails,
      loading: loadingBalanceDetails,
      refetch: refetchBalanceDetails,
    },
  ] = useLazyQuery<
    AccountBalanceDetailsAdminPartnerQuery,
    AccountBalanceDetailsAdminPartnerQueryVariables
  >(accountBalanceDetailsAdminPartnerQuery, {
    returnPartialData: true,
  });
  const balanceDetails =
    dataBalanceDetails && dataBalanceDetails.accountBalanceDetailsAdmin;
  let balanceStr = '0';
  if (balanceDetails && balanceDetails.balance) {
    balanceStr = formatNumberWithCommas(
      centsDollarsDecimal(balanceDetails.balance),
    );
  }
  const {
    data: dataAccountBalanceEventPaginated,
    error: errorAccountBalanceEventPaginated,
    loading: loadingAccountBalanceEventPaginated,
    fetchMore: fetchMoreAccountBalanceEventPaginated,
  } = useQuery<
    AccountBalanceEventPaginateAdminPartnerQuery,
    AccountBalanceEventPaginateAdminPartnerQueryVariables
  >(accountBalanceEventPaginateAdminPartnerQuery, {
    variables: {
      direction: 'BACKWARD',
      fromDate: initDate.getTime(),
      limit: PAGE_LIMIT,
      partnerId,
    },
  });
  const allAccountBalanceEvents = _.uniqBy(
    [
      ...((dataAccountBalanceEventPaginated &&
        dataAccountBalanceEventPaginated.accountBalanceEventPaginateAdmin) ||
        []),
    ]
      .concat(tempBalanceEvents)
      .filter((abe) => abe.ownerPartnerStr === partnerId),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  function loadMoreBalanceEvents() {
    fetchMoreAccountBalanceEventPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (
          !fetchMoreResult ||
          !fetchMoreResult.accountBalanceEventPaginateAdmin
        )
          return prev;
        if (
          fetchMoreResult.accountBalanceEventPaginateAdmin.length < PAGE_LIMIT
        ) {
          setShowLoadMoreBalance(false);
        }
        return {
          accountBalanceEventPaginateAdmin: (
            prev.accountBalanceEventPaginateAdmin || []
          ).concat(fetchMoreResult.accountBalanceEventPaginateAdmin),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate:
          allAccountBalanceEvents[allAccountBalanceEvents.length - 1].createdAt,
        limit: PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'AdminPartnerDetail',
        func: 'loadMoreBalanceEvents',
      });
    });
  }
  const [tryDebitBalance] = useMutation<
    AccountBalanceDebitPartnerMutation,
    AccountBalanceDebitPartnerMutationVariables
  >(accountBalanceDebitPartnerMutation, {
    onCompleted: () => {
      if (refetchBalanceDetails) {
        refetchBalanceDetails().catch((err: ApolloError) => {
          addNotification(errorUtils.getErrorMessage(err) || 'Refetch Error');
          logError(err, {
            component: 'AdminPartnerDetail',
            func: 'refetchBalanceDetails',
          });
        });
      }
    },
  });
  const [tryCreditBalance] = useMutation<
    AccountBalanceCreditPartnerMutation,
    AccountBalanceCreditPartnerMutationVariables
  >(accountBalanceCreditPartnerMutation, {
    onCompleted: () => {
      if (refetchBalanceDetails) {
        refetchBalanceDetails().catch((err: ApolloError) => {
          addNotification(errorUtils.getErrorMessage(err) || 'Refetch Error');
          logError(err, {
            component: 'AdminPartnerDetail',
            func: 'refetchBalanceDetails',
          });
        });
      }
    },
  });
  function onCreditBalance() {
    if (actionLoading) return;
    if (!balanceReason || !balanceAmountVal || balanceAmountVal <= 0) {
      addNotification('Please submit all the fields!', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryCreditBalance({
      update: (_cache, { data: dataCreditMutation }) => {
        if (dataCreditMutation && dataCreditMutation.accountBalanceCredit) {
          const nextTempEdges = tempBalanceEvents.filter(
            (e) => e.id !== dataCreditMutation.accountBalanceCredit.id,
          );
          if (
            !allAccountBalanceEvents.find(
              (e) => e.id === dataCreditMutation.accountBalanceCredit.id,
            )
          ) {
            nextTempEdges.push(dataCreditMutation.accountBalanceCredit);
          }
          setTempBalanceEvents(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        amount: balanceAmountVal,
        partnerId,
        reason: balanceReason,
      },
    })
      .then(() => {
        setActionLoading(false);
        setBalanceAmount('');
        setBalanceReason('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Credit Error');
        logError(err, {
          component: 'AdminPartnerDetail',
          func: 'tryCreditBalance',
        });
      });
  }
  function onDebitBalance() {
    if (actionLoading) return;
    if (!balanceReason || !balanceAmountVal || balanceAmountVal <= 0) {
      addNotification('Please submit all the fields!', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryDebitBalance({
      update: (_cache, { data: dataDebitMutation }) => {
        if (dataDebitMutation && dataDebitMutation.accountBalanceDebit) {
          const nextTempEdges = tempBalanceEvents.filter(
            (e) => e.id !== dataDebitMutation.accountBalanceDebit.id,
          );
          if (
            !allAccountBalanceEvents.find(
              (e) => e.id === dataDebitMutation.accountBalanceDebit.id,
            )
          ) {
            nextTempEdges.push(dataDebitMutation.accountBalanceDebit);
          }
          setTempBalanceEvents(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        amount: balanceAmountVal,
        partnerId,
        reason: balanceReason,
      },
    })
      .then(() => {
        setActionLoading(false);
        setBalanceAmount('');
        setBalanceReason('');
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Debit Error');
        logError(err, {
          component: 'AdminPartnerDetail',
          func: 'tryDebitBalance',
        });
      });
  }
  function updateBalance() {
    if (balanceAction === 'credit') {
      onCreditBalance();
    } else {
      onDebitBalance();
    }
  }
  const balanceUpdateStr =
    balanceAction === 'credit' ? 'Add Credit' : 'Debit Account';
  const levelStr = partnerDetails
    ? getPartnerLevelStr(partnerDetails.levelEstimate)
    : null;
  const secondaryPanels = ['About', 'KPIs'].filter((p) => p !== primaryPanel);
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
    if (
      panelName === 'KPIs' &&
      !calledBalanceDetails &&
      partnerDetails &&
      partnerDetails.recipientId
    ) {
      getBalanceDetails({
        variables: {
          partnerId,
          recipientId: partnerDetails.recipientId,
        },
      }).catch((err: ApolloError) => {
        logError(err, {
          component: 'AdminPartnerDetail',
          func: 'getBalanceDetails',
        });
      });
    }
  }
  if (
    loadingPartner ||
    loadingBalanceDetails ||
    loadingAccountBalanceEventPaginated
  ) {
    // ignore these
  }
  let loginToken = '';
  if (!errorToken && !loadingToken && dataToken) {
    loginToken = dataToken.adminGenerateToken || '';
  }
  const loginUrl =
    envUtils.pickWebAppUrlForUserType('partner') + '?token=' + loginToken;
  return (
    <div className="DashboardModal AdminPartnerDetailModal">
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/extras/partners')
              }
              className="ThreadDetailMainHeaderBack"
            >
              {partnerDetails ? partnerDetails.partnerName || '' : 'Back'}
            </div>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody AdminPartnerDetailView"></div>
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div className="ThreadDetailSidebar">
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            <div className="ThreadDetailSidebarBodyPanels">
              {primaryPanel === 'About' && partnerDetails && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                  {isEditingInfo ? (
                    <div className="ThreadDetailSidebarAdminSection">
                      <div
                        className="ThreadDetailSidebarAdminSectionTopRightBtn"
                        onClick={() => setIsEditingInfo(false)}
                      >
                        cancel
                      </div>
                      <div className="ThreadDetailSidebarAdminForm">
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Partner Name
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Partner Name"
                          value={editPartnerName}
                          onChange={(e) =>
                            setEditPartnerName(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Tracking Id (no spaces, dashes okay)
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="tracking-id"
                          value={editPartnerTrackingId}
                          onChange={(e) =>
                            setEditPartnerTrackingId(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Contact Name
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Contact Name"
                          value={editPartnerContactName}
                          onChange={(e) =>
                            setEditPartnerContactName(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Contact Email
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="email"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Contact Email"
                          value={editPartnerContactEmail}
                          onChange={(e) =>
                            setEditPartnerContactEmail(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Secondary Emails
                        </div>
                        {editPartnerSecondaryEmails.map((se, i) => (
                          <input
                            key={i}
                            className="ThreadDetailSidebarAdminFormInput ThreadDetailSidebarAdminFormInputNoBottom"
                            type="email"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Secondary Email Address"
                            value={se}
                            onChange={(e) =>
                              setEditPartnerSecondaryEmails(
                                editPartnerSecondaryEmails
                                  .slice(0, i)
                                  .concat(e.currentTarget.value)
                                  .concat(
                                    editPartnerSecondaryEmails.slice(i + 1),
                                  ),
                              )
                            }
                          />
                        ))}
                        <div
                          className="ThreadDetailSidebarAdminFormExtra"
                          onClick={() =>
                            setEditPartnerSecondaryEmails(
                              editPartnerSecondaryEmails.concat(''),
                            )
                          }
                        >
                          Add another email?
                        </div>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Exclude from KPIs?
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editPartnerExcludeKpis}
                          onChange={(e) =>
                            setEditPartnerExcludeKpis(e.currentTarget.value)
                          }
                        >
                          <option value="false">False</option>
                          <option value="true">True</option>
                        </select>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          No Payments?
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editPartnerNoCommission}
                          onChange={(e) =>
                            setEditPartnerNoCommission(e.currentTarget.value)
                          }
                        >
                          <option value="false">False = yes payments</option>
                          <option value="true">True = no payments</option>
                        </select>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Customers Get Discounts?
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editPartnerIsDiscount}
                          onChange={(e) =>
                            setEditPartnerIsDiscount(e.currentTarget.value)
                          }
                        >
                          <option value="">False = no discounts</option>
                          <option value="10_FIRST_FOREVER">
                            10% off first project
                          </option>
                        </select>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Partner Url
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Partner Url"
                          value={editPartnerUrl}
                          onChange={(e) =>
                            setEditPartnerUrl(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Partner Type
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editPartnerType}
                          onChange={(e) =>
                            setEditPartnerType(e.currentTarget.value)
                          }
                        >
                          <option value="app">App</option>
                          <option value="app-projects">
                            App Projects (&quot;Need help with a{' '}
                            {partnerDetails.partnerName} project?&quot;)
                          </option>
                          <option value="theme">Theme</option>
                          <option value="agency">Agency</option>
                          <option value="audience">Audience</option>
                          <option value="expert">Expert</option>
                          <option value="other">Other</option>
                        </select>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Tool For Auto-Tagging Requests
                        </div>
                        <MultiSelect
                          options={TOOL_OPTIONS}
                          currValues={editPartnerTagTool}
                          onChange={setEditPartnerTagTool}
                          withTags
                          max={1}
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Grandfathered GMV Level
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editPartnerGrandfatheredGMVLevel}
                          onChange={(e) =>
                            setEditPartnerGrandfatheredGMVLevel(
                              e.currentTarget.value,
                            )
                          }
                        >
                          <option value="">None</option>
                          <option value="level-1">Level 1</option>
                          <option value="level-2">Level 2</option>
                          <option value="level-3">Level 3</option>
                          <option value="level-4">Level 4</option>
                          <option value="level-5">Level 5</option>
                          <option value="level-6">Level 6</option>
                        </select>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Payment Rails Recipient Id
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Payment Rails Recipient Id"
                          value={editPartnerRecipientId}
                          onChange={(e) =>
                            setEditPartnerRecipientId(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Legacy Affiliate Id
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Legacy Affiliate Id"
                          value={editPartnerAffiliateId}
                          onChange={(e) =>
                            setEditPartnerAffiliateId(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Legacy RFSN Id
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Legacy RFSN Id"
                          value={editPartnerRfsnId}
                          onChange={(e) =>
                            setEditPartnerRfsnId(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Legacy ST Ambassador Id
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Legacy ST Ambassador Id"
                          value={editPartnerStAmbassadorId}
                          onChange={(e) =>
                            setEditPartnerStAmbassadorId(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Status Note
                        </div>
                        <TextareaAutosize
                          type="text"
                          placeholder="Status note..."
                          spellCheck="true"
                          className="ThreadDetailSidebarAdminFormInput"
                          value={editPartnerStatusNote}
                          onChange={(e) => {
                            setEditPartnerStatusNote(e.currentTarget.value);
                          }}
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Logo
                        </div>
                        {editPartnerLogo ? (
                          <div
                            className="ThreadDetailSidebarAdminFormUpload"
                            onClick={() => setEditPartnerLogo('')}
                          >
                            remove current logo
                          </div>
                        ) : (
                          <ReactFilestack
                            apikey="AGxdviGoVRwWPL6lKEdnXz"
                            actionOptions={{
                              maxFiles: 1,
                              storeTo: {
                                container: envUtils.pick(
                                  'files.asklorem.com',
                                  'files-dev.asklorem.com',
                                  'files-dev.asklorem.com',
                                ),
                                location: 's3',
                                path: `${partnerId}/`,
                                region: 'us-east-1',
                              },
                            }}
                            onSuccess={fileUploaded}
                            onError={fileError}
                            customRender={({ onPick }: { onPick: any }) => (
                              <div
                                onMouseDown={onPick}
                                className="ThreadDetailSidebarAdminFormUpload"
                              >
                                Attach a file
                              </div>
                            )}
                          />
                        )}
                        <div
                          className={
                            'ThreadDetailSidebarAdminFormAction ' +
                            (actionLoading
                              ? ' ThreadDetailSidebarAdminFormActionLoading '
                              : '')
                          }
                          onClick={onSaveEditingInfo}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="ThreadDetailSidebarAdminSection">
                      <div
                        className="ThreadDetailSidebarAdminSectionTopRightBtn"
                        onClick={startEditingInfo}
                      >
                        edit
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Partner
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionBig">
                        {partnerDetails.partnerName}
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Tracking Id:{' '}
                        <span className="SelectAllText">
                          {partnerDetails.trackingId || 'MISSING'}
                        </span>
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Contact name: {partnerDetails.contactName || ''}
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Contact email:{' '}
                        <span className="SelectAllText">
                          {partnerDetails.contactEmail || ''}
                        </span>
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Secondary emails:{' '}
                        {(partnerDetails.secondaryEmails || [])
                          .filter((se) => se !== partnerDetails.contactEmail)
                          .map((se, i) => (
                            <Fragment key={i}>
                              <span className="SelectAllText">{se}</span>,{' '}
                            </Fragment>
                          ))}
                      </div>
                      {partnerDetails.createdAt && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Created:{' '}
                          {moment(partnerDetails.createdAt).format(
                            'MMMM Do, YYYY',
                          )}
                        </div>
                      )}
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Partner Type: {partnerDetails.partnerType || ''}
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Tool For Auto-Tagging Requests:{' '}
                        {partnerDetails.tagTool
                          ? tagLabelTool(partnerDetails.tagTool)
                          : 'None'}
                      </div>
                      {!!partnerDetails.grandfatheredGMVLevel && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Grandfathered GMV Level:{' '}
                          {partnerDetails.grandfatheredGMVLevel}
                        </div>
                      )}
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Payment Rails Recipient Id:{' '}
                        <span className="SelectAllText">
                          {partnerDetails.recipientId || 'MISSING'}
                        </span>
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Partner URL: {partnerDetails.partnerUrl || 'missing'}
                      </div>
                      {partnerDetails.shouldExcludeFromKpiReports && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Exclude from KPIs: True
                        </div>
                      )}
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Partner Payments:{' '}
                        {partnerDetails.isNoCommission
                          ? 'No, we do not pay this partner'
                          : 'Yes, we do pay this partner'}
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Customers get discounts:{' '}
                        {partnerDetails.isAlwaysDiscount || 'None'}
                      </div>
                      {partnerDetails.affiliateId && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Legacy Affiliate Id: {partnerDetails.affiliateId}
                        </div>
                      )}
                      {partnerDetails.rfsnId && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Legacy RFSN Id: {partnerDetails.rfsnId}
                        </div>
                      )}
                      {partnerDetails.stAmbassadorId && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Legacy ST Ambassador Id:{' '}
                          {partnerDetails.stAmbassadorId}
                        </div>
                      )}
                      {partnerDetails.statusNote && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Status Note: {partnerDetails.statusNote}
                        </div>
                      )}
                      {partnerDetails.partnerLogo ? (
                        <img
                          className="ThreadDetailSidebarAdminSectionImage"
                          src={partnerDetails.partnerLogo}
                          alt="Partner Logo"
                        />
                      ) : (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Logo: NONE
                        </div>
                      )}
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        <a
                          className="ThreadDetailSidebarAboutWebsiteLink"
                          href={loginUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Impersonate Partner
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {primaryPanel === 'KPIs' && partnerDetails && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelKpis">
                  <div className="ThreadDetailSidebarAdminSection">
                    <div className="ThreadDetailSidebarAdminSectionTitle">
                      KPIs
                    </div>
                    {levelStr && (
                      <Fragment>
                        <div className="ThreadDetailSidebarAdminSectionBig">
                          {levelStr.name}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Total Sales:{' '}
                          {kpiDollars(partnerDetails.gmvTotalEstimate || 0)}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Total Purchases:{' '}
                          {partnerDetails.allPurchasesEstimate || 0}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Unique Purchases:{' '}
                          {partnerDetails.uniquePurchasesEstimate || 0}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Unique Leads:{' '}
                          {partnerDetails.uniqueLeadsEstimate || 0}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Unique Referrals:{' '}
                          {partnerDetails.uniqueReferredHumansEstimate || 0}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Unique Signups:{' '}
                          {partnerDetails.uniqueSignupsEstimate || 0}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Active Projects In Progress:{' '}
                          {partnerDetails.uniqueActiveProjectsEstimate || 0}
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <div className="ThreadDetailSidebarAdminSection">
                    <div className="ThreadDetailSidebarAdminSectionTitle">
                      Account Balance
                    </div>
                    {!!partnerDetails.isNoCommission && (
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        WE DO NOT PAY THIS PARTNER
                      </div>
                    )}
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Current Balance:{' '}
                      {errorBalanceDetails
                        ? errorUtils.getErrorMessage(errorBalanceDetails) ||
                          'Loading Error'
                        : '$' + balanceStr}
                    </div>
                    <div className="ThreadDetailSidebarAdminForm">
                      <div className="ThreadDetailSidebarAdminFormTitle">
                        Credit or Debit?
                      </div>
                      <select
                        className="ThreadDetailSidebarAdminFormInput"
                        required
                        value={balanceAction}
                        onChange={(e) =>
                          setBalanceAction(e.currentTarget.value)
                        }
                      >
                        <option value="debit">Debit</option>
                        <option value="credit">Credit</option>
                      </select>
                      <div className="ThreadDetailSidebarAdminFormTitle">
                        Credit/Debit amount
                      </div>
                      <input
                        className="ThreadDetailSidebarAdminFormInput"
                        type="text"
                        autoComplete="new-off"
                        spellCheck="false"
                        placeholder="$ amount"
                        value={balanceAmount}
                        onChange={(e) =>
                          setBalanceAmount(e.currentTarget.value)
                        }
                      />
                      <div className="ThreadDetailSidebarAdminFormTitle">
                        Credit/Debit Reason
                      </div>
                      <TextareaAutosize
                        type="text"
                        placeholder="Credit/debit reason..."
                        spellCheck="true"
                        className="ThreadDetailSidebarAdminFormInput"
                        value={balanceReason}
                        onChange={(e) => {
                          setBalanceReason(e.currentTarget.value);
                        }}
                      />
                      <div
                        className={
                          'ThreadDetailSidebarAdminFormAction ' +
                          (actionLoading
                            ? ' ThreadDetailSidebarAdminFormActionLoading '
                            : '')
                        }
                        onClick={updateBalance}
                      >
                        {balanceUpdateStr}
                      </div>
                    </div>
                    <div className="ThreadDetailSidebarAdminBalanceLog">
                      {!allAccountBalanceEvents.length && (
                        <div className="ThreadDetailSidebarAdminEmpty">
                          {errorAccountBalanceEventPaginated
                            ? errorUtils.getErrorMessage(
                                errorAccountBalanceEventPaginated,
                              ) || 'Loading Error'
                            : 'No transactions yet.'}
                        </div>
                      )}
                      <div className="ExpertProfileTransactions">
                        {allAccountBalanceEvents.map((abe) => {
                          let titleStr = '';
                          let subStr = '';
                          if (abe.brand && abe.project && abe.project.quote) {
                            titleStr = abe.brand.name || 'Project';
                            subStr =
                              abe.project.quote.title || abe.reason || '';
                          } else if (abe.paymentRailsPaymentID) {
                            titleStr = 'Cashed Out';
                            subStr =
                              abe.paymentRailsPaymentStatus || abe.reason || '';
                          } else if (abe.action === 'credit') {
                            titleStr = 'Credit Added';
                            subStr = abe.reason || '';
                          } else if (abe.action === 'debit') {
                            titleStr = 'Credit Removed';
                            subStr = abe.reason || '';
                          }
                          return (
                            <div
                              key={abe.id}
                              className={
                                'ExpertProfileTransaction ' +
                                (abe.action === 'credit'
                                  ? ' ExpertProfileTransactionCredit '
                                  : ' ExpertProfileTransactionDebit ')
                              }
                            >
                              <div className="ExpertProfileTransactionTitle">
                                {titleStr}
                              </div>
                              <div className="ExpertProfileTransactionSub">
                                {subStr}
                              </div>
                              <div className="ExpertProfileTransactionAmount">
                                $
                                {formatNumberWithCommas(
                                  centsDollarsDecimal(abe.amount || 0),
                                )}
                              </div>
                              <div className="ExpertProfileTransactionDate">
                                {moment(abe.createdAt).format('MMMM Do, YYYY')}
                              </div>
                            </div>
                          );
                        })}
                        {allAccountBalanceEvents.length >= PAGE_LIMIT &&
                          showLoadMoreBalance && (
                            <div
                              className="ExpertProfileTransactionsLoader"
                              onClick={loadMoreBalanceEvents}
                            >
                              load more
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/extras/partners')
              }
              className="DashboardErrorCoverNav"
            >
              back
            </div>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminPartnerDetail.propTypes = {
  partnerId: PropTypes.string.isRequired,
};

export default AdminPartnerDetail;
