import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import {
  RequestReviewForAdminFullQuery,
  RequestCanceledForAdminQuery,
  RequestMatchingForAdminQuery,
} from '../../gql/graphql';
import { RequestSummaryHq } from '../../utils/gql';
import errorUtils from '../../utils/error';

const requestReviewForAdminFullQuery = gql`
  query RequestReviewForAdminFull {
    requestReviewForAdmin {
      ...RequestSummaryHq
    }
  }
  ${RequestSummaryHq}
`;

const requestCanceledForAdminQuery = gql`
  query RequestCanceledForAdmin {
    requestCanceledForAdmin {
      ...RequestSummaryHq
    }
  }
  ${RequestSummaryHq}
`;

const requestMatchingForAdminQuery = gql`
  query RequestMatchingForAdmin {
    requestMatchingForAdmin {
      ...RequestSummaryHq
    }
  }
  ${RequestSummaryHq}
`;

interface AdminQueueProps {
  socketClient: ClientWithOnReconnected;
}

const initTime = new Date().getTime();

const AdminQueue = ({ socketClient }: AdminQueueProps) => {
  const {
    data: dataReviewRequests,
    error: errorReviewRequests,
    loading: loadingReviewRequests,
    refetch: refetchReviewRequests,
  } = useQuery<RequestReviewForAdminFullQuery>(requestReviewForAdminFullQuery, {
    returnPartialData: true,
  });
  const {
    data: dataCanceledRequests,
    error: errorCanceledRequests,
    loading: loadingCanceledRequests,
    refetch: refetchCanceledRequests,
  } = useQuery<RequestCanceledForAdminQuery>(requestCanceledForAdminQuery, {
    returnPartialData: true,
  });
  const {
    data: dataMatchingRequests,
    error: errorMatchingRequests,
    loading: loadingMatchingRequests,
    refetch: refetchMatchingRequests,
  } = useQuery<RequestMatchingForAdminQuery>(requestMatchingForAdminQuery, {
    returnPartialData: true,
  });
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminQueue socketClient onReconnected');
      refetchReviewRequests().catch(() => {});
      refetchCanceledRequests().catch(() => {});
      refetchMatchingRequests().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [
    socketClient,
    refetchReviewRequests,
    refetchCanceledRequests,
    refetchMatchingRequests,
  ]);
  // combine all data, dedup, sort into 4 groups, render
  const allRequestsForAdmin = _.uniqBy(
    [
      ...((dataReviewRequests && dataReviewRequests.requestReviewForAdmin) ||
        []),
      ...((dataCanceledRequests &&
        dataCanceledRequests.requestCanceledForAdmin) ||
        []),
      ...((dataMatchingRequests &&
        dataMatchingRequests.requestMatchingForAdmin) ||
        []),
    ],
    'id',
  ).sort((a, b) => (a.createdAt || 0) - (b.createdAt || 0));
  const requestsInReview = allRequestsForAdmin.filter(
    (r) => !r.cancelledAt && r.inReview && r.isActive,
  );
  const requestsInCanceled = allRequestsForAdmin.filter((r) => r.cancelledAt);
  const requestsInMatching = allRequestsForAdmin.filter(
    (r) => !r.cancelledAt && !r.inReview && r.isActive,
  );
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [requestsInReview, requestsInCanceled, requestsInMatching]);
  if (
    loadingReviewRequests ||
    loadingMatchingRequests ||
    loadingCanceledRequests
  ) {
    // ignore these
  }
  return (
    <div className="DashboardScreen ClientsScreen">
      <div className="DashboardScreenContent ClientsScreenContent">
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Needs Review</div>
          {!requestsInReview.length && (
            <div className="DashboardSectionEmpty">
              {errorReviewRequests
                ? errorUtils.getErrorMessage(errorReviewRequests)
                : 'New requests that need review will show up here.'}
            </div>
          )}
          <div className="DashboardSectionScrollerSwitch">
            {requestsInReview.map((r) => {
              let eventDateFull = '';
              let eventDateSummary = '';
              if (r.createdAt) {
                const nowMoment = moment.tz(initTime, moment.tz.guess());
                const eventMoment = moment.tz(r.createdAt, moment.tz.guess());
                eventDateFull =
                  eventMoment.format('MMMM Do, YYYY') +
                  ' at ' +
                  eventMoment.format('h:mma z');
                eventDateSummary = eventDateFull;
                if (nowMoment.isSame(eventMoment, 'day')) {
                  eventDateSummary =
                    'Today at ' + eventMoment.format('h:mma z');
                } else if (
                  nowMoment
                    .clone()
                    .subtract(1, 'day')
                    .isSame(eventMoment, 'day')
                ) {
                  eventDateSummary =
                    'Yesterday at ' + eventMoment.format('h:mma z');
                } else if (nowMoment.isSame(eventMoment, 'week')) {
                  eventDateSummary =
                    eventMoment.format('dddd') +
                    ' at ' +
                    eventMoment.format('h:mma z');
                } else if (nowMoment.isSame(eventMoment, 'year')) {
                  eventDateSummary =
                    eventMoment.format('MMMM Do') +
                    ' at ' +
                    eventMoment.format('h:mma z');
                }
              }
              return (
                <div key={r.id} className="ClientSummary ClientSummaryAlert">
                  <Link
                    to={'/requests/' + r.id}
                    className={
                      'ClientSummaryBody ' +
                      (r.adminAssigned ? ' ClientSummaryBodyExtended ' : '')
                    }
                  >
                    <div className="ClientSummaryTitle">
                      {r.brand && r.brand.name ? r.brand.name : ''}
                    </div>
                    <div className="ClientSummarySubTitle">
                      Review: {r.inReview}
                    </div>
                    {!!r.isMultipleMatch && (
                      <div className="ClientSummarySubTitle ClientSummarySubTitleAlert">
                        Is Multiple Match (made:{' '}
                        {r.matchLog ? r.matchLog.length : 0})
                      </div>
                    )}
                    {!!r.isAgency && (
                      <div className="ClientSummarySubTitle ClientSummarySubTitleAlert">
                        Agency: {r.isAgency}
                      </div>
                    )}
                    {!!r.isOngoing && (
                      <div className="ClientSummarySubTitle ClientSummarySubTitleAlert">
                        Is Ongoing
                      </div>
                    )}
                    {r.adminAssigned && (
                      <div
                        className={
                          'ClientSummaryAssignedTo ClientSummaryAssignedTo' +
                          (r.adminAssigned || '').toUpperCase()
                        }
                      >
                        {r.adminAssigned}
                      </div>
                    )}
                  </Link>
                  <div className="ClientSummaryFooter">
                    <div
                      className="ClientSummaryFooterText"
                      data-tip={eventDateFull}
                    >
                      {eventDateSummary || ''}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Recently Canceled</div>
          {!requestsInCanceled.length && (
            <div className="DashboardSectionEmpty">
              {errorCanceledRequests
                ? errorUtils.getErrorMessage(errorCanceledRequests)
                : 'Canceled requests that need to be reviewed will show up here.'}
            </div>
          )}
          <div className="DashboardSectionScrollerSwitch">
            {requestsInCanceled.map((r) => {
              let eventDateFull = '';
              let eventDateSummary = '';
              if (r.cancelledAt || r.createdAt) {
                const nowMoment = moment.tz(initTime, moment.tz.guess());
                const eventMoment = moment.tz(
                  r.cancelledAt || r.createdAt,
                  moment.tz.guess(),
                );
                eventDateFull =
                  eventMoment.format('MMMM Do, YYYY') +
                  ' at ' +
                  eventMoment.format('h:mma z');
                eventDateSummary = eventDateFull;
                if (nowMoment.isSame(eventMoment, 'day')) {
                  eventDateSummary =
                    'Today at ' + eventMoment.format('h:mma z');
                } else if (
                  nowMoment
                    .clone()
                    .subtract(1, 'day')
                    .isSame(eventMoment, 'day')
                ) {
                  eventDateSummary =
                    'Yesterday at ' + eventMoment.format('h:mma z');
                } else if (nowMoment.isSame(eventMoment, 'week')) {
                  eventDateSummary =
                    eventMoment.format('dddd') +
                    ' at ' +
                    eventMoment.format('h:mma z');
                } else if (nowMoment.isSame(eventMoment, 'year')) {
                  eventDateSummary =
                    eventMoment.format('MMMM Do') +
                    ' at ' +
                    eventMoment.format('h:mma z');
                }
              }
              return (
                <div key={r.id} className="ClientSummary ClientSummaryAlert">
                  <Link to={'/requests/' + r.id} className="ClientSummaryBody">
                    <div className="ClientSummaryTitle">
                      {r.brand && r.brand.name ? r.brand.name : ''}
                    </div>
                    <div className="ClientSummarySubTitle">
                      Cancel: {r.cancelledReason}
                    </div>
                    {!!r.isMultipleMatch && (
                      <div className="ClientSummarySubTitle ClientSummarySubTitleAlert">
                        Is Multiple Match (made:{' '}
                        {r.matchLog ? r.matchLog.length : 0})
                      </div>
                    )}
                    {!!r.isAgency && (
                      <div className="ClientSummarySubTitle ClientSummarySubTitleAlert">
                        Agency: {r.isAgency}
                      </div>
                    )}
                    {!!r.isOngoing && (
                      <div className="ClientSummarySubTitle ClientSummarySubTitleAlert">
                        Is Ongoing
                      </div>
                    )}
                    {r.adminAssigned && (
                      <div
                        className={
                          'ClientSummaryAssignedTo ClientSummaryAssignedTo' +
                          (r.adminAssigned || '').toUpperCase()
                        }
                      >
                        {r.adminAssigned}
                      </div>
                    )}
                    <div
                      className={
                        'ClientSummaryStatusTag ClientSummaryStatusTag' +
                        (r.cancelledHow || '').toUpperCase()
                      }
                    >
                      {r.cancelledHow}
                    </div>
                  </Link>
                  <div className="ClientSummaryFooter">
                    <div
                      className="ClientSummaryFooterText"
                      data-tip={eventDateFull}
                    >
                      {eventDateSummary || ''}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="DashboardSection">
          <div className="DashboardSectionHeader">Waiting For Match</div>
          {!requestsInMatching.length && (
            <div className="DashboardSectionEmpty">
              {errorMatchingRequests
                ? errorUtils.getErrorMessage(errorMatchingRequests)
                : 'New requests that are still waiting for a match will show up here.'}
            </div>
          )}
          <div className="DashboardSectionScrollerSwitch">
            {requestsInMatching.map((r) => {
              let eventDateFull = '';
              let eventDateSummary = '';
              if (r.createdAt) {
                const nowMoment = moment.tz(initTime, moment.tz.guess());
                const eventMoment = moment.tz(r.createdAt, moment.tz.guess());
                eventDateFull =
                  eventMoment.format('MMMM Do, YYYY') +
                  ' at ' +
                  eventMoment.format('h:mma z');
                eventDateSummary = eventDateFull;
                if (nowMoment.isSame(eventMoment, 'day')) {
                  eventDateSummary =
                    'Today at ' + eventMoment.format('h:mma z');
                } else if (
                  nowMoment
                    .clone()
                    .subtract(1, 'day')
                    .isSame(eventMoment, 'day')
                ) {
                  eventDateSummary =
                    'Yesterday at ' + eventMoment.format('h:mma z');
                } else if (nowMoment.isSame(eventMoment, 'week')) {
                  eventDateSummary =
                    eventMoment.format('dddd') +
                    ' at ' +
                    eventMoment.format('h:mma z');
                } else if (nowMoment.isSame(eventMoment, 'year')) {
                  eventDateSummary =
                    eventMoment.format('MMMM Do') +
                    ' at ' +
                    eventMoment.format('h:mma z');
                }
              }
              return (
                <div key={r.id} className="ClientSummary ClientSummaryAlert">
                  <Link to={'/requests/' + r.id} className="ClientSummaryBody">
                    <div className="ClientSummaryTitle">
                      {r.brand && r.brand.name ? r.brand.name : ''}
                    </div>
                    <div className="ClientSummarySubTitle">
                      Wait: {r.waitType}
                    </div>
                    {!!r.isMultipleMatch && (
                      <div className="ClientSummarySubTitle ClientSummarySubTitleAlert">
                        Is Multiple Match (made:{' '}
                        {r.matchLog ? r.matchLog.length : 0})
                      </div>
                    )}
                    {!!r.isHqApprove && (
                      <div className="ClientSummarySubTitle ClientSummarySubTitleAlert">
                        Is HQ Approve
                      </div>
                    )}
                    {!!r.isAgency && (
                      <div className="ClientSummarySubTitle ClientSummarySubTitleAlert">
                        Agency: {r.isAgency}
                      </div>
                    )}
                    {!!r.isOngoing && (
                      <div className="ClientSummarySubTitle ClientSummarySubTitleAlert">
                        Is Ongoing
                      </div>
                    )}
                    {r.adminAssigned && (
                      <div
                        className={
                          'ClientSummaryAssignedTo ClientSummaryAssignedTo' +
                          (r.adminAssigned || '').toUpperCase()
                        }
                      >
                        {r.adminAssigned}
                      </div>
                    )}
                    {!!r.isHqApprove &&
                      !!r.needsApproval &&
                      !!r.needsApproval.length && (
                        <div className="ClientSummaryStatusTag ClientSummaryStatusTagBLACKLIST">
                          NEEDS APPROVAL
                        </div>
                      )}
                  </Link>
                  <div className="ClientSummaryFooter">
                    <div
                      className="ClientSummaryFooterText"
                      data-tip={eventDateFull}
                    >
                      {eventDateSummary || ''}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

AdminQueue.propTypes = {
  socketClient: PropTypes.object.isRequired,
};

export default AdminQueue;
