import { Notifier, INotice } from '@airbrake/browser';
import envUtils from './env';
import oauthUtils from './oauth';
const airbrake = new Notifier({
  projectId: 316468,
  projectKey: '4b799d82cb20ab6ca142dd6bb2b79173',
});

/**
 * Ensure airbrake sends to proper environment. Add filters here if we don't want certain errors to reach Airbrake
 * @type {Notice} forwards notice to notify
 */
airbrake.addFilter((notice: INotice) => {
  notice.context.environment = envUtils.get();
  if (shouldFilter(notice.errors)) return null;
  return envUtils.pick('true', 'true', '') ? notice : null; // don't send errors in dev
});

const shouldFilter = (errors: INotice['errors']) => {
  if (!errors) return false;
  if (errors.find((el) => el.message.includes('Audio node triggered too many')))
    return true;
  if (errors.find((el) => el.message.includes('GraphQL error'))) return true;
  if (
    errors.find((el) =>
      el.message.includes(
        'A message was not sent because socket is not connected, is closing or is already closed',
      ),
    )
  )
    return true;
  return false;
};

/**
 * Error logging for the app.
 * @param  {String | Error}  err           Error object or String error message
 * @param  {Object}  params                Params like 'info', used for error bubbling in React ErrorBoundary component
 * @param  {String | undefined} severity   See https://airbrake.io/docs/airbrake-faq/what-is-severity/
 * @return {undefined}
 */
const logError = (err: Error | string, params?: any, severity?: string) => {
  const cachedUserId = oauthUtils.getCachedUserId();
  console.error(
    `logError(severity=${severity || 'error'})`,
    err,
    params,
    cachedUserId,
  );
  airbrake
    .notify({
      context: { gqlUserId: cachedUserId, severity },
      error: err,
      params: { ...params },
    })
    .catch(() => {});
};

export default logError;
