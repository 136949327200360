/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import React, { useEffect, useState, Fragment, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import copyToClipboard from 'clipboard-copy';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import moment from 'moment-timezone';
import { useHistory, Link } from 'react-router-dom';
import { ClientWithOnReconnected } from '../../utils/apollo';
import '../../styles/page/ExpertRequestDetail.scss';
import logError from '../../utils/airbrake';
import tokenUtils from '../../utils/token';
import errorUtils from '../../utils/error';
import templateUtils from '../../utils/template';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import {
  TextMessageEventDetail,
  EmailMessageEventDetail,
  MessageEventDetail,
  MeetingEventDetail,
  QuoteDetail,
  PhoneCallEventDetail,
  RequestEventDetail,
  MatchDetail,
  RequestDetail,
  ProjectDetailHq,
} from '../../utils/gql';
import {
  SKIP_REASONS,
  SKILL_OPTIONS,
  TOOL_OPTIONS,
} from '../../utils/constants';
import MultiSelect from '../feature/MultiSelect';
import LocationTimestamp from '../feature/LocationTimestamp';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import RequestThreadEvent from '../threadEvent/RequestThreadEvent';
import EmailMessageThreadEvent from '../threadEvent/EmailMessageThreadEvent';
import TextMessageThreadEvent from '../threadEvent/TextMessageThreadEvent';
import QuoteThreadEvent from '../threadEvent/QuoteThreadEvent';
import MessageThreadEvent from '../threadEvent/MessageThreadEvent';
import PhoneCallThreadEvent from '../threadEvent/PhoneCallThreadEvent';
import MeetingThreadEvent from '../threadEvent/MeetingThreadEvent';
import { IFilestackFileUpload } from '../../utils/filestack';
import EmailEditor from '../feature/EmailEditor';
import TextEditor from '../feature/TextEditor';
import {
  prefabPriceStr,
  unclaimedReason,
  kpiDollars,
  kpiNumber,
  formatNumberWithCommas,
  centsDollarsRounded,
  quoteStatusFormatted,
  validDiscountCode,
  pitchDiscountCode,
  tagLabelBudget,
  tagLabelFocus,
  tagLabelTimeline,
} from '../../utils/format';
import {
  TemplatesQuery,
  RequestDetailsQuery,
  RequestDetailsQueryVariables,
  MatchHistoryThreadPaginatedQuery,
  MatchHistoryThreadPaginatedQueryVariables,
  RequestCancelReadSessionMutation,
  RequestCancelReadSessionMutationVariables,
  RequestDetailSkipMutation,
  RequestDetailSkipMutationVariables,
  RequestExtendReadSessionMutation,
  RequestExtendReadSessionMutationVariables,
  RequestTagsByExpertMutation,
  RequestTagsByExpertMutationVariables,
  RequestMatchMutation,
  RequestMatchMutationVariables,
  RequestMatchAskMutation,
  RequestMatchAskMutationVariables,
  ProjectPaginateBrandRequestQuery,
  ProjectPaginateBrandRequestQueryVariables,
  MatchRequestSendTextMessageMutation,
  MatchRequestSendTextMessageMutationVariables,
  MatchRequestSendEmailMessageMutation,
  MatchRequestSendEmailMessageMutationVariables,
  ExpertDetailsQuery,
  QuoteStatus,
} from '../../gql/graphql';

const requestDetailsQuery = gql`
  query RequestDetails($requestId: ID!) {
    requestDetails(requestId: $requestId) {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const requestExtendReadSessionMutation = gql`
  mutation RequestExtendReadSession($requestId: ID!, $isExtension: Boolean!) {
    requestStartReadSession(requestId: $requestId, isExtension: $isExtension) {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const requestTagsByExpertMutation = gql`
  mutation RequestTagsByExpert(
    $requestId: ID!
    $tagSkills: [String!]!
    $tagTools: [String!]!
  ) {
    requestTagsByExpert(
      requestId: $requestId
      tagSkills: $tagSkills
      tagTools: $tagTools
    ) {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

// @connection(key: "thread", filter: ["matchId"])
const matchHistoryThreadPaginatedQuery = gql`
  query MatchHistoryThreadPaginated(
    $matchId: ID
    $direction: String!
    $fromDate: Date
    $limit: Int!
  ) {
    threadPaginated(
      matchId: $matchId
      direction: $direction
      fromDate: $fromDate
      limit: $limit
    ) {
      id
      edges {
        id
        cursor
        node {
          ... on TextMessageEvent {
            ...TextMessageEventDetail
          }
          ... on EmailMessageEvent {
            ...EmailMessageEventDetail
          }
          ... on RequestEvent {
            ...RequestEventDetail
          }
          ... on MessageEvent {
            ...MessageEventDetail
          }
          ... on MeetingEvent {
            ...MeetingEventDetail
          }
          ... on Quote {
            ...QuoteDetail
          }
          ... on PhoneCallEvent {
            ...PhoneCallEventDetail
          }
        }
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const requestMatchMutation = gql`
  mutation RequestMatch($requestId: ID!, $useEmail: Boolean) {
    requestMatch(requestId: $requestId, useEmail: $useEmail) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const requestMatchAskMutation = gql`
  mutation RequestMatchAsk(
    $requestId: ID!
    $emailInput: ThreadSendEmailMessageInput
    $textInput: ThreadSendTextMessageInput
  ) {
    requestMatchAsk(
      requestId: $requestId
      emailInput: $emailInput
      textInput: $textInput
    ) {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const requestCancelReadSessionMutation = gql`
  mutation RequestCancelReadSession($requestId: ID!) {
    requestCancelReadSession(requestId: $requestId) {
      id
      readSessionClaimedBy {
        id
        fullName
      }
      readSessionEndsAt
      readSessionExtended
    }
  }
`;

const requestDetailSkipMutation = gql`
  mutation RequestDetailSkip(
    $requestId: ID!
    $reason: String!
    $moreDetail: String
  ) {
    requestSkip(
      requestId: $requestId
      reason: $reason
      moreDetail: $moreDetail
    ) {
      id
      expertsSkippedReason {
        expertStr
      }
      potentialMatchesStr
      readSessionClaimedBy {
        id
        fullName
      }
      readSessionEndsAt
      readSessionExtended
    }
  }
`;

const matchRequestSendEmailMessageMutation = gql`
  mutation MatchRequestSendEmailMessage($input: ThreadSendEmailMessageInput!) {
    threadSendEmailMessage(input: $input) {
      ...EmailMessageEventDetail
    }
  }
  ${EmailMessageEventDetail}
`;

const matchRequestSendTextMessageMutation = gql`
  mutation MatchRequestSendTextMessage($input: ThreadSendTextMessageInput!) {
    threadSendTextMessage(input: $input) {
      ...TextMessageEventDetail
    }
  }
  ${TextMessageEventDetail}
`;

const projectPaginateBrandRequestQuery = gql`
  query ProjectPaginateBrandRequest(
    $brandId: ID!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    projectPaginateBrand(
      brandId: $brandId
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...ProjectDetailHq
    }
  }
  ${ProjectDetailHq}
`;

interface ExpertRequestDetailProps {
  emailTemplates: TemplatesQuery['templates'];
  expertDetails?: ExpertDetailsQuery['expertDetails'];
  expertId: string;
  requestId: string;
  socketClient: ClientWithOnReconnected;
}

interface IGroupedEvents {
  group: MatchHistoryThreadPaginatedQuery['threadPaginated']['edges'];
}

let skipReadCancel = false;
const initTime = new Date().getTime();
const ACTIVITY_PAGE_LIMIT = 3;

const ExpertRequestDetail = ({
  emailTemplates,
  expertDetails,
  expertId,
  requestId,
  socketClient,
}: ExpertRequestDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const [initDate] = useState(() => new Date());
  const [isTagging, setIsTagging] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState(false);
  const [step, setStep] = useState('view');
  const [isSkipping, setIsSkipping] = useState(false);
  const [showHqApprove, setShowHqApprove] = useState(false);
  const [timeLeft, setTimeLeft] = useState('0:00');
  const [matchHistoryId, setMatchHistoryId] = useState('');
  const [requestTagSkills, setRequestTagSkills] = useState([] as string[]);
  const [requestTagTools, setRequestTagTools] = useState([] as string[]);
  const [contactPreference, setContactPreference] = useState('');
  const [emailStatus, setEmailStatus] = useState('');
  const [emailFiles, setEmailFiles] = useState([] as IFilestackFileUpload[]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailIntroContent, setEmailIntroContent] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [emailRecipients, setEmailRecipients] = useState(
    [] as RequestDetailsQuery['requestDetails']['createdBy'][],
  );
  const [textStatus, setTextStatus] = useState('');
  const [textFiles, setTextFiles] = useState([] as IFilestackFileUpload[]);
  const [textContent, setTextContent] = useState('');
  const [textRecipients, setTextRecipients] = useState(
    [] as RequestDetailsQuery['requestDetails']['createdBy'][],
  );
  const [showLoadMoreProjects, setShowLoadMoreProjects] = useState(true);
  const {
    data: dataRequest,
    error: errorRequest,
    loading: loadingRequest,
    refetch: refetchRequest,
  } = useQuery<RequestDetailsQuery, RequestDetailsQueryVariables>(
    requestDetailsQuery,
    {
      onCompleted: (completedData) => {
        if (completedData && completedData.requestDetails) {
          setTimeout(claimIfAvailable, 1000);
          setRequestTagSkills(completedData.requestDetails.tagSkills);
          setRequestTagTools(completedData.requestDetails.tagTools);
        }
      },
      returnPartialData: true,
      variables: {
        requestId,
      },
    },
  );
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('ExpertRequestDetail socketClient onReconnected');
      refetchRequest().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchRequest]);
  let fullErrorCover = '';
  if (errorRequest) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorRequest) ||
      'Could not load request details';
  }
  const requestDetails =
    dataRequest && dataRequest.requestDetails && dataRequest.requestDetails.id
      ? dataRequest.requestDetails
      : undefined;
  let hasCurrentMatchOrMultiple3 = false;
  if (requestDetails) {
    if (requestDetails.isMultipleMatch && requestDetails.matchLog) {
      hasCurrentMatchOrMultiple3 = requestDetails.matchLog.length >= 3;
    } else if (requestDetails.currentMatchStr) {
      hasCurrentMatchOrMultiple3 = true;
    }
  }
  let unavailableErrorReason = '';
  let readSessionIsAvailable = false;
  if (requestDetails) {
    if (requestDetails.cancelledAt) {
      unavailableErrorReason =
        "Unfortunately, it looks like this project was canceled. Don't worry, new leads are always available on Storetasker.";
    } else if (hasCurrentMatchOrMultiple3 && !sendLoading && !claimSuccess) {
      unavailableErrorReason =
        "Unfortunately, it looks like this project was already matched to another expert. Don't worry, new leads are always available on Storetasker.";
    } else if (requestDetails.inReview) {
      unavailableErrorReason =
        "Unfortunately, this project is no longer active. Don't worry, new leads are always available on Storetasker.";
    } else if (!requestDetails.isActive && !sendLoading && !claimSuccess) {
      unavailableErrorReason =
        "Unfortunately, this project is no longer active. Don't worry, new leads are always available on Storetasker.";
    } else if (
      requestDetails.expertsSkippedReason &&
      requestDetails.expertsSkippedReason.find(
        (skip) => skip.expertStr === expertId,
      )
    ) {
      unavailableErrorReason =
        "Unfortunately, it looks like you've already skipped this project. Don't worry, new leads are always available on Storetasker.";
    } else if (
      requestDetails.potentialMatchesStr &&
      requestDetails.potentialMatchesStr.indexOf(expertId) === -1 &&
      !sendLoading &&
      !claimSuccess
    ) {
      unavailableErrorReason =
        "Unfortunately, this project is no longer active. Don't worry, new leads are always available on Storetasker.";
    } else if (
      requestDetails &&
      requestDetails.readSessionClaimedByStr &&
      requestDetails.readSessionClaimedByStr !== expertId &&
      !sendLoading &&
      !claimSuccess
    ) {
      unavailableErrorReason =
        'Another expert is currently viewing this project. It may become available in a few minutes.';
    } else if (
      requestDetails &&
      !requestDetails.readSessionClaimedByStr &&
      !sendLoading &&
      !claimSuccess
    ) {
      readSessionIsAvailable = true;
    }
  }
  function claimIfAvailable() {
    if (
      requestDetails &&
      readSessionIsAvailable &&
      !fullErrorCover &&
      !unavailableErrorReason
    ) {
      tryExtendRead(false);
    }
  }
  const hasPreviousActivity =
    requestDetails &&
    !requestDetails.isMultipleMatch &&
    requestDetails.matchLog &&
    requestDetails.matchLog.length >= 1;
  const hasOtherTags =
    requestDetails &&
    ((requestDetails.tagFocus || []).length >= 1 ||
      (requestDetails.tagLocations || []).length >= 1 ||
      requestDetails.tagTimeline ||
      requestDetails.tagBudgetMax ||
      requestDetails.tagBudgetMin);
  let didEditTags = false;
  if (requestDetails) {
    if (
      requestDetails.tagSkills.length !== requestTagSkills.length ||
      requestDetails.tagSkills.find((t) => requestTagSkills.indexOf(t) === -1)
    ) {
      didEditTags = true;
    }
    if (
      requestDetails.tagTools.length !== requestTagTools.length ||
      requestDetails.tagTools.find((t) => requestTagTools.indexOf(t) === -1)
    ) {
      didEditTags = true;
    }
  }
  const nowMoment = moment.tz(initTime, moment.tz.guess());
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [requestDetails, step]);
  let eventDateFull = '';
  let eventDateSummary = '';
  if (requestDetails) {
    let pickDate = requestDetails.createdAt;
    if (
      requestDetails.matchLog &&
      requestDetails.matchLog.length &&
      requestDetails.matchLog[requestDetails.matchLog.length - 1].unmatchedAt
    ) {
      pickDate =
        requestDetails.matchLog[requestDetails.matchLog.length - 1]
          .unmatchedAt!;
    }
    const eventMoment = moment.tz(pickDate, moment.tz.guess());
    // January 4th, 2020 at 5:37pm PST
    eventDateFull =
      eventMoment.format('MMMM Do, YYYY') +
      ' at ' +
      eventMoment.format('h:mma z');
    eventDateSummary = eventMoment.format('M/D/YY');
    if (nowMoment.isSame(eventMoment, 'day')) {
      eventDateSummary = 'Today at ' + eventMoment.format('h:mma z');
    } else if (
      nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')
    ) {
      eventDateSummary = 'Yesterday at ' + eventMoment.format('h:mma z');
    } else if (nowMoment.isSame(eventMoment, 'week')) {
      eventDateSummary =
        eventMoment.format('dddd') + ' at ' + eventMoment.format('h:mma z');
    } else if (nowMoment.isSame(eventMoment, 'year')) {
      eventDateSummary = eventMoment.format('MMM Do');
    }
  }
  const brandId =
    requestDetails && requestDetails.brand ? requestDetails.brand.id : '';
  const {
    data: dataProjectPaginated,
    error: errorProjectPaginated,
    loading: loadingProjectPaginated,
    fetchMore: fetchMoreProjectPaginated,
  } = useQuery<
    ProjectPaginateBrandRequestQuery,
    ProjectPaginateBrandRequestQueryVariables
  >(projectPaginateBrandRequestQuery, {
    skip: !brandId,
    variables: {
      brandId,
      direction: 'BACKWARD',
      fromDate: initDate.getTime(),
      limit: ACTIVITY_PAGE_LIMIT,
    },
  });
  const allProjectsForBrand = _.uniqBy(
    [
      ...((dataProjectPaginated && dataProjectPaginated.projectPaginateBrand) ||
        []),
    ].filter((r) => r.brandStr === brandId),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  const oldestCreatedProject =
    allProjectsForBrand[allProjectsForBrand.length - 1];
  function loadMoreProjects() {
    fetchMoreProjectPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.projectPaginateBrand)
          return prev;
        if (fetchMoreResult.projectPaginateBrand.length < ACTIVITY_PAGE_LIMIT) {
          setShowLoadMoreProjects(false);
        }
        return {
          projectPaginateBrand: (prev.projectPaginateBrand || []).concat(
            fetchMoreResult.projectPaginateBrand,
          ),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate: oldestCreatedProject.createdAt,
        limit: ACTIVITY_PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'ExpertRequestDetail',
        func: 'loadMoreProjects',
      });
    });
  }
  const {
    data: dataThread,
    error: errorThread,
    loading: loadingThread,
  } = useQuery<
    MatchHistoryThreadPaginatedQuery,
    MatchHistoryThreadPaginatedQueryVariables
  >(matchHistoryThreadPaginatedQuery, {
    skip: !matchHistoryId,
    variables: {
      direction: 'after',
      fromDate: 0,
      limit: 100, // FUTURE there could be more than 100 messages in the history...
      matchId: matchHistoryId,
    },
  });
  const cleanThread =
    (dataThread &&
      dataThread.threadPaginated &&
      dataThread.threadPaginated.edges) ||
    [];
  if (errorThread) {
    fullErrorCover =
      'Error loading this thread: ' + errorUtils.getErrorMessage(errorThread);
  }
  if (
    loadingThread ||
    loadingRequest ||
    loadingProjectPaginated ||
    errorProjectPaginated
  ) {
    // ignore these
  }
  function groupThreadEvents(
    events: MatchHistoryThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    const groupedEvents: IGroupedEvents[] = [];
    events.forEach((ev) => {
      let isSameEventType = false;
      let isSameSenderSide = false;
      let isSameHuman = false;
      let isCurrGroupable = false;
      let isLastGroupable = false;
      const isFirstGroup = !groupedEvents.length;
      if (
        !isFirstGroup &&
        (ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'MessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent')
      ) {
        isCurrGroupable = true;
        isLastGroupable = true;
        const lastGroup = groupedEvents[groupedEvents.length - 1];
        const lastGroupEvent = lastGroup.group[0];
        // is same event type as last
        if (lastGroupEvent.node.__typename === ev.node.__typename) {
          isSameEventType = true;
          // is both sent from expert or both from Human
          isSameSenderSide =
            !!(ev.node.ownerHuman && lastGroupEvent.node.ownerHuman) ||
            !!(ev.node.ownerExpert && lastGroupEvent.node.ownerExpert);
          // is human interacting same
          isSameHuman =
            (ev.node.ownerHuman &&
              lastGroupEvent.node.ownerHuman &&
              ev.node.ownerHuman.id === lastGroupEvent.node.ownerHuman.id) ||
            (ev.node.recipientHumans[0] &&
              lastGroupEvent.node.recipientHumans[0] &&
              ev.node.recipientHumans[0].id ===
                lastGroupEvent.node.recipientHumans[0].id);
          // don't group texts with quotes attached
          if (ev.node.__typename === 'TextMessageEvent') {
            if (ev.node.quote) {
              isCurrGroupable = false;
            }
          }
          if (lastGroupEvent.node.__typename === 'TextMessageEvent') {
            if (lastGroupEvent.node.quote) {
              isLastGroupable = false;
            }
          }
        }
      }
      if (
        isFirstGroup ||
        !isCurrGroupable ||
        !isLastGroupable ||
        !isSameEventType ||
        !isSameSenderSide ||
        !isSameHuman
      ) {
        groupedEvents.push({
          group: [ev],
        });
      } else {
        groupedEvents[groupedEvents.length - 1].group.push(ev);
      }
    });
    return groupedEvents;
  }
  const threadEventEdges = _.uniqBy(cleanThread, 'id')
    .sort((a, b) => a.cursor - b.cursor)
    .filter((ev) => {
      if (ev.node.__typename === 'Quote') {
        // filter out quote accepts on subscriptions
        return (
          ev.node.paymentType === 'PROJECT' ||
          ev.node.paymentType === 'BILL' ||
          ev.node.status !== QuoteStatus.Accepted
        );
      }
      return true;
    });
  const threadEventGroups = groupThreadEvents(threadEventEdges);
  function generateComponentsForGroup(
    groupedEvents: MatchHistoryThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    return groupedEvents
      .map((ev, i) => {
        if (ev.node.__typename === 'RequestEvent') {
          return (
            <RequestThreadEvent
              key={ev.id}
              requestEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'MessageEvent') {
          return (
            <MessageThreadEvent
              key={ev.id}
              expertId={expertId}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              messageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'Quote') {
          return (
            <QuoteThreadEvent
              key={ev.id}
              quote={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'EmailMessageEvent') {
          return (
            <EmailMessageThreadEvent
              key={ev.id}
              expertId={expertId}
              emailMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'TextMessageEvent') {
          return (
            <TextMessageThreadEvent
              key={ev.id}
              expertId={expertId}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              textMessageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'PhoneCallEvent') {
          return (
            <PhoneCallThreadEvent
              key={ev.id}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              phoneCallEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        if (ev.node.__typename === 'MeetingEvent') {
          return (
            <MeetingThreadEvent
              key={ev.id}
              meetingEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
            />
          );
        }
        console.log('Missing ThreadEvent type');
        return null;
      })
      .filter((c) => c);
  }
  let matchLogCurrent:
    | RequestDetailsQuery['requestDetails']['matchLog'][0]
    | undefined;
  if (matchHistoryId && requestDetails && requestDetails.matchLog) {
    matchLogCurrent = requestDetails.matchLog.find(
      (ml) => ml.matchStr === matchHistoryId,
    );
  }
  useEffect(() => {
    function updateTime() {
      if (requestDetails) {
        const timeDiff =
          Math.max(
            0,
            (requestDetails.readSessionEndsAt || 0) - new Date().getTime(),
          ) / 1000;
        const min = Math.floor(timeDiff / 60.0).toString();
        const secRaw = Math.floor(timeDiff % 60);
        const sec = secRaw < 10 ? '0' + secRaw.toString() : secRaw.toString();
        setTimeLeft(min + ':' + sec);
      }
    }
    const timerInterval = setInterval(updateTime, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [requestDetails]);
  const [tryExtendReadSession] = useMutation<
    RequestExtendReadSessionMutation,
    RequestExtendReadSessionMutationVariables
  >(requestExtendReadSessionMutation);
  function tryExtendRead(isExtension: boolean) {
    console.log('tryExtendRead', requestId);
    tryExtendReadSession({
      variables: {
        isExtension,
        requestId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Extend Read Error');
      logError(err, {
        component: 'ExpertRequestDetail',
        func: 'tryExtendRead',
      });
    });
  }
  const [trySkip] = useMutation<
    RequestDetailSkipMutation,
    RequestDetailSkipMutationVariables
  >(requestDetailSkipMutation);
  function trySkipRequest(reason: string, moreDetail?: string) {
    console.log('trySkipRequest', requestId);
    trySkip({
      optimisticResponse: {
        requestSkip: {
          __typename: 'Request',
          expertsSkippedReason: (
            (requestDetails && requestDetails.expertsSkippedReason) ||
            []
          ).concat({
            __typename: 'ExpertSkippedReason',
            expertStr: expertId,
          }),
          id: requestId,
          potentialMatchesStr: (
            (requestDetails && requestDetails.potentialMatchesStr) ||
            []
          ).filter((pm) => pm !== expertId),
          readSessionClaimedBy: null,
          readSessionEndsAt: null,
          readSessionExtended: false,
        },
      },
      variables: {
        moreDetail,
        reason,
        requestId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Skip Request Error');
      logError(err, {
        component: 'ExpertRequestDetail',
        func: 'trySkipRequest',
      });
    });
    skipReadCancel = true;
    history.push('/leads');
  }
  const [tryTag] = useMutation<
    RequestTagsByExpertMutation,
    RequestTagsByExpertMutationVariables
  >(requestTagsByExpertMutation);
  function saveNewTags() {
    if (isTagging || !requestDetails) return;
    setIsTagging(true);
    tryTag({
      optimisticResponse: {
        requestTagsByExpert: {
          ...requestDetails,
          tagSkills: requestTagSkills,
          tagTools: requestTagTools,
        },
      },
      variables: {
        requestId,
        tagSkills: requestTagSkills,
        tagTools: requestTagTools,
      },
    })
      .then(() => {
        setIsTagging(false);
      })
      .catch((err: ApolloError) => {
        setIsTagging(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Tag Request Error');
        logError(err, {
          component: 'ExpertRequestDetail',
          func: 'tryTag',
        });
      });
  }

  const [tryReadCancel] = useMutation<
    RequestCancelReadSessionMutation,
    RequestCancelReadSessionMutationVariables
  >(requestCancelReadSessionMutation, {
    variables: {
      requestId,
    },
  });
  useEffect(() => {
    setContactPreference('');
    setEmailStatus('');
    setEmailSubject('');
    setEmailIntroContent('');
    setEmailContent('');
    setEmailRecipients([]);
    setEmailFiles([]);
    setTextStatus('');
    setTextContent('');
    setTextRecipients([]);
    setTextFiles([]);
    if (requestId) {
      // check & clean up previous drafts
      const textDraftId = tokenUtils.readLocalStorage('request-text-id') || '';
      if (textDraftId !== requestId) {
        tokenUtils.removeLocalStorage('request-text-id');
        tokenUtils.removeLocalStorage('request-text-content');
      }
      const emailDraftId =
        tokenUtils.readLocalStorage('request-email-id') || '';
      if (emailDraftId !== requestId) {
        tokenUtils.removeLocalStorage('request-email-id');
        tokenUtils.removeLocalStorage('request-email-content');
      }
    }
  }, [requestId]);
  useEffect(() => {
    skipReadCancel = false;
    return () => {
      console.log('tryCancelReadSession', skipReadCancel);
      if (!skipReadCancel) {
        tryReadCancel({
          optimisticResponse: {
            requestCancelReadSession: {
              __typename: 'Request',
              id: requestId,
              readSessionClaimedBy: null,
              readSessionEndsAt: null,
              readSessionExtended: false,
            },
          },
        }).catch((err: ApolloError) => {
          logError(err, {
            component: 'ExpertRequestDetail',
            func: 'tryCancelReadSession',
          });
        });
      }
    };
  }, [tryReadCancel, requestId]);
  function unclaimedByWho(expertFullName?: string, unmatchedHow?: string) {
    if (unmatchedHow === 'expert') {
      return 'Unclaimed by ' + (expertFullName || 'expert');
    }
    if (unmatchedHow === 'brand') {
      return (expertFullName || 'Expert') + ' unclaimed by client';
    }
    if (unmatchedHow === 'hq') {
      return (expertFullName || 'Expert') + ' un-matched by HQ';
    }
    return 'Unclaimed by expert';
  }
  function unclaimedGoal(expertFirstName?: string, unmatchedHow?: string) {
    if (unmatchedHow === 'expert') {
      return (
        (expertFirstName || 'They') +
        ' said the client is still interested in finding another expert to work with.'
      );
    }
    return 'The client said they are still interested in finding another expert to work with.';
  }
  function unclaimedReasonCombined(
    unmatchedReasonRaw?: string,
    unmatchedMoreDetail?: string,
  ) {
    let combinedStr = '';
    const unmatchedReason = unclaimedReason(unmatchedReasonRaw || '');
    if (unmatchedReason) {
      combinedStr = unmatchedReason;
    }
    if (unmatchedMoreDetail) {
      combinedStr =
        (combinedStr ? combinedStr + ': ' : '') +
        '"' +
        unmatchedMoreDetail +
        '"';
    }
    return combinedStr;
  }
  function blockClickPropagation(ev: React.MouseEvent) {
    if (ev) {
      // ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  function startClaimScreen() {
    setStep('claim');
    tryExtendRead(true);
    let pickEditorType = 'email';
    if (contactPreference) {
      pickEditorType = contactPreference;
    } else if (requestDetails && requestDetails.contactPreference) {
      pickEditorType = requestDetails.contactPreference;
    }
    if (pickEditorType === 'text') {
      if (!expertDetails || !expertDetails.primaryPublicPhone) {
        pickEditorType = 'email';
      }
      if (
        !requestDetails ||
        !requestDetails.createdBy ||
        !requestDetails.createdBy.primaryPhone
      ) {
        pickEditorType = 'email';
      }
    }
    if (pickEditorType === 'text') {
      setupTextEditor();
    } else {
      setupEmailEditor();
    }
  }
  const isTextAllowed =
    !!expertDetails &&
    !!expertDetails.primaryPublicPhone &&
    !!requestDetails &&
    !!requestDetails.createdBy &&
    !!requestDetails.createdBy.primaryPhone;
  function setupEmailEditor(skipCheck?: boolean) {
    console.log('setupEmailEditor', requestDetails, contactPreference);
    if (!requestDetails) {
      return;
    }
    if (!contactPreference || skipCheck) {
      setContactPreference('email');
      let content = '';
      let subject = '';
      // setup email
      const emailDraftId = tokenUtils.readLocalStorage('request-email-id');
      if (emailDraftId) {
        // draft found
        content = tokenUtils.readLocalStorage('request-email-content') || '';
      }
      const plannedRecipients = requestDetails.createdBy
        ? [requestDetails.createdBy]
        : [];
      const plannedRecipientNames = plannedRecipients.map((r) => ({
        firstName: r.firstName,
        lastName: r.lastName,
      }));
      const expertName = expertDetails
        ? {
            firstName: expertDetails.firstName,
            lastName: expertDetails.lastName,
          }
        : undefined;
      subject = templateUtils.getClaimLeadDefaultEmailSubject(
        requestDetails.brand && requestDetails.brand.name,
        plannedRecipientNames[0],
        expertName,
      );
      const introContent = templateUtils.getClaimLeadDefaultEmailIntro(
        plannedRecipientNames[0],
        expertName,
        expertDetails && expertDetails.tagSkills,
        expertDetails && expertDetails.tagFocus,
        (expertDetails && expertDetails.profileClients) || undefined,
        (expertDetails && expertDetails.profileEmailIntro) || undefined,
        expertDetails && expertDetails.primaryPublicEmail,
        (expertDetails && expertDetails.primaryPublicPhone) || undefined,
      );
      if (!content) {
        content = templateUtils.getClaimLeadDefaultEmail(
          plannedRecipientNames,
          expertDetails &&
            expertDetails.scheduleTimeEnabled &&
            expertDetails.profilePath
            ? expertDetails.profilePath
            : undefined,
          expertName,
        );
      }
      setEmailSubject(subject || '');
      setEmailIntroContent(introContent || '');
      setEmailContent(content || '');
      setEmailRecipients(plannedRecipients);
      setEmailFiles([]);
      setEmailStatus(emailDraftId ? 'Saved' : '');
    }
  }
  const debouncedEmailDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage('request-email-id', requestId || '');
    tokenUtils.writeLocalStorage('request-email-content', emailContent);
    setEmailStatus('Saved');
  }, 1000);
  function onSaveEmailDraft(
    subject: string,
    draftContent: string | null,
    files: IFilestackFileUpload[],
  ) {
    let saveDraft = false;
    if (emailSubject !== subject) {
      saveDraft = true;
      setEmailSubject(subject || '');
    }
    if (emailFiles !== files) {
      setEmailFiles(files || []);
    }
    if (draftContent !== null && emailContent !== draftContent) {
      saveDraft = true;
      setEmailContent(draftContent);
    }
    if (saveDraft) {
      setEmailStatus('Saving');
      debouncedEmailDraft.callback();
    }
  }
  function setupTextEditor(skipCheck?: boolean) {
    if (!requestDetails) {
      return;
    }
    if (!contactPreference || skipCheck) {
      setContactPreference('text');
      let content = '';
      // setup text
      const textDraftId = tokenUtils.readLocalStorage('request-text-id');
      if (textDraftId) {
        // draft found
        content = tokenUtils.readLocalStorage('request-text-content') || '';
      }
      const plannedRecipients = [requestDetails.createdBy];
      if (!content) {
        const plannedRecipientNames = plannedRecipients.map((r) => ({
          firstName: r.firstName,
          lastName: r.lastName,
        }));
        const expertName = expertDetails
          ? {
              firstName: expertDetails.firstName,
              lastName: expertDetails.lastName,
            }
          : undefined;
        content = templateUtils.getClaimLeadDefaultText(
          plannedRecipientNames,
          expertName,
        );
      }
      setTextContent(content || '');
      setTextRecipients(plannedRecipients);
      setTextFiles([]);
      setTextStatus(textDraftId ? 'Saved' : '');
    }
  }
  const debouncedTextDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage('request-text-id', requestId || '');
    tokenUtils.writeLocalStorage('request-text-content', textContent);
    setTextStatus('Saved');
  }, 1000);
  function onSaveTextDraft(content: string, files: IFilestackFileUpload[]) {
    let saveDraft = false;
    if (textFiles !== files) {
      setTextFiles(files || []);
    }
    if (textContent !== content) {
      saveDraft = true;
      setTextContent(content);
    }
    if (saveDraft) {
      setTextStatus('Saving');
      debouncedTextDraft.callback();
    }
  }
  const [trySendEmailMessage] = useMutation<
    MatchRequestSendEmailMessageMutation,
    MatchRequestSendEmailMessageMutationVariables
  >(matchRequestSendEmailMessageMutation);
  const [trySendTextMessage] = useMutation<
    MatchRequestSendTextMessageMutation,
    MatchRequestSendTextMessageMutationVariables
  >(matchRequestSendTextMessageMutation);
  const [tryMatch] = useMutation<
    RequestMatchMutation,
    RequestMatchMutationVariables
  >(requestMatchMutation);
  const [tryMatchAsk] = useMutation<
    RequestMatchAskMutation,
    RequestMatchAskMutationVariables
  >(requestMatchAskMutation);
  function onClaimSendEmail(drafHtml: string) {
    if (sendLoading || !requestDetails || claimSuccess) return;
    if (!emailSubject.trim() || !emailRecipients.length || !drafHtml) {
      addNotification(
        'You need a subject, recipients, and an email body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    console.log('onClaimSendEmail');
    if (requestDetails.isHqApprove) {
      tryMatchAsk({
        variables: {
          emailInput: {
            files: emailFiles,
            html: drafHtml,
            recipientHumans: emailRecipients.map((r) => r.id),
            subject: 'Re: ' + emailSubject,
          },
          requestId,
        },
      })
        .then(({ data: mutationData }) => {
          if (mutationData && mutationData.requestMatchAsk) {
            setClaimSuccess(true);
            setSendLoading(false);
            tokenUtils.removeLocalStorage('request-email-id');
            tokenUtils.removeLocalStorage('request-email-content');
            skipReadCancel = true;
            setShowHqApprove(true);
          } else {
            setSendLoading(false);
            addNotification('An error occured. Please refresh and try again.');
          }
        })
        .catch((err: ApolloError) => {
          setSendLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Matching Ask Error',
          );
          logError(err, {
            component: 'ExpertRequestDetail',
            func: 'tryMatchAskRequest',
          });
        });
    } else {
      tryMatch({
        variables: {
          requestId,
          useEmail: true,
        },
      })
        .then(({ data: mutationData }) => {
          if (mutationData && mutationData.requestMatch) {
            trySendEmailMessage({
              variables: {
                input: {
                  files: emailFiles,
                  hasDelay: 2,
                  html: drafHtml,
                  matchId: mutationData.requestMatch.id,
                  recipientHumans: emailRecipients.map((r) => r.id),
                  subject: 'Re: ' + emailSubject,
                },
              },
            })
              .then(() => {
                setClaimSuccess(true);
                setSendLoading(false);
                tokenUtils.removeLocalStorage('request-email-id');
                tokenUtils.removeLocalStorage('request-email-content');
                skipReadCancel = true;
                history.push('/clients/' + mutationData.requestMatch.id);
              })
              .catch((err: ApolloError) => {
                setClaimSuccess(true);
                setSendLoading(false);
                addNotification(
                  errorUtils.getErrorMessage(err) || 'Email Match Error',
                );
                logError(err, {
                  component: 'ExpertRequestDetail',
                  func: 'sendEmailMessage',
                });
                history.push('/clients/' + mutationData.requestMatch.id);
              });
          } else {
            setSendLoading(false);
            addNotification('An error occured. Please refresh and try again.');
          }
        })
        .catch((err: ApolloError) => {
          setSendLoading(false);
          addNotification(errorUtils.getErrorMessage(err) || 'Matching Error');
          logError(err, {
            component: 'ExpertRequestDetail',
            func: 'tryMatchRequest',
          });
        });
    }
  }
  function onClaimSendText() {
    if (sendLoading || !requestDetails || claimSuccess) return;
    if (!textRecipients.length || !textContent) {
      addNotification(
        'You need recipients and a message body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    console.log('onClaimSendText');
    if (requestDetails.isHqApprove) {
      tryMatchAsk({
        variables: {
          requestId,
          textInput: {
            content: textContent,
            files: textFiles,
            recipientHuman: textRecipients[0].id,
          },
        },
      })
        .then(({ data: mutationData }) => {
          if (mutationData && mutationData.requestMatchAsk) {
            setClaimSuccess(true);
            setSendLoading(false);
            tokenUtils.removeLocalStorage('request-text-id');
            tokenUtils.removeLocalStorage('request-text-content');
            skipReadCancel = true;
            setShowHqApprove(true);
          } else {
            setSendLoading(false);
            addNotification('An error occured. Please refresh and try again.');
          }
        })
        .catch((err: ApolloError) => {
          setSendLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Matching Ask Error',
          );
          logError(err, {
            component: 'ExpertRequestDetail',
            func: 'tryMatchAskRequest',
          });
        });
    } else {
      tryMatch({
        variables: {
          requestId,
          useEmail: true,
        },
      })
        .then(({ data: mutationData }) => {
          if (mutationData && mutationData.requestMatch) {
            trySendTextMessage({
              variables: {
                input: {
                  content: textContent,
                  files: textFiles,
                  matchId: mutationData.requestMatch.id,
                  recipientHuman: textRecipients[0].id,
                },
              },
            })
              .then(() => {
                setClaimSuccess(true);
                setSendLoading(false);
                tokenUtils.removeLocalStorage('request-text-id');
                tokenUtils.removeLocalStorage('request-text-content');
                skipReadCancel = true;
                history.push('/clients/' + mutationData.requestMatch.id);
              })
              .catch((err: ApolloError) => {
                setClaimSuccess(true);
                setSendLoading(false);
                addNotification(
                  errorUtils.getErrorMessage(err) || 'Text Match Error',
                );
                logError(err, {
                  component: 'ExpertRequestDetail',
                  func: 'sendTextMessage',
                });
                history.push('/clients/' + mutationData.requestMatch.id);
              });
          } else {
            setSendLoading(false);
            addNotification('An error occured. Please refresh and try again.');
          }
        })
        .catch((err: ApolloError) => {
          setSendLoading(false);
          addNotification(errorUtils.getErrorMessage(err) || 'Matching Error');
          logError(err, {
            component: 'ExpertRequestDetail',
            func: 'tryMatchRequest',
          });
        });
    }
  }
  function resetReadSession() {
    tryExtendRead(false);
  }
  function takeStepForward(specificMatch?: string) {
    if (step === 'view') {
      if (hasPreviousActivity && requestDetails) {
        setMatchHistoryId(
          specificMatch ||
            matchHistoryId ||
            requestDetails.matchLog[0].matchStr,
        );
      }
      if (hasPreviousActivity) {
        setStep('history');
      } else {
        startClaimScreen();
      }
    } else if (step === 'history') {
      startClaimScreen();
    }
  }
  function takeStepBack() {
    if (step === 'history') {
      setStep('view');
    } else if (step === 'claim') {
      setStep(hasPreviousActivity ? 'history' : 'view');
    }
  }
  const requestCard = requestDetails ? (
    <div
      className={
        'RequestDetailViewCard ' +
        (isSkipping ? ' RequestDetailViewCardSkipping ' : '')
      }
    >
      <div className="RequestDetailViewCardTop">
        {isSkipping ? (
          <div className="RequestDetailViewCardTopWhySkip">
            Why do you want to skip?
          </div>
        ) : (
          <div className="RequestDetailViewCardTopText">New Lead</div>
        )}
        <div className="RequestDetailViewCardTopText RequestDetailViewCardTopTextAlt">
          {(requestDetails.brand && requestDetails.brand.name) || ''}
        </div>
        {!(fullErrorCover || unavailableErrorReason) && (
          <div
            className="RequestDetailViewCardTopSkip"
            onClick={() => setIsSkipping(!isSkipping)}
          >
            {isSkipping ? 'Cancel' : 'Skip'}
          </div>
        )}
      </div>
      <div className="RequestDetailViewCardBody">
        {requestDetails.projectPrefab && requestDetails.projectPrefab.title && (
          <div className="RequestDetailViewCardBodyTitle">
            {requestDetails.projectPrefab.title}{' '}
            {requestDetails.projectPrefab.priceType &&
              requestDetails.projectPrefab.priceType !== 'NONE' &&
              requestDetails.projectPrefab.cents && (
                <span>
                  (
                  {prefabPriceStr(
                    requestDetails.projectPrefab.priceType,
                    requestDetails.projectPrefab.cents,
                  )}
                  )
                </span>
              )}
          </div>
        )}
        {requestDetails.projectPrefab &&
          requestDetails.projectPrefab.estimatedCompletion && (
            <div className="RequestDetailViewCardBodySubtitle">
              Estimated completion:{' '}
              {requestDetails.projectPrefab.estimatedCompletion} day
              {requestDetails.projectPrefab.estimatedCompletion > 1 ? 's' : ''}
            </div>
          )}
        <ReadOnlyEditor
          className="RequestDetailViewCardBodyContent"
          content={
            requestDetails.projectPrefab
              ? requestDetails.projectPrefab.description || ''
              : requestDetails.content || ''
          }
        />
        {!!requestDetails.projectPrefab && !!requestDetails.content && (
          <ReadOnlyEditor
            className="RequestDetailViewCardBodyContent RequestDetailViewCardBodyContentExtra"
            content={requestDetails.content || ''}
          />
        )}
        {!!requestDetails.isMultipleMatch && !requestDetails.isHqApprove && (
          <div
            className="RequestDetailViewCardBodyPro"
            data-tip="This client has requested to meet up to 3 experts upfront. This lead does not count toward your close rate."
          >
            Storetasker Pro
          </div>
        )}
        {!!requestDetails.isHqApprove && (
          <div
            className="RequestDetailViewCardBodyHqApprove"
            data-tip="Storetasker HQ is personally helping this client with their search. This lead does not count toward your close rate."
          >
            HQ Assisted
          </div>
        )}
        {!!requestDetails.isAgency &&
          !requestDetails.isMultipleMatch &&
          !requestDetails.isHqApprove &&
          !requestDetails.isKeyAccount && (
            <div
              className="RequestDetailViewCardBodyAgency"
              data-tip="This lead is from an Agency. Agency clients typically have ongoing work across their portfolio and are more likely to want a retainer."
            >
              Agency
            </div>
          )}
        {!!requestDetails.isKeyAccount &&
          !requestDetails.isAgency &&
          !requestDetails.isMultipleMatch &&
          !requestDetails.isHqApprove && (
            <div
              className="RequestDetailViewCardBodyVIP"
              data-tip="Storetasker HQ has identified this lead as a VIP. Typically a well known, large, or high budget client. Take extra care!"
            >
              VIP
            </div>
          )}
        {!!requestDetails.projectPrefab &&
          !!requestDetails.projectPrefab.internalNote && (
            <div className="RequestDetailViewCardBodyInternal">
              <div className="RequestDetailViewCardBodySubtitle">
                Note from Storetasker HQ:
              </div>
              <ReadOnlyEditor
                className="RequestDetailViewCardBodyContent"
                content={requestDetails.projectPrefab.internalNote || ''}
              />
            </div>
          )}
        {!!(requestDetails.files && requestDetails.files.length) && (
          <div className="RequestDetailViewCardBodyFiles">
            {requestDetails.files.map((f) => (
              <a
                key={f.id}
                className="RequestDetailViewCardBodyFile"
                href={f.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {f.filename}
              </a>
            ))}
          </div>
        )}
        {isSkipping && (
          <div
            className="RequestDetailViewCardBodySkipFlow"
            onClick={blockClickPropagation}
          >
            {SKIP_REASONS.map((s) => (
              <div
                key={s.value}
                onClick={() => trySkipRequest(s.value)}
                className="RequestDetailViewCardBodySkipOption"
              >
                {s.text}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="RequestDetailViewCardFooter">
        {eventDateSummary && (
          <div
            className="RequestDetailViewCardFooterRight"
            data-tip={eventDateFull}
          >
            {eventDateSummary}
          </div>
        )}
      </div>
    </div>
  ) : null;
  const activityContent = requestDetails ? (
    <Fragment>
      {!!hasPreviousActivity && (
        <div className="RequestDetailViewSection">
          <div className="RequestDetailViewSectionTitle">Activity</div>
          {requestDetails.matchLog.map((ml) => (
            <div key={ml.matchStr} className="RequestDetailViewSectionUnclaim">
              {!!requestDetails.currentMatchStr &&
              ml.matchStr === requestDetails.currentMatchStr ? (
                <Fragment>
                  <div className="RequestDetailViewSectionUnclaimTitle">
                    Matched with{' '}
                    {ml.matchedWho && ml.matchedWho.fullName
                      ? ml.matchedWho.fullName
                      : 'an expert'}
                  </div>
                  <div className="RequestDetailViewSectionUnclaimInfo">
                    The client is currently working with{' '}
                    {ml.matchedWho && ml.matchedWho.firstName
                      ? ml.matchedWho.firstName
                      : 'their expert'}{' '}
                    on this project.
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="RequestDetailViewSectionUnclaimTitle">
                    {unclaimedByWho(
                      (ml.matchedWho && ml.matchedWho.fullName) || '',
                      ml.unmatchedHow || '',
                    )}
                  </div>
                  <div className="RequestDetailViewSectionUnclaimInfo">
                    {unclaimedGoal(
                      (ml.matchedWho && ml.matchedWho.firstName) || '',
                      ml.unmatchedHow || '',
                    )}
                  </div>
                  <div className="RequestDetailViewSectionUnclaimQuote">
                    {unclaimedReasonCombined(
                      ml.unmatchedReason || '',
                      ml.unmatchedMoreDetail || '',
                    )}
                  </div>
                  {!(
                    fullErrorCover ||
                    unavailableErrorReason ||
                    readSessionIsAvailable
                  ) && (
                    <div
                      className="RequestDetailViewSectionUnclaimHistoryLink"
                      onClick={() => takeStepForward(ml.matchStr)}
                    >
                      View Previous Conversation
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          ))}
        </div>
      )}
      {hasOtherTags && (
        <div className="RequestDetailViewSection">
          <div className="RequestDetailViewSectionTitle">Details</div>
          {(requestDetails.tagFocus || []).length >= 1 && (
            <div className="RequestDetailViewSectionLocation">
              Focus:{' '}
              {(requestDetails.tagFocus || []).map(tagLabelFocus).join(', ')}
            </div>
          )}
          {(requestDetails.tagLocations || []).length >= 1 && (
            <div className="RequestDetailViewSectionLocation">
              Locations: {(requestDetails.tagLocations || []).join(', ')}
            </div>
          )}
          {!!requestDetails.tagTimeline && (
            <div className="RequestDetailViewSectionLocation">
              Timeline: {tagLabelTimeline(requestDetails.tagTimeline)}
            </div>
          )}
          {!!(requestDetails.tagBudgetMin || requestDetails.tagBudgetMax) && (
            <div className="RequestDetailViewSectionLocation">
              Budget:{' '}
              {tagLabelBudget(
                requestDetails.tagBudgetMin,
                requestDetails.tagBudgetMax,
              )}
            </div>
          )}
        </div>
      )}
      <div className="RequestDetailViewSection">
        <div className="RequestDetailViewSectionTitle">
          Help us tag this project
        </div>
        <div className="ThreadDetailSidebarAdminFormTitleCounter">
          Skills
          <div className="ThreadDetailSidebarAdminFormTitleCounterAmount">
            {requestTagSkills.length} / 3
          </div>
        </div>
        <MultiSelect
          options={SKILL_OPTIONS}
          currValues={requestTagSkills}
          onChange={setRequestTagSkills}
          withTags
          max={3}
        />
        <div className="ThreadDetailSidebarAdminFormTitleCounter">
          Tools
          <div className="ThreadDetailSidebarAdminFormTitleCounterAmount">
            {requestTagTools.length} / 5
          </div>
        </div>
        <MultiSelect
          options={TOOL_OPTIONS}
          currValues={requestTagTools}
          onChange={setRequestTagTools}
          withTags
          max={5}
        />
        {!!didEditTags && (
          <div
            onClick={saveNewTags}
            className={
              'RequestDetailViewSectionAction ' +
              (isTagging ? ' RequestDetailViewSectionActionLoading ' : '')
            }
          >
            save
          </div>
        )}
      </div>
    </Fragment>
  ) : null;
  function getRequestHistoryTop() {
    return !matchLogCurrent || !requestDetails ? null : (
      <div className="RequestDetailHistoryTop">
        <div className="RequestDetailHistoryTitle">
          {unclaimedByWho(
            (matchLogCurrent.matchedWho &&
              matchLogCurrent.matchedWho.fullName) ||
              '',
            matchLogCurrent.unmatchedHow || '',
          )}
        </div>
        <div className="RequestDetailHistorySubtitle">
          {unclaimedGoal(
            (matchLogCurrent.matchedWho &&
              matchLogCurrent.matchedWho.firstName) ||
              '',
            matchLogCurrent.unmatchedHow || '',
          )}
        </div>
        <div className="RequestDetailHistoryDesc">
          {unclaimedReasonCombined(
            matchLogCurrent.unmatchedReason || '',
            matchLogCurrent.unmatchedMoreDetail || '',
          )}
        </div>
        {requestDetails.matchLog.length > 1 && (
          <div className="RequestDetailHistoryOthers">
            {requestDetails.matchLog
              .filter((ml) => ml.matchStr !== matchHistoryId)
              .map((ml) => (
                <div
                  key={ml.matchStr}
                  className="RequestDetailHistoryOther"
                  onClick={() => setMatchHistoryId(ml.matchStr)}
                >
                  Also previously matched with{' '}
                  {(ml.matchedWho && ml.matchedWho.fullName) ||
                    'another expert'}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
  let spendMax = 0;
  if (
    requestDetails &&
    requestDetails.brand &&
    requestDetails.brand.estimateSpend
  ) {
    spendMax = requestDetails.brand.estimateSpend;
  }
  if (
    requestDetails &&
    requestDetails.createdBy &&
    requestDetails.createdBy.estimateSpend
  ) {
    if (requestDetails.createdBy.estimateSpend > spendMax) {
      spendMax = requestDetails.createdBy.estimateSpend;
    }
  }
  return (
    <div className="DashboardModal RequestDetailModal">
      <div className="DashboardModalTopCircle" />
      <div className="DashboardModalBottomCircle" />
      <div className="ExpertRequestDetail">
        <div className="RequestDetailHeader">
          {step === 'view' && (
            <Fragment>
              <Link to="/leads" className="RequestDetailHeaderClose">
                Leads
              </Link>
              {!(fullErrorCover || unavailableErrorReason) && (
                <Fragment>
                  {readSessionIsAvailable ? (
                    <div
                      className="RequestDetailHeaderNext"
                      onClick={() => resetReadSession()}
                    >
                      reset read session?
                    </div>
                  ) : (
                    <div
                      className="RequestDetailHeaderNext"
                      onClick={() => takeStepForward()}
                    >
                      Next
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
          {step === 'history' && (
            <Fragment>
              <div className="RequestDetailHeaderBack" onClick={takeStepBack}>
                Back
              </div>
              {!(fullErrorCover || unavailableErrorReason) && (
                <Fragment>
                  {readSessionIsAvailable ? (
                    <div
                      className="RequestDetailHeaderNext"
                      onClick={() => resetReadSession()}
                    >
                      reset read session?
                    </div>
                  ) : (
                    <div
                      className="RequestDetailHeaderNext"
                      onClick={() => takeStepForward()}
                    >
                      Next
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
          {step === 'claim' && !sendLoading && (
            <div className="RequestDetailHeaderBack" onClick={takeStepBack}>
              Back
            </div>
          )}
          {!!requestDetails &&
            !!timeLeft &&
            !(
              fullErrorCover ||
              unavailableErrorReason ||
              readSessionIsAvailable
            ) && (
              <div
                className={
                  'RequestDetailHeaderClock ' +
                  (step === 'claim' ? ' RequestDetailHeaderClockRight ' : '')
                }
              >
                {timeLeft}
              </div>
            )}
        </div>
        <div className="RequestDetailBody">
          {requestDetails && step === 'view' && (
            <div
              className={
                'RequestDetailView ' +
                (hasPreviousActivity ? ' RequestDetailViewWithActivity ' : '')
              }
            >
              <div className="RequestDetailViewColumn RequestDetailViewColumnRequestTop">
                {requestCard}
              </div>
              <div className="RequestDetailViewColumn RequestDetailViewColumnInfo">
                <div className="RequestDetailViewSection">
                  <div className="RequestDetailViewSectionTitle">Brand</div>
                  <div className="RequestDetailViewSectionHuge">
                    {(requestDetails.brand && requestDetails.brand.name) || ''}
                  </div>
                  {requestDetails.createdBy &&
                    requestDetails.createdBy.createdAt && (
                      <div className="RequestDetailViewSectionLocation">
                        Signed up:{' '}
                        {moment(requestDetails.createdBy.createdAt).format(
                          'MMMM Do, YYYY',
                        )}
                      </div>
                    )}
                  <div className="RequestDetailViewSectionDesc">
                    {(requestDetails.brand &&
                      requestDetails.brand.description) ||
                      ''}
                  </div>
                </div>
                <div className="RequestDetailViewSection">
                  <div className="RequestDetailViewSectionTitle">Website</div>
                  {requestDetails.brand &&
                    requestDetails.brand.shopifyAdminURL && (
                      <div className="RequestDetailViewSectionWebsite">
                        <a
                          className="RequestDetailViewSectionWebsiteLink"
                          href={
                            'http://' + requestDetails.brand.shopifyAdminURL
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {requestDetails.brand.shopifyAdminURL}
                        </a>
                        <div
                          className="RequestDetailViewSectionWebsiteLinkCopy"
                          onClick={() => {
                            copyToClipboard(
                              requestDetails.brand.shopifyAdminURL || '',
                            ).catch(() => {});
                          }}
                        />
                      </div>
                    )}
                  {requestDetails.brand && requestDetails.brand.url && (
                    <div className="RequestDetailViewSectionWebsite">
                      <a
                        className="RequestDetailViewSectionWebsiteLink"
                        href={'http://' + requestDetails.brand.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {requestDetails.brand.url}
                      </a>
                      <div
                        className="RequestDetailViewSectionWebsiteLinkCopy"
                        onClick={() => {
                          copyToClipboard(requestDetails.brand.url || '').catch(
                            () => {},
                          );
                        }}
                      />
                    </div>
                  )}
                  {requestDetails.brand &&
                    !requestDetails.brand.url &&
                    !requestDetails.brand.shopifyAdminURL && (
                      <div className="RequestDetailViewSectionNormal">
                        Unknown
                      </div>
                    )}
                  {!!requestDetails.brand &&
                    !!requestDetails.brand.shopifyStorefrontPassword &&
                    requestDetails.brand.shopifyStorefrontPassword !==
                      'has-secret-password' && (
                      <div className="RequestDetailViewSectionNormal RequestDetailViewSectionNormalHighlight">
                        Storefront Password:{' '}
                        {requestDetails.brand.shopifyStorefrontPassword}
                      </div>
                    )}
                </div>
                {!!requestDetails.createdBy &&
                  !!validDiscountCode(
                    requestDetails.createdBy.discountCode,
                  ) && (
                    <div className="RequestDetailViewSection">
                      <div className="RequestDetailViewSectionTitle">
                        Discount Code
                      </div>
                      <div className="RequestDetailViewSectionNormal">
                        {pitchDiscountCode(
                          requestDetails.createdBy.discountCode,
                        )}{' '}
                        You will still get paid based on the full amount you
                        quote, don&apos;t worry.
                      </div>
                    </div>
                  )}
                <div className="RequestDetailViewSection">
                  <div className="RequestDetailViewSectionTitle">Client</div>
                  <div className="RequestDetailViewSectionNormal">
                    {(requestDetails.createdBy &&
                      requestDetails.createdBy.fullName) ||
                      ''}
                  </div>
                  {requestDetails.createdBy &&
                    requestDetails.createdBy.location && (
                      <div className="RequestDetailViewSectionLocation">
                        <LocationTimestamp
                          locationCity={requestDetails.createdBy.location.city}
                          locationRegionCode={
                            requestDetails.createdBy.location.regionCode
                          }
                          locationTimezone={
                            requestDetails.createdBy.location.timezone
                          }
                        />
                      </div>
                    )}
                  {requestDetails.createdBy &&
                    requestDetails.createdBy.partnerReferral &&
                    requestDetails.createdBy.partnerReferral.partner && (
                      <a
                        className="RequestDetailViewSectionReferral"
                        href={
                          requestDetails.createdBy.partnerReferral.partner
                            .partnerUrl || ''
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Referred by:{' '}
                        {requestDetails.createdBy.partnerReferral.partner
                          .partnerName || ''}
                      </a>
                    )}
                </div>
                {!!spendMax && (
                  <div className="RequestDetailViewSection">
                    <div className="RequestDetailViewSectionTitle">
                      Total Spent
                    </div>
                    <div className="RequestDetailViewSectionNormal">
                      {kpiDollars(spendMax)}
                    </div>
                  </div>
                )}
                {!!requestDetails.brand &&
                  !!(
                    requestDetails.brand.categoryType ||
                    requestDetails.brand.estimateEmployees ||
                    requestDetails.brand.estimateRevenue ||
                    requestDetails.brand.estimateTheme ||
                    requestDetails.brand.instagramFollowers ||
                    requestDetails.brand.isShopifyPlus
                  ) && (
                    <div className="RequestDetailViewSection">
                      <div className="RequestDetailViewSectionTitle">Info</div>
                      {!!requestDetails.brand.isShopifyPlus && (
                        <div className="RequestDetailViewSectionNormal">
                          Plan: Shopify Plus
                        </div>
                      )}
                      {!!requestDetails.brand.categoryType && (
                        <div className="RequestDetailViewSectionLocation">
                          Category: {requestDetails.brand.categoryType}
                        </div>
                      )}
                      {!!requestDetails.brand.estimateEmployees && (
                        <div className="RequestDetailViewSectionLocation">
                          Est. Employees:{' '}
                          {kpiNumber(requestDetails.brand.estimateEmployees)}
                        </div>
                      )}
                      {!!requestDetails.brand.estimateRevenue && (
                        <div className="RequestDetailViewSectionLocation">
                          Est. Monthly Revenue:{' '}
                          {kpiDollars(requestDetails.brand.estimateRevenue)}
                        </div>
                      )}
                      {!!requestDetails.brand.estimateTheme && (
                        <div className="RequestDetailViewSectionLocation">
                          Theme: {requestDetails.brand.estimateTheme}
                        </div>
                      )}
                      {!!requestDetails.brand.instagramFollowers && (
                        <div className="RequestDetailViewSectionLocation">
                          Est. Instagram followers:{' '}
                          {kpiNumber(requestDetails.brand.instagramFollowers)}
                        </div>
                      )}
                    </div>
                  )}
                {activityContent && (
                  <div className="RequestDetailViewActivityUnder">
                    {activityContent}
                  </div>
                )}
                {!!allProjectsForBrand.length && (
                  <div className="RequestDetailViewSection">
                    <div className="RequestDetailViewSectionTitle">
                      Previous Projects
                    </div>
                    <div className="RequestDetailViewSectionProjects">
                      {allProjectsForBrand.map((p) => (
                        <div key={p.id} className="PreviousProjectItem">
                          <div className="PreviousProjectCard">
                            <div className="PreviousProjectCardTitle">
                              {p.quote.title}
                            </div>
                            <div className="PreviousProjectCardSubtitle">
                              <ExpertUserBubble
                                expertId={p.expert.id}
                                expertDetails={p.expert}
                                primary
                              />
                              {p.expert.firstName || ''}
                            </div>
                            <div className="PreviousProjectCardRight">
                              $
                              {formatNumberWithCommas(
                                centsDollarsRounded(p.quote.cents),
                              )}
                            </div>
                            <div
                              className={
                                'PreviousProjectCardStatusLine CardStatusLine' +
                                p.quote.status
                              }
                            />
                            <div className="PreviousProjectCardStatus">
                              {quoteStatusFormatted(p.quote.status)}
                            </div>
                          </div>
                          <div className="PreviousProjectFooter">
                            {moment(p.createdAt).format('MMMM Do, YYYY')}
                          </div>
                        </div>
                      ))}
                      {allProjectsForBrand.length >= ACTIVITY_PAGE_LIMIT &&
                        oldestCreatedProject &&
                        showLoadMoreProjects && (
                          <div
                            className="PreviousProjectItemLoader"
                            onClick={loadMoreProjects}
                          >
                            load more projects
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
              <div className="RequestDetailViewColumn RequestDetailViewColumnRequestMiddle">
                {requestCard}
              </div>
              {activityContent && (
                <div className="RequestDetailViewColumn RequestDetailViewColumnActivity">
                  {activityContent}
                </div>
              )}
            </div>
          )}
          {requestDetails && step === 'history' && matchLogCurrent && (
            <div className="RequestDetailHistory">
              <div className="RequestDetailHistoryColumn">
                {getRequestHistoryTop()}
                <div className="ThreadEvents">
                  {threadEventGroups.map((evGroup) => (
                    <div className="ThreadEventGroup" key={evGroup.group[0].id}>
                      {generateComponentsForGroup(evGroup.group)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {requestDetails && step === 'claim' && (
            <div>
              <div className="RequestDetailClaim">
                <div className="RequestDetailClaimColumn RequestDetailClaimColumnRequestLeft">
                  {requestCard}
                </div>
                <div className="RequestDetailClaimColumn RequestDetailClaimColumnEditor">
                  {contactPreference === 'email' && (
                    <Fragment>
                      <EmailEditor
                        emailTemplates={emailTemplates}
                        expertId={expertId}
                        expertEmailAddress={
                          expertDetails && expertDetails.primaryPublicEmail
                            ? expertDetails.primaryPublicEmail
                            : ''
                        }
                        expertPhoneNumber={
                          expertDetails && expertDetails.primaryPublicPhone
                            ? expertDetails.primaryPublicPhone
                            : ''
                        }
                        expertSchedulePath={
                          expertDetails &&
                          expertDetails.scheduleTimeEnabled &&
                          expertDetails.profilePath
                            ? expertDetails.profilePath
                            : ''
                        }
                        files={emailFiles}
                        fixedRecipients={
                          emailRecipients[0] && [
                            {
                              firstName: emailRecipients[0].firstName || '',
                              id: emailRecipients[0].id || '',
                              lastName: emailRecipients[0].lastName || '',
                            },
                          ]
                        }
                        initContent={emailContent}
                        introContent={emailIntroContent}
                        isClaim
                        isHideFiles={!!requestDetails.isHqApprove}
                        isSending={sendLoading}
                        onSaveDraft={onSaveEmailDraft}
                        onSendEmail={onClaimSendEmail}
                        recipients={[]}
                        subject={emailSubject}
                        status={emailStatus}
                        switchToTextEditor={
                          isTextAllowed
                            ? () => {
                                setupTextEditor(true);
                              }
                            : undefined
                        }
                      />
                      <div className="ThreadEventWrapper ThreadEventWrapperTip">
                        <div className="ThreadEventCardWrapper">
                          <div className="ThreadEventCard ThreadEventCardTip">
                            <div className="ThreadEventCardBody ">
                              <div className="ThreadActionQuoteTipTitle">
                                Pro Tip!
                              </div>
                              <div className="ThreadActionQuoteTipSection">
                                <div className="ThreadActionQuoteTipSectionHeader">
                                  From Project To Client
                                </div>

                                <div className="ThreadActionQuoteTipBody">
                                  Every brand has a roadmap with lots of
                                  problems to solve. Learning more about what’s
                                  next, building trust, and suggest solutions
                                  can turn a $1k client into a $100k client.
                                </div>
                              </div>
                              <div className="ThreadActionQuoteTipSection">
                                <div className="ThreadActionQuoteTipSectionHeader">
                                  A Call Is Worth 10 Emails
                                </div>

                                <div className="ThreadActionQuoteTipBody">
                                  It may be a no-brainer, but jumping on a call
                                  builds trust, let’s you quickly understand the
                                  scope, and significantly increases the chances
                                  of a sale.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                  {contactPreference === 'text' && (
                    <TextEditor
                      content={textContent}
                      expertId={expertId}
                      expertEmailAddress={
                        expertDetails && expertDetails.primaryPublicEmail
                          ? expertDetails.primaryPublicEmail
                          : ''
                      }
                      expertPhoneNumber={
                        expertDetails && expertDetails.primaryPublicPhone
                          ? expertDetails.primaryPublicPhone
                          : ''
                      }
                      expertSchedulePath={
                        expertDetails &&
                        expertDetails.scheduleTimeEnabled &&
                        expertDetails.profilePath
                          ? expertDetails.profilePath
                          : ''
                      }
                      files={textFiles}
                      fixedRecipients={
                        textRecipients[0] && [
                          {
                            firstName: textRecipients[0].firstName || '',
                            id: textRecipients[0].id || '',
                            lastName: textRecipients[0].lastName || '',
                          },
                        ]
                      }
                      isClaim
                      isHideFiles={!!requestDetails.isHqApprove}
                      isSending={sendLoading}
                      onSaveDraft={onSaveTextDraft}
                      onSendText={onClaimSendText}
                      recipients={[]}
                      status={textStatus}
                      switchToEmailEditor={() => {
                        setupEmailEditor(true);
                      }}
                    />
                  )}
                </div>
                <div className="RequestDetailClaimColumn RequestDetailClaimColumnRequestUnder">
                  {requestCard}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {fullErrorCover ? (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <Link to="/leads" className="DashboardErrorCoverNav">
              back
            </Link>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      ) : (
        <Fragment>
          {!!unavailableErrorReason && (
            <div className="DashboardErrorCover DashboardErrorCoverSee">
              <div className="DashboardErrorCoverOver" />
              <div className="DashboardErrorCoverTopper">
                <div className="DashboardErrorCoverTopperText">
                  {unavailableErrorReason}
                </div>
                <Link to="/leads" className="DashboardErrorCoverTopperBtn">
                  view more leads
                </Link>
              </div>
            </div>
          )}
          {!!showHqApprove && (
            <div className="DashboardErrorCover DashboardErrorCoverSee">
              <div className="DashboardErrorCoverOver" />
              <div className="DashboardErrorCoverTopper">
                <div className="DashboardErrorCoverTopperTitle">Thanks!</div>
                <div className="DashboardErrorCoverTopperText">
                  Storetasker HQ is personally helping this client with their
                  search.
                  <br />
                  <br />
                  How it works:
                  <br />
                  * This lead does not count toward your close rate.
                  <br />* We&apos;ll notify you, typically within 1-2 days, if
                  they&apos;d like to connect.
                </div>
                <Link to="/leads" className="DashboardErrorCoverTopperBtn">
                  back to the queue
                </Link>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

ExpertRequestDetail.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  expertDetails: PropTypes.object,
  expertId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

ExpertRequestDetail.defaultProps = {
  expertDetails: null,
};

export default ExpertRequestDetail;
