/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
// import moment from 'moment-timezone';
import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQuery, ApolloError, gql, useLazyQuery } from '@apollo/client';
import { ClientWithOnReconnected } from '../../utils/apollo';
import {
  AuthHumanQuery,
  BrandForHumansQuery,
  RequestActiveForBrandsQuery,
  MatchActiveForBrandsQuery,
  ExpertDetailsFullHumanQuery,
  ExpertDetailsFullHumanQueryVariables,
  RequestDetailsHumanExpertQuery,
  RequestDetailsHumanExpertQueryVariables,
  ProjectActiveExpertForBrandsQuery,
  ProjectActiveExpertForBrandsQueryVariables,
  ProjectPastPaginateExpertBrandsQuery,
  ProjectPastPaginateExpertBrandsQueryVariables,
} from '../../gql/graphql';
import envUtils from '../../utils/env';
import {
  ExpertDetailHuman,
  ProjectSummary,
  RequestSummary,
} from '../../utils/gql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import errorUtils from '../../utils/error';
import logError from '../../utils/airbrake';
import {
  expertFocusType,
  formatValidPhoneNumber,
  formatNumberWithCommas,
  centsDollarsRounded,
  // projectNotificationStatus,
  quoteStatusFormattedHuman,
  tagLabelSkill,
  tagLabelTool,
} from '../../utils/format';
import LocationTimestamp from '../feature/LocationTimestamp';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import '../../styles/page/HumanExpertDetail.scss';

const expertDetailsHumanQuery = gql`
  query ExpertDetailsFullHuman($expertId: ID!) {
    expertDetails(expertId: $expertId) {
      ...ExpertDetailHuman
    }
  }
  ${ExpertDetailHuman}
`;

const projectActiveExpertForBrandsQuery = gql`
  query ProjectActiveExpertForBrands($brandIds: [ID!]!, $expertId: ID) {
    projectActiveForBrands(brandIds: $brandIds, expertId: $expertId) {
      ...ProjectSummary
    }
  }
  ${ProjectSummary}
`;

const projectPastPaginateExpertBrandsQuery = gql`
  query ProjectPastPaginateExpertBrands(
    $brandIds: [ID!]!
    $direction: String!
    $limit: Int!
    $fromDate: Date
    $expertId: ID
  ) {
    projectPastPaginateBrands(
      brandIds: $brandIds
      direction: $direction
      limit: $limit
      fromDate: $fromDate
      expertId: $expertId
    ) {
      ...ProjectSummary
    }
  }
  ${ProjectSummary}
`;

const requestDetailsHumanExpertQuery = gql`
  query RequestDetailsHumanExpert($requestId: ID!) {
    requestDetails(requestId: $requestId) {
      ...RequestSummary
    }
  }
  ${RequestSummary}
`;

interface HumanExpertDetailProps {
  activeMatches: MatchActiveForBrandsQuery['matchActiveForBrands'];
  activeRequests: RequestActiveForBrandsQuery['requestActiveForBrands'];
  brands: BrandForHumansQuery['brandForHumans'];
  expertId: string;
  human: Extract<
    Exclude<AuthHumanQuery['auth'], null | undefined>['user'],
    { __typename?: 'Human' | undefined }
  >;
  socketClient: ClientWithOnReconnected;
}

const PAGE_LIMIT = 4;

const HumanExpertDetail = ({
  activeMatches,
  activeRequests,
  brands,
  expertId,
  // human,
  socketClient,
}: HumanExpertDetailProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const [primaryPanel, setPrimaryPanel] = useState('Details');
  const secondaryPanels = ['Details'].filter((p) => p !== primaryPanel);
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
  }
  const [pastLoaded, setPastLoaded] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const {
    loading: loadingExpert,
    error: errorExpert,
    data: dataExpert,
    refetch: refetchExpert,
  } = useQuery<
    ExpertDetailsFullHumanQuery,
    ExpertDetailsFullHumanQueryVariables
  >(expertDetailsHumanQuery, {
    returnPartialData: true,
    variables: {
      expertId,
    },
  });
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('HumanExpertDetail socketClient onReconnected');
      refetchExpert().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchExpert]);
  const expertDetails =
    dataExpert && dataExpert.expertDetails && dataExpert.expertDetails.id
      ? dataExpert.expertDetails
      : undefined;
  const matchedWithExpert = activeMatches.find((m) => m.expertStr === expertId);
  const skillsAndTools = expertDetails
    ? (expertDetails.tagSkills || [])
        .map(tagLabelSkill)
        .concat((expertDetails.tagTools || []).map(tagLabelTool))
    : [];
  const {
    loading: loadingProjectsActive,
    error: errorProjectsActive,
    data: dataProjectsActive,
  } = useQuery<
    ProjectActiveExpertForBrandsQuery,
    ProjectActiveExpertForBrandsQueryVariables
  >(projectActiveExpertForBrandsQuery, {
    returnPartialData: true,
    skip: !brands.length,
    variables: {
      brandIds: brands.map((b) => b.id),
      expertId,
    },
  });
  const [
    getPastProjects,
    {
      called: calledProjectPast,
      data: dataProjectPast,
      error: errorProjectPast,
      loading: loadingProjectPast,
      fetchMore: fetchMoreProjectPast,
    },
  ] = useLazyQuery<
    ProjectPastPaginateExpertBrandsQuery,
    ProjectPastPaginateExpertBrandsQueryVariables
  >(projectPastPaginateExpertBrandsQuery, {
    returnPartialData: true,
    variables: {
      brandIds: brands.map((b) => b.id),
      direction: 'BACKWARD',
      expertId,
      limit: PAGE_LIMIT,
    },
  });
  const allProjectsForBrands = _.uniqBy(
    [
      ...((dataProjectsActive && dataProjectsActive.projectActiveForBrands) ||
        []),
      ...((calledProjectPast &&
        dataProjectPast &&
        dataProjectPast.projectPastPaginateBrands) ||
        []),
    ].filter(
      (p) =>
        p.quote &&
        p.expertStr &&
        p.expertStr === expertId &&
        p.brandStr &&
        brands.find((b) => b.id === p.brandStr),
    ),
    'id',
  );
  const activeProjectsForBrands = allProjectsForBrands
    .filter((p) => p.isActive)
    .sort(
      (a, b) =>
        (b.quote.createdAt || b.createdAt || 0) -
        (a.quote.createdAt || a.createdAt || 0),
    );
  const pastProjectsForBrands = allProjectsForBrands
    .filter((p) => !p.isActive)
    .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));
  const activeRequestWithExpert = activeRequests.find(
    (r) =>
      matchedWithExpert &&
      r.matchLog &&
      r.matchLog.find(
        (ml) => !ml.unmatchedAt && ml.matchStr === matchedWithExpert.id,
      ),
  );
  const activeRequestWithExpertHasProject =
    activeRequestWithExpert &&
    activeRequestWithExpert.matchLog &&
    activeProjectsForBrands.find((p) =>
      activeRequestWithExpert.matchLog.find(
        (ml) => !ml.unmatchedAt && ml.matchStr === p.matchStr,
      ),
    )
      ? activeRequestWithExpert
      : undefined;
  const {
    loading: loadingRequest,
    error: errorRequest,
    data: dataRequest,
  } = useQuery<
    RequestDetailsHumanExpertQuery,
    RequestDetailsHumanExpertQueryVariables
  >(requestDetailsHumanExpertQuery, {
    returnPartialData: true,
    skip: !activeRequestWithExpert || !!activeRequestWithExpertHasProject,
    variables: {
      requestId: activeRequestWithExpert ? activeRequestWithExpert.id : '',
    },
  });
  const requestDetails =
    dataRequest && dataRequest.requestDetails && dataRequest.requestDetails.id
      ? dataRequest.requestDetails
      : undefined;
  function loadThePastInit() {
    getPastProjects().catch((err: ApolloError) => {
      logError(err, {
        component: 'HumanExpertDetail',
        func: 'getPastProjects',
      });
    });
    setPastLoaded(true);
  }
  function loadMoreProjects() {
    if (fetchMoreProjectPast) {
      fetchMoreProjectPast({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || !fetchMoreResult.projectPastPaginateBrands)
            return prev;
          if (fetchMoreResult.projectPastPaginateBrands.length < PAGE_LIMIT) {
            setShowLoadMore(false);
          }
          return {
            projectPastPaginateBrands: (
              prev.projectPastPaginateBrands || []
            ).concat(fetchMoreResult.projectPastPaginateBrands),
          };
        },
        variables: {
          direction: 'BACKWARD',
          fromDate:
            pastProjectsForBrands[pastProjectsForBrands.length - 1].createdAt,
          limit: PAGE_LIMIT,
        },
      }).catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Past Projects Error',
        );
        logError(err, {
          component: 'Homescreen',
          func: 'loadMoreProjects',
        });
      });
    }
  }
  function projectSidebarCard(
    p: ProjectActiveExpertForBrandsQuery['projectActiveForBrands'][0],
  ) {
    return (
      <div key={p.id} className="ThreadDetailSidebarUpNextItem">
        <Link
          to={'/projects/' + p.id}
          className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardProject"
        >
          <div className="ThreadDetailSidebarUpNextCardHeader">
            <div className="ThreadDetailSidebarUpNextCardType">Project</div>
            <div className="ThreadDetailSidebarUpNextCardTitle">
              {p.quote.title}
            </div>
            <div className="ThreadDetailSidebarUpNextCardSubtitle">
              ${formatNumberWithCommas(centsDollarsRounded(p.quote.cents))}
            </div>
            <div
              className={
                'ThreadDetailSidebarUpNextCardStatusLine CardStatusLine' +
                p.quote.status
              }
            />
            <div className="ThreadDetailSidebarUpNextCardStatus">
              {quoteStatusFormattedHuman(p.quote.status)}
            </div>
          </div>
        </Link>
      </div>
    );
  }
  if (
    loadingExpert ||
    loadingProjectsActive ||
    loadingProjectPast ||
    loadingRequest ||
    errorRequest
  ) {
    // ignore these
  }

  return (
    <div className="DashboardModal ClientDetailModal HumanExpertDetailModal">
      <div className="DashboardModalTopCircle" />
      <div className="DashboardModalBottomCircle" />
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <Link to="/experts" className="ThreadDetailMainHeaderBack">
              Experts
            </Link>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody">
            {!!expertDetails && (
              <div className="ThreadDetailMainBodyFocus ThreadDetailMainBodyFocusDual">
                <div className="ThreadDetailMainBodyFocusDualLeft">
                  {!!expertDetails.photo && (
                    <div
                      className="ExpertDetailPhoto"
                      style={{
                        backgroundImage: 'url(' + expertDetails.photo + ')',
                      }}
                    >
                      <div className="ExpertDetailPhotoFooter">
                        <div className="ExpertDetailPhotoTitle">
                          {expertDetails.firstName || 'Expert'}
                        </div>
                        <div className="ExpertDetailPhotoSubTitle">
                          {expertFocusType(expertDetails.tagFocus) ||
                            'Shopify Expert'}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="ThreadDetailMainBodyFocusDualRight">
                  <div className="ThreadDetailMainBodyFocusTitle">
                    {(expertDetails.firstName || '') +
                      ' ' +
                      (expertDetails.lastName || '')}
                  </div>
                  {!!expertDetails.profileBio && (
                    <div className="ExpertDetailSection">
                      <div className="ExpertDetailSectionTitle">About</div>
                      <div className="ExpertDetailDesc">
                        {expertDetails.profileBio}
                      </div>
                    </div>
                  )}
                  {!!(
                    expertDetails.locationStr || expertDetails.locationTimezone
                  ) && (
                    <div className="ExpertDetailSection">
                      <div className="ExpertDetailSectionTitle">Location</div>
                      <div className="ExpertDetailDesc">
                        <LocationTimestamp
                          locationStr={expertDetails.locationStr}
                          locationTimezone={expertDetails.locationTimezone}
                        />
                      </div>
                    </div>
                  )}
                  {!!(
                    expertDetails.primaryPublicEmail ||
                    expertDetails.primaryPublicPhone
                  ) && (
                    <div className="ExpertDetailSection">
                      <div className="ExpertDetailSectionTitle">Contact</div>
                      {!!expertDetails.primaryPublicEmail && (
                        <div className="ExpertDetailContactLineWrap">
                          <a
                            className="ExpertDetailContactLine"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={'mailto:' + expertDetails.primaryPublicEmail}
                          >
                            {expertDetails.primaryPublicEmail || ''}
                          </a>
                        </div>
                      )}
                      {!!expertDetails.primaryPublicPhone && (
                        <div className="ExpertDetailContactLineWrap">
                          <a
                            className="ExpertDetailContactLine"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={'sms:' + expertDetails.primaryPublicPhone}
                          >
                            {formatValidPhoneNumber(
                              expertDetails.primaryPublicPhone,
                              true,
                            ) ||
                              expertDetails.primaryPublicPhone ||
                              ''}
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                  {!!skillsAndTools.length && (
                    <div className="ExpertDetailSection">
                      <div className="ExpertDetailSectionTitle">Skills</div>
                      <div className="ExpertDetailSkills">
                        {skillsAndTools.map((f) => (
                          <div key={f} className="ExpertDetailSkill">
                            {f}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {!!activeRequestWithExpert &&
                    !!requestDetails &&
                    !activeRequestWithExpertHasProject && (
                      <div className="ExpertDetailSection">
                        <div className="ExpertDetailSectionTitle">
                          Active project
                        </div>
                        <div>
                          <Link
                            className="ProjectsScreenRequest"
                            to={'/requests/' + requestDetails.id}
                          >
                            <div className="ProjectsScreenRequestHeader">
                              <div className="ProjectsScreenRequestHeaderTitle">
                                Matched with {expertDetails.firstName || ''}
                              </div>
                              <div className="ProjectsScreenRequestStatus ProjectsScreenRequestStatusMatched">
                                matched
                              </div>
                            </div>
                            <div className="ProjectsScreenRequestBody">
                              <ReadOnlyEditor
                                className="ProjectsScreenRequestText"
                                content={
                                  requestDetails.projectPrefab
                                    ? requestDetails.projectPrefab
                                        .description || ''
                                    : requestDetails.content || ''
                                }
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                  {!matchedWithExpert && (
                    <div className="ExpertDetailSection">
                      <div className="ExpertDetailSectionTitle">
                        Start a new project
                      </div>
                      {activeRequests.length >= 3 ? (
                        <div className="ExpertDetailInfo">
                          <div className="ExpertDetailInfoText">
                            {activeRequests[0].currentMatchStr
                              ? 'You are already working with another expert on a project.'
                              : 'You already have an active project that is waiting to get matched.'}
                          </div>
                          <Link
                            to={'/requests/' + activeRequests[0].id}
                            className="ExpertDetailInfoLink"
                          >
                            view details
                          </Link>
                        </div>
                      ) : (
                        <Link
                          className="ExpertDetailAction"
                          to={'/new?expert=' + expertDetails.id}
                        >
                          Work with {expertDetails.firstName || 'expert'}
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div
          className={
            'ThreadDetailSidebar ' +
            (!secondaryPanels.length ? ' ThreadDetailSidebarSolo ' : '')
          }
        >
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            {!!expertDetails && (
              <div className="ThreadDetailSidebarBodyPanels">
                {primaryPanel === 'Details' && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelUpNext">
                    {!!matchedWithExpert && (
                      <div className="ThreadDetailSidebarHumanSection ThreadDetailSidebarHumanSectionPadding">
                        <div className="ThreadDetailSidebarHumanSectionTitle">
                          Chat History
                        </div>
                        <div className="ThreadDetailSidebarHumanSectionSubTitle">
                          View your past email and text comms
                        </div>
                        <Link
                          className="ThreadDetailSidebarHumanSectionBtn"
                          to={'/match/' + matchedWithExpert.id}
                        >
                          view chat history
                        </Link>
                      </div>
                    )}
                    {(!activeRequests[0] || activeRequestWithExpert) &&
                      !!expertDetails.scheduleTimeEnabled &&
                      !!expertDetails.profilePath && (
                        <div className="ThreadDetailSidebarHumanSection ThreadDetailSidebarHumanSectionPadding">
                          <div className="ThreadDetailSidebarHumanSectionTitle">
                            Schedule A Meeting
                          </div>
                          <div className="ThreadDetailSidebarHumanSectionSubTitle">
                            Find a time that suits you both for a call
                          </div>
                          <a
                            className="ThreadDetailSidebarHumanSectionBtn"
                            href={envUtils.pick(
                              `https://meet.storetasker.com/${expertDetails.profilePath}`,
                              `https://www-dev.storetasker.com/experts/${expertDetails.profilePath}?meet=true`,
                              `http://localhost:3000/experts/${expertDetails.profilePath}?meet=true`,
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            schedule a meeting
                          </a>
                        </div>
                      )}
                    <div className="ThreadDetailSidebarHumanSection">
                      <div className="ThreadDetailSidebarHumanSectionTitle ThreadDetailSidebarHumanSectionTitlePadding">
                        Active Projects
                      </div>
                      {!activeProjectsForBrands.length && (
                        <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                          <div className="ThreadDetailSidebarBodyPanelEmpty ThreadDetailSidebarBodyPanelEmptyHuman">
                            {matchedWithExpert ? (
                              <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                                {errorProjectsActive
                                  ? errorUtils.getErrorMessage(
                                      errorProjectsActive,
                                    )
                                  : 'Active projects with this expert will show up here.'}
                              </div>
                            ) : (
                              <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                                Once you start working with this expert, active
                                projects will show up here.
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {activeProjectsForBrands.map(projectSidebarCard)}
                      {!!matchedWithExpert && !pastLoaded && (
                        <div
                          className="ThreadDetailSidebarUpNextExpandPast"
                          onClick={loadThePastInit}
                        >
                          View past
                        </div>
                      )}
                    </div>
                    {!!pastLoaded && (
                      <div className="ThreadDetailSidebarHumanSection">
                        <div className="ThreadDetailSidebarHumanSectionTitle ThreadDetailSidebarHumanSectionTitlePadding">
                          Past Projects
                        </div>
                        {!pastProjectsForBrands.length && (
                          <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                            <div className="ThreadDetailSidebarBodyPanelEmpty ThreadDetailSidebarBodyPanelEmptyHuman">
                              <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                                {errorProjectPast
                                  ? errorUtils.getErrorMessage(errorProjectPast)
                                  : 'Past projects with this expert will show up here.'}
                              </div>
                            </div>
                          </div>
                        )}
                        {pastProjectsForBrands.map(projectSidebarCard)}
                        {pastProjectsForBrands.length >= PAGE_LIMIT &&
                          showLoadMore && (
                            <div
                              className="AdminListItemLoader"
                              onClick={loadMoreProjects}
                            >
                              load more
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!errorExpert && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <Link to="/experts" className="DashboardErrorCoverNav">
              back
            </Link>
            <div className="DashboardErrorCoverContent">
              An error occured while trying to load this expert. Please try
              again.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

HumanExpertDetail.propTypes = {
  activeMatches: PropTypes.array.isRequired,
  activeRequests: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  expertId: PropTypes.string.isRequired,
  human: PropTypes.object.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default HumanExpertDetail;
