/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import React, { useState, useContext, Fragment, useEffect } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { ClientWithOnReconnected } from '../../utils/apollo';
import {
  useMutation,
  useQuery,
  useLazyQuery,
  ApolloError,
  gql,
} from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import envUtils from '../../utils/env';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import PhoneNumberInput from '../feature/PhoneNumberInput';
import LocationTimestamp from '../feature/LocationTimestamp';
import UserNoteEditor from '../feature/UserNoteEditor';
import {
  HumanDetailHq,
  HumanSummary,
  BrandDetail,
  UserNoteDetail,
} from '../../utils/gql';
import {
  HumanDetailsAdminQuery,
  HumanDetailsAdminQueryVariables,
  BrandForHumansAdminQuery,
  BrandForHumansAdminQueryVariables,
  HumanEditByAdminMutation,
  HumanEditByAdminMutationVariables,
  UserNoteHumanAdminQuery,
  UserNoteHumanAdminQueryVariables,
  UserNoteHumanEditAdminMutation,
  UserNoteHumanEditAdminMutationVariables,
  UserNoteHumanUpdatedAdminSubscription,
  UserNoteHumanUpdatedAdminSubscriptionVariables,
  AdminGenerateTokenHumanQuery,
  AdminGenerateTokenHumanQueryVariables,
  HumanProPaymentsQuery,
  HumanProPaymentsQueryVariables,
  HumanResetPaymentMethodMutation,
  HumanResetPaymentMethodMutationVariables,
  HumanSubscriptionProAdminMutation,
  HumanSubscriptionProAdminMutationVariables,
  HumanPartnerReferralMutation,
  HumanPartnerReferralMutationVariables,
  BrandCreateForHumanByAdminMutation,
  BrandCreateForHumanByAdminMutationVariables,
  HumanMergeByAdminMutation,
  HumanMergeByAdminMutationVariables,
  PartnerSearchForAdminHumanMutation,
  PartnerSearchForAdminHumanMutationVariables,
  HumanSearchForAdminHumanMutation,
  HumanSearchForAdminHumanMutationVariables,
} from '../../gql/graphql';
import {
  formatValidPhoneNumber,
  centsDollarsRounded,
  validClientFee,
  validDiscountCode,
  explainClientFee,
  explainDiscountCode,
} from '../../utils/format';

const humanDetailsAdminQuery = gql`
  query HumanDetailsAdmin($humanId: ID!) {
    humanDetails(humanId: $humanId) {
      ...HumanDetailHq
    }
  }
  ${HumanDetailHq}
`;

const adminGenerateTokenHumanQuery = gql`
  query AdminGenerateTokenHuman($forId: ID!, $userType: String!) {
    adminGenerateToken(forId: $forId, userType: $userType)
  }
`;

const humanProPaymentsQuery = gql`
  query HumanProPayments($humanId: ID!) {
    humanProPayments(humanId: $humanId) {
      id
      createdAt
      cents
      humanStr
      invoiceId
    }
  }
`;

const userNoteAdminQuery = gql`
  query UserNoteHumanAdmin($matchId: ID, $supportChannelId: ID, $humanId: ID) {
    userNoteForTarget(
      matchId: $matchId
      supportChannelId: $supportChannelId
      humanId: $humanId
    ) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const userNoteEditAdminMutation = gql`
  mutation UserNoteHumanEditAdmin(
    $body: String!
    $userNoteId: ID
    $matchId: ID
    $supportChannelId: ID
    $humanId: ID
  ) {
    userNoteEdit(
      body: $body
      userNoteId: $userNoteId
      matchId: $matchId
      supportChannelId: $supportChannelId
      humanId: $humanId
    ) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const adminUserNoteUpdatedSubscription = gql`
  subscription UserNoteHumanUpdatedAdmin($targetId: ID!) {
    userNoteUpdated(targetId: $targetId) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const brandForHumansAdminQuery = gql`
  query BrandForHumansAdmin($humanIds: [ID!]!) {
    brandForHumans(humanIds: $humanIds) {
      id
      name
      url
      shopifyAdminURL
    }
  }
`;

const humanEditByAdminMutation = gql`
  mutation HumanEditByAdmin(
    $humanId: ID!
    $clientFee: String
    $discountCode: String
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $secondaryEmails: [String!]!
    $shouldExcludeFromKpiReports: Boolean
    $isDelinquent: Boolean
    $isAgency: String
  ) {
    humanEditByAdmin(
      humanId: $humanId
      clientFee: $clientFee
      discountCode: $discountCode
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      secondaryEmails: $secondaryEmails
      shouldExcludeFromKpiReports: $shouldExcludeFromKpiReports
      isDelinquent: $isDelinquent
      isAgency: $isAgency
    ) {
      ...HumanDetailHq
    }
  }
  ${HumanDetailHq}
`;

const humanPartnerReferralMutation = gql`
  mutation HumanPartnerReferral(
    $humanId: ID!
    $partnerId: ID
    $isSoft: Boolean
    $medium: String
    $ticket: String
    $campaign: String
  ) {
    humanPartnerReferral(
      humanId: $humanId
      partnerId: $partnerId
      isSoft: $isSoft
      medium: $medium
      ticket: $ticket
      campaign: $campaign
    ) {
      ...HumanDetailHq
    }
  }
  ${HumanDetailHq}
`;

const humanResetPaymentMethodMutation = gql`
  mutation HumanResetPaymentMethod($humanId: ID!) {
    humanResetPaymentMethod(humanId: $humanId) {
      ...HumanDetailHq
    }
  }
  ${HumanDetailHq}
`;

const humanSubscriptionProAdminMutation = gql`
  mutation HumanSubscriptionProAdmin($humanId: ID!, $isCancel: Boolean!) {
    humanSubscriptionProAdmin(humanId: $humanId, isCancel: $isCancel) {
      ...HumanDetailHq
    }
  }
  ${HumanDetailHq}
`;

const partnerSearchForAdminHumanMutation = gql`
  mutation PartnerSearchForAdminHuman($searchQuery: String!) {
    partnerSearchForAdmin(searchQuery: $searchQuery) {
      id
      partnerName
      trackingId
    }
  }
`;

const humanSearchForAdminHumanMutation = gql`
  mutation HumanSearchForAdminHuman($searchQuery: String!) {
    humanSearchForAdmin(searchQuery: $searchQuery) {
      ...HumanSummary
    }
  }
  ${HumanSummary}
`;

const brandCreateForHumanByAdminMutation = gql`
  mutation BrandCreateForHumanByAdmin(
    $name: String!
    $url: String!
    $humanId: ID!
  ) {
    brandCreateForHumanByAdmin(name: $name, url: $url, humanId: $humanId) {
      ...BrandDetail
    }
  }
  ${BrandDetail}
`;

const humanMergeByAdminMutation = gql`
  mutation HumanMergeByAdmin($parentHumanId: ID!, $childHumanId: ID!) {
    humanMergeByAdmin(
      parentHumanId: $parentHumanId
      childHumanId: $childHumanId
    ) {
      ...HumanDetailHq
    }
  }
  ${HumanDetailHq}
`;

interface AdminHumanDetailProps {
  humanId: string;
  socketClient: ClientWithOnReconnected;
}

const AdminHumanDetail = ({ humanId, socketClient }: AdminHumanDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const [actionLoading, setActionLoading] = useState(false);
  const [proActionLoading, setProActionLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [editHumanClientFee, setEditHumanClientFee] = useState('');
  const [editHumanDiscountCode, setEditHumanDiscountCode] = useState('');
  const [editHumanFirst, setEditHumanFirst] = useState('');
  const [editHumanLast, setEditHumanLast] = useState('');
  const [editHumanExcludeKpis, setEditHumanExcludeKpis] = useState('false');
  const [editHumanDelinquent, setEditHumanDelinquent] = useState('false');
  const [editHumanIsAgency, setEditHumanIsAgency] = useState('');
  const [editHumanPhone, setEditHumanPhone] = useState('');
  const [editHumanPhoneValid, setEditHumanPhoneValid] = useState(true);
  const [editHumanEmail, setEditHumanEmail] = useState('');
  const [editHumanSecondaryEmails, setEditHumanSecondaryEmails] = useState(
    [] as string[],
  );
  const [newBrandName, setNewBrandName] = useState('');
  const [newBrandUrl, setNewBrandUrl] = useState('');
  const [partnerMedium, setPartnerMedium] = useState('email');
  const [partnerSoft, setPartnerSoft] = useState('false');
  const [partnerTicket, setPartnerTicket] = useState('');
  const [partnerCampaign, setPartnerCampaign] = useState('');
  const [primaryPanel, setPrimaryPanel] = useState('About');
  const [modalLoading, setModalLoading] = useState(false);
  const [searchInputHumans, setSearchInputHumans] = useState('');
  const [searchResultsHumans, setSearchResultsHumans] = useState<
    HumanSearchForAdminHumanMutation['humanSearchForAdmin'] | null
  >(null);
  const [mergeSpecific, setMergeSpecific] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState<
    PartnerSearchForAdminHumanMutation['partnerSearchForAdmin'] | null
  >(null);
  const {
    data: dataHuman,
    error: errorHuman,
    loading: loadingHuman,
    refetch: refetchHuman,
  } = useQuery<HumanDetailsAdminQuery, HumanDetailsAdminQueryVariables>(
    humanDetailsAdminQuery,
    {
      returnPartialData: true,
      variables: {
        humanId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorHuman) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorHuman) || 'Could not load human details';
  }
  const humanDetails =
    dataHuman && dataHuman.humanDetails && dataHuman.humanDetails.id
      ? dataHuman.humanDetails
      : undefined;
  if (humanDetails && humanDetails.migratedToHumanStr) {
    fullErrorCover =
      'This human was merged into: ' + humanDetails.migratedToHumanStr;
  }
  const {
    data: dataToken,
    error: errorToken,
    loading: loadingToken,
  } = useQuery<
    AdminGenerateTokenHumanQuery,
    AdminGenerateTokenHumanQueryVariables
  >(adminGenerateTokenHumanQuery, {
    variables: {
      forId: humanId,
      userType: 'human',
    },
  });
  const {
    data: dataBrands,
    error: errorBrands,
    loading: loadingBrands,
    refetch: refetchBrands,
  } = useQuery<BrandForHumansAdminQuery, BrandForHumansAdminQueryVariables>(
    brandForHumansAdminQuery,
    {
      returnPartialData: true,
      variables: {
        humanIds: [humanId],
      },
    },
  );
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminHumanDetail socketClient onReconnected');
      refetchHuman().catch(() => {});
      refetchBrands().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchHuman, refetchBrands]);
  const brandsForHuman = (dataBrands && dataBrands.brandForHumans) || [];
  const {
    data: dataProPayments,
    error: errorProPayments,
    loading: loadingProPayments,
  } = useQuery<HumanProPaymentsQuery, HumanProPaymentsQueryVariables>(
    humanProPaymentsQuery,
    {
      variables: {
        humanId,
      },
    },
  );
  const allProPayments = _.uniqBy(
    [...((dataProPayments && dataProPayments.humanProPayments) || [])].filter(
      (p) => p.humanStr === humanId,
    ),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  const [
    getUserNotes,
    {
      called: calledUserNotes,
      data: dataUserNotes,
      error: errorUserNotes,
      loading: loadingUserNotes,
      subscribeToMore: subscribeToMoreUserNotes,
    },
  ] = useLazyQuery<UserNoteHumanAdminQuery, UserNoteHumanAdminQueryVariables>(
    userNoteAdminQuery,
    {
      variables: {
        humanId,
      },
    },
  );
  const allUserNotes = (dataUserNotes && dataUserNotes.userNoteForTarget) || [];
  useEffect(() => {
    let unsub: () => void;
    if (humanId && calledUserNotes && subscribeToMoreUserNotes) {
      unsub = subscribeToMoreUserNotes<
        UserNoteHumanUpdatedAdminSubscription,
        UserNoteHumanUpdatedAdminSubscriptionVariables
      >({
        document: adminUserNoteUpdatedSubscription,
        onError: (err) => {
          addNotification(
            'Error loading notes. Please refresh and try again: ' + err.message,
          );
          console.error('onError: subscribeToMoreUserNotes', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { userNoteUpdated },
            },
          },
        ) => {
          console.log('userNoteUpdated', prev, userNoteUpdated);
          if (!userNoteUpdated || !userNoteUpdated.humanStr) {
            return prev;
          }
          if (humanId !== userNoteUpdated.humanStr) {
            return prev;
          }
          const updatingIndex = (prev.userNoteForTarget || []).findIndex(
            (un) => un.id === userNoteUpdated.id,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              userNoteForTarget: prev.userNoteForTarget
                .slice(0, updatingIndex)
                .concat({
                  ...prev.userNoteForTarget[updatingIndex],
                  ...userNoteUpdated,
                })
                .concat(prev.userNoteForTarget.slice(updatingIndex + 1)),
            };
          }
          return {
            userNoteForTarget: (prev.userNoteForTarget || []).concat(
              userNoteUpdated,
            ),
          };
        },
        variables: {
          targetId: humanId,
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [subscribeToMoreUserNotes, humanId, calledUserNotes, addNotification]);
  const [tryEditNote] = useMutation<
    UserNoteHumanEditAdminMutation,
    UserNoteHumanEditAdminMutationVariables
  >(userNoteEditAdminMutation);
  function onSaveNote(body: string, userNoteId?: string) {
    if (noteLoading || !humanId) return;
    setNoteLoading(true);
    tryEditNote({
      variables: {
        body,
        humanId,
        userNoteId,
      },
    })
      .then(() => {
        setNoteLoading(false);
      })
      .catch((err: ApolloError) => {
        setNoteLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Edit Note Error');
        logError(err, {
          component: 'AdminHumanDetail',
          func: 'onSaveNote',
        });
      });
  }
  const [tryBrandCreate] = useMutation<
    BrandCreateForHumanByAdminMutation,
    BrandCreateForHumanByAdminMutationVariables
  >(brandCreateForHumanByAdminMutation);
  function createNewBrand() {
    if (createLoading) return;
    if (!newBrandName || !newBrandUrl) {
      addNotification('Please submit brand name and url.', undefined, 5000);
      return;
    }
    setCreateLoading(true);
    tryBrandCreate({
      variables: {
        humanId,
        name: newBrandName,
        url: newBrandUrl,
      },
    })
      .then(({ data: mutationData }) => {
        setCreateLoading(false);
        if (mutationData && mutationData.brandCreateForHumanByAdmin) {
          history.push('/brands/' + mutationData.brandCreateForHumanByAdmin.id);
        }
      })
      .catch((err: ApolloError) => {
        setCreateLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Brand Create Error',
        );
        logError(err, {
          component: 'AdminHumanDetail',
          func: 'tryBrandCreate',
        });
      });
  }
  const [tryPartnerReferral] = useMutation<
    HumanPartnerReferralMutation,
    HumanPartnerReferralMutationVariables
  >(humanPartnerReferralMutation);
  function makePartnerReferral(partnerId: string) {
    tryPartnerReferral({
      variables: {
        campaign: partnerCampaign,
        humanId,
        isSoft: partnerSoft === 'true',
        medium: partnerMedium,
        partnerId,
        ticket: partnerTicket,
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Partner Referral Error',
      );
      logError(err, {
        component: 'AdminHumanDetail',
        func: 'tryPartnerReferral',
      });
    });
  }
  function removePartnerReferral() {
    tryPartnerReferral({
      variables: {
        humanId,
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Partner Referral Error',
      );
      logError(err, {
        component: 'AdminHumanDetail',
        func: 'tryPartnerReferral',
      });
    });
  }
  const [tryResetPayment] = useMutation<
    HumanResetPaymentMethodMutation,
    HumanResetPaymentMethodMutationVariables
  >(humanResetPaymentMethodMutation);
  function resetPaymentMethod() {
    if (actionLoading) return;
    setActionLoading(true);
    tryResetPayment({
      variables: {
        humanId,
      },
    })
      .then(() => {
        setActionLoading(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Reset Payment Error',
        );
        logError(err, {
          component: 'AdminHumanDetail',
          func: 'tryResetPayment',
        });
      });
  }
  const [tryAdminSubscriptionPro] = useMutation<
    HumanSubscriptionProAdminMutation,
    HumanSubscriptionProAdminMutationVariables
  >(humanSubscriptionProAdminMutation);
  function toggleSubscriptionPro() {
    if (proActionLoading || !humanDetails) return;
    setProActionLoading(true);
    tryAdminSubscriptionPro({
      variables: {
        humanId,
        isCancel: !!humanDetails.proStart,
      },
    })
      .then(() => {
        setProActionLoading(false);
      })
      .catch((err: ApolloError) => {
        setProActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Toggle Pro Error');
        logError(err, {
          component: 'AdminHumanDetail',
          func: 'tryAdminSubscriptionPro',
        });
      });
  }
  const [tryEditHuman] = useMutation<
    HumanEditByAdminMutation,
    HumanEditByAdminMutationVariables
  >(humanEditByAdminMutation);
  function onSaveEditingInfo() {
    if (actionLoading) return;
    if (!editHumanFirst && !editHumanLast) {
      addNotification('Please submit a first or last name.', undefined, 5000);
      return;
    }
    if (!editHumanPhoneValid) {
      addNotification('This phone number is invalid.', undefined, 5000);
      return;
    }
    if (!editHumanEmail && !editHumanPhone) {
      addNotification('Please either a phone or an email', undefined, 5000);
      return;
    }
    if (!editHumanEmail && editHumanSecondaryEmails.length) {
      addNotification(
        'Make sure you have a primary email set instead of only secondaries.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryEditHuman({
      variables: {
        clientFee: editHumanClientFee || '',
        discountCode: editHumanDiscountCode || null,
        email: editHumanEmail,
        firstName: editHumanFirst,
        humanId,
        isAgency: editHumanIsAgency,
        isDelinquent: editHumanDelinquent === 'true',
        lastName: editHumanLast,
        phone: editHumanPhone,
        secondaryEmails: editHumanSecondaryEmails.filter(
          (se) => !!se && !!se.trim(),
        ),
        shouldExcludeFromKpiReports: editHumanExcludeKpis === 'true',
      },
    })
      .then(() => {
        setActionLoading(false);
        setIsEditingInfo(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Human Edit Error');
        logError(err, {
          component: 'AdminHumanDetail',
          func: 'tryEditHuman',
        });
      });
  }
  function startEditingInfo() {
    if (!humanDetails) {
      return;
    }
    setIsEditingInfo(true);
    setEditHumanFirst(humanDetails.firstName || '');
    setEditHumanClientFee(validClientFee(humanDetails.clientFee || ''));
    setEditHumanDiscountCode(
      validDiscountCode(humanDetails.discountCode || '', true),
    );
    setEditHumanLast(humanDetails.lastName || '');
    setEditHumanExcludeKpis(
      humanDetails.shouldExcludeFromKpiReports ? 'true' : 'false',
    );
    setEditHumanIsAgency(humanDetails.isAgency || '');
    setEditHumanDelinquent(humanDetails.isDelinquent ? 'true' : 'false');
    setEditHumanPhone(humanDetails.primaryPhone || '');
    setEditHumanPhoneValid(true);
    setEditHumanEmail(humanDetails.primaryEmail || '');
    setEditHumanSecondaryEmails(
      (humanDetails.secondaryEmails || []).filter(
        (se) => se !== humanDetails.primaryEmail,
      ),
    );
  }
  function phoneNumberChange(phone: string, isValid: boolean) {
    setEditHumanPhone(phone);
    if (!phone || isValid) {
      setEditHumanPhoneValid(true);
    }
  }
  function phoneNumberBlur(isValid: boolean) {
    setEditHumanPhoneValid(!editHumanPhone || isValid);
  }
  const [tryPartnerSearch] = useMutation<
    PartnerSearchForAdminHumanMutation,
    PartnerSearchForAdminHumanMutationVariables
  >(partnerSearchForAdminHumanMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput || searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    tryPartnerSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        if (dataMatchSearch && dataMatchSearch.partnerSearchForAdmin) {
          console.log(
            'tryPartnerSearch',
            dataMatchSearch.partnerSearchForAdmin,
          );
          setSearchResults(dataMatchSearch.partnerSearchForAdmin);
        }
      },
      variables: {
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Expert Search Error');
      logError(err, {
        component: 'AdminHumanDetail',
        func: 'tryPartnerSearch',
      });
      setSearchResults(null);
    });
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
  }
  const [tryHumanSearch] = useMutation<
    HumanSearchForAdminHumanMutation,
    HumanSearchForAdminHumanMutationVariables
  >(humanSearchForAdminHumanMutation);
  function onSearchStartHumans() {
    console.log('onSearchStartHumans', searchInputHumans);
    if (!searchInputHumans || searchInputHumans.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    tryHumanSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        if (dataMatchSearch && dataMatchSearch.humanSearchForAdmin) {
          console.log('tryHumanSearch', dataMatchSearch.humanSearchForAdmin);
          setSearchResultsHumans(
            dataMatchSearch.humanSearchForAdmin.filter((h) => h.id !== humanId),
          );
        }
      },
      variables: {
        searchQuery: searchInputHumans,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Human Search Error');
      logError(err, {
        component: 'AdminHumanDetail',
        func: 'tryHumanSearch',
      });
      setSearchResultsHumans(null);
    });
  }
  function handleSearchInputHumansKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStartHumans();
    }
  }
  function confirmMergeSpecific(mergeId: string) {
    setMergeSpecific(mergeId);
  }
  function cancelMergeSpecific() {
    setMergeSpecific('');
  }
  const [tryHumanMerge] = useMutation<
    HumanMergeByAdminMutation,
    HumanMergeByAdminMutationVariables
  >(humanMergeByAdminMutation);
  function onMergeSpecific() {
    if (modalLoading) {
      return;
    }
    setModalLoading(true);
    tryHumanMerge({
      variables: {
        childHumanId: humanId,
        parentHumanId: mergeSpecific,
      },
    })
      .then(() => {
        setModalLoading(false);
        setMergeSpecific('');
        setSearchInputHumans('');
        setSearchResultsHumans(null);
        history.push('/humans/' + mergeSpecific);
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Merge Human Error');
        logError(err, {
          component: 'AdminHumanDetail',
          func: 'onMergeSpecific',
        });
      });
  }
  function blockClickPropagation(ev: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  const secondaryPanels = ['About', 'Notes'].filter((p) => p !== primaryPanel);
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
    if (panelName === 'Notes' && !calledUserNotes && humanId) {
      getUserNotes().catch((err: ApolloError) => {
        logError(err, {
          component: 'AdminHumanDetail',
          func: 'getUserNotes',
        });
      });
    }
  }
  if (loadingHuman || loadingBrands || loadingUserNotes || loadingProPayments) {
    // ignore these
  }
  let loginToken = '';
  if (!errorToken && !loadingToken && dataToken) {
    loginToken = dataToken.adminGenerateToken || '';
  }
  const loginUrl =
    envUtils.pickWebAppUrlForUserType('human') + '?token=' + loginToken;
  return (
    <div className="DashboardModal AdminHumanDetailModal">
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '') +
          (mergeSpecific ? ' ThreadDetailViewWithModal ' : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/brands')
              }
              className="ThreadDetailMainHeaderBack"
            >
              {humanDetails
                ? (humanDetails.firstName || '') +
                  ' ' +
                  (humanDetails.lastName || '')
                : 'Back'}
            </div>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody AdminHumanDetailView">
            <div className="AdminLinkedRecords AdminLinkedRecordsLeft">
              <div className="AdminLinkedRecordsTitle">Brands</div>
              {brandsForHuman.map((b) => (
                <Link
                  key={b.id}
                  to={'/brands/' + b.id}
                  className="AdminLinkedRecord"
                >
                  <div className="AdminLinkedRecordTitle">{b.name}</div>
                  <div className="AdminLinkedRecordSubtitle">{b.url}</div>
                  <div className="AdminLinkedRecordTag">Brand</div>
                </Link>
              ))}
              {!brandsForHuman.length && (
                <div className="AdminLinkedRecordsEmpty">
                  {errorBrands
                    ? errorUtils.getErrorMessage(errorBrands)
                    : 'No brands founds for this client.'}
                </div>
              )}
            </div>
            <div className="AdminDashboardForm">
              <div className="AdminDashboardFormTitle">
                Create a completely new brand for this client?
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Brand Name</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Brand Name"
                  value={newBrandName}
                  onChange={(e) => setNewBrandName(e.currentTarget.value)}
                />
              </div>
              <div className="AdminDashboardFormField">
                <div className="AdminDashboardFormFieldTitle">Website Url</div>
                <input
                  className="AdminDashboardFormFieldInput"
                  type="text"
                  autoComplete="new-off"
                  spellCheck="false"
                  placeholder="Website Url"
                  value={newBrandUrl}
                  onChange={(e) => setNewBrandUrl(e.currentTarget.value)}
                />
              </div>
              <div
                className={
                  'AdminDashboardFormAction ' +
                  (createLoading ? ' AdminDashboardFormActionLoading ' : '')
                }
                onClick={createNewBrand}
              >
                Create
              </div>
            </div>
          </div>
          {!!mergeSpecific && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelMergeSpecific}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Merge into another human?
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        You are merging this human, into the parent human you
                        just selected. We can&apos;t undo this action. Please
                        double check this.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={onMergeSpecific}
                          >
                            Yes, merge this child into the selected parent human
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelMergeSpecific}
                          >
                            No, never mind, do nothing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelMergeSpecific}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div className="ThreadDetailSidebar">
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            <div className="ThreadDetailSidebarBodyPanels">
              {primaryPanel === 'About' && humanDetails && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                  {isEditingInfo ? (
                    <div className="ThreadDetailSidebarAdminSection">
                      <div
                        className="ThreadDetailSidebarAdminSectionTopRightBtn"
                        onClick={() => setIsEditingInfo(false)}
                      >
                        cancel
                      </div>
                      <div className="ThreadDetailSidebarAdminForm">
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          First Name
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="First Name"
                          value={editHumanFirst}
                          onChange={(e) =>
                            setEditHumanFirst(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Last Name
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Last Name"
                          value={editHumanLast}
                          onChange={(e) =>
                            setEditHumanLast(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Phone Number
                        </div>
                        <PhoneNumberInput
                          className={
                            'ThreadDetailSidebarAdminFormInput ' +
                            (!editHumanPhoneValid
                              ? ' ThreadDetailSidebarAdminFormInputInvalid '
                              : '')
                          }
                          value={editHumanPhone}
                          onChange={phoneNumberChange}
                          onBlur={phoneNumberBlur}
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Primary Email
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="email"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Primary Email"
                          value={editHumanEmail}
                          onChange={(e) =>
                            setEditHumanEmail(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Secondary Emails
                        </div>
                        {editHumanSecondaryEmails.map((se, i) => (
                          <input
                            key={i}
                            className="ThreadDetailSidebarAdminFormInput ThreadDetailSidebarAdminFormInputNoBottom"
                            type="email"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Secondary Email Address"
                            value={se}
                            onChange={(e) =>
                              setEditHumanSecondaryEmails(
                                editHumanSecondaryEmails
                                  .slice(0, i)
                                  .concat(e.currentTarget.value)
                                  .concat(
                                    editHumanSecondaryEmails.slice(i + 1),
                                  ),
                              )
                            }
                          />
                        ))}
                        <div
                          className="ThreadDetailSidebarAdminFormExtra"
                          onClick={() =>
                            setEditHumanSecondaryEmails(
                              editHumanSecondaryEmails.concat(''),
                            )
                          }
                        >
                          Add another email?
                        </div>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Exclude from KPIs?
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editHumanExcludeKpis}
                          onChange={(e) =>
                            setEditHumanExcludeKpis(e.currentTarget.value)
                          }
                        >
                          <option value="false">False</option>
                          <option value="true">True</option>
                        </select>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Is Agency?
                        </div>
                        <input
                          className="ThreadDetailSidebarAdminFormInput"
                          type="text"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Agency Website"
                          value={editHumanIsAgency}
                          onChange={(e) =>
                            setEditHumanIsAgency(e.currentTarget.value)
                          }
                        />
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Warning / Delinquent
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editHumanDelinquent}
                          onChange={(e) =>
                            setEditHumanDelinquent(e.currentTarget.value)
                          }
                        >
                          <option value="false">False</option>
                          <option value="true">True</option>
                        </select>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Client Fee
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editHumanClientFee}
                          onChange={(e) =>
                            setEditHumanClientFee(e.currentTarget.value)
                          }
                        >
                          <option value="">None</option>
                          <option value="3_POINT_5">3.5% client fee</option>
                        </select>
                        <div className="ThreadDetailSidebarAdminFormTitle">
                          Active Discount Code
                        </div>
                        <select
                          className="ThreadDetailSidebarAdminFormInput"
                          required
                          value={editHumanDiscountCode}
                          onChange={(e) =>
                            setEditHumanDiscountCode(e.currentTarget.value)
                          }
                        >
                          <option value="">None - New Customer</option>
                          <option value="ALREADY_PAID">
                            None - Already Did A Project
                          </option>
                          <option value="10_FIRST_FOREVER">
                            10% off FIRST project
                          </option>
                          <option value="15_FIRST_FOREVER">
                            15% off FIRST project
                          </option>
                          <option value="10_NEXT_FOREVER">
                            10% off NEXT project
                          </option>
                        </select>
                        <div
                          className={
                            'ThreadDetailSidebarAdminFormAction ' +
                            (actionLoading
                              ? ' ThreadDetailSidebarAdminFormActionLoading '
                              : '')
                          }
                          onClick={onSaveEditingInfo}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      {!!humanDetails.expertReferralStr && (
                        <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                          <div className="ThreadDetailSidebarBodyPanelEmpty">
                            <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                              An expert brought this client
                            </div>
                            <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                              Storetasker only takes a 3% cut on clients you
                              bring to the platform and you&apos;re welcome to
                              charge them any hourly rate you like.
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="ThreadDetailSidebarAdminSection">
                        <div
                          className="ThreadDetailSidebarAdminSectionTopRightBtn"
                          onClick={startEditingInfo}
                        >
                          edit
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionTitle">
                          Human
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionBig">
                          {(humanDetails.firstName || '') +
                            ' ' +
                            (humanDetails.lastName || '')}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          <LocationTimestamp
                            locationCity={
                              humanDetails.location &&
                              humanDetails.location.city
                            }
                            locationRegionCode={
                              humanDetails.location &&
                              humanDetails.location.regionCode
                            }
                            locationTimezone={
                              humanDetails.location &&
                              humanDetails.location.timezone
                            }
                          />
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Primary phone:{' '}
                          <span className="SelectAllText">
                            {formatValidPhoneNumber(
                              humanDetails.primaryPhone,
                            ) || humanDetails.primaryPhone}
                          </span>
                        </div>
                        {!!humanDetails.pendingPhone && (
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Pending phone:{' '}
                            <span className="SelectAllText">
                              {formatValidPhoneNumber(
                                humanDetails.pendingPhone,
                              ) || humanDetails.pendingPhone}
                            </span>
                          </div>
                        )}
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Primary email:{' '}
                          <span className="SelectAllText">
                            {humanDetails.primaryEmail || ''}
                          </span>
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Secondary emails:{' '}
                          {(humanDetails.secondaryEmails || [])
                            .filter((se) => se !== humanDetails.primaryEmail)
                            .map((se, i) => (
                              <Fragment key={i}>
                                <span className="SelectAllText">{se}</span>,{' '}
                              </Fragment>
                            ))}
                        </div>
                        {humanDetails.createdAt && (
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Signed up:{' '}
                            {moment(humanDetails.createdAt).format(
                              'MMMM Do, YYYY',
                            )}
                          </div>
                        )}
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Has Saved Payment Method:{' '}
                          {humanDetails.paymentMethodId ? 'True' : 'False'}
                        </div>
                        {!!humanDetails.paymentMethodId && (
                          <div
                            className={
                              'ThreadDetailSidebarAdminAction ' +
                              (actionLoading
                                ? ' ThreadDetailSidebarAdminActionLoading '
                                : '')
                            }
                            onClick={resetPaymentMethod}
                          >
                            reset credit card
                          </div>
                        )}
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Lead Id: {humanDetails.leadStr || 'none'}
                        </div>
                        {humanDetails.shouldExcludeFromKpiReports && (
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Exclude from KPIs: True
                          </div>
                        )}
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Is Agency: {humanDetails.isAgency || 'False'}
                        </div>
                        {humanDetails.isDelinquent && (
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Warning / Delinquent: True
                          </div>
                        )}
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Client Fee: {explainClientFee(humanDetails.clientFee)}
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Discount Code:{' '}
                          {explainDiscountCode(humanDetails.discountCode)}
                        </div>
                        {humanDetails.isVerified && (
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Is Verified: True
                          </div>
                        )}
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          <a
                            className="ThreadDetailSidebarAboutWebsiteLink"
                            href={loginUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Impersonate Human
                          </a>
                        </div>
                      </div>
                      <div className="ThreadDetailSidebarAdminSection">
                        <div className="ThreadDetailSidebarAdminSectionTitle">
                          Storetasker Pro
                        </div>
                        {humanDetails.proStart ? (
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Active{' '}
                            {humanDetails.proSubscriptionId ? 'paid' : 'unpaid'}{' '}
                            subscriber since{' '}
                            {moment(humanDetails.proStart).format(
                              'MMMM Do, YYYY',
                            )}
                          </div>
                        ) : (
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Not subscribed.
                          </div>
                        )}
                        <div
                          className={
                            'ThreadDetailSidebarAdminAction ' +
                            (proActionLoading
                              ? ' ThreadDetailSidebarAdminActionLoading '
                              : '')
                          }
                          onClick={toggleSubscriptionPro}
                        >
                          {humanDetails.proStart
                            ? 'Cancel Pro Membership'
                            : 'Give Pro For Free'}
                        </div>
                        {!allProPayments.length && (
                          <div className="ThreadDetailSidebarAdminEmpty">
                            {errorProPayments
                              ? errorUtils.getErrorMessage(errorProPayments) ||
                                'Loading Error'
                              : 'No ST Pro subscription payments yet.'}
                          </div>
                        )}
                        {allProPayments.map((p) => (
                          <div className="AdminLinkedRecord" key={p.id}>
                            <div className="AdminLinkedRecordTitle">
                              {moment(p.createdAt).format('MMMM Do, YYYY')}
                            </div>
                            <div className="AdminLinkedRecordSubtitle">
                              ${centsDollarsRounded(p.cents || 0)}
                            </div>
                            <div className="AdminLinkedRecordSubtitle">
                              {p.invoiceId}
                            </div>
                          </div>
                        ))}
                      </div>
                      {!humanDetails.expertReferralStr && (
                        <div className="ThreadDetailSidebarAdminSection">
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Partner Referral
                          </div>
                          {humanDetails.partnerReferral &&
                            humanDetails.partnerReferral.partner && (
                              <Fragment>
                                <Link
                                  to={
                                    '/extras/partners/' +
                                    humanDetails.partnerReferral.partner.id
                                  }
                                  className="AdminLinkedRecord"
                                >
                                  <div className="AdminLinkedRecordTitle">
                                    {humanDetails.partnerReferral.partner
                                      .partnerName || ''}
                                  </div>
                                  <div className="AdminLinkedRecordSubtitle">
                                    Partner Referral
                                  </div>
                                  <div className="AdminLinkedRecordTag">
                                    Partner
                                  </div>
                                </Link>
                                <div className="ThreadDetailSidebarAdminSectionDescription">
                                  Medium:{' '}
                                  {humanDetails.partnerReferral.medium || '?'}
                                </div>
                                <div className="ThreadDetailSidebarAdminSectionDescription">
                                  Type:{' '}
                                  {humanDetails.partnerReferral.isSoft
                                    ? 'Soft Referral'
                                    : 'Real Referral'}
                                </div>
                                {humanDetails.partnerReferral.ticket && (
                                  <div className="ThreadDetailSidebarAdminSectionDescription">
                                    Ticket:{' '}
                                    {humanDetails.partnerReferral.ticket}
                                  </div>
                                )}
                                {humanDetails.partnerReferral.campaign && (
                                  <div className="ThreadDetailSidebarAdminSectionDescription">
                                    Campaign:{' '}
                                    {humanDetails.partnerReferral.campaign}
                                  </div>
                                )}
                                <div
                                  className="ThreadDetailSidebarAdminSectionRowBtn"
                                  onClick={removePartnerReferral}
                                >
                                  Remove referral?
                                </div>
                              </Fragment>
                            )}
                          {(!humanDetails.partnerReferral ||
                            !humanDetails.partnerReferral.partner) && (
                            <div className="ThreadDetailSidebarAdminForm">
                              <div className="ThreadDetailSidebarAdminFormTitle">
                                Referral Medium
                              </div>
                              <select
                                className="ThreadDetailSidebarAdminFormInput"
                                required
                                value={partnerMedium}
                                onChange={(e) =>
                                  setPartnerMedium(e.currentTarget.value)
                                }
                              >
                                <option value="email">Email Intro</option>
                                <option value="form">Embedded Form</option>
                                <option value="link">Tracking Link</option>
                                <option value="support">Support Ticket</option>
                                <option value="discount">Discount Code</option>
                                <option value="manual">
                                  Manual Attribution
                                </option>
                              </select>
                              <div className="ThreadDetailSidebarAdminFormTitle">
                                Referral Type
                              </div>
                              <select
                                className="ThreadDetailSidebarAdminFormInput"
                                required
                                value={partnerSoft}
                                onChange={(e) =>
                                  setPartnerSoft(e.currentTarget.value)
                                }
                              >
                                <option value="false">Real Referral</option>
                                <option value="true">Soft Referral</option>
                              </select>
                              {partnerMedium === 'support' && (
                                <Fragment>
                                  <div className="ThreadDetailSidebarAdminFormTitle">
                                    Ticket Number
                                  </div>
                                  <input
                                    className="ThreadDetailSidebarAdminFormInput"
                                    type="text"
                                    autoComplete="new-off"
                                    spellCheck="false"
                                    placeholder="Ticket Number"
                                    value={partnerTicket}
                                    onChange={(e) =>
                                      setPartnerTicket(e.currentTarget.value)
                                    }
                                  />
                                </Fragment>
                              )}
                              <div className="ThreadDetailSidebarAdminFormTitle">
                                Campaign Name
                              </div>
                              <input
                                className="ThreadDetailSidebarAdminFormInput"
                                type="text"
                                autoComplete="new-off"
                                spellCheck="false"
                                placeholder="Campaign Name"
                                value={partnerCampaign}
                                onChange={(e) =>
                                  setPartnerCampaign(e.currentTarget.value)
                                }
                              />
                              <div className="ThreadDetailSidebarAdminSearch">
                                <div className="ThreadDetailSidebarAdminSearchBar">
                                  <div className="ThreadDetailSidebarAdminFormTitle">
                                    Partner Search
                                  </div>
                                  <input
                                    type="text"
                                    className="ThreadDetailSidebarAdminSearchBarInput"
                                    autoComplete="new-off"
                                    spellCheck="false"
                                    placeholder="Partner Search"
                                    value={searchInput}
                                    onChange={(e) =>
                                      setSearchInput(e.target.value)
                                    }
                                    onKeyDown={handleSearchInputKeyDown}
                                  />
                                </div>
                                <div className="ThreadDetailSidebarAdminSearchResults">
                                  {searchResults && !searchResults.length && (
                                    <div className="ThreadDetailSidebarAdminEmpty">
                                      No partners found. Try another search
                                      query to find who you&apos;re looking for.
                                    </div>
                                  )}
                                  {searchResults &&
                                    searchResults.map((partnerResult) => (
                                      <div
                                        key={partnerResult.id}
                                        className="ThreadDetailSidebarAdminSearchResult"
                                        onClick={() =>
                                          makePartnerReferral(partnerResult.id)
                                        }
                                      >
                                        <div className="ThreadDetailSidebarAdminSearchResultTitle">
                                          {partnerResult.partnerName || ''}
                                        </div>
                                        <div className="ThreadDetailSidebarAdminSearchResultSubtitle">
                                          {partnerResult.trackingId}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      <div className="ThreadDetailSidebarAdminSection">
                        <div className="ThreadDetailSidebarAdminSectionTitle">
                          Merge Into Human
                        </div>
                        <div className="ThreadDetailSidebarAdminSearch">
                          <div className="ThreadDetailSidebarAdminSearchBar">
                            <input
                              type="text"
                              className="ThreadDetailSidebarAdminSearchBarInput"
                              autoComplete="new-off"
                              spellCheck="false"
                              placeholder="Search"
                              value={searchInputHumans}
                              onChange={(e) =>
                                setSearchInputHumans(e.target.value)
                              }
                              onKeyDown={handleSearchInputHumansKeyDown}
                            />
                          </div>
                          <div className="ThreadDetailSidebarAdminSearchResults">
                            {searchResultsHumans &&
                              !searchResultsHumans.length && (
                                <div className="ThreadDetailSidebarAdminEmpty">
                                  No humans found. Try another search query to
                                  find who you&apos;re looking for.
                                </div>
                              )}
                            {searchResultsHumans &&
                              searchResultsHumans.map((humanResult) => (
                                <div
                                  key={humanResult.id}
                                  className="ThreadDetailSidebarAdminSearchResult"
                                  onClick={() =>
                                    confirmMergeSpecific(humanResult.id)
                                  }
                                >
                                  <div className="ThreadDetailSidebarAdminSearchResultTitle">
                                    {(
                                      (humanResult.firstName || '') +
                                      ' ' +
                                      (humanResult.lastName || '')
                                    ).trim()}
                                  </div>
                                  <div className="ThreadDetailSidebarAdminSearchResultSubtitle">
                                    {humanResult.primaryEmail ||
                                      humanResult.primaryPhone ||
                                      ''}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              )}
              {primaryPanel === 'Notes' && humanDetails && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelTeam">
                  <div className="ThreadDetailSidebarAdminSection">
                    <UserNoteEditor
                      onSave={onSaveNote}
                      isLoading={noteLoading}
                      errorFound={errorUserNotes}
                      userNotes={allUserNotes}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/brands')
              }
              className="DashboardErrorCoverNav"
            >
              back
            </div>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminHumanDetail.propTypes = {
  humanId: PropTypes.string.isRequired,
};

export default AdminHumanDetail;
