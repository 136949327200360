import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ReactTooltip from 'react-tooltip';
import { ErrorBoundary } from 'react-error-boundary';
import './styles/App.scss';
import AppHuman from './AppHuman';
import AppExpert from './AppExpert';
import AppAdmin from './AppAdmin';
import AppPartner from './AppPartner';
import { WebAppProvider } from './components/context/WebApp';
import GlobalNotificationProvider, {
  GlobalNotification,
} from './components/context/GlobalNotification';
import apolloUtils from './utils/apollo';
import logError from './utils/airbrake';
import { STRIPE_PUBLIC_KEY } from './utils/constants';
/* start: only import these once */
import 'draft-js/dist/Draft.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './styles/page/ThreadDetailView.scss';
import './styles/threadEvent/ThreadEvent.scss';
import './styles/feature/UserBubble.scss';
import './styles/feature/ThreadEditor.scss';
import './styles/page/Homescreen.scss';
/* end: only import these once */

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY, {
  apiVersion: '2020-08-27',
});

function getSubdomain() {
  const sitehost = window.location.host;
  const sub = sitehost.split('.')[0];
  if (
    sub === 'localhost:3000' ||
    sub === '192' ||
    sitehost.indexOf('ngrok') >= 0
  )
    return fakeSubdomain();
  if (sub === 'localhost:3001') return 'app';
  if (sub === 'localhost:3002') return 'expert';
  if (sub === 'localhost:3003') return 'admin';
  if (sub === 'localhost:3004') return 'partner';
  return sub;
}

function fakeSubdomain() {
  return 'expert';
}

window.addEventListener('unhandledrejection', (err) => {
  // Catch all handler to notify HQ in the event a promise was rejected, but never handled
  // This event is not implemented in Firefox yet, just Chrome: https://developer.mozilla.org/en-US/docs/Web/API/PromiseRejectionEvent
  let message;
  try {
    message = JSON.stringify(err.reason);
  } catch (e) {
    message = err.reason;
  }
  logError(`Uncaught promise error (missing a catch() somewhere): ${message}`);

  // To actually send the error you can do this, i'm not sure what is more useful - knowing that the actual error
  // or just knowing that a promise was rejected and not handled (ie. a catch block needs to be added to handle it somewhere)
  err.promise.catch((e: Error) => {
    console.error('err.promise.catch', e);
    logError(e);
  });
});

const logErrorBoundary = (error: Error, info: { componentStack: string }) => {
  // Do something with the error, e.g. log to an external API
  logError(error, { info });
};

// Renders appropriate top-level view based on the subdomain (e.g. https://experts.storetasker.com)
const subdomain = getSubdomain();
if (window.self !== window.top) {
  ReactDOM.render(
    <div>
      Invalid Iframe. Please go to{' '}
      <a href="https://www.storetasker.com">Storetasker.com</a> to start again
      or email{' '}
      <a href="mailto:support@storetasker.com">support@storetasker.com</a>.
    </div>,
    document.getElementById('root'),
  );
} else if (subdomain === 'app' || subdomain === 'app-dev') {
  document.title = 'Storetasker: Customer Dashboard';
  const clientObj = apolloUtils.create('customer');
  ReactDOM.render(
    <BrowserRouter>
      <ErrorBoundary
        fallback={<div>Something went wrong</div>}
        onError={logErrorBoundary}
      >
        <ApolloProvider client={clientObj.apolloClient}>
          <WebAppProvider value="human">
            <GlobalNotificationProvider>
              <Elements stripe={stripePromise}>
                <div className="AppContainer AppHuman">
                  <AppHuman socketClient={clientObj.socketClient} />
                  <GlobalNotification />
                  <ReactTooltip className="custom-tooltip" effect="solid" />
                </div>
              </Elements>
            </GlobalNotificationProvider>
          </WebAppProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </BrowserRouter>,
    document.getElementById('root'),
  );
} else if (subdomain === 'expert' || subdomain === 'expert-dev') {
  document.title = 'Storetasker: Expert Dashboard';
  const clientObj = apolloUtils.create('expert');
  ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={clientObj.apolloClient}>
        <WebAppProvider value="expert">
          <GlobalNotificationProvider>
            <ErrorBoundary
              fallback={<div>Something went wrong</div>}
              onError={logErrorBoundary}
            >
              <div className="AppContainer AppExpert">
                <AppExpert socketClient={clientObj.socketClient} />
                <GlobalNotification />
                <ReactTooltip className="custom-tooltip" effect="solid" />
              </div>
            </ErrorBoundary>
          </GlobalNotificationProvider>
        </WebAppProvider>
      </ApolloProvider>
    </BrowserRouter>,
    document.getElementById('root'),
  );
} else if (subdomain === 'partner' || subdomain === 'partner-dev') {
  document.title = 'Storetasker: Partner Dashboard';
  const clientObj = apolloUtils.create('partner');
  ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={clientObj.apolloClient}>
        <WebAppProvider value="partner">
          <GlobalNotificationProvider>
            <ErrorBoundary
              fallback={<div>Something went wrong</div>}
              onError={logErrorBoundary}
            >
              <div className="AppContainer AppPartner">
                <AppPartner />
                <GlobalNotification />
                <ReactTooltip className="custom-tooltip" effect="solid" />
              </div>
            </ErrorBoundary>
          </GlobalNotificationProvider>
        </WebAppProvider>
      </ApolloProvider>
    </BrowserRouter>,
    document.getElementById('root'),
  );
} else if (subdomain === 'admin' || subdomain === 'admin-dev') {
  document.title = 'Storetasker: Admin Dashboard';
  const clientObj = apolloUtils.create('admin');
  ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={clientObj.apolloClient}>
        <WebAppProvider value="admin">
          <GlobalNotificationProvider>
            <ErrorBoundary
              fallback={<div>Something went wrong</div>}
              onError={logErrorBoundary}
            >
              <div className="AppContainer AppAdmin">
                <AppAdmin socketClient={clientObj.socketClient} />
                <GlobalNotification />
                <ReactTooltip className="custom-tooltip" effect="solid" />
              </div>
            </ErrorBoundary>
          </GlobalNotificationProvider>
        </WebAppProvider>
      </ApolloProvider>
    </BrowserRouter>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(
    <div>
      Invalid Domain. Please go to{' '}
      <a href="https://www.storetasker.com">Storetasker.com</a> to start again
      or email{' '}
      <a href="mailto:support@storetasker.com">support@storetasker.com</a>.
    </div>,
    document.getElementById('root'),
  );
  logError(`Invalid subdomain ${subdomain}`);
}
// window.defaultTitle = document.title;
