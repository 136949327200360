import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { MatchDetailsQuery } from '../../gql/graphql';

interface ProjectCancelActionsProps {
  onCancel: (reason: string, hasPaid: boolean) => void;
  project: MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0];
}

const statusPostAccept = [
  'ACCEPTED',
  'APPROVED',
  'COMPLETED',
  'REVISIONS_REQUESTED',
  'SUBSCRIPTION_PAYMENT_MADE',
  'IN_PROGRESS',
  'BILL_PAID',
];

const ProjectCancelActions = ({
  onCancel,
  project,
}: ProjectCancelActionsProps) => {
  const hasPaid = statusPostAccept.indexOf(project.quote.status) >= 0;
  return (
    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
      <div className="ThreadDetailMainFocusModalContainerTitle">
        Cancel Project
      </div>
      <div className="ThreadDetailMainFocusModalContainerSubtitle">
        Your client will NOT receive an automated notification about this.
        Please make sure to let them know that this project has been canceled.
      </div>
      <div className="ThreadDetailMainFocusModalChoicesSection">
        <div className="ThreadDetailMainFocusModalChoicesTitle">
          Why are you canceling this project?
        </div>
        <div className="ThreadDetailMainFocusModalChoicesOptions">
          {hasPaid ? (
            <Fragment>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onCancel('CLIENT_CANCELED', hasPaid)}
              >
                Client asked to cancel this project
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onCancel('EXPERT_CANCELED', hasPaid)}
              >
                I decided to cancel this project
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onCancel('CLIENT_DISAPPEARED', hasPaid)}
              >
                Client has disappeared
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onCancel('EXPERT_CREATING_NEW_PROJECT', hasPaid)}
              >
                I am creating a new project instead
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onCancel('CLIENT_DENIED_QUOTE', hasPaid)}
              >
                Client did not accept my quote
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onCancel('CLIENT_DISAPPEARED', hasPaid)}
              >
                Client has disappeared
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onCancel('CLIENT_CANCELED', hasPaid)}
              >
                Client asked to cancel this project
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onCancel('EXPERT_CANCELED', hasPaid)}
              >
                I decided to cancel this project
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onCancel('EXPERT_CREATING_NEW_PROJECT', hasPaid)}
              >
                I am creating a new project instead
              </div>
            </Fragment>
          )}
        </div>
      </div>
      {!!hasPaid &&
        project.quote &&
        project.quote.paymentType === 'PROJECT' && (
          <div className="ThreadDetailMainFocusModalContainerWarning">
            Since they have already paid for this project in escrow, our Support
            Team will look into this and refund the client as needed.
          </div>
        )}
    </div>
  );
};

ProjectCancelActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

export default ProjectCancelActions;
