import _ from 'lodash';
import { useEffect, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useSubscription, gql } from '@apollo/client';
import {
  Link,
  NavLink,
  Route,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import apolloUtils, { ClientWithOnReconnected } from '../../utils/apollo';
import ExpertActiveRequests from './ExpertActiveRequests';
import ExpertRequestDetail from './ExpertRequestDetail';
import ExpertClientDetail from './ExpertClientDetail';
import ExpertSupportChannelDetail from './ExpertSupportChannelDetail';
import ExpertProfileView from './ExpertProfileView';
import ExpertHomescreen from './ExpertHomescreen';
import ExpertInbound from './ExpertInbound';
import ExpertActiveClients from './ExpertActiveClients';
import ExpertCollabView from './ExpertCollabView';
import ExpertDangerZone from './ExpertDangerZone';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import {
  RequestActiveForExpertQuery,
  RequestUpdatedSubscription,
  MatchAlertsForExpertQuery,
  OrphanUnreadForExpertQuery,
  MeetingActiveForExpertTopQuery,
  MatchUpdatedSubscription,
  ExpertDetailsQuery,
  ExpertDetailsQueryVariables,
  TemplatesQuery,
  MeetingUpdatedSubscription,
  OrphanUpdatedSubscription,
  ExpertUpdatedSubscription,
  BrandUpdatedSubscription,
  HumanUpdatedSubscription,
  ProjectUpdatedSubscription,
  QuoteUpdatedSubscription,
  SupportChannelUpdatedSubscription,
} from '../../gql/graphql';
import errorUtils from '../../utils/error';
import alertUtils from '../../utils/alert';
import {
  ExpertDetail,
  MatchDetail,
  MatchPartialTop,
  OrphanDetail,
  OrphanPartialTop,
  MeetingPartialTop,
  MeetingDetail,
  RequestDetail,
  RequestPartialTop,
  SupportChannelDetail,
  BrandDetail,
  HumanSummary,
  ProjectDetail,
  QuoteDetail,
  TemplateSummary,
} from '../../utils/gql';
import {
  unreadReasonFormatted,
  shouldAlert,
  centsDollarsRounded,
  formatNumberWithCommas,
  isLeadSuggested,
} from '../../utils/format';
import logoBlack from '../../images/storetasker-logo-black.png';
import logoWhite from '../../images/storetasker-logo-white.png';

const requestActiveForExpertQuery = gql`
  query RequestActiveForExpert {
    requestActiveForExpert {
      ...RequestPartialTop
    }
  }
  ${RequestPartialTop}
`;

const requestUpdatedSubscription = gql`
  subscription RequestUpdated {
    requestUpdated {
      ...RequestDetail
    }
  }
  ${RequestDetail}
`;

const matchAlertsForExpertQuery = gql`
  query MatchAlertsForExpert {
    matchAlertsForExpert {
      ...MatchPartialTop
    }
    matchDangerZoneForExpert {
      ...MatchPartialTop
    }
    matchDangerZoneProjectsForExpert {
      ...MatchPartialTop
    }
    matchOldAlertsForExpert {
      ...MatchPartialTop
    }
  }
  ${MatchPartialTop}
`;

const expertDetailsQuery = gql`
  query ExpertDetails($expertId: ID!) {
    expertDetails(expertId: $expertId) {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;

const templatesQuery = gql`
  query Templates {
    templates {
      ...TemplateSummary
    }
  }
  ${TemplateSummary}
`;

const matchUpdatedSubscription = gql`
  subscription MatchUpdated {
    matchUpdated {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const orphanUnreadForExpertQuery = gql`
  query OrphanUnreadForExpert {
    orphanUnreadForExpert {
      ...OrphanPartialTop
    }
  }
  ${OrphanPartialTop}
`;

const orphanUpdatedSubscription = gql`
  subscription OrphanUpdated {
    orphanUpdated {
      ...OrphanDetail
    }
  }
  ${OrphanDetail}
`;

const supportChannelUpdatedSubscription = gql`
  subscription SupportChannelUpdated {
    supportChannelUpdated {
      ...SupportChannelDetail
    }
  }
  ${SupportChannelDetail}
`;

const meetingActiveForExpertQuery = gql`
  query MeetingActiveForExpertTop {
    meetingActiveForExpert {
      ...MeetingPartialTop
    }
  }
  ${MeetingPartialTop}
`;

const meetingUpdatedSubscription = gql`
  subscription MeetingUpdated {
    meetingUpdated {
      ...MeetingDetail
    }
  }
  ${MeetingDetail}
`;

const expertUpdatedSubscription = gql`
  subscription ExpertUpdated {
    expertUpdated {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;

const brandUpdatedSubscription = gql`
  subscription BrandUpdated {
    brandUpdated {
      ...BrandDetail
    }
  }
  ${BrandDetail}
`;

const humanUpdatedSubscription = gql`
  subscription HumanUpdated {
    humanUpdated {
      ...HumanSummary
    }
  }
  ${HumanSummary}
`;

const projectUpdatedSubscription = gql`
  subscription ProjectUpdated {
    projectUpdated {
      ...ProjectDetail
    }
  }
  ${ProjectDetail}
`;

const quoteUpdatedSubscription = gql`
  subscription QuoteUpdated {
    quoteUpdated {
      ...QuoteDetail
    }
  }
  ${QuoteDetail}
`;

const PATH_TO_CLASS = {
  clients: 'ExpertDashboardClients',
  collab: 'ExpertDashboardCollab',
  dangerzone: 'ExpertDashboardDangerZone',
  home: 'ExpertDashboardHome',
  inbound: 'ExpertDashboardInbound',
  leads: 'ExpertDashboardLeads',
  profile: 'ExpertDashboardProfile',
  support: 'ExpertDashboardSupport',
} as { [key: string]: string };

const MAX_ACTIVE_PROJECTS = 15;
const MAX_ACTIVE_PROJECTS_BAD = 8;
const MAX_ACTIVE_ESCROW = 1500000;
const MAX_ACTIVE_ESCROW_BAD = 800000;
const initTime = new Date().getTime();
const jobsSuggested = new Map<string, boolean>();

interface ExpertDashboardProps {
  expertId: string;
  socketClient: ClientWithOnReconnected;
}

const ExpertDashboard = ({ expertId, socketClient }: ExpertDashboardProps) => {
  const location = useLocation();
  const [networkErrorMessage, setNetworkErrorMessage] = useState('');
  const [networkErrorType, setNetworkErrorType] = useState('');
  const [refetchedTemplates, setRefetchedTemplates] = useState(false);
  const { addNotification } = useContext(GlobalNotificationContext);
  const {
    data: dataExpertDetails,
    error: errorExpertDetails,
    loading: loadingExpertDetails,
    refetch: refetchExpertDetails,
    subscribeToMore: subscribeToMoreExpertDetails,
  } = useQuery<ExpertDetailsQuery, ExpertDetailsQueryVariables>(
    expertDetailsQuery,
    {
      returnPartialData: true,
      variables: {
        expertId,
      },
    },
  );
  const expertDetails =
    dataExpertDetails &&
    dataExpertDetails.expertDetails &&
    dataExpertDetails.expertDetails.id
      ? dataExpertDetails.expertDetails
      : undefined;
  useEffect(() => {
    subscribeToMoreExpertDetails<ExpertUpdatedSubscription>({
      document: expertUpdatedSubscription,
      onError: (err) => {
        addNotification(
          'An error occured, please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreExpertDetails', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { expertUpdated },
          },
        },
      ) => {
        console.log('expertUpdated', prev, expertUpdated);
        if (!expertUpdated) {
          return prev;
        }
        return {
          expertDetails: {
            ...(prev.expertDetails || {}),
            ...expertUpdated,
          },
        };
      },
    });
  }, [subscribeToMoreExpertDetails, addNotification]);
  const skiVal =
    expertDetails && expertDetails.ski ? expertDetails.ski : undefined;
  const skillsVal =
    expertDetails && expertDetails.tagSkills
      ? expertDetails.tagSkills
      : undefined;
  const toolsVal =
    expertDetails && expertDetails.tagTools
      ? expertDetails.tagTools
      : undefined;
  const {
    data: dataActiveRequests,
    error: errorActiveRequests,
    loading: loadingActiveRequests,
    refetch: refetchActiveRequests,
    subscribeToMore: subscribeToMoreActiveRequests,
  } = useQuery<RequestActiveForExpertQuery>(requestActiveForExpertQuery);
  useEffect(() => {
    subscribeToMoreActiveRequests<RequestUpdatedSubscription>({
      document: requestUpdatedSubscription,
      onError: (err) => {
        addNotification(
          'An error occured, please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreActiveRequests', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { requestUpdated },
          },
        },
      ) => {
        console.log('requestUpdated', prev, requestUpdated);
        if (!requestUpdated) {
          return prev;
        }
        const updatingIndex = (prev.requestActiveForExpert || []).findIndex(
          (l) => l.id === requestUpdated.id,
        );
        // check if interesting
        const isActiveAndRelevant = !!(
          !requestUpdated.cancelledAt &&
          !requestUpdated.inReview &&
          requestUpdated.isActive &&
          !requestUpdated.expertsSkippedReason.find(
            (skip) => skip.expertStr === expertId,
          ) &&
          !requestUpdated.needsApproval.find(
            (na) => na.expertStr === expertId,
          ) &&
          !requestUpdated.readLog.find((rl) => rl.readByStr === expertId) &&
          requestUpdated.potentialMatchesStr.indexOf(expertId) >= 0
        );
        // check map if already done this one + is in suggestedMatchesStr
        if (
          isActiveAndRelevant &&
          !jobsSuggested.get(requestUpdated.id) &&
          isLeadSuggested(
            expertId,
            requestUpdated,
            undefined,
            skiVal,
            skillsVal,
            toolsVal,
          )
        ) {
          jobsSuggested.set(requestUpdated.id, true);
          addNotification('', 'SUGGESTION', undefined, {
            requestId: requestUpdated.id,
          });
        }
        if (updatingIndex >= 0) {
          // update existing
          return {
            requestActiveForExpert: prev.requestActiveForExpert
              .slice(0, updatingIndex)
              .concat({
                ...prev.requestActiveForExpert[updatingIndex],
                ...requestUpdated,
              })
              .concat(prev.requestActiveForExpert.slice(updatingIndex + 1)),
          };
        }
        if (isActiveAndRelevant) {
          alertUtils.alertNewJob(requestUpdated.id);
        }
        // add new
        return {
          requestActiveForExpert: (prev.requestActiveForExpert || []).concat(
            requestUpdated,
          ),
        };
      },
    });
  }, [
    subscribeToMoreActiveRequests,
    addNotification,
    expertId,
    skiVal,
    skillsVal,
    toolsVal,
  ]);
  let activeLeadsForExpert: RequestActiveForExpertQuery['requestActiveForExpert'] =
    [];
  if (dataActiveRequests && dataActiveRequests.requestActiveForExpert) {
    dataActiveRequests.requestActiveForExpert.forEach((r) => {
      if (
        isLeadSuggested(expertId, r, expertDetails) &&
        !jobsSuggested.get(r.id)
      ) {
        jobsSuggested.set(r.id, true);
      }
    });
    activeLeadsForExpert = dataActiveRequests.requestActiveForExpert.filter(
      (r) =>
        !r.cancelledAt &&
        !r.inReview &&
        r.isActive &&
        (!expertDetails || !expertDetails.isNoKeyAccounts || !r.isKeyAccount) &&
        !r.expertsSkippedReason.find((skip) => skip.expertStr === expertId) &&
        !r.needsApproval.find((na) => na.expertStr === expertId) &&
        r.potentialMatchesStr.indexOf(expertId) >= 0,
    );
  }
  const unreadActiveLeads = activeLeadsForExpert.filter(
    (l) => !l.readLog.find((rl) => rl.readByStr === expertId),
  );
  const {
    data: dataMatchAlerts,
    error: errorMatchAlerts,
    loading: loadingMatchAlerts,
    refetch: refetchMatchAlerts,
    subscribeToMore: subscribeToMoreMatchAlerts,
  } = useQuery<MatchAlertsForExpertQuery>(matchAlertsForExpertQuery);
  useEffect(() => {
    subscribeToMoreMatchAlerts<MatchUpdatedSubscription>({
      document: matchUpdatedSubscription,
      onError: (err) => {
        addNotification(
          'An error occured, please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreMatchAlerts', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { matchUpdated },
          },
        },
      ) => {
        console.log('matchUpdated', prev, matchUpdated);
        if (!matchUpdated) {
          return prev;
        }
        const updatingIndexAlerts = (prev.matchAlertsForExpert || []).findIndex(
          (l) => l.id === matchUpdated.id,
        );
        const updatingIndexDangerZone = (
          prev.matchDangerZoneForExpert || []
        ).findIndex((l) => l.id === matchUpdated.id);
        const updatingIndexDangerZoneProjects = (
          prev.matchDangerZoneProjectsForExpert || []
        ).findIndex((l) => l.id === matchUpdated.id);
        const updatingIndexOldAlerts = (
          prev.matchOldAlertsForExpert || []
        ).findIndex((l) => l.id === matchUpdated.id);
        const nextAlerts =
          updatingIndexAlerts >= 0
            ? prev.matchAlertsForExpert
                .slice(0, updatingIndexAlerts)
                .concat({
                  ...prev.matchAlertsForExpert[updatingIndexAlerts],
                  ...matchUpdated,
                })
                .concat(
                  prev.matchAlertsForExpert.slice(updatingIndexAlerts + 1),
                )
            : (prev.matchAlertsForExpert || []).concat(matchUpdated);
        const nextDangerZone =
          updatingIndexDangerZone >= 0
            ? prev.matchDangerZoneForExpert
                .slice(0, updatingIndexDangerZone)
                .concat({
                  ...prev.matchDangerZoneForExpert[updatingIndexDangerZone],
                  ...matchUpdated,
                })
                .concat(
                  prev.matchDangerZoneForExpert.slice(
                    updatingIndexDangerZone + 1,
                  ),
                )
            : (prev.matchDangerZoneForExpert || []).concat(matchUpdated);
        const nextDangerZoneProjects =
          updatingIndexDangerZoneProjects >= 0
            ? prev.matchDangerZoneProjectsForExpert
                .slice(0, updatingIndexDangerZoneProjects)
                .concat({
                  ...prev.matchDangerZoneProjectsForExpert[
                    updatingIndexDangerZoneProjects
                  ],
                  ...matchUpdated,
                })
                .concat(
                  prev.matchDangerZoneProjectsForExpert.slice(
                    updatingIndexDangerZoneProjects + 1,
                  ),
                )
            : (prev.matchDangerZoneProjectsForExpert || []).concat(
                matchUpdated,
              );
        const nextOldAlerts =
          updatingIndexOldAlerts >= 0
            ? prev.matchOldAlertsForExpert
                .slice(0, updatingIndexOldAlerts)
                .concat({
                  ...prev.matchOldAlertsForExpert[updatingIndexOldAlerts],
                  ...matchUpdated,
                })
                .concat(
                  prev.matchOldAlertsForExpert.slice(
                    updatingIndexOldAlerts + 1,
                  ),
                )
            : (prev.matchOldAlertsForExpert || []).concat(matchUpdated);
        const shouldDoAlert =
          !matchUpdated.isBlocked &&
          matchUpdated.expertStr === expertId &&
          shouldAlert(matchUpdated.expertUnread);
        if (shouldDoAlert) {
          if (updatingIndexAlerts >= 0) {
            // update existing
            if (
              !shouldAlert(
                prev.matchAlertsForExpert[updatingIndexAlerts].expertUnread,
              )
            ) {
              alertUtils.alertUnread(
                matchUpdated.id,
                unreadReasonFormatted(matchUpdated.expertUnread),
              );
            }
          } else {
            // add new
            alertUtils.alertUnread(
              matchUpdated.id,
              unreadReasonFormatted(matchUpdated.expertUnread),
            );
          }
        }
        return {
          matchAlertsForExpert: nextAlerts,
          matchDangerZoneForExpert: nextDangerZone,
          matchDangerZoneProjectsForExpert: nextDangerZoneProjects,
          matchOldAlertsForExpert: nextOldAlerts,
        };
      },
    });
  }, [subscribeToMoreMatchAlerts, addNotification, expertId]);
  const allAlertsForExpert = _.uniqBy(
    [
      ...((dataMatchAlerts && dataMatchAlerts.matchAlertsForExpert) || []),
    ].filter((m) => !m.isBlocked && m.expertStr === expertId),
    'id',
  );
  const allDZForExpert = _.uniqBy(
    [
      ...((dataMatchAlerts && dataMatchAlerts.matchOldAlertsForExpert) || []),
      ...((dataMatchAlerts && dataMatchAlerts.matchDangerZoneForExpert) || []),
      ...((dataMatchAlerts &&
        dataMatchAlerts.matchDangerZoneProjectsForExpert) ||
        []),
    ].filter((m) => !m.isBlocked && m.expertStr === expertId),
    'id',
  );
  const unreadOldActiveClients = allDZForExpert.filter(
    (m) =>
      m.expertUnread &&
      m.expertReadAt &&
      m.expertReadAt < initTime - 3 * 24 * 60 * 60 * 1000,
  );
  const dangerZoneClients = allDZForExpert.filter(
    (m) => m.dangerZoneEventExpertStr,
  );
  const dangerZoneProjects = allDZForExpert.filter(
    (m) => m.dangerZoneProjectsStr && m.dangerZoneProjectsStr.length,
  );
  const allMatchesForExpert = _.uniqBy(
    [...allAlertsForExpert, ...allDZForExpert],
    'id',
  );
  const unreadActiveClients = allMatchesForExpert.filter((m) => m.expertUnread);
  const {
    data: dataOrphanUnread,
    error: errorOrphanUnread,
    loading: loadingOrphanUnread,
    refetch: refetchOrphanUnread,
    subscribeToMore: subscribeToMoreOrphanUnread,
  } = useQuery<OrphanUnreadForExpertQuery>(orphanUnreadForExpertQuery);
  useEffect(() => {
    subscribeToMoreOrphanUnread<OrphanUpdatedSubscription>({
      document: orphanUpdatedSubscription,
      onError: (err) => {
        addNotification(
          'An error occured, please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreOrphanUnread', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { orphanUpdated },
          },
        },
      ) => {
        console.log('orphanUpdated', prev, orphanUpdated);
        if (!orphanUpdated) {
          return prev;
        }
        const updatingIndex = (prev.orphanUnreadForExpert || []).findIndex(
          (l) => l.id === orphanUpdated.id,
        );
        const shouldDoAlert =
          !orphanUpdated.migratedToMatchStr &&
          orphanUpdated.expertStr === expertId &&
          shouldAlert(orphanUpdated.expertUnread);
        if (updatingIndex >= 0) {
          // update existing
          if (
            shouldDoAlert &&
            !shouldAlert(prev.orphanUnreadForExpert[updatingIndex].expertUnread)
          ) {
            alertUtils.alertUnread(
              orphanUpdated.id,
              unreadReasonFormatted(orphanUpdated.expertUnread),
            );
          }
          return {
            orphanUnreadForExpert: prev.orphanUnreadForExpert
              .slice(0, updatingIndex)
              .concat({
                ...prev.orphanUnreadForExpert[updatingIndex],
                ...orphanUpdated,
              })
              .concat(prev.orphanUnreadForExpert.slice(updatingIndex + 1)),
          };
        }
        // add new
        if (shouldDoAlert) {
          alertUtils.alertUnread(
            orphanUpdated.id,
            unreadReasonFormatted(orphanUpdated.expertUnread),
          );
        }
        return {
          orphanUnreadForExpert: (prev.orphanUnreadForExpert || []).concat(
            orphanUpdated,
          ),
        };
      },
    });
  }, [subscribeToMoreOrphanUnread, addNotification, expertId]);
  let activeOrphansForExpert: OrphanUnreadForExpertQuery['orphanUnreadForExpert'] =
    [];
  if (dataOrphanUnread && dataOrphanUnread.orphanUnreadForExpert) {
    activeOrphansForExpert = dataOrphanUnread.orphanUnreadForExpert.filter(
      (o) => !o.migratedToMatchStr && o.expertStr === expertId,
    );
  }
  const unreadOrphans = activeOrphansForExpert.filter((o) => o.expertUnread);
  const { subscribeToMore: subscribeToMoreMeetings } =
    useQuery<MeetingActiveForExpertTopQuery>(meetingActiveForExpertQuery);
  useEffect(() => {
    subscribeToMoreMeetings<MeetingUpdatedSubscription>({
      document: meetingUpdatedSubscription,
      onError: (err) => {
        addNotification(
          'An error occured, please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreMeetings', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { meetingUpdated },
          },
        },
      ) => {
        console.log('meetingUpdated', prev, meetingUpdated);
        if (!meetingUpdated) {
          return prev;
        }
        const updatingIndex = (prev.meetingActiveForExpert || []).findIndex(
          (l) => l.id === meetingUpdated.id,
        );
        if (updatingIndex >= 0) {
          // update existing
          return {
            meetingActiveForExpert: prev.meetingActiveForExpert
              .slice(0, updatingIndex)
              .concat({
                ...prev.meetingActiveForExpert[updatingIndex],
                ...meetingUpdated,
              })
              .concat(prev.meetingActiveForExpert.slice(updatingIndex + 1)),
          };
        }
        // add new
        return {
          meetingActiveForExpert: (prev.meetingActiveForExpert || []).concat(
            meetingUpdated,
          ),
        };
      },
    });
  }, [subscribeToMoreMeetings, addNotification]);
  const {
    data: dataTemplates,
    error: errorTemplates,
    refetch: refetchTemplates,
    loading: loadingTemplates,
  } = useQuery<TemplatesQuery>(templatesQuery);
  const templates = _.sortBy(
    (dataTemplates &&
      dataTemplates.templates &&
      dataTemplates.templates.filter(
        (t) => t.expertStr && t.expertStr === expertId,
      )) ||
      [],
    'title',
  );
  const quoteTemplates = templates.filter((t) => t.isQuote);
  const emailTemplates = templates.filter((t) => !t.isQuote);
  const refetchTemplatesTop = useCallback(() => {
    setRefetchedTemplates(false);
    refetchTemplates()
      .catch(() => {})
      .finally(() => {
        setRefetchedTemplates(true);
      });
  }, [refetchTemplates]);
  useEffect(() => {
    let networkTimeout: NodeJS.Timeout | undefined;
    function removeNetworkError() {
      showNetworkError('');
    }
    function showNetworkError(
      message: string,
      status?: string,
      timeout?: number,
    ) {
      if (networkTimeout) {
        clearTimeout(networkTimeout);
      }
      setNetworkErrorType(message ? status || 'BAD' : '');
      setNetworkErrorMessage(message || '');
      if (timeout) {
        networkTimeout = setTimeout(removeNetworkError, timeout);
      }
    }
    const connectingListener = socketClient.on('connecting', () => {
      console.log('ExpertDashboard socketClient onConnecting');
    });
    const connectedListener = socketClient.on('connected', () => {
      console.log('ExpertDashboard socketClient onConnected');
      removeNetworkError();
    });
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('ExpertDashboard socketClient onReconnected');
      showNetworkError('Reconnected!', 'GOOD', 1500);
      refetchExpertDetails().catch(() => {});
      refetchTemplates().catch(() => {});
      refetchActiveRequests().catch(() => {});
      refetchMatchAlerts().catch(() => {});
      refetchOrphanUnread().catch(() => {});
    });
    const disconnectedListener = socketClient.on('closed', () => {
      console.log('ExpertDashboard socketClient onDisconnected');
      if (!apolloUtils.getClosedOnPurpose()) {
        showNetworkError('Connection Error. Trying to reconnect.');
      }
    });
    const errorListener = socketClient.on('error', () => {
      console.log('ExpertDashboard socketClient onError');
      if (!apolloUtils.getClosedOnPurpose()) {
        showNetworkError('Connection Error. Trying to reconnect.');
      }
    });
    return () => {
      connectingListener();
      connectedListener();
      reconnectedListener();
      disconnectedListener();
      errorListener();
    };
  }, [
    socketClient,
    refetchExpertDetails,
    refetchTemplates,
    refetchActiveRequests,
    refetchMatchAlerts,
    refetchOrphanUnread,
  ]);
  useSubscription<SupportChannelUpdatedSubscription>(
    supportChannelUpdatedSubscription,
  );
  useSubscription<BrandUpdatedSubscription>(brandUpdatedSubscription);
  useSubscription<HumanUpdatedSubscription>(humanUpdatedSubscription);
  useSubscription<ProjectUpdatedSubscription>(projectUpdatedSubscription);
  useSubscription<QuoteUpdatedSubscription>(quoteUpdatedSubscription);
  const isSupportUnread = !!(
    expertDetails &&
    expertDetails.adminSupportChannel &&
    expertDetails.adminSupportChannel.expertUnread
  );
  const loadingError =
    errorExpertDetails ||
    errorActiveRequests ||
    errorTemplates ||
    errorMatchAlerts ||
    errorOrphanUnread
      ? errorUtils.getErrorMessage(
          errorExpertDetails ||
            errorActiveRequests ||
            errorTemplates ||
            errorMatchAlerts ||
            errorOrphanUnread!,
        )
      : '';
  if (
    loadingExpertDetails &&
    loadingActiveRequests &&
    loadingMatchAlerts &&
    loadingTemplates &&
    refetchedTemplates &&
    loadingOrphanUnread
  ) {
    // ignore these
  }
  let blockClaims = false;
  let claimsLeftStr =
    "It all starts with a project. Let's build your client base.";
  let claimsLeftExtra = '';
  if (expertDetails) {
    if (
      expertDetails.status === 'no-match' ||
      expertDetails.status === 'no-match-quote'
    ) {
      claimsLeftStr = 'You do not have any available claims right now.';
      blockClaims = true;
    } else if (expertDetails.claimsLeft > 0) {
      if (
        expertDetails.levelEstimate !== 'level-5' &&
        expertDetails.activeProjectCount >= MAX_ACTIVE_PROJECTS
      ) {
        blockClaims = true;
        claimsLeftStr = `You already have ${expertDetails.activeProjectCount} active projects with your existing clients.`;
        claimsLeftExtra = `You already have ${expertDetails.activeProjectCount} active projects with your existing clients (not including subscriptions). Once this is below ${MAX_ACTIVE_PROJECTS} again, you'll be able to claim more leads here.`;
      } else if (
        expertDetails.status === 'back' &&
        expertDetails.activeProjectCount >= MAX_ACTIVE_PROJECTS_BAD
      ) {
        blockClaims = true;
        claimsLeftStr = `You already have ${expertDetails.activeProjectCount} active projects with your existing clients.`;
        claimsLeftExtra = `You already have ${expertDetails.activeProjectCount} active projects with your existing clients (not including subscriptions). Once this is below ${MAX_ACTIVE_PROJECTS_BAD} again, you'll be able to claim more leads here.`;
      } else if (
        expertDetails.levelEstimate !== 'level-4' &&
        expertDetails.levelEstimate !== 'level-5' &&
        expertDetails.activeEscrowCents >= MAX_ACTIVE_ESCROW
      ) {
        blockClaims = true;
        claimsLeftStr = `You already have $${formatNumberWithCommas(
          centsDollarsRounded(expertDetails.activeEscrowCents),
        )} in escrow with your active projects.`;
        claimsLeftExtra = `You already have $${formatNumberWithCommas(
          centsDollarsRounded(expertDetails.activeEscrowCents),
        )} in escrow with your active projects. Once this is below $${formatNumberWithCommas(
          centsDollarsRounded(MAX_ACTIVE_ESCROW),
        )} again, you'll be able to claim more leads here.`;
      } else if (
        expertDetails.status === 'back' &&
        expertDetails.activeEscrowCents >= MAX_ACTIVE_ESCROW_BAD
      ) {
        blockClaims = true;
        claimsLeftStr = `You already have $${formatNumberWithCommas(
          centsDollarsRounded(expertDetails.activeEscrowCents),
        )} in escrow with your active projects.`;
        claimsLeftExtra = `You already have $${formatNumberWithCommas(
          centsDollarsRounded(expertDetails.activeEscrowCents),
        )} in escrow with your active projects. Once this is below $${formatNumberWithCommas(
          centsDollarsRounded(MAX_ACTIVE_ESCROW_BAD),
        )} again, you'll be able to claim more leads here.`;
      } else {
        claimsLeftStr = `You have ${expertDetails.claimsLeft} claim${
          expertDetails.claimsLeft > 1 ? 's' : ''
        } left this week. This resets every Sunday night (10pm EST).`;
      }
    } else {
      blockClaims = true;
      claimsLeftStr =
        'You have no more claims left this week. This resets every Sunday night (10pm EST).';
      claimsLeftExtra =
        'You have no more claims left this week. This resets every Sunday night (10pm EST). You can also earn extra claims whenever one of your current leads accepts their first project quote with you.';
    }
  }
  if (expertDetails) {
    let soundSetting = expertDetails.notificationSounds || 'NONE';
    if (blockClaims && soundSetting === 'QUEUE') {
      soundSetting = 'NONE';
    } else if (blockClaims && soundSetting === 'ALL') {
      soundSetting = 'UNREAD';
    }
    alertUtils.setPlaySounds(soundSetting);
  }
  const pathBase = location.pathname.split('/')[1];
  const pathClass = PATH_TO_CLASS[pathBase] || '';
  return (
    <div className={'Dashboard ExpertDashboard ' + pathClass + ' '}>
      <div className="DashboardFixed">
        <div className="DashboardFixedTopCircle" />
        <div className="DashboardFixedBottomCircle" />
      </div>
      <div className="DashboardNav">
        <div className="DashboardNavHeader">
          <Link className="DashboardNavHeaderLeft" to="/home">
            <img
              className="DashboardNavHeaderLogo DashboardNavHeaderLogoWhite"
              src={logoWhite}
              alt="Storetasker Logo"
            />
            <img
              className="DashboardNavHeaderLogo DashboardNavHeaderLogoBlack"
              src={logoBlack}
              alt="Storetasker Logo"
            />
          </Link>
          <Link className="DashboardNavHeaderRight" to="/profile">
            <ExpertUserBubble
              className="DashboardNavHeaderProfile"
              expertDetails={expertDetails}
              expertId={expertId}
            />
          </Link>
        </div>
        <div className="DashboardNavIntro">
          <Switch location={location}>
            <Route path="/home">
              <div className="DashboardNavIntroHeader">Welcome Back</div>
              <div className="DashboardNavIntroBody">
                This is your home base for announcements and important
                reminders.
              </div>
            </Route>
            <Route path="/leads">
              <div className="DashboardNavIntroHeader">
                Find Your Next Client
              </div>
              <div className="DashboardNavIntroBody">{claimsLeftStr}</div>
            </Route>
            <Route path="/clients">
              <div className="DashboardNavIntroHeader">Build Your Business</div>
              <div className="DashboardNavIntroBody">
                Close leads, manage active projects, and build long term
                relationships.
              </div>
            </Route>
            <Route path="/inbound">
              <div className="DashboardNavIntroHeader">Unknown Messages</div>
              <div className="DashboardNavIntroBody">
                Here you&apos;ll find referrals and direct outreach from new
                leads.
              </div>
            </Route>
            <Route path="/dangerzone">
              <div className="DashboardNavIntroHeader">The Danger Zone</div>
              <div className="DashboardNavIntroBody">
                Let&apos;s work together to resolve support cases quickly and
                keep clients happy.
              </div>
            </Route>
            <Route path="/support">
              <div className="DashboardNavIntroHeader">Storetasker Support</div>
              <div className="DashboardNavIntroBody">
                This is where you can connect with our Support Team whenever you
                need help.
              </div>
            </Route>
            <Route path="/collab">
              <div className="DashboardNavIntroHeader">
                Collaborate with Experts
              </div>
              <div className="DashboardNavIntroBody">
                Invite new experts to join Storetasker and search for other
                experts to work with.
              </div>
            </Route>
            <Route path="/profile">
              <div className="DashboardNavIntroHeader">Storetasker Profile</div>
              <div className="DashboardNavIntroBody">
                This is where you can update your profile, view KPI&apos;s, cash
                out, and more.
              </div>
            </Route>
          </Switch>
        </div>
        <div className="DashboardNavLinks">
          <div className="DashboardNavLinkPointer" />
          <NavLink className="DashboardNavLink DashboardNavLinkHome" to="/home">
            <span className="DashboardNavLinkText">Home</span>
          </NavLink>
          <NavLink className="DashboardNavLink" to="/leads">
            <span className="DashboardNavLinkText">Leads</span>
            {!blockClaims && !!unreadActiveLeads.length && (
              <span className="DashboardNavLinkSuper">
                {unreadActiveLeads.length}
              </span>
            )}
          </NavLink>
          <NavLink className="DashboardNavLink" to="/clients">
            <span className="DashboardNavLinkText">Clients</span>
            {!!unreadActiveClients.length && (
              <span className="DashboardNavLinkSuper">
                {unreadActiveClients.length}
              </span>
            )}
          </NavLink>
          <NavLink className="DashboardNavLink" to="/inbound">
            <span className="DashboardNavLinkText">Inbound</span>
            {!!unreadOrphans.length && (
              <span className="DashboardNavLinkSuper">
                {unreadOrphans.length}
              </span>
            )}
          </NavLink>
          <NavLink className="DashboardNavLink" to="/dangerzone">
            <span className="DashboardNavLinkText DashboardNavLinkTextBig">
              Danger Zone
            </span>
            <span className="DashboardNavLinkText DashboardNavLinkTextSmall">
              DZ
            </span>
            {!!(
              dangerZoneClients.length +
              unreadOldActiveClients.length +
              dangerZoneProjects.length
            ) && (
              <span className="DashboardNavLinkSuper">
                {dangerZoneClients.length +
                  unreadOldActiveClients.length +
                  dangerZoneProjects.length}
              </span>
            )}
          </NavLink>
          <NavLink
            className="DashboardNavLink DashboardNavLinkSupport"
            to="/support"
          >
            <span className="DashboardNavLinkText">Support</span>
            {isSupportUnread && (
              <span className="DashboardNavLinkSuper">1</span>
            )}
          </NavLink>
          <NavLink
            className="DashboardNavLink DashboardNavLinkCollab"
            to="/collab"
          >
            <span className="DashboardNavLinkText DashboardNavLinkTextBig">
              Collaborate
            </span>
            <span className="DashboardNavLinkText DashboardNavLinkTextSmall">
              Collab
            </span>
          </NavLink>
          <a
            className="DashboardNavLink DashboardNavLinkPerksExpert"
            href="https://resources.storetasker.com/perks-of-storetasker"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="DashboardNavLinkText">Exclusive Perks</span>
          </a>
        </div>
        <div className="DashboardNavFooter">
          <NavLink className="DashboardNavFooterLink" to="/profile">
            {expertDetails
              ? (expertDetails.firstName || '') +
                ' ' +
                (expertDetails.lastName || '')
              : ''}
          </NavLink>
        </div>
      </div>
      <div className="DashboardMain">
        <Switch location={location}>
          <Route path="/home">
            <ExpertHomescreen
              expertId={expertId}
              alertCount={unreadActiveClients.length}
              alertOldCount={unreadOldActiveClients.length}
              dzCount={dangerZoneClients.length}
              dzProjectCount={dangerZoneProjects.length}
              queueCount={activeLeadsForExpert.length}
              expertDetails={expertDetails}
              socketClient={socketClient}
            />
          </Route>
          <Route path="/leads">
            <ExpertActiveRequests
              expertId={expertId}
              expertDetails={expertDetails}
              socketClient={socketClient}
              blockClaims={blockClaims ? claimsLeftExtra || claimsLeftStr : ''}
            />
          </Route>
          <Route path="/clients">
            <ExpertActiveClients
              expertId={expertId}
              socketClient={socketClient}
            />
          </Route>
          <Route path="/inbound">
            <ExpertInbound
              emailTemplates={emailTemplates}
              expertId={expertId}
              expertDetails={expertDetails}
              socketClient={socketClient}
            />
          </Route>
          <Route path="/dangerzone">
            <ExpertDangerZone expertId={expertId} socketClient={socketClient} />
          </Route>
          <Route path="/support">
            <div className="SupportScreen" />
          </Route>
          <Route path="/collab">
            <ExpertCollabView
              expertId={expertId}
              expertDetails={expertDetails}
            />
          </Route>
          <Route path="/profile">
            <div className="ProfileScreen" />
          </Route>
          <Redirect to="/home" />
        </Switch>
      </div>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/leads/:id"
            render={(routeProps) => (
              <ExpertRequestDetail
                emailTemplates={emailTemplates}
                expertId={expertId}
                expertDetails={expertDetails}
                socketClient={socketClient}
                requestId={routeProps.match.params.id}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/clients/:id"
            render={(routeProps) => (
              <ExpertClientDetail
                emailTemplates={emailTemplates}
                expertId={expertId}
                expertDetails={expertDetails}
                matchId={routeProps.match.params.id}
                quoteTemplates={quoteTemplates}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/support"
            render={() => (
              <ExpertSupportChannelDetail
                emailTemplates={emailTemplates}
                expertId={expertId}
                expertDetails={expertDetails}
                socketClient={socketClient}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Route
            location={location}
            path="/profile"
            render={() => (
              <ExpertProfileView
                emailTemplates={emailTemplates}
                expertId={expertId}
                quoteTemplates={quoteTemplates}
                refetchTemplates={refetchTemplatesTop}
              />
            )}
          />
        </CSSTransition>
      </TransitionGroup>
      {!!networkErrorMessage && (
        <div
          className={
            'AppDashboardNetworkError AppDashboardNetworkError' +
            (networkErrorType || 'BAD')
          }
          onClick={() => setNetworkErrorMessage('')}
        >
          {networkErrorMessage}
        </div>
      )}
      {!!loadingError && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div className="DashboardErrorCoverContent">{loadingError}</div>
          </div>
        </div>
      )}
    </div>
  );
};

ExpertDashboard.propTypes = {
  expertId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default ExpertDashboard;
